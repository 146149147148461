import { Directive, DoCheck, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[carewebFilterMinHeight]'
})
export class FilterMinHeightDirective implements DoCheck {
    private previousHeigth: number;

    constructor(private element: ElementRef, private renderer: Renderer2) {}

    ngDoCheck() {
        const height = document.getElementById('grid').offsetHeight;
        if (this.previousHeigth !== height) {
            this.previousHeigth = height;
            const pageMinHeight = parseInt(document.getElementById('top').style.minHeight, 10);
            this.renderer.setStyle(
                this.element.nativeElement,
                'min-height',
                pageMinHeight > height ? pageMinHeight + 'px' : height + 35 + 'px'
            );
        }
    }
}
