import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { ToastrService } from 'ngx-toastr';
import { PsService } from '../../../ps.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { InputValidators } from 'src/main/webapp/src/app/components/validators/InputValidators';
import { EtablissementDataRaw } from 'src/main/webapp/src/app/data/EtablissementDataRaw';

@Component({
	selector: 'careweb-app-ps-etablissement-nouveau',
	templateUrl: './ps-etablissement-nouveau.component.html',
	styleUrls: [ './ps-etablissement-nouveau.component.scss' ]
})
export class PsEtablissementNouveauComponent implements OnInit {
	submitted: Boolean = false;
	etablissementForm: UntypedFormGroup;
	@Input() idPs: number;
	@Output() public showModal = new EventEmitter<boolean>();

	constructor(
		private fb: UntypedFormBuilder,
		private modalRef: BsModalRef,
		private toastr: ToastrService,
		private inputValidators: InputValidators,
		private psService: PsService
	) {}

	ngOnInit() {
		this.validForm();
	}

	validForm() {
		this.etablissementForm = this.fb.group({
			raisonSociale: [ '', [ Validators.required, this.inputValidators.inputWithSpaces ] ],
			adresse1: [ '' ],
			adresse2: [ '' ],
			codePostal: [ '' ],
			ville: [ '' ],
			telephone: [ '' ],
			fax: [ '' ],
			email: [ '', [ Validators.required,
							Validators.pattern(this.inputValidators.emailRegexPattern) ] ]
		});
	}

	onSubmit() {
		this.submitted = true;
		if (this.etablissementForm.invalid) {
			return;
		}

		var currentEtablissement: EtablissementDataRaw = new EtablissementDataRaw();
		currentEtablissement.raisonSociale = isNullOrUndefined(this.etablissementForm.value.raisonSociale)
			? ''
			: (this.etablissementForm.value.raisonSociale as String).trim();
		currentEtablissement.adresse1 = isNullOrUndefined(this.etablissementForm.value.adresse1)
			? ''
			: (this.etablissementForm.value.adresse1 as String).trim();
		currentEtablissement.adresse2 = isNullOrUndefined(this.etablissementForm.value.adresse2)
			? ''
			: (this.etablissementForm.value.adresse2 as String).trim();
		currentEtablissement.codepostal = isNullOrUndefined(this.etablissementForm.value.codePostal)
			? ''
			: (this.etablissementForm.value.codePostal as String).trim();
		currentEtablissement.ville = isNullOrUndefined(this.etablissementForm.value.ville)
			? ''
			: (this.etablissementForm.value.ville as String).trim();
		currentEtablissement.telephone = isNullOrUndefined(this.etablissementForm.value.telephone)
			? ''
			: this.etablissementForm.value.telephone;
		currentEtablissement.fax = isNullOrUndefined(this.etablissementForm.value.fax)
			? ''
			: this.etablissementForm.value.fax;
		currentEtablissement.email = isNullOrUndefined(this.etablissementForm.value.email)
			? ''
			: (this.etablissementForm.value.email as String).trim();

		this.psService.attachPsOfEtablissement(this.idPs, currentEtablissement).subscribe(
			(data) => {
				if (data) {
					this.toastr.success("L'établissement a été ajouté avec succès");
					this.modalRef.hide();
				}
			},
			() => {
				this.toastr.error("L'établissement n'a pas pu être ajouté");
			}
			// this.submitted = false;
		);
	}

	onReset() {
		this.submitted = false;
		this.etablissementForm.reset();
		this.modalRef.hide();
	}

	get formFields() {
		return this.etablissementForm.controls;
	}

	get formFieldsValue() {
		return this.etablissementForm.value;
	}
}
