<div class="full-careweb-container">
    <div class="row" style="margin-top:50px">
        <div class="col" style="text-align: center; font-size: 100px;">404</div>
    </div>
    <div class="row" style="margin-top:20px">
        <div class="col" style="text-align: center; font-size: 20px;">La page que vous avez demandée est introuvable.</div>
    </div>
    <div class="row" style="margin-top:20px">
        <div class="col"  style="text-align: center;"><button class="btn btn-primary btn-lg" type="button">Retour à l'accueil</button></div>
    </div>
</div>