export class RappSuiviFilterRaw {
	idOct: number;
    numeroAdeli: string;
	email: string;
	compteDesyncho: boolean; // oui (i.e. comptes désynchros > 0) / non (i.e. comptes désynchros = 0) 
	vrt: boolean; // oui (i.e. VRT > 0) / non (i.e. VRT = 0) 
	rapproAnomalies: boolean;  // oui / non
	startDate: Date;
	endDate: Date;
	connectionNumber : Number;
	typeRappro : string;
    constructor(data = null) {
		Object.assign(this, data);
	}
}
