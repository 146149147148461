import { Component, OnInit } from '@angular/core';
import { VersionService } from '../../pages/login/version.service';
import { BuildVersionDataRaw } from '../../data/buildVersionDataRaw';

@Component({
	selector: 'careweb-app-footer',
	templateUrl: './footer.component.html',
	styleUrls: [ './footer.component.scss' ]
})
export class FooterComponent implements OnInit{
	version: BuildVersionDataRaw;
	constructor(private versionSvc: VersionService) {}

	ngOnInit() {
		var ctx = this;
		this.versionSvc.getVersion().subscribe((data) => {
			ctx.version = data[0];
		})
	}
}
