import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
// import { element } from 'protractor';
const EXCEL_TYPE= 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx'

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }

  public exportAsExcelFile(
      reportSubHeading: String,
      headersArray: any,
      json: any[],
      footerData: any,
      excelFileName:  string,
      sheetName: string
  ){

      const header = headersArray;
      const data = json;

      const workbook = new Workbook();
      workbook.creator = 'Careweb';
      workbook.lastModifiedBy = 'Careweb';
      workbook.created = new Date();
      workbook.modified = new Date();
      const worksheet = workbook.addWorksheet(sheetName);
      const headerRow = worksheet.addRow(header);
      headerRow.eachCell((cell,index)=>{
        cell.font=  {size:12,bold:false};

        worksheet.getColumn(index).width=header[index-1].length<20?20:header[index-1].length;
      });
      let columnsArray: any[];
      for(const key in json){
        if(json.hasOwnProperty(key)){
          columnsArray = Object.keys(json[key]);
        }
      }
      data.forEach((element:any)=>{
        const eachRow:any = [];
        columnsArray.forEach((column)=>{
          eachRow.push(element[column]);
        });
        if(element.isDeleted === 'Y'){
          const deletedRow: any = worksheet.addRows(eachRow);
          deletedRow.eachCell((cell:any)=>{
            cell.font = {name: 'calibri',family:4,size:11,bold:false,strike:true};
          });
        }else{
          worksheet.addRow(eachRow);
        }
      });

      worksheet.addRow([]);

      if(footerData != null){
        footerData.forEach((element:any)=>{
          const eachRow:any = [];
          element.forEach((val: any)=>{
            eachRow.push(val);
          });
          const footerRow = worksheet.addRow(eachRow);
          footerRow.eachCell((cell)=>{
            cell.font = {bold:true};
          });
        });
      }

      workbook.xlsx.writeBuffer().then((data:ArrayBuffer)=>{
        const blob = new Blob([data],{type:EXCEL_TYPE});
        fs.saveAs(blob,excelFileName+ EXCEL_EXTENSION);
      });


  }

  // private numToAlpha(num:number){
  //   let alpha = '';
  //   for(;num>=0;num=parseInt((num/26).toString(),10)-1){
  //     alpha = String.fromCharCode(num%26+0x41)+alpha;
  //   }
  //   return alpha;
  // }
}

