<div class="full-careweb-container">
    <div id="filter" class="filter-container" carewebFilterMinHeight>
        <div class="filter-container-inner" [ngClass]="{ 'show-content': showFilter, 'init-hidden': initHidden }">
            <careweb-accordion-header-buttons (closeFilters)="toggleFilterDisplay()" (resetFilters)="resetAllFilter()"
                (launchSearch)="launchSearch()"></careweb-accordion-header-buttons>
            <div class="filter-content">
                <mat-accordion displayMode="flat" class="accordion-filter">
                    <careweb-accordion-group id="service-name" title="Nom du service">
                        <ng-select name="serviceName" id="serviceName" placeholder="Nom du service" [searchable]="false"
                            [(ngModel)]="filters.serviceName" appendTo=".filter-container" (keydown.enter)="onKeyDown($event)">
                            <ng-option *ngFor="let service of serviceList" [value]="service.serviceName">
                                {{service.serviceName}}
                            </ng-option>
                        </ng-select>
                    </careweb-accordion-group>
                 <!--   <careweb-accordion-group id="date-appel" title="Date de l'appel">
                        <careweb-date-from-to-filter labelFrom="entre le" [(modelFrom)]="filters.startProcessingDate"
                            [(modelTo)]="filters.endProcessingDate" [maxDate]="currentDate">
                        </careweb-date-from-to-filter>
                    </careweb-accordion-group>-->
                    <!-- [(size)]="fontSizePx" -->
                    <careweb-accordion-group id="date-appel" title="Date de l'appel">
                        <careweb-date-time-filter labelFrom=" entre le " [(modelStart)]="filters.startProcessingDate"
                                              [(modelEnd)]="filters.endProcessingDate" [maxDate]="currentDate" (keydown.enter)="onKeyDown($event)">
                        </careweb-date-time-filter>
                    </careweb-accordion-group>

                    <careweb-accordion-group id="provider-number" title="Numéro de PS">
                        <input type="text" id="input_provider-number" [(ngModel)]="filters.providerNumber"
                            oninput="this.value=this.value.replace(/[^\d]/,'').replace(/\s/g,'').replace( /\./gi,'');if(this.value.length==9) return false;"
                            onpaste="this.value=this.value.replace(/[^\d]/,'').replace(/\s/g,'').replace( /\./gi,'');if(this.value.length==9) return false;"
                            pattern="^[0-9]" min="0" (keydown.enter)="onKeyDown($event)" onKeyPress="if(this.value.length==9) return false;" />
                    </careweb-accordion-group>
                    <careweb-accordion-group id="num_amc_otp" title="Numéro AMC/OTP">
                        <input type="text" id="input_amc_otp" [(ngModel)]="filters.organizationNumber"
                            oninput="this.value=this.value.replace(/[^\d]/,'').replace(/\s/g,'').replace( /\./gi,'');"
                            pattern="^[0-9]" min="0" (keydown.enter)="onKeyDown($event)"/>
                    </careweb-accordion-group>
                    <careweb-accordion-group id="insee-benef" title="INSEE du bénéficiaire">
                        <input type="text" id="input_insee-benef"
                            [(ngModel)]="filters.beneficiaryIdentificationNumber"  (keydown.enter)="onKeyDown($event)"/>
                    </careweb-accordion-group>
                    <careweb-accordion-group id="cgpass-ref" title="Référence externe CGPASS">
                        <input type="text" id="input_cgpass-ref" [(ngModel)]="filters.cgpassExternalReference" (keydown.enter)="onKeyDown($event)" />
                    </careweb-accordion-group>
                    <careweb-accordion-group id="return-codeOK" title="Code Retour">
                        <ng-select name="returnCodeOK" id="returnCodeOK" placeholder="Code retour" [searchable]="false"
                            [(ngModel)]="filters.returnCodeOK" appendTo="body" (keydown.enter)="onKeyDown($event)">
                            <ng-option [value]="''"> </ng-option>
                            <ng-option [value]="true">OK</ng-option>
                            <ng-option [value]="false">KO</ng-option>
                        </ng-select>
                    </careweb-accordion-group>
                </mat-accordion>
            </div>
        </div>
    </div>
    <div class="grid-container" id="grid">
        <h2 class="title-border mt-3">Liste des flux</h2>
        <div>
            <section>
                <div class="row grid-top-btn">
                    <div class="col-md-4 filter">
                        <button type="button" name="btn_toggle_filter" class="btn btn-primary small left-open"
                            [hidden]="showFilter" (click)="toggleFilterDisplay()">
                            <i class="fa fa-sliders"></i>
                            Filtrer
                        </button>
                        <careweb-pluralize class="filter-count" [messageKey]="'Résultat(s) trouvé(s)'"
                            [count]="rowCount == 0 ? -1 : rowCount"></careweb-pluralize>
                    </div>
                    <div class="col-md-8 conf-col">
                        <div class="careweb-toolbar">
                            <careweb-pagination-size id="dv_fse_paging" [pageSizes]="[10, 20,30, 50, 100]"
                                                     [(ngModel)]="gridDataRaw.size" (paginationSizeChange)="onPaginationSizeChange($event)">
                            </careweb-pagination-size>
                            <span class="btn btn-action small" tooltip="Réinitialiser l'affichage" placement="top"
                                container="body" name="spn_reset_grid" (click)="resetGridState()">
                                <i class="fa fa-refresh"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row grid-top-filter">
                    <div class="col-md-12">
                        <careweb-filter-label *ngFor="let filter of filterList" (closeFilter)="removeFilter(filter.id)">
                            <span *ngIf="filter">{{ filter.label}}: {{filter.value }}</span>
                        </careweb-filter-label>
                    </div>
                </div>
                <div class="row highlight-row">
                    <div class="col-md-12 relative">
                        <ag-grid-angular style="width: 100%" class="ag-theme-balham ag-theme-no-rows"
                            [gridOptions]="gridHelper.gridOptions">
                        </ag-grid-angular>
                        <careweb-pagination [gridApi]="gridHelper.gridApi"></careweb-pagination>
                    </div>
                </div>
                <div *ngIf="rowCount==50" class="row highlight-row col-12 mt-2 m-0 p-0 " style="color:red;">
                    * Seuls les 50 appels les plus récents répondant à vos critères sont affichés. Si vous ne
                    trouvez pas l’appel recherché, merci d’affiner vos critères de sélection.
                </div>
                <div *ngIf="showError" class="row highlight-row col-12 mt-2 m-0 p-0 " style="color:red;">
                    Une erreur est survenue coté web service, Veuillez réessayer plus tard.
                </div>
            </section>
        </div>
    </div>
</div>