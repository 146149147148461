import { Component, AfterViewInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'tracability-preview',
	template: `
		<i
			[ngClass]="iconClass"
            container="body"
			[tooltip]="tooltip"
            containerClass="tooltip-grid"
		></i>
	`
})
export class TracabilityPreviewComponent implements ICellRendererAngularComp, AfterViewInit {
	iconClass: string;
	tooltip: string;
	private params: any;

	constructor() {}

	agInit(params: any): void {
		this.params = params;
	}

	ngAfterViewInit() {
		var cellParams = this.params;
		if (cellParams.data) {
			if (
				cellParams.colDef.field &&
				cellParams.colDef.field == 'options' &&
				cellParams.redirect == 'tracabilityDetails'
			) {
				this.iconClass = cellParams.iconClass;
				this.tooltip = 'Consulter détails de flux';
			}
		}
	}

	refresh(): boolean {
		return false;
	}

	// previewTracability(): void {
	// 	if (this.params.redirect == 'tracabilityDetails') {
	// 		if (this.params.type == 'parent') {
	// 			this.tracabilityService.setFluxDetails(this.params.data.parentTrace);
	// 		} else if (this.params.type == 'child') {
	// 			this.tracabilityService.setFluxDetails(this.params.data);
	// 		}
	// 		this.router.navigate([ '/tracability-details' ]);
	// 	}
	// }
}
