export class PsDelegueDataRaw {
	id: Number;
	idPsAssocie: Number;
	numeroPsAssocie: String;
	nomPsAssocie: String;
	raisonSocialeEtablissementPsAssocie: String;
	idPsDelegue: Number;
	numeroPsDelegue: String;
	nomPsDelegue: String;
	raisonSocialeEtablissementPsDelegue: String;
}
