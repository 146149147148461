import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SiteEmissionDataRaw } from '../../../data/siteEmissionDataRaw';
import { SiteEmissionService } from '../../ps/service/site-emission.service';
import { MigrationOctService } from '../migration-oct.service';

@Component({
	selector: 'careweb-app-start-migration-oct',
	templateUrl: './start-migration-oct.component.html',
	styleUrls: ['./start-migration-oct.component.scss']
})

export class StartMigrationOctComponent implements OnInit {
	@Input() idBoiteMail: number;
	@Input() numeroPs: number;
	@Input() idPs: number;
	@Input() showModalStart: boolean;
	@Input() showModalStop: boolean;
	@Output() modalClosed = new EventEmitter<void>();
	startForm: FormGroup;
	siteEmissionPs: SiteEmissionDataRaw[];
	selectedSite: any;
	submitted: Boolean = false;
	siteDestination: any;
	modalMessage: string = 'Le processus de migration est actuellement activé sur cette BAL, en poursuivant cette action, ce processus sera désactivé. Souhaitez-vous poursuivre ?'
	//debugArray: any = [{ value: 0, viewValue: 'Débogage' }, { value: 1, viewValue: 'Production' }];
	idSiteDestination: any;
	constructor(
		private modalRef: BsModalRef,
		private toastr: ToastrService,
		private fb: FormBuilder,
		private migrationSvc: MigrationOctService,
		private siteEmissionService: SiteEmissionService,
		private modalService: BsModalService,

	) {
		this.siteEmissionPs = new Array<SiteEmissionDataRaw>();
	}

	ngOnInit() {
		this.initializeCurrentPage();
		if (this.showModalStart) {
			this.getListSiteEmissionByIdPs(this.idPs);
		}
	}

	initializeCurrentPage() {
		this.validForm();
	}

	getListSiteEmissionByIdPs(idPs: number) {
		this.siteEmissionService.getSiteEmissionsByIdPs(idPs).subscribe((data) => {
			data = data.filter((site) => site.balEmettrice !== null);
			if (data) {
				this.siteEmissionPs = new Array<SiteEmissionDataRaw>();
				this.siteEmissionPs = data;
				this.selectedSite = data[0].idSite;
			}
		});
	}

	validForm() {
		this.startForm = this.fb.group({
			siteDestination: [this.siteEmissionPs[0]],
		});
	}

	get formFields() {
		return this.startForm.controls;
	}

	closeModal() {
		this.modalRef.hide();
		this.modalClosed.emit();
		this.modalService.onHide.subscribe(() => {
			this.migrationSvc.notifyModalClosed();
		});
	}

	onSubmit() {
		let idSiteDestination = this.startForm.value.siteDestination;
		this.migrationSvc.start({ idBoiteMail: this.idBoiteMail, idSiteDestination: idSiteDestination }).subscribe(
			(data) => {
				if (data) {
					if (data.erreurTransfert != null) {
						this.toastr.error(data.erreurTransfert.description);
					} else {
						this.toastr.success("Le processus de migration a bien été demarré.", "", { timeOut: 60000 });
						this.closeModal();
					}
				}
			},
			(err) => {
				this.toastr.error("Une erreur est survenue lors du démarrage du processus de migration.", "", { timeOut: 60000 });
			}
		);

	}

	reset() {
		this.modalRef.hide();
	}

	confirmModal() {
		this.migrationSvc.stop(this.idBoiteMail).subscribe(
			() => {
				this.toastr.success("Le processus de migration a été arrêté.", "", { timeOut: 60000 });
				this.closeModal();
			},
			(err) => {
				this.toastr.error("Une erreur est survenue lors de l'arrêt du processus de migration.", "", { timeOut: 60000 });
			}
		);
	}

	showModal(showModal: boolean): void {
		this.showModalStop = false;
		this.closeModal();
	}
}
