import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, Renderer2, AfterContentChecked } from '@angular/core';
import { Subscription } from 'rxjs';
import { ContextHelpSvc, HelpItem } from '../../core/services/context-help.service';
import {AdminService} from "../../pages/ contenus-plateforme/admin-content.service";

/** Composant affichage une page d'aide. */
@Component({
    selector: 'careweb-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss'],
    animations: [
        trigger('fadeAndSlideInOut', [
            transition(':enter', [
                style({ opacity: 0 }),
                group([
                    animate('0.5s ease-out', style({ opacity: 0.6 })),
                    query('section', [
                        style({ transform: 'translateX(100%)' }),
                        animate('0.5s ease-out', style({ transform: 'translateX(0%)' }))
                    ])
                ])
            ]),
            transition(
                ':leave',
                group([
                    animate('0.5s ease-in', style({ opacity: 0 })),
                    query('section', animate('0.5s ease-in', style({ transform: 'translateX(100%)' })))
                ])
            )
        ])
    ]
})
export class HelpComponent implements OnInit, OnDestroy, AfterContentChecked {
    /** Souscription aux événements de demande d'aide. */
    private helpRequestSubscription: Subscription;

    /** L'item d'aide à afficher. */
    help: HelpItem;
    /** Indique si la page d'aide doit être visible. */
    displayed: boolean;

    constructor(private helpSvc: ContextHelpSvc, private renderer: Renderer2,private adminService: AdminService) {
        this.help = { code: null, title: '', text: '' };
    }

    ngOnInit() {
        this.helpRequestSubscription = this.helpSvc.helpRequested.subscribe(help => this.open(help));
    }

    ngOnDestroy() {
        this.helpRequestSubscription.unsubscribe();
    }

    /** Ouvre la page d'aide et affiche l'item donné */
	private open(help: HelpItem): void {
		if (help.code === 'help-013') {
            this.adminService.getVideoSettings().subscribe((data)=>{
                help.text = help.text.replace('UrlVideo', data.url);
                this.help = help;
                this.displayed = true;
            })
		} else {
			this.help = help;
			this.displayed = true;
		}
		this.renderer.setStyle(document.body, 'overflow-y', 'hidden');
	}

    /** Demande la fermeture de la page d'aide affichée. */
    close() {
        this.displayed = false;

        if (document.body.classList.contains('app')) {
            this.renderer.setStyle(document.body, 'overflow-y', 'scroll');
        } else if (document.body.classList.contains('no-app')) {
            this.renderer.setStyle(document.body, 'overflow-y', 'auto');
        }
    }

    ngAfterContentChecked() {
    		if (document.getElementById('video-player-details')) {
    			document.getElementById('video-player-details').addEventListener(
    				'toggle',
    				function(evt) {
    					if (!(document.getElementById('video-player-details') as HTMLDetailsElement).open) {
    						const videoPlayer: HTMLMediaElement = document.getElementById(
    							'video-player-help'
    						) as HTMLMediaElement;
    						videoPlayer.pause();
    					}
    				},
    				false
    			);
    		}
    	}
}
