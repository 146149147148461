
import { Component } from '@angular/core';

@Component({
	selector: 'careweb-suivi-rapp-index',
	templateUrl: './suivi-rapp-index.component.html',
	styleUrls: ['./suivi-rapp-index.component.scss']
})

export class SuiviRappIndexComponent {
	active: number = 1;
	constructor(
	) { }

	onTabChange($event) {
	}
}
