import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../../data/userDataRaw';
import { UserPreferencesDataRaw } from '../../data/UserPreferencesDataRaw';
import { CONF } from '../constants';

@Injectable({ providedIn: 'root' })
export class UserService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/user';

	constructor(private http: HttpClient) {}

	resetPwd(login: string, token: string, newPassword: string): Observable<User> {
		return this.http
			.post<User>(`${this.baseUrl}/reset-password`, {
				token: token,
				password: newPassword,
				login: login
			});
	}

	changePassword(login: string, token: string, newPassword: string, oldPasssword): Observable<User> {
		return this.http
			.post<User>(`${this.baseUrl}/change-password`, {
				token: token,
				oldPassword: oldPasssword,
				password: newPassword,
				login: login
			});
	}

	updateUserEmail(data: User): Observable<any> {
		return this.http.put<any>(this.baseUrl + '/' + data.idUser + '/update', data);
	}

	updateUser(idUser, user: User): Observable<User> {
		return this.http.put<any>(this.baseUrl + '/update/' + idUser, user);
	}

	updateUserPreferences(userPreferences: UserPreferencesDataRaw): Observable<void> {
		return this.http.put<any>(this.baseUrl + '/update/user-preferences', userPreferences);
	}
}
