<div *ngIf="releaseNotes != null" class="x-large-careweb-container">
  <div class="bloc-release-notes">
    <div class="row">
      <div class="col-md-5">
        <showdown [options]="{ tables: true }" [value]="subTitle"></showdown>
      </div>
      <div class="col-md-3 mt-3 version"><span >Version :</span></div>
      <div class="col-md-4 mt-3 align-items-center">
        <ng-select [(ngModel)]="selectedTitle" (change)="onSelectTitle()" [searchable]="false"
                   [clearable]="false">
          <ng-option *ngFor="let version of versions" [value]="version.title">VERSION {{ version.title }}</ng-option>
        </ng-select>
      </div>
    </div>
    <showdown [options]="{ tables: true }" [value]="contentTitle"></showdown>
  </div>
</div>