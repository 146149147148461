export class MvFinanciersFilterRaw {
    numeroPs: String;
    numeroPScomboBox: String;
    numFacture: String;
    numFactureFrom: String;
    numFactureTo: String;
    organismeLibelle: string;
    montantMf: number;
    montantMfFrom: number;
    montantMfTo: number;
    referenceDette: String;
    natureDette: String;
    natureOperation: String;
    jourComptable: Date;
    jourComptableFrom: Date;
    jourComptableTo: Date;


}