import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'careweb-app-unknown-page',
	templateUrl: './unknown-page.component.html',
	styleUrls: [ './unknown-page.component.scss' ]
})
export class UnknownPageComponent implements OnInit {

	constructor() {
	}

	ngOnInit() {
	}

}
