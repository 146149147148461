import { ConvertDateFormat } from './../../../core/utilities/convert-date-format.service';
import { RspFilterRaw } from './../../../data/filters/rspFilterRaw';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { RspRappSummaryService } from '../rspRappSummary.service';
import { RspRappSummaryDataRaw } from '../../../data/rspRappSummaryDataRaw';
import { isNullOrUndefined } from 'util';
import { NgForm } from '@angular/forms';
import { TransactionService } from '../transaction.service';
import { TransactionDataRaw } from '../../../data/transactionDataRaw';
import * as Chart from 'chart.js';
import { formatDate } from '@angular/common';
import { forkJoin } from 'rxjs';
import { StorageService } from '../../../shared/storage-service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale, frLocale } from 'ngx-bootstrap/chronos';
import { RspRappSummaryFilterRaw } from "../../../data/filters/rspRappSummaryFilterRaw";
import {PsService} from "../../ps/ps.service";
import {PsDelegueDataRaw} from "../../../data/PsDelegueDataRaw";
import {PsDelegueService} from "../../ps/service/ps-delegue.service";
import { isEmpty } from 'lodash';

@Component({
	selector: 'app-rapp-dashboard',
	templateUrl: './rapp-dashboard.component.html',
	styleUrls: [ './rapp-dashboard.component.scss' ]
})
export class RappDashboardComponent implements OnInit {
	public isCollapsed = false;

	dateFin: Date = new Date();
	dateDebut: Date = new Date();
	startDate: Date;
	endDate: Date;
	nbrDays = 300;
	userConnected;
	isSupervisor: boolean = false;
	numeroPS ;
	rspFilter = new RspFilterRaw();
	rspCriteria = new RspRappSummaryDataRaw();
	transactionCriteria = new TransactionDataRaw();
	filtersRSP = new RspRappSummaryFilterRaw();
	tauxRapp: string;
	tauxGlobal: string;
	filterPeriode: number = 7;
	rspAvecPaiementEtLibelle = { sum: '0', count: 0 };
	rspAvecPaiementRapproches = { sum: '0', count: 0 };
	@ViewChild('retourForm')
	form: NgForm;
	public chartVirements: any;
	public chartTop10Orga: any;
	chartTop10OrgaByRappAuto: any;
	ranges: any = [7,15,30, 60, 90];
	selectedRange: number;
	currentDate: Date = new Date();
	isAuthorized: boolean = false;
	constructor(
		private localeService: BsLocaleService,
		private rspRappSummarySvc: RspRappSummaryService,
		private bankTransactionSvc: TransactionService,
		private router: Router,
		private storageService: StorageService,
		private convertFormatDate: ConvertDateFormat,
		private psService: PsService,
		private psDelegueSVC: PsDelegueService
	) {
		// Body styling
		const body = document.body;
		body.className = 'app';
		// Define local date fr
		defineLocale('fr', frLocale);
	}

	ngOnInit() {
		// Set local date
		this.setLocale();
		
		const sessionPeriode = this.storageService.get('rappDashPeriode', true);
		if (sessionPeriode) {
			this.filterPeriode = sessionPeriode;
		}

		this.dateFin = new Date();
		this.dateFin.setDate(this.dateFin.getDate() - 7);

		this.userConnected = JSON.parse(localStorage.getItem('careweb_user'));
		if(this.userConnected.idPs!=null){
			this.psService.getPsById(this.userConnected.idPs).then((data) => {
				this.numeroPS = data.numeroAdeli;
			});
		}
		const pcConnected  = JSON.parse(localStorage.getItem('current_ps'));
		this.psDelegueSVC.getPsDelegueByIdPs(this.userConnected.idPs?this.userConnected.idPs:pcConnected.idPs).subscribe((data: Array<PsDelegueDataRaw>) => {
			if (data.length > 0 ) {
				this.isSupervisor = true; }
		});
		// Apply numero Ps supervised on the filter if exist
		var numeroPsSupervisedLocalStorage = localStorage.getItem('supervised_ps');
		if (!isEmpty(numeroPsSupervisedLocalStorage)) {
			if (numeroPsSupervisedLocalStorage === '-1') {
			}
			else {
				numeroPsSupervisedLocalStorage = numeroPsSupervisedLocalStorage.split(';')[0];
                this.numeroPS = numeroPsSupervisedLocalStorage;
			}
		}
		this.getStatistiques();
		this.isAuthorized = this.isAdminOrHotline();
	}

	getStatistiques() {
		this.fillDefaultCriteria();
		this.getRapprochementKpi();
		this.getRspByDateAndStatus();
		this.getTop10Organismes();
		this.getTop10OrganismesByRappAuto();
	}

	isAdminOrHotline() {
		return (this.userConnected.idTypeUser === 1 || this.userConnected.idTypeUser === 3)
	}

	changePeriodeFilter() {
		this.destroyCharts();
		this.getStatistiques();
		this.storageService.set('rappDashPeriode', this.filterPeriode, true);
	}

	destroyCharts() {
		this.chartVirements.destroy();
		this.chartTop10Orga.destroy();
		this.chartTop10OrgaByRappAuto.destroy();
	}

	fillDefaultCriteria() {
		this.rspFilter.idPs = (localStorage.getItem('supervised_ps') != null && localStorage.getItem('supervised_ps') !='-1') ? localStorage.getItem('supervised_ps').split(';')[1]:(localStorage.getItem('idPs') != null) ? localStorage.getItem('idPs') : this.userConnected.idPs;
		this.rspFilter.dateReceptionRspTo = this.dateFin;
		this.dateDebut = new Date(this.dateFin);
		this.dateDebut.setDate(this.dateFin.getDate() - this.filterPeriode);
		this.rspFilter.dateReceptionRspFrom = this.dateDebut;
		this.rspCriteria.idPs = (localStorage.getItem('supervised_ps') != null && localStorage.getItem('supervised_ps') !='-1') ? localStorage.getItem('supervised_ps').split(';')[1]:(localStorage.getItem('idPs') != null) ? localStorage.getItem('idPs') : this.userConnected.idPs;

		this.rspCriteria.dateComptableFrom = !isNullOrUndefined(this.rspFilter.dateReceptionRspFrom)
			? this.convertFormatDate.getFormatDateFr(this.rspFilter.dateReceptionRspFrom)
			: null;

		this.rspCriteria.dateComptableTo = !isNullOrUndefined(this.rspFilter.dateReceptionRspTo)
			? this.convertFormatDate.getFormatDateFr(this.rspFilter.dateReceptionRspTo)
			: null;

		this.transactionCriteria.dateOperationFrom = this.convertFormatDate.getFormatDateFr(this.rspFilter.dateReceptionRspFrom);
		this.transactionCriteria.dateOperationTo = this.convertFormatDate.getFormatDateFr(this.rspFilter.dateReceptionRspTo);
	}

	// @TODO: Delete this unused method
	getBankTransactionsByDate() {
		this.bankTransactionSvc
			.getBankTransactionsByDate(this.transactionCriteria, this.rspCriteria.idPs)
			.subscribe((data) => {
				var vals = data.reverse();
				var dates = [];
				var virementRapp = [];
				var virementNonRapp = [];
				vals.forEach((ele) => {
					dates.push(formatDate(ele[0], 'dd/MM/yy', 'fr-FR'));
					virementRapp.push(ele[2]);
					virementNonRapp.push(ele[3]);
				});

				this.configChartVirementsByDate(dates, virementRapp, virementNonRapp);
			});
	}

	calculateTauxRapprochement() {
		if (this.rspAvecPaiementEtLibelle != null && this.rspAvecPaiementRapproches != null) {
			this.tauxRapp = !isNaN(this.rspAvecPaiementRapproches.count * 100 / this.rspAvecPaiementEtLibelle.count)
				? (this.rspAvecPaiementRapproches.count * 100 / this.rspAvecPaiementEtLibelle.count).toFixed(2)
				: '0';
		}
	}

	calculateTauxGlobal(rspAvecPaiementRapproches, allRsp) {
			this.tauxGlobal = !isNaN(rspAvecPaiementRapproches * 100 / allRsp)
				? (rspAvecPaiementRapproches * 100 / allRsp).toFixed(2)
				: '0';
	}

	getTop10Organismes() {
		this.rspRappSummarySvc.getTop10Organismes(this.rspCriteria).subscribe((data) => {
			var orga = [];
			var montants = [];
			data.forEach((ele) => {
				orga.push(ele[0]);
				montants.push((parseFloat(ele[1]) / 100).toFixed(2));
			});

			this.configChartTop10Organismes(orga, montants);
			//('TOP 10', data);
		});
	}

	getTop10OrganismesByRappAuto() {
		this.rspRappSummarySvc.getTop10OrganismesByRappAuto(this.rspCriteria).subscribe((data) => {
			var orga = [];
			var percent = [];
			data.forEach((ele) => {
				orga.push(ele[0]);
				percent.push(ele[1]);
			});

			this.configChartTop10OrganismesByRappAuto(orga, percent);
			//console.log('TOP 10 RAPP AUTO', data);
		});
	}

	getRapprochementKpi() {
		forkJoin([
			this.rspRappSummarySvc.getRspAvecPaiementEtLibelle(this.rspCriteria),
			this.rspRappSummarySvc.getRspAvecPaiementRapproches(this.rspCriteria),
			this.rspRappSummarySvc.getAllRspByIdPs(this.rspCriteria)
		]).subscribe((data) => {
			if (data) {
				// RSP avec virement rapprochables
				if (data[0]) {
					this.rspAvecPaiementEtLibelle = data[0];
					this.rspAvecPaiementEtLibelle.sum = !isNaN(parseFloat(data[0].sum) / 100)
						? (parseFloat(data[0].sum) / 100).toFixed(2)
						: '0';
				}
				// RSP avec virement rapproché
				if (data[1]) {
					this.rspAvecPaiementRapproches = data[1];
					this.rspAvecPaiementRapproches.sum = !isNaN(parseFloat(data[1].sum) / 100)
						? (parseFloat(data[1].sum) / 100).toFixed(2)
						: '0';
				}
				// all RSP
				if (data[2]) {
					let tauxGlobal = data[2];
					this.calculateTauxGlobal(tauxGlobal.count, tauxGlobal.sum);
				}
				// Taux rapprochement
				this.calculateTauxRapprochement();
			}
		});
	}

	getRspByDateAndStatus() {
		this.rspRappSummarySvc.getRspByDateAndStatus(this.rspCriteria).subscribe((data) => {
			var vals = data.reverse();
			var dates = [];
			var virementRapp = [];
			var virementNonRapp = [];
			vals.forEach((ele) => {
				dates.push(formatDate(ele[0], 'dd/MM/yy', 'fr-FR'));
				virementRapp.push(ele[2]);
				virementNonRapp.push(ele[3]);
			});
			this.configChartVirementsByDate(dates, virementRapp, virementNonRapp);
		});
	}

	private configChartVirementsByDate(dates: any[], vrtRapp: any[], vrtNonRapp: any[]) {
		let canvasB = document.getElementById("chartVirements");
		let chartVirements = new Chart('chartVirements', {
			type: 'bar',
			data: {
				labels: dates,
				datasets: [
					{
						label: 'RSP avec virements rapprochés',
						backgroundColor: '#21b8cf',
						data: vrtRapp
					},
					{
						label: 'RSP avec virements non rapprochés',
						backgroundColor: '#9e9e9e',
						data: vrtNonRapp
					}
				]
			},
			options: {
				title: {
					display: false
				},
				legend: {
					position: 'bottom',
					labels: {
						fontSize: 17
					}
				},
				tooltips: {
					mode: 'index',
					callbacks: {
						label: function(tooltipItem, data) {
							return tooltipItem.yLabel + ' €';
						}
					}
				},
				hover: { mode: null },
				responsive: true,
				scales: {
					xAxes: [
						{
							stacked: true
						}
					],
					yAxes: [
						{
							stacked: true
						}
					]
				}
			}
		});
		this.chartVirements = chartVirements;
		const filterRSP = this.filtersRSP;
		const router = this.router;
		canvasB.onclick = function (b) {
			let slice = chartVirements.getElementAtEvent(b);
			if (!slice.length) {
				return;
			} else {
				const date = slice[0]["_model"].label;
				const [day,month, year] = date.split('/');
				const fullYear = new Date().getFullYear().toString().substr(0,2) + year;
				filterRSP.rsp30Jours = true;
				filterRSP.dateComptableFrom = new Date(+fullYear, month - 1, day);
				filterRSP.dateComptableTo = new Date(+fullYear, month - 1, day);
				sessionStorage['rapproManRspFilter'] = JSON.stringify(filterRSP);
				return router.navigate(['/rapp-manuel']);
			}
		}
	}

	private configChartTop10Organismes(orga: any[], montants: any[]) {
		this.chartTop10Orga = new Chart('chartTop10Orga', {
			type: 'horizontalBar',
			data: {
				labels: orga,
				datasets: [
					{
						data: montants,
						backgroundColor: '#21b8cf'
					}
				]
			},
			options: {
				title: {
					display: false
				},
				legend: {
					display: false
				},
				tooltips: {
					mode: 'index',
					callbacks: {
						label: function(tooltipItem, data) {
							return tooltipItem.xLabel + ' €';
						}
					}
				},
				responsive: true,
				scales: {
					xAxes: [
						{
							stacked: true
						}
					],
					yAxes: [
						{
							stacked: true
						}
					]
				}
			}
		});
	}

	private configChartTop10OrganismesByRappAuto(orga: any[], percent: any[]) {
		this.chartTop10OrgaByRappAuto = new Chart('chartTop10OrgaByRappAuto', {
			type: 'horizontalBar',
			data: {
				labels: orga,
				datasets: [
					{
						data: percent,
						backgroundColor: '#21b8cf'
					}
				]
			},
			options: {
				title: {
					display: false
				},
				legend: {
					display: false
				},
				tooltips: {
					mode: 'index',
					callbacks: {
						label: function(tooltipItem, data) {
							return tooltipItem.xLabel + ' %';
						}
					}
				},
				responsive: true,
				scales: {
					xAxes: [
						{
							stacked: true
						}
					],
					yAxes: [
						{
							stacked: true
						}
					]
				}
			}
		});
	}

	voirRspAvecVirRapprochable(event: MouseEvent,isRapproche? : boolean) {
		const pcConnected  = JSON.parse(localStorage.getItem('current_ps'));
		if (!isNullOrUndefined(this.rspFilter)) {
			event.preventDefault();
			this.rspFilter.avecVirement = true;
			this.rspFilter.statutRsp = '0';
			this.rspFilter.rapproBancaire = isRapproche ? isRapproche : null;
			this.rspFilter.numeroPScomboBox = this.numeroPS;
			this.rspFilter .idPs=pcConnected?.idPs;
			sessionStorage['rspFilter'] = JSON.stringify(this.rspFilter);
			const url = this.router.createUrlTree(['/rsp'], { queryParams: { fromDashboard: true } }).toString();
			window.open(url, '_blank');
			//this.router.navigate([ '/rsp' ]);
		}
	}
	datePeriodeClick(period: number): void {
		if (this.selectedRange == period) {
			this.isCollapsed = false;
		} else {
			this.selectedRange = period;
			this.filterPeriode=period;
			this.changePeriodeFilter();
			this.isCollapsed = false;
		}
	}

	private setLocale() {
		this.localeService.use('fr');
	}
}
