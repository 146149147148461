<div class="modal modal-show fade show" id="modalPslogiciel" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel">
    <div class="modal-dialog modal-xxl" role="document">
        <div class="modal-content">
            <div class="inmodal">

                <div class="modal-header pb-1">
                    <h3 class="modal-title">Rattacher un logiciel</h3>
                    <button class="close" name="btn_display_filter" data-dismiss="modal" type="button" title="Fermer"
                        (click)="closeModal()"><span aria-hidden="true">×</span></button>
                </div>
                <div class="modal-body pb-0">
                    <app-ps-logiciel-existant [idPs]="idPs" [hasEditor]="hasEditor"></app-ps-logiciel-existant>
                </div>
                <div class="modal-footer">
                </div>

            </div>
        </div>
    </div>

</div>