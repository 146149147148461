export class TypeZeroFilterRaw {
	typeEmetteur: string;
	emetteur: string;
	typeDestinataire: string;
	compactage: boolean;
	compactageOui: boolean;
	compactageNon: boolean;
	cryptage: boolean;
	cryptageOui: boolean;
	cryptageNon: boolean;
	regroupement: string;
	numDestinataire: string;
}
