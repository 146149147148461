import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { CONF } from '../constants';
import { OctDataRaw } from '../../data/octDataRaw';

@Injectable({ providedIn: 'root' })
export class OctService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/oct';
	public currentOctSubject: BehaviorSubject<OctDataRaw>;
	constructor(private http: HttpClient) {
		this.currentOctSubject = new BehaviorSubject<OctDataRaw>(null);
	}

	/*getOctList(): Observable<any> {
		return this.http.get<any>(`${this.baseUrl}/list`).pipe(
			map((oct) => {
				return oct;
			})
		);
	}*/
	getAllOctByNumAdeli(numAdeli :String,email :String):Observable<any>{
		return this.http.get<any>(`${this.baseUrl}/search-listOct?numAdeli=`+ numAdeli +`&email=`+email);
	}
	getAllOctByUserSearchFilters(criteria: any): Observable<any> {
		return this.http.post<any>(
			this.baseUrl +
				'/search-list-oct-by-user/',
				criteria
		);
	}
	getOctByIdUser(idUser: String): Observable<any> {
		return this.http.get<any>(`${this.baseUrl}/search-oct?idUser=` + idUser);
	}

	// @TODO: check if used
	getOcts(): Observable<any> {
		return this.http.get<any>(`${this.baseUrl}/list`);
	}

	public get getCurrentOctValue(): OctDataRaw {
		return this.currentOctSubject.value;
	}

	setOctValue(value: OctDataRaw) {
		this.currentOctSubject.next(value);
	}

	getOctById(idOct: any): Observable<any> {
		return this.http.get<any>(this.baseUrl + '/' + idOct);
	}

}
