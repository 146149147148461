<careweb-accordion-group id="multi-filter" [title]="title" [badgeCount]="badgeCount" [isDateRangeValid] = "isDateRangeValid">
    <input
        type="text"
        [id]="getInputID()"
        [name]="property"
        [(ngModel)]="textValue"
        (keyup.enter)="addFilter()"
        (blur)="addFilter()"
    />
    <careweb-filter-label *ngFor="let filter of criterias" (click)="removeFilter(filter)">
        {{ filter }}
    </careweb-filter-label>
</careweb-accordion-group>
