import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CONF } from '../../core/constants';
import { OctDataRaw } from '../../data/octDataRaw';
import { User } from '../../data/userDataRaw';
import {CustomSortDataRow} from "../../data/customSortDatRaw";
import {isNullOrUndefined} from "util";
import {isEmpty} from "lodash-es";

@Injectable({ providedIn: 'root' })
export class UsersService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/user';
	public currentlistOctByUser: BehaviorSubject<Array<OctDataRaw>>;
	constructor(private httpClient: HttpClient) {
		this.currentlistOctByUser = new BehaviorSubject<Array<OctDataRaw>>(null);
	}

	getUsersListByCriteriaByIdOct(criteria: any): Observable<any> {
		return this.httpClient.get<any>(
			this.baseUrl +
				'/list/oct-users?page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				'&idOct=' +
				criteria.idOct +
				this.getSortParameters(criteria.sorts)
		);
	}

	getSortParameters(sorts) {
		if (sorts && sorts.length === 1) {
			const sort = sorts[0].property;
			return '&sort=' + sort + '&direction=' + sorts[0].direction;
		} else {
			// sort par défaut
			return '&sort=nom&direction=DESC';
		}
	}

	updateUserEmail(user: User): Observable<any> {
		return this.httpClient.put<any>(this.baseUrl + '/update/' + user.idUser + '/email', user);
	}

	unblockAccount(user: User): Observable<string> {
		return this.httpClient.post(this.baseUrl + '/unblock/' + user.idUser, user, { responseType: 'text' });
	}

	getUsersByFilterNotPS(criteria: any, user: User): Observable<any> {
		return this.httpClient.post<any>(
			this.baseUrl +
				'/search/not-user-ps/?page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				this.getSortParameters(criteria.sorts),
			user
		);
	}
	getAllOctByUserSearchFilters(criteria: any): Observable<any> {
		return this.httpClient.post<any>(
			this.baseUrl +
				'/search-list-oct-by-user/',
				criteria
		);
	}
	getUsersByFilterAndTypeUserAndNotAttachPs(criteria: any, typeUser, user: User): Observable<any> {
		return this.httpClient.post<any>(
			this.baseUrl +
				'/search/typeUser/not-attach-ps/' +
				criteria.idPs +
				'?page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				'&typeUser=' +
				typeUser +
				this.getSortParameters(criteria.sorts),
			user
		);
	}

	getHotlineUsersList(idOct): Observable<any> {
		return this.httpClient.get<any>(this.baseUrl + '/list/hotline-users/?idOct=' + idOct);
	}

	getAdminAndHotlineUsersList(idOct): Observable<any> {
		return this.httpClient.get<any>(this.baseUrl + '/list/admin-hotline-users/?idOct=' + idOct);
	}

	saveUser(user: User): Observable<User> {
		return this.httpClient.post<any>(this.baseUrl + '/create', user).pipe(catchError(this.handleError));
	}

	update(idUser, user: User): Observable<User> {
		return this.httpClient
			.put<any>(this.baseUrl + '/update/' + idUser + '/oct/', user)
			.pipe(catchError(this.handleError));
	}

	updateUser(idUser, user: User): Observable<User> {
		return this.httpClient.put<any>(this.baseUrl + '/update/' + idUser, user).pipe(catchError(this.handleError));
	}

	getUsersByEmail(email): Observable<User> {
		return this.httpClient.post<User>(this.baseUrl + '/email', email).pipe(catchError(this.handleError));
	}

	sendEmailToUser(idUser): Observable<User> {
		return this.httpClient.get<User>(this.baseUrl + '/sendEmail/'+ idUser);
	}

	handleError(error: HttpErrorResponse) {
		return throwError(error);
	}

	getUserByLogin(login: String): Observable<User> {
		return this.httpClient.get<User>(this.baseUrl + '/login/' + login);
	}

	getUserByIdWithGroup(idUser): Observable<User> {
		return this.httpClient.get<User>(this.baseUrl + '/' + idUser + '/group/');
	}

	getUserById(id: String): Observable<User> {
		return this.httpClient.get<User>(this.baseUrl + '/' + id);
	}

	getListOctByIdUser(id: String): Observable<Array<OctDataRaw>> {
		const ctx = this;
		return this.httpClient.get<User>(this.baseUrl + '/' + id).pipe(
			map((user) => {
				ctx.currentlistOctByUser = new BehaviorSubject<Array<OctDataRaw>>(user.octs);
				return user.octs;
			})
		);
	}

	setCurrentlistOctByUser(octs: Array<OctDataRaw>) {
		this.currentlistOctByUser.next(octs);
	}

	public get getCurrentlistOctByUser(): Array<OctDataRaw> {
		return this.currentlistOctByUser.value;
	}

	contactSupport(email: any, motif: string, desc: string, numeroAdeli: String, oct: String): Observable<void> {
		return this.httpClient.get<void>(`${this.baseUrl}/contact-support?email=${email}
		&motif=${motif}&desc=${desc}&numeroAdeli=${numeroAdeli}&oct=${oct}`);
	}

	verifyActualPassword(idUser, oldPassword: string): Observable<User> {
		return this.httpClient.get<User>(`${this.baseUrl}/verify-password?idUser=${idUser}&oldPassword=${oldPassword}`);
	}

	verifyActualPasswordUsingToken(token: string, login: string, oldPassword: string): Observable<User> {
		return this.httpClient
			.post<User>(`${this.baseUrl}/verify-old-password`, {
				token: token,
				password: oldPassword,
				login: login
			})
			.pipe(map((data) => new User()));
	}

	public getUserStatutById(id: String): Observable<boolean> {
		return this.httpClient.get(this.baseUrl + '/' + id + '/statut').pipe(map((data: boolean) => {return data}), catchError(this.handleError));
	}

	public getMainUsersByIdPs(idPs: number, idUsers: String[]): Observable<User[]> {
		return this.httpClient.post<User[]>(this.baseUrl + '/main/' + idPs, idUsers);
	}

	public sendInvitationMail(email: string): Observable<void> {
		return this.httpClient.get<void>(this.baseUrl + '/send-invitation-mail?email=' + email);
	}

	exportUsers(criteria: any, userDataRaw: User, fields: string[]): Observable<any> {
		const sorts: CustomSortDataRow = criteria.sorts;
		let parsedFilter = JSON.parse(sessionStorage.getItem('userFilter'));
		let oct: boolean = ( !isNullOrUndefined(parsedFilter) && !isEmpty(parsedFilter) );
		return this.httpClient.post<HttpResponse<Blob>>(
			this.baseUrl + '/export?idOct=' + criteria.idOct + this.getSortParameters(sorts) + '&default=' + oct,
			{
				filters: userDataRaw,
				fields: fields
			},
			{
				observe: 'response' as 'body',
				responseType: 'blob' as 'json'
			}
		).pipe(
			map((response: HttpResponse<Blob>): any => {
				return this.getFileInfosFromResponse(response,"Liste-Compte-OCT.xlsx");
			})
		)
	}

	exportUsersByEmail(criteria: any, userDataRaw: User, fields: string[]): Observable<any> {
		const sorts: CustomSortDataRow = criteria.sorts;
		let parsedFilter = JSON.parse(sessionStorage.getItem('userFilter'));
		let oct: boolean = ( !isNullOrUndefined(parsedFilter) && !isEmpty(parsedFilter) );
		return this.httpClient.post<HttpResponse<Blob>>(
			this.baseUrl + '/export-by-email?idOct=' + criteria.idOct + this.getSortParameters(sorts) + '&default=' + oct,
			{
				filters: userDataRaw,
				fields: fields
			},
			{
				observe: 'response' as 'body',
				responseType: 'blob' as 'json'
			}
		);
	}
	getFileInfosFromResponse(response: HttpResponse<Blob>,fileName: string) {
		return {
			file: new Blob([ response.body ], { type: 'application/vnd.ms-excel' }),
			fileName: fileName
		};
	}
}
