import { ColDef, GridOptions, IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import {Component, OnInit, OnDestroy, HostListener} from '@angular/core';
import { Router } from '@angular/router';
import { findIndex, isEmpty, uniqBy } from 'lodash-es';
import { AgGridHelper } from '../../components/ag-grid/ag-grid-helper';
import { gridConvertSort } from '../../components/ag-grid/ag-grid.utils';
import { EditeurDataRaw } from '../../data/editeurDataRaw';
import { EditeurFilterRaw } from '../../data/filters/editeurFilterRaw';
import { FilterItem } from '../../data/filters/filter-item';
import { EditeurPreviewComponent } from './editeur-preview.component';
import { EditeurService } from './services/editeurs.service';
import { isNullOrUndefined } from 'util';
import { RouteHistoryService } from '../../shared/route-history.service';
import { GridDataRaw } from '../../data/gridDataRaw';
import { StorageService } from '../../shared/storage-service';
import {ExportModalComponent, ExportMode} from "../export-modal/export-modal.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import { saveAs } from 'file-saver';
import {TransformService} from "../../core/services/transform.service";
import {ExportState, State} from "../../shared/model/storage.model";
@Component({
	selector: 'careweb-app-editeurs',
	templateUrl: './editeurs.component.html',
	styleUrls: ['./editeurs.component.scss']
})
export class EditorsComponent implements OnInit, OnDestroy {
	initHidden = true;
	showFilter = false;
	filters = new EditeurFilterRaw();
	filterList: FilterItem[];
	gridHelper: AgGridHelper;
	editeurDataRaw: EditeurDataRaw;
	pageNumber: number;
	gridDataRaw: GridDataRaw;
	rowCount: number;
	private openedModal: BsModalRef;
	export: boolean = false;
	exportState: ExportState;
	constructor(
		private editeursSvc: EditeurService,
		private router: Router,
		private routeHistory: RouteHistoryService,
		private storageService: StorageService,
		private modalService: BsModalService,
		private transformService: TransformService
	) {
		this.editeurDataRaw = new EditeurDataRaw();

		//Get grid size from session if already stored
		const sessionPageSize = this.storageService.get('editorsPaginationPageSize', true);
		this.gridDataRaw = new GridDataRaw();
		this.gridDataRaw.size = !isNullOrUndefined(sessionPageSize) ? sessionPageSize : 10;

		this.gridHelper = new AgGridHelper('editors', this.gridDataRaw.size, 10);
	}

	ngOnInit() {
		// verify previous url if not details remove session currentPage
		if (this.routeHistory?.getPreviousUrl()?.indexOf('editeur-details') === -1) {
			sessionStorage['editorsCurrentPage'] = null;
		}

		// Init filters
		const parsedFilter = JSON.parse(sessionStorage.getItem('editeurFilter'));
		// Get filter stored in session if exist
		if (!isEmpty(parsedFilter)) {
			this.filters = Object.assign({}, parsedFilter);
		} else {
			this.filterList = [];
		}

		// this.scrollTopService.setScrollTop();
		this.gridHelper.gridOptions = <GridOptions>(<unknown>{
			defaultColDef: {
				resizable: true,
				suppressMenu: true
			},
			columnDefs: this.getColumnDefs(),
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 10,
			maxBlocksInCache: 0,
			infiniteInitialRowCount: 1,
			paginationPageSize: this.gridDataRaw.size,
			paginateChildRows: true,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			enableCellTextSelection: true,
			localeText: this.gridHelper.getLocaleText(),
			onRowClicked: (params) => this.onRowClicked(params),
			onGridReady: (params) => this.onGridReady(params),
			onGridSizeChanged: (params) => this.onGridSizeChanged(params),
			onColumnMoved: (params) => this.onColumnMoved(params)
		});
	}

	onGridReady(params) {
		this.initFilter();
		const ctx = this;

		this.gridHelper.gridApi = params.api;
		this.gridHelper.gridColumnApi = params.columnApi;
		this.initSorting();
		this.restoreGrid();
		this.setColumnOrder(window.innerWidth);
		this.gridHelper.gridApi.setServerSideDatasource(null);
		this.gridHelper.refreshView();
		const parsedFilter = JSON.parse(sessionStorage.getItem('editeurFilter'));
		if (!isEmpty(parsedFilter)) {
			this.launchSearch();
		} else {
			const dataSource: IServerSideDatasource = {
				getRows: function (paramsRows: IServerSideGetRowsParams) {
					const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
					ctx.editeursSvc
						.getEditeursList({
							page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
							size: ctx.gridHelper.gridApi.paginationGetPageSize(),
							sorts
						})
						.subscribe(
							(data) => {
								ctx.rowCount = data.totalElements;
								ctx.gridHelper.manageNoRowsOverlay(ctx.rowCount);
								paramsRows.success({"rowData": data.content, "rowCount": ctx.rowCount});
								const pageN = Number.parseInt(sessionStorage.getItem('editorsCurrentPage'));
								if (
									!isNullOrUndefined(pageN) &&
									pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
									pageN > 0
								) {
									ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
								}
								sessionStorage['editorsCurrentPage'] = null;
							},
							() => paramsRows.fail()
						);
				}
			};
			this.gridHelper.gridApi.setServerSideDatasource(dataSource);
			this.gridHelper.gridApi.sizeColumnsToFit();
		}
	}
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		let width = event.target.innerWidth;
		this.setColumnOrder(width);
	}
	setColumnOrder(width : any) {
		const columnDefs = this.getColumnDefs();
		if(width<=1177){
			const optionsColumnIndex = columnDefs.findIndex(column => column.field === 'options');
			if (optionsColumnIndex !== -1) {
				columnDefs.splice(0, 0, columnDefs.splice(optionsColumnIndex, 1)[0]);
				this.gridHelper.gridApi.setColumnDefs(columnDefs);
			}
		}else {
			this.gridHelper.gridApi.setColumnDefs(columnDefs);
			this.gridHelper.gridApi.sizeColumnsToFit();
		}
	}
	onGridSizeChanged(params) {
		this.gridHelper.gridApi.sizeColumnsToFit();
	}

	onRowClicked(params) {
		if (!this.gridHelper.isCellTextSelected()) {
			this.router.navigate(['/editeur-details', params.data.idEditeur]);
		}
	}

	toggleFilterDisplay() {
		if (this.initHidden) {
			this.initHidden = false;
			setTimeout(() => {
				this.showFilter = !this.showFilter;
			}, 10);
		} else {
			this.showFilter = !this.showFilter;
		}
	}

	onKeyDown(event) {
		this.launchSearch();
	}

	refresh(): boolean {
		return false;
	}

	restoreGrid() {
		this.gridHelper.restoreGridStatePrefix();
		this.gridDataRaw.size = this.gridHelper.paginationPageSize;
	}

	resetGridState() {
		this.gridHelper.resetGridState();
		this.gridDataRaw.size = this.gridHelper.defaultPaginationPageSize;
		this.initSorting();
		//this.resetAllFilter();
	}

	private getColumnDefs(): ColDef[] {
		return [
			{
				headerName: 'Raison sociale',
				field: 'raisonSociale',
				minWidth: 150,
				lockVisible: true,
				sortable: true
			},
			{
				headerName: 'Code postal',
				field: 'codePostal',
				minWidth: 100,
				lockVisible: true,
				sortable: true,
				cellStyle: this.gridHelper.centerContent()
			},
			{
				headerName: 'Ville',
				field: 'ville',
				minWidth: 100,
				lockVisible: true,
				sortable: true
			},
			{
				headerName: 'Téléphone',
				field: 'telephone',
				minWidth: 100,
				maxWidth: 200,
				lockVisible: true,
				sortable: true,
				valueFormatter: this.formatTelephone,
				cellStyle: this.gridHelper.centerContent()
			},
			{
				headerName: '',
				field: 'options',
				maxWidth: 50,
				minWidth: 50,
				cellStyle: this.gridHelper.centerContent(),
				cellRenderer: EditeurPreviewComponent,
				cellRendererParams: {
					iconClass: 'fa fa-eye',
					redirect: 'editeurDetails'
				}
			}
		];
	}

	private initSorting() {
		const sort = [
			{
				colId: 'raisonSociale',
				sort: 'asc',
				sortIndex: 0
			}
		];
		return this.gridHelper.applyColumnState(sort);
	}

	formatTelephone = (params): string => {
		if (params.data) {
			if (params.value) {
				return params.value.replace(/\s/g, '').replace(/\./gi, '');
			}
		}
	};

	launchSearch(): void {
		const ctx = this;
		this.setFilter();
		this.updateFilterList(this.filters);
		//this.gridHelper.gridApi.refreshInfiniteCache();
		this.gridHelper.gridApi.setServerSideDatasource(null);
		this.gridHelper.refreshView();
		const dataSource: IServerSideDatasource = {
			getRows: function (paramsRows: IServerSideGetRowsParams) {
				const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
				ctx.editeursSvc
					.getEditeursFilteredList(
						{
							page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
							size: ctx.gridHelper.gridApi.paginationGetPageSize(),
							sorts
						},
						ctx.editeurDataRaw
					)
					.subscribe(
						(data) => {
							ctx.rowCount = data.totalElements;
							ctx.gridHelper.manageNoRowsOverlay(ctx.rowCount);
							paramsRows.success({"rowData": data.content, "rowCount": ctx.rowCount});
							const pageN = Number.parseInt(sessionStorage.getItem('editorsCurrentPage'));
							if (
								!isNullOrUndefined(pageN) &&
								pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
								pageN > 0
							) {
								ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
							}
							sessionStorage['editorsCurrentPage'] = null;
						},
						() => paramsRows.fail()
					);
			}
		};

		this.gridHelper.gridApi.setServerSideDatasource(dataSource);
		sessionStorage['editeurFilter'] = JSON.stringify(this.filters);
		// this.gridHelper.gridApi.sizeColumnsToFit();
	}

	resetAllFilter(): void {
		this.initFilter();
		this.filters = new EditeurFilterRaw();
		// this.gridHelper.gridApi.setFilterModel(null);
		// this.gridHelper.gridApi.onFilterChanged();
		this.launchSearch();
		this.editeursSvc.reset();
	}

	setFilter(): void {
		this.filters.ville = this.filters.ville ? this.filters.ville.trim() : null;
		this.editeurDataRaw.ville = this.filters.ville ? this.filters.ville.toUpperCase() : '';
		this.editeurDataRaw.telephone = this.filters.telephone;
		this.editeurDataRaw.codePostal = this.filters.codePostal;
		this.filters.raisonSociale = this.filters.raisonSociale ? this.filters.raisonSociale.trim() : null;
		this.editeurDataRaw.raisonSociale = this.filters.raisonSociale ? this.filters.raisonSociale.toUpperCase() : '';
	}

	exporter(): void {
		const columns: string[] = this.gridHelper.gridColumnApi.getAllDisplayedColumns()
			.filter(column => column.getColDef().headerName != "")
			.map(column => column.getColDef().field);
		if (this.rowCount > 0 && this.rowCount <= 1000) {
			this.exportToExcel(columns);
		} else if (this.rowCount > 1000) {
			this.openedModal = this.modalService.show(ExportModalComponent,  { initialState: {
					nbrElement: this.rowCount
				}, class: 'modal-lg'});
			this.openedModal.content.exportEvent.subscribe((e: any) => {
				if (e.action === ExportMode.EXPORT) {
					// this.exportFileXlsx();
					this.exportToExcel(columns);
				}
				if (e.action === ExportMode.EMAIL) {
					// this.exportFileXlsx();
					this.exportByEmail(columns);
				}
			});
		}
	}

	exportToExcel(columns): void {
		this.export = true;
		this.exportState = {
			message : "Vous allez exporter <b>" + this.transformService.transform(this.rowCount) + " ligne(s)</b>, veuillez patienter quelques instants.",
			state: State.INPROGRESS
		}
		const sorts = gridConvertSort(this.gridHelper.gridSortModel(),[]);
		this.editeursSvc.exportEditeurs(
			{
				page: this.gridHelper.gridApi.paginationGetCurrentPage(),
				size: this.gridHelper.gridApi.paginationGetPageSize(),
				export: false,
				sorts
			},
			this.editeurDataRaw,
			columns
		).subscribe((response) => {
			saveAs(response.file, response.fileName);
			this.exportState = {
				message : "Votre fichier a été téléchargé avec succès.",
				state: State.SUCCESS
			}
		},() => {
			this.exportState = {
				message : "Le téléchargement de votre fichier a rencontré un problème.",
				state: State.FAILED
			}
		});
	}

	exportByEmail(columns) {
		const sorts = gridConvertSort(this.gridHelper.gridSortModel(),[]);
		this.editeursSvc.exportEditeursByEmail(
			{
				page: this.gridHelper.gridApi.paginationGetCurrentPage(),
				size: this.gridHelper.gridApi.paginationGetPageSize(),
				export: false,
				sorts
			},
			this.editeurDataRaw,
			columns
		).subscribe(() => {

		})
	}
	closeAlert() {
		this.export = false;
	}

	initFilter() {
		// Liste des filtres
		this.resetFilter();
		this.updateFilterList(this.filters);
	}

	resetFilter() {
		this.editeursSvc.reset();
	}

	updateFilterList(filters) {
		const ctx = this;
		ctx.filterList = [];
		Object.keys(filters).forEach((key) => {
			if (filters[key] !== null && filters[key] !== '') {
				ctx.filterList.push(ctx.translateFilterLabelValue(key, filters[key]));
			}
		});
		// Remove duplicated ids
		ctx.filterList = uniqBy(ctx.filterList, 'id');
		// filter null
		ctx.filterList = ctx.filterList.filter((item) => {
			return item.id !== null;
		});
	}

	translateFilterLabelValue(key, value) {
		let translatedKey = key;
		let formattedValue = value;
		let formattedKey = key;
		switch (key) {
			case 'raisonSociale':
				translatedKey = 'Raison sociale';
				break;
			case 'telephone':
				translatedKey = 'Téléphone';
				break;
			case 'codePostal':
				translatedKey = 'Code Postal';
				break;
			case 'ville':
				translatedKey = 'Ville';
				break;
			default:
				break;
		}
		return new FilterItem().withId(formattedKey).withLabel(translatedKey).withValue(formattedValue).withValue2(value);
	}

	removeFilter(id) {
		const ctx = this;
		delete ctx.filters[id];
		let currentFilterList = ctx.filterList;
		const index = findIndex(currentFilterList, (filt) => filt.id === id);
		currentFilterList.splice(index, 1);
		ctx.filterList = currentFilterList;
		this.fillFilterObjectFromTagsOnly();
		ctx.launchSearch();
	}

	fillFilterObjectFromTagsOnly() {
		this.filters = new EditeurFilterRaw();
		this.filterList.forEach(filter => {
			this.filters[filter.id] = filter.value2;
		});
	}

	ngOnDestroy() {
		sessionStorage['editeurFilter'] = JSON.stringify(this.filters);
		sessionStorage['editorsCurrentPage'] = this.gridHelper.gridApi.paginationGetCurrentPage();
		this.gridHelper.saveGridStatePrefix();
	}

	onPaginationSizeChange(paginationSize: number): void {
		this.gridHelper.changePaginationSize(paginationSize);
		this.launchSearch();
		this.gridHelper.saveGridStatePrefix();
	}

	onColumnMoved(params: any): void {
		this.gridHelper.saveGridColumnState();
	}
}
