import {HttpClient, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONF } from '../../../core/constants';
import { FseDataRaw } from '../../../data/fseDataRaw';
import { FseDataSetRaw } from '../../../data/fseDataSetRaw';
import { isNullOrUndefined } from 'util';
import {CustomSortDataRow} from "../../../data/customSortDatRaw";
import {map} from "rxjs/operators";

@Injectable({
	providedIn: 'root'
})
export class FseService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/flux/fse';
	res: any;
	constructor(private httpClient: HttpClient) {}

	getFseFilteredListSort(criteria: any): Observable<any> {
		return this.httpClient.get<any>(
			this.baseUrl +
				'/list?page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				'&idOct=' +
				criteria.idOct +
				this.getSortParameters(criteria.sorts)
		);
	}

	getFseFilteredListFiltre(criteria: any, fseDataRaw: FseDataRaw): Observable<any> {
		if (!isNullOrUndefined(criteria.idOct)) {
			fseDataRaw.idOct = criteria.idOct;
		}
		// if(criteria.remboursement == null || criteria.remboursement == undefined){
		// 	criteria.remboursement = false;
		// }
		const sorts: CustomSortDataRow[] = criteria.sorts
		return this.httpClient.post<any>(
			this.baseUrl +
				'/search-filter?page=' +
				criteria.page +
				'&size=' +
				criteria.size ,{
				filters: fseDataRaw,
				sorts: sorts
			}
		);
	}

	searchFsesByIdPsWithDelegues(criteria, fseDataRaw: FseDataRaw) {
		const sorts: CustomSortDataRow[] = criteria.sorts;
		this.res = this.httpClient.post<any>(
			this.baseUrl +
			'/search/ps/' +
			criteria.idPs +
			'/delegues/fse?' +
			'page=' +
			criteria.page +
			'&size=' +
			criteria.size,
			{
				filters: fseDataRaw,
				sorts: sorts
			}
		);
		return this.res;
	}

	getFseByLotId(criteria: any, fseDataRaw: FseDataRaw): Observable<any> {
		return this.httpClient.get<any>(
			this.baseUrl +
				'/search-lot?idLot=' +
				fseDataRaw.idLot +
				'&page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				this.getSortParameters(criteria.sorts)
		);
	}

	getSortParameters(sorts) {
		if (sorts.length === 1) {
			const sort = sorts[0].property;
			return '&sort=' + sort + '&direction=' + sorts[0].direction;
		} else {
			// sort par défaut
			return '&sort=dateReceptionPartition&direction=DESC';
		}
	}
	getSort(sorts)	{
		if (sorts.length === 1) {
			const sort = sorts[0].property;
			return '?sort=' + sort + '&direction=' + sorts[0].direction;
		} else {
			// sort par défaut
			return '?sort=dateReceptionPartition&direction=DESC';
		}
	}

	getFseById(idFacture): Observable<FseDataRaw> {
		return this.httpClient.get<FseDataRaw>(this.baseUrl + '/' + idFacture);
	}

	getFseEnvoyes(searchCriteria: FseDataRaw): Observable<FseDataSetRaw> {
		return this.httpClient.post<FseDataSetRaw>(this.baseUrl + '/getFseByNumPs', searchCriteria);
	}

	getFseGraphDataSet(searchCriteria: FseDataRaw): Observable<FseDataSetRaw> {
		return this.httpClient.post<FseDataSetRaw>(this.baseUrl + '/getFseGraphDataSet', searchCriteria);
	}

	getFseCountPerDay(criteria: any): Observable<any> {
		return this.httpClient.get<any>(this.baseUrl + '/count/daily/?idOct=' + criteria.idOct);
	}

	getFseRcImpayee(searchCriteria: FseDataRaw): Observable<any> {
		return this.httpClient.post<any>(this.baseUrl + '/getFseRcImpayee', searchCriteria);
	}
	getDashboardKpi(searchCriteria: FseDataRaw): Observable<Array<FseDataSetRaw>> {
		return this.httpClient.post<any>(this.baseUrl + '/kpi', searchCriteria);
	}

	getFseRoImpayee(searchCriteria: FseDataRaw): Observable<any> {
		return this.httpClient.post<any>(this.baseUrl + '/getFseRoImpayee', searchCriteria);
	}

	getRepartionRcRo(searchCriteria: FseDataRaw): Observable<any> {
		return this.httpClient.post<any>(this.baseUrl + '/getRepartionRcRo', searchCriteria);
	}

	getFseRcImpayeeAdmin(criteria: FseDataRaw): Observable<FseDataSetRaw> {
		return this.httpClient.post<FseDataSetRaw>(this.baseUrl + '/fseRcImpayeeAdmin', criteria);
	}

	getFseRoImpayeeAdmin(criteria: FseDataRaw): Observable<FseDataSetRaw> {
		return this.httpClient.post<FseDataSetRaw>(this.baseUrl + '/fseRoImpayeeAdmin', criteria);
	}
	exportFses(criteria: any, fsesDataRaw: FseDataRaw, fields: string[]): Observable<any> {
		const sorts: CustomSortDataRow[] = criteria.sorts;
		return this.httpClient.post<HttpResponse<Blob>>(
			this.baseUrl + '/export'
			+this.getSort(sorts),
			{
				filters: fsesDataRaw,
				fields: fields
			},
			{
				observe: 'response' as 'body',
				responseType: 'blob' as 'json'
			}
		).pipe(
			map((response: HttpResponse<Blob>): any => {
				return this.getFileInfosFromResponse(response);
			})
		);
	}
	exportFsesByEmail(criteria: any, fsesDataRaw: FseDataRaw, fields: string[]): Observable<any> {
		const sorts: CustomSortDataRow[] = criteria.sorts;
		return this.httpClient.post<HttpResponse<Blob>>(
			this.baseUrl + '/export-by-email'+this.getSort(sorts),
			{
				filters: fsesDataRaw,
				fields: fields
			},
			{
				observe: 'response' as 'body',
				responseType: 'blob' as 'json'
			}
		);
	}
	getFileInfosFromResponse(response: HttpResponse<Blob>) {
		return {
			file: new Blob([ response.body ], { type: 'application/vnd.ms-excel' }),
			fileName: 'Liste_FSE.xlsx'
		};
	}
}
