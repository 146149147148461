import { RappDetailVrtCellRendererComponent } from './../rapp-detail-vrt-cell-enderer.component';
import { IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { AgGridHelper } from '../../../components/ag-grid/ag-grid-helper';

import { RapprochementComptePsService } from '../rapprochement.service';
import { DatePipe } from '@angular/common';
import { gridConvertSort } from '../../../components/ag-grid/ag-grid.utils';
import { RapprochementDataRaw } from '../../../data/rapprochementDataRaw';
import { RappTransactionFilterRaw } from '../../../data/filters/rappTransactionFilterRaw';
import { RappAutoPreviewcomponent } from '../rapp-auto-preview.component';
import { RappAutoCountComponent } from '../rapp-auto-count.component';
import {findIndex, isEmpty, uniqBy} from 'lodash-es';

import { LotsFilterSvc } from '../../flux/lots/lots-filter.service';
import { FilterItem } from '../../../data/filters/filter-item';

import { ToastrService } from 'ngx-toastr';
import { RappAutoExternalPreviewComponent } from '../rapp-auto-external-preview.component';
import { isNullOrUndefined } from 'util';
import { GridDataRaw } from '../../../data/gridDataRaw';
import { StorageService } from '../../../shared/storage-service';
import { FormatThousandsPipe } from '../../../core/pipes/format-thousands.pipe';
import { ColumnTooltipComponent } from '../../../components/column-tooltip/column-tooltip-component';
import { ExportUtilService } from '../../../core/utilities/export-util.service';
import {ColumnCommentTooltipComponent} from "../../../components/column-tooltip/column-comment-tooltip-component";
import {RapprochementTypeEnum} from "../../../data/RapprochementTypeEnum";
import { AgGridAngular } from 'ag-grid-angular';
import {ExportModalComponent, ExportMode} from "../../export-modal/export-modal.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {saveAs} from 'file-saver';
import {ExportState, State} from "../../../shared/model/storage.model";
import {TransformService} from "../../../core/services/transform.service";

@Component({
    selector: 'app-rapp-valide',
    templateUrl: './rapp-valide.component.html',
    styleUrls: ['./rapp-valide.component.scss']
})
export class RappValideComponent implements OnInit, OnDestroy {
    @ViewChild('gridVrt') gridVrt: AgGridAngular;
    @ViewChild('gridRsp') gridRsp: AgGridAngular;
    gridHelper: AgGridHelper;
    gridHelperRSP: AgGridHelper;
    gridHelperVRT: AgGridHelper;
    datePipe: DatePipe;
    rapprochementDataRaw: RapprochementDataRaw;
    showRappAutoDetail: boolean;
    selectedRow: any;
    nbRowSelected: number;
    montantTotalSelected: string;
    initHidden = true;
    gridDataRaw: GridDataRaw;
    selectedRapprochement: RapprochementDataRaw;
    showFilter = false;
    filters = new RappTransactionFilterRaw();
    filterList: FilterItem[];
    oxlinUserConnected: any;
    userConnected: any;
    showConfirmModal: boolean = false;
    confirmModalTitle: string;
    confirmModalContent: string;
    gridDataRawRsp: GridDataRaw;
    gridDataRawVrt: GridDataRaw;
    montantVrtMinError: boolean = false;
    montantVrtMaxError: boolean = false;
    montantRspMinError: boolean = false;
    montantRspMaxError: boolean = false;
    active = 1;
    static MIN_WIDTH = 1173;
    rowCount: number = 0;
    private openedModal: BsModalRef;
    exportState: ExportState;
    export: boolean = false;
    rspPurger: boolean = false
    loadData: boolean = false
    constructor(
        private rapprochementComptePsService: RapprochementComptePsService,
        private lotsFilterSvc: LotsFilterSvc,
        private toastr: ToastrService,
        private storageService: StorageService,
        private modalService: BsModalService,
        private transformService: TransformService,
        private formatThousands: FormatThousandsPipe
    ) {
        // Body styling
        const body = document.body;
        body.className = 'app';

        //Get grid size from session if already stored
        const sessionPageSize = this.storageService.get('rappValidePaginationPageSize', true);
        this.gridDataRaw = new GridDataRaw();
        this.gridDataRaw.size = !isNullOrUndefined(sessionPageSize) ? sessionPageSize : 10;
        this.gridHelper = new AgGridHelper('rappValide', this.gridDataRaw.size, 10);

        const sessionPageSizeRsp = this.storageService.get('rappValideRspPaginationPageSize', true);
        this.gridDataRawRsp = new GridDataRaw();
        this.gridDataRawRsp.size = !isNullOrUndefined(sessionPageSizeRsp) ? sessionPageSizeRsp : 10;
        this.gridHelperRSP = new AgGridHelper('rappValideRsp', this.gridDataRawRsp.size, 10);

        const sessionPageSizeVrt = this.storageService.get('rappValideVrtPaginationPageSize', true);
        this.gridDataRawVrt = new GridDataRaw();
        this.gridDataRawVrt.size = !isNullOrUndefined(sessionPageSizeVrt) ? sessionPageSizeVrt : 10;
        this.gridHelperVRT = new AgGridHelper('rappValideVrt', this.gridDataRawVrt.size, 10);

        this.selectedRow = null;
        this.rapprochementDataRaw = new RapprochementDataRaw();
        this.nbRowSelected = 0;
        this.montantTotalSelected = null;
    }

    ngOnInit() {
        this.parseFilters();
        this.userConnected = JSON.parse(localStorage.getItem('careweb_user'));
        this.oxlinUserConnected = JSON.parse(localStorage.getItem('current_ps')).rappComptePs;
        // Initiate date pipe format
        this.datePipe = new DatePipe('fr-FR');

        // Init grid options
        this.gridHelper.gridOptions = {
            columnDefs: this.getColumnDefs(),
            defaultColDef: {
                suppressMenu: true,
                resizable: true
            },
            domLayout: 'autoHeight',
            rowHeight: 50,
            headerHeight: 50,
            rowModelType: 'serverSide',
            suppressServerSideInfiniteScroll: false,
            pagination: true,
            cacheBlockSize: 10,
            paginationPageSize: this.gridDataRaw.size,
            infiniteInitialRowCount: 1,
            suppressScrollOnNewData: true,
            suppressPaginationPanel: true,
            suppressContextMenu: true,
            suppressPropertyNamesCheck: true,
            enableCellTextSelection: true,
            suppressDragLeaveHidesColumns: true,
            localeText: this.gridHelper.getLocaleText(),
            components: {
                columnTooltipCellRenderer: ColumnCommentTooltipComponent
            },
            onRowClicked: (params) => this.onDetailClicked(params),
            onGridReady: (params) => this.onGridReady(params),
            // onGridSizeChanged: (params) => this.onGridSizeChanged(params),
            getRowClass: (params) => this.rowStyleMontantDiff(params),
            onColumnMoved: (params) => this.onColumnChanged(params),
        };

    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        let width = event.target.innerWidth;
        this.setColumnOrder(width);
    }
    setColumnOrder(width:any) {
        const columnDefs = this.getColumnDefs();
        if(width <= 1185){
            const columnApi = this.gridHelper.gridColumnApi;
            const optionsColumnIndex = columnDefs.findIndex(column => column.field === 'options');
            if (optionsColumnIndex !== -1) {
                columnApi.moveColumn('options', 0);
                columnApi.moveColumn('unvalidate', 1);
            }
        }else {
            this.gridHelper.gridApi.sizeColumnsToFit();
            this.gridHelper.gridApi.setColumnDefs(columnDefs);
        }
    }

    parseFilters() {
        const parsedFilter = JSON.parse(sessionStorage.getItem('rapproValidFilter'));
        if (!isEmpty(parsedFilter)) {
            this.filters = Object.assign({}, parsedFilter);
        } else {
            this.filterList = [new FilterItem()];
        }
    }

    rowStyleMontantDiff(params) {
        if (params.data != null && params.data.montantTotalRsp && params.data.montantTotalTr && params.data.montantTotalRsp.toFixed(2) != params.data.montantTotalTr.toFixed(2)) {
            return 'row-orange';
        }
    }

    onGridSizeChanged(params) {
        this.gridHelper.gridApi.sizeColumnsToFit();
    }

    onRspGridSizeChanged(params) {
        this.gridHelperRSP.gridApi.sizeColumnsToFit();
    }

    onVrtGridSizeChanged(params) {
        this.gridHelperVRT.gridApi.sizeColumnsToFit();
    }

    onGridReadyRSP(params) {
        if (this.selectedRow != null) {
            this.gridHelperRSP.gridApi = params.api;
            this.gridHelperRSP.gridColumnApi = params.columnApi;
            this.restoreRspGrid();
            this.gridHelperRSP.gridApi.setRowData(this.selectedRow.rsps);
            this.gridHelperRSP.gridApi.sizeColumnsToFit();
        }
    }

    resetGridState() {
        this.gridHelper.resetGridStateRapp();
        this.gridDataRaw.size = this.gridHelper.defaultPaginationPageSize;
    }

    onGridReadyVRT(params) {
        if (this.selectedRow != null) {
            this.gridHelperVRT.gridApi = params.api;
            this.gridHelperVRT.gridColumnApi = params.columnApi;
            this.restoreVrtGrid();
            this.gridHelperVRT.gridApi.setRowData(this.selectedRow.bankTransactions);
            this.gridHelperVRT.gridApi.sizeColumnsToFit();
        }
    }

    onVrtSortChanged(params) {
        this.gridHelperVRT.saveGridSort();
    }

    onRspSortChanged(params) {
        this.gridHelperRSP.saveGridSort();
    }

    onGridReady(params) {
        var width = window.innerWidth;
        this.gridHelper.gridApi = params.api;
        this.gridHelper.gridColumnApi = params.columnApi;
        this.restoreGrid();
        this.setColumnOrder(width);
        let parsedFilter = JSON.parse(sessionStorage.getItem('lotsFilter'));
        if (parsedFilter) {
            Object.keys(parsedFilter).forEach(
                (key) => (parsedFilter[key] === undefined || parsedFilter[key] === null ? delete parsedFilter[key] : {})
            );
        }
        this.launchSearch();
    }

    resetFilter() {
        this.lotsFilterSvc.reset();
    }

    initFilter() {
        // Liste des filtres
        this.resetFilter();
        this.updateFilterList(this.filters);
    }

    resetAllFilter(): void {
        this.initFilter();
        this.filters = new RappTransactionFilterRaw();
        this.launchSearch();
        this.lotsFilterSvc.reset();
        this.montantRspMinError = false;
        this.montantRspMaxError = false;
        this.montantVrtMinError = false;
        this.montantVrtMaxError = false;
    }

    getIdPsEbics() {
        var ebicsUser = JSON.parse(localStorage.getItem('careweb_ebics_user'));
        if(ebicsUser) {
			return ebicsUser.id;
        }
        return null;
    }

    launchSearch(): void {

        this.montantVrtMinError = false;
        this.montantVrtMaxError = false;

        this.montantRspMinError = false;
        this.montantRspMaxError = false;

        // Vérifier les montants et afficher les messages d'erreur si nécessaire
        let montantVrtFrom = parseFloat(String(this.filters.montantTotalTrFrom));
        let montantVrtTo = parseFloat(String(this.filters.montantTotalTrTo));

        if (!isNaN(montantVrtFrom) && !isNaN(montantVrtTo) && montantVrtFrom > montantVrtTo) {
            this.montantVrtMinError = true;
            this.montantVrtMaxError = true;
        } else if ((isNaN(montantVrtFrom) && !isNaN(montantVrtTo)) || (!isNaN(montantVrtFrom) && isNaN(montantVrtTo))) {
            this.montantVrtMinError = false;
            this.montantVrtMaxError = false;
        }

        let montantRspFrom = parseFloat(String(this.filters.montantTotalRspFrom));
        let montantRspTo = parseFloat(String(this.filters.montantTotalRspTo));

        if (!isNaN(montantRspFrom) && !isNaN(montantRspTo) && montantRspFrom > montantRspTo) {
            this.montantRspMinError = true;
            this.montantRspMaxError = true;
        } else if ((isNaN(montantRspFrom) && !isNaN(montantRspTo)) || (!isNaN(montantRspFrom) && isNaN(montantRspTo))) {
            this.montantRspMinError = false;
            this.montantRspMaxError = false;
        }
        if (!this.montantRspMinError && !this.montantRspMaxError
            && !this.montantVrtMinError && !this.montantVrtMaxError) {
            const ctx = this;
            this.rapprochementDataRaw = new RapprochementDataRaw();

            this.rapprochementDataRaw.valide = 1;

            this.rapprochementDataRaw.idPs = this.getPsId();

            this.rapprochementDataRaw.idRappComptePs = this.getIdRappComptePs();
            this.rapprochementDataRaw.idEbicsPs = this.getIdPsEbics();

            this.updateFilterList(this.filters);

            this.rapprochementDataRaw.periode = this.filters.periode;
            this.rapprochementDataRaw.label = this.filters.label ? this.filters.label.trim() : null;
            this.rapprochementDataRaw.organismeLibelle = this.filters.organismeLibelle
                ? this.filters.organismeLibelle
                : null;
            this.rapprochementDataRaw.montantTotalRspExacte = this.filters.montantTotalRspExacte;
            this.rapprochementDataRaw.montantTotalRspFrom = this.filters.montantTotalRspFrom;
            this.rapprochementDataRaw.montantTotalRspTo = this.filters.montantTotalRspTo;
            this.rapprochementDataRaw.montantTotalTrFrom = this.filters.montantTotalTrFrom;
            this.rapprochementDataRaw.montantTotalTrExacte = this.filters.montantTotalTrExacte;
            this.rapprochementDataRaw.montantTotalTrTo = this.filters.montantTotalTrTo;
            this.rapprochementDataRaw.montantDiff = isNullOrUndefined(this.filters.montantDiff)
                ? null
                : this.filters.montantDiff;
            this.rapprochementDataRaw.idOrganisme = this.filters.idOrganisme;
            this.rapprochementDataRaw.commentaire = this.filters.commentaire;
            this.rapprochementDataRaw.libelleVirement1 = this.filters.libelleVirement1 || null;
            this.rapprochementDataRaw.libelleVirement2 = this.filters.libelleVirement1 || null;

            Object.keys(this.rapprochementDataRaw).forEach((key) => {
                if (isNullOrUndefined(this.rapprochementDataRaw[key]) || this.rapprochementDataRaw[key] === '') {
                    delete this.rapprochementDataRaw[key];
                }
            });

            const dataSource: IServerSideDatasource = {
                getRows: function (paramsRows: IServerSideGetRowsParams) {
                    const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);

                    ctx.rapprochementComptePsService
                        .getRapprochementsFilteredList(
                            {
                                page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
                                size: ctx.gridHelper.gridApi.paginationGetPageSize(),
                                sorts
                            },
                            ctx.rapprochementDataRaw
                        )
                        .subscribe(
                            (data) => {
                                ctx.rowCount = data.totalElements;
                                ctx.gridHelper.manageNoRowsOverlay(ctx.rowCount);
                                paramsRows.success({"rowData": data.content, "rowCount": ctx.rowCount});
                            },
                            () => paramsRows.fail()
                        );
                }
            };
            this.gridHelper.gridApi.setServerSideDatasource(dataSource);
            sessionStorage['rapproValidFilter'] = JSON.stringify(this.filters);
        }
    }

    toggleFilterDisplay() {
        let width =window.innerWidth;
        if (this.initHidden) {
            this.initHidden = false;
            setTimeout(() => {
                this.showFilter = !this.showFilter;
            }, 10);
        } else {
            this.showFilter = !this.showFilter;
            if(!this.showFilter && width>1800){
                setTimeout(() => {
                    this.gridHelper.gridApi.sizeColumnsToFit();
                }, 200);
            }
        }
    }

    restoreGrid() {
        this.gridHelper.restoreGridStatePrefix();
        this.gridDataRaw.size = this.gridHelper.paginationPageSize;
    }

    restoreRspGrid() {
        if(!isNullOrUndefined(this.gridHelperRSP)){
            this.gridHelperRSP.restoreGridStatePrefix();
        }
        this.gridDataRawRsp.size = this.gridHelperRSP.paginationPageSize;
    }

    restoreVrtGrid() {
        this.gridHelperVRT.restoreGridStatePrefix();
        this.gridDataRawVrt.size = this.gridHelperVRT.paginationPageSize;

    }

    refresh() {
        this.gridHelper.gridApi.deselectAll();
    }

    onDetailClicked(params) {
        this.loadData=false;
        if (!this.gridHelper.isCellTextSelected() && !this.showRappAutoDetail && !(
            this.gridHelper?.gridApi?.getFocusedCell()?.column.getColId() ===
            'unvalidate')) {
            this.selectedRow = params.data;

            this.showRappAutoDetail = true;
            this.gridHelperRSP = new AgGridHelper('rappValideRsp', this.gridDataRawRsp.size, 10);
            this.gridHelperVRT = new AgGridHelper('rappValideVrt', this.gridDataRawVrt.size, 10);

            // Init grid options
            this.gridHelperRSP.gridOptions = {
                columnDefs: this.getColumnRSPDefs(),
                defaultColDef: {
                    resizable: true,
                    suppressMenu: true
                },
                domLayout: 'autoHeight',
                rowHeight: 50,
                headerHeight: 50,
                pagination: true,
                cacheBlockSize: 5,
                paginationPageSize: 10,
                infiniteInitialRowCount: 1,
                suppressScrollOnNewData: true,
                suppressPaginationPanel: true,
                suppressContextMenu: true,
                suppressPropertyNamesCheck: true,
                angularCompileHeaders: true,
                suppressDragLeaveHidesColumns: true,
                localeText: this.gridHelper.getLocaleText(),
                onGridReady: (params) => this.onGridReadyRSP(params),
                // onGridSizeChanged: (params) => this.onRspGridSizeChanged(params),
                onSortChanged: (params) => this.onRspSortChanged(params),
            };

            // Init grid options
            this.gridHelperVRT.gridOptions = {
                columnDefs: this.getColumnVRTDefs(),
                defaultColDef: {
                    resizable: true,
                    suppressMenu: true
                },
                domLayout: 'autoHeight',
                rowHeight: 50,
                headerHeight: 50,
                pagination: true,
                cacheBlockSize: 5,
                paginationPageSize: 10,
                infiniteInitialRowCount: 1,
                suppressScrollOnNewData: true,
                suppressPaginationPanel: true,
                suppressContextMenu: true,
                suppressPropertyNamesCheck: true,
                masterDetail: true,
                suppressDragLeaveHidesColumns: true,
                detailCellRenderer: 'myDetailCellRenderer',
                components: {
                    columnTooltipCellRenderer: ColumnTooltipComponent,
                    myDetailCellRenderer: RappDetailVrtCellRendererComponent,
                },
                localeText: this.gridHelper.getLocaleText(),
                onGridReady: (params) => this.onGridReadyVRT(params),
                // onGridSizeChanged: (params) => this.onVrtGridSizeChanged(params),
                onSortChanged: (params) => this.onVrtSortChanged(params),
            };
            // load rapp details 
            this.rapprochementComptePsService.getRapprochementById(this.selectedRow.idRapprochement).subscribe((res)=> {
                this.selectedRow.rsps = res.rsps;
                this.selectedRow.bankTransactions = res.bankTransactions;
                this.restoreRspGrid();
                if(this.selectedRow.nbRsp>0 &&  isNullOrUndefined(this.selectedRow.rsps)){
                    this.rspPurger=true;
                    this.loadData=true;
                }else {
                    this.rspPurger= false;
                    this.loadData=true;
                }
                this.gridHelperRSP.gridApi?.setRowData(this.selectedRow.rsps);
                this.gridHelperRSP.gridApi?.sizeColumnsToFit();

                this.gridHelperVRT.gridApi.setRowData(this.selectedRow.bankTransactions);
                this.gridHelperVRT.gridApi.sizeColumnsToFit();
            })

        }
    }

    onDetachRappClicked(params) {
        this.showRappAutoDetail = false;
        if (params.data.commentaire === 'Toujours pointer' && params.data.pointerKeyword) {
            this.selectedRapprochement = params.data;
            this.confirmModalTitle = 'Vous allez supprimer un pointage automatisé';
            this.confirmModalContent = `En cliquant sur "Valider" vous allez supprimer l'association automatique "Toujours pointer"
                qui a généré ce rapprochement. Tous les pointages (passés et futurs) qui ont été effectués automatiquement par cette
                règle seront supprimés.`;
            this.showConfirmModal = true;
        } else if (params.data.commentaire === 'Toujours solder') {
            this.selectedRapprochement = params.data;
            this.confirmModalTitle = 'Vous allez supprimer un solde automatisé';
            this.confirmModalContent = `En cliquant sur "Valider" vous allez supprimer l'association automatique "Toujours solder" qui a généré ce rapprochement.
            Tous les soldes (passés et futurs) qui ont été effectués automatiquement par cette règle seront supprimés.`;
            this.showConfirmModal = true;
        } else {
            params.data.idPs = this.getPsId();
            this.rapprochementComptePsService.deleteRapprochement(params.data).subscribe((data) => {
                this.toastr.success('Détachement effectué avec succès', 'Rapprochement validé');
                this.launchSearch();
            });
        }
    }

    public closeModalRappAutoDetail() {
        if (this.gridRsp) {
            this.gridRsp.api.destroy();
        }
        if (this.gridVrt) {
            this.gridVrt.api.destroy();
        }
        this.gridHelperRSP.gridApi?.destroy();
        this.gridHelperVRT.gridApi.destroy();
        this.showRappAutoDetail = false;
    }

    private getColumnRSPDefs() {
        const columnDefs: any[] = [
            {
                headerName: 'N° lot',
                minWidth: 100,
                field: 'numLot',
                cellStyle: this.gridHelper.centerContent(),
                cellRenderer: RappAutoExternalPreviewComponent,
                sortable: true,
                sort: 'desc'
            },
            {
                headerName: 'N° FSE',
                minWidth: 100,
                field: 'numeroFacture',
                cellStyle: this.gridHelper.centerContent(),
                cellRenderer: RappAutoExternalPreviewComponent,
                sortable: true
            },
            {
                headerName: 'Montant AMO',
                minWidth: 100,
                field: 'montantFactureRo',
                cellStyle: this.gridHelper.rightContent(),
                valueFormatter: this.formatCurrency,
                sortable: true
            },
            {
                headerName: 'Montant AMO payé',
                minWidth: 150,
                field: 'montantRo',
                cellStyle: this.gridHelper.rightContent(),
                valueFormatter: (params) => this.gridHelper.formatWithSigne(params.value,params.data.signe),
                sortable: true
            },
            {
                headerName: 'Montant AMC',
                minWidth: 100,
                field: 'montantFactureRc',
                cellStyle: this.gridHelper.rightContent(),
                valueFormatter: this.formatCurrency,
                sortable: true
            },
            {
                headerName: 'Montant AMC payé',
                minWidth: 150,
                field: 'montantRc',
                cellStyle: this.gridHelper.rightContent(),
                valueFormatter: (params) => this.gridHelper.formatWithSigne(params.value,params.data.signe),
                sortable: true
            },
            {
                headerName: 'Libellé organisme',
                minWidth: 100,
                field: 'libelleOrganisme',
                sortable: true
            },
            {
                headerName: 'Date réception',
                minWidth: 130,
                field: 'dateReception',
                cellStyle: this.gridHelper.centerContent(),
                valueFormatter: this.formatDate,
                sortable: true
            },
            {
                headerName: 'Libellé virement 1',
                minWidth: 250,
                field: 'libelleVirement1',
                sortable: true
            },
            {
                headerName: 'Libellé virement 2',
                minWidth: 250,
                field: 'libelleVirement2',
                sortable: true
            }
        ];

        return columnDefs;
    }

    private getColumnVRTDefs() {
        const columnDefs: any[] = [
            {
                headerName: 'Libellé',
                field: 'label',
                cellRenderer: 'agGroupCellRenderer',
                cellRendererParams: {
                    suppressDoubleClickExpand: true,
                    innerRenderer: 'columnTooltipCellRenderer'
                },
                minWidth: 700,
                sortable: true
            },
            {
                headerName: 'Montant',
                minWidth: 180,
                field: 'montant',
                cellStyle: this.gridHelper.rightContent(),
                valueFormatter: this.formatCurrencyRapp,
                sortable: true
            },
            {
                headerName: 'Date opération',
                minWidth: 180,
                field: 'dateComptable',
                cellStyle: this.gridHelper.centerContent(),
                valueFormatter: this.formatDate,
                sortable: true,
                sort: 'desc'
            }
        ];

        return columnDefs;
    }

    private getColumnDefs() {
        const columnDefs: any[] = [

            {
                headerName: 'Avis de réception comptable (RSP)',
                headerClass: 'ag-header-group-parent',
                children: [
                    this.getCount('nbRsp'),
                    {
                        headerName: 'Date comptable',
                        field: 'dateReceptionRsp',
                        minwidth: 100,
                        maxWidth: 150,
                        cellStyle: this.gridHelper.centerContent(),
                        valueFormatter: this.formatDate,
                        sortable: true
                    },
                    {
                        headerName: 'Organismes',
                        field: 'organismeLibelle',
                        minwidth: 110,
                        sortable: true
                        //cellRenderer: innerCellFormatter,
                    },
                    {
                        headerName: 'Montant',
                        field: 'montantTotalRsp',
                        minwidth: 150,
                        maxWidth: 200,
                        cellStyle: this.formatCellRspVrt(), //this.gridHelper.rightContent(),
                        valueFormatter: this.formatCurrencyRapp,
                        sortable: true
                    }
                ]
            },
            {
                headerName: 'Virements banque (VRT)',
                headerClass: 'ag-header-group-parent',
                children: [
                    this.getCount('nbTransactions'),
                    {
                        headerName: 'Montant',
                        field: 'montantTotalTr',
                        minwidth: 150,
                        maxWidth: 200,
                        cellStyle: this.gridHelper.rightContent(),
                        valueFormatter: this.formatCurrencyRapp,
                        sortable: true
                    },
                    {
                        headerName: 'Libellé',
                        field: 'label',
                        cellRenderer: 'agGroupCellRenderer',
                        cellRendererParams: {
                            suppressDoubleClickExpand: true,
                            innerRenderer: 'columnTooltipCellRenderer'
                        },
                        minwidth: 150,
                        sortable: true
                    },
                    {
                        headerName: 'Date opération',
                        field: 'dateComptableTr',
                        minwidth: 100,
                        maxWidth: 150,
                        cellStyle: this.formatCellDateOperation(),
                        valueFormatter: this.formatDate,
                        sortable: true
                    }
                ]
            },
            {
                headerName: 'Date valide',
                field: 'dateValide',
                minwidth: 100,
                maxWidth: 150,
                valueFormatter: this.formatDate,
                headerClass: 'ag-header-merge',
                cellStyle: this.formatCellDateOperation(),
                sortable: true,
                sort: 'desc'
            },
            {
                headerName: 'Date rapp.',
                field: 'dateRapprochement',
                minwidth: 100,
                maxWidth: 150,
                cellStyle: this.gridHelper.centerContent(),
                valueFormatter: this.formatDate,
                sortable: true
            },
            {
                headerName: 'Commentaire',
                field: 'commentaire',
                cellRenderer: 'agGroupCellRenderer',
                cellRendererParams: {
                    suppressDoubleClickExpand: true,
                    innerRenderer: 'columnTooltipCellRenderer'
                },
                valueGetter : params => {
                    if(params.data.pointerKeyword !=null){
                        return params.data.commentaire +' : ' + params.data.pointerKeyword;
                    }
                    return params.data.commentaire;
                },
                minWidth: 100,
                maxWidth: 350,
                sortable: true,
            },
            this.dissociateRapprochementAuto(),
            this.getDetailsRapprochement()
        ];
        return columnDefs;
    }

    formatCellRspVrt() {
        return { textAlign: 'right', borderRight: 'solid 1px #d9dcde' };
    }

    formatCellDateOperation() {
        return { textAlign: 'center', borderRight: 'solid 1px #d9dcde' };
    }

    dissociateRapprochementAuto() {
        return {
            headerName: '',
            field: 'unvalidate',
            suppressCellSelection: true,
            minwidth: 50,
            maxWidth: 50,
            headerClass: 'ag-header-merge',
            cellRenderer: RappAutoPreviewcomponent,
            onCellClicked: (params) => this.onDetachRappClicked(params),
            lockVisible: true,
            cellStyle: this.gridHelper.centerContent()
        };
    }

    getDetailsRapprochement() {
        return {
            headerName: '',
            field: 'options',
            suppressCellSelection: true,
            minwidth: 50,
            maxWidth: 50,
            headerClass: 'ag-header-merge',
            cellRenderer: RappAutoPreviewcomponent,
            onCellClicked: (params) => this.onDetailClicked(params),
            lockVisible: true,
            cellStyle: this.gridHelper.centerContent()
        };
    }

    getCount(field: string) {
        return {
            headerName: 'Nb.',
            field: field,
            suppressCellSelection: true,
            minWidth: 50,
            maxWidth: 80,
            headerClass: 'ag-header-merge',
            cellRenderer: RappAutoCountComponent,
            onCellClicked: (params) => this.onDetailClicked(params),
            lockVisible: true,
            cellStyle: this.gridHelper.centerContent(),
            sortable: true
        };
    }

    formatDate = (params): string => {
        if (params.value != 'MULTI' && params.value != 'PAS DE RSP')
            return this.datePipe.transform(params.value, 'shortDate');
        else return params.value;
    };

    formatCurrencyRapp = (params): string => {
        if (params.data) {
            if (params.colDef.field === 'montantTotalTr' && params.data.rapprochementAuto === RapprochementTypeEnum.SOLDE) {
                return '';
            }
            return this.formatThousands.transform(params.value);
        }
    };

    formatCurrency = (params): string => {
        if (params.data) {
            return this.formatThousands.transform(parseFloat(params.value) / 100);
        }
    };

    updateFilterList(filters) {
        const ctx = this;
        ctx.filterList = [];
        Object.keys(filters).forEach((key) => {
            if (filters[key] !== null && filters[key] !== '') {
                ctx.filterList.push(ctx.translateFilterLabelValue(key, filters[key]));
            }
        });
        // Remove duplicated ids
        ctx.filterList = uniqBy(ctx.filterList, 'id');
        // filter null
        ctx.filterList = ctx.filterList.filter((item) => {
            return item.id != null;
        });
    }

    translateFilterLabelValue(key, value) {
        let translatedKey = key;
        let formattedValue = value;
        let formattedKey = key;
        switch (key) {
            case 'label':
                translatedKey = 'Libellé virement contient';
                break;
            case 'organismeLibelle':
                translatedKey = 'Libellé organisme contient';
                break;
            case 'periode':
                translatedKey = 'Période de rapprochement';
                formattedValue = value + ' derniers jours';
                break;
            case 'montantTotalRspExacte':
                translatedKey = 'Montant RSP';
                formattedValue = value + ' €';
                break;
            case 'montantTotalRspFrom':
                translatedKey = 'Montant RSP supérieur ou égal à';
                formattedValue = value + ' €';
                break;
            case 'montantTotalRspTo':
                translatedKey = 'Montant RSP inférieur ou égal à';
                formattedValue = value + ' €';
                break;
            case 'montantTotalTrExacte':
                translatedKey = 'Montant VRT ';
                formattedValue = value + ' €';
                break;
            case 'montantTotalTrFrom':
                translatedKey = 'Montant VRT supérieur ou égal à';
                formattedValue = value + ' €';
                break;
            case 'montantTotalTrTo':
                translatedKey = 'Montant VRT inférieur ou égal à';
                formattedValue = value + ' €';
                break;
            case 'montantDiff':
                translatedKey = 'Montant diff. RSP/VRT';
                formattedValue = value ? 'Oui' : 'Non';
                break;
            case 'idOrganisme':
                translatedKey = 'Code organisme émetteur';
                break;
            case 'libelleVirement1':
                translatedKey = 'Libellé de virement';
                break;
            default:
                break;
        }
        return new FilterItem().withId(formattedKey).withLabel(translatedKey).withValue(formattedValue).withValue2(value);
    }

    onKeyDown(event) {
        this.launchSearch();
    }

    removeFilter(id) {
        const ctx = this;
        delete ctx.filters[id];
        let currentFilterList = ctx.filterList;
        const index = findIndex(currentFilterList, (filt) => filt.id === id);
        currentFilterList.splice(index, 1);
        ctx.filterList = currentFilterList;
        this.fillFilterObjectFromTagsOnly();
        ctx.launchSearch();
    }

    fillFilterObjectFromTagsOnly() {
        this.filters = new RappTransactionFilterRaw();
        this.filterList.forEach(filter => {
            this.filters[filter.id] = filter.value2;
        });
    }

    ngOnDestroy() {
        sessionStorage['rapproValidFilter'] = JSON.stringify(this.filters);
        this.gridHelper.saveGridStatePrefix();
        this.gridHelperRSP.saveGridStatePrefix();
        this.gridHelperVRT.saveGridStatePrefix();
    }

    onPaginationSizeChange(paginationSize: number): void {
        this.gridHelper.changePaginationSize(paginationSize);
        this.launchSearch();
        this.gridHelper.saveGridStatePrefix();
    }

    onColumnChanged(params: any): void {
        this.gridHelper.saveGridColumnState();
    }

    /* ---------------- CONFIRM MODAL -------------- */
    public showModal(showModal: boolean): void {
        this.showConfirmModal = showModal;
    }

    public confirmModal(): void {
        if (this.selectedRapprochement !== null && this.selectedRapprochement !== undefined) {
            this.selectedRapprochement.idPs = this.getPsId();
            this.rapprochementComptePsService.deleteRapprochement(this.selectedRapprochement).subscribe((data) => {
                this.showModal(false);
                this.toastr.success('Détachement effectué avec succès', 'Rapprochement validé');
                this.launchSearch();
            }, (error) => {
                this.showModal(false);
                this.selectedRapprochement = null;
            });
        }
    }

    exportCsv() {
        let ctx = this;
        this.rapprochementDataRaw = new RapprochementDataRaw();
        this.rapprochementDataRaw.valide = 1;
        this.rapprochementDataRaw.idRappComptePs = this.getIdRappComptePs();
        this.rapprochementDataRaw.periode = this.filters.periode;
        this.rapprochementDataRaw.label = this.filters.label ? this.filters.label.trim() : null;
        this.rapprochementDataRaw.notes = this.filters.notes ? this.filters.notes.trim() : null;
        this.rapprochementDataRaw.organismeLibelle = this.filters.organismeLibelle ? this.filters.organismeLibelle : null;
        this.rapprochementDataRaw.montantTotalRspExacte = this.filters.montantTotalRspExacte;
        this.rapprochementDataRaw.montantTotalRspFrom = this.filters.montantTotalRspFrom;
        this.rapprochementDataRaw.montantTotalRspTo = this.filters.montantTotalRspTo;
        this.rapprochementDataRaw.montantTotalTrExacte = this.filters.montantTotalTrExacte;
        this.rapprochementDataRaw.montantTotalTrFrom = this.filters.montantTotalTrFrom;
        this.rapprochementDataRaw.montantTotalTrTo = this.filters.montantTotalTrTo;
        this.rapprochementDataRaw.montantDiff = isNullOrUndefined(this.filters.montantDiff) ? null : this.filters.montantDiff;
        this.rapprochementDataRaw.idOrganisme = this.filters.idOrganisme;
        this.rapprochementDataRaw.commentaire = this.filters.commentaire;
        this.rapprochementDataRaw.libelleVirement1 = this.filters.libelleVirement1 || null;
        this.rapprochementDataRaw.libelleVirement2 = this.filters.libelleVirement1 || null;

        Object.keys(this.rapprochementDataRaw).forEach((key) => {
            if (isNullOrUndefined(this.rapprochementDataRaw[key]) || this.rapprochementDataRaw[key] === '') {
                delete this.rapprochementDataRaw[key];
            }
        });

        let exportFile = new ExportUtilService();
        const sorts = gridConvertSort(this.gridHelper.gridSortModel(), []);
        const columnKeys = this.gridHelper.gridColumnApi.getColumnState().filter(column => {
            return (column.colId != 'options');
        }).map(column => {
            return column.colId;
        });
        let headers: Array < String > = [];
        let map = this.getColumnsAndFieldDataRappValide(columnKeys, this.rapprochementDataRaw);
        for (let key of map.keys()) {
            headers.push(key)
        }
        exportFile.setNameSheet('Rapprochement_valide');
        exportFile.setHeadersToExport(headers);
        exportFile.setFontHeaderSize(13, false);
        ctx.rapprochementComptePsService
            .exportRapprochementsListValide({
                    sorts
                },
                ctx.rapprochementDataRaw
            )
            .subscribe(dataSource => {
                dataSource.forEach((data) => {
                    let map = this.getColumnsAndFieldDataRappValide(columnKeys, data);
                    let jsonObject = [];
                    map.forEach((value, key) => {
                        jsonObject[key] = value
                    });
                    exportFile.setRowData(jsonObject);
                    exportFile.SetWidthBeetwenColumns(5);
                })
                exportFile.ExportData("Rapprochements-validés")
            });
    }
    exportFileCsv() {
        const columns = this.gridHelper.gridColumnApi.getAllDisplayedColumns()
            .filter(column => column.getColDef().headerName != "")
            .map(column => column.getColDef().field);
        if (this.rowCount > 0 && this.rowCount <= 1000) {
            this.exportToExcel(columns);
        } else if (this.rowCount > 1000) {
            this.openedModal = this.modalService.show(ExportModalComponent,  { initialState: {
                    nbrElement: this.rowCount
                }, class: 'modal-lg'});
            this.openedModal.content.exportEvent.subscribe((e: any) => {
                if (e.action === ExportMode.EXPORT) {
                    this.exportToExcel(columns);
                }
                if (e.action === ExportMode.EMAIL) {
                    this.exportByEmail(columns);
                }
            });
        }
    }

    exportToExcel(columns) {
        const sorts = gridConvertSort(this.gridHelper.gridSortModel(), []);
        this.export = true;
        this.exportState = {
            message : "Vous allez exporter <b>" + this.transformService.transform(this.rowCount) + " ligne(s)</b>, veuillez patienter quelques instants.",
            state: State.INPROGRESS
        }
        this.rapprochementComptePsService.exportRapprochementsValide(
            {
                sorts: sorts
            },
            this.rapprochementDataRaw,
            columns
        ).subscribe((response)=> {
            saveAs(response.file, response.fileName);
            this.exportState = {
                message : "Votre fichier a été téléchargé avec succès.",
                state: State.SUCCESS
            }
        },() => {
            this.exportState = {
                message : "Le téléchargement de votre fichier a rencontré un problème.",
                state: State.FAILED
            }
        });
    }

    exportByEmail(columns) {
        const sorts = gridConvertSort(this.gridHelper.gridSortModel(), []);
        this.rapprochementComptePsService.exportRapprochementByEmail(
            {
                sorts: sorts
            },
            this.rapprochementDataRaw,
            columns
        ).subscribe(() => {

        });
    }

    closeAlert() {
        this.export = false;
    }

    formatCurrencyExport(params: number, rapprochementAuto): string {
        if (params === 0 && rapprochementAuto === 'Solde') {
            return ''
        }
        return this.formatThousands.transform(params);
    }

    formatDateExport(params): string {
        if (params != 'MULTI' && params != 'PAS DE RSP')
            return this.datePipe.transform(params, 'shortDate');
        else return params;
    }

    getColumnsAndFieldDataRappValide(columnKeys: string[], dataFields ? : any) {
        let data = new Map();
        columnKeys.forEach(column => {
            switch (column) {
                case "dateValide":
                    data.set("Date valide", this.formatDateExport(dataFields.dateValide));
                    break;
                case "nbRsp":
                    data.set("Nombre de RSP", dataFields.nbRsp != null ? dataFields.nbRsp > 1 ? dataFields.nbRsp : '' : '');
                    break;
                case "dateReceptionRsp":
                    data.set("Date comptable RSP", this.formatDateExport(dataFields.dateReceptionRsp));
                    break;
                case "organismeLibelle":
                    data.set("Organismes", dataFields.organismeLibelle);
                    break;
                case "montantTotalRsp":
                    data.set("Montant RSP", this.formatCurrencyExport(dataFields.montantTotalRsp, dataFields.label));
                    break;
                case "nbTransactions":
                    data.set("Nombre de VRT", dataFields.nbTransactions != null  ? dataFields.nbTransactions > 1 ? dataFields.nbTransactions : '' : '');
                    break;
                case "dateRapprochement":
                    data.set("Date rapp.", this.formatDateExport(dataFields.dateRapprochement));
                    break;
                case "montantTotalTr":
                    data.set("Montant VRT", this.formatCurrencyExport(dataFields.montantTotalTr, dataFields.label));
                    break;
                case "label":
                    data.set("Libellé", dataFields.label);
                    break;
                case "dateComptableTr":
                    data.set("Date opération VRT", this.formatDateExport(dataFields.dateComptableTr));
                    break;
                case "commentaire":
                    data.set("Commentaire", dataFields.commentaire);
                    break;
            }
        })
        return data;
    }

    onRspPaginationSizeChange(paginationSize: number): void {
        this.gridHelperRSP.changePaginationSize(paginationSize);
        this.gridHelperRSP.gridApi.setRowData(this.selectedRow.rsps);
        this.gridHelperRSP.saveGridStatePrefix();
    }

    onVrtPaginationSizeChange(paginationSize: number): void {
        this.gridHelperVRT.changePaginationSize(paginationSize);
        this.gridHelperVRT.gridApi.setRowData(this.selectedRow.bankTransactions);
        this.gridHelperVRT.saveGridStatePrefix();
    }

    resetRspGridState() {
        this.gridHelperRSP.resetGridState();
        this.gridDataRawRsp.size = this.gridHelperRSP.defaultPaginationPageSize;
    }

    resetVrtGridState() {
        this.gridHelperVRT.resetGridState();
        this.gridDataRawVrt.size = this.gridHelperVRT.defaultPaginationPageSize;
    }

    onChoiceChange($event: any, type: string): void {
        if (type === 'montantTotalRsp') {
            if ($event.filterChoice === "EXACTE") {
                this.filters[type + 'From'] = null;
                this.filters[type + 'To'] = null;

            } else {
                this.filters[type +'Exacte'] = null;
            }
        }else {
            if ($event.filterChoice === "EXACTE") {
                this.filters[type + 'From'] = null;
                this.filters[type + 'To'] = null;
            } else {
                this.filters[type +'Exacte'] = null;
            }
        }
    }

    formatRoRc(params, key) {
        let total = params?.signe === 0 ? params?.cumulMontantFacture * -1 : params?.cumulMontantFacture
        let ro = params?.montantRo ? parseFloat(params.montantRo) / 100 : 0
        let rc = params?.montantRc ? parseFloat(params.montantRc) / 100 : 0
        switch (key) {
            case 'montantRc': { return ro + rc * -1 === total || ro * -1 + rc * -1 === total ? (rc * -1).toString() : rc.toString(); break; }
            case 'montantRo': { return rc + ro * -1 === total || ro * -1 + rc * -1 === total ? (ro * -1).toString() : ro.toString(); break; }
        }
    }

    getIdRappComptePs() {
        let currentPsStorage = localStorage.getItem('current_ps');
        if(currentPsStorage) {
            const currentPs = JSON.parse(currentPsStorage);
            if(currentPs.rappComptePs &&  currentPs.rappComptePs.idRappComptePs) {
                return currentPs.rappComptePs.idRappComptePs;
            }
        }
        return this.oxlinUserConnected?.idRappComptePs;
    }

    getPsId() {
        return (localStorage.getItem('supervised_ps') != null && localStorage.getItem('supervised_ps') !='-1') ? localStorage.getItem('supervised_ps').split(';')[1]:(localStorage.getItem('idPs') != null) ? localStorage.getItem('idPs') : this.userConnected.idPs;
        //return (localStorage.getItem('idPs') != null) ? localStorage.getItem('idPs') : this.userConnected.idPs
    }
}
