import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { isNullOrUndefined } from 'util';

@Injectable()
export class RappAccessEbicsGuard implements CanActivate {
	constructor( private router: Router) {}

	canActivate() {
		//const canActive = this.authSvc.currentUserValue.userPreferences.rapproActif;
		var canActive=false;
		let currentPsStorage = localStorage.getItem('current_ps');
		if (currentPsStorage) {
			const currentPs = JSON.parse(currentPsStorage);
			canActive= currentPs.typeRappro=='EBICS' && currentPs.psEbics.filter((e) => e.actif)?.length > 0;
		}
		if (!isNullOrUndefined(canActive) && !canActive) {
			this.router.navigate([ '/dashboard' ]);
		}
		
		return canActive;
	}
}
