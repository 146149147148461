export class OrganismeDataRaw {
	idOrganisme: number;
	libelleCourt: string;
	libelleLong: string;
	actif: Boolean;
	retourRegroupement: boolean;
	adresse1: string;
	adresse2?: string;
	codePostal: string;
	ville: string;
	telephone: string;
	fax: string;
	commentaires: string;
	typeOrganisme: string;
	envoisCompresses: boolean;
	rcSeul: boolean;
	chiffrementAccepte: boolean;
}
