<div class="modal-header pb-1">
  <button class="close" name="btn_display_filter" data-dismiss="modal" type="button"
      title="Fermer" (click)="closeModal()"><span aria-hidden="true">×</span></button>
</div>
<div class="modal-body pb-0">
  <div class="video-player-container">
    <video controls autoplay [muted]="'muted'"  class="video-player" >
      <source [src] = "safeUrl" type="video/mp4" />
  </video>

  <div class="custom-control custom-checkbox mt-3 ">
    <input type="checkbox" class="custom-control-input" id="customCheck" 
    [checked]="!this.user.userPreferences.proposeVideo" 
    (change) = "onSelectionChanged()">
    <label class="custom-control-label" for="customCheck">Ne plus me proposer la vidéo</label>
  </div>

  </div>
</div>
<div class="modal-footer">
  <div>
    <button class="btn btn-primary ms-1 "  type="button" (click)="closeModal()"> Fermer </button>
  </div>
</div>