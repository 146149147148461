import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CONF } from '../constants';

/** Représente le contenu d'une page d'aide */
export interface HelpItem {
    /** Identifiant de la page d'aide. */
    code: string;
    /** Titre de la page d'aide. */
    title: string;
    /** Contenu de la page d'aide. */
    text: string;
}

/**
 * Service d'obtention du contenu des pages d'aide.
 */
@Injectable()
export class ContextHelpSvc {
    /** Evénement demandant l'affichage d'un item d'aide donné. */
    readonly helpRequested: EventEmitter<HelpItem>;
    private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/help/get';
    constructor(private http: HttpClient) {
        this.helpRequested = new EventEmitter();
    }

    /**
     * Demande l'affichage d'un page d'aide.
     * @param code Le code de la page d'aide à afficher.
     */
    showHelp(code: string): void {
        this.getContextHelpItem(code).subscribe(helpItem => this.helpRequested.emit(helpItem));
    }

    private getContextHelpItem(code: string): Observable<HelpItem> {
        return this.parseHelpFile().pipe(
            map(
                helpItems => helpItems.find(item => item.code === code)
            )
        );
    }

    private parseHelpFile(): Observable<HelpItem[]> {
        const httpOptions = { responseType: 'text' as 'json' };
        return this.http.get<string>(this.baseUrl, httpOptions).pipe(map(response => this.parseHelpItems(response)));
    }

    private parseHelpItems(data: string): HelpItem[] {
        const newBlockDelimiter = '[##]';
        const langLine = 1;

        const blocks = data.split(newBlockDelimiter);

        // Le premier block est spéciale et contient la langue
        // locale = this.cleanText(blocks[langLine]);

        const items: HelpItem[] = [];
        for (let i = langLine + 1; i < blocks.length; i++) {
            const block = blocks[i];
            if (!this.isBlank(block)) {
                items.push(this.extractFromBlock(block));
            }
        }

        return items;
    }

    private extractFromBlock(block: string): HelpItem {
        const blockPartDelimiter = '[#]';
        let blockParts = block.split(blockPartDelimiter);
        blockParts = blockParts.filter(part => !this.isBlank(part));

        return {
            code: this.cleanText(blockParts[0]),
            title: this.cleanText(blockParts[1]),
            text: blockParts[2]
        };
    }

    private isBlank(item: string): boolean {
        return !item || item.replace(/(\r\n|\n|\r)/gm, '').trim() === '';
    }

    private cleanText(text: string): string {
        return text.replace(/(\r\n|\n|\r)/gm, '').trim();
    }
}
