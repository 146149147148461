import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { CONF } from '../../../core/constants';
import { CustomSortDataRow } from '../../../data/customSortDatRaw';
import { LotsDataRaw } from '../../../data/lotsDataRaw';

@Injectable({
	providedIn: 'root'
})
export class LotsService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/flux/lots';

	constructor(private httpClient: HttpClient) {}

	getLotsCountPerDay(criteria: any): Observable<any> {
		return this.httpClient.get<any>(this.baseUrl + '/count/daily/?idOct=' + criteria.idOct);
	}

	getPsRequest(criteria) {
		if (criteria.idPs !== undefined) {
			return '&numeroPs=' + criteria.idPs;
		} else {
			return '';
		}
	}

	getLotsFilteredList(criteria: any, lotsDataRaw: LotsDataRaw, timeoutMs: number): Observable<any> {
		const sorts: CustomSortDataRow[] = criteria.sorts;
		return this.httpClient.post<any>(
		  this.baseUrl + '/search?page=' + criteria.page + '&size=' + criteria.size + '&export=' + criteria.export, 
		  {
			filters: lotsDataRaw,
			sorts: sorts
		  }
		).pipe(
		  timeout(timeoutMs),
		  catchError(error => {
			console.error('Request timed out:', error);
			throw error;
		  })
		);
	  }

	getLotsByPS(criteria: any, lotsDataRaw: LotsDataRaw, exportData: boolean): Observable<any> {
		const sorts: CustomSortDataRow[] = criteria.sorts;
		return this.httpClient.post<any>(
			this.baseUrl +
				'/search/ps/' +
				criteria.idPs +
				'/delegues/lots?page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				'&export=' + exportData,
			{
				filters: lotsDataRaw,
				sorts: sorts
			}
		);
	}

	getLotById(criteria: any): Observable<any> {
		return this.httpClient.get<any>(this.baseUrl + '/' + criteria.idLot);
	}

	countLotsWithoutArls(criteria: LotsDataRaw): Observable<number> {
		return this.httpClient.post<number>(this.baseUrl + '/count/withoutarl', criteria);
	}

	countLotsAndFactureByDay(criteria: LotsDataRaw): Observable<any> {
		return this.httpClient.post<any>(this.baseUrl + '/count/statByDay', criteria);
	}

	exportLots(criteria: any, lotsDataRaw: LotsDataRaw, fields: string[]): Observable<any> {
		const sorts: CustomSortDataRow[] = criteria.sorts;
		return this.httpClient.post<HttpResponse<Blob>>(
			this.baseUrl + '/export',
			{
				filters: lotsDataRaw,
				sorts: sorts,
				fields: fields
			},
			{
				observe: 'response' as 'body',
				responseType: 'blob' as 'json'
			}
		).pipe(
			map((response: HttpResponse<Blob>): any => {
				return this.getFileInfosFromResponse(response);
			})
		);
	}

	exportLotsByEmail(criteria: any, lotsDataRaw: LotsDataRaw, fields: string[]): Observable<any> {
		const sorts: CustomSortDataRow[] = criteria.sorts;
		return this.httpClient.post<HttpResponse<Blob>>(
			this.baseUrl + '/export-by-email',
			{
				filters: lotsDataRaw,
				sorts: sorts,
				fields: fields
			},
			{
				observe: 'response' as 'body',
				responseType: 'blob' as 'json'
			}
		);
	}

	getFileInfosFromResponse(response: HttpResponse<Blob>) {
		return {
			file: new Blob([ response.body ], { type: 'application/vnd.ms-excel' }),
			fileName: 'Liste_Lots.xlsx'
		};
	}
}
