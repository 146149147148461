<div class="modal modal-show fade show" id="modalPsediteur" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel">
    <div class="modal-dialog modal-xxl" role="document">
        <div class="modal-content">
            <div class="inmodal">

                <div class="modal-header pb-1">
                    <h3 class="modal-title">Rattacher un éditeur</h3>
                    <button class="close" name="btn_display_filter" data-dismiss="modal" type="button" title="Fermer"
                        (click)="closeModal()"><span aria-hidden="true">×</span></button>
                </div>
                <div class="modal-body pb-0">
                    <careweb-app-ps-editeur-existant [idPs]="idPs"></careweb-app-ps-editeur-existant>
                </div>
                <div class="modal-footer">
                </div>

            </div>
        </div>
    </div>

</div>