import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CONF } from '../../core/constants';
import { HotResolutionDataRaw } from '../../data/hotResolutionDataRaw';
import { catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class HotResolutionService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/hotline/resolutions';

	constructor(private httpClient: HttpClient) {}

	getResolutionList(): Observable<any> {
		return this.httpClient.get<any>(this.baseUrl + '/list');
	}

	getResolutionListPageable(criteria: any): Observable<any> {
		return this.httpClient.get<any>(
			this.baseUrl +
				'/pageable-list?page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				'&sort=libelle' +
				this.getDirectionParameters(criteria.sortsResolution)
		);
	}

	saveHotResolution(HotTypeDataRaw: HotResolutionDataRaw): Observable<any> {
		return this.httpClient.post<any>(this.baseUrl + '/create', HotTypeDataRaw).pipe(catchError(this.handleError));
	}

	deleteHotResolution(idType: number): Promise<any> {
		return this.httpClient.delete(`${this.baseUrl}/${idType}`).toPromise();
	}

	handleError(error: HttpErrorResponse) {
		return throwError(error);
	}

	getDirectionParameters(sortsResolution) {
		if (sortsResolution.length === 1) {
			return '&direction=' + sortsResolution[0].direction;
		} else {
			return '&direction=ASC';
		}
	}
}
