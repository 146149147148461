<div class="filter-date-input form-group checkbox-filter">
	<!-- <label>Entre le</label> -->
	<p class="input-group no-margin">
		<span class="input-group">
			<input type="text" class="form-control" name="dateMin" #dpMin="bsDatepicker" bsDatepicker [(ngModel)]="modelFrom"
				[maxDate]="maxDate" size="25" placement="right" (bsValueChange)="onModelFromChange($event)"
				(change)="onModelFromChange($event);onChangeInput($event)"  (input)="onModelFromChange($event)"
				[bsConfig]="{ containerClass: 'theme-kiss' , returnFocusToInput: true }" placeholder="Choisir une date" (keydown.enter) = "onKeyEnter()" autocomplete="off"/>
			<span class="input-group-btn" (click)="dpMin.show()">
				<label class="btn btn-secondary btn-sm" for="dateMin" style="height: 38px; width: 55px">
					<i class="fa fa-calendar"></i>
				</label>
			</span>
		</span>
	</p>
</div>