<div class="full-careweb-container">
    <div id="filter" class="filter-container" carewebFilterMinHeight>
        <div class="filter-container-inner" [ngClass]="{ 'show-content': showFilter, 'init-hidden': initHidden }">
            <careweb-accordion-header-buttons (closeFilters)="toggleFilterDisplay()" (resetFilters)="resetAllFilter()"
                (launchSearch)="launchSearch()"></careweb-accordion-header-buttons>
            <div class="filter-content">
                <mat-accordion displayMode="flat" class="accordion-filter">
                    <!-- Nom de la banque -->
                    <careweb-accordion-group id="bankName" title="Nom de la banque">
                        <input type="text" id="input_banque" [(ngModel)]="filters.bankName" maxlength="150"
                            (keydown.enter)="onKeyDown($event)" />
                    </careweb-accordion-group>
                    <!-- Host ID -->
                    <careweb-accordion-group id="ebicsHostId" title="Host ID">
                        <input type="text" id="input_host" [(ngModel)]="filters.ebicsHostId" maxlength="150"
                            (keydown.enter)="onKeyDown($event)" />
                    </careweb-accordion-group>
                    <!-- ebicsUserId -->
                    <careweb-accordion-group id="ebicsUserId" title="Ebics User ID">
                        <input type="text" id="input_user_id" [(ngModel)]="filters.ebicsUserId" maxlength="150"
                            (keydown.enter)="onKeyDown($event)" />
                    </careweb-accordion-group>
                    <!-- ebicsUserId -->
                    <careweb-accordion-group id="ebicsUrl" title="Ebics URL">
                        <input type="text" id="input_url" [(ngModel)]="filters.ebicsUrl" maxlength="150"
                            (keydown.enter)="onKeyDown($event)" />
                    </careweb-accordion-group>
                    <!-- ebicsFileFormat -->
                    <careweb-accordion-group id="ebicsFileFormat" title="Ebics File Format">
                        <input type="text" id="input_ebicsFileFormat" [(ngModel)]="filters.ebicsFileFormat"
                            maxlength="150" (keydown.enter)="onKeyDown($event)" />
                    </careweb-accordion-group>
                    <!-- Etat des organismes -->
                    <careweb-accordion-group id="ebicsOrderType" title="Order type">
                        <ng-select name="actif" id="actif" placeholder="Order type" [searchable]="false"
                            [(ngModel)]="filters.ebicsOrderType" appendTo=".filter-container"
                            (keydown.enter)="onKeyDown($event)">
                            <ng-option [value]="''"></ng-option>
                            <ng-option [value]="'FDL'">FDL</ng-option>
                        </ng-select>
                    </careweb-accordion-group>
                </mat-accordion>
            </div>
        </div>
    </div>
    <div id="filter" class="filter-container" carewebFilterMinHeight >
        <div class="filter-container-inner"  *ngIf="active==2" [ngClass]="{ 'show-content': regleComponent?.showFilter, 'init-hidden': regleComponent?.initHidden }">
            <careweb-accordion-header-buttons (closeFilters)="regleComponent?.toggleFilterDisplay()" (resetFilters)="regleComponent?.resetAllFilter()"
                (launchSearch)="regleComponent?.launchSearch()"></careweb-accordion-header-buttons>
            <div class="filter-content">
                <mat-accordion displayMode="flat" class="accordion-filter">
                    <!-- Statement -->
                    <careweb-accordion-group id="statement" title="Statement">
                        <input type="text" id="input_statement" [(ngModel)]="regleComponent?.filters.statement" maxlength="150"
                            (keydown.enter)="regleComponent?.onKeyDown($event)" />
                    </careweb-accordion-group>
                    <!-- IBAN -->
                    <careweb-accordion-group id="iban" title="IBAN">
                        <input type="text" id="input_iban" [(ngModel)]="regleComponent?.filters.iban" maxlength="150"
                            (keydown.enter)="regleComponent?.onKeyDown($event)" />
                    </careweb-accordion-group>
                    <!-- Ntry -->
                    <careweb-accordion-group id="ntry" title="Ntry">
                        <input type="text" id="input_ntry" [(ngModel)]="regleComponent?.filters.ntry" maxlength="150"
                            (keydown.enter)="regleComponent?.onKeyDown($event)" />
                    </careweb-accordion-group>
                    <!-- Date Import -->
                    <careweb-accordion-group id="dateImport" title="Date Import">
                        <input type="text" id="input_dateImport" [(ngModel)]="regleComponent?.filters.dateImport" maxlength="150"
                            (keydown.enter)="regleComponent?.onKeyDown($event)" />
                    </careweb-accordion-group>
                    <!-- Label -->
                    <careweb-accordion-group id="label" title="Label">
                        <input type="text" id="input_label" [(ngModel)]="regleComponent?.filters.label" maxlength="150"
                            (keydown.enter)="regleComponent?.onKeyDown($event)" />
                    </careweb-accordion-group>
                    <!-- Transaction ID -->
                    <careweb-accordion-group id="transactionId" title="Transaction ID">
                        <input type="text" id="input_transactionId" [(ngModel)]="regleComponent?.filters.transactionId" maxlength="150"
                            (keydown.enter)="regleComponent?.onKeyDown($event)" />
                    </careweb-accordion-group>
                    <!-- Emetteur -->
                    <careweb-accordion-group id="emetteur" title="Emetteur">
                        <input type="text" id="input_emetteur" [(ngModel)]="regleComponent?.filters.emetteur" maxlength="150"
                            (keydown.enter)="regleComponent?.onKeyDown($event)" />
                    </careweb-accordion-group>
                    <!-- Motif -->
                    <careweb-accordion-group id="motif" title="Motif">
                        <input type="text" id="input_motif" [(ngModel)]="regleComponent?.filters.motif" maxlength="150"
                            (keydown.enter)="regleComponent?.onKeyDown($event)" />
                    </careweb-accordion-group>
                    <!-- Date Comptable -->
                    <careweb-accordion-group id="dateComptable" title="Date Comptable">
                        <input type="text" id="input_dateComptable" [(ngModel)]="regleComponent?.filters.dateComptable" maxlength="150"
                            (keydown.enter)="regleComponent?.onKeyDown($event)" />
                    </careweb-accordion-group>
                    <!-- Montant -->
                    <careweb-accordion-group id="montant" title="Montant">
                        <input type="text" id="input_montant" [(ngModel)]="regleComponent?.filters.montant" maxlength="150"
                            (keydown.enter)="regleComponent?.onKeyDown($event)" />
                    </careweb-accordion-group>
                    <!-- Détail -->
                    <careweb-accordion-group id="detail" title="Détail">
                        <input type="text" id="input_detail" [(ngModel)]="regleComponent?.filters.detail" maxlength="350"
                            (keydown.enter)="regleComponent?.onKeyDown($event)" />
                    </careweb-accordion-group>
    
                </mat-accordion>
            </div>
        </div>
    </div>

    <div class="grid-container" id="grid">
        <h2 class="title-border mt-3">Administration des abonnements EBICS
        </h2>
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs" [destroyOnHide]="false" (navChange)="onTabChange($event)">
            <li [ngbNavItem]="1">
                <button ngbNavLink>Liste des abonnements EBICS</button>
                <ng-template ngbNavContent>
                    <div id="onglet3" class="tab-pane fade show active">
                        <section>
                            <div class="row grid-top-btn">
                                <div class="col-md-4 filter">
                                    <button type="button" name="btn_toggle_filter"
                                        class="btn btn-primary small left-open" [hidden]="showFilter"
                                        (click)="toggleFilterDisplay()">
                                        <i class="fa fa-sliders"></i>
                                        Filtrer
                                    </button>
                                    <careweb-pluralize class="filter-count" [messageKey]="'Résultat(s) trouvé(s)'"
                                        [count]="gridHelper.totalRowCount"></careweb-pluralize>
                                </div>

                                <div class="col-md-8 conf-col">
                                    <div class="careweb-toolbar">
                                        <span class="btn btn-primary small" 
                                        tooltip="Ajouter un abonnement" placement="top" container="body"  (click)="goToAjouterAbonnement()">
                                        Ajouter
                                    </span>
                                        <careweb-pagination-size id="dv_organismes_paging"
                                            [pageSizes]="[10, 20, 30, 50, 100]" [(ngModel)]="gridDataRaw.size"
                                            (paginationSizeChange)="onPaginationSizeChange($event)">
                                        </careweb-pagination-size>
                                        <span class="btn btn-action small" tooltip="Réinitialiser l'affichage"
                                            placement="top" container="body" name="spn_reset_grid"
                                            (click)="resetGridState()">
                                            <i class="fa fa-refresh"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row grid-top-filter">
                                <div class="col-md-12">
                                    <careweb-filter-label *ngFor="let filter of filterList" [filter]="filter.id"
                                        [isOrganisme]="true" (closeFilter)="removeFilter(filter.id)">
                                        <span *ngIf="filter">{{ filter.label}}: {{filter.value }}</span>
                                    </careweb-filter-label>
                                </div>
                            </div>
                            <div class="row highlight-row">
                                <div class="col-md-12 relative">
                                    <ag-grid-angular style="width: 100%" class="ag-theme-balham ag-theme-organisme"
                                        [gridOptions]="gridHelper.gridOptions">
                                    </ag-grid-angular>

                                    <careweb-pagination [gridApi]="gridHelper.gridApi"></careweb-pagination>
                                </div>
                            </div>
                        </section>
                    </div>
                    
                </ng-template>
            </li>
            <li [ngbNavItem]="2">
                <button ngbNavLink>Administration des règles de parsing</button>
                <ng-template ngbNavContent>

                    <app-regle-parsing-xml></app-regle-parsing-xml>

                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
    
</div>

<div *ngIf="showRefreshAbonnementModal">
    <div class="modal modal-show fade show" id="modalRefresh" tabindex="-1">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <div class="inmodal">
                    <div class="modal-header pb-1">
                        <h3 class="modal-title">Renouvellement de Certification</h3>
                        <div class="button-bar control">
                            <a><i class="fa fa-close" (click)="cancelRefresh()"></i></a>
                        </div>
                    </div>
                    <div class="modal-body pb-0">
                        <div class="form-block">
                            <fieldset class="container-fluid">
                                <div class="row">
                                    <div class="col-sm-12 col-form-label">Veuillez confirmer si vous souhaitez procéder au renouvellement de cet abonnement ? </div>
                                </div>
                                <div class="button-bar control">
                                    <button type="button" (click)="cancelRefresh()"
                                            class="btn btn-default"> Annuler
                                    </button>
                                    <button type="button" (click)="refreshAbonnement()"
                                            class="btn btn-primary">
                                        <span>Renouveler</span>
                                    </button>
                                </div>
                            </fieldset>
                        </div>

                    </div>
                    <div class="modal-footer">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div *ngIf="showDeleteAbonnementModal">
    <div class="modal modal-show fade show" id="modalDeleteType" tabindex="-1">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <div class="inmodal">
                    <div class="modal-header pb-1">
                        <h3 class="modal-title">Suppression de l'abonnement</h3>
                        <div class="button-bar control">
                            <a><i class="fa fa-close" (click)="cancelDelete()"></i></a>
                        </div>
                    </div>
                    <div class="modal-body pb-0">
                        <div class="form-block" *ngIf="suppressionPossible">
                            <fieldset class="container-fluid">
                                <div class="row">
                                    <div class="col-sm-12 col-form-label">Voulez-vous supprimer
                                        l'abonnement ?</div>
                                </div>
                                <div class="button-bar control">
                                    <button type="button" (click)="cancelDelete()"
                                        class="btn btn-default"> Annuler
                                    </button>
                                    <button type="button" (click)="deleteAbonnement()"
                                        class="btn btn-primary">
                                        <span>Supprimer</span>
                                    </button>
                                </div>
                            </fieldset>
                        </div>
                        <div class="form-block" *ngIf="!suppressionPossible">
                            <fieldset class="container-fluid">
                                <div class="row">
                                    <div class="col-sm-12 col-form-label alert alert-warning"
                                        style="width: 92%;margin: auto;"><i
                                            class="fa fa-exclamation-triangle text-info-debug mr-1"></i><span>
                                            Vous ne
                                            pouvez pas supprimer cet abonnement EBICS. il est
                                            utilisé dans la
                                            configuration
                                            de rapprochement bancaire de certains comptes.
                                        </span>
                                    </div>
                                </div>
                                 <br>   
                                <div class="button-bar control">
                                    <button type="button" (click)="cancelDelete()"
                                        class="btn btn-default"> Annuler
                                    </button>
                                    <button type="button" disabled class="btn btn-primary">
                                        <span>Supprimer</span>
                                    </button>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div class="modal-footer">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="regleComponent?regleComponent.showDeleteRegleModal:false && active==2" class="modalDeleteRegle">
    <div class="modal-backdrop"></div>
    <div class="modal modal-show fade show" id="modalDeleteType" tabindex="-1">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <div class="inmodal">
                    <div class="modal-header pb-1">
                        <h3 class="modal-title">Suppression de la règle</h3>
                        <div class="button-bar control">
                            <i class="fa fa-close" (click)="regleComponent?.cancelDelete()"></i>
                        </div>
                    </div>
                    <div class="modal-body pb-0">
                        <div class="form-block">
                            <fieldset class="container-fluid">
                                <div class="row">
                                    <div class="col-sm-12 col-form-label">Voulez-vous supprimer la règle ?</div>
                                </div>
                                <div class="button-bar control">
                                    <button type="button" (click)="regleComponent?.cancelDelete()" class="btn btn-default"> Annuler
                                    </button>
                                    <button type="button" (click)="regleComponent?.deleteRegle()" class="btn btn-primary">
                                        <span>Supprimer</span>
                                    </button>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div class="modal-footer">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
