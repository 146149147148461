<div class="x-large-careweb-container">
    <section class="form-content">
        <!-- Titre H2 -->
        <div class="row form-title">
            <h2 class="col-md-6">Détails de l'AMC/OTP "{{label}}" - {{organizationNumber}} (ConvType : {{convType}}, CSR : {{csr}})</h2>
            <div class="col-md-6 text-title">
                <button class="btn btn-default btn-lg" type="button" (click)="goBack()">Retour</button>
            </div>
        </div>
        <div class="form-block">
            <div class="row">
                <div class="col">
                    <h3 class="title-border">
                        <div class="row">
                            <div class="col-auto me-auto">Etats de l'AMC/OTP sur la plateforme</div>
                            <div class="col-auto">
                                <careweb-pluralize class="filter-count" [messageKey]="'état(s)'"
                                    [count]="gridHelper.totalRowCount"></careweb-pluralize>
                            </div>
                        </div>
                    </h3>
                </div>
            </div>
            <div class="row highlight-row">
                <div class="col-md-12 relative">
                    <ag-grid-angular style="width: 100%" class="ag-theme-balham ag-theme-no-rows"
                        [gridOptions]="gridHelper.gridOptions">
                    </ag-grid-angular>
                    <careweb-pagination [gridApi]="gridHelper.gridApi"></careweb-pagination>
                </div>
            </div>
        </div>
        <!-- Paramétrage des SEL -->
        <div class="form-block">
            <!-- Titre -->
            <div class="row">
                <div class="col">
                    <h3 class="title-border">
                        <div class="row">
                            <div class="col-auto me-auto">Paramétrage des SEL</div>
                            <div class="col-auto">
                                <careweb-pluralize class="filter-count" [messageKey]="'paramétre(s)'"
                                    [count]="gridHelperParamSel.totalRowCount"></careweb-pluralize>
                            </div>
                        </div>
                    </h3>
                </div>
            </div>
            <!-- TOOLBAR -->
            <div class="row grid-top-btn" style="margin-top:0px">
                <div class="col-md-12 conf-col">
                    <div class="sy-toolbar">
                        <div class="button-bar control">
                            <!-- Bouton -->
                            <button type="button" class="btn btn-primary" data-toggle="modal"
                                (click)="openParamSelModal()">
                                Créer un paramétrage SEL
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Tableau -->
            <div class="row highlight-row">
                <div class="col-md-12 relative">
                    <ag-grid-angular style="width: 100%" class="ag-theme-balham ag-theme-no-rows"
                        [gridOptions]="gridHelperParamSel.gridOptions">
                    </ag-grid-angular>
                    <careweb-pagination [gridApi]="gridHelperParamSel.gridApi"></careweb-pagination>
                </div>
            </div>
        </div>
        <!-- Situations conventionnelles -->
        <div class="form-block">
            <!-- Titre -->
            <div class="row">
                <div class="col">
                    <h3 class="title-border">
                        <div class="row">
                            <div class="col-auto me-auto">Situations conventionnelles</div>
                            <div class="col-auto">
                                <careweb-pluralize class="filter-count" [messageKey]="'situation(s)'"
                                    [count]="gridHelperSituation.totalRowCount"></careweb-pluralize>
                            </div>
                        </div>
                    </h3>
                </div>
            </div>
            <!-- TOOLBAR -->
            <div class="row grid-top-btn" style="margin-top:0px">
                <div class="col-md-12 conf-col">
                    <div class="sy-toolbar">
                        <div class="button-bar control">
                            <!-- Bouton -->
                            <button type="button" class="btn btn-primary" data-toggle="modal"
                                (click)="relancerTout('C')">
                                Tout relancer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Tableau -->
            <div class="row highlight-row">
                <div class="col-md-12 relative">
                    <ag-grid-angular style="width: 100%" class="ag-theme-balham ag-theme-no-rows"
                        [gridOptions]="gridHelperSituation.gridOptions">
                    </ag-grid-angular>
                    <careweb-pagination [gridApi]="gridHelperSituation.gridApi"></careweb-pagination>
                </div>
            </div>
        </div>
    </section>
</div>
<!-- Modal Edit status AMC OTP -->
<div *ngIf="showEditStatutModal">
    <div class="modal-backdrop"></div>
    <div class="modal modal-show fade show" id="modalEditStatut" tabindex="-1">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="inmodal">
                    <div class="modal-header pb-1">
                        <h3 class="modal-title">Modification de l'état de l'AMC/OTP  -
                            {{selectedOrgState.organizationNumber}}</h3>
                        <button class="close" (click)="closeModal()" name="btn_display_filter" type="button"
                            title="Fermer"><span aria-hidden="true">×</span></button>
                    </div>
                    <div class="modal-body pb-0 mt-3">
                        <div class="form-block">
                            <form [formGroup]="stateForm" (ngSubmit)="saveOrgState()">
                                <fieldset class="container-fluid">
                                    <div class="row">
                                        <div class="col-sm-3 col-form-label">Type de convention</div>
                                        <div class="col-sm-3 form-group">
                                            <input type="text" name="convType" formControlName="convType" readonly />
                                        </div>
                                        <div class="col-sm-3 col-form-label">Critère secondaire</div>
                                        <div class="col-sm-3 form-group">
                                            <input required type="text" class="form-control" name="secondaryCriteria"
                                                maxlength="50" formControlName="secondaryCriteria" readonly />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-3 col-form-label">Inscrit à la plateforme</div>
                                        <div class="col-sm-3 form-group">
                                            <ng-select 
                                                style="width:100%; height:calc(1.8em + .75rem + 2px)!important;"
                                                name="subscribedPlatform" id="subscribedPlatformSelector"
                                                [clearable]="false" formControlName="subscribedPlatform"
                                                appendTo="body">
                                                <ng-option *ngIf="existeInParamSel" [value]="true">Oui</ng-option>
                                                <ng-option [value]="false">Non</ng-option>
                                            </ng-select>
                                        </div>
                                        <div class="col-sm-3 col-form-label">InterAMC</div>
                                        <div class="col-sm-3 form-group">
                                            <input type="text" class="form-control" name="interAmc"
                                                formControlName="interAmc" readonly />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-3 col-form-label">SEL interAMC</div>
                                        <div class="col-sm-3 form-group">
                                            <ng-select 
                                                style="width:100%; height:calc(1.8em + .75rem + 2px)!important;"
                                                [clearable]="false" id="interAmcSel" name="interAmcSel"
                                                formControlName="interAmcSel">
                                                <ng-option
                                                    *ngIf="selectedOrgState.interAmcSel=='INIT' || selectedOrgState.interAmcSel=='KO'"
                                                    [value]="'INIT'">INIT</ng-option>
                                                <ng-option *ngIf="selectedOrgState.interAmcSel=='OK'" [value]="'OK'">OK
                                                </ng-option>
                                                <ng-option
                                                    *ngIf="selectedOrgState.interAmcSel=='KO' || selectedOrgState.interAmcSel=='OK'"
                                                    [value]="'KO'">KO</ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="control row">
                                        <div class="col-6 mt-3">

                                        </div>
                                        <div class="button-bar col-6">
                                            <button type="button" (click)="reinitialiserOrgState()"
                                                class="btn btn-default"> Annuler
                                            </button>
                                            <button type="button" type="submit" class="btn btn-primary">
                                                <span>Enregistrer</span>
                                            </button>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                    <div class="modal-footer">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal Add and Edit ParmSel -->
<div *ngIf="showParamSelModal">
    <div class="modal-backdrop"></div>
    <div class="modal modal-show fade show" id="modalParamSel" tabindex="-1">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="inmodal">
                    <div class="modal-header pb-1">
                        <h3 *ngIf="oldParamSel.id == null" class="modal-title">Ajouter un paramétrage SEL</h3>
                        <h3 *ngIf="oldParamSel.id !== null" class="modal-title">Modification d'un paramétrage SEL
                        </h3>
                        <button class="close" (click)="closeModal()" name="btn_display_filter" type="button"
                            title="Fermer"><span aria-hidden="true">×</span></button>
                    </div>
                    <div class="modal-body pb-0 mt-3">
                        <div class="form-block">
                            <form [formGroup]="paramSelForm" (ngSubmit)="saveServiceDirectory()">
                                <fieldset class="container-fluid">
                                    <div class="row">
                                        <div class="col-sm-2 col-form-label pr-0">Type de convention</div>
                                        <div class="col-sm-4 form-group">
                                            <input type="text" class="form-control" name="agreementType"
                                                formControlName="agreementType" style="text-transform: uppercase"
                                                onKeyPress="if(this.value.length==2) return false;"
                                                onkeyup="if(this.value.length==2) return false;"
                                                onpaste="if(this.value.length==2) return false;" />
                                        </div>
                                        <div class="col-sm-2 col-form-label">Critère secondaire</div>
                                        <div class="col-sm-4 form-group">
                                            <input type="text" class="form-control" name="secondaryCriteria"
                                                formControlName="secondaryCriteria"
                                                onKeyPress="if(this.value.length==3) return false;"
                                                onpaste="if(this.value.length==3) return false;" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-2 col-form-label">Code service</div>
                                        <div class="col-sm-4 form-group">
                                            <ng-select
                                                style="width:100%; height:calc(1.8em + .75rem + 2px)!important;"
                                                name="service" id="serviceSelector" [clearable]="false"
                                                formControlName="service" appendTo="body"
                                                (change)="codeServiceChange($event)">
                                                <ng-option [value]="'IDB'">IDB</ng-option>
                                                <ng-option [value]="'CLC'">CLC</ng-option>
                                                <ng-option [value]="'PRA'">PRA</ng-option>
                                            </ng-select>
                                        </div>
                                        <div class="col-sm-2 col-form-label">Version de norme</div>
                                        <div class="col-sm-4 form-group">
                                            <input *ngIf="paramselFormFields.service.value == 'IDB'" [value]="'IDB1'"
                                                type="text" class="form-control" name="normVersion"
                                                formControlName="normVersion" disabled />
                                            <input *ngIf="paramselFormFields.service.value == 'CLC'" [value]="'CLC1'"
                                                type="text" class="form-control" name="normVersion"
                                                formControlName="normVersion" disabled />
                                            <input *ngIf="paramselFormFields.service.value == 'PRA'" [value]="'PRA1'"
                                                type="text" class="form-control" name="normVersion"
                                                formControlName="normVersion" disabled />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-2 col-form-label">Url de routage *</div>
                                        <div class="col-sm-4 form-group">
                                            <input type="text" class="form-control" name="routingUrl"
                                                [ngClass]="{ 'is-invalid': submitted && paramselFormFields.routingUrl.errors }"
                                                formControlName="routingUrl" required
                                                pattern="(http[s]?://){1}[a-zA-Z0-9.-_:/\?=&@%\+~#\-\_]*" />
                                            <div *ngIf="submitted && paramselFormFields.routingUrl.errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="paramselFormFields.routingUrl.errors.required || paramselFormFields.routingUrl.errors.whitespaces">
                                                    Ce champ est obligatoire</div>
                                                <div
                                                    *ngIf="paramselFormFields.routingUrl.errors.pattern?.requiredPattern">
                                                    Ce champ doit être au format http://… ou https://</div>
                                            </div>
                                        </div>
                                        <div class="col-sm-2 col-form-label">Type d’adresse</div>
                                        <div class="col-sm-4 form-group">
                                            <input
                                                *ngIf="paramselFormFields.service.value == 'IDB' || paramselFormFields.service.value == 'CLC'"
                                                [value]="'EDI'" type="text" class="form-control" name="adressType"
                                                formControlName="adressType" disabled />
                                            <input *ngIf="paramselFormFields.service.value == 'PRA'" type="text"
                                                class="form-control" name="adressType" formControlName="adressType"
                                                [value]="" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-2 col-form-label">Domaine
                                            <span
                                                *ngIf="paramselFormFields.service.value=='IDB' || paramselFormFields.service.value=='CLC'">*</span>
                                        </div>
                                        <div class="col-sm-4 form-group">
                                            <ng-select
                                                [required]="paramselFormFields.service.value=='IDB' || paramselFormFields.service.value=='CLC'"
                                                formControlName="agreementDomain" [clearable]="false"
                                                appendTo="body"
                                                style="width:100%; height:calc(1.8em + .75rem + 2px)!important;"
                                                [ngClass]="{ 'is-invalid': submitted && paramselFormFields.agreementDomain.errors }">
                                                <ng-option value=""></ng-option>
                                                <ng-option *ngFor="let domain of domainArray" [value]="domain.code">
                                                    {{domain.label}}
                                                </ng-option>
                                            </ng-select>
                                            <div *ngIf="submitted && paramselFormFields.agreementDomain.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="paramselFormFields.agreementDomain.errors.required">
                                                    Ce champ est obligatoire pour le code service IDB et CLC</div>
                                            </div>
                                        </div>
                                        <div class="col-sm-2 col-form-label">Norme service</div>
                                        <div class="col-sm-4 form-group">
                                            <input
                                                *ngIf="paramselFormFields.service.value == 'IDB' || paramselFormFields.service.value == 'CLC'"
                                                [value]="'MEDAMC'" type="text" class="form-control" name="norm"
                                                formControlName="norm" disabled />
                                            <input *ngIf="paramselFormFields.service.value == 'PRA'" type="text"
                                                class="form-control" name="norm" formControlName="norm" [value]="" disabled/>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-2 col-form-label">Date de début</div>
                                        <div class="col-sm-4 form-group">
                                            <input type="text" class="form-control" name="startDate" id="startDate"
                                                formControlName="startDate" [minDate]="today" bsDatepicker size="25"
                                                placement="bottom" placeholder="Choisir une date"
                                                [bsConfig]="{ containerClass: 'theme-kiss'  }"
                                                autocomplete="off"
                                                [ngClass]="{ 'is-invalid': submitted && paramselFormFields.startDate.errors }" />
                                            <div *ngIf="submitted && paramselFormFields.startDate.errors"
                                                class="error-messages ng-active">
                                                La date de début doit être supérieure ou égale à la date du jour.
                                            </div>
                                        </div>
                                        <div class="col-sm-2 col-form-label">Source</div>
                                        <div class="col-sm-4 form-group">
                                            <input type="text" class="form-control" name="initSource"
                                                formControlName="initSource" disabled />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-2 col-form-label">Méthode</div>
                                        <div class="col-sm-4 form-group">
                                            <input type="text" class="form-control" name="method"
                                                formControlName="method"
                                                onKeyPress="if(this.value.length==20) return false;"
                                                onpaste="if(this.value.length==20) return false;" />
                                        </div>
                                    </div>
                                    <div class="control row">
                                        <div class="col-6 mt-3">

                                        </div>
                                        <div class="button-bar col-6">
                                            <button type="button" (click)="reinitialiserParamSel()"
                                                class="btn btn-default"> Annuler
                                            </button>
                                            <button type="button" type="submit" class="btn btn-primary">
                                                <span>Enregistrer</span>
                                            </button>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                    <div class="modal-footer">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal confirmation de suppression -->
<careweb-confirm-modal (confirmModal)="deleteParamSel()" (showModal)="showModal($event)" [(visible)]="showConfirmModal"
    [text]="modalMessage">
</careweb-confirm-modal>