import {
	AfterViewInit,
	Component,
	ContentChildren,
	ElementRef,
	HostBinding,
	Input,
	OnChanges,
	OnDestroy,
	QueryList,
	Renderer2,
	SimpleChanges
} from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { MessageSpec } from './message-spec';
import { MessageComponent } from './messages-override.component';

@Component({
	selector: 'careweb-error-messages',
	template: `
        <ng-container *ngIf="errorMessages && errorMessages.length > 0">
            <div class="error-messages ng-active">{{ errorMessages[0] }}</div>
        </ng-container>
    `,
	styleUrls: [ './messages-override.css' ]
})
export class ErrorMessagesComponent implements AfterViewInit, OnChanges, OnDestroy {
	@ContentChildren(ErrorMessagesComponent) errorsChildren: QueryList<MessageComponent>;
	@Input() field: NgModel;
	@Input() compareField: NgModel;
	@Input() extraField: NgModel;
	@Input() touched: boolean;
	@Input() debounce: number;
	@Input() extraCondition: boolean;
	@Input() extraConditionLabel: string;
	@Input() nameField: string;
	errorMessages: string[];
	errorsMap: Map<String, MessageSpec>;
	private subscriptions = new Subject();

	constructor(private element: ElementRef, private renderer: Renderer2, private form: NgForm) {}

	ngAfterViewInit(): void {
		this.errorsMap = new Map();
		this.errorsMap
			.set('required', {
				key: this.nameField ? `Le champ ${this.nameField} est obligatoire ` : 'Ce champ est obligatoire'
			})
			.set('email', { key: "Le format de l'adresse email n'est pas valide" })
			.set('minlength', {
				key:
					this.field.name === 'password' || this.field.name === 'newPassword'
						? 'Votre nouveau mot de passe ne respecte pas la longueur minimum de 12 caractères'
						: "Le numéro de PS n'est pas valide (nombre de 9 caractères)",
				params: { length: this.getMinLength() }
			})
			.set('maxlength', { key: 'error.max_length', params: { length: this.getMaxLength() } })
			.set('number', { key: 'Ce champ doit contenir un nombre.' })
			.set('pattern', { key: 'Format incorrect' })
			.set('hasLowercase', { key: 'Votre nouveau mot de passe ne contient pas de minuscule' })
			.set('hasUppercase', { key: 'Votre nouveau mot de passe ne contient pas de majuscule' })
			.set('hasNumber', { key: 'Votre nouveau mot de passe ne contient pas de chiffre' })
			.set('hasSpecialChar', { key: 'Votre nouveau mot de passe ne contient pas de caractère spécial' })
			.set('compareTo', { key: 'Les deux mots de passe ne sont pas identiques' })
			.set('bsDate', { key: this.getBsDateMessage(this.field.name) })
		if (this.touched) {
			this.field.statusChanges
				.pipe(takeUntil(this.subscriptions), debounceTime(this.debounce))
				.subscribe(() => this.checkValidity());
			if (this.compareField) {
				this.compareField.statusChanges
					.pipe(takeUntil(this.subscriptions))
					.subscribe(() => this.checkValidity());
			}
			if (this.extraField) {
				this.extraField.statusChanges.pipe(takeUntil(this.subscriptions)).subscribe(() => this.checkValidity());
			}
			this.form.ngSubmit.pipe(takeUntil(this.subscriptions)).subscribe(() => {
				this.checkValidity();
			});
		} else {
			this.field.control.statusChanges.pipe(takeUntil(this.subscriptions)).subscribe(() => {
				if (this.form.submitted && this.field.valid) {
					this.checkValidity();
				}
			});
			this.form.ngSubmit.pipe(takeUntil(this.subscriptions)).subscribe(() => {
				this.checkValidity();
			});
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.extraCondition && changes.extraCondition.currentValue) {
			this.checkValidity();
		}
	}

	/**
     * Fournit la valeur de l'attribut minlength.
     */
	private getMinLength() {
		const elem = document.querySelector('form [name="' + this.field.name + '"]');
		return elem ? elem.getAttribute('minlength') : null;
	}

	/**
     * Fournit la valeur de l'attribut maxlength.
     */
	private getMaxLength() {
		const elem = document.querySelector('form [name="' + this.field.name + '"]');
		return elem ? elem.getAttribute('[maxlength]') : null;
	}

	/**
     * Fournit l'element div à mettre en erreur.
     */
	private getDivElement() {
		const parent = this.renderer.parentNode(this.element.nativeElement);
		return this.renderer.parentNode(parent);
	}

	/**
     * Vérifie la validité d'un champ avant l'affichage du message.
     */
	private checkValidity(): void {
		this.errorMessages = [];
		if (this.field.dirty || this.form.submitted) {
			const divElem = this.getDivElement();
			if (!this.extraCondition && this.field.invalid && this.field.errors) {
				const errors = this.field && this.field.errors && Object.keys(this.field.errors);
				errors.forEach((error) => {
					if (this.errorsMap.get(error) !== undefined) {
						this.errorMessages.push(this.errorsMap.get(error).key, this.errorsMap.get(error).params);
					}
				});
				this.renderer.addClass(divElem, 'field-error');
			} else if (this.extraCondition) {
				this.errorMessages.push(this.extraConditionLabel);
				this.renderer.addClass(divElem, 'field-error');
			} else {
				this.renderer.removeClass(divElem, 'field-error');
				this.errorMessages = [];
			}
		}
	}

	//Return the correspond bsDate error message
	getBsDateMessage(fieldName: string): string {
		switch(fieldName) {
			case 'dateMin':
				return 'La date de début doit être antérieur à la date de fin';
			case 'dateMax':
				return 'La date de fin doit être supérieur à la date de début';
			case 'dashDateMin':
				return 'Indiquer une date antérieure à la date de fin';
			case 'dashDateMax':
				return 'Indiquer une date postérieure à la date de début';
			default:
				return '';
		}
	}

	ngOnDestroy() {
		this.subscriptions.next();
		this.subscriptions.complete();
	}

	/** Ajout un identifiant sur l'élément HTML de la forme : errors-<fieldname> */
	@HostBinding('attr.id')
	get elementId() {
		return `errors-${this.field.name}`;
	}
}
