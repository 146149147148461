export class RappTransactionFilterRaw {
	idUser: number;
	valide: boolean;
	label: string;
	notes: string;
	organismeLibelle: string;
	commentaire :string;
	periode: number;
	montantTotalRspExacte : number;
	montantTotalRspFrom : number;
	montantTotalRspTo: number;
	montantTotalTrExacte: number;
	montantTotalTrFrom: number;
	montantTotalTrTo: number;
	montantDiff: boolean;
	hasMontantDiff: boolean;
	idOrganisme: string;
	libelleVirement1: string;
	libelleVirement2: string;
}
