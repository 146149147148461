export class SuiviEbicsDataRaw{
    dateDemarrageExacte: any;
    dateDemarrageFrom: any;
    dateDemarrageTo: any;
    bankName: string;
    fromDateSync?: Date;
    toDateSync?: Date;
    hostId?: string;
    ebicsStatut?: string;
    erreurCategorie?: string;
    erreur?: string;
    payloadFilePath?: string;
    json?: string;
}
