import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { isNullOrUndefined } from 'util';
import { defineLocale, frLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';


@Component({
	selector: "careweb-date-time-filter",
	templateUrl: "./date-time-filter.html",
})
export class DateTimePickerComponent implements OnInit {

	@Input() modelEnd: Date;
	@Output() modelEndChange = new EventEmitter<Date>();

	@Input() modelStart: Date;
	@Output() modelStartChange = new EventEmitter<Date>();

	@Input() maxDate?: Date | null = new Date();

	@Output() onEnter = new EventEmitter<any>();

	@Output() onClick = new EventEmitter<any>();

	tooltipStart: boolean = true;
	tooltipEnd: boolean = true;
	datePipe: DatePipe;
	isDateRangeValid2 = true;

	constructor(private localeService: BsLocaleService) {
		defineLocale('fr', frLocale);
		this.datePipe = new DatePipe('fr-FR');
	}

	ngOnInit() {
		this.setLocale();
		this.maxDate = !isNullOrUndefined(this.maxDate) ? this.maxDate : new Date();
	}

	onKeyEnter2() {
		this.onEnter.emit();
	}

	onChangeInputFrom($event) {
		if ($event !== 'Invalid date' && $event !== 'Date invalide' && $event !== '') {
			this.modelStart = $event;
			this.modelStartChange.emit(this.modelStart);
			if (this.modelEnd) {
				this.checkDateRangeValidity();
			}
		}
		else if ($event=== 'Invalid date') {
			$event  = 'Date invalide'
		}
		if($event === ''){
			this.tooltipStart=false;
		}
	}

	onChangeInputTo($event) {
		if ($event !== '' && $event !== 'Date invalide' && $event !== 'Invalid date') {
			this.modelEnd = $event;
			this.modelEndChange.emit(this.modelEnd);
			//this.modelTo2 = new Date(this.datePipe.transform(new Date($event.target.value).toISOString(), 'short'));
			if (this.modelStart) {
				this.checkDateRangeValidity();
			}
		} else if ($event === 'Invalid date') {
			$event = 'Date invalide'
		}
		if ($event === '') {
			this.tooltipStart = false;
		}
	}

	checkDateRangeValidity() {
		if (this.modelStart > this.modelEnd) {
			this.isDateRangeValid2 = false;
			this.tooltipStart = false;
			this.tooltipEnd = false;
		} else {
			this.isDateRangeValid2 = true;
			this.tooltipStart = true;
			this.tooltipEnd = true;
		}
	}
	private setLocale() {
		this.localeService.use('fr');
	}

	onKeyEnter() {
		this.onEnter.emit();
	}
}
