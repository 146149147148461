<div class="x-large-careweb-container" *ngIf="currentHotline">
    <form [formGroup]="hotlineForm">
        <section class="form-content">
            <!-- Titre H2 -->
            <div class="row form-title">
                <h2 class="col-md-6">
                    Détails de l'intervention n° {{ currentHotline.reference}}
                </h2>
                <div class="col-md-6 text-title">
                    <button class="btn btn-default btn-lg" type="button" (click)="goBack()">Retour</button>
                </div>
            </div>
            <div class="form-block">
                <!-- Titre -->
                <div class="row">
                    <div class="col">
                        <h3 class="title-border">
                            Informations
                        </h3>
                    </div>
                </div>
                <fieldset class="container-fluid">
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Numéro de PS</div>
                        <div class="col-sm-4 form-group">

                            <input type="text" class="form-control" readonly formControlName="numeroPs"
                                name="numeroPs" /></div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Type d'intervention</div>
                        <div class="col-sm-4 form-group"><input type="text" class="form-control"
                                formControlName="intervention" readonly />
                        </div>
                        <div class="col-sm-2 col-form-label">Motif</div>
                        <div class="col-sm-4 form-group"><input type="text" class="form-control" formControlName="motif"
                                readonly /></div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Initiateur</div>
                        <div class="col-sm-4 form-group"><input type="text" class="form-control" formControlName="intervenant"
                                name="intervenant" readonly />
                        </div>
                        <div class="col-sm-2 col-form-label">Niveau</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" name="niveau" formControlName="niveau" readonly />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Statut *</div>
                        <div class="col-sm-4 form-group">
                            <ng-select name="statut" id="statut" placeholder="Statut de l'intervention"
                                formControlName="statut" appendTo="body" [clearable]="false" [required]="true"
                                [ngClass]="{ 'is-invalid': submitted && formFields.statut.errors }"
                                style="width:100%; height:calc(1.8em + .75rem + 2px)!important;">
                                <ng-option *ngFor="let statut of interventionStatut" [value]="statut.id">
                                    {{statut.statut}}</ng-option>
                            </ng-select>
                            <div *ngIf="submitted && formFields.statut.errors?.required" class="invalid-feedback">
                                Ce champ est obligatoire
                            </div>
                        </div>
                         <div class="col-sm-2 col-form-label">Service du destinataire </div>
                        <div class="col-sm-4 form-group">
                            <!-- <input type="text" class="form-control" readonly formControlName="service" /> -->
                            <ng-select placeholder="Service du destinataire de l'intervention"
                             name="service" class="mb-3 mt-1" appendTo="body"
                            [clearable]="true" formControlName="service" >
                            <ng-option *ngFor="let service of groupeUsers" [value]="service.value | uppercase">
                                {{service.value | uppercase}}</ng-option>
                        </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Commentaire </div>
                        <div class="col-sm-10 form-group">
                            <textarea class="form-control-input" placeholder="" maxlength="1000" name="commentaire"
                                rows="6" style="resize:horizontal;  resize: vertical;" formControlName="commentaire"
                                autosize></textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Agent assigné </div>
                        <div class="col-sm-4 form-group">
                            <ng-select placeholder="Agent assigné" formControlName="agent" appendTo="body" name="agent"
                                class="mb-3 mt-1">
                                <ng-option *ngFor="let agent of agents" [value]="agent.id">
                                    {{agent.value}}</ng-option>
                            </ng-select>
                        </div>
                        <div class="col-sm-2 col-form-label">Résolution </div>
                        <div class="col-sm-4 form-group">
                            <ng-select placeholder="Résolution de l'intervention" formControlName="resolution"
                                appendTo="body" name="resolution" class="  mb-3 mt-1" [clearable]="false">
                                <ng-option *ngFor="let resolution of resolutions " [value]="resolution.id">
                                    {{resolution.value}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                </fieldset>
                <!-- TOOLBAR -->
                <div class="row grid-top-btn" style="margin-top:0px">
                    <h2 class="col-md-6"></h2>
                    <div class="col-md-6 text-title">
                        <button type="button" class="btn btn-default" (click)="cancel()">
                            Annuler
                        </button>&nbsp;&nbsp;
                        <button type="button" class="btn btn-primary" (click)="updateHotline()">
                            Enregistrer
                        </button>
                    </div>
                </div>
            </div>
            <div class="form-block">
                <div class="row mb-2 ">
                    <div class="col">
                        <h3 class="title-border">
                            <div class="row">
                                <div class="col-auto me-auto">Historique</div>
                                <div class="col-auto"> {{historisqueNbre}} résultat(s) trouvé(s)</div>
                            </div>
                        </h3>
                    </div>
                </div>
                <div class="row form-title">
                    <h2 class="col-md-6"></h2>
                    <div class="col-md-6 text-title">
                        <span class="btn btn-action small" [class.disabled]="!gridHelper.isAvailableData()"
                            tooltip="Exporter les résultats (.xlsx)" placement="top" container="body"
                            name="btn_export_.xls" (click)="exportXlsx()">
                            <i class="fa fa-file-excel-o"></i>
                        </span>
                    </div>
                </div>
                <div class="row highlight-row">
                    <div class="col-md-12 relative">
                        <ag-grid-angular style="width: 100%;" class="ag-theme-material"
                            [gridOptions]="gridHelper.gridOptions">
                        </ag-grid-angular>
                        <careweb-pagination [gridApi]="gridHelper.gridApi"></careweb-pagination>
                    </div>
                </div>
            </div>
        </section>
    </form>
</div>