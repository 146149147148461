export class PageCriteriaSortRaw {
    /** Direction du tri. */
    direction: string;
    /** Propriété sur laquelle doit porter le tri. */
    property: string;
    /** la priorité du tri */
    priority?: number;

    constructor(data = null) {
        Object.assign(this, data);
    }
}
