import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ColDef, ColGroupDef, GetDataPath, GetRowIdFunc, GridReadyEvent} from 'ag-grid-community';
import 'ag-grid-enterprise';
import {AgCheckAllService, AgCheckBoxState, AgHeaderCheckboxComponent} from '../../../components/header-checkbox/header-checkbox-ag.component';
import { CheckAllState } from '../../../components/header-checkbox/header-checkbox-ag.component';
import { RspRappSummaryService } from '../rspRappSummary.service';
import { RspRappSummaryDataRaw } from '../../../data/rspRappSummaryDataRaw';
import { FormatThousandsPipe } from '../../../core/pipes/format-thousands.pipe';
import {isNullOrUndefined} from "util";
import {RappAutoPreviewcomponent} from "../rapp-auto-preview.component";
import {AgGridHelper} from "../../../components/ag-grid/ag-grid-helper";
import {GridDataRaw} from "../../../data/gridDataRaw";
import {CustomSortDataRow} from "../../../data/customSortDatRaw";
import {gridConvertSort} from "../../../components/ag-grid/ag-grid.utils";
import {StorageService} from "../../../shared/storage-service";
import {RowNode} from "ag-grid-enterprise";
import {Tree} from "../../../data/Tree";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-ag-RspGrid',
  templateUrl: './ag-RspGrid.html',
  styleUrls: ['./ag-RspGrid.component.scss']
})
export class AgRspGridComponent implements OnInit {

  gridOptions;
  gridApi;
  gridId = 'rsp-grid-id';
  userConnected: any;
  gridHelperRSP: AgGridHelper;
  rspGridDataRaw: GridDataRaw;
  currentPage = 1;
  selectedRow: any;
  showRspDetail: boolean;
  showFilter = false;
  ispageChange = false;
  data: Tree[] = [];
  expandedNodes= [];
  rowCount:number;
  totalElement = 0 ;
  totalRspSelected :number;
  nbRowSelectedRSPMAX: number = 0;
  checkAll = true;
  loadingRsp = false;
  isRspSoldable = false;
  isVrtPointable = false;
  totalDiffRSP: any;
  montantTotalSelectedRSP: number;
  montantckeckAll: number;
  groupDefaultExpanded = 0;
  selectedRspRows: any[];
  selectedItem: any[];
  unSelectedRspRows: any[];
  @Input() rspRappSummaryDataRaw: RspRappSummaryDataRaw; //filtre
  @Input() montantTotalSelectedVrt: number;
  @Output() totalDiffVRTEvent = new EventEmitter<any>();
  @Input() gridHelperVRT: AgGridHelper;
  @Input() searchRspFilter:Boolean;
  datePipe: DatePipe;
  private selectedIds: Set<number> = new Set();
  public rowSelection: 'single' | 'multiple' = 'multiple';

  formatCurrencyRspRapp = (params): string => {
    if (params.data) {
      return this.formatThousands.transform(params.value/100);
    }
  };
  sortMontant= (): any =>{
    (value1, value2) => {
      const numericValue1 = parseFloat(value1.replace(',', ''));
      const numericValue2 = parseFloat(value2.replace(',', ''));
      return numericValue1 - numericValue2;
    }
  }

  public columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Avis de réception comptable (RSP)',
      headerClass: 'ag-header-group-parent ag-header-group-rsp',
      children: [
        {
          headerName: 'Date',
          field: 'dateReception',
          cellStyle: this.centerContent(),
          sortable: true,
          sort: 'desc',
          sortIndex: 0,
          onCellClicked: (params) => this.onRowClicked(params)
        },
        {
          headerName: 'Montant',
          field: 'value',
          sortable: true,
          cellClass: ['font-weight-bold'],
          cellStyle: this.endContent(),
          valueFormatter: this.formatCurrencyRspRapp,
          comparator: this.sortMontant,
          onCellClicked: (params) => this.onRowClicked(params)
        },
        {
          headerName: '',
          field: 'detailRSP',
          width: 70,
          minWidth: 40,
          headerClass: 'ag-header-merge',
          cellRenderer: RappAutoPreviewcomponent,
          cellStyle: this.centerContent(),
          onCellClicked: (params) => this.onDetailClicked(params),
        },
        {
          headerName: '',
          field: 'check',
          width: 70,
          minWidth: 40,
          checkboxSelection: true,
          cellStyle: this.centerContent(),
          headerComponent: AgHeaderCheckboxComponent,
          headerComponentParams: {
            isCheckAll: true,
            id: this.gridId
          }
        }
      ]
    }
  ];
  public defaultColDef: ColDef = {
    suppressMenu: true,
    resizable: true,
    filter: false,
  };
  formatNumLotCell = (params): string => {
    if (params.data) {
      if(params.data.children){
        const level=params.node.level;
        switch (level) {
          case 0:
            return params.value + ' (' + params.data.children.length + ')';
            break;
          case 1:
            return this.datePipe.transform(params.value, 'shortDate') + ' (' + params.data.children.length + ')';
            break;
          case 2:
            return (params.value.trim() === '')
                ? `Aucun lot (${params.data.children.length})`
                : `LOT n° ${params.value} (${params.data.children.length})`;
            break;
        }
      }else return 'FSE n° ' + params.value;
    }
  };
  onDetailClicked(params) {
    if(params.data.node){
      this.selectedRow = params.data.rspRappSummaryDto;
      this.showRspDetail = true;
    }
    this.onRowClicked(params);
  }
  public closeModalRspDetail() {
    this.selectedRow = null;
    this.showRspDetail = false;
  }

  public autoGroupColumnDef: ColDef = {
    headerName: 'Emetteur > Date > Lot > FSE',
    field: 'label',
    cellRendererParams: {
      checkbox: false,
      suppressCount: true,
    },
    filter: false,
    sortable: true,
    sort: 'asc',
    sortIndex: 1,
    minWidth: 280,
    valueFormatter: this.formatNumLotCell,
    comparator: this.sortMontant,
    onCellClicked: (params) => this.onRowClicked(params)
  };

  public getDataPath: GetDataPath = (data) => {
    return data.dataPath;
  };

  public getRowId: GetRowIdFunc = (params) => {
    return params.data.uuid;
  };

  public rowData!: any[];

  onSortChanged(params) {
    this.gridHelperRSP.gridApi = params.api;
    this.currentPage=1;
    this.ispageChange=true;
    this.loadData(this.rspRappSummaryDataRaw);
    this.captureExpandedNodes();
  }

  selectAllRsp(){
    this.gridHelperRSP.gridApi.forEachNode((node: RowNode) => {
      this.updateSelectionState(node);
      this.ckeckAllupdateSelection(node);
    });
  }
  onGridSizeChanged() {
      this.gridHelperRSP.gridApi.sizeColumnsToFit();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridHelperRSP.gridApi = params.api;
    this.gridHelperRSP.gridColumnApi = params.columnApi;
    this.restoreGrid(this.gridHelperRSP);
    this.gridHelperRSP.gridApi.sizeColumnsToFit();
    if (this.rspGridDataRaw.columnState) {
      this.gridHelperRSP.gridColumnApi.applyColumnState({state: this.rspGridDataRaw.columnState});
    }
    this.loadData(this.rspRappSummaryDataRaw);
  }

  constructor(private agCheckAllService: AgCheckAllService, private formatThousands: FormatThousandsPipe,
              private rspRappSummaryService: RspRappSummaryService,private storageService: StorageService){
    this.rspGridDataRaw = new GridDataRaw();
    const rspPageSize = this.storageService.get('rappManuRspPaginationPageSize', true);
    this.rspGridDataRaw.size = !isNullOrUndefined(rspPageSize) ? rspPageSize : 10;
    this.gridHelperRSP = new AgGridHelper('rappManuRsp', this.rspGridDataRaw.size);
    this.initSelectedRsp();
  }

  ngOnInit() {
    this.datePipe = new DatePipe('fr-FR');

    this.userConnected = JSON.parse(localStorage.getItem('careweb_user'));
    // Init grid options FOR RSP
    this.gridHelperRSP.gridOptions = {
      columnDefs: this.columnDefs,
      defaultColDef: this.defaultColDef,
      autoGroupColumnDef: this.autoGroupColumnDef,
      treeData: true,
      animateRows: true,
      groupDefaultExpanded: this.groupDefaultExpanded,
      getDataPath: this.getDataPath,
      rowData: this.rowData,
      rowHeight: 50,
      headerHeight: 50,
      domLayout: 'autoHeight',
      colResizeDefault: 'shift',
      groupSelectsChildren: true,
      rowSelection:this.rowSelection,
      suppressRowClickSelection: true,
      getRowId: this.getRowId,
      suppressServerSideInfiniteScroll: false,
      pagination: true,
      cacheBlockSize: 10,
      paginationPageSize: this.rspGridDataRaw.size,
      infiniteInitialRowCount: 1,
      suppressScrollOnNewData: true,
      suppressPaginationPanel: true,
      suppressContextMenu: true,
      enableCellTextSelection: true,
      suppressHorizontalScroll: true,
      suppressDragLeaveHidesColumns: true,
      localeText: this.gridHelperRSP.getLocaleText(),
      onGridReady: (params) => this.onGridReady(params),
      onPaginationChanged: (params) => this.onPaginationChanged(params),
      onGridSizeChanged:() => this.onGridSizeChanged(),
      onSelectionChanged: (params) => this.onSelectionChanged(params),
      onSortChanged: (params) => this.onSortChanged(params),
    };
    this.agCheckAllService.checkAll$.subscribe((value: AgCheckBoxState) => {
      //  this.onCheckAll(value);
      if(this.gridId == value.id) {
       this.onCheckAll(value.state == CheckAllState.SELECT);
      }
    });
    let expandedNodes = sessionStorage.getItem('expandedNodes');
    if (expandedNodes) {
      this.expandedNodes = JSON.parse(expandedNodes);
    }
  }

  onRowClicked(params) {
    if (!this.gridHelperRSP.isCellTextSelected()) {
      params.node.setExpanded(!params.node.expanded);
    }
  }
  onCheckAll(checkAll: any): void {
    if (checkAll) {
      this.initSelectedRsp();
      this.agCheckAllService.notifyCheckBox({ id: this.gridId, state: CheckAllState.SELECT });
      this.checkAll = true;
      this.loadingRsp = true;
      this.totalRspSelected=this.nbRowSelectedRSPMAX;
      this.montantTotalSelectedRSP= this.montantckeckAll;
      this.aggregateDiffSelected();
      this.checkVrtPointability();
      this.checkRspSoldability();
      const selectedNodes = this.gridHelperRSP.gridApi.getSelectedNodes();
      this.selectedItem = selectedNodes.map((node) => node.data).filter((data) => data.node);
      this.selectedRspRows.push(...this.selectedItem);
    } else {
      this.montantTotalSelectedRSP= 0;
      this.checkAll = false;
      this.selectedIds = new Set<number>();
      this.selectedRspRows = [];
      this.unSelectedRspRows = [];
    }
  }
  onPaginationChanged(params: any): void {
    if(this.ispageChange){
      if (this.checkAll){
        params.api.getRenderedNodes().map((node: RowNode) => {
          this.updateSelectionState(node);
          this.ckeckAllupdateSelection(node);
        });
      }else{
        params.api.getRenderedNodes().map((node: RowNode) => {
          this.updateSelectionState(node);
        });
      }
    }
  }

  onSelectionChanged(params) {
      if(this.checkAll){
          params.api.getRenderedNodes().map((node: RowNode) => {
            this.checkAllSelectionChanged(node);
          });

      }else {
        params.api.getRenderedNodes().map((node: RowNode) => {
          this.selectionChanged(node);
        });
        this.calculateSelectedRsp();
      }
      this.checkRspSoldability();
      this.checkVrtPointability();
      this.checkAllRspManagement(params);
  }
  checkAllRspManagement(params:any) {
    if(this.checkAll){
      if(this.unSelectedRspRows.length == 0) {
        this.agCheckAllService.notifyCheckBox({ id: this.gridId, state: CheckAllState.SELECT });
        this.checkAll = true;
      } else if (this.unSelectedRspRows.length == this.nbRowSelectedRSPMAX){
        this.agCheckAllService.notifyCheckBox({ id: this.gridId, state: CheckAllState.UNSELECT });
        this.initSelectedRsp();
        this.checkAll = false;
      } else {
        if(this.unSelectedRspRows.length!=0)
        this.agCheckAllService.notifyCheckBox({ id: this.gridId, state: CheckAllState.INDETERMINATE });
      }
    }else {
      const selectedNodes = params.api.getSelectedNodes();
      this.selectedItem = selectedNodes.map((node) => node.data).filter((data) => data.node);
      if (this.selectedRspRows.length == 0) {
        this.agCheckAllService.notifyCheckBox({ id: this.gridId, state: CheckAllState.UNSELECT });
        this.checkAll = false;
      } else if (this.selectedRspRows.length != this.nbRowSelectedRSPMAX) {
        this.agCheckAllService.notifyCheckBox({ id: this.gridId, state: CheckAllState.INDETERMINATE });
        this.checkAll = false;
      } else if (this.selectedRspRows.length == this.nbRowSelectedRSPMAX  ) {
        this.agCheckAllService.notifyCheckBox({ id: this.gridId, state: CheckAllState.SELECT });
        this.checkAll = true;
      }
    }
    this.aggregateDiffSelected();
  }
  calculateSelectedRsp(): void {
    if (this.checkAll) {
      this.aggregateDiffSelected();
    } else {
      var aggMontant = 0;
       if(this.selectedRspRows.length==0){
         this.montantTotalSelectedRSP = 0;
       }
      this.selectedRspRows.forEach((row) => {
        aggMontant += row.value / 100;
        this.montantTotalSelectedRSP = aggMontant;
      })
      this.aggregateDiffSelected();
      this.totalRspSelected = this.selectedRspRows .length;
    }
  }
  pageChange(currentPage :number){
    this.currentPage =currentPage;
    this.ispageChange=true;
    this.loadData(this.rspRappSummaryDataRaw);
  }
  public loadData(rspRappSummaryDataRawfiltre: RspRappSummaryDataRaw) {
    const ctx = this;
    const sorts: CustomSortDataRow[] = gridConvertSort(ctx.gridHelperRSP.gridSortModel(), []);
      this.rspRappSummaryService.getRappSummarysFilteredList(
        {
          page: ctx.currentPage,
          size: ctx.rspGridDataRaw.size,
          sorts: sorts
        },
          rspRappSummaryDataRawfiltre
      ).subscribe((value) => {
       ctx.gridHelperRSP.gridApi!.setRowData(processData(value.content));
        ctx.rowCount = value.totalPages;
       ctx.totalElement= value.totalElements.toLocaleString('en-US', { minimumFractionDigits: 0 });
       if(ctx.totalElement==1 && this.searchRspFilter){
         this.gridHelperRSP.gridApi.getRenderedNodes()[0].setExpanded(true);
         this.captureExpandedNodes();
       }
        this.restoreExpandedState(this.expandedNodes);
       if(this.checkAll){
         this.selectAllRsp();
       }
    });

    ctx.rspRappSummaryService.getRappTotalCount(
        rspRappSummaryDataRawfiltre
    ).subscribe(value => {
       ctx.nbRowSelectedRSPMAX=value.count;
       ctx.montantckeckAll= value.sum/100;
      });
 /*   if(!this.ispageChange && this.checkAll ){
      this.initSelectedRsp();
    }*/
  }
  initSelectedRsp(): void {
    this.montantTotalSelectedRSP = 0;
    this.totalRspSelected=0;
    this.currentPage = 1;
    this.totalDiffRSP = {
      montant: null,
      status: null
    };
    this.selectedRspRows = [];
    this.unSelectedRspRows = [];
    this.gridHelperRSP.gridApi?.refreshHeader();
    this.agCheckAllService.notifyCheckBox({ id: this.gridId, state: CheckAllState.UNSELECT });
    this.expandedNodes=[];
    this.checkAll=false;
  }
  aggregateDiffSelected(){
    let diffRSP = (this.montantTotalSelectedRSP ? parseFloat(this.montantTotalSelectedRSP.toFixed(2)) : 0) - (this.montantTotalSelectedVrt ? parseFloat(this.montantTotalSelectedVrt.toFixed(2)) : 0);
    this.totalDiffRSP = {
      montant: this.formatThousands.transform(diffRSP),
      status: diffRSP != 0 ? 'text-danger' : 'text-success'
    };
    const totalDiffVRT = {
      montant: this.formatThousands.transform(-diffRSP),
      status: diffRSP != 0 ? 'text-danger' : 'text-success'
    };
    this.totalDiffVRTEvent.emit(totalDiffVRT);
  }
  checkRspSoldability() {
    const rspOrganisms = this.selectedRspRows.map(selectedRsp => selectedRsp.rspRappSummaryDto?.libelleOrganisme);
    this.isRspSoldable = (this.selectedRspRows.length > 0 && rspOrganisms.every(el => el === rspOrganisms[0])) &&
        this.gridHelperVRT.gridApi?.getSelectedRows().length === 0 ;
  }
  checkVrtPointability() {
    this.isVrtPointable = this.gridHelperVRT.gridApi?.getSelectedRows().length > 0 && this.selectedRspRows.length === 0 && !this.checkAll;
  }
  centerContent() {
    return { display: 'flex' , justifyContent : 'center' };
  }
  endContent(): any {
    return { display: 'flex' , justifyContent : 'end' };
  }
  onRspPaginationSizeChange(paginationSize: number): void {
    this.gridHelperRSP.changePaginationSize(paginationSize);
    this.rspGridDataRaw.size = paginationSize;
    this.currentPage = 1;
    this.ispageChange = true;
    this.loadData(this.rspRappSummaryDataRaw);
    this.gridHelperRSP.saveGridStatePrefix();
    this.captureExpandedNodes();
    sessionStorage.setItem('rappManuRspPaginationPageSize', this.rspGridDataRaw.size.toString());
  }

  resetGridStateRSP() {
    this.expandedNodes=[];
    this.gridHelperRSP.resetGridState();
    this.rspGridDataRaw.size=10;
    this.gridHelperRSP.gridApi.paginationGoToPage(1);
    this.initSelectedRsp();
    this.loadData(this.rspRappSummaryDataRaw);
    this.gridHelperRSP.gridApi.deselectAll();
    this.gridHelperRSP.gridApi.forEachNode(node => node.setExpanded(false) );
    this.gridHelperRSP.gridColumnApi.resetColumnState();
    this.gridHelperRSP.gridApi.sizeColumnsToFit();
  }
  restoreGrid(gridHelper: AgGridHelper) {
    gridHelper.restoreGridStatePrefix();
  }
  captureExpandedNodes() {
    this.gridHelperRSP.gridApi.forEachNode(node => {
      if (node.expanded && !this.expandedNodes.some(expandedNode => expandedNode.label=== node.data.label)) {
        this.expandedNodes.push(node.data);
      } else {
        if(!node.expanded){
          this.removeNodeAndChildren(node.data);
        }
      }
    });
    sessionStorage['expandedNodes'] = JSON.stringify(this.expandedNodes);
  }
  removeNodeAndChildren(node: any) {
    const index = this.expandedNodes.findIndex(expandedNode => expandedNode.label === node.label);
    if (index !== -1) {
      node.setExpanded(false);
      this.expandedNodes.splice(index, 1);
    }
    // Supprimer les enfants récursivement
    node.childrenAfterAggFilter?.forEach(childNode => {
      this.removeNodeAndChildren(childNode);
    });
  }
  restoreExpandedState(expandedNodes) {
    this.gridHelperRSP.gridApi .forEachNode(node => {
      if(expandedNodes.some(expandedNode => expandedNode.label=== node.data.label)){
        node.setExpanded(true);
      }
    });
  }

  //  la selection lors de changement de page & checkAll Rsp
  ckeckAllupdateSelection(node: RowNode) {
    const leafNodes = getLeafNodes(node.data);
    leafNodes.forEach(leftNode => {
      if (this.unSelectedRspRows.some(unSelectedRow =>
          unSelectedRow.rspRappSummaryDto.idRspRappSummary === leftNode.rspRappSummaryDto.idRspRappSummary)) {
        node.setSelected(false);
      } else {
        node.setSelected(true);
        this.ispageChange = false;
      }
    });

    if (node.childrenAfterGroup) {
      node.childrenAfterGroup.forEach(childNode => {
        this.ckeckAllupdateSelection(childNode);
      });
    }
  }

  //  la selection lors de changement de page
  updateSelectionState(node: RowNode): void {
    const leafNodes = getLeafNodes(node.data);
    const isSelected = leafNodes.some(leafNode =>
        this.selectedRspRows.some(selectedRow =>
            selectedRow.rspRappSummaryDto.idRspRappSummary === leafNode.rspRappSummaryDto.idRspRappSummary
        )
    );
    node.setSelected(isSelected);
    // Recursively update selection state for children
    if (node.childrenAfterGroup && node.childrenAfterGroup.length > 0) {
      node.childrenAfterGroup.forEach(childNode => {
        this.updateSelectionState(childNode);
      });
    }
  }
  selectionChanged(node: RowNode): void {
    const leafNodes = getLeafNodes(node.data);
    if (node.isSelected()) {
      this.addLeafNodes(leafNodes);
    } else {
      this.removeLeafNodes(leafNodes);
    }

    // Process children recursively
    if (node.childrenAfterGroup && node.childrenAfterGroup.length > 0) {
      node.childrenAfterGroup.forEach(childNode => {
        this.selectionChanged(childNode);
      });
    }
  }

  private addLeafNodes(leafNodes: any[]): void {
    const newLeafNodes = leafNodes.filter(leafNode =>
        !this.selectedRspRows.some(rsp =>
            rsp.rspRappSummaryDto.idRspRappSummary === leafNode.rspRappSummaryDto.idRspRappSummary)
    );
    this.selectedRspRows.push(...newLeafNodes);
    this.ispageChange = false;
  }

  private removeLeafNodes(leafNodes: any[]): void {
    leafNodes.forEach(leafNode => {
      const id = leafNode.rspRappSummaryDto.idRspRappSummary;
      const index = this.selectedRspRows.findIndex(rsp => rsp.rspRappSummaryDto.idRspRappSummary === id);
      if (index !== -1 && !this.ispageChange) {
        this.selectedRspRows.splice(index, 1);
        this.selectedIds.delete(id);
        this.ispageChange = false;
      }
    });
  }
  checkAllSelectionChanged(node: RowNode) {
    if (isNullOrUndefined(node.childrenAfterGroup) || node.childrenAfterGroup.length === 0) {
      // Node has no children or an empty childrenAfterGroup array
      if (node.isSelected() && this.unSelectedRspRows.some(rsp =>rsp.rspRappSummaryDto.idRspRappSummary === node.data.rspRappSummaryDto.idRspRappSummary)) {
        this.selectedRspRows.push(node.data);
        this.totalRspSelected = ++this.totalRspSelected;
        this.montantTotalSelectedRSP += node.data.value/100;
        this.unSelectedRspRows.splice(this.unSelectedRspRows.indexOf(node.data), 1);
      } else {
        if (!node.isSelected() && !this.unSelectedRspRows.some(selectedRow => selectedRow.rspRappSummaryDto.idRspRappSummary === node.data.rspRappSummaryDto.idRspRappSummary)){
          //if(!this.ispageChange){
            this.unSelectedRspRows.push(node.data);
            this.totalRspSelected = --this.totalRspSelected;
            this.montantTotalSelectedRSP -= node.data.value/100;
            this.selectedRspRows.splice(this.selectedRspRows.indexOf(node.data), 1);
        }
      }

    } else {
      node.childrenAfterGroup.forEach(childNode => {
        this.checkAllSelectionChanged(childNode);
      });
    }
  }
}

//get nodes the tree
function getLeafNodes(node: any): any[] {
  const leafNodes: any[] = [];
  function findLeafNodes(currentNode: any) {
    if (currentNode.children && currentNode.children.length > 0) {
      currentNode.children.forEach((childNode: any) => {
        findLeafNodes(childNode);
      });
    } else {
      if(currentNode.node){
        leafNodes.push(currentNode);
      }
    }
  }
  findLeafNodes(node);
  return leafNodes;
}
function processData(data: Tree[]) {
  const flattenedData: Tree[] = [];
  const flattenRowRecursive = (row: any, parentPath: string[]) => {
    const dataPath = [...parentPath, row.uuid];
    flattenedData.push({ ...row, dataPath });
    if (row.children) {
      row.children.forEach((children: any) =>
          flattenRowRecursive(children, dataPath)
      );
    }
  };
  data.forEach((row) => flattenRowRecursive(row, []));
  return flattenedData;
}
