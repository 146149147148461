<div class="full-careweb-container">
  <div id="filter" class="filter-container" carewebFilterMinHeight>
    <div class="filter-container-inner" [ngClass]="{ 'show-content': showFilter, 'init-hidden': initHidden }">
      <careweb-accordion-header-buttons (closeFilters)="toggleFilterDisplay()" (resetFilters)="resetAllFilter()"
        (launchSearch)="launchSearch()"></careweb-accordion-header-buttons>
      <div class="filter-content">
        <mat-accordion displayMode="flat" class="accordion-filter">

          <!-- Identifiant national de PS -->
          <careweb-accordion-group id="numeroPs" title="N° de PS">
            <input type="text" id="numeroPs" class="form-control" [(ngModel)]="filters.numeroPs"
              (keydown.enter)="onKeyDown($event)" onlyNum="9" placeholder="000000000"/>
          </careweb-accordion-group>

          <!-- Date de création -->
          <careweb-accordion-group id="date_creation" title="Date de création">
            <careweb-date-personnalize-filter labelFrom="entre le" [(modelFrom)]="filters.dateCreation"
              [maxDate]="null" (onEnter) = "onKeyDown($event)"></careweb-date-personnalize-filter>
          </careweb-accordion-group>

          <!-- L'initiateur de l'intervention -->
          <careweb-accordion-group id="intervenant" title="Initiateur">
            <ng-select name="intervenant" id="intervenant" placeholder="L'initiateur de l'intervention"
              [(ngModel)]="filters.intervenant"  (keydown.enter)="onKeyDown($event)" appendTo=".filter-container">
              <ng-option *ngFor="let intervenant of intervenants" [value]="intervenant">{{intervenant.name}}
              </ng-option>
            </ng-select>
          </careweb-accordion-group>

          <!--  Motif de l'intervention -->
          <careweb-accordion-group id="motif" title="Motif">
            <ng-select name="motif" id="motif" placeholder="Motif de l'intervention" [searchable]="false"
              [(ngModel)]="filters.motif" (keydown.enter)="onKeyDown($event)" appendTo=".filter-container">
              <ng-option *ngFor="let motif of motifs" [value]="motif.id">{{motif.name}}</ng-option>
            </ng-select>
          </careweb-accordion-group>

          <!-- Date de l'action -->
          <careweb-accordion-group id="date_action" title="Date d'action">
            <careweb-date-personnalize-filter labelFrom="entre le" [(modelFrom)]="filters.dateDebutIntervention"
              [maxDate]="null" (onEnter) = "onKeyDown($event)"></careweb-date-personnalize-filter>
          </careweb-accordion-group>

          <!-- Statut de l'intervention -->
          <careweb-accordion-group id="status" title="Statut">
            <ng-select name="status" id="status" placeholder="Statut de l'intervention" [searchable]="false"
              [(ngModel)]="filters.statut" (keydown.enter)="onKeyDown($event)" appendTo=".filter-container">
              <ng-option *ngFor="let statut of interventionStatut" [value]="statut.id">{{statut.statut}}</ng-option>
            </ng-select>
          </careweb-accordion-group>

          <!-- Service de destinataire de l'intervention -->
          <careweb-accordion-group id="groupe" title="Service du destinataire">
            <ng-select name="groupe" id="groupe" placeholder="Service du destinataire"
              [(ngModel)]="filters.groupe" (keydown.enter)="onKeyDown($event)" appendTo=".filter-container">
              <ng-option *ngFor="let groupe of groupeUsers" [value]="groupe">{{groupe.name}}</ng-option>
            </ng-select>
          </careweb-accordion-group>

          <!--    Référence de l'intervention -->
          <careweb-accordion-group id="idPs" title="Référence">
            <input type="text" id="reference" class="form-control" [(ngModel)]="filters.reference"
                   (keydown.enter)="onKeyDown($event)" onlyNum="9"/>
          </careweb-accordion-group>

          <!--  Agent assigné  -->
          <careweb-accordion-group id="agent" title="Agent assigné ">
            <ng-select placeholder="Agent assigné" [(ngModel)]="filters.agent" appendTo=".filter-container" name="agent"
              class="  mb-3 mt-1" (keydown.enter)="onKeyDown($event)">
              <ng-option *ngFor="let agent of agents " [value]="agent">
                {{agent.value}}</ng-option>
            </ng-select>
          </careweb-accordion-group>
        </mat-accordion>
      </div>
    </div>
  </div>
  <div class="grid-container" id="grid">
    <h2 class="title-border mt-3">Rechercher une intervention</h2>
    <div>
      <section>
        <div class="row grid-top-btn">
          <div class="col-md-4 filter">
            <button type="button" name="btn_toggle_filter" class="btn btn-primary small left-open" [hidden]="showFilter"
              (click)="toggleFilterDisplay()">
              <i class="fa fa-sliders"></i>
              Filtrer
            </button>
            <careweb-pluralize class="filter-count" [messageKey]="'Résultat(s) trouvé(s)'"
              [count]="gridHelper.totalRowCount"></careweb-pluralize>
          </div>
          <div class="col-md-8 conf-col">
            <div class="careweb-toolbar">
              <div [tooltip]="gridHelper.isAvailableData() ? 'Exporter les résultats (.xlsx)' : 'Aucune ligne à exporter, veuillez affiner votre recherche'"
                   placement="top" container="body">
                <span class="btn btn-action small" [class.disabled]="!gridHelper.isAvailableData()"
                       name="btn_export_.xls" (click)="exportXlsx()">
                <i class="fa fa-file-excel-o"></i>
                </span>
              </div>
              <careweb-pagination-size id="dv_buyer_paging" [pageSizes]="[10, 20, 30, 50, 100]"
                [(ngModel)]="gridDataRaw.size" (paginationSizeChange)="onPaginationSizeChange($event)">
              </careweb-pagination-size>
              <span class="btn btn-action small" tooltip="Réinitialiser l'affichage" placement="top" container="body"
                name="spn_reset_grid" (click)="resetGridState()">
                <i class="fa fa-refresh"></i>
              </span>
            </div>
          </div>
        </div>
          <app-alert [export]="export" [exportState]="exportState" (close)="closeAlert()"></app-alert>
        <div class="row grid-top-filter">
          <div class="col-md-12">
            <careweb-filter-label *ngFor="let filter of filterList" (closeFilter)="removeFilter(filter.id)">
              <span *ngIf="filter">{{ filter.label}}: {{filter.value }}</span>
            </careweb-filter-label>
          </div>
        </div>
        <div class="row highlight-row">
          <div class="col-md-12 relative">
            <ag-grid-angular style="width: 100%;" class="ag-theme-balham" [gridOptions]="gridHelper.gridOptions">
            </ag-grid-angular>
            <careweb-pagination [gridApi]="gridHelper.gridApi"></careweb-pagination>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>

<div *ngIf="showDeleteInterventionModal">
  <div class="modal modal-show fade show" id="modalDeleteType" tabindex="-1">
    <div class="modal-dialog modal-md" role="document">
      <div class="modal-content">
        <div class="inmodal">
          <div class="modal-header pb-1">
            <h3 class="modal-title">Suppression</h3>
            <div class="button-bar control">
              <a><i class="fa fa-close" (click)="cancelDelete()"></i></a>
            </div>
          </div>
          <div class="modal-body pb-0">
            <div class="form-block">
              <fieldset class="container-fluid">
                <div class="row">
                  <div class="col-sm-12 col-form-label">Voulez-vous supprimer l'intervention ?</div>
                </div>
                <div class="button-bar control">
                  <button type="button" (click)="cancelDelete()" class="btn btn-default"> Annuler
                  </button>
                  <button type="button" (click)="deleteIntervention()" class="btn btn-primary">
                    <span>Supprimer</span>
                  </button>
                </div>
              </fieldset>
            </div>
          </div>
          <div class="modal-footer">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
