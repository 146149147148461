import { Component, OnInit } from '@angular/core';
import { AgGridHelper } from '../../../components/ag-grid/ag-grid-helper';
import { GridDataRaw } from '../../../data/gridDataRaw';
import { ColDef, ColGroupDef, GetRowIdFunc, GridOptions, GridReadyEvent, SortChangedEvent } from 'ag-grid-community';
import { RappReconciliationRatecomponent } from '../rapp-reconciliation-rate.component';
import { RapprochementComptePsService } from '../../rapprochement/rapprochement.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { RappDashboardPsComponent } from '../rapp-dashboard-ps/rapp-dashboard-ps.component';
import { gridConvertSort } from '../../../components/ag-grid/ag-grid.utils';
import { PerfDashboardFilterRaw } from '../../../data/filters/perf-dashboard-filter';
import { FilterItem } from '../../../data/filters/filter-item';
import { findIndex } from 'lodash';

@Component({
	selector: 'careweb-rapp-dashboard-organism',
	templateUrl: './rapp-dashboard-organism.component.html',
	styleUrls: ['./rapp-dashboard-organism.component.scss']
})
export class RappDashboardOrganismComponent implements OnInit {

	gridOptions;
	gridApi;
	gridHelper: AgGridHelper;
	gridDataRaw: GridDataRaw;
	currentPage = 1;
	rowCount: number;
	groupDefaultExpanded = 0;
	showFilter = false;
	initHidden = true;
	totalElement = 0;
	filters: PerfDashboardFilterRaw = { startDate: new Date(), endDate: new Date() };
	filterPeriode: number = 3;

	matchingKeysRenderer = (params) => {
		if (params.data && params.value) {
			let matchingKey = '';
			for (let i = 0; params.value.length > i; i++) {
				if (params.value[i] !== 'ORGANISME')
					matchingKey += `<span class="badge badge-primary badge-spacer" style="margin-right: 2px;" data-toggle="tooltip" data-placement="top" title="${params.value[i]}">${params.value[i]}</span>`;
			}
			return matchingKey;
		}
		return "";
	};

	matchingTypesRenderer = (params) => {
		if (params.data && params.value) {
			let matchingType = '';
			for (let i = 0; params.value.length > i; i++) {
				matchingType += `<span class="badge badge-warning badge-spacer" style="margin-right: 2px;">${params.value[i]}</span>`;
			}
			return matchingType;
		}
		return "";
	};

	detailsRenderer = (params) => {
		if (!params.data.children) {
			return `<i class="fa fa-eye" container="body" tooltip="Consulter détails du rapprochement" containerClass="tooltip-grid"></i>`;
		}
		return "";
	};

	public columnDefs: (ColDef | ColGroupDef)[] = [
		{
			headerName: 'Organisme',
			field: 'organismName',
			flex: 19,
			sortable: false
		},
		{
			headerName: 'Règles de Clés',
			field: 'matchingKeys',
			cellRenderer: this.matchingKeysRenderer,
			cellClass: ['font-weight-bold'],
			flex: 19,
			sortable: false
		},
		{
			headerName: 'Règles de Dictionnaire',
			field: 'matchingTypes',
			cellRenderer: this.matchingTypesRenderer,
			cellClass: ['font-weight-bold'],
			flex: 19,
			sortable: false
		},
		{
			headerName: '# Non Rapprochés',
			field: 'totalRspNotRapp',
			cellClass: ['font-weight-bold'],
			flex: 19,
			sortable: true,
			sort: 'desc'
		},
		{
			headerName: '% Rapprochement',
			field: 'totalRspRapp',
			cellClass: ['font-weight-bold'],
			cellRenderer: RappReconciliationRatecomponent,
			flex: 19,
			sortable: true
		},
		{
			headerName: '',
			field: 'options',
			cellClass: ['font-weight-bold'],
			cellRenderer: this.detailsRenderer,
			onCellClicked: (params) => this.onShowDetails(params),
			flex: 5,
			sortable: false
		}
	];

	filterList: FilterItem[];

	initGridOptions() {
		this.gridHelper.gridOptions = <GridOptions>(<unknown>{
			columnDefs: this.columnDefs,
			defaultColDef: {
				suppressMenu: true,
				resizable: true,
				sortable: true
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			getRowId: this.getRowId,
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 10,
			paginationPageSize: this.gridDataRaw.size,
			infiniteInitialRowCount: 1,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			localeText: this.gridHelper.getLocaleText(),
			onGridReady: (params) => this.onGridReady(params),
			onSortChanged: (event) => this.onSortChanged(event),
			suppressRowClickSelection: true
		});
	}

	public defaultColDef: ColDef = {
		suppressMenu: true,
		resizable: true,
		filter: false,
	};

	organismName = (params): string => {
		if (params.data && params.data.organismName) return params.data.organismName + ' (' + params.data.children.length + ')';
	};

	public getRowId: GetRowIdFunc = (params) => {
		return params.data.uuid;
	};

	constructor(private rapprochementService: RapprochementComptePsService, private modalService: BsModalService) {
		this.filters.endDate.setDate(this.filters.endDate.getDate() - this.filterPeriode);
		this.filters.startDate.setDate(this.filters.startDate.getDate() - 7 - this.filterPeriode);
		this.gridDataRaw = new GridDataRaw();
		this.gridDataRaw.size = 10;
		this.gridDataRaw.page = 0;
		this.gridHelper = new AgGridHelper('rappPerf', this.gridDataRaw.size);
	}

	ngOnInit() {
		this.parseFilter();
		this.updateFilterList();
		this.initGridOptions();
	}

	removeFilter(id) {
		const ctx = this;
		if (ctx.filterList) {
			delete ctx.filters[id];
			let currentFilterList = ctx.filterList;
			const index = findIndex(currentFilterList, (filt) => filt.id === id);
			if (index != -1) {
				currentFilterList.splice(index, 1);
			}
			ctx.filterList = currentFilterList;
			ctx.launchSearch();
		}
	}

	parseFilter() {
		const parsedFilter = JSON.parse(sessionStorage.getItem('perfOrganismFilter'));
		if (parsedFilter) {
			this.filters = Object.assign({}, parsedFilter);
			if (this.filters.startDate) {
				this.filters.startDate = new Date(this.filters.startDate);
			}
			if (this.filters.endDate) {
				this.filters.endDate = new Date(this.filters.endDate);
			}
		}
	}

	updateFilterList() {
		this.filterList = [];
		if (this.filters.organism) {
			this.filterList.push(new FilterItem()
				.withId('organism')
				.withLabel('Organisme').withValue(this.filters.organism).withValue2(this.filters.organism));
		}
		if (this.filters.keyRule) {
			this.filterList.push(new FilterItem()
				.withId('keyRule')
				.withLabel('Règle de clés').withValue(this.filters.keyRule).withValue2(this.filters.keyRule));
		}
		if (this.filters.dictionaryRule) {
			this.filterList.push(new FilterItem()
				.withId('dictionaryRule')
				.withLabel('Règle de dictionnaire').withValue(this.filters.dictionaryRule).withValue2(this.filters.dictionaryRule));
		}
	}

	onGridReady(params: GridReadyEvent) {
		this.gridHelper.gridApi = params.api;
		this.gridHelper.gridColumnApi = params.columnApi;
		this.restoreGridState();
		this.getPerfData();
	}

	onRowClicked(params) {
		if (!this.gridHelper.isCellTextSelected()) {
			params.node.setExpanded(!params.node.expanded);
		}
	}

	pageChange(currentPage: number) {
		this.currentPage = currentPage;
		this.getPerfData();
	}

	getPerfData() {
		const ctx = this;
		const dataSource = {
			getRows: (paramsRows) => {
				const sorts = gridConvertSort(this.gridHelper.gridSortModel(), []);
				ctx.rapprochementService
					.getPerfDashboard({
						filters: ctx.filters,
						page: ctx.currentPage,
						size: ctx.gridDataRaw.size,
						sorts: sorts
					})
					.subscribe((data) => {
						paramsRows.success({ "rowData": data.content });
						ctx.rowCount = Math.ceil(data.size / this.gridDataRaw.size);
						ctx.totalElement = (data.content.length == 0 || data.size == 0) ? -1 : data.size;
					})
			}
		};
		this.gridHelper.gridApi.setServerSideDatasource(dataSource);
	}

	restoreGrid(gridHelper: AgGridHelper) {
		gridHelper.restoreGridStatePrefix();
	}

	restoreGridState(): void {
		this.gridHelper.restoreGridStatePrefix();
		this.gridDataRaw.size = this.gridHelper.paginationPageSize;
	}

	onDateFilterChange($event) {
		this.currentPage = 1;
		if ($event) {
			if ($event.range) {
				this.filters.startDate = $event.startDate;
				this.filters.endDate = $event.endDate;
				this.filters.startDate.setDate(this.filters.startDate.getDate() - this.filterPeriode);
				this.filters.endDate.setDate(this.filters.endDate.getDate() - this.filterPeriode);
			} else {
				this.filters.startDate = $event.startDate;
				this.filters.endDate = $event.endDate;
			}
			sessionStorage.setItem('perfOrganismFilter', JSON.stringify(this.filters));
			this.getPerfData();
		}
	}

	onSortChanged(event: SortChangedEvent): void {
		this.currentPage = 1;
		this.getPerfData();
	}

	onShowDetails(params) {
		this.modalService.show(RappDashboardPsComponent, {
			initialState: {
				organism: params.data.organismName,
				startDate: this.filters.startDate,
				endDate: this.filters.endDate
			},
			class: 'modal-xl'
		});
	}

	toggleFilterDisplay() {
		if (this.initHidden) {
			this.initHidden = false;
			setTimeout(() => {
				this.showFilter = !this.showFilter;
			}, 10);
		} else {
			this.showFilter = !this.showFilter;
		}
	}

	resetFilter() {
		this.filters.organism = null;
		this.filters.keyRule = null;
		this.filters.dictionaryRule = null;
		this.launchSearch();
	}

	resetGridState() {
		this.gridHelper.resetGridStateRapp();
		this.gridDataRaw.size = 10;
	}

	onKeyDown($event) {
		this.launchSearch();
	}

	launchSearch() {
		sessionStorage.setItem('perfOrganismFilter', JSON.stringify(this.filters));
		this.updateFilterList();
		this.currentPage = 1;
		this.getPerfData();
	}

	onPaginationSizeChange(paginationSize: number): void {
		this.gridHelper.changePaginationSize(paginationSize);
		this.launchSearch();
		this.gridHelper.saveGridStatePrefix();
	}
}