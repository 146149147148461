import { Pipe, PipeTransform } from '@angular/core';

/**
 * formatte un montant du cent en euro
 */
@Pipe({
	name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {
	constructor() {}

	transform(data) {
		if (data === null) {
			data = '0';
		}
		if (data) {
			let amount = parseFloat(data) / 100;
			return amount.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' });
		}
	}
}
