<careweb-help></careweb-help>
<div class="full-careweb-container">
  <div id="filter" class="filter-container" carewebFilterMinHeight>
    <div class="filter-container-inner" [ngClass]="{ 'show-content': showFilter, 'init-hidden': initHidden }">
      <careweb-accordion-header-buttons [disablesearch]="disableSearch()" (closeFilters)="toggleFilterDisplay()" (resetFilters)="resetAllFilter()"
        (launchSearch)="launchSearch()"></careweb-accordion-header-buttons>
        <div class="filter-content">
        <mat-accordion displayMode="flat" class="accordion-filter">
          <!-- Numéro de PS -->
          <careweb-accordion-group id="numero_ps" title="Numéro de PS" [hidden]="!existGroupNumeroPS()" >
            <input type="text" id="input_numero_ps" [(ngModel)]="filters.numeroPs" 
            oninput="this.value=this.value.replace(/[^\d]/,'').replace(/\s/g,'').replace( /\./gi,'');"
            onpaste="this.value=this.value.replace(/[^\d]/,'').replace(/\s/g,'').replace( /\./gi,''); if(this.value.length==9) return false;"
            onKeyPress="if(this.value.length==9) return false;" pattern="^[0-9]" min="0"
            [hidden]="!isAuthorized()" (keydown.enter)="onKeyDown($event)" placeholder="000000000"/>

            <!--Afficher Numéro de PS avec ComboBox si l'utilisateur PS-->
            <ng-select name="numeroPScomboBox" id="numeroPScomboBox" placeholder="Numéro de PS" [searchable]="false"
              [(ngModel)]="filters.numeroPScomboBox" appendTo=".filter-container" [hidden]="isAuthorized()">
              <ng-option *ngFor="let numeroPScomboBox of psDelegueListFilter" [value]="numeroPScomboBox.numeroPsDelegue">
                {{numeroPScomboBox.numeroPsDelegue}}
              </ng-option>
            </ng-select>
          </careweb-accordion-group>
          <!-- Numéro du lot -->
          <careweb-accordion-group id="numero_lot" title="Numéro du lot">
            <input type="text" id="input_num_lot_from" class="form-control" [(ngModel)]="filters.numLot"  maxlength="3"
                   onKeyPress="if(this.value.length==3) return false;" (keydown.enter)="onKeyDown($event)"
                   onpaste="if(this.value.length==3) return false;" />
          </careweb-accordion-group>
          <!-- Lots envoyés -->
          <careweb-accordion-group id="lots_envoyes" title="Lots envoyés" #dateAccordion>
            <careweb-date-from-to-filter labelFrom="entre le" [(modelFrom)]="filters.dateReceptionPartitionFrom" 
             [(modelTo)]="filters.dateReceptionPartitionTo" [(modelExacte)]="filters.dateReceptionPartitionExacte" 
             [maxDate]="null" [isExactePlageChoice]="true" id="lots_envoyes"
             (filterChange)="this.onChoiceChange($event)"
             (dateRangeValidityChange) = "checkDateRangeValidity($event)" (onEnter) = "onKeyDown($event)" #dateEnvoie></careweb-date-from-to-filter>
          </careweb-accordion-group>
          <!-- Rapprochement -->
          <careweb-accordion-group id="id-rapprochement" title="Rapproché">
            <ng-select name="rapprochement" id="rapprochement" placeholder="Rapprochement" [searchable]="false"
            [(ngModel)]="filters.rapprochement" appendTo=".filter-container" (keydown.enter)="onKeyDown($event)">
            <ng-option [value]="''"> </ng-option>
            <ng-option [value]="true">Oui</ng-option>
            <ng-option [value]="false">Non</ng-option>
          </ng-select>
          </careweb-accordion-group>
          <!-- Securise -->
          <careweb-accordion-group id="id-securise" title="Sécurisé">
            <ng-select name="securise" id="securise" placeholder="Sécurisé" [searchable]="false"
            [(ngModel)]="filters.securise" appendTo=".filter-container" (keydown.enter)="onKeyDown($event)">
            <ng-option [value]="''"> </ng-option>
            <ng-option [value]="true">Oui</ng-option>
            <ng-option [value]="false">Non</ng-option>
          </ng-select>
          </careweb-accordion-group>
          <!-- AR_P (ARL provisoire) -->
          <careweb-accordion-group id="id-arp" title="AR_P (ARL provisoire)">
            <ng-select name="arp" id="arp" placeholder="AR_P (ARL provisoire)" [searchable]="false"
            [(ngModel)]="filters.arp" appendTo=".filter-container" (keydown.enter)="onKeyDown($event)">
            <ng-option [value]="''"> </ng-option>
            <ng-option [value]="true">Oui</ng-option>
            <ng-option [value]="false">Non</ng-option>
          </ng-select>
        </careweb-accordion-group>
          <!-- Statut-->
          <careweb-accordion-group id="id-statut" title="Statut">
            <ng-select name="statut" id="statut" placeholder="Statut" [searchable]="false"
            [(ngModel)]="filters.statut" appendTo=".filter-container" (keydown.enter)="onKeyDown($event)">
            <ng-option [value]="''"> </ng-option>
            <ng-option [value]="true">Positif</ng-option>
            <ng-option [value]="false">Négatif</ng-option>
          </ng-select>
          </careweb-accordion-group>
        </mat-accordion>
        </div>
    </div>
  </div>
  <div class="grid-container" id="grid">
    <h2 class="title-border mt-3">Liste des ARL
      <careweb-open-help code="help-001"></careweb-open-help>
    </h2>
    <div>
      <section>
        <div class="row grid-top-btn">
          <div class="col-md-4 filter">
            <button type="button" name="btn_toggle_filter" class="btn btn-primary small left-open" [hidden]="showFilter"
              (click)="toggleFilterDisplay()">
              <i class="fa fa-sliders"></i>
              Filtrer
            </button>
            <careweb-pluralize *ngIf="totalResultsHidden" class="filter-count" [messageKey]="'Résultat(s) trouvé(s)'"
              [count]="arlGridHelper.totalRowCount"></careweb-pluralize>
          </div>
          <div class="col-md-8 conf-col">
            <div class="careweb-toolbar">
             
              <div [tooltip]="arlGridHelper.isAvailableData() ? 'Exporter les résultats (.xlsx)' : 'Aucune ligne à exporter, veuillez affiner votre recherche'"
                   placement="top" container="body">
                <span class="btn btn-action small" [class.disabled]="!arlGridHelper.isAvailableData()"
                       name="btn_export_xlsx" (click)="exportFileXlsxModal()">
                <i class="fa fa-file-excel-o"></i>
                </span>
              </div>


              <careweb-column-selector (itemSelect)="selectColumnEvent($event)"
                (itemDeselect)="deselectColumnEvent($event)"></careweb-column-selector>
              <careweb-pagination-size id="dv_arl_paging" [pageSizes]="[10, 20, 30, 50, 100]" [(ngModel)]="gridDataRaw.size"
                (paginationSizeChange)="onPaginationSizeChange($event)">
              </careweb-pagination-size>
              <span class="btn btn-action small" tooltip="Réinitialiser l'affichage" placement="top" container="body"
                name="spn_reset_grid" (click)="resetGridState()">
                <i class="fa fa-refresh"></i>
              </span>
            </div>
          </div>
        </div>
          <app-alert [export]="export" [exportState]="exportState" (close)="closeAlert()"></app-alert>
        <div class="row grid-top-filter">
          <div class="col-md-12">
              <careweb-filter-label *ngFor="let filter of filterList" (closeFilter)="removeFilter(filter.id)">
                  <span *ngIf="filter">{{ filter.label}}: {{filter.value }}</span>
              </careweb-filter-label>
          </div>
        </div>
        <div class="row highlight-row">
          <div class="col-md-12 relative">
            <ag-grid-angular style="width: 100%;" class="ag-theme-balham ag-theme-arl" [gridOptions]="arlGridHelper.gridOptions">
            </ag-grid-angular>
            <careweb-pagination [gridApi]="arlGridHelper.gridApi"></careweb-pagination>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
