import {ICellRendererAngularComp} from "ag-grid-angular";
import {AfterViewInit, Component} from "@angular/core";
import {isNullOrUndefined} from "util";
import {EbicsService} from "../ps/service/ebics.service";
import { saveAs } from 'file-saver';

@Component({
    selector: 'app-suivi-ebics-preview',
    template: `
        <i [ngClass]="[params.action === 'load' ? 'fa fa-download' : 'fa fa-eye', disabled ? 'disabled' : '']"
        container="body"
        (click)="suiviEbicsActionClick()"
        [tooltip]="tooltip"
        containerClass="tooltip-grid"
	  ></i>
    `
})
export class SuiviEbicsPreviewComponent implements ICellRendererAngularComp, AfterViewInit {
    params: any;
    iconClass: string;
    tooltip = '';
    showConfirmModal = false;
    modalMessage: string;
    disabled: boolean;

    constructor(private ebicsServcie: EbicsService) {
    }
    agInit(params: any): void {
        this.params = params;
    }
    ngAfterViewInit() {
        const cellParams = this.params;
        if (cellParams.data) {
            if (!isNullOrUndefined(cellParams.iconClass)) {
                if((this.params.data.ebicsStatut != 'SUCCESS' && cellParams.action === 'load') || (this.params.data.ebicsStatut != 'SUCCESS' && (cellParams.data.ebicsStatut != 'ERROR' || cellParams.data.erreurCatgorie != 'LOCAL ERROR IN CODE') && cellParams.action === 'load')){
                    this.disabled = true;
                }

                this.iconClass = cellParams.iconClass;
            }

            if (!isNullOrUndefined(cellParams.action)) {
                if (cellParams.action === 'detail') {
                    this.tooltip = 'détail';
                } else if (cellParams.action === 'load') {
                    this.tooltip = "télécharger";
                }
            }
        }
    }

    suiviEbicsActionClick(): void {
        if (
            !isNullOrUndefined(this.params.action) &&
            this.params.action === 'load' &&
            (this.params.data.ebicsStatut === 'SUCCESS' || (this.params.data.ebicsStatut === 'ERROR' && this.params.data.erreurCatgorie === 'LOCAL ERROR IN CODE'))
        ) {
            var segment = this.params.value.split('/');
            var filePath = segment[segment.length - 1];
            this.ebicsServcie.getRessourceFile(filePath).subscribe((response) => {
                saveAs(response.file, response.fileName);
            });
        }
    }
    refresh(): boolean {
        return false;
    }
}
