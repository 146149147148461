import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {CONF} from "../../core/constants";
import {Observable} from "rxjs";

@Injectable()
export class AdminService {
    private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/administration';
    constructor(private httpClient: HttpClient) { }

    updateVideoSettings(videoUrl: string, videoVisibility: boolean ): Observable<any> {
        return this.httpClient.post<any>(
            this.baseUrl + '/update-video-settings/'+
            videoVisibility,videoUrl
        );
    }

    getVideoSettings(){
        return this.httpClient.get<any>(this.baseUrl + '/getSettingsVideo');
    }

}
