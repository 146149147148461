import { Injectable } from "@angular/core";
import {HttpClient,HttpResponse} from '@angular/common/http';
import {CONF} from "../../../core/constants";
import { Observable } from 'rxjs';
import {MouvementsFinanciersDataRaw} from "../../../data/MouvementFinancierDataRaw";
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MouvementFinancierService {

    private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/flux/mouvementFinancier';

    constructor(private httpClient: HttpClient) {}

    getSortParameters(sorts) {
        if (sorts.length === 1) {
            const sort = sorts[0].property;
            return '&sort=' + sort + '&direction=' + sorts[0].direction;
        } else {
            // sort par défaut
            return '&sort=jourComptable&direction=DESC';
        }
    }

    getMvFinancierFilteredList(criteria: any, mouvementsFinanciersDataRaw: MouvementsFinanciersDataRaw): Observable<any> {
        return this.httpClient.post<any>(
            this.baseUrl +
            '/search?page=' +
            criteria.page +
            '&size=' +
            criteria.size +
            '&idOct=' +
            criteria.idOct +
            this.getSortParameters(criteria.sorts),
            mouvementsFinanciersDataRaw
        );

    }
    getMouvementFinancier(criteria: any, mouvementsFinanciersDataRaw: MouvementsFinanciersDataRaw) : Observable<any>{
        return this.httpClient.post<any>(
            this.baseUrl +
              '/search/ps/' +
              criteria.idPs +
              '/mvfinancier?page=' +
              criteria.page +
              '&size=' +
              criteria.size+
              this.getSortParameters(criteria.sorts),
            mouvementsFinanciersDataRaw,
            {
            }
        );
    }

    exportMouvementsFinanciers(criteria: any, mouvementsFinanciersDataRaw: MouvementsFinanciersDataRaw, fields: string[]): Observable<any>{
        mouvementsFinanciersDataRaw.fields= fields;
        return this.httpClient.post<HttpResponse<Blob>>(
            this.baseUrl +
            '/export?idOct=' + criteria.idOct +
            this.getSortParameters(criteria.sorts),
            mouvementsFinanciersDataRaw,

            {
                observe: 'response' as 'body',
                responseType: 'blob' as 'json'
            }
        ).pipe(
            map((response: HttpResponse<Blob>): any => {
                return this.getFileInfosFromResponse(response);
            })
        );

    }

    exportMouvementsFinanciersByEmail(criteria: any,mouvementsFinanciersDataRaw: MouvementsFinanciersDataRaw, fields: string[]): Observable<any>{
        mouvementsFinanciersDataRaw.fields = fields;
        return this.httpClient.post<HttpResponse<Blob>>(
            this.baseUrl +
            '/export-by-email?idOct=' + criteria.idOct +
            this.getSortParameters(criteria.sorts),
            mouvementsFinanciersDataRaw,
            {
                observe: 'response' as 'body',
                responseType: 'blob' as 'json'
            }
        );
    }

    getFileInfosFromResponse(response: HttpResponse<Blob>) {
        let fileName = 'Liste_Mouvements_Financiers.xlsx';
        return {
            file: new Blob([ response.body ], { type: 'application/vnd.ms-excel' }),
            fileName: fileName
        };
    }
}