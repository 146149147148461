export class EbicsParams {
	ebicsHostId: string;
	ebicsUserId: string;
	ebicsUrl: string;
	ebicsCustomerId: string;
	ebicsOrderType: string;
	idPs:number;
	id:number;
	actif:Boolean;
	createdDate:string;
	lastSyncStatut:string;
	lastSyncDate:string;
	bankName:string;
    accountRef:string;
	ebicsFileFormat:string;
	bankId:number;
}
