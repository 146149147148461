import { Component, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
import { ColumnSelectData } from './column-select';

@Component({
	selector: 'careweb-column-selector',
	templateUrl: './grid-column-selector.html',
	styleUrls: [ './column-selector.scss' ]
})
export class ColumnSelectorComponent {
	@Output() itemSelect = new EventEmitter<any>();
	@Output() itemDeselect = new EventEmitter<any>();
	selectedItems: any[];
	items: ColumnSelectData[] = [];
	hidden = true;

	constructor(private elementRef: ElementRef) {}

	/**
     * Fournit l'emission d'un evenement lors de la sélection ou la déselection d'un item.
     * @param selected Boolean permettant de savoir si l'item a été sélectionné ou déselectionné.
     * @param item L'item de l'evenement.
     */
	onChange(selected, item) {
		if (selected) {
			this.itemSelect.emit(item);
		} else {
			this.itemDeselect.emit(item);
		}
	}

	toggleItemSelection(item) {
		item.selected = !item.selected;
		if (item.selected) {
			this.itemSelect.emit(item);
		} else {
			this.itemDeselect.emit(item);
		}
	}

	/**
     * Mutateur de la liste des items selectionnés.
     * @param data L'objet contenant la liste des items selectionnés.
     */
	setSelectedItems(selectedItems) {
		this.selectedItems = selectedItems;
		if (this.items) {
			this.selectedItems.forEach((s) => {
				const item = this.items.find((i) => i.inputData.id === s.id);
				item.selected = true;
			});
		}
	}

	/**
     * Mutateur de la liste des items.
     * @param data L'objet contenant la liste des items.
     */
	setItems(data) {
		this.items = data.map((d) => {
			let formattedName = d.name;
			switch (d.id) {
				case 'nbPartRo':
					formattedName = 'NB Paiement';
					break;
				case 'montantRo':
					formattedName = 'AMO Paiement';
					break;
				case 'montantRc':
					formattedName = 'AMC Paiement';
					break;
				case 'rejetNB':
					formattedName = 'NB Rejet';
					break;
				case 'rejetRO':
					formattedName = 'AMO Rejet';
					break;
				case 'rejetRC':
					formattedName = 'AMC Rejet';
					break;
				default:
					d.name;
			}
			const itemData = new ColumnSelectData(d);
			itemData.id = d.id;
			itemData.label = formattedName;
			return itemData;
		});
	}

	/**
     * Affiche ou cache la liste des items.
     */
	toggleHiden() {
		this.hidden = !this.hidden;
	}

	/**
     *Gère le clic en dehors du composant pour fermer la liste.
     * @param event L'evenement de clic.
     */
	@HostListener('document:click', [ '$event' ])
	clickOutside(event) {
		if (!this.elementRef.nativeElement.contains(event.target)) {
			this.hidden = true;
		}
	}

	/**
     * Optimisseur de modification d'une liste.
     * @param index L'index de l'element.
     * @param item L'element géré.
     */
	trackByIndex(index, item) {
		return index;
	}
}
