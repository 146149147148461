<div class="modal modal-show fade show" id="modalStartMigrationOct" tabindex="-1" role="dialog"
    aria-labelledby="startmigrationModal" *ngIf="showModalStart">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="inmodal">
                <div class="modal-header pb-1">
                    <h3 class="modal-title">Démarrer le processus de migration</h3>
                    <button class="close" name="btn_display_filter" data-dismiss="modal" type="button" title="Fermer"
                        (click)="closeModal()"><span aria-hidden="true">×</span></button>
                </div>
                <div class="modal-body pb-0">
                    <div class="form-block">
                        <form [formGroup]="startForm" (ngSubmit)="onSubmit()">
                            <fieldset class="container-fluid">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="alert alert-info" role="alert">
                                            En entreprenant cette démarche, vous initiez le déclenchement du processus de transfert des messages depuis votre compte OCT antérieur vers votre compte OCT récemment créé.<br><br>
                                            <b>Veuillez sélectionner la boite mail de destination.</b> 
                                          </div>
                                    </div>
                                </div>
<!--                                 <div class="row">
                                    <div class="col-sm-4 col-form-label">Mode d'exécution: </div>
                                    <div class="col-sm-6 form-group">
                                        <ng-select formControlName="debug"
                                        style="width:100%; height:calc(1.5em + .75rem + 2px);" bindLabel="name"
                                        [clearable]="false">
                                        <ng-option *ngFor="let debug of debugArray" [value]="debug">
                                            {{debug.viewValue}}
                                        </ng-option>
                                    </ng-select>
                                    </div>
                                </div> -->
                                <div class="row">
                                    <div class="col-sm-4 col-form-label">Boite mail de destination: </div>
                                    <div class="col-sm-6 form-group">
                                        <ng-select formControlName="siteDestination"
                                        style="width:100%; height:calc(1.5em + .75rem + 2px);" bindLabel="name" [(ngModel)]="selectedSite"
                                        [clearable]="false">
                                        <ng-option *ngFor="let siteEmission of siteEmissionPs" [value]="siteEmission.idSite">
                                              {{siteEmission.balEmettrice}}
                                        </ng-option>
                                    </ng-select>
                                    </div>
                                    <div class="col-sm-2"></div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 align-left">
                                        <i class="fa fa-exclamation-triangle text-info-debug mr-1"></i> Les messages sont copiés puis effacés du compte précédent. Ce processus peut durer plusieurs minutes.
                                    </div>
                                </div>
                                <!-- TOOLBAR -->
                                <div class="button-bar control">
                                    <button type="button" class="btn btn-default" (click)="reset()"> Annuler </button>
                                    <button type="submit" class="btn btn-primary"> Démarrer </button>
                                </div>

                            </fieldset>
                        </form>
                    </div>
                </div>
                <div class="modal-footer">
                </div>
            </div>
        </div>
    </div>
</div>

<careweb-confirm-modal  [title]= "'Arrêter le processus de migration'" (confirmModal)="confirmModal()" (showModal)="showModal($event)"  [(visible)]="showModalStop"
    [text]="modalMessage">
</careweb-confirm-modal>