import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CONF } from '../../../core/constants';

@Injectable({
	providedIn: 'root'
})
export class ComptabiliteService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/flux/comptabilite';

	constructor(private httpClient: HttpClient) {}

	getComptabiliteFileContent(criteria: any) {
		return this.httpClient
			.post<HttpResponse<Blob>>(
				this.baseUrl + '/find',
				JSON.stringify({
					isPsUser: criteria.isPsUser,
					numeroAdeli: criteria.numeroAdeli,
					reportId: criteria.reportId,
					minDate: criteria.beginDate,
					maxDate: criteria.endDate,
					reportType: criteria.reportType,
					octId: criteria.octId
				}),
				{
					headers: new HttpHeaders({
						'Content-Type': 'application/json',
						AcceptEncoding: 'gzip, deflate',
						Accept: '*/*',
						CacheControl: 'no-cache'
					}),
					observe: 'response' as 'body',
					responseType: 'blob' as 'json'
				}
			)
			.pipe(
				map((response: HttpResponse<any>): any => {
					return this.getFileInfosFromResponse(response);
				})
			);
	}

	getFileInfosFromResponse(response: HttpResponse<any>) {
		return {
			file: new Blob([ response.body ], { type: 'application/pdf' }),
			fileName: this.getFileNameFromResponse(response)
		};
	}

	getFileNameFromResponse(response: HttpResponse<object>): string {
		const filenameRegex = /filename="([^"]+)/;
		const fileName = response.headers.get('Content-Disposition').match(filenameRegex)[1];
		return fileName;
	}

}
