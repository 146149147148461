import { Component, OnInit, Input } from '@angular/core';
import { ScrollTopService } from '../../../../../core/utilities/scroll-top.service';
import { AgGridHelper } from '../../../../../components/ag-grid/ag-grid-helper';
import { IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { gridConvertSort } from 'src/main/webapp/src/app/components/ag-grid/ag-grid.utils';
import { PsService } from '../../../ps.service';
import { PsDeleguePreviewComponent } from '../ps-delegue-preview.components';
import { PsFilterRaw } from 'src/main/webapp/src/app/data/filters/psFilterRaw';
import { AuthService } from 'src/main/webapp/src/app/core/services/auth.service';
import { isNullOrUndefined } from 'util';
import { isEmpty } from 'lodash-es';
import { PsDelegueService } from '../../../service/ps-delegue.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'careweb-app-ps-delegue-existant',
	templateUrl: './ps-delegue-existant.component.html',
	styleUrls: ['./ps-delegue-existant.component.scss']
})
export class PsDelegueExistantComponent implements OnInit {
	gridHelper: AgGridHelper;
	paginationSize: number;
	params: any;
	psDataRaw: PsFilterRaw;
	@Input() idPs: Number;
	currentOct;
	psSuperviseSelected: Array<PsFilterRaw>;
	messageNotSelected = false;

	constructor(
		private scrollTopService: ScrollTopService,
		private modalRef: BsModalRef,
		private psSvc: PsService,
		private authSvc: AuthService,
		private psDelegueSVC: PsDelegueService,
		private toastr: ToastrService
	) {
		this.gridHelper = new AgGridHelper('psDelegueExist', 5, 5);
		this.psDataRaw = new PsFilterRaw();
		this.psSuperviseSelected = new Array<PsFilterRaw>();
	}

	ngOnInit() {
		this.currentOct = JSON.parse(localStorage.getItem('octCurrent'));
		this.scrollTopService.setScrollTop();
		this.gridHelper.gridOptions = {
			columnDefs: this.getColumnDefs(),
			defaultColDef: {
				resizable: true,
				suppressMenu: true
			},
			components: {
				customInputFilterComponent: this.getInputFloatingFilterComponent()
			},
			context: { parentComponent: this },
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 5,
			paginationPageSize: 5,
			infiniteInitialRowCount: 1,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			localeText: this.gridHelper.getLocaleText(),
			onGridReady: (params) => this.onGridReady(params),
			// onGridSizeChanged: (params) => this.onGridSizeChanged(params),
			onFilterChanged: (params) => { }
		};
		this.paginationSize = this.gridHelper.paginationPageSize;
	}

	onGridSizeChanged(params) {
		this.gridHelper.gridApi.sizeColumnsToFit();
	}

	restoreGrid() {
		this.gridHelper.restoreGridStatePrefix();
		this.paginationSize = this.gridHelper.paginationPageSize;
	}

	onGridReady(params) {
		const ctx = this;
		ctx.psDataRaw.idPsNot = this.idPs;
		this.gridHelper.gridApi = params.api;
		this.gridHelper.gridColumnApi = params.columnApi;
		this.restoreGrid();
		this.gridHelper.gridApi.sizeColumnsToFit();
		const dataSource: IServerSideDatasource = {
			getRows: function (paramsRows: IServerSideGetRowsParams) {
				const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
				ctx.psSvc
					.searchPsSuperviseByIdOct(
						{
							page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
							size: ctx.gridHelper.gridApi.paginationGetPageSize(),
							idOct: ctx.currentOct.idOct,
							sorts
						},
						ctx.psDataRaw
					)
					.subscribe(
						(data) => {
							const rowCount = data.totalElements;
							ctx.gridHelper.manageNoRowsOverlay(rowCount);
							paramsRows.success({"rowData": data.content, "rowCount": rowCount});
						},
						() => paramsRows.fail()
					);
			}
		};

		this.gridHelper.gridApi.setServerSideDatasource(dataSource);
		this.gridHelper.gridApi.sizeColumnsToFit();
		this.gridHelper.gridApi.hidePopupMenu();
	}

	private getColumnDefs() {
		return [
			{
				headerName: 'Numéro du PS',
				field: 'numeroAdeli',
				minWidth: 190,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true }
			},
			{
				headerName: 'Nom du PS',
				field: 'nom',
				minWidth: 170,
				lockVisible: true,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true }
			},
			{
				headerName: 'Code postal',
				field: 'codepostalEtablissment',
				minWidth: 170,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true }
			},
			{
				headerName: 'Ville',
				field: 'villeEtablissment',
				minWidth: 150,
				lockVisible: true,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true },
				cellStyle: this.gridHelper.centerContent()
			},
			{
				headerName: 'Téléphone',
				field: 'telephoneEtablissment',
				minWidth: 160,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true }
			},
			{
				headerName: 'Fax',
				field: 'faxEtablissment',
				minWidth: 150,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true }
			},
			{
				headerName: '',
				field: 'options',
				minWidth: 70,
				lockVisible: true,
				cellClass: ['link-icon', 'text-center'],
				cellRenderer: PsDeleguePreviewComponent,
				cellStyle: this.gridHelper.centerContent(),
				onCellClicked: (params) => this.attachPs(params.data),
				cellRendererParams: {
					iconClass: 'fa fa-link',
					redirect: 'attach'
				}
			}
		];
	}

	isAuthorized() {
		return this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
	}

	// showCurrentValue(event) {
	//
	// }

	getInputFloatingFilterComponent() {
		const ctx = this;
		function InputFloatingFilter() { }

		InputFloatingFilter.prototype.init = function (params) {
			this.eGui = document.createElement('div');
			this.eGui.setAttribute('class', 'ag-input-wrapper');

			switch (params.filterParams.colDef.field) {
				case 'numeroAdeli':
					this.eGui.innerHTML = this.eGui.innerHTML =
						'<input type="number" class="customFilterInput" style="width:100%; box-shadow: none;" onKeyPress="return this.value.length!==9 && event.charCode >= 48 && event.charCode <= 57" pattern="^[0-9]" min="0" step="1" max="999999999"/>';
					break;
				case 'nom':
				case 'villeEtablissment':
					this.eGui.innerHTML =
						"<input type='text'  class='customFilterInput' style='width:100%; box-shadow: none;' onKeyPress='if(this.value.length==50) return false;'/>";
					break;
				case 'telephoneEtablissment':
				case 'faxEtablissment':
					this.eGui.innerHTML = this.eGui.innerHTML =
						'<input type="number" class="customFilterInput" style="width:100%; box-shadow: none;" onKeyPress="return this.value.length!==16 && event.charCode >= 48 && event.charCode <= 57" pattern="^[0-9]" min="0" step="1" max="99999"/>';
					break;
				case 'codepostalEtablissment':
					this.eGui.innerHTML =
						'<input type="number" class="customFilterInput" style="width:100%; box-shadow: none;" onKeyPress="return this.value.length!==5 && event.charCode >= 48 && event.charCode <= 57" pattern="^[0-9]" min="0" step="1" max="99999"/>';
					break;
				default:
					break;
			}

			this.currentValue = null;
			this.eFilterInput = this.eGui.querySelector('input');
			this.eFilterInput.style.color = params.color;
			const that = this;
			function onInputChanged() {
				const filterField = params.filterParams.colDef.field;
				if (that.eFilterInput.value === '') {
					// Remove the filter
					delete ctx.psDataRaw[filterField];
					params.parentFilterInstance(function (instance) {
						instance.onFloatingFilterChanged(null, null);
					});
					return;
				}

				that.currentValue = that.eFilterInput.value;


				if (that.eFilterInput.value.length >= 3) {
					if (that.currentValue != '') {
						ctx.psDataRaw[filterField] = that.eFilterInput.value;
					}
					params.parentFilterInstance(function (instance) {
						instance.onFloatingFilterChanged('equals', that.currentValue);
					});
				}
			}
			this.eFilterInput.addEventListener('input', onInputChanged);
		};

		InputFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
			if (!parentModel) {
				this.eFilterInput.value = '';
				this.currentValue = null;
			} else {
				this.eFilterInput.value = parentModel.filter + '';
				this.currentValue = parentModel.filter;
			}
		};

		InputFloatingFilter.prototype.getGui = function () {
			return this.eGui;
		};

		return InputFloatingFilter;
	}

	getLibelleSelected() {
		const result = this.psSuperviseSelected.map((ps) => ps.nom);
		return isNullOrUndefined(result)
			? 'PS sélectionné(s): Aucun'
			: isEmpty(result) ? ' PS sélectionné(s): Aucun' : 'PS sélectionné(s): ' + result.join(', ');
	}

	attachPs(psSupervise: PsFilterRaw) {
		this.messageNotSelected = false;
		if (this.psSuperviseSelected.findIndex((psSup) => psSup.idPs === psSupervise.idPs) === -1) {
			this.psSuperviseSelected.push(psSupervise);
		}
	}

	savePsSupervise() {
		this.messageNotSelected = false;
		if (isEmpty(this.psSuperviseSelected) || this.psSuperviseSelected.length === 0) {
			this.messageNotSelected = true;
			return;
		}
		const listIdPs = this.psSuperviseSelected.map((ps) => ps.idPs);
		this.psDelegueSVC.saveSupervise(this.idPs, listIdPs).subscribe(
			(data) => {
				if (data) {
					this.toastr.success('Le(s) PS ont été ajouté avec succès');
					this.modalRef.hide();
				}
			},
			() => {
				this.toastr.error("Le(s) PS n'ont pas pu être ajouté");
			}
		);
	}

	cancel() {
		this.messageNotSelected = false;
		this.psSuperviseSelected = new Array<PsFilterRaw>();
		this.modalRef.hide();
	}
}
