<div class="modal modal-show fade show" id="modalMigrationOct" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel">

    <div class="modal-dialog modal-xxl" role="document">
        <div class="modal-content">
            <div class="inmodal">
                <div class="modal-header pb-1">
                    <h3 class="modal-title">{{title}}</h3>
                    <button class="close" name="btn_display_filter" data-dismiss="modal" type="button" title="Fermer"
                        (click)="closeModal()"><span aria-hidden="true">×</span></button>
                </div>
                <div class="modal-body pb-0">
                    <div class="form-block">
                        <div class="row">
                            <div class="col">
                                <h3 class="title-border">
                                    <div class="row">
                                        <div class="col-auto mr-auto">Règles de parsing des fichiers EBICS </div>
                                        <div class="col-auto"></div>
                                    </div>
                                </h3>
                            </div>
                        </div>
                        <form [formGroup]="addRegleParsingForm" (ngSubmit)="onSubmit()">
                            <fieldset class="container-fluid">
                                <div class="row">
                                    <div class="col-sm-2 col-form-label">Abonnement
                                    </div>
                                    <div class="col-sm-4 form-group">
                                        <ng-select formControlName="ebicsParamsId" bindValue="id"
                                                   style="width:100%; height:calc(1.5em + .75rem + 2px)"
                                                   [placeholder]="placeholder"
                                                   [items]="listAbonnements"
                                                   [ngClass]="{ 'is-invalid': formFields.ebicsParamsId.touched && formFields.ebicsParamsId.errors }"
                                                   [searchable]="false" [clearable]="true" [multiple]="true" [closeOnSelect]="false" required>
                                            <ng-template ng-multi-label-tmp let-items="items">
                                                <div *ngIf="items.length > 0" class="mb-2">
                                                    <span *ngIf="items.length > 1">{{items.length}} abonnements sélectionnés</span>
                                                    <span *ngIf="items.length === 1">{{items.length}} abonnement sélectionné</span>
                                                </div>
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item">
                                                <span>{{'[ '+item.bankName+' ] '}}{{'( '+item.ebicsUserId+' | '+item.ebicsHostId+' )'}}</span>
                                            </ng-template>
                                        </ng-select>
                                        <div *ngIf="submitted && formFields.ebicsParamsId.errors" class="invalid-feedback">
                                            <div *ngIf="formFields.ebicsParamsId.errors.required || formFields.ebicsParamsId.errors.whitespaces">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>
                                        <!-- <div *ngIf="formFields.ebicsParamsId.touched && formFields.ebicsParamsId.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="formFields.ebicsParamsId.errors"> Choisissez
                                                un abonnement.
                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="col-sm-2 col-form-label">Statement </div>
                                    <div class="col-sm-4 form-group">
                                        <input type="text" class="form-control" formControlName="statement"  [ngClass]="{ 'is-invalid': submitted && formFields.statement.errors }"
                                        />
                                        <div *ngIf="submitted && formFields.statement.errors" class="invalid-feedback">
                                            <div *ngIf="formFields.statement.errors.required || formFields.statement.errors.whitespaces">
                                              Ce champ est obligatoire
                                            </div>
                                          </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-2 col-form-label">IBAN</div>
                                    <div class="col-sm-4 form-group">
                                        <input type="text" class="form-control" formControlName="iban"  [ngClass]="{ 'is-invalid': submitted && formFields.iban.errors }"
                                        />
                                        <div *ngIf="submitted && formFields.iban.errors" class="invalid-feedback">
                                            <div *ngIf="formFields.iban.errors.required || formFields.iban.errors.whitespaces">
                                              Ce champ est obligatoire
                                            </div>
                                          </div>
                                    </div>
                                    <div class="col-sm-2 col-form-label">Ntry </div>
                                    <div class="col-sm-4 form-group">
                                        <input type="text" class="form-control" formControlName="ntry"  [ngClass]="{ 'is-invalid': submitted && formFields.ntry.errors }"
                                        />
                                        <div *ngIf="submitted && formFields.ntry.errors" class="invalid-feedback">
                                            <div *ngIf="formFields.ntry.errors.required || formFields.ntry.errors.whitespaces">
                                              Ce champ est obligatoire
                                            </div>
                                          </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-2 col-form-label">Date Import</div>
                                    <div class="col-sm-4 form-group">
                                        <input type="text" class="form-control" formControlName="dateImport"  [ngClass]="{ 'is-invalid': submitted && formFields.dateImport.errors }"
                                        />
                                        <div *ngIf="submitted && formFields.dateImport.errors" class="invalid-feedback">
                                            <div *ngIf="formFields.dateImport.errors.required || formFields.dateImport.errors.whitespaces">
                                              Ce champ est obligatoire
                                            </div>
                                          </div>
                                    </div>
                                    <div class="col-sm-2 col-form-label">Label </div>
                                    <div class="col-sm-4 form-group">
                                        <input type="text" class="form-control" formControlName="label"  [ngClass]="{ 'is-invalid': submitted && formFields.label.errors }"
                                        />
                                        <div *ngIf="submitted && formFields.label.errors" class="invalid-feedback">
                                            <div *ngIf="formFields.label.errors.required || formFields.label.errors.whitespaces">
                                              Ce champ est obligatoire
                                            </div>
                                          </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-2 col-form-label">ID Transaction</div>
                                    <div class="col-sm-4 form-group">
                                        <input type="text" class="form-control" formControlName="transactionId"  [ngClass]="{ 'is-invalid': submitted && formFields.transactionId.errors }"
                                        />
                                        <div *ngIf="submitted && formFields.transactionId.errors" class="invalid-feedback">
                                            <div *ngIf="formFields.transactionId.errors.required || formFields.transactionId.errors.whitespaces">
                                              Ce champ est obligatoire
                                            </div>
                                          </div>
                                    </div>
                                    <div class="col-sm-2 col-form-label">Emetteur </div>
                                    <div class="col-sm-4 form-group">
                                        <input type="text" class="form-control" formControlName="emetteur"  [ngClass]="{ 'is-invalid': submitted && formFields.emetteur.errors }"/>
                                        <div *ngIf="submitted && formFields.emetteur.errors" class="invalid-feedback">
                                            <div *ngIf="formFields.emetteur.errors.required || formFields.emetteur.errors.whitespaces">
                                              Ce champ est obligatoire
                                            </div>
                                          </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-2 col-form-label">Motif</div>
                                    <div class="col-sm-4 form-group">
                                        <input type="text" class="form-control" formControlName="motif"  [ngClass]="{ 'is-invalid': submitted && formFields.motif.errors }"
                                        />
                                        <div *ngIf="submitted && formFields.motif.errors" class="invalid-feedback">
                                            <div *ngIf="formFields.motif.errors.required || formFields.motif.errors.whitespaces">
                                              Ce champ est obligatoire
                                            </div>
                                          </div>
                                    </div>
                                    <div class="col-sm-2 col-form-label">Date Comptable </div>
                                    <div class="col-sm-4 form-group">
                                        <input type="text" class="form-control" formControlName="dateComptable"  [ngClass]="{ 'is-invalid': submitted && formFields.dateComptable.errors }"
                                        />
                                        <div *ngIf="submitted && formFields.dateComptable.errors" class="invalid-feedback">
                                            <div *ngIf="formFields.dateComptable.errors.required || formFields.dateComptable.errors.whitespaces">
                                              Ce champ est obligatoire
                                            </div>
                                          </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-2 col-form-label">Montant</div>
                                    <div class="col-sm-4 form-group">
                                        <input type="text" class="form-control" formControlName="montant"  [ngClass]="{ 'is-invalid': submitted && formFields.montant.errors }"
                                        />
                                        <div *ngIf="submitted && formFields.montant.errors" class="invalid-feedback">
                                            <div *ngIf="formFields.montant.errors.required || formFields.montant.errors.whitespaces">
                                              Ce champ est obligatoire
                                            </div>
                                          </div>
                                    </div>
                                    <div class="col-sm-2 col-form-label">Détail</div>
                                    <div class="col-sm-4 form-group">
                                        <input type="text" class="form-control" formControlName="detail"  [ngClass]="{ 'is-invalid': submitted && formFields.detail.errors }"
                                        />
                                        <div *ngIf="submitted && formFields.detail.errors" class="invalid-feedback">
                                            <div *ngIf="formFields.detail.errors.required || formFields.detail.errors.whitespaces">
                                              Ce champ est obligatoire
                                            </div>
                                          </div>
                                    </div>
                                </div>
                                <!-- TOOLBAR -->
                                <div class="button-bar control">
                                    <button type="button" class="btn btn-default" (click)="reset()"> Annuler </button>
                                    <button type="submit" class="btn btn-primary"> Enregistrer </button>
                                </div>

                            </fieldset>
                        </form>
                    </div>
                </div>
                <div class="modal-footer">
                </div>
            </div>
        </div>
    </div>
</div>
