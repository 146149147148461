import { Directive, HostBinding } from '@angular/core';

@Directive({ selector: '[carewebMinHeight]' })
export class MinHeightDirective {
    @HostBinding('style.minHeight.px')
    minHeight: number;

    constructor() {
        const header = 123;
        const footer = 65;
        this.minHeight = top.innerHeight - (header + footer) + 100;
    }
}
