<div class="modal-header pb-1">
    <h3 class="modal-title">Virements à étudier</h3>
    <button class="close" name="btn_display_filter" data-dismiss="modal" type="button" title="Fermer"
        (click)="closeModal()"><span aria-hidden="true">×</span></button>
</div>
<div class="modal-body pb-0">
    <div class="form-block">
        <fieldset class="container-fluid">
            <div class="row">
                <div class="col">
                    <div class="table-responsive table-fixed-height">
                        <table class="ui-grid-like">
                            <thead>
                                <tr>
                                    <th>Libellé</th>
                                    <th>Montant</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let vrt of virementsAetudier">
                                    <td *ngIf="vrt[3]!==''"
                                        container="body"
                                        [tooltip]="vrt[0]+' : '+vrt[3]"
                                        containerClass="tooltip-grid"
                                        class="overflow-hidden text-truncate rappro-tooltip"
                                        placement="bottom">
                                        {{vrt[0]}} : {{vrt[3].substring(0,30)}}
                                        <span *ngIf="vrt[3].length > 30">[...]</span>
                                    </td>
                                    <td
                                            *ngIf="vrt[3]===''"
                                            container="body"
                                            [tooltip]="vrt[0]"
                                            containerClass="tooltip-grid"
                                            class="overflow-hidden text-truncate rappro-tooltip"
                                            placement="bottom">
                                        {{vrt[0]}}
                                    </td>
                                    <td class="text-nowrap">{{vrt[1] | formatThousands}}</td>
                                    <td>{{vrt[2] | date:'shortDate'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </fieldset>
    </div>
</div>
<div class="modal-footer">
</div>