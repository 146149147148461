import { PadStartPipe } from './../../../core/pipes/pad-start.pipe';
import { OrganismeTypePipe } from './../../../core/pipes/organisme-type.pipe';
import { PhoneNumberFormatterPipe } from './../../../core/pipes/phone-number-formatter.pipe';
import { ColDef, GridOptions, IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { DatePipe } from '@angular/common';
import {Component, OnInit, OnDestroy, HostListener} from '@angular/core';
import { Router } from '@angular/router';
import { findIndex, isEmpty, uniq, uniqBy } from 'lodash-es';
import { isNullOrUndefined } from 'util';
import { AgGridHelper } from '../../../components/ag-grid/ag-grid-helper';
import { gridConvertSort } from '../../../components/ag-grid/ag-grid.utils';
import { AuthService } from '../../../core/services/auth.service';
import { AmcDataRaw } from '../../../data/amcDataRaw';
import { AmoDataRaw } from '../../../data/amoDataRaw';
import { FilterItem } from '../../../data/filters/filter-item';
import { OrganismeFilterRaw } from '../../../data/filters/organismeFilterRaw';
import { OrganismePreviewComponent } from './organismes-subcomponents/organisme-preview.component';
import { OrganismesService } from './organismes.service';
import { BehaviorSubject } from 'rxjs';
import { User } from '../../../data/userDataRaw';
import { RouteHistoryService } from '../../../shared/route-history.service';
import { GridDataRaw } from '../../../data/gridDataRaw';
import { StorageService } from '../../../shared/storage-service';
import { ExportUtilService } from '../../../core/utilities/export-util.service';
import {TypeOrganisme} from "../../../shared/model/organisme.model";
import { saveAs } from 'file-saver';
import {TransformService} from "../../../core/services/transform.service";
import {ExportModalComponent, ExportMode} from "../../export-modal/export-modal.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
//import {TransformService} from "../../../core/services/transform.service";
import {ExportState, State} from "../../../shared/model/storage.model";
@Component({
	selector: 'careweb-app-organismes',
	templateUrl: './organismes.component.html',
	styleUrls: ['./organismes.component.scss']
})
export class OrganismesComponent implements OnInit, OnDestroy {
	params: any;
	initHidden = true;
	showFilter = false;
	gridHelper: AgGridHelper;
	filters: OrganismeFilterRaw;
	filterList: FilterItem[];
	amoDataRow: AmoDataRaw;
	amcDataRow: AmcDataRaw;
	isOrganismRc: boolean = false;
	datePipe: DatePipe;
	userConnected;
	psOcts: Number[];
	idTypePS: Number;
	gridDataRaw: GridDataRaw;
	padStartPipe: PadStartPipe;
	rowCount: number;
	private openedModal: BsModalRef;
	export: boolean = false;
	exportState: ExportState;
	serverName: string;
	static MIN_WIDTH = 1173;
	constructor(
		private organismesSvc: OrganismesService,
		private authSvc: AuthService,
		private router: Router,
		private routeHistory: RouteHistoryService,
		private storageService: StorageService,
		private phoneNumberFormatterPipe: PhoneNumberFormatterPipe,
		private organismeTypePipe: OrganismeTypePipe,
		private modalService: BsModalService,
		private transformService: TransformService
	) {
		//Get grid size from session if already stored
		const sessionPageSize = this.storageService.get('organismesPaginationPageSize', true);
		this.gridDataRaw = new GridDataRaw();
		this.gridDataRaw.size = !isNullOrUndefined(sessionPageSize) ? sessionPageSize : 10;

		this.gridHelper = new AgGridHelper('organismes', this.gridDataRaw.size);
		this.amoDataRow = new AmoDataRaw();
		this.amcDataRow = new AmcDataRaw();
		this.filters = new OrganismeFilterRaw();
		this.padStartPipe = new PadStartPipe();
	}

	ngOnInit() {
		// verify previous url if not details remove session currentPage
		if (this.routeHistory?.getPreviousUrl()?.indexOf('organisme-details') === -1) {
			sessionStorage['organismesCurrentPage'] = null;
		}
		// Init filters
		const parsedFilter = JSON.parse(sessionStorage.getItem('organismeFilter'));
		// Get filter stored in session if exist
		if (!isEmpty(parsedFilter)) {
			this.filters = Object.assign({}, parsedFilter);
			if (isNullOrUndefined(this.filters.typeOrganisme) || isEmpty(this.filters.typeOrganisme)) {
				this.filters.typeOrganisme = 'RO';
			}
		} else {
			this.filterList = [];
			this.filters = new OrganismeFilterRaw();
			this.filters.typeOrganisme = 'RO';
		}

		// Init ag-grid
		this.gridHelper.gridOptions = < GridOptions > ( < unknown > {
			columnDefs: this.getColumnDefs(),
			defaultColDef: {
				resizable: true,
				suppressMenu: true
			},
			components: {
				formatOctLabels: this.formatOctLabels
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,

			cacheBlockSize: 10,
			maxBlocksInCache: 0,
			infiniteInitialRowCount: 1,
			pagination: true,
			paginationPageSize: this.gridDataRaw.size,
			paginateChildRows: true,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			localeText: this.gridHelper.getLocaleText(),
			enableCellTextSelection: true,
			ensureDomOrder: true,
			onRowClicked: (params) => this.onRowClicked(params),
			onGridReady: (params) => this.onGridReady(params),
			// onGridSizeChanged: (params) => this.onGridSizeChanged(params),
			onSortChanged: (params) => this.onSortChanged(params),
			onColumnMoved: (params) => this.onColumnMoved(params)
		});
	}
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		let width = event.target.innerWidth;
		this.setColumnOrder(width);
	}
	setColumnOrder(width :any) {
		if(width<=2135){
			const columnApi = this.gridHelper.gridColumnApi;
			const currentOrder = columnApi.getColumnState();
			const columnIndexToMove = currentOrder.findIndex((col) => col.colId ===  'option');
			if (columnIndexToMove !== -1) {
				columnApi.moveColumn( 'option', 0);
			}
		}else {
			const originalColumnDefs = this.getColumnDefs();
			this.gridHelper.gridApi.setColumnDefs(originalColumnDefs);
			this.gridHelper.gridApi.sizeColumnsToFit();
		}
	}
	onGridReady(params) {
		this.initFilter();
		this.gridHelper.gridApi = params.api;
		this.gridHelper.gridColumnApi = params.columnApi;
		this.restoreGrid();
		this.getOrganismesList();
		this.setColumnOrder(window.innerWidth);
	}

	onGridSizeChanged(params) {
		this.gridHelper.gridApi.sizeColumnsToFit();
	}

	getOrganismesList() {
		const ctx = this;
		const typeOrganisme = this.filters.typeOrganisme;
		this.filters.sigle = this.filters.sigle ? this.filters.sigle.trim() : null;
		this.filters.libelle = this.filters.libelle ? this.filters.libelle.trim() : null;
		this.filters.commentaire = this.filters.commentaire ? this.filters.commentaire.trim() : null;

		if (typeOrganisme !== 'RC') {
			this.amoDataRow.libelleCourt = this.filters.sigle || null;
			this.amoDataRow.libelleLong = this.filters.libelle || null;
			this.amoDataRow.actif = isNullOrUndefined(this.filters.actif) ? null : this.filters.actif;
			this.amoDataRow.commentaires = this.filters.commentaire || null;

			this.amoDataRow.codeGr = this.filters.codeGrandRegime;
			this.amoDataRow.codeCaisseCas = this.filters.codeCaisseCAS;
			this.amoDataRow.codeCentreCas = this.filters.codeCentreCAS;

			// vider les filtres de RC
			this.filters.codeOfficiel = null;
			this.filters.codeEntree = null;
		} else if (typeOrganisme === 'RC') {
			this.amcDataRow.libelleCourt = this.filters.sigle || null;
			this.amcDataRow.libelleLong = this.filters.libelle || null;
			this.amcDataRow.actif = isNullOrUndefined(this.filters.actif) ? null : this.filters.actif;
			this.amcDataRow.commentaires = this.filters.commentaire || null;
			this.amcDataRow.codeOfficiel = this.filters.codeOfficiel;
			this.amcDataRow.aidePerenne = isNullOrUndefined(this.filters.aidePerenne) ? null : this.filters.aidePerenne;
			this.filters.codeEntree = this.filters.codeEntree ? this.padStartPipe.transform(this.filters.codeEntree.trim(), 10, '0') : null;
			this.amcDataRow.libelleCodeEntree = this.filters.codeEntree;

			// vider les filtres de RO
			this.filters.codeGrandRegime = null;
			this.filters.codeCaisseCAS = null;
			this.filters.codeCentreCAS = null;
		}

		this.updateFilterList(this.filters);
		Object.keys(this.amcDataRow).forEach((key) => {
			if (isNullOrUndefined(this.amcDataRow[key])) {
				delete this.amcDataRow[key];
			}
		});

		const dataSource: IServerSideDatasource = {
			getRows: function (paramsRows: IServerSideGetRowsParams) {
				const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
				if (typeOrganisme !== 'RC') {
					ctx.isOrganismRc = false;
					ctx.organismesSvc
						.getOrganismesRo({
								page: ctx.gridHelper.gridApi.paginationGetCurrentPage() ,
								size: ctx.gridHelper.gridApi.paginationGetPageSize(),
								sorts
							},
							ctx.amoDataRow
						)
						.subscribe(
							(data) => {
								ctx.rowCount = data.totalElements;
								ctx.gridHelper.manageNoRowsOverlay(ctx.rowCount);
								paramsRows.success({
									"rowData": data.content,
									"rowCount": ctx.rowCount
								});
								const pageN = Number.parseInt(sessionStorage.getItem('organismesCurrentPage'));
								if (
									!isNullOrUndefined(pageN) &&
									pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
									pageN > 0
								) {
									ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
								}
								sessionStorage['organismesCurrentPage'] = null;
							},
							() => paramsRows.fail()
						);
				} else if (typeOrganisme === 'RC') {
					ctx.isOrganismRc = true;
					// if connected user is PS
					this.userConnected = new BehaviorSubject < User > (JSON.parse(localStorage.getItem('careweb_user')));
					let idPs = -1;
					if (!isNullOrUndefined(this.userConnected.value) && this.userConnected.value.idTypeUser == 2) {
						idPs = this.userConnected.value.idPs;
					}

					ctx.organismesSvc
						.getOrganismesRc({
								page: ctx.gridHelper.gridApi.paginationGetCurrentPage() ,
								size: ctx.gridHelper.gridApi.paginationGetPageSize(),
								sorts
							},
							ctx.amcDataRow,
							idPs
						)
						.subscribe(
							(data) => {
								ctx.rowCount = data.totalElements;
								ctx.gridHelper.manageNoRowsOverlay(ctx.rowCount);
								paramsRows.success({
									"rowData": data.content,
									"rowCount": ctx.rowCount
								});
								const pageN = Number.parseInt(sessionStorage.getItem('organismesCurrentPage'));
								if (
									!isNullOrUndefined(pageN) &&
									pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
									pageN > 0
								) {
									ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
								}
								sessionStorage['organismesCurrentPage'] = null;
							},
							() => paramsRows.fail()
						);
				}
			}
		};
		this.gridHelper.gridApi.setServerSideDatasource(dataSource);
	}

	restoreGrid() {
		this.gridHelper.restoreGridStatePrefix();
		this.gridDataRaw.size = this.gridHelper.paginationPageSize;
	}

	agInit(params: any): void {
		this.params = params;
	}

	updateOrganismeView($event) {
		this.isOrganismRc = false;
		this.filters.typeOrganisme = $event;
		if (this.filters.typeOrganisme === 'RC') {
			this.isOrganismRc = true;
		}
		this.gridHelper.gridApi.setColumnDefs(this.getColumnDefs());
		this.gridHelper.refreshView();
		this.launchSearch();
	}

	toggleFilterDisplay() {
		let width =window.innerWidth;
		if (this.initHidden) {
			this.initHidden = false;
			setTimeout(() => {
				this.showFilter = !this.showFilter;
			}, 10);
		} else {
			this.showFilter = !this.showFilter;
			if(!this.showFilter && width>2500){
                setTimeout(() => {
                    this.gridHelper.gridApi.sizeColumnsToFit();
                }, 200);
			}
		}
	}

	resetGridState() {
		this.gridHelper.resetGridState();
		this.gridDataRaw.size = this.gridHelper.defaultPaginationPageSize;
		//this.resetAllFilter();
	}

	refresh(): boolean {
		return false;
	}

	resetAllFilter(): void {
		this.filters = new OrganismeFilterRaw();
		this.filters.typeOrganisme = 'RO';
		this.launchSearch();
		this.organismesSvc.reset();
	}

	launchSearch(): void {
		this.getOrganismesList();
		sessionStorage['organismeFilter'] = JSON.stringify(this.filters);
	}

	initFilter() {
		// Liste des filtres
		this.resetFilter();
		this.updateFilterList(this.filters);
	}

	resetFilter() {
		this.organismesSvc.reset();
	}

	toggleFilter(): void {}

	private getColumnDefs(): ColDef[] {
		const ctx = this;
		let typeOrganisme = this.filters.typeOrganisme;
		if (typeOrganisme !== 'RC') {
			return [{
					headerName: 'ID Organisme',
					field: 'idOrganisme',
					minWidth: 150,
					lockVisible: true,
					cellStyle: this.gridHelper.centerContent(),
					sortable: true,
					hide: !this.isAuthorized()
				},
				{
					headerName: 'Actif',
					field: 'actif',
					minWidth: 80,
					maxWidth: 80,
					lockVisible: true,
					cellStyle: this.gridHelper.centerContent(),
					cellRenderer: this.stateIconRenderer,
					sortable: true
				},
				{
					headerName: 'Sigle',
					field: 'libelleCourt',
					minWidth: 150,
					lockVisible: true,
					sortable: true
				},
				{
					headerName: 'Libellé',
					field: 'libelleLong',
					minWidth: 180,
					lockVisible: true,
					sortable: true
				},
				{
					headerName: 'Téléphone',
					field: 'telephone',
					valueFormatter: (params) => this.phoneNumberFormatterPipe.transform(params.value),
					minWidth: 100,
					cellStyle: this.gridHelper.centerContent(),
					lockVisible: true,
					sortable:false
				},
				{
					headerName: 'Commentaire',
					field: 'commentaires',
					minWidth: 300,
					lockVisible: true,
					sortable: false
				},
				{
					headerName: 'Régime',
					field: 'typeOrganisme',
					valueFormatter: (params) => this.organismeTypePipe.transform(params.value),
					minWidth: 80,
					maxWidth: 80,
					lockVisible: true,
					cellStyle: this.gridHelper.centerContent()
				},
				{
					headerName: 'Tous Centres',
					field: 'tousCentres',
					minWidth: 100,
					maxWidth: 100,
					lockVisible: true,
					cellRenderer: this.stateIconRenderer,
					cellStyle: this.gridHelper.centerContent(),
					sortable: true
				},
				{
					headerName: 'Code G-R',
					field: 'codeGr',
					minWidth: 100,
					lockVisible: true,
					cellStyle: this.gridHelper.centerContent(),
					sortable: true
				},
				{
					headerName: 'Caisse C.A.S',
					field: 'codeCaisseCas',
					minWidth: 100,
					lockVisible: true,
					cellStyle: this.gridHelper.centerContent(),
					sortable: true
				},
				{
					headerName: 'Centre C.A.S',
					field: 'codeCentreCas',
					minWidth: 100,
					lockVisible: true,
					cellStyle: this.gridHelper.centerContent(),
					sortable: true
				},
				{
					headerName: '',
					field: 'options',
					minWidth: 50,
					maxWidth: 50,
					cellStyle: this.gridHelper.centerContent(),
					cellRenderer: OrganismePreviewComponent,
					cellRendererParams: {
						iconClass: 'fa fa-eye',
						action: 'view',
						redirect: 'organismeDetails',
						organisme: ctx,
						typeOrganisme: ctx.filters.typeOrganisme
					},
					hide: !ctx.isPs()
				},
				{
					headerName: '',
					field: 'option',
					minWidth: 50,
					maxWidth: 50,
					cellStyle: this.gridHelper.centerContent(),
					cellRenderer: OrganismePreviewComponent,
					cellRendererParams: {
						iconClass: 'fa fa-edit',
						action: 'edit',
						redirect: 'organismeDetails',
						organisme: ctx,
						typeOrganisme: ctx.filters.typeOrganisme,
						type: 'organisme'
					},
					hide: !ctx.isAuthorized()
				}
			];
		} else {
			return [{
					headerName: 'ID Organisme',
					field: 'idOrganisme',
					minWidth: 100,
					maxWidth: 150,
					cellStyle: this.gridHelper.centerContent(),
					lockVisible: true,
					sortable: true,
					hide: !this.isAuthorized()
				},
				{
					headerName: '',
					field: 'warning',
					minWidth: 50,
					maxWidth: 50,
					lockVisible: true,
					cellStyle: this.gridHelper.centerContent(),
					cellRenderer: OrganismePreviewComponent,
					cellRendererParams: {
						iconClass: 'fa fa-exclamation-circle',
						action: 'warning',
						organisme: ctx
					}
				},
				{
					headerName: 'Actif',
					field: 'actif',
					minWidth: 30,
					lockVisible: true,
					cellStyle: this.gridHelper.centerContent(),
					cellRenderer: this.stateIconRenderer,
					sortable: true
				},
				{
					headerName: 'Sigle',
					field: 'libelleCourt',
					minWidth: 200,
					lockVisible: true,
					sortable: true
				},
				{
					headerName: 'Libellé',
					field: 'libelleLong',
					minWidth: 150,
					lockVisible: true,
					sortable: true
				},
				{
					headerName: 'Code officiel',
					field: 'codeOfficiel',
					minWidth: 150,
					lockVisible: true,
					cellStyle: this.gridHelper.centerContent(),
					sortable: true
				},
				{
					headerName: 'Téléphone',
					field: 'telephone',
					valueFormatter: (params) => this.phoneNumberFormatterPipe.transform(params.value),
					minWidth: 120,
					cellStyle: this.gridHelper.centerContent(),
					lockVisible: true,
					sortable: false
				},
				{
					headerName: 'Commentaire',
					field: 'commentaires',
					minWidth: 300,
					lockVisible: true,
					sortable: false
				},
				{
					headerName: 'OCT*',
					field: 'oct',
					minWidth: 150,
					lockVisible: true,
					hide: !ctx.isAuthorized(),
					cellRenderer: this.formatOctLabels
				},
				{
					headerName: 'Régime',
					field: 'typeOrganisme',
					valueFormatter: (params) => this.organismeTypePipe.transform(params.value),
					minWidth: 110,
					lockVisible: true,
					cellStyle: this.gridHelper.centerContent()
				},
				{
					headerName: 'Début',
					field: 'dateDebutValidite',
					minWidth: 130,
					lockVisible: true,
					cellStyle: this.gridHelper.centerContent(),
					sortable: true
				},
				{
					headerName: 'Fin',
					field: 'dateFinValidite',
					minWidth: 150,
					lockVisible: true,
					cellStyle: this.gridHelper.centerContent(),
					sortable: true
				},
				{
					headerName: 'Code entrée',
					field: 'libelleCodeEntree',
					minWidth: 150,
					lockVisible: true
				},
				{
					headerName: 'Aide pérenne',
					field: 'aidePerenne',
					minWidth: 120,
					lockVisible: true,
					cellRenderer: this.stateIconRenderer,
					cellStyle: this.gridHelper.centerContent(),
					sortable: true
				},
				{
					headerName: '',
					field: 'options',
					minWidth: 70,
					maxWidth: 70,
					cellStyle: this.gridHelper.centerContent(),
					cellRenderer: OrganismePreviewComponent,
					cellRendererParams: {
						iconClass: 'fa fa-eye',
						action: 'view',
						redirect: 'organismeDetails',
						organisme: ctx,
						typeOrganisme: ctx.filters.typeOrganisme
					},
					hide: !ctx.isPs()
				},
				{
					headerName: '',
					field: 'option',
					minWidth: 70,
					maxWidth: 70,
					cellStyle: this.gridHelper.centerContent(),
					cellRenderer: OrganismePreviewComponent,
					cellRendererParams: {
						iconClass: 'fa fa-edit',
						action: 'edit',
						redirect: 'organismeDetails',
						organisme: ctx,
						typeOrganisme: ctx.filters.typeOrganisme
					},
					hide: !ctx.isAuthorized()
				}
				/*,{
					headerName: '',
					field: 'options',
					minWidth: 70,
					maxWidth: 70,
					cellStyle: this.gridHelper.centerContent(),
					cellRenderer: OrganismePreviewComponent,
					cellRendererParams: {
						iconClass: 'fa fa-trash',
						action: 'delete',
						organisme: ctx
					},
					hide: !ctx.isAuthorized()
				}*/
			];
		}
	}

	isAuthorized() {
		return this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
	}

	isPs() {
		return this.authSvc.userAuthorized('PS');
	}

	onKeyDown($event) {
		if ( this.filters.sigle ) {
			if ( this.filters.sigle.length >= 3 ) {
				this.launchSearch();
			}
		} else {
			this.launchSearch();
		}
	}

	updateFilterList(filters) {
		const ctx = this;
		ctx.filterList = [];
		Object.keys(filters).forEach((key) => {
			if (filters[key] !== null && filters[key] !== '') {
				ctx.filterList.push(ctx.translateFilterLabelValue(key, filters[key]));
			}
		});
		// Remove duplicated ids
		ctx.filterList = uniqBy(ctx.filterList, 'id');
		// filter null
		ctx.filterList = ctx.filterList.filter((item) => {
			return item.id != null;
		});
	}

	translateFilterLabelValue(key, value) {
		let translatedKey = key;
		let formattedValue = value;
		let formattedKey = key;
		switch (key) {
			case 'sigle':
				translatedKey = 'Sigle';
				break;
			case 'libelle':
				translatedKey = "Libellé de l'organisme";
				break;
			case 'commentaire':
				translatedKey = 'Commentaire';
				break;
			case 'typeOrganisme':
				translatedKey = "Type de l'organisme";
				formattedValue = this.organismeTypePipe.transform(value);
				break;
			case 'codeGrandRegime':
				translatedKey = 'Code grand régime';
				break;
			case 'actif':
				translatedKey = 'Etat des organismes';
				formattedValue = value ? 'Actif' : 'Non actif';
				break;
			case 'codeCaisseCAS':
				translatedKey = 'Code caisse C.A.S';
				break;
			case 'codeCentreCAS':
				translatedKey = 'Code centre C.A.S';
				break;
			case 'codeOfficiel':
				translatedKey = 'Code officiel';
				break;
			case 'codeEntree':
				translatedKey = 'Code entrée';
				break;
			case 'aidePerenne':
				translatedKey = 'Aide pérenne';
				formattedValue = value ? 'Oui' : 'Non';
				break;
			default:
				break;
		}

		return new FilterItem().withId(formattedKey).withLabel(translatedKey).withValue(formattedValue).withValue2(value);
	}

	removeFilter(id) {
		if (id !== 'typeOrganisme') {
			const ctx = this;
			delete ctx.filters[id];
			let currentFilterList = ctx.filterList;
			const index = findIndex(currentFilterList, (filt) => filt.id === id);
			currentFilterList.splice(index, 1);
			ctx.filterList = currentFilterList;
			this.fillFilterObjectFromTagsOnly();
			ctx.launchSearch();
		}
	}

	fillFilterObjectFromTagsOnly() {
		this.filters = new OrganismeFilterRaw();
		this.filterList.forEach(filter => {
			this.filters[filter.id] = filter.value2;
		});
	}

	ngOnDestroy() {
		sessionStorage['organismeFilter'] = JSON.stringify(this.filters);
		sessionStorage['organismesCurrentPage'] = this.gridHelper.gridApi.paginationGetCurrentPage();
		this.gridHelper.saveGridStatePrefix();
	}

	onRowClicked(params) {
		let typeOrganisme = '';
		if (!isNullOrUndefined(this.filters.typeOrganisme)) {
			typeOrganisme = this.filters.typeOrganisme;
		} else {
			typeOrganisme = 'RO';
		}

		//var selection = getSelection();
		if (!this.gridHelper.isCellTextSelected()) {
			this.router.navigate(['/organisme-details', params.data.idOrganisme, typeOrganisme]);
		}

		// if (params.api.getFocusedCell().column.colId !== 'idOrganisme') {
		// 	this.router.navigate([ '/organisme-details', params.data.idOrganisme, typeOrganisme ]);
		// }
	}

	stateIconRenderer = (params): string => {
		if (!isNullOrUndefined(params.value)) {
			return params.value ?
				'<span><i class="fa fa-check green"></i></span>' :
				'<span><i class="fa fa-close red"></i></span>';
		} else {
			return '';
		}
	};

	formatOctLabels = (params): string => {
		if (params.data && params.data.oct) {
			let octs = params.data.oct;
			octs.trim();
			const octLabelsArray = uniq(octs.split(',').filter((item) => item !== ' '));
			let renderedLabels = '';
			octLabelsArray.forEach((oct) => {
				renderedLabels =
					renderedLabels +
					"<span class='badge badge-pill badge-primary mr-1 oct-badge' [ngClass]='statusClass' placement='top' container='body' containerClass='tooltip-grid'>" +
					oct +
					'</span>';
			});
			return renderedLabels;
		}
	}

	onPaginationSizeChange(paginationSize: number): void {
		this.gridHelper.changePaginationSize(paginationSize);
		this.getOrganismesList();
		this.gridHelper.saveGridStatePrefix();
	}

	onSortChanged(params: any): void {
		this.gridHelper.saveGridSort();
	}

	onColumnMoved(params: any): void {
		this.gridHelper.saveGridColumnState();
	}

	exportFileXlsModal() {
		const columns = this.gridHelper.gridColumnApi.getAllDisplayedColumns()
			.filter(column => column.getColDef().headerName != "")
			.map(column => column.getColDef().field);
		if (this.rowCount > 0 && this.rowCount <= 1000) {
			this.exportToExcel(columns);
		} else if (this.rowCount > 1000) {
			this.openedModal = this.modalService.show(ExportModalComponent,  { initialState: {
					nbrElement: this.rowCount
				}, class: 'modal-lg'});
			this.openedModal.content.exportEvent.subscribe((e: any) => {
				if (e.action === ExportMode.EXPORT) {
					// this.exportFileXlsx();
					this.exportToExcel(columns);
				}
				if (e.action === ExportMode.EMAIL) {
					// this.exportFileXlsx();
					this.exportByEmail(columns);
				}
			});
		}
	}

	exportToExcel(columns): void {
		this.export = true;
		this.exportState = {
			message : "Vous allez exporter <b>" + this.transformService.transform(this.rowCount) + " ligne(s)</b>, veuillez patienter quelques instants.",
			state: State.INPROGRESS
		}
		const sorts = gridConvertSort(this.gridHelper.gridSortModel(), []);
		if (this.filters.typeOrganisme == TypeOrganisme.AMO) {
			this.organismesSvc.exportOrganismeAmo(
				{
					page: this.gridHelper.gridApi.paginationGetCurrentPage() ,
					size: this.gridHelper.gridApi.paginationGetPageSize(),
					export: false,
					sorts
				},
				this.amoDataRow,
				columns
			).subscribe((response) => {
				saveAs(response.file, response.fileName);
				this.exportState = {
					message : "Votre fichier a été téléchargé avec succès.",
					state: State.SUCCESS
				}
			},() => {
				this.exportState = {
					message : "Le téléchargement de votre fichier a rencontré un problème.",
					state: State.FAILED
				}
			});
		}else if (this.filters.typeOrganisme == TypeOrganisme.AMC) {
			this.organismesSvc.exportOrganismeAmc(
				{
					page: this.gridHelper.gridApi.paginationGetCurrentPage() ,
					size: this.gridHelper.gridApi.paginationGetPageSize(),
					export: false,
					sorts
				},
				this.amcDataRow,
				columns
			).subscribe((response)=> {
				saveAs(response.file, response.fileName);
				this.exportState = {
					message : "Votre fichier a été téléchargé avec succès.",
					state: State.SUCCESS
				}
			},() => {
				this.exportState = {
					message : "Le téléchargement de votre fichier a rencontré un problème.",
					state: State.FAILED
				}
			});
		}
	}

	exportByEmail(columns){
		const sorts = gridConvertSort(this.gridHelper.gridSortModel(), []);
		if(this.filters.typeOrganisme == TypeOrganisme.AMO) {
			this.organismesSvc.exportOrganismeAmoByEmail(
				{
					page: this.gridHelper.gridApi.paginationGetCurrentPage(),
					size: this.gridHelper.gridApi.paginationGetPageSize(),
					export: false,
					sorts
				},
				this.amoDataRow,
				columns
			).subscribe(() => {
			});
		} else if (this.filters.typeOrganisme == TypeOrganisme.AMC) {
			this.organismesSvc.exportOrganismeAmcByEmail(
				{
					page: this.gridHelper.gridApi.paginationGetCurrentPage(),
					size: this.gridHelper.gridApi.paginationGetPageSize(),
					export: false,
					sorts
				},
				this.amcDataRow,
				columns
			).subscribe(() => {

			});
		}
	}
	closeAlert() {
		this.export = false;
	}
	
	exportExcelClient(){
		let excel = new ExportUtilService();
		const sorts = gridConvertSort(this.gridHelper.gridSortModel(), []);
		const columnKeys = this.gridHelper.gridColumnApi.getColumnState().filter(column => {
			return ((column.colId != 'options_1') && (column.colId != 'options'));
		}).map(column => {
			return column.colId;
		});
		let headers: Array < String > = [];
		let map = this.getColumnsAndFieldDataAmo(columnKeys, this.amoDataRow);
		for (let key of map.keys()) {
			headers.push(key)
		}
		if (this.filters.typeOrganisme == TypeOrganisme.AMO) {
			excel.setNameSheet('Amo');
			excel.setCellTitle('D1');
			excel.setTitle('Liste Des AMO');
			excel.setFontTitleSize(30, true);
			excel.setHeadersToExport(headers);
			excel.setFontHeaderSize(14, true);
			this.organismesSvc.exportAmo({
				sorts
			}, this.amoDataRow).subscribe((dataSource) => {
				dataSource.content.forEach((data) => {
					let map = this.getColumnsAndFieldDataAmo(columnKeys, data);
					let jsonObject = [];
					map.forEach((value, key) => {
						jsonObject[key] = value
					});
					excel.setRowData(jsonObject);
					excel.SetWidthBeetwenColumns(20);
				})
				excel.addBorderStyle();
				excel.ExportData('Liste-Des-AMO')
			});
		}
	}

	getColumnsAndFieldDataAmo(columnKeys: string[], dataFields ? : any) {
		let data = new Map();
		columnKeys.forEach(column => {
			switch (column) {
				case "idOrganisme":
					if (this.isAuthorized()) {
						data.set("ID Organisme", dataFields.idOrganisme);
						break;
					}
					break;
				case "actif":
					data.set("Actif", dataFields.actif ? "Oui" : "Non");
					break;
				case "libelleCourt":
					data.set("Sigle", dataFields.libelleCourt);
					break;
				case "libelleLong":
					data.set("Libellé", dataFields.libelleLong);
					break;
				case "telephone":
					data.set("Téléphone", this.phoneNumberFormatterPipe.transform(dataFields.telephone));
					break;
				case "commentaires":
					data.set("Commentaire", dataFields.commentaires);
					break;
				case "typeOrganisme":
					data.set("Régime", "Amo");
					break;
				case "tousCentres":
					data.set("Tous Centres", dataFields.tousCentres ? "Oui" : "Non");
					break;
				case "codeGr":
					data.set("Code G-R", dataFields.codeGr);
					break;
				case "codeCaisseCas":
					data.set("Caisse C.A.S", dataFields.codeCaisseCas);
					break;
				case "codeCentreCas":
					data.set("Centre C.A.S", dataFields.codeCentreCas);
					break;
			}
		})
		return data;
	}
}
