import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { isNullOrUndefined } from 'util';

/*
	Allow to render icons on Organisme table
*/

@Component({
	selector: 'careweb-abonnement-preview',
	template: `
      <i [hidden]="hide"
        [ngClass]="iconClass"
        container="body"
        (click)="abonnementActionClick()"
        [tooltip]="tooltip"
        containerClass="tooltip-grid"
	  ></i>
    `
})
export class AbonnementPreviewComponent implements ICellRendererAngularComp, AfterViewInit {
	private params: any;
	iconClass: string;
	tooltip = '';
	hide = false;
	showConfirmModal = false;
	modalMessage: string;

	constructor(private router: Router) {}

	agInit(params: any): void {
		this.params = params;
	}

	ngAfterViewInit() {
		const cellParams = this.params;
		if (cellParams.data) {
			if (!isNullOrUndefined(cellParams.iconClass)) {
				this.iconClass = cellParams.iconClass;
			}

			if (!isNullOrUndefined(cellParams.action)) {
				if (cellParams.action === 'delete') {
					this.tooltip = 'Supprimer l\'abonnement';
				} else if (cellParams.action === 'edit') {
					this.tooltip = "Modifier l'abonnement";
				}
			}
		}
	}

	refresh(): boolean {
		return false;
	}

	abonnementActionClick(): void {
		if (
			!isNullOrUndefined(this.params.action) &&
			this.params.action === 'edit'
		) {
			this.router.navigate([ '/abonnements-ebics', this.params.data.id]);
		}
	}
}
