import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FseDataRaw } from '../../../../data/fseDataRaw';
import { LotsDataRaw } from '../../../../data/lotsDataRaw';
import { OrganismesService } from '../../../organismes/organismes/organismes.service';
import { AmcDataRaw } from '../../../../data/amcDataRaw';
import { AmoDataRaw } from '../../../../data/amoDataRaw';
import { PsDataRaw } from '../../../../data/psDataRaw';

@Component({
	selector: 'careweb-fse-details-modal',
	templateUrl: './fse-details-modal.component.html'
})
export class FseDetailsModalComponent implements OnInit {
	currentJustify = 'left';
	@Input() facture: FseDataRaw;
	@Input() lot: LotsDataRaw;
	@Input() ps: PsDataRaw;
	amcData: AmcDataRaw | null;
	amoData: AmoDataRaw;
	active: 1;
	constructor(private modalRef: BsModalRef, private organismesSvc: OrganismesService) {
		this.amcData = new AmcDataRaw();
		this.amoData = new AmoDataRaw();
	}

	ngOnInit(): void {
		const ctx = this;
		if (this.facture.numOrganismeRc.trim().length > 0) {
			ctx.organismesSvc
				.getAmcByNumOrganisme({
					numOrganismeRc: this.facture.numOrganismeRc
				})
				.subscribe((data) => {
					data.length > 0 ? (ctx.amcData = data[0]) : '';
				});
		}

		if (this.facture.codeOrganismeDestinataireAmo && this.facture.codeGr) {
			ctx.organismesSvc
				.getAmoByCodeOrganismeAndCodeGr({
					codeOrgType1: this.facture.codeOrganismeDestinataireAmo,
					codeGr: this.facture.codeGr
				})
				.subscribe((data) => {
					data.length > 0 ? (ctx.amoData = data[0]) : '';
				});
		}
	}

	cancel() {
		this.modalRef.hide();
	}

	closeModal() {
		this.modalRef.hide();
	}
}
