import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { isNumber } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import { AuthService } from '../../../core/services/auth.service';
import { HotlineDataRaw } from '../../../data/hotlineDataRaw';
import { User } from '../../../data/userDataRaw';
import { GroupUsersService } from '../../users/groupUsers.service';
import { UsersService } from '../../users/users.service';
import { HotlineService } from '../hotline.service';
import { HotMotifsService } from '../hotMotifs.service';
import { HotResolutionService } from '../hotResolution.service';
import { HotTypeService } from '../hotType.service';

@Component({
	selector: 'careweb-app-intervention',
	templateUrl: './intervention.component.html',
	styleUrls: [ './intervention.component.scss' ]
})

export class InterventionComponent implements OnInit {
	motifsList;
	agents = [];
	numeroPs: String = null;
	idPs: String = null;
	resolutions = [];
	motifs = [];
	groupeUsers = [];
	interventions = [];
	niveaux = [ { id: 1, value: 'Normal' }, { id: 2, value: 'Urgent' } ];
	interventionStatus: any = [
		{ id: 1, value: 'En cours' },
		{ id: 2, value: 'Transférée' },
		{ id: 3, value: 'Clôturée' }
	];
	niveau = { id: String, value: String };
	retour: any;
	user: any;
	type: any;
	uservalue: any;
	commentaire: String;
	hotlineDataRaw = new HotlineDataRaw();
	currentUser: User;
	@ViewChild('interventionForm')
	form: NgForm;
	constructor(
		private hotlineSvc: HotlineService,
		private usersService: UsersService,
		private authSvc: AuthService,
		private hotMotifsService: HotMotifsService,
		private groupeUsersSvc: GroupUsersService,
		private hotResolutionService: HotResolutionService,
		private hotTypeService: HotTypeService,
		private toastr: ToastrService,
		private location: Location,
		private router: Router
	) {}

	ngOnInit() {
		this.init();
	}

	init() {
		const ctx = this;
		ctx.usersService.getHotlineUsersList(JSON.parse(localStorage.getItem('octCurrent')).idOct).subscribe((data) => {
			ctx.getAgents(data);
		});

		ctx.hotMotifsService.getHotlineList().subscribe((data) => {
			ctx.getMotifsLists(data);
		});

		ctx.groupeUsersSvc.getGroupUserList().subscribe((data) => {
			ctx.getGroupeUsers(data);
		});
		ctx.hotResolutionService.getResolutionList().subscribe((data) => {
			ctx.getResolution(data);
		});

		ctx.hotTypeService.getHotTypeList().subscribe((data) => {
			ctx.getHotType(data);
		});

		this.hotlineDataRaw.statut = this.interventionStatus[0].id;
		this.hotlineDataRaw.niveau = this.niveaux[0].id;

		setTimeout(() => {
			this.getSessionValue();
		}, 15);
	}

	getAgents = (data: any) => {
		if (data) {
			data.forEach((element) => {
				const agent = {
					id: element.idUser,
					value: `${element.prenom ? element.prenom : ''} ${element.nom ? element.nom.toUpperCase() : ''}`
				};
				this.agents.push(agent);
			});
		}
	};

	getHotlineValue = (data: any): String => {
		if (data) {
			this.agents.forEach((element) => {
				if (element.id === data) {
					this.retour = element.value;
				}
			});
		}
		return this.retour;
	};

	getSessionValue() {
		this.numeroPs = localStorage.getItem('numeroPs');
		this.idPs = localStorage.getItem('idPs');
		this.currentUser = this.authSvc.currentUserValue;
		this.getUservalue();
		this.hotlineDataRaw.numeroPs = this.numeroPs;
		this.hotlineDataRaw.user = this.currentUser.idUser;
		this.hotlineDataRaw.userName = this.currentUser.nom;
		this.hotlineDataRaw.oct = JSON.parse(localStorage.getItem('octCurrent')).idOct;
	}

	getUservalue(): String {
		this.uservalue =
			this.currentUser && this.currentUser.prenom && this.currentUser.nom
				? `${this.currentUser.prenom} ${this.currentUser.nom}`
				: '';
		return this.uservalue;
	}

	getMotifsLists = (data: any) => {
		if (data) {
			this.hotlineDataRaw.motif = data[0].idMotifs;
			data.forEach((element) => {
				let motif = {
					id: element.idMotifs,
					value: element.libelle
				};
				this.motifs.push(motif);
			});
		}
	};

	getMotifsValue = (data: any): String => {
		if (data) {
			this.motifs.forEach((element) => {
				if (element.id === data) {
					this.retour = element.value;
				}
			});
		}
		return this.retour;
	};

	getGroupeUsers = (data: any) => {
		if (data) {
			data.forEach((element) => {
				//	if (element.libelleCourt === 'HOT1' || element.libelleCourt === 'ADMINI') {
				let groupe = {
					id: element.idGroupe,
					value: element.libelleLong
				};
				this.groupeUsers.push(groupe);
				//}
			});
		}
	};

	getGroupeUsersValue = (data: any): String => {
		if (data) {
			this.groupeUsers.forEach((element) => {
				if (element.id === data) {
					this.retour = element.value;
				}
			});
		}
		return this.retour;
	};

	getResolution = (data: any) => {
		if (data) {
			data.forEach((element) => {
				const resolution = {
					id: element.idResolution,
					value: element.libelle
				};
				this.resolutions.push(resolution);
			});
		}
	};

	getHotType = (data: any) => {
		if (data) {
			this.hotlineDataRaw.intervention = data[0].libelle;
			data.forEach((element) => {
				let intervention = {
					id: element.idType,
					value: element.libelle
				};
				this.interventions.push(intervention);
			});
		}
	};

	getNiveauValue(data: any): Number {
		if (data) {
			this.niveaux.forEach((element) => {
				if (element.value == data) {
					this.retour = element.id;
				}
			});
		}
		return this.retour;
	}

	getTypeValue = (data: any): String => {
		if (data) {
			this.interventions.forEach((element) => {
				if (element.value === data) {
					this.retour = element.id;
				}
			});
		}
		return this.retour;
	};

	saveHotline() {
		this.getSessionValue();

		if (!isNumber(this.hotlineDataRaw.intervention)) {
			this.hotlineDataRaw.intervention = this.getTypeValue(this.hotlineDataRaw.intervention);
		}
		if (
			!isNullOrUndefined(this.hotlineDataRaw.numeroPs) &&
			!isNullOrUndefined(this.hotlineDataRaw.user) &&
			!isNullOrUndefined(this.hotlineDataRaw.oct) &&
			!isNullOrUndefined(this.hotlineDataRaw.intervention) &&
			!isNullOrUndefined(this.hotlineDataRaw.statut) &&
			(this.hotlineDataRaw.statut == 1 ||
				(this.hotlineDataRaw.statut == 2 && !isNullOrUndefined(this.hotlineDataRaw.service)) ||
				(this.hotlineDataRaw.statut == 3 && !isNullOrUndefined(this.hotlineDataRaw.resolution)))
		) {
			this.hotlineSvc.saveHotline(this.hotlineDataRaw).subscribe(
				(data) => {
					//this.hotlineDataRaw = new HotlineDataRaw();
					this.getSessionValue();
					this.toastr.success("L'intervention a été ajoutée avec succès");
					this.router.navigate([ '/hotline' ]);
				},
				(err) => {
					this.toastr.error("Une erreur s'est produite, veuillez réessayer ultérieurement");
				}
			);
		}
	}

	doTextareaValueChange(ev) {
		try {
			this.hotlineDataRaw.commentaire = ev.target.value;
		} catch (e) {}
	}

	getValues(value: any) {
		switch (this.hotlineDataRaw.statut) {
			case 1: {
				this.hotlineDataRaw.service = null;
				this.hotlineDataRaw.resolution = null;
				break;
			}
			case 2: {
				this.hotlineDataRaw.service = this.groupeUsers[0].id;
				this.hotlineDataRaw.resolution = null;
				break;
			}
			case 3: {
				this.hotlineDataRaw.service = null;
				this.hotlineDataRaw.resolution = this.resolutions[0].id;
				break;
			}
		}
	}

	backClicked() {
		this.location.back();
	}

	cancel() {
		this.init();
		this.hotlineDataRaw.commentaire = '';
	}
}
