import { Component, Input } from '@angular/core';
import { ContextHelpSvc } from '../../core/services/context-help.service';

/** Composant permettant l'affichage d'une page d'aide. */
@Component({
	selector: 'careweb-open-help',
	templateUrl: './open-help.component.html',
	styles: [ 'a.info.popin, a.info.popin:hover { text-decoration: none; }' ]
})
export class OpenHelpComponent {
	/** Code de la page d'aide à ouvrir. */
	@Input() code: string;

	constructor(private helpSvc: ContextHelpSvc) {}

	/** Déclecnche l'affichage de la page d'aide. */
	public openPopin() {
		this.helpSvc.showHelp(this.code);
	}
}
