import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
    selector: 'careweb-accordion-header-buttons',
    templateUrl: './accordion-header-buttons.html',
    styles: ['.filter-top button:hover {cursor: pointer;}']
})
export class AccordionHeaderButtonsComponent {

    // property that disable filter search button
    @Input()
    disablesearch: boolean = false;

    // Lors de la sélection d'un élément
    @Output()
    closeFilters = new EventEmitter<void>();

    // Lors du reset des filtres
    @Output()
    resetFilters = new EventEmitter<void>();

    // Lors du lancement de la recherche
    @Output()
    launchSearch = new EventEmitter<void>();

    onCloseFilters() {
        this.closeFilters.emit();
    }

    onResetFilters() {
        this.resetFilters.emit();
    }

    onLaunchSearch() {
        this.launchSearch.emit();
    }
}
