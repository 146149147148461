import { Injectable } from '@angular/core';

@Injectable()
export class CAWDateService {

    constructor() {
    }

    getDifferenceEnJours(dateExpr) {
        const dateExp = new Date(dateExpr);
        dateExp.setHours(0, 0, 0, 0);
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        return (dateExp.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24);
    }
}
