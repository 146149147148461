import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONF } from '../../core/constants';

@Injectable()
export class TutorialService {
private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/help';
constructor(private http: HttpClient) { }

getVideoLink(): Observable<string> {
    return this.http.get<string>(this.baseUrl + '/video');
}

}
