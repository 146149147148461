import { Component, OnInit, Input } from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { InputValidators } from 'src/main/webapp/src/app/components/validators/InputValidators';
import { EtablissementService } from '../../../service/etablissement.service';
import { EtablissementDataRaw } from 'src/main/webapp/src/app/data/EtablissementDataRaw';
@Component({
  selector: 'app-edit-ps-etablissement',
  templateUrl: './edit-ps-etablissement.component.html',
  styleUrls: ['./edit-ps-etablissement.component.scss']
})
export class EditPsEtablissementComponent implements OnInit {
  @Input() etablissement: any;
	@Input() idPs: number;
	etablissementForm: UntypedFormGroup;
	initialEmail: String;
	submitted: Boolean = false;
	initalValues: any;

	constructor(
		private modalRef: BsModalRef,
		private toastr: ToastrService,
		private fb: UntypedFormBuilder,
		private etablissementService: EtablissementService, // private router: Router
    private inputValidators: InputValidators,

	) {}
	ngOnInit() {
		this.initialiseCurrentPage();
	}

	initialiseCurrentPage() {
		this.submitted = false;
		this.validForm();
		this.getEtablissementById(this.etablissement.idEtablissement);
	}

	getEtablissementById(idEtablissement) {
		this.etablissementService.getEtablissementById(idEtablissement).subscribe(
			(data) => {
				if (data) {
					this.etablissementForm.patchValue({
						raisonSociale: data.raisonSociale,
						adresse1: isNullOrUndefined(data.adresse1) ? '' : data.adresse1,
						adresse2: isNullOrUndefined(data.adresse2) ? '' : data.adresse2,
						codePostal: data.codepostal,
						ville: data.ville,
						telephone: data.telephone,
            fax : data.fax,
						email: data.email,
						
					});
				}
			},
			(err) => {}
		);
	}

	validForm() {
		this.etablissementForm = this.fb.group({
			raisonSociale: [ '', [ Validators.required, this.inputValidators.inputWithSpaces ] ],
			adresse1: [ '' ],
			adresse2: [ '' ],
			codePostal: [ '' ],
			ville: [ '' ],
			telephone: [ '' ],
			fax: [ '' ],
			email: [ '', [ Validators.required,
							Validators.pattern(this.inputValidators.emailRegexPattern) ] ]
		});
	}

	get formFields() {
		return this.etablissementForm.controls;
	}

	closeModal() {
		this.modalRef.hide();
	}
 
	onSubmit() {
		this.submitted = true;
		if (this.etablissementForm.valid) {
			this.updateEtablissement();
		
	  }
	}

	updateEtablissement() {
		let currentEtablissement: EtablissementDataRaw = new EtablissementDataRaw();
		let data = this.etablissementForm.value;
    console.log(data);
    currentEtablissement.idEtablissement=this.etablissement.idEtablissement;
		currentEtablissement.raisonSociale = data.raisonSociale;
		currentEtablissement.adresse1 = data.adresse1;
		currentEtablissement.adresse2 = data.adresse2;
    currentEtablissement.telephone = data.telephone;
		currentEtablissement.codepostal = data.codePostal;
		currentEtablissement.ville = data.ville;
		currentEtablissement.fax = data.fax;
		currentEtablissement.email = data.email;
		console.log(currentEtablissement);
		this.etablissementService.updateEtablissementWithAttachPs(currentEtablissement).subscribe((data) => {
			if (data) {
				this.toastr.success("L'établissement a été mise à jour avec succès");
				this.closeModal();
			}
			this.submitted = false;
		});
	}

	reset() {
		this.etablissementForm.reset();
		
		this.modalRef.hide();
	}
}
