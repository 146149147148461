import { Component, OnInit } from '@angular/core';
import { AgGridHelper } from '../../../components/ag-grid/ag-grid-helper';
import { ColDef, IServerSideGetRowsParams, IServerSideDatasource } from 'ag-grid-enterprise';
import { Router } from '@angular/router';
import { RegroupementsService } from './regroupement.service';
import { RegroupementFilterRaw } from '../../../data/filters/regroupementFilterRaw';
import { RegroupementDataRaw } from '../../../data/regroupementDataRaw';
import { isNullOrUndefined } from 'util';
import { GridDataRaw } from '../../../data/gridDataRaw';
import { StorageService } from '../../../shared/storage-service';

@Component({
	selector: 'careweb-app-regroupements',
	templateUrl: './regroupements.component.html',
	styleUrls: ['./regroupements.component.scss']
})
export class RegroupementsComponent implements OnInit {
	params: any;
	initHidden = true;
	showFilter = false;
	gridHelper: AgGridHelper;
	filters: RegroupementFilterRaw;
	regroupementDataRaw: RegroupementDataRaw;
	gridDataRaw: GridDataRaw;

	constructor(
		private regroupementsSvc: RegroupementsService,
		private router: Router,
		private storageService: StorageService
	) {
		//Get grid size from session if already stored
		const sessionPageSize = this.storageService.get('regroupementPaginationPageSize', true);
		this.gridDataRaw = new GridDataRaw();
		this.gridDataRaw.size = !isNullOrUndefined(sessionPageSize) ? sessionPageSize : 10;

		this.gridHelper = new AgGridHelper('regroupement', this.gridDataRaw.size, 10);
		this.regroupementDataRaw = new RegroupementDataRaw();
	}

	ngOnInit() {
		this.filters = new RegroupementFilterRaw();
		this.gridHelper.gridOptions = {
			columnDefs: this.getColumnDefs(),
			defaultColDef: {
				resizable: true
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 10,
			maxBlocksInCache: 0,
			infiniteInitialRowCount: 1,
			paginationPageSize: this.gridDataRaw.size,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			localeText: this.gridHelper.getLocaleText(),
			onRowClicked: (params) => this.onRowClicked(params),
			onGridReady: (params) => this.onGridReady(params),
			onColumnMoved: (params) => this.onColumnChanged(params)
		};
	}

	onGridReady(params) {
		const ctx = this;
		this.gridHelper.gridApi = params.api;
		this.gridHelper.gridColumnApi = params.columnApi;
		this.restoreGrid();
		this.gridHelper.gridApi.sizeColumnsToFit();

		const dataSource: IServerSideDatasource = {
			getRows: function (paramsRows: IServerSideGetRowsParams) {
				ctx.regroupementsSvc
					.getRegroupementsList({
						page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
						size: ctx.gridHelper.gridApi.paginationGetPageSize()
					})
					.subscribe(
						(data) => {
							const rowCount = data.totalElements;
							ctx.gridHelper.manageNoRowsOverlay(rowCount);
							paramsRows.success({"rowData": data.content, "rowCount": rowCount});
						},
						() => paramsRows.fail()
					);
			}
		};
		this.gridHelper.gridApi.setServerSideDatasource(dataSource);
	}

	onRowClicked(params) {
		this.router.navigate(['/regroupement-details', params.data.idRegroupement]);
	}

	restoreGrid() {
		this.gridHelper.restoreGridStatePrefix();
		this.gridDataRaw.size = this.gridHelper.paginationPageSize;
	}

	onKeyDown(event) {
		this.launchSearch();
	}

	toggleFilterDisplay() {
		if (this.initHidden) {
			this.initHidden = false;
			setTimeout(() => {
				this.showFilter = !this.showFilter;
			}, 10);
		} else {
			this.showFilter = !this.showFilter;
		}
	}

	resetGridState() {
		this.gridHelper.resetGridState();
		this.gridDataRaw.size = this.gridHelper.defaultPaginationPageSize;
	}

	exportCsv() {
		const params = {
			skipHeader: false,
			columnGroups: false,
			skipFooters: false,
			skipGroups: true,
			skipPinnedTop: false,
			skipPinnedBottom: false,
			allColumns: true,
			onlySelected: false,
			fileName: 'Regroupements_liste',
			sheetName: 'Regroupements_liste',
			exportMode: '.csv'
		};

		this.gridHelper.gridApi.exportDataAsCsv(params);
	}

	refresh(): boolean {
		return false;
	}

	resetAllFilter(): void {
		this.filters = new RegroupementFilterRaw();
		this.gridHelper.gridApi.setFilterModel(null);
		this.gridHelper.gridApi.onFilterChanged();
		this.launchSearch();
	}

	launchSearch(): void {
		const ctx = this;
		this.restoreGrid();

		this.regroupementDataRaw.libelle = this.filters.libelle || '';
		this.regroupementDataRaw.regroupementRc = this.filters.regroupementRc || '';
		const dataSource: IServerSideDatasource = {
			getRows: function (paramsRows: IServerSideGetRowsParams) {
				ctx.regroupementsSvc
					.getRegroupementFilteredList(
						{
							page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
							size: ctx.gridHelper.gridApi.paginationGetPageSize()
						},
						ctx.regroupementDataRaw
					)
					.subscribe(
						(data) => {
							const rowCount = data.totalElements;
							ctx.gridHelper.manageNoRowsOverlay(rowCount);
							paramsRows.success({"rowData": data.content, "rowCount": rowCount});
						},
						() => paramsRows.fail()
					);
			}
		};
		this.gridHelper.gridApi.setServerSideDatasource(dataSource);
		this.gridHelper.gridApi.sizeColumnsToFit();

		// Get grid to run filter operation again
		this.gridHelper.gridApi.onFilterChanged();
	}

	private getColumnDefs(): ColDef[] {
		return [
			{
				headerName: 'Libellé',
				field: 'libelle',
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent()
			},
			{
				headerName: 'RCI',
				field: 'brcsi',
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				cellRenderer: this.iconRenderer
			},
			{
				headerName: 'Gestion',
				field: 'options',
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent()
			}
		];
	}

	iconRenderer = (params): string => {
		if (params.data) return params.value ? '<i class="fa fa-check"></i>' : '<i class="fa fa-check"></i>';
	};

	onPaginationSizeChange(paginationSize: number): void {
		this.gridHelper.changePaginationSize(paginationSize);
		this.gridHelper.saveGridStatePrefix();
	}

	ngOnDestroy() {
		this.gridHelper.saveGridStatePrefix();
	}

	onColumnChanged(params: any): void {
		this.gridHelper.saveGridColumnState();
	}
}
