import { Injectable } from "@angular/core";
import { FilterService } from "../../../core/services/filter-service";
import { CONF } from "../../../core/constants";
import { HttpClient } from "@angular/common/http";
import { ReglesParsingFilterRaw } from "../../../data/filters/ReglesParsingFilterRaw";
import { ReglesParsingDataRaw } from "../../../data/ReglesParsingDataRaw";
import { Subject } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class ReglesParsingService extends FilterService{

    private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/ebics';

    private modalClosedSource = new Subject<void>();

	modalClosed$ = this.modalClosedSource.asObservable();

	constructor(private httpClient: HttpClient) {
		super();
	}
    getAllReglesParsing(criteria: any, reglesParsingFilterRaw: ReglesParsingFilterRaw){
		return this.httpClient.post<any>(this.baseUrl + '/params/parsing?page=' + criteria.page + '&size=' + criteria.size +
			this.getSortParameters(criteria.sorts), reglesParsingFilterRaw);
	}
    save(regleParsing: ReglesParsingDataRaw) {
        return this.httpClient.post<any>(this.baseUrl + '/params/parsing', regleParsing);
    }
    getSortParameters(sorts) {
		if (sorts.length === 1) {
			const sort = sorts[0].property;
			return '&sort=' + sort + '&direction=' + sorts[0].direction;
		} else {
			// sort par défaut
			return '&sort=statement&direction=ASC';
		}
	}
    getRegleParsingById(id:number){
		return this.httpClient.get<any>(this.baseUrl + '/params/parsing/'+id);
	}
    deleteRegle(idRegle : number){
		return this.httpClient.delete<any>(this.baseUrl + '/params/parsing/'+idRegle);
	}
    notifyModalClosed() {
		this.modalClosedSource.next();
	}
    

}