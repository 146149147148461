<div class="x-large-careweb-container">
	<form [formGroup]="psForm" (ngSubmit)="onSubmit()">
		<!-- .form-content : A utiliser pour les pages de formulaire - Ne pas utiliser pour les fenetres modales -->
		<section class="form-content">

			<!-- Titre H2 -->
			<div class="row form-title">
				<h2 class="col-md-6">
					{{getTitle()}}
					<!-- <a href="#"><span class="popin-btn-container"><i class="fa fa-info-circle"></i></span></a> -->
				</h2>
				<div class="col-md-6 text-title" style="text-align:right">
					<button *ngIf="isAuthorized()" type="button" class="btn btn-primary" (click)='goHotlinePage()' >Consulter les interventions</button>
					<button class="btn btn-default btn-lg" (click)='goBack()' type="button" style="margin-left: 10px;">Retour</button>
				</div>
			</div>
			<!-- NAV TABS -->

			<ul ngbNav #nav="ngbNav" [(activeId)]="active" [destroyOnHide]="false" class="nav-tabs" (navChange)="onTabChange($event)">
				<li [ngbNavItem]="1" id="comptes" title="Informations du compte">
					<button ngbNavLink>Informations du compte</button>
					<ng-template ngbNavContent>
						<!-- ONGLET 1 -->
						<div id="onglet1">
							<!-- Form-block - Permet de separer les blocs de formulaires entre eux -->
							<div class="form-block">
								<!-- Titre -->
								<div class="row mb-2">
									<div class="col">
										<h3 class="title-border" style="margin-top:30px">
											Détails
										</h3>
									</div>
								</div>
								<fieldset class="container-fluid">
									<div class="row">
										<div class="col-sm-2 col-form-label">Numéro ADELI *</div>
										<div class="col-sm-4 form-group">
											<input type="text" formControlName="numeroAdeli" class="form-control"
												[ngClass]="{ 'is-invalid': submitted && formFields.numeroAdeli.errors }"
												[readonly]="isReadonlyNumeroAdeli()"
												oninput="this.value=this.value.replace(/[^\d]/,'');"
												onpaste="if(this.value.length==9) return false;"
												onKeyPress="if(this.value.length==9) return false;" />
											<div *ngIf="submitted && formFields.numeroAdeli.errors"
												class="invalid-feedback">
												<div *ngIf="formFields.numeroAdeli.errors.required">Ce champ est
													obligatoire</div>
												<div *ngIf="formFields.numeroAdeli.errors.minlength">Ce champ n'est pas
													valide</div>
												<div *ngIf="formFields.numeroAdeli.errors.checkAdeli">
													Le numéro ADELI n'est pas valide
												</div>
											</div>
										</div>
										<div class="col-sm-2 col-form-label">Numéro RPPS</div>
										<div class="col-sm-4 form-group">
											<input type="text" formControlName="rpps" class="form-control"
												[readonly]="isReadonlyRPPS()"
												oninput="this.value=this.value.replace(/[^\d]/,'');"
												onpaste="if(this.value.length==11) return false;"
												onKeyPress="if(this.value.length==11) return false;" />
											<div *ngIf="submitted && formFields.rpps.errors" class="invalid-feedback">
												<div *ngIf="formFields.rpps.errors.minlength"> Le champ Numéro RPPS
													n'est pas valide</div>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-sm-2 col-form-label">Type de PS</div>
										<div class="col-sm-4 form-group">
											<ng-select formControlName="typePs" bindLabel="name"
												style="width:100%; height:calc(1.5em + .75rem + 2px)"
												[clearable]="false" [readonly]="isAdminSel()">
												<ng-option *ngFor="let typeps of listTypePs trackBy: trackByIndex"
													[value]="typeps">
													{{typeps.libelleLong}}
												</ng-option>
											</ng-select>
										</div>
										<div class="col-sm-2 col-form-label">Actif</div>
										<div class="col-sm-4 form-group">
											<ng-select formControlName="actif" bindLabel="name"
												style="width:100%; height:calc(1.5em + .75rem + 2px)"
												[clearable]="false" [readonly]="isAdminSel()">
												<ng-option *ngFor="let actif of actifArray" [value]="actif">
													{{actif.viewValue}}
												</ng-option>
											</ng-select>
										</div>
									</div>
									<div class="row">
										<div class="col-sm-2 col-form-label" *ngIf="showFreqEnvoi">Fréquence d'envoi
											des fichiers</div>
										<div class="col-sm-4 form-group" *ngIf="showFreqEnvoi">
											<ng-select formControlName="frequenceEnvois" bindLabel="name"
												style="width:100%; height:calc(1.5em + .75rem + 2px)"
												[clearable]="false" [readonly]="isAdminSel()">
												<ng-option *ngFor="let freqEnvoi of frequenceEnvoieCaisseArray"
													[value]="freqEnvoi">
													{{freqEnvoi.viewValue}}
												</ng-option>
											</ng-select>
										</div>
										<div class="col-sm-2 col-form-label" *ngIf="showCaractTrans">
											Caractéristiques transmission</div>
										<div class="col-sm-4 form-group" *ngIf="showCaractTrans">
											<ng-select formControlName="caractTrans" bindLabel="name"
												style="width:100%; height:calc(1.5em + .75rem + 2px)"
												[clearable]="false" [readonly]="isAdminSel()">
												<ng-option *ngFor="let caractTransm of carateristiqueTransmissionArray"
													[value]="caractTransm">
													{{caractTransm.viewValue}}
												</ng-option>
											</ng-select>
										</div>
									</div>
									<div class="row">
										<div class="col-sm-2 col-form-label" [hidden]="!showSv">SV</div>
										<div class="col-sm-4 form-group" [hidden]="!showSv">
											<ng-select formControlName="sv" bindLabel="name"
												style="width:100%; height:calc(1.5em + .75rem + 2px)"
												[clearable]="false" (change)="changeSV($event)"
												[readonly]="isAdminSel()">
												<ng-option *ngFor="let sv of svArray" [value]="sv">
													{{sv.viewValue}}
												</ng-option>
											</ng-select>
										</div>
										<div class="col-sm-2 col-form-label" *ngIf="showBordereauCaisse">Bordereaux
											de caisse</div>
										<div class="col-sm-4 form-group" *ngIf="showBordereauCaisse">
											<ng-select formControlName="bordereauCaisse" bindLabel="name"
												style="width:100%; height:calc(1.5em + .75rem + 2px)"
												[clearable]="false" [readonly]="isAdminSel()">
												<ng-option *ngFor="let bordereauCaisse of bordereauxCaisseArray"
													[value]="bordereauCaisse">
													{{bordereauCaisse.viewValue}}
												</ng-option>
											</ng-select>
										</div>
									</div>
									<div class="row" [hidden]="!showLoi && !showModePush">
										<div class="col-sm-2 col-form-label">LOI</div>
										<div class="col-sm-4 form-group">
											<ng-select formControlName="loi" bindLabel="name"
												style="width:100%; height:calc(1.5em + .75rem + 2px);background: #e7ecf3;"
												[clearable]="false" [readonly]="isAdminSel()">
												<ng-option *ngFor="let loi of loiArray" [value]="loi">
													{{loi.viewValue}}
												</ng-option>
											</ng-select>
										</div>
										<div class="col-sm-2 col-form-label">Mode push</div>
										<div class="col-sm-4 form-group">
											<ng-select formControlName="loiPush" bindLabel="name"
												style="width:100%; height:calc(1.5em + .75rem + 2px);background: #e7ecf3;"
												[clearable]="false" [readonly]="isAdminSel()">
												<ng-option *ngFor="let modePush of modePushArray" [value]="modePush">
													{{modePush.viewValue}}
												</ng-option>
											</ng-select>
										</div>
									</div>
									<div class="row">
										<div class="col-sm-2 col-form-label">Aide pérenne</div>
										<div class="col-sm-4 form-group">
											<ng-select formControlName="aidePerenne" bindLabel="name"
												style="width:100%; height:calc(1.5em + .75rem + 2px);"
												[clearable]="false" [readonly]="isAdminSel()">
												<ng-option *ngFor="let aidePerenne of aidePerenneArray"
													[value]="aidePerenne">
													{{aidePerenne.viewValue}}
												</ng-option>
											</ng-select>
										</div>
										<div class="col-sm-2 col-form-label">Date de dernière télétransmission</div>
										<div class="col-sm-4 form-group">
											<input type="text" formControlName="derniereTransmission" readonly
												class="form-control" />
										</div>
									</div>
									<div class="row">
										<div class="col-sm-2 col-form-label">Adresse envoi des ART en PDF</div>
										<div class="col-sm-4 form-group">
											<input type="text" formControlName="addrMailArt" class="form-control"
												[readonly]="isAdminSel()" />
										</div>
										<div class="col-sm-2 col-form-label">Adresse envoi des RPH en PDF</div>
										<div class="col-sm-4 form-group">
											<input type="text" formControlName="addrMailRph" class="form-control"
												[readonly]="isAdminSel()" />
										</div>
									</div>
								</fieldset>
								<!-- TOOLBAR -->
								<div class="row grid-top-btn" style="margin-top:0px">
									<div class="col-md-12 conf-col">
										<div class="sy-toolbar">

											<!-- TOOLBAR -->
											<!-- <div class="button-bar control"> -->
											<div>
												<button type="button" (click)="onReset()" class="btn btn-default me-3"
													*ngIf="!isAdminSel()">
													Annuler
												</button>&nbsp;&nbsp;
												<!-- Bouton -->
												<button type="submit" [disabled]="!psForm.dirty"
													class="btn btn-primary " *ngIf="!isAdminSel()">
													Enregistrer
												</button>
											</div>
											<!-- </div> -->

										</div>
									</div>
								</div>
							</div>
							<!-- Form-block - Permet de separer les blocs de formulaires entre eux -->
							<div class="form-block">
								<!-- Titre -->
								<div class="row">
									<div class="col">
										<h3 class="title-border">
											<div class="row">
												<div class="col-auto me-auto">Etablissement</div>
												<div class="col-auto">{{getLengthEtablissementPs()}} établissement</div>
											</div>
										</h3>
									</div>
								</div>

								<!-- TOOLBAR -->
								<div class="row grid-top-btn" style="margin-top:0px">
									<div class="col-md-12 conf-col">
										<div class="sy-toolbar">

											<!-- TOOLBAR -->
											<div class="button-bar control">
												<div>
													<!-- Bouton -->
													<button type="button" class="btn btn-primary"
														[disabled]="existEtablissementPs() || this.createPsComponent"
														data-toggle="modal" data-target="#modalAddEtablissement"
														(click)="openModal('etablissement','')" *ngIf="!isAdminSel()">
														Rattacher un établissement
													</button>
												</div>
											</div>

										</div>
									</div>
								</div>
								<!-- Tableau -->
								<div class="row">
									<div class="col">
										<!-- Utiliser .table-responsive pour des tableaux optimises en mode mobile -->
										<div class="table-responsive">
											<table class="ui-grid-like" *ngIf="existEtablissementPs() ">
												<thead>
													<tr>
														<th>Raison sociale</th>
														<th>Adresse 1</th>
														<th>Adresse 2</th>
														<th>Code Postal</th>
														<th>Ville</th>
														<th>Téléphone</th>
														<th>Fax</th>
														<th>Email</th>
														<th style="width:50px"></th>
														<th style="width:50px"></th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>{{etablissementPs.raisonSociale}}</td>
														<td>{{etablissementPs.adresse1}}</td>
														<td>{{etablissementPs.adresse2}}</td>
														<td>{{etablissementPs.codepostal}}</td>
														<td>{{etablissementPs.ville}}</td>
														<td>{{etablissementPs.telephone}}</td>
														<td>{{etablissementPs.fax}}</td>
														<td>{{etablissementPs.email}}</td>
														<td class="text-center" style="font-size: 1rem"
															data-toggle="modal" data-target="#modalEditUser"
															(click)="openModal('editEtablissement',etablissementPs)">
															<a *ngIf="!isAdminSel()">
																<i class="fa fa-edit gridCellStyle"
																	data-toggle="tooltip"
																	title="Modifier l'établissement"></i>
															</a>
														</td>
														<td class="text-center" style="font-size: 1rem">
															<a>
																<i class="fa fa-chain-broken gridCellStyle"
																	*ngIf="existEtablissementPs() && !isAdminSel()"
																	(click)="openModalConfim('etablissement',etablissementPs)"
																	data-toggle="tooltip"
																	title="Détacher l'établissement">
																</i>
															</a>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
							<!-- utilisateur -->
							<div class="form-block">
								<!-- Titre -->
								<div class="row">
									<div class="col">
										<h3 class="title-border">
											<div class="row">
												<div class="col-auto me-auto">Liste des utilisateurs</div>
												<div class="col-auto">{{getLengthUserPs()}} utilisateur(s)</div>
											</div>
										</h3>
									</div>
								</div>
								<!-- TOOLBAR -->
								<div class="row grid-top-btn" style="margin-top:0px">
									<div class="col-md-12 conf-col">
										<div class="sy-toolbar">
											<!-- TOOLBAR -->
											<div class="button-bar control">
												<div>
													<!-- Bouton -->
													<button type="button" class="btn btn-primary" data-toggle="modal"
														[disabled]="this.createPsComponent" data-target="#modalAddUser"
														(click)="openModal('addUser','')" *ngIf="!isAdminSel()">
														Ajouter un utilisateur
													</button>
												</div>
											</div>

										</div>
									</div>
								</div>
								<!-- Tableau -->
								<div class="row">
									<div class="col">
										<!-- Utiliser .table-responsive pour des tableaux optimises en mode mobile -->
										<div class="table-responsive">
											<table class="ui-grid-like" *ngIf="getLengthUserPs() > 0">
												<thead>
													<tr>
														<th style="width:150px">Type</th>
														<th style="width:50px">Civilité</th>
														<th style="min-width:100px">Nom</th>
														<th style="min-width:100px">Prénom</th>
														<th style="min-width:100px">Fonction</th>
														<th style="min-width:150px">Email</th>
														<th class="text-center" style="min-width:50px">Actif</th>
														<th class="text-center" style="min-width:50px">Compte bloqué
														</th>
														<th class="text-center" style="min-width:50px">Rappro. visible
														</th>
														<th class="text-center" style="width:20px"></th>
														<th class="text-center" style="width:20px"></th>
													</tr>
												</thead>
												<tbody>
													<tr *ngFor="let mainUser of mainUsers">
														<td>{{getTypeUser(mainUser.idTypeUser).viewValue}}</td>
														<td>{{formatCivility(mainUser.idCivilite)}}</td>
														<td>{{mainUser.nom}}</td>
														<td>{{mainUser.prenom}}</td>
														<td>{{mainUser.fonction}}</td>
														<td>{{mainUser.email}}</td>
														<td class="text-center">
															<i
																[ngClass]="(mainUser.actif===true)? 'fa fa-check' :'fa fa-close'"></i>
														</td>
														<td class="text-center">
															<i
																[ngClass]="(mainUser.blocked)? 'fa fa-times red' :''"></i>
														</td>
														<td class="text-center">
															<i
																[ngClass]="(mainUser?.userPreferences?.rapproActif===true)? 'fa fa-check' :'fa fa-close'"></i>
														</td>
														<td class="text-center" style="font-size: 1rem"
															data-toggle="modal" data-target="#modalEditUser"
															(click)="openModal('editUser',mainUser)">
															<a *ngIf="!isAdminSel()">
																<i class="fa fa-edit gridCellStyle"
																	data-toggle="tooltip"
																	title="Modifier l'utilisateur"></i>
															</a>
														</td>
														<td class="text-center" style="font-size: 1rem">
															<a *ngIf="!isAdminSel()">
																<i class="fa fa-chain-broken gridCellStyle"
																	(click)="openModalConfim('user',mainUser)"
																	data-toggle="tooltip"
																	title="Détacher l'utilisateur"></i>
															</a>
														</td>
													</tr>
													<tr *ngFor="let user of usersPs">
														<td>{{getTypeUser(user.idTypeUser)?.viewValue}}</td>
														<td>{{formatCivility(user.idCivilite)}}</td>
														<td>{{user.nom}}</td>
														<td>{{user.prenom}}</td>
														<td>{{user.fonction}}</td>
														<td>{{user.email}}</td>
														<td class="text-center">
															<i
																[ngClass]="(user.actif===true)? 'fa fa-check' :'fa fa-close'"></i>
														</td>
														<td class="text-center">
															<i
																[ngClass]="(user.blocked===true)? 'fa fa-times red' :''"></i>
														</td>
														<td class="text-center">
															<i
																[ngClass]="(user?.userPreferences?.rapproActif===true)? 'fa fa-check' :'fa fa-close'"></i>
														</td>
														<td class="text-center" style="font-size: 1rem"
															data-toggle="modal" data-target="#modalEditUser"
															(click)="openModal('editUser',user)">
															<a *ngIf="!isAdminSel()">
																<i class="fa fa-edit gridCellStyle"
																	data-toggle="tooltip"
																	title="Modifier l'utilisateur"></i>
															</a>
														</td>
														<td class="text-center" style="font-size: 1rem">
															<a *ngIf="!isAdminSel()">
																<i class="fa fa-chain-broken gridCellStyle"
																	(click)="openModalConfim('user',user)"
																	data-toggle="tooltip"
																	title="Détacher l'utilisateur"></i>
															</a>
														</td>
													</tr>

												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
							<!-- Liste des PS supervisé -->
							<div class="form-block" [hidden]="!this.isAuthorized()">
								<!-- Titre -->
								<div class="row">
									<div class="col">
										<h3 class="title-border">
											<div class="row">
												<div class="col-auto me-auto">Liste des PS supervisés</div>
												<div class="col-auto">{{getLengthPsDelegue()}} supervisé(s)</div>
											</div>
											<!-- <a href="#" class="btn-popin"><i class="fa fa-info-circle"></i></a> -->
										</h3>
									</div>
								</div>
								<!-- TOOLBAR -->
								<div class="row grid-top-btn" style="margin-top:0px">
									<div class="col-md-12 conf-col">
										<div class="sy-toolbar">
											<!-- TOOLBAR -->
											<div class="button-bar control">
												<div>
													<!-- Bouton -->
													<button type="button" class="btn btn-primary" data-toggle="modal"
														[disabled]="this.createPsComponent"
														data-target="#modalAddSupervise"
														(click)="openModal('addpsSupervise','')">
														Rattacher un PS
													</button>
												</div>
											</div>

										</div>
									</div>
								</div>
								<!-- Tableau -->
								<div class="row">
									<div class="col">
										<div class="table-responsive">
											<table class="ui-grid-like" *ngIf="getLengthPsDelegue() > 0">
												<thead>
													<tr>
														<th style="width:300px">Numéro ps</th>
														<th style="width:300px">Nom</th>
														<th style="min-width:100px">Etablissement</th>
														<th class="text-center" style="width:20px"></th>
													</tr>
												</thead>
												<tbody>
													<tr *ngFor="let delegue of listPsDelegue">
														<td>{{delegue.numeroPsDelegue}}</td>
														<td>{{delegue.nomPsDelegue}}</td>
														<td>{{delegue.raisonSocialeEtablissementPsDelegue}}</td>
														<td class="text-center" style="font-size: 1rem">
															<a>
																<i class="fa fa-chain-broken gridCellStyle"
																	(click)="openModalConfim('psSupervise',delegue)"
																	data-toggle="tooltip" title="Détacher le PS"></i>
															</a>
														</td>
													</tr>

												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>

							<!-- Liste des PS associé -->
							<div class="form-block" [hidden]="!this.isAuthorized()">
								<!-- Titre -->
								<div class="row">
									<div class="col">
										<h3 class="title-border">
											<div class="row">
												<div class="col-auto me-auto">Liste des PS superviseurs</div>
												<div class="col-auto">{{getLengthPsAssocie()}} superviseur(s)</div>
											</div>

										</h3>
									</div>
								</div>
								<!-- TOOLBAR -->

								<!-- Tableau -->
								<div class="row">
									<div class="col">
										<div class="table-responsive">
											<table class="ui-grid-like" *ngIf="getLengthPsAssocie() > 0">
												<thead>
													<tr>
														<th style="width:300px">Numéro ps</th>
														<th style="width:300px">Nom</th>
														<th style="min-width:100px">Etablissement</th>
													</tr>
												</thead>
												<tbody>
													<tr *ngFor="let associe of listPsAssocie">
														<td>{{associe.numeroPsAssocie}}</td>
														<td>{{associe.nomPsAssocie}}</td>
														<td>{{associe.raisonSocialeEtablissementPsAssocie}}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div id="onglet_InfoLogiciel" *ngIf="isNewPs || createPsComponent">
							<!-- PAR Editeur -->
							<div class="form-block">
								<!-- Titre -->
								<div class="row">
									<div class="col">
										<h3 class="title-border">
											<div class="row">
												<div class="col-auto me-auto">Editeur</div>
												<div class="col-auto">{{getLengthEditeurPs()}} éditeur</div>
											</div>
										</h3>
									</div>
								</div>
								<fieldset class="container-fluid">

									<!-- TOOLBAR -->
									<div class="row grid-top-btn" style="margin-top:0px">
										<div class="col-md-12 conf-col">
											<div class="sy-toolbar">

												<!-- TOOLBAR -->
												<div class="button-bar control">
													<div>
														<!-- Bouton -->
														<button type="button" class="btn btn-primary"
																[disabled]="existEditeurPs() || !enabledOngletLogiciel" data-toggle="modal"
																(click)="openModal('editeur','')">
															Rattacher un éditeur
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>

									<!-- Tableau -->
									<div class="row">
										<div class="col">
											<!-- Utiliser .table-responsive pour des tableaux optimises en mode mobile -->
											<div class="table-responsive">
												<table class="ui-grid-like" *ngIf="existEditeurPs()">
													<thead>
													<tr>
														<th>Raison sociale</th>
														<th>Adresse 1</th>
														<th>Code Postal</th>
														<th>Ville</th>
														<th>Téléphone</th>
														<th>Fax</th>
														<th>Email</th>
														<th>Adresse du site internet</th>
														<th style="width:50px"></th>
													</tr>
													</thead>
													<tbody>
													<tr>
														<td>{{editeurPs.raisonSociale}}</td>
														<td>{{editeurPs.adresse1}}</td>
														<td>{{editeurPs.codePostal}}</td>
														<td>{{editeurPs.ville}}</td>
														<td>{{editeurPs.telephone}}</td>
														<td>{{editeurPs.fax}}</td>
														<td>{{editeurPs.email}}</td>
														<td>{{editeurPs.url}}</td>
														<td class="text-center" style="font-size: 1rem">
															<a>
																<i class="fa fa-chain-broken gridCellStyle"
																   (click)="openModalConfim('editeur',editeurPs)"
																   data-toggle="tooltip"
																   title="Détacher l'éditeur"></i>
															</a>
														</td>
													</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</fieldset>
							</div>
							<div class="form-block">
								<!-- Titre -->
								<div class="row">
									<div class="col">
										<h3 class="title-border">
											<div class="row">
												<div class="col-auto me-auto">Logiciel</div>
												<div class="col-auto">{{getLengthLogicielPs()}} logiciel</div>
											</div>
										</h3>
									</div>
								</div>
								<fieldset class="container-fluid">
									<!-- TOOLBAR -->
									<div class="row grid-top-btn" style="margin-top:0px">
										<div class="col-md-12 conf-col">
											<div class="sy-toolbar">
												<!-- TOOLBAR -->
												<div class="button-bar control">
													<div>
														<!-- Bouton -->
														<button type="button" class="btn btn-primary"
																 data-toggle="modal"
																[disabled]="existLogicielPs() || !enabledOngletLogiciel"
																data-target="#modalRightsUser"
																(click)="openModal('logiciel','')">
															Rattacher un logiciel
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
									<!-- Tableau -->
									<div class="row">
										<div class="col">
											<div class="table-responsive">
												<table class="ui-grid-like" *ngIf="existLogicielPs()">
													<thead>
													<tr>
														<th style="width:250px">Nom</th>
														<th>Raison sociale de l’éditeur</th>
														<th>Système d'exploitation</th>
														<th>Version</th>
														<th style="width:50px"></th>
													</tr>
													</thead>
													<tbody>
													<tr>
														<td>{{logicielPs.libelle}}</td>
														<td>{{raisonSocialeEditeur}}</td>
														<td>{{logicielPs.systeme}}</td>
														<td>{{logicielPs.version}}</td>
														<td class="text-center" style="font-size: 1rem">
															<a>
																<i class="fa fa-chain-broken gridCellStyle"
																   (click)="openModalConfim('logiciel',logicielPs)"
																   data-toggle="tooltip"
																   title="Détacher le logiciel">
																</i>
															</a>
														</td>
													</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>

								</fieldset>
							</div>
						</div>
						<div id="onglet_InfoBal" class="tab-pane fade show active" *ngIf="isNewPs || createPsComponent">
							<div class="form-block">
								<!-- Titre -->
								<div class="row">
									<div class="col">
										<h3 class="title-border">
											<div class="row">
												<div class="col-auto me-auto">Paramètres PS</div>
												<div class="col-auto">{{siteEmissionPs.length}} BAL emettrice(s)</div>
											</div>
											<!-- <a href="#" class="btn-popin"><i class="fa fa-info-circle"></i></a> -->
										</h3>
									</div>
								</div>
								<fieldset class="container-fluid">
									<!-- TOOLBAR -->
									<div class="row grid-top-btn" style="margin-top:0px">
										<div class="col-md-12 conf-col">
											<div class="sy-toolbar">
												<!-- TOOLBAR -->
												<div class="button-bar control">
													<div>
														<!-- Bouton -->
														<button type="button" class="btn btn-primary"
																data-toggle="modal" [disabled]="!enabledOngletBal"
																(click)="openModal('createSiteEmission','')">
															Rattacher un nouveau site
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
									<!-- Tableau -->
									<div class="row">
										<div class="col">
											<div class="table-responsive">
												<table class="ui-grid-like" *ngIf="siteEmissionPs.length > 0">
													<thead>
													<tr>
														<th>ID Site</th>
														<th>BAL Emettrice</th>
														<th>Type Retour</th>
														<th>Type Transport</th>
														<th style="width:20px">Envois compressés</th>
														<th style="width:20px">Envoi Jdre</th>
														<th style="width:20px">Actif</th>
														<th style="width:20px">Site Rerouté</th>
														<th style="width:20px">ART</th>
														<th style="width:20px">RPH2</th>
														<th style="width:20px"></th>
														<th style="width:20px" [hidden]="!isAdminAuthorized()"></th>
													</tr>
													</thead>
													<tbody>
													<tr *ngFor="let siteEmission of siteEmissionPs">
														<td>{{siteEmission.idSite}}</td>
														<td>{{siteEmission.balEmettrice}}</td>
														<td>{{siteEmission.typeRetourSouhaite}}</td>
														<td>{{getNomTypeTransport(siteEmission.idTypeTransport)}}
														</td>
														<td class="text-center">
															<i
																	[ngClass]="(siteEmission.envoisCompresses)? 'fa fa-check' :'fa fa-close'"></i>
														</td>
														<td class="text-center">
															<i
																	[ngClass]="(siteEmission.envoiJdre)? 'fa fa-check' :'fa fa-close'"></i>
														</td>
														<td class="text-center">
															<i
																	[ngClass]="(siteEmission.actif)? 'fa fa-check' :'fa fa-close'"></i>
														</td>
														<td class="text-center">
															<i
																	[ngClass]="(siteEmission.siteReroutage)? 'fa fa-check' :'fa fa-close'"></i>
														</td>
														<td class="text-center">
															<i
																	[ngClass]="(siteEmission.envoiART)? 'fa fa-check' :'fa fa-close'"></i>
														</td>
														<td class="text-center">
															<i
																	[ngClass]="(siteEmission.rph2)? 'fa fa-check' :'fa fa-close'"></i>
														</td>
														<td class="text-center" style="font-size: 1rem"
															data-toggle="modal" data-target="#modalEditUser">
															<a>
																<i class="fa fa-edit gridCellStyle"
																   data-toggle="tooltip"
																   (click)="openModal('UpdateSiteEmission',siteEmission)"
																   title="Modifier le site d'émission">
																</i>
															</a>
														</td>
														<td class="text-center" style="font-size: 1rem"
															[hidden]="!isAdminAuthorized()">
															<a (click)="openModalConfim('siteemission',siteEmission)"
															   tooltip="Supprimer le site d'émission"
															   class="text-gray">
																<i class="fa fa-trash gridCellStyle"
																   container="body"></i>
															</a>
														</td>
													</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</fieldset>
							</div>
						</div>
					</ng-template>
				</li>
				<!-- ONGLET 2 -->
				<li [ngbNavItem]="2" id="logiciel" title="Informations du logiciel" [disabled]="!enabledOngletLogiciel"
					*ngIf="!isAdminSel()">
					<button ngbNavLink>Informations du logiciel</button>
					<ng-template ngbNavContent>
						<div id="onglet2">
							<!-- PAR Editeur -->
							<div class="form-block">
								<!-- Titre -->
								<div class="row">
									<div class="col">
										<h3 class="title-border">
											<div class="row">
												<div class="col-auto me-auto">Editeur</div>
												<div class="col-auto">{{getLengthEditeurPs()}} éditeur</div>
											</div>
										</h3>
									</div>
								</div>
								<fieldset class="container-fluid">

									<!-- TOOLBAR -->
									<div class="row grid-top-btn" style="margin-top:0px">
										<div class="col-md-12 conf-col">
											<div class="sy-toolbar">

												<!-- TOOLBAR -->
												<div class="button-bar control">
													<div>
														<!-- Bouton -->
														<button type="button" class="btn btn-primary"
															[disabled]="existEditeurPs()" data-toggle="modal"
															(click)="openModal('editeur','')">
															Rattacher un éditeur
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>

									<!-- Tableau -->
									<div class="row">
										<div class="col">
											<!-- Utiliser .table-responsive pour des tableaux optimises en mode mobile -->
											<div class="table-responsive">
												<table class="ui-grid-like" *ngIf="existEditeurPs()">
													<thead>
														<tr>
															<th>Raison sociale</th>
															<th>Adresse 1</th>
															<th>Code Postal</th>
															<th>Ville</th>
															<th>Téléphone</th>
															<th>Fax</th>
															<th>Email</th>
															<th>Adresse du site internet</th>
															<th style="width:50px"></th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>{{editeurPs.raisonSociale}}</td>
															<td>{{editeurPs.adresse1}}</td>
															<td>{{editeurPs.codePostal}}</td>
															<td>{{editeurPs.ville}}</td>
															<td>{{editeurPs.telephone}}</td>
															<td>{{editeurPs.fax}}</td>
															<td>{{editeurPs.email}}</td>
															<td>{{editeurPs.url}}</td>
															<td class="text-center" style="font-size: 1rem">
																<a>
																	<i class="fa fa-chain-broken gridCellStyle"
																		(click)="openModalConfim('editeur',editeurPs)"
																		data-toggle="tooltip"
																		title="Détacher l'éditeur"></i>
																</a>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</fieldset>
							</div>
							<div class="form-block">
								<!-- Titre -->
								<div class="row">
									<div class="col">
										<h3 class="title-border">
											<div class="row">
												<div class="col-auto me-auto">Logiciel</div>
												<div class="col-auto">{{getLengthLogicielPs()}} logiciel</div>
											</div>
										</h3>
									</div>
								</div>
								<fieldset class="container-fluid">
									<!-- TOOLBAR -->
									<div class="row grid-top-btn" style="margin-top:0px">
										<div class="col-md-12 conf-col">
											<div class="sy-toolbar">
												<!-- TOOLBAR -->
												<div class="button-bar control">
													<div>
														<!-- Bouton -->
														<button type="button" class="btn btn-primary"
															[disabled]="existLogicielPs()" data-toggle="modal"
															data-target="#modalRightsUser"
															(click)="openModal('logiciel','')">
															Rattacher un logiciel
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
									<!-- Tableau -->
									<div class="row">
										<div class="col">
											<div class="table-responsive">
												<table class="ui-grid-like" *ngIf="existLogicielPs()">
													<thead>
														<tr>
															<th style="width:250px">Nom</th>
															<th>Raison sociale de l’éditeur</th>
															<th>Système d'exploitation</th>
															<th>Version</th>
															<th style="width:50px"></th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>{{logicielPs.libelle}}</td>
															<td>{{raisonSocialeEditeur}}</td>
															<td>{{logicielPs.systeme}}</td>
															<td>{{logicielPs.version}}</td>
															<td class="text-center" style="font-size: 1rem">
																<a>
																	<i class="fa fa-chain-broken gridCellStyle"
																		(click)="openModalConfim('logiciel',logicielPs)"
																		data-toggle="tooltip"
																		title="Détacher le logiciel">
																	</i>
																</a>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>

								</fieldset>
							</div>
						</div>
					</ng-template>
				</li>
				<!-- ONGLET 3 -->
				<li [ngbNavItem]="3" id="bal" title="Informations de la BAL" [disabled]="!enabledOngletBal"
					*ngIf="!isAdminSel()">
					<button ngbNavLink>Informations de la BAL</button>
					<ng-template ngbNavContent>
						<div id="onglet3" class="tab-pane fade show active">
							<div class="form-block">
								<!-- Titre -->
								<div class="row">
									<div class="col">
										<h3 class="title-border">
											<div class="row">
												<div class="col-auto me-auto">Paramètres PS</div>
												<div class="col-auto">{{siteEmissionPs.length}} BAL emettrice(s)</div>
											</div>
											<!-- <a href="#" class="btn-popin"><i class="fa fa-info-circle"></i></a> -->
										</h3>
									</div>
								</div>
								<fieldset class="container-fluid">
									<!-- TOOLBAR -->
									<div class="row grid-top-btn" style="margin-top:0px">
										<div class="col-md-12 conf-col">
											<div class="sy-toolbar">
												<!-- TOOLBAR -->
												<div class="button-bar control">
													<div>
														<!-- Bouton -->
														<button type="button" class="btn btn-primary"
															data-toggle="modal"
															(click)="openModal('createSiteEmission','')">
															Rattacher un nouveau site
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
									<!-- Tableau -->
									<div class="row">
										<div class="col">
											<div class="table-responsive">
												<table class="ui-grid-like" *ngIf="siteEmissionPs.length > 0">
													<thead>
														<tr>
															<th>ID Site</th>
															<th>BAL Emettrice</th>
															<th>Type Retour</th>
															<th>Type Transport</th>
															<th style="width:20px">Envois compressés</th>
															<th style="width:20px">Envoi Jdre</th>
															<th style="width:20px">Actif</th>
															<th style="width:20px">Site Rerouté</th>
															<th style="width:20px">ART</th>
															<th style="width:20px">RPH2</th>
															<th style="width:20px"></th>
															<th style="width:20px" [hidden]="!isAdminAuthorized()"></th>
														</tr>
													</thead>
													<tbody>
														<tr *ngFor="let siteEmission of siteEmissionPs">
															<td>{{siteEmission.idSite}}</td>
															<td>{{siteEmission.balEmettrice}}</td>
															<td>{{siteEmission.typeRetourSouhaite}}</td>
															<td>{{getNomTypeTransport(siteEmission.idTypeTransport)}}
															</td>
															<td class="text-center">
																<i
																	[ngClass]="(siteEmission.envoisCompresses)? 'fa fa-check' :'fa fa-close'"></i>
															</td>
															<td class="text-center">
																<i
																	[ngClass]="(siteEmission.envoiJdre)? 'fa fa-check' :'fa fa-close'"></i>
															</td>
															<td class="text-center">
																<i
																	[ngClass]="(siteEmission.actif)? 'fa fa-check' :'fa fa-close'"></i>
															</td>
															<td class="text-center">
																<i
																	[ngClass]="(siteEmission.siteReroutage)? 'fa fa-check' :'fa fa-close'"></i>
															</td>
															<td class="text-center">
																<i
																	[ngClass]="(siteEmission.envoiART)? 'fa fa-check' :'fa fa-close'"></i>
															</td>
															<td class="text-center">
																<i
																	[ngClass]="(siteEmission.rph2)? 'fa fa-check' :'fa fa-close'"></i>
															</td>
															<td class="text-center" style="font-size: 1rem"
																data-toggle="modal" data-target="#modalEditUser">
																<a>
																	<i class="fa fa-edit gridCellStyle"
																		data-toggle="tooltip"
																		(click)="openModal('UpdateSiteEmission',siteEmission)"
																		title="Modifier le site d'émission">
																	</i>
																</a>
															</td>
															<td class="text-center" style="font-size: 1rem"
																[hidden]="!isAdminAuthorized()">
																<a (click)="openModalConfim('siteemission',siteEmission)"
																	tooltip="Supprimer le site d'émission"
																	class="text-gray">
																	<i class="fa fa-trash gridCellStyle"
																		container="body"></i>
																</a>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</fieldset>
							</div>
						</div>
					</ng-template>
				</li>
				<!-- ONGLET 4 -->
				<li [ngbNavItem]="4" id="service" *ngIf="existInSEL" title="Service en ligne">
					<button ngbNavLink>Service en ligne</button>
					<ng-template ngbNavContent>
						<div *ngIf="!showError" id="onglet4" class="tab-pane fade show active">
							<!-- Form-block - Permet de separer les blocs de formulaires entre eux -->
							<form [formGroup]="psSelForm">
								<div class="form-block">
									<!-- Titre -->
									<div class="row mb-2">
										<div class="col">
											<h3 class="title-border" style="margin-top:30px">
												Informations du PS
											</h3>
										</div>
									</div>
									<fieldset class="container-fluid">
										<div class="row">
											<div class="col-sm-2 col-form-label">Civilité</div>
											<div class="col-sm-4 form-group">
												<input type="text" formControlName="civilityCode" class="form-control"
													readonly />
											</div>
											<div class="col-sm-2 col-form-label">Nom</div>
											<div class="col-sm-4 form-group">
												<input type="text" formControlName="lastName" class="form-control"
													readonly />
											</div>
										</div>
										<div class="row">
											<div class="col-sm-2 col-form-label">Prénom</div>
											<div class="col-sm-4 form-group">
												<input type="text" formControlName="firstName" class="form-control"
													readonly />
											</div>
											<div class="col-sm-2 col-form-label">N° téléphone 1</div>
											<div class="col-sm-4 form-group">
												<input type="text" formControlName="phoneNumber" class="form-control"
													readonly />
											</div>
										</div>
										<div class="row">
											<div class="col-sm-2 col-form-label">N° téléphone 2</div>
											<div class="col-sm-4 form-group">
												<input type="text" formControlName="phoneNumber2" class="form-control"
													readonly />
											</div>
											<div class="col-sm-2 col-form-label">N° fax</div>
											<div class="col-sm-4 form-group">
												<input type="text" formControlName="faxNumber" class="form-control"
													readonly />
											</div>
										</div>
										<div class="row">
											<div class="col-sm-2 col-form-label">Email</div>
											<div class="col-sm-4 form-group">
												<input type="text" formControlName="email" class="form-control"
													readonly />
											</div>
										</div>
										<div class="row">
											<div class="col-sm-2 col-form-label">N° de rue</div>
											<div class="col-sm-4 form-group">
												<input type="text" formControlName="streetNo" class="form-control"
													readonly />
											</div>
											<div class="col-sm-2 col-form-label">Type de rue</div>
											<div class="col-sm-4 form-group">
												<input type="text" formControlName="streetType" class="form-control"
													readonly />
											</div>
										</div>
										<div class="row">
											<div class="col-sm-2 col-form-label">Nom de rue</div>
											<div class="col-sm-4 form-group">
												<input type="text" formControlName="streetName" class="form-control"
													readonly />
											</div>
											<div class="col-sm-2 col-form-label">Complément adresse</div>
											<div class="col-sm-4 form-group">
												<input type="text" formControlName="addressLine2" class="form-control"
													readonly />
											</div>
										</div>
										<div class="row">
											<div class="col-sm-2 col-form-label">Code postal</div>
											<div class="col-sm-4 form-group">
												<input type="text" formControlName="postCode" class="form-control"
													readonly />
											</div>
											<div class="col-sm-2 col-form-label">Ville</div>
											<div class="col-sm-4 form-group">
												<input type="text" formControlName="town" class="form-control"
													readonly />
											</div>
										</div>
										<div class="row">
											<div class="col-sm-2 col-form-label">Pays</div>
											<div class="col-sm-4 form-group">
												<input type="text" formControlName="country" class="form-control"
													readonly />
											</div>
										</div>
									</fieldset>
								</div>
							</form>
							<!-- Form-block - Permet de separer les blocs de formulaires entre eux -->
							<div class="form-block">
								<!-- Titre -->
								<div class="row">
									<div class="col">
										<h3 class="title-border">
											<div class="row">
												<div class="col-auto me-auto">Activités du PS</div>
											</div>
										</h3>
									</div>
								</div>
								<!-- Tableau -->
								<div class="row">
									<div class="col">
										<!-- Utiliser .table-responsive pour des tableaux optimises en mode mobile -->
										<!-- la liste des activités du PS  -->
										<div class="table-responsive">
											<table class="ui-grid-like">
												<thead>
													<tr>
														<th>Code activité</th>
														<th>Libellé activité</th>
													</tr>
												</thead>
												<tbody>
													<tr *ngFor="let activity of activities">
														<td>{{activity.code}}</td>
														<td>{{activity.label}}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
							<!-- la liste des accords -->
							<div class="form-block">
								<!-- Titre -->
								<div class="row">
									<div class="col">
										<h3 class="title-border">
											<div class="row">
												<div class="col-auto me-auto">Accords</div>
											</div>
										</h3>
									</div>
								</div>

								<!-- Tableau -->
								<div class="row">
									<div class="col">
										<!-- Utiliser .table-responsive pour des tableaux optimises en mode mobile -->
										<div class="table-responsive">
											<table class="ui-grid-like">
												<thead>
													<tr>
														<th>Libellé de l'accord</th>
														<th style="width:150px">Date de début</th>
														<th style="width:150px">Date de Fin</th>
														<th>Intitulé du compte</th>
														<th>Domiciliation bancaire</th>
														<th>IBAN</th>
													</tr>
												</thead>
												<tbody>
													<tr *ngFor="let agreement of agreements">
														<td>{{agreement.label}}</td>
														<td>{{agreement.startDate}}</td>
														<td>{{agreement.endDate}}</td>
														<td>{{agreement.bankingData.name}}</td>
														<td>{{agreement.bankingData.bankingDomiciliation}}</td>
														<td>{{agreement.bankingData.ibanCountryCode}}{{agreement.bankingData.basicBankAccountNumber}}
															Clé: {{agreement.bankingData.ibanKeyControl}}
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
							<!-- Liste de suivi de l'abonnement-->
							<div class="form-block">
								<!-- Titre -->
								<div class="row">
									<div class="col">
										<h3 class="title-border">
											<div class="row">
												<div class="col-auto me-auto">Suivi de l'abonnement</div>
											</div>
										</h3>
									</div>
								</div>
								<!-- Tableau -->
								<div class="row">
									<div class="col">
										<div class="table-responsive">
											<table class="ui-grid-like">
												<thead>
													<tr>
														<th style="min-width:120px">Date</th>
														<th style="width:200px">Code</th>
														<th style="min-width:300px">Libellé</th>
														<th style="min-width:300px">Référence externe CGPASS</th>
														<th style="min-width:300px">URL CGPASS</th>
														<th style="min-width:300px">Motif de refus</th>
													</tr>
												</thead>
												<tbody>
													<tr *ngFor="let subscriptionEvent of subscriptionEvents">
														<td>{{subscriptionEvent.eventDate}}</td>
														<td>{{subscriptionEvent.eventCode}}</td>
														<td>{{subscriptionEvent.eventLabel}}</td>
														<td>{{subscriptionEvent.cgpassExternalRef}}</td>
														<td>{{subscriptionEvent.cgpassUrl}}</td>
														<td>{{subscriptionEvent.cgpassRejectMessage}}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="showError" class="row highlight-row col-12 mt-2 m-0 p-0 " style="color:red;">
							Une erreur est survenue coté web service, Veuillez réessayer plus tard.
						</div>
					</ng-template>
				</li>
				<!-- ONGLET RAPPROCHEMENT BANCAIRE -->
				<li [ngbNavItem]="5" id="rapprochement" *ngIf="!isAdminSel()" title="Rapprochement bancaire"
					[disabled]="!enabledOngletRappBanc">
					<button ngbNavLink>Rapprochement bancaire</button>
					<ng-template ngbNavContent>
						<div id="onglet3" class="tab-pane fade show active">
							<div class="form-block">

								<div class="row">
									<div class="col">
										<h3 class="title-border">
											<div class="row">
												<div class="col-auto me-auto">Type de connexion bancaire</div>
												<div class="col-auto"></div>
											</div>

											<!-- <a href="#" class="btn-popin"><i class="fa fa-info-circle"></i></a> -->
										</h3>
									</div>
								</div>
								<div class="row" *ngIf="!isLoadingTypeRappro">
									<div class="col-sm-4 col-form-label">Choisir le type de connexion bancaire</div>
									<div class="col-sm-2 form-group">
										<ng-select formControlName="cnxBank" bindLabel="name" [clearable]="false"
											(change)="onCnxBankSelected()">
											<ng-option *ngFor="let cnxBank of cnxBankArray" [value]="cnxBank">
												{{cnxBank.viewValue}}
											</ng-option>
										</ng-select>
									</div>
									<div class="col-sm-6">
										<div [hidden]="rappComptePs != null || !showOxlinForm" class="row"
											style="text-align:right">
											<div class="col">
												<button type="button" class="btn btn-primary"
													(click)="openModalConfim('activateRappBanc', null)">
													Activer le service
												</button>
											</div>
										</div>
										<div class="row" style="text-align:right" *ngIf="!showEbicsForm">
											<div class="col">
												<button type="button" class="btn btn-primary"
													[hidden]="!rappComptePs?.actif "
													(click)="deleteRappBancaireAccount()">
													Désactiver le service
												</button>
												<button type="button" class="btn btn-primary"
													[hidden]="rappComptePs?.actif || rappComptePs == null"
													(click)="createRappBancaireAccount()">
													Réactiver le service
												</button>
											</div>
										</div>
										<div class="row" style="text-align:right">
											<div class="col" *ngIf="typeConnexionBancaire=='ebics'?true:false"
												style="text-align:right">
												<button type="button" class="btn btn-primary" *ngIf="showEbicsForm"
													(click)="openModalConfim('inactivateRappBanc', null)">
													Désactiver le service 
												</button>
											</div>
										</div>
										<div class="row" style="text-align:right">
											<div class="col" style="text-align:right"
												*ngIf="(typeConnexionBancaire!='ebics' ||typeConnexionBancaire==null ) ?true:false">
												<button type="button" class="btn btn-primary"
													*ngIf="showEbicsForm && comptesAssocies.length==0"
													(click)="openModalConfim('activateRappBanc', null)">
													Activer le service 
												</button>
											</div>
										</div>
										<div class="row" style="text-align:right">
											<div class="col" style="text-align:right"
												*ngIf="(typeConnexionBancaire!='ebics') ? true:false">
												<button *ngIf="showEbicsForm && comptesAssocies.length!=0" type="button" class="btn btn-primary"
													(click)="createRappBancaireAccount()">
													Réactiver le service 
												</button>
											</div>
										</div>
									</div>
								</div>
								<!-- <div *ngIf="(showEbicsForm && ebicsParams?.actif) || (showOxlinForm && rappComptePs?.actif)"
									class="row">
									<div class="col-sm-2 col-form-label">Type de connexion bancaire</div>
									<div class="col-sm-2 form-group">
										<input type="text" class="form-control" readonly
											value="{{typeConnexionBancaire}}{{rappComptePs?.actif}}" />
									</div>
								</div> -->
								<br>
								<div class="form-block" *ngIf="showEbicsForm">
									
									<div class="row">

										<div class="col">
											<h3 class="title-border">
												<div class="row">
													<div class="col-auto me-auto">Liste des comptes associés</div>

												</div>
											</h3>
										</div>


									</div>
									<div class="row">
										<div class="col" style="text-align:right"><button type="button" class="btn btn-primary"
												(click)="openModal('addAccount','')">
												Ajouter un compte
											</button></div>
									</div>
									<br>

								</div>
								<!-- Titre -->
								<div class="row" *ngIf="showOxlinForm && rappComptePs != null">
									<div class="col">
										<h3 class="title-border">
											<div class="row">
												<div class="col-auto me-auto">Informations générales</div>
												<div class="col-auto"></div>
											</div>
											<!-- <a href="#" class="btn-popin"><i class="fa fa-info-circle"></i></a> -->
										</h3>
									</div>
								</div>
								<fieldset *ngIf="showOxlinForm && rappComptePs != null" class="container-fluid">
									<div class="row">
										<div class="col-sm-2 col-form-label">Id Oxlin</div>
										<div class="col-sm-4 form-group">
											<input type="text" class="form-control" readonly
												value="{{rappComptePs?.idOxlinUser}}" />
										</div>
										<div class="col-sm-2 col-form-label">Email</div>
										<div class="col-sm-4 form-group">
											<input type="text" class="form-control" readonly
												value="{{rappComptePs?.addressEmail}}" />
										</div>
									</div>
									<div class="row">
										<div class="col-sm-2 col-form-label">Date de création</div>
										<div class="col-sm-4 form-group">
											<input type="text" class="form-control" readonly
												value="{{rappComptePs?.creationDate | date: 'dd-MM-yyyy HH:mm:ss'}}" />
										</div>
									</div>
									<div class="row">
										<div class="col-sm-2 col-form-label">Date activation</div>
										<div class="col-sm-4 form-group">
											<input type="text" class="form-control" readonly
												value="{{rappComptePs?.debutDate | date: 'dd-MM-yyyy HH:mm:ss'}}" />
										</div>
										<div class="col-sm-2 col-form-label">Date de fin</div>
										<div class="col-sm-4 form-group">
											<input type="text" class="form-control" readonly
												value="{{rappComptePs?.finDate | date: 'dd-MM-yyyy HH:mm:ss'}}" />
										</div>
									</div>
									<div class="row">
										<div class="col-sm-2 col-form-label">Date dernière synchro</div>
										<div class="col-sm-4 form-group">
											<input type="text" class="form-control" readonly
												value="{{rappComptePs?.dernierRefresh | date: 'dd-MM-yyyy HH:mm:ss'}}" />
										</div>
									</div>

								</fieldset>
								<div [hidden]="rappComptePs == null || showEbicsForm" class="row">
									<div class="col">
										<h3 class="title-border">
											<div class="row">
												<div class="col-auto me-auto">Liste des comptes associés</div>
												<div class="col-auto">

												</div>
											</div>
											<!-- <a href="#" class="btn-popin"><i class="fa fa-info-circle"></i></a> -->
										</h3>

									</div>
								</div>
								<card-ebics-abonnement *ngIf="showEbicsForm"
									[comptesAssocies]="comptesAssocies"></card-ebics-abonnement>
								<fieldset class="container-fluid">

									<div class="row" [hidden]="rappComptePs == null || showEbicsForm">
										<div *ngFor="let accordedConnection of oxlinUserConnAcc" class="col-md-6">
											<div class="box-bank">
												<div class="box-bank-content">
													<div class="row title">
														<div class="col-md-1">
															<img src="{{accordedConnection[1].logo_url}}"
																class="imgbank" />
														</div>
														<div class="col">
															<div>
																<div class="title text-primary">
																	{{accordedConnection[1].name}}
																</div>
																<div class="subtitle">{{accordedConnection[0].name}}
																</div>
															</div>
														</div>

													</div>
													<div class="row infos">
														<div class="col">
															<div class="row">
																<div class="col-md-3">Account Id</div>
																<div class="col-md-9">{{accordedConnection[0].id}}</div>
															</div>
															<div class="row">
																<div class="col-md-3">Connection Id</div>
																<div class="col-md-9">
																	{{accordedConnection[0].connection_id}}
																</div>
															</div>
															<div class="row">
																<div class="col-md-3">Ajouté le</div>
																<div class="col-md-9">
																	{{accordedConnection[0].creation_date * 1000 | date:
																	'dd-MM-yyyy HH:mm:ss'}}
																</div>
															</div>
														</div>
													</div>
													<div class="row infos" style="margin-top:15px !important">
														<div class="col"
															style="padding-top:5px; margin-right:10px; border-top:solid 1px #e9e9e9;">
															<div class="row">
																<div class="col-md-6">Dernière synchro effectuée
																</div>
																<div class="col-md-6">
																	{{accordedConnection[1].channels[0].last_end_date *
																	1000 | date: 'dd-MM-yyyy HH:mm:ss'}}
																</div>
															</div>
															<div class="row">
																<div class="col-md-6">Dernière synchro avec succès
																</div>
																<div class="col-md-6">
																	{{accordedConnection[1].channels[0].last_success_date
																	* 1000 | date: 'dd-MM-yyyy HH:mm:ss'}}
																</div>
															</div>
															<div class="row">
																<div class="col-md-6">Connexion accordée jusqu'au</div>
																<div class="col-md-6">
																	{{ accordedConnection[1].channels[0].expires ?
																	(accordedConnection[1].channels[0].expires * 1000 |
																	date: 'dd-MM-yyyy HH:mm:ss') : 'Pas de fin'}}
																</div>
															</div>
															<div class="row">
																<div class="col-md-6">Statut</div>
																<div class="col-md-6" [ngClass]="{
																			'text-success': accordedConnection[1].status === 'SUCCESS',
																			'text-danger': accordedConnection[1].status !== 'SUCCESS'}">{{accordedConnection[1].status}}</div>
															</div>
															<div class="row">
																<div class="col-md-6">Message</div>
																<div class="col-md-6" [ngClass]="{
																		'text-success': accordedConnection[1].channels[0].status === 'SUCCESS',
																		'text-danger': accordedConnection[1].channels[0].status !== 'SUCCESS'}">
																	{{accordedConnection[1].channels[0].status}}</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

									</div>
								</fieldset>
							</div>
						</div>
					</ng-template>
				</li>
			</ul>
			<div [ngbNavOutlet]="nav" class="mt-2"></div>
		</section>
	</form>
</div>

<!-- Modal confirmation de suppression -->
<careweb-confirm-modal (confirmModal)="confirmModal()" (showModal)="showModal($event)" [(visible)]="showConfirmModal"
	[text]="modalMessage" [labelValidButton]="labelValidButtonConfirmModal"
	[labelCancelButton]="labelCancelButtonConfirmModal">
</careweb-confirm-modal>