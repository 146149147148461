import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
	selector: '[carewebPwdValid]',
	providers: [ { provide: NG_VALIDATORS, useExisting: forwardRef(() => PwdValidatorDirective), multi: true } ]
})
export class PwdValidatorDirective implements Validator {
	/**
     * Implémentation de la validation.
     */
	validate(control: AbstractControl): { [key: string]: boolean } {
		const pwd = control.value;
		if (!this.hasUppercase(pwd)) {
			return {
				hasUppercase: false
			};
		}

		if (!this.hasLowercase(pwd)) {
			return {
				hasLowercase: false
			};
		}

		if (!this.hasNumber(pwd)) {
			return {
				hasNumber: false
			};
		}
		if (!this.hasSpecialChar(pwd)) {
			return {
				hasSpecialChar: false
			};
		}
		if (this.hasSpaceChar(pwd)) {
			return {
				hasSpaceChar: false
			};
		}
		return null;
	}

	private hasUppercase(pwd: string): boolean {
		return !pwd || /[A-Z]/.test(pwd);
	}

	private hasLowercase(pwd: string): boolean {
		return !pwd || /[a-z]/.test(pwd);
	}

	private hasNumber(pwd: string): boolean {
		return !pwd || /[0-9]/.test(pwd);
	}

	private hasSpecialChar(pwd: string): boolean {
		return !pwd || /[`~!@#\$%\^&\*\(\)_\-\+=\{\}\[\]\\\|:;"'<>,\.\?\/£]/.test(pwd);
	}

	private hasSpaceChar(pwd: string): boolean {
		return !pwd || /\s/.test(pwd);
	}
}
