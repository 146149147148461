import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
//import { AuthService } from '../../core/services/auth.service';

@Injectable()
export class OrganismeIsAuthAccessGuard implements CanActivate {
	constructor(/* private authSvc: AuthService */) {}

	canActivate() {
		return this.isAuthorized();
	}

	isAuthorized() {
		return true;//return this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
	}
}
