import { AccordionGroupComponent } from './../../../components/accordion/accordion-group.component';
import { DateFromToFilterComponent } from './../../../components/date-from-to/date-from-to-filter.component';
import { PadStartPipe } from './../../../core/pipes/pad-start.pipe';
import { YesNoPipe } from './../../../core/pipes/yesno.pipe';
import { OrganismeTypePipe } from './../../../core/pipes/organisme-type.pipe';
import {Component, OnInit, ViewChild, OnDestroy, HostListener} from '@angular/core';
import { AgGridHelper } from '../../../components/ag-grid/ag-grid-helper';
import { ColDef, IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { gridConvertSort } from '../../../components/ag-grid/ag-grid.utils';
import { Router} from '@angular/router';
import { FseService } from './fse.service';
import { DatePipe } from '@angular/common';
import { AuthService } from '../../../core/services/auth.service';
import { FsePreviewComponent } from './fse-subcomposant/fse-preview.component';
import { ColumnSpec } from '../../../components/ag-grid/grid-column-spec';
import { ColumnSelectorComponent } from '../../../components/ag-grid/grid-column-selector.component';
import { isEmpty } from 'lodash-es';
import { findIndex } from 'lodash-es';
import { FilterItem } from '../../../data/filters/filter-item';
import { PartsDataRaw } from '../../../data/partsDataRaw';
import { FseDataRaw } from '../../../data/fseDataRaw';
import { FseFilterRaw } from '../../../data/filters/fse-filter';
import { LotsFilterSvc } from '../lots/lots-filter.service';
import { isNullOrUndefined } from 'util';
import { OctService } from '../../../core/services/oct.service';
import { PsDelegueDataRaw } from '../../../data/PsDelegueDataRaw';
import { PsDelegueService } from '../../ps/service/ps-delegue.service';
import { ConvertDateFormat } from '../../../core/utilities/convert-date-format.service';
import { PsService } from '../../ps/ps.service';
import { RouteHistoryService } from '../../../shared/route-history.service';
import { GridDataRaw } from '../../../data/gridDataRaw';
import { StorageService } from '../../../shared/storage-service';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {ExportModalComponent, ExportMode} from '../../export-modal/export-modal.component';
import { ExportUtilService } from '../../../core/utilities/export-util.service';
import { saveAs } from 'file-saver';
import {TransformService} from "../../../core/services/transform.service";
import {CustomRouteHistoryService} from "../../../shared/custom-route-history.service";
import {ExportState, State} from "../../../shared/model/storage.model";

@Component({
	selector: 'careweb-app-fse',
	templateUrl: './fse.component.html',
	styleUrls: ['./fse.component.scss']
})
export class FSEComponent implements OnInit, OnDestroy {
	iconClass: string;
	tooltip: string;
	params: any;
	destinationRC: string;
	codeDestinataireRc: string;
	initHidden = true;
	showFilter = false;
	gridHelper: AgGridHelper;
	filterList: FilterItem[];
	organismeLabel;
	currentFse;
	tptatus = 'non';
	datePipe: DatePipe;
	currentOct;
	filters = new FseFilterRaw();
	fseDataRaw: FseDataRaw;
	numeroFseError: Boolean = false; // Indicates if fse filter entries are correct nbFrom < nbTo
	numeroFseExacteError: Boolean = false; // Indicates if the exact filter entry has at least 3 characters
	psDelegueListFilter = new Array<PsDelegueDataRaw>();
	totalResultsHidden = false;
	padStartPipe: PadStartPipe;
	pipeYesNo: YesNoPipe;

	@ViewChild('dateEnvoie')
	dateEnvoie: DateFromToFilterComponent;
	isDateRangeValid = true;

	@ViewChild('dateAccordion')
	dateAccordion: AccordionGroupComponent;

	public rowCount: number = 0;
	private openedModal: BsModalRef;


	exportableColumns: Array<string> = [
		'numPs',
		'numeroLot',
		'destinataireDuReglement',
		'numFacture',
		'totalMontantsFactures',
		'totalRemboursableAmc',
		'totalRemboursableAmo',
		'dateReceptionPartition',
		'parts',
		'codeOrganismeDestinataireAmo',
		'codeOrganismeRo',
		'destinationRC',
		'codeRcOfficiel',
		'lot',
		'nbRspRO',
		'nbRspRC'
	];
	gridDataRaw: GridDataRaw;

	@ViewChild(ColumnSelectorComponent, { static: true })
	columnSelector: ColumnSelectorComponent;

	@ViewChild(FsePreviewComponent, { static: true })
	fseLotPreviewComponent: FsePreviewComponent;

	@ViewChild('tooltipExacte')
	tooltipExacte: TooltipDirective;

	@ViewChild('tooltipFrom')
	tooltipFrom: TooltipDirective;

	@ViewChild('tooltipTo')
	tooltipTo: TooltipDirective;
	// fromRemboursement: boolean;
	export: boolean = false;
	exportState: ExportState;
	serverName: string;
	partExtraiteFilter = [
		{id:"RO", value: "AMO", checked: false},
		{id:"GS", value: "AMO+AMC en gestion séparée", checked: false},
		{id:"GU", value: "AMO+AMC en gestion unique", checked: false},
		{id:"RC", value: "AMC", checked: false},
		{id:"AUCUN", value: "Aucune", checked: false}
	]
	selectedItems: string[] = [];
	constructor(
		private fseSvc: FseService,
		private router: Router,
		private authSvc: AuthService,
		private lotsFilterSvc: LotsFilterSvc,
		private psSVC: PsService,
		private octSvc: OctService,
		private psDelegueSVC: PsDelegueService,
		private convertFormatDate: ConvertDateFormat,
		private routeHistory: RouteHistoryService,
		private storageService: StorageService,
		private organismeTypePipe: OrganismeTypePipe,
		private modalService: BsModalService,
		private transformService: TransformService,
		public customRouteHistory: CustomRouteHistoryService
	) {
		//Get grid size from session if already stored
		const sessionPageSize = this.storageService.get('fsePaginationPageSize', true);
		this.gridDataRaw = new GridDataRaw();
		this.gridDataRaw.size = !isNullOrUndefined(sessionPageSize) ? sessionPageSize : 10;

		this.gridHelper = new AgGridHelper('fse', this.gridDataRaw.size, 10);
		this.fseDataRaw = new FseDataRaw();
		this.convertFormatDate = new ConvertDateFormat();
		this.padStartPipe = new PadStartPipe();
		this.pipeYesNo = new YesNoPipe();
	}

	ngOnInit() {
		// verify previous url if not details remove session currentPage
		this.customRouteHistory.startSaveHistory();
		if (
			this.routeHistory?.getPreviousUrl()?.indexOf('fse-details') === -1 &&
			this.routeHistory?.getPreviousUrl()?.indexOf('ps-details') === -1
		) {
			sessionStorage['fseCurrentPage'] = null;
		}

		const parsedFilter = JSON.parse(sessionStorage.getItem('fseFilters'));
		// Get filter stored in session if exist
		if (!isEmpty(parsedFilter)) {
			this.filters = Object.assign({}, parsedFilter);
			// Convert String dates to Dates format
			this.filters.dateLotRecuFrom ? (this.filters.dateLotRecuFrom = new Date(parsedFilter.dateLotRecuFrom)) : '';
			this.filters.dateLotRecuTo ? (this.filters.dateLotRecuTo = new Date(parsedFilter.dateLotRecuTo)) : '';
			this.filters.dateLotRecuExacte ? (this.filters.dateLotRecuExacte = new Date(parsedFilter.dateLotRecuExacte)) : '';

			this.filters.dateRspRecuFrom ? (this.filters.dateRspRecuFrom = new Date(parsedFilter.dateRspRecuFrom)) : '';
			this.filters.dateRspRecuTo ? (this.filters.dateRspRecuTo = new Date(parsedFilter.dateRspRecuTo)) : '';
			this.filters.dateRspRecuExacte ? (this.filters.dateRspRecuExacte = new Date(parsedFilter.dateRspRecuExacte)) : '';
		}

		// Apply numero Ps on the filter if exist
		const numeroPsLocalStorage = localStorage.getItem('numeroPs');
		if (!isEmpty(numeroPsLocalStorage)) {
			if(this.filters.numeroPs && this.filters.numeroPs != numeroPsLocalStorage) {
				this.filters =  new FseFilterRaw();
			}
			this.filters.numeroPs = numeroPsLocalStorage;
			sessionStorage['fseFilters'] = JSON.stringify(this.filters);
		}
		// Apply numero Ps supervised on the filter if exist
		var numeroPsSupervisedLocalStorage = localStorage.getItem('supervised_ps');
		if (!isEmpty(numeroPsSupervisedLocalStorage)) {
			if (numeroPsSupervisedLocalStorage === '-1') {
				this.filters.numeroPScomboBox = null;
			}
			else {
				numeroPsSupervisedLocalStorage = numeroPsSupervisedLocalStorage.split(';')[0];
				if (this.filters.numeroPScomboBox && this.filters.numeroPScomboBox != numeroPsSupervisedLocalStorage) {
					this.filters = new FseFilterRaw();
				}
				this.filters.numeroPScomboBox = numeroPsSupervisedLocalStorage;
				sessionStorage['fseFilters'] = JSON.stringify(this.filters);
			}


		}
		// Get current oct
		this.currentOct = JSON.parse(localStorage.getItem('octCurrent'));
		if (isNullOrUndefined(this.currentOct)) {
			this.octSvc.currentOctSubject.subscribe((value) => {
				this.currentOct = value;
			});
		}
		sessionStorage.setItem('fiche-fse-tab', 'Informations');
		sessionStorage.setItem('fiche-lot-tab','Informations');

		// Init date pipe
		this.datePipe = new DatePipe('fr-FR');
		// Init grid options
		this.gridHelper.gridOptions = {
			columnDefs: this.getColumnDefs(),
			defaultColDef: {
				suppressMenu: true,
				resizable: true
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 10,
			maxBlocksInCache: 0,
			infiniteInitialRowCount: 1,
			paginationPageSize: this.gridDataRaw.size,
			paginateChildRows: true,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			localeText: this.gridHelper.getLocaleText(),
			enableCellTextSelection: true,
			onRowClicked: (params) => this.onRowClicked(params),
			getRowClass: (params) => this.stylePreviouslyClickedRow(params),
			onGridReady: (params) => this.onGridReady(params),
			onSortChanged: (params) => this.onSortChanged(params),
			onColumnMoved: (params) => this.onColumnChanged(params),
			onColumnVisible: (params) => this.onColumnChanged(params)
		};

		// load combobox of numero PS if user is PS
		if (!this.isAuthorized()) {
			this.loadComboBoxFilterNumeroPS_Supervise();
		}

	}

	existGroupNumeroPS() {
		return this.isAuthorized() || this.psDelegueListFilter.length !== 0;
	}

	onRowClicked(params: any) {
		sessionStorage.previouslyClickedFseId = params.node.data.idFacture;
	}

	stylePreviouslyClickedRow(params: any) {
		let rowNode = params.node;
		if (!isNullOrUndefined(rowNode.data)) {
			let previouslyClickedRow = sessionStorage.getItem('previouslyClickedFseId');
			if (rowNode.data.idFacture === Number(previouslyClickedRow)) {
				return 'ag-row-selected';
			}
		}
	}

	loadComboBoxFilterNumeroPS_Supervise() {
		const userConnected = JSON.parse(localStorage.getItem('careweb_user'));
		if (!isEmpty(userConnected)) {
			if (!isNullOrUndefined(userConnected.idPs)) {
				this.psDelegueSVC.getPsDelegueByIdPs(userConnected.idPs).subscribe((data) => {
					if (!isNullOrUndefined(data) && !isEmpty(data)) {
						this.psDelegueListFilter = data;
						const psCurrent = new PsDelegueDataRaw();
						psCurrent.idPsDelegue = this.psDelegueListFilter[0].idPsAssocie;
						psCurrent.numeroPsDelegue = this.psDelegueListFilter[0].numeroPsAssocie;
						psCurrent.nomPsDelegue = this.psDelegueListFilter[0].nomPsAssocie;
						this.psDelegueListFilter.push(psCurrent);
					}
					isNullOrUndefined(this.gridHelper.gridColumnApi) ? null : this.gridHelper.gridColumnApi.setColumnVisible('numPs', this.existGroupNumeroPS());
				});
			}
		}
	}

	onGridReady(params) {
		const ctx = this;
		var width = window.innerWidth;
		this.gridHelper.gridApi = params.api;
		this.gridHelper.gridColumnApi = params.columnApi;
		this.initSorting();
		this.restoreGridState();
		this.initColumnsDisplay();
		this.setColumnOrder(width);
		let parsedFilter = JSON.parse(sessionStorage.getItem('fseFilters'));
		if (parsedFilter) {
			Object.keys(parsedFilter).forEach(
				(key) => (parsedFilter[key] === undefined || parsedFilter[key] === null ? delete parsedFilter[key] : {})
			);
		}

		if (!isEmpty(parsedFilter)) {
			this.launchSearch();
		} else {
			const dataSource: IServerSideDatasource = {
				getRows: function (paramsRows: IServerSideGetRowsParams) {
					const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
					let criteria;
					// Connect By Admin Or Hotline
					if (!ctx.isAuthorized()) {
						// Connect By PS
						const userConnected = JSON.parse(localStorage.getItem('careweb_user'));
						const criteriaArl = new FseDataRaw();
						criteria = {
							page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
							size: ctx.gridHelper.gridApi.paginationGetPageSize(),
							idPs: userConnected.idPs,
							sorts
						};
						ctx.fseSvc.searchFsesByIdPsWithDelegues(criteria, criteriaArl).subscribe(
							(data) => {
								ctx.rowCount = data.totalElements;
								ctx.gridHelper.manageNoRowsOverlay(ctx.rowCount);
								paramsRows.success({"rowData": data.content, "rowCount": ctx.rowCount});
								const pageN = Number.parseInt(sessionStorage.getItem('fseCurrentPage'));
								if (
									!isNullOrUndefined(pageN) &&
									pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
									pageN > 0
								) {
									ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
								}
								sessionStorage['fseCurrentPage'] = null;
								ctx.totalResultsHidden = true;
							},
							() => paramsRows.fail()
						);
					}
				}
			};
			this.gridHelper.gridApi.setServerSideDatasource(ctx.isAuthorized() ? null : dataSource);
		}
	}

	initColumnsDisplay() {
		const allColumns = this.gridHelper.gridColumnApi.getColumns();
		const selectedColumns = this.gridHelper.gridColumnApi.getAllDisplayedColumns();
		this.columnSelector.setItems(this.getColumnSpecs(allColumns));
		this.columnSelector.setSelectedItems(this.getColumnSpecs(selectedColumns));
	}

	private getColumnSpecs(columns: any[]): ColumnSpec[] {
		const allowedColumns = [
			'destinataireDuReglement',
			'dateReceptionPartition',
			'parts',
			'destinationRC',
			'lot',
			'codeOrganismeDestinataireAmo',
			'codeRcOfficiel',
			'codeOrganismeRo',
		];

		return columns
			.filter(
				(cd) => cd.colDef.headerName && cd.colDef.headerName.length > 0 && allowedColumns.indexOf(cd.colId) > -1
			)
			.map((cd) => {
				return {
					id: cd.colDef.field,
					name: cd.colDef.headerName,
					label: cd.colDef.headerName
				};
			});
	}

	selectColumnEvent(col: ColumnSpec) {
		this.gridHelper.gridColumnApi.setColumnVisible(col.id, true);
		this.gridHelper.gridApi.sizeColumnsToFit();
		this.addColumn(col.id);
	}

	deselectColumnEvent(col: ColumnSpec) {
		this.gridHelper.gridColumnApi.setColumnVisible(col.id, false);
		this.gridHelper.gridApi.sizeColumnsToFit();
		this.removeColumn(col.id);
	}

	toggleFilterDisplay() {
		let width =window.innerWidth;
		if (this.initHidden) {
			this.initHidden = false;
			setTimeout(() => {
				this.showFilter = !this.showFilter;
			}, 10);
		} else {
			this.showFilter = !this.showFilter;
			if(!this.showFilter && width>2000){
				setTimeout(() => {
					this.gridHelper.gridApi.sizeColumnsToFit();
				}, 200);
			}
		}
	}

	resetGridState() {
		this.gridHelper.resetGridState();
		this.gridDataRaw.size = this.gridHelper.defaultPaginationPageSize;
		this.initColumnsDisplay();
		//this.resetAllFilter();
	}

	exportFileXlsxModal(){
		const columns = this.gridHelper.gridColumnApi.getAllDisplayedColumns()
			.filter(column => column.getColDef().headerName != "")
			.map(column => column.getColDef().field);
		if (this.rowCount > 0 && this.rowCount <= 1000) {
			this.exportToExcel(columns);
		} else if (this.rowCount > 1000) {
			this.openedModal = this.modalService.show(ExportModalComponent,  { initialState: {
					nbrElement: this.rowCount
				}, class: 'modal-lg'});
			this.openedModal.content.exportEvent.subscribe((e: any) => {
				if (e.action === ExportMode.EXPORT) {
					// this.exportFileXlsx();
					this.exportToExcel(columns);
				}
				if (e.action === ExportMode.EMAIL) {
					// this.exportFileXlsx();
					this.exportByEmail(columns);
				}
			});
		}
    }

	exportFileXlsx() {
		if(!this.gridHelper.isAvailableData()){
			return;
		}
		const ctx = this;
		let excel = new ExportUtilService();
		const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);

		if (!this.isAuthorized()) {
			// Le compte PS ne doit pas voir la colonne numPs
			const nonExportableColumns = ['numPs'];
			this.exportableColumns = this.exportableColumns.filter((item) => nonExportableColumns.indexOf(item) === -1);
		}

		let headers: Array < String > = [];
		let map = this.getColumnsAndFieldDataFse(this.exportableColumns, this.fseDataRaw);
		for (let key of map.keys()) {
			headers.push(key)
		}
		excel.setNameSheet('Fse');
		excel.setCellTitle('D1');
		excel.setTitle('Liste Des FSE');
		excel.setFontTitleSize(30, true);
		excel.setHeadersToExport(headers);
		excel.setFontHeaderSize(14, true);

		if (ctx.isAuthorized()) {
			ctx.fseSvc.getFseFilteredListFiltre(
							{
								page: 0,
								size: this.rowCount,
								idOct: ctx.currentOct ? ctx.currentOct.idOct : '',
								sorts
							},
							ctx.fseDataRaw
						)
						.subscribe((dataSource) => {
						dataSource.content.forEach((data) => {
						let map = this.getColumnsAndFieldDataFse(this.exportableColumns, data);
						let jsonObject = [];
						map.forEach((value, key) => {
							jsonObject[key] = value
						});
						excel.setRowData(jsonObject);
						excel.SetWidthBeetwenColumns(20);
					});
						excel.addBorderStyle();
						excel.ExportData('Liste-FSE')
			    	})
		           } else {
						ctx.fseDataRaw.idOct = null;
						const userConnected = JSON.parse(localStorage.getItem('careweb_user'));
						const criteria = {
							page: 0,
							size: this.rowCount,
							idPs: userConnected.idPs,
							sorts
						};
						ctx.fseSvc.searchFsesByIdPsWithDelegues(criteria, ctx.fseDataRaw).subscribe((dataSource) => {
						dataSource.content.forEach((data) => {
						let map = this.getColumnsAndFieldDataFse(this.exportableColumns, data);
						let jsonObject = [];
						map.forEach((value, key) => {
							jsonObject[key] = value
						});
						excel.setRowData(jsonObject);
						excel.SetWidthBeetwenColumns(20);
					});
					excel.addBorderStyle();
					excel.ExportData('Liste-FSE')
				})
			}
    
    }

	getColumnsAndFieldDataFse(columnKeys: string[], dataFields ? : any) {
		let data = new Map();
		columnKeys.forEach(column => {
			switch (column) {
				case "numPs":
					data.set("N° de PS", dataFields.numPs);
					break;
				case "numeroLot":
					data.set("Lot", dataFields.numeroLot || dataFields?.lot?.numLot);
					break;
				case "numFacture":
					data.set("N° de FSE", dataFields.numFacture);
					break;
				case "totalMontantsFactures":
					data.set("Montant total", this.formatCurrency({
						data: true,
						value: dataFields.totalMontantsFactures
					    }) 
					);
					break;
				case "totalRemboursableAmc":
					data.set("Montant AMC", this.formatCurrency({
						data: true,
						value: dataFields.totalRemboursableAmc
					    }) 
					);
					break;
				case "totalRemboursableAmo":
					data.set("Montant AMO", this.formatCurrency({
						data: true,
						value: dataFields.totalRemboursableAmo
					    }) 
					);
					break;
				case "dateReceptionPartition":
					data.set("Date de réception", this.formatDate({
						value: dataFields.dateReceptionPartition
					    }) 
					);
					break;
				case "parts":
					data.set("Organisme destinataire AMO", this.formatRegimeRO(dataFields, true));
					break;
				case "destinationRC":
					data.set("Organisme destinataire AMC", this.formatRegimeRC(dataFields, true));
					break;
				case "destinataireDuReglement":
					data.set("Tiers-Payant", this.fromNumberToBoolean(dataFields.destinataireDuReglement));
					break;
				case "nbRspRO":
					data.set("RSP AMO", this.fromNumberToBoolean(dataFields.nbRspRO));
					break;
				case "nbRspRC":
					data.set("RSP AMC", this.fromNumberToBoolean(dataFields.nbRspRC));
					break;
				case "codeOrganismeRo":
					data.set("Code destinataire AMO", this.formatCodeDestinataireAMO(dataFields, true));
					break;
				case "codeRcOfficiel":
					data.set("Code destinataire AMC", this.formatCodeDestinataireAMC(dataFields, true));
					break;
				case "lot":
					data.set("ARL SCOR", this.getArLScor(dataFields?.lot?.signeArlScor));
					break;
			}
		})
		return data;
	}

	fromNumberToBoolean(value: string) : string{
		return Number(value) > 0 ? "Oui" : "Non";
	}

	exportExcelClient() {
		const ctx = this;
		if (!this.isAuthorized()) {
			// Le compte PS ne doit pas voir la colonne numPs
			const nonExportableColumns = ['numPs'];
			this.exportableColumns = this.exportableColumns.filter((item) => nonExportableColumns.indexOf(item) === -1);
		}
		const params = {
			skipHeader: false,
			columnGroups: false,
			skipFooters: false,
			skipGroups: true,
			skipPinnedTop: false,
			skipPinnedBottom: false,
			allColumns: true,
			onlySelected: false,
			fileName: 'Liste-FSE',
			sheetName: 'Liste-FSE',
			// exportMode: "'xlsx'",
			columnKeys: this.exportableColumns,
			processCellCallback: function (params) {
				if (
					params.column.colDef.field === 'totalMontantsFactures' ||
					params.column.colDef.field === 'totalRemboursableAmc' ||
					params.column.colDef.field === 'totalRemboursableAmo'
				) {
					params.value = ctx.formatCurrency({
						data: true,
						value: params.value
					});
				} else if (params.column.colDef.field === 'dateReceptionPartition') {
					params.value = ctx.formatDate({
						value: params.value
					});
				} else if (params.column.colDef.field === 'destinataireDuReglement' && Number(params.value) > 0) {
					ctx.tptatus = 'Oui';
					params.value = ctx.tptatus;
				} else if (params.column.colDef.field === 'destinataireDuReglement' && Number(params.value) === 0) {
					ctx.tptatus = 'Non';
					params.value = ctx.tptatus;
				} else if (params.column.colDef.field === 'nbRspRO' && Number(params.value) > 0) {
					ctx.tptatus = 'Oui';
					params.value = ctx.tptatus;
				} else if (params.column.colDef.field === 'nbRspRO' && Number(params.value) === 0) {
					ctx.tptatus = 'Non';
					params.value = ctx.tptatus;
				} else if (params.column.colDef.field === 'nbRspRC' && Number(params.value) > 0) {
					ctx.tptatus = 'Oui';
					params.value = ctx.tptatus;
				} else if (params.column.colDef.field === 'nbRspRC' && Number(params.value) === 0) {
					ctx.tptatus = 'Non';
					params.value = ctx.tptatus;
				} else if (params.column.colDef.field === 'parts') {
					if (params.column.colDef.headerName === 'Organisme destinataire AMO') {
						const list: PartsDataRaw[] = params.value;

						for (let _i = 0; _i < list.length; _i++) {
							const part = list[_i];
							if (part.partType === 'RO') {
								params.value = part.libelleCourtOrganisme;
							} else if (part.partType === 'RC') {
								ctx.destinationRC = part.libelleCourtOrganisme;
								ctx.codeDestinataireRc = part.codeOrganismeDestinataire;
							}
						}
					}
				} else if (params.column.colDef.field === 'codeOrganismeRo') {
					params.value = ctx.formatCodeDestinataireAMO(params.node.data, true);
				} else if (params.column.colDef.field === 'destinationRC') {
					params.value = ctx.destinationRC;
				} else if (params.column.colDef.field === 'codeRcOfficiel') {
					params.value = ctx.codeDestinataireRc;
				} else if (params.column.colDef.field === 'lot') {
					if (params.value.signeArlScor) {
						params.value = 'oui';
					} else {
						params.value = 'non';
					}
				} else if (params.column.colDef.field === 'lot') {
					if (params.value.signeArlScor) {
						params.value = 'oui';
					} else {
						params.value = 'non';
					}
				}
				return params.value;
			},
			processRowGroupCallback: (params) => this.rowGroupCallback(params)
		};
		this.gridHelper.gridApi.exportDataAsExcel(params);
	}

	rowGroupCallback(params) {
		return params.node.key;
	}

	refresh(): boolean {
		return false;
	}

	resetAllFilter(): void {
		this.dateEnvoie.isDateRangeValid = true;
		this.isDateRangeValid = true;

		if (!this.isAuthorized()) {
			this.initFilter();
			this.filters = new FseFilterRaw();
			this.launchSearch();
			this.lotsFilterSvc.reset();
		} else {
			this.filters = new FseFilterRaw();
			this.rowCount = 0;
			this.lotsFilterSvc.reset();
			this.updateFilterList(this.filters);
			this.clearGridFromData();
		}
		sessionStorage.removeItem('fseFilters');
	}

	isAuthorized() {
		return this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
	}

	hasRapproBancaireAccess(): boolean {
		if (!this.isAuthorized()) {
			if (localStorage.getItem('current_ps') != null) {
				const rappComptePs = JSON.parse(localStorage.getItem('current_ps')).rappComptePs;
				if(rappComptePs){
					return rappComptePs.actif;
				}
				else{
					return false;
				}
			} else {
				return false;
			}
		} else {
			return true;
		}
	}

	private getColumnDefs(): ColDef[] {
		const ctx = this;
		return [
			{
				headerName: 'N° de PS',
				field: 'numPs',
				lockVisible: true,
				cellClass: (params) => this.styleLink(params),
				hide: !this.existGroupNumeroPS(),
				minWidth: 100,
				maxWidth: 150,
				onCellClicked: (params) => this.onPsCellClicked(params),
				cellStyle: this.gridHelper.centerContent(),
				sortable: true,
			},
			this.getTiersPayant(),
			{
				headerName: 'Lot',
				field: 'numeroLot',
				minWidth: 60,
				maxWidth: 100,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				cellClass: (params) => this.styleLink(params),
				sortable: true,
				sort: 'desc',
				sortIndex: 1,
				headerTooltip: 'Numéro du lot',
				onCellClicked: (params) => this.goToDetailLot(params),
				cellRenderer: this.formatNumLotValue,
			},
			{
				headerName: 'N° de FSE',
				field: 'numFacture',
				minWidth: 100,
				maxWidth: 150,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				onCellClicked: (params) => this.onCellClicked(params),
				sortable: true,
			},
			{
				headerName: 'Montant total',
				field: 'totalMontantsFactures',
				minWidth: 120,
				valueFormatter: (params) => this.gridHelper.formatWithSigne(params.value,params.data.signe),
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				onCellClicked: (params) => this.onCellClicked(params),
				sortable: true,
				cellClass: ['text-center', 'font-weight-bold'],
			},
			{
				headerName: 'Montant AMO',
				field: 'totalRemboursableAmo',
				minWidth: 130,
				lockVisible: true,
				valueFormatter: this.formatCurrency,
				cellStyle: this.gridHelper.centerContent(),
				onCellClicked: (params) => this.onCellClicked(params),
				sortable: true,
				cellClass: ['text-center', 'font-weight-bold'],
			},
			{
				headerName: 'Montant AMC',
				field: 'totalRemboursableAmc',
				minWidth: 130,
				lockVisible: true,
				valueFormatter: this.formatCurrency,
				cellStyle: this.gridHelper.centerContent(),
				onCellClicked: (params) => this.onCellClicked(params),
				sortable: true,
				cellClass: ['text-center', 'font-weight-bold'],
			},
			this.getRSPRO(),
			this.getRSPRC(),
			{
				headerName: 'Date de réception',
				field: 'dateReceptionPartition',
				minWidth: 150,
				maxWidth: 200,
				valueFormatter: this.formatDate,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				onCellClicked: (params) => this.onCellClicked(params),
				sortable: true,
				sort: 'desc',
				sortIndex: 0
			},
			{
				headerName: 'Organisme AMO',
				field: 'parts',
				cellRenderer: (params) => ctx.formatRegimeRO(params.data, false),
				minWidth: 320,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				onCellClicked: (params) => this.onCellClicked(params),
				sortable: true
			},
			{
				headerName: 'Code AMO',
				field: 'codeOrganismeRo',
				minWidth: 180,
				lockVisible: true,
				cellRenderer: (params) => this.formatCodeDestinataireAMO(params.data, false),
				cellStyle: this.gridHelper.centerContent(),
				onCellClicked: (params) => this.onCellClicked(params),
				sortable: true
			},
			{
				headerName: 'Organisme AMC',
				field: 'destinationRC',
				cellRenderer: (params) => ctx.formatRegimeRC(params.data, false),
				minWidth:300,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				onCellClicked: (params) => this.onCellClicked(params),
				sortable: true
			},
			{
				headerName: 'Code AMC',
				field: 'codeRcOfficiel',
				minWidth: 180,
				lockVisible: true,
				cellRenderer: (params) => this.formatCodeDestinataireAMC(params.data, false),
				cellStyle: this.gridHelper.centerContent(),
				onCellClicked: (params) => this.onCellClicked(params),
				sortable: true
			},
			{
				headerName: 'ARL SCOR',
				field: 'lot',
				cellRenderer: (params) => this.getArLScor(params.data.lot.signeArlScor),
				hide: true,
				minWidth: 100,
				maxWidth: 100,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				onCellClicked: (params) => this.onCellClicked(params),
				sortable: true,
			},
			{
				headerName: 'ARL',
				field: 'lot.signeArl',
				headerClass: 'ag-header-merge',
				minWidth: 100,
				lockVisible: true,
				cellRenderer: (params) => this.formatArlComponent(params),
				onCellClicked: (params) => this.onCellClicked(params),
				cellStyle: this.gridHelper.centerContent(),
				sortable: true,
			},
			this.getBoLink(),
			this.getFseDetails()
		];
	}

	styleLink(params) {

		let previouslyClickedRow = sessionStorage.getItem('previouslyClickedFseId');

		if (!isNullOrUndefined(params.data) && params.data.idFacture === Number(previouslyClickedRow)) {

			return 'link-in-selected-row';

		} else {

			return 'link-in-not-selected-rows';
		}

	}

	onCellClicked(params) {
		if (!this.gridHelper.isCellTextSelected()) {
			this.router.navigate(['/fse-details', params.data.idFacture]);
		}
	}

	onPsCellClicked(params) {
		var ctx = this;
		if (this.isAuthorized() && !this.gridHelper.isCellTextSelected()) {
			ctx.psSVC
				.getPsByNumAdeli({
					numAdeli: params.value
				})
				.subscribe((data) => {
					this.router.navigate(['/ps-details', data[0].idPs]);
				});
		}
	}

	getBoLink() {
		if (this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN')) {
			return {
				headerName: '',
				field: 'lienBO.idFacture',
				minWidth: 50,
				maxWidth: 50,
				cellRenderer: FsePreviewComponent,
				cellRendererParams: {
					redirect: 'boLink'
				},
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
			};
		} else {
			return {
				hide: true
			};
		}
	}

	getFseDetails() {
		return {
			headerName: '',
			field: 'options',
			minWidth: 50,
			maxWidth: 50,
			cellRenderer: FsePreviewComponent,
			cellRendererParams: {
				redirect: 'fseDetail'
			},
			lockVisible: true,
			cellStyle: this.gridHelper.centerContent(),
		};
	}

	formatDate = (params): string => {
		return this.datePipe.transform(params.value, 'short');
	};

	formatDateForTags = (params): string => {
		if (params.value !== null && params.value != 'Invalid Date') {
			return this.datePipe.transform(params.value, 'dd/MM/yyyy');
		}
	};

	getTiersPayant() {
		return {
			headerName: 'Tiers-Payant',
			field: 'destinataireDuReglement',
			minWidth: 100,
			maxWidth: 150,
			cellRenderer: FsePreviewComponent,
			cellRendererParams: {
				redirect: 'tiersPayant'
			},
			lockVisible: true,
			cellStyle: this.gridHelper.centerContent(),
			sortable: true,
		};
	}

	getRSPRO() {
		return {
			headerName: 'RSP AMO',
			field: 'nbRspRO',
			minWidth: 100,
			maxWidth: 150,
			cellRenderer: FsePreviewComponent,
			cellRendererParams: {
				redirect: 'nbRspRO'
			},
			lockVisible: true,
			cellStyle: this.gridHelper.centerContent(),
			sortable: true
		};
	}

	getRSPRC() {
		return {
			headerName: 'RSP AMC',
			field: 'nbRspRC',
			minWidth: 100,
			maxWidth: 150,
			cellRenderer: FsePreviewComponent,
			cellRendererParams: {
				redirect: 'nbRspRC'
			},
			lockVisible: true,
			cellStyle: this.gridHelper.centerContent(),
			sortable: true
		};
	}

	formatCurrency = (params): string => {
		if (params.data) {
			if (params.value) {
				return params.value / 100 + ' €';
			} else {
				return '0.00 €';
			}
		}
	};

	formatRegimeRO = (data, toExport): string => {
		if (data && !isNullOrUndefined(data.parts)) {
			const list: PartsDataRaw[] = data.parts;
			for (let _i = 0; _i < list.length; _i++) {
				const part = list[_i];
				if (part.partType === 'RO') {
					return part.libelleLongOrganisme ? part.libelleLongOrganisme : toExport ? '' : 'Part AMO non prise en compte';
				}
			}
		}
	};

	formatRegimeRC = (data, toExport): string => {
		if (data && !isNullOrUndefined(data.parts)) {
			const list: PartsDataRaw[] = data.parts;
			for (let _i = 0; _i < list.length; _i++) {
				const part = list[_i];
				if (part.partType === 'RC') {
					return part.libelleLongOrganisme ? part.libelleLongOrganisme : toExport ? '' : data.numOrganismeRc.trim() == "M" ? "M" : 'Part AMC non prise en compte';
				}
			}
		}
	};

	formatNumLotValue = (params): string => {
		return params.data.numeroLot || params.data.lot?.numLot
	};

	formatCodeDestinataireAMC = (data, exportFormat): string => {
			if (data.partRcAPrendreEnCompte == false) {
						return exportFormat ? '' : 'Part AMC non prise en compte';
			} else if (data.partRcAPrendreEnCompte == true) {
			if (data.codeDestinataireRC && data.codeDestinataireRC.trim() !== "") {
				return data.codeDestinataireRC;
			} else if (data && !isNullOrUndefined(data.parts)) {
				const list: PartsDataRaw[] = data.parts;
				for (let _i = 0; _i < list.length; _i++) {
					const part = list[_i];
					if (part.partType === 'RC') {
						return part.codeOrganismeDestinataire ? part.codeOrganismeDestinataire : '';
					}
				}
			}
		} else {
			return '';
		}
	};

	formatCodeDestinataireAMO = (data, exportFormat): string => {
		if (data.partRoAPrendreEnCompte == false) {
					return exportFormat ? '' : 'Part AMO non prise en compte';
		} else if (data.partRoAPrendreEnCompte == true) {
			if (data.codeOrganismeRo && data.codeOrganismeRo.trim() !== "") {
				return data.codeOrganismeRo;
			} else if (data && !isNullOrUndefined(data.parts)) {
				const list: PartsDataRaw[] = data.parts;
				for (let _i = 0; _i < list.length; _i++) {
					const part = list[_i];
					if (part.partType === 'RO') {
						return part.codeOrganismeDestinataire ? part.codeOrganismeDestinataire : '';
					}
				}
			}
		} else {
			return '';
		}
	};
	formatArlComponent(params) {
		if (!isNullOrUndefined(params.value)) {
			if (params.value == true) {
				return "<span class='ui-grid-cell-contents bullet-label green status bullet' container='body' containerclass='tooltip-grid' placement='top' ng-reflect-klass='ui-grid-cell-contents bullet-l' ng-reflect-ng-class='green,status,bullet'> Positif </span>";
			} else {
				return "<span class='ui-grid-cell-contents bullet-label red status bullet' container='body' containerclass='tooltip-grid' placement='top' ng-reflect-klass='ui-grid-cell-contents bullet-l' ng-reflect-ng-class='red,status,bullet'> Négatif </span>";
			}
		}
	}
	
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		let width = event.target.innerWidth;
		this.setColumnOrder(width);
	}
	setColumnOrder(width :any) {
		const columnDefs = this.getColumnDefs();
		const columnApi = this.gridHelper.gridColumnApi;
		const originalColumnOrder = this.getColumnDefs().map(column => column.field);

		if(width<=2140){
			const optionsColumnIndex = columnDefs.findIndex(column => column.field === 'options');
			const lienBOColumnIndex = columnDefs.findIndex(column => column.field === 'lienBO.idFacture');
			if (optionsColumnIndex !== -1 || lienBOColumnIndex !== -1) {
				columnDefs.splice(0, 0, columnDefs.splice(optionsColumnIndex, 1)[0]);
				columnApi.moveColumn('options', 0);
				columnApi.moveColumn('lienBO.idFacture', 1);
			}
		}else {
			this.gridHelper.gridApi.sizeColumnsToFit();
			originalColumnOrder.forEach((field, index) => {
				columnApi.moveColumn(field, index);
			});
		}
	}
	getArLScor = (value): any => {
		return this.pipeYesNo.transform(value, 'typeArl');
	};

	private initSorting() {
		const sort = [
			{
				colId: 'numLot',
				sort: 'asc',
				sortIndex: 0
			}
		];
		return this.gridHelper.applyColumnState(sort);
	}

	onKeyDown(event) {
		this.launchSearch();
	}

	isFormValid(): boolean {
		let lotInputValue = true;
		if (this.filters.numeroFseTo && this.filters.numeroFseFrom &&
			Number(this.filters.numeroFseTo) < Number(this.filters.numeroFseFrom)
		) {
			lotInputValue = false;
			this.numeroFseError = true;
			this.showErrorTooltip('From');
			this.showErrorTooltip('To');
		} else {
			this.numeroFseError = false;
			this.hideErrorTooltip('From');
			this.hideErrorTooltip('To');
		}

		if (this.filters.numFacture && this.filters.numFacture.length < 3) {
			lotInputValue = false;
			this.numeroFseExacteError = true;
			this.showErrorTooltip('Exacte');
		} else {
			this.numeroFseExacteError = false;
			this.hideErrorTooltip('Exacte');
		}

		if (this.filters.dateLotRecuFrom && this.filters.dateLotRecuTo) {
			if ((this.filters.dateLotRecuFrom.getTime() > this.filters.dateLotRecuTo.getTime()) || !this.isDateRangeValid) {
				this.dateAccordion.expansionPannel.open();
				this.dateEnvoie.showTooltipDebut();
				this.dateEnvoie.showTooltipFin();
				lotInputValue = false;
			} else {
				this.dateEnvoie.hideTooltlipDebut();
				this.dateEnvoie.hideTooltlipFin();
			}
		}

		return lotInputValue;
	}

	launchSearch(): void {
		const ctx = this;
		this.fseDataRaw = new FseDataRaw();
		ctx.totalResultsHidden = true;
		if (this.isFormValid()) {
			this.filters.numLot = this.filters.numLot ? this.filters.numLot.toUpperCase() : null;

			this.fseDataRaw.tiersPayant = isNullOrUndefined(this.filters.tiersPayant) ? null : this.filters.tiersPayant;

			this.fseDataRaw.presenceArlScor = isNullOrUndefined(this.filters.presenceArlScor)
				? null
				: this.filters.presenceArlScor;

			this.fseDataRaw.presenceRspRo = isNullOrUndefined(this.filters.presenceRspRo)
				? null
				: this.filters.presenceRspRo;

			this.fseDataRaw.presenceRspRc = isNullOrUndefined(this.filters.presenceRspRc)
				? null
				: this.filters.presenceRspRc;

			// Filtre type d'Organisme
			this.fseDataRaw.typeOrganisme = this.filters.typeOrganisme || null;

			// Fitre NumeroPs
			this.fseDataRaw.numPs = this.isAuthorized()
				? this.filters.numeroPs ? this.filters.numeroPs.replace(/\s/g, '').replace(/\./gi, '') : null
				: this.filters.numeroPScomboBox ? this.filters.numeroPScomboBox : null;
			// this.filters.numeroPs || null;
			this.filters.numLot = this.formatNumLot(this.filters.numLot); //@TODO: to put in utils service
			this.fseDataRaw.numeroLot = this.filters.numLot || null;
			this.filters.numeroFseFrom = this.filters.numeroFseFrom ? this.filters.numeroFseFrom.trim() : null;
			this.fseDataRaw.numeroFseFrom = this.filters.numeroFseFrom || null;
			this.filters.numeroFseTo = this.filters.numeroFseTo ? this.filters.numeroFseTo.trim() : null;
			this.fseDataRaw.numeroFseTo = this.filters.numeroFseTo || null;
			this.filters.numFacture = this.filters.numFacture ? this.filters.numFacture.trim() : null;
			this.fseDataRaw.numFacture = this.filters.numFacture || null;

			this.filters.codeDestinataireRoRegime = this.filters.codeDestinataireRoRegime
				? this.filters.codeDestinataireRoRegime.trim()
				: null;
			this.fseDataRaw.codeDestinataireRoRegime = this.filters.codeDestinataireRoRegime || null;

			this.filters.codeDestinataireRoCaisse = this.filters.codeDestinataireRoCaisse
				? this.filters.codeDestinataireRoCaisse.trim()
				: null;
			this.fseDataRaw.codeDestinataireRoCaisse = this.filters.codeDestinataireRoCaisse || null;

			this.filters.codeDestinataireRoCenter = this.filters.codeDestinataireRoCenter
				? this.filters.codeDestinataireRoCenter.trim()
				: null;
			this.fseDataRaw.codeDestinataireRoCenter = this.filters.codeDestinataireRoCenter || null;
			this.filters.codeDestinataireRC = this.filters.codeDestinataireRC
				? this.padStartPipe.transform(this.filters.codeDestinataireRC.trim(), 10, '0')
				: null;
			this.fseDataRaw.codeDestinataireRC = this.filters.codeDestinataireRC || null;

			this.fseDataRaw.dateLotRecuFrom = this.convertFormatDate.getFormatDateFr(this.filters.dateLotRecuFrom);
			this.fseDataRaw.dateLotRecuTo = this.convertFormatDate.getFormatDateFr(this.filters.dateLotRecuTo);
			this.fseDataRaw.dateLotRecuExacte = this.convertFormatDate.getFormatDateFr(this.filters.dateLotRecuExacte);

			this.fseDataRaw.dateRspRecuFrom = this.convertFormatDate.getFormatDateFr(this.filters.dateRspRecuFrom);
			this.fseDataRaw.dateRspRecuTo = this.convertFormatDate.getFormatDateFr(this.filters.dateRspRecuTo);
			this.fseDataRaw.dateRspRecuExacte = this.convertFormatDate.getFormatDateFr(this.filters.dateRspRecuExacte);

			this.fseDataRaw.idOct = ctx.currentOct ? ctx.currentOct.idOct : null;

			// Si redirection de DashboardPs pour Rembouressement RO et RC
			this.fseDataRaw.statutRsp = !isNullOrUndefined(this.filters.statutRsp) ? this.filters.statutRsp : null;

			this.fseDataRaw.partExtraite = isNullOrUndefined(this.filters.partExtraite)
				? null
				: this.filters.partExtraite;
			this.selectedItems = isNullOrUndefined(this.filters.partExtraite)
				? []
				: this.filters.partExtraite;

			this.fseDataRaw.libelleRc = this.filters.libelleRc || null;
			this.fseDataRaw.libelleRo = this.filters.libelleRo || null;
			// this.fseDataRaw.remboursement = this.fromRemboursement ? true : false;
			this.fseDataRaw.centreInfoTypeZero = this.filters.centreInfoTypeZero || null;
			this.fseDataRaw.rapproBancaire = isNullOrUndefined(this.filters.rapproBancaire)
				? null
				: this.filters.rapproBancaire;

			this.updateFilterList(this.filters);
			Object.keys(this.fseDataRaw).forEach((key) => {
				if (isNullOrUndefined(this.fseDataRaw[key])) {
					delete this.fseDataRaw[key];
				}
			});

			const dataSource: IServerSideDatasource = {
				getRows: function (paramsRows: IServerSideGetRowsParams) {
					const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
					// Connect By Admin Or Hotline
					if (ctx.isAuthorized()) {
						ctx.fseSvc
							.getFseFilteredListFiltre(
								{
									page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
									size: ctx.gridHelper.gridApi.paginationGetPageSize(),
									idOct: ctx.currentOct ? ctx.currentOct.idOct : '',
									sorts
								},
								ctx.fseDataRaw
							)
							.subscribe(
								(data) => {
									ctx.rowCount = data.totalElements;
									ctx.gridHelper.manageNoRowsOverlay(ctx.rowCount);
									paramsRows.success({"rowData": data.content, "rowCount": ctx.rowCount});
									const pageN = Number.parseInt(sessionStorage.getItem('fseCurrentPage'));
									if (
										!isNullOrUndefined(pageN) &&
										pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
										pageN > 0
									) {
										ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
									}
									sessionStorage['fseCurrentPage'] = null;
								},
								() => paramsRows.fail()
							);
					} else {
						// Connect By PS
						ctx.fseDataRaw.idOct = null;
						const userConnected = JSON.parse(localStorage.getItem('careweb_user'));
						const criteria = {
							page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
							size: ctx.gridHelper.gridApi.paginationGetPageSize(),
							idPs: userConnected.idPs,
							sorts
						};
						ctx.fseSvc.searchFsesByIdPsWithDelegues(criteria, ctx.fseDataRaw).subscribe(
							(data) => {
								ctx.rowCount = data.totalElements;
								ctx.gridHelper.manageNoRowsOverlay(ctx.rowCount);
								paramsRows.success({"rowData": data.content, "rowCount": ctx.rowCount});
								const pageN = Number.parseInt(sessionStorage.getItem('fseCurrentPage'));
								if (
									!isNullOrUndefined(pageN) &&
									pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
									pageN > 0
								) {
									ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
								}
								sessionStorage['fseCurrentPage'] = null;
							},
							() => paramsRows.fail()
						);
					}
				}
			};
			this.updateCheckedStatus();
			this.gridHelper.gridApi.setServerSideDatasource(dataSource);
			sessionStorage['fseFilters'] = JSON.stringify(this.filters);
		}
	}
	updateCheckedStatus() {
		this.partExtraiteFilter.forEach(item => {
			item.checked = this.selectedItems.some(selectedItems => selectedItems === item.id);
		})
	}

	formatNumLot(numLot) {
		if (!isNullOrUndefined(numLot)) {
			numLot = numLot.trim();
			if (numLot.length == 3) {
				return numLot;
			} else if (numLot.length == 2) {
				return '0' + numLot;
			} else if (numLot.length == 1) {
				return '00' + numLot;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	initFilter() {
		// Liste des filtres
		this.resetFilter();
		this.updateFilterList(this.filters);
	}

	resetFilter() {
		this.lotsFilterSvc.reset();
	}

	restoreGridState() {
		this.gridHelper.restoreGridStatePrefix();
		this.gridDataRaw.size = this.gridHelper.paginationPageSize;
	}

	updateFilterList(filters) {
		const ctx = this;
		ctx.filterList = [];
		Object.keys(filters).forEach((key) => {
			if (filters[key] !== null && filters[key] !== '' && key !== 'statutRsp') {
				if (key === 'partExtraite') {
					if (this.filters.partExtraite.length > 0) {
						this.filters.partExtraite.map(elt => ctx.filterList.push(ctx.translateFilterLabelValue(key, elt)));
					}
				} else {
					ctx.filterList.push(ctx.translateFilterLabelValue(key, filters[key]));
				}
			}
		});
		// Remove duplicated ids
		// ctx.filterList = uniqBy(ctx.filterList, 'id');
		// filter null
		ctx.filterList = ctx.filterList.filter((item) => {
			return item.id !== null;
		});
	}
	onCheckBoxChange(event, item) {
		if (event.target.checked) {
			this.selectedItems.push(item.id);
		} else {
			const index = this.selectedItems.findIndex(selectedItem => selectedItem === item.id);
			if (index !== -1) {
				this.selectedItems.splice(index, 1);
			}
		}
		this.filters.partExtraite = this.selectedItems.length != 0 ? this.selectedItems : null;
	}

	translateFilterLabelValue(key, value) {
		let translatedKey = key;
		let formattedValue = value;
		let formattedKey = key;
		const ctx = this;
		if (key === 'numeroPs' || key === 'numeroPScomboBox') {
			translatedKey = 'Numéro de PS';
		} else if (key === 'numLot') {
			translatedKey = 'N° du lot';
		} else if (key === 'tiersPayant') {
			translatedKey = 'Tiers-Payant';
			formattedValue = value ? 'Oui' : 'Non';
		} else if (key === 'numeroFseFrom') {
			translatedKey = 'N° de FSE supérieur ou égal à';
		} else if (key === 'numeroFseTo') {
			translatedKey = 'N° de FSE inférieur ou égal à';
		} else if (key === 'numFacture') {
			translatedKey = 'N° de FSE';
		} else if (key === 'dateLotRecuFrom') {
			translatedKey = 'Lots envoyés après le';
			formattedValue = ctx.formatDateForTags({ value: value });
		} else if (key === 'dateLotRecuTo') {
			translatedKey = 'Lots envoyés avant le';
			formattedValue = ctx.formatDateForTags({ value: value });
		} else if (key === 'dateLotRecuExacte') {
			translatedKey = 'Lots envoyés le';
			formattedValue = ctx.formatDateForTags({ value: value });
		} else if (key === 'dateRspRecuFrom') {
			translatedKey = 'Rsp reçus après le';
			formattedValue = ctx.formatDateForTags({ value: value });
		} else if (key === 'dateRspRecuTo') {
			translatedKey = 'Rsp reçus avant le';
			formattedValue = ctx.formatDateForTags({ value: value });
		} else if (key === 'dateRspRecuExacte') {
			translatedKey = 'Rsp reçus le';
			formattedValue = ctx.formatDateForTags({ value: value });
		} else if (key === 'presenceArlScor') {
			translatedKey = 'Présence ARL SCOR';
			formattedValue = value ? 'Oui' : 'Non';
		} else if (key === 'presenceRspRo') {
			translatedKey = 'Présence de RSP AMO';
			formattedValue = value ? 'Oui' : 'Non';
		} else if (key === 'presenceRspRc') {
			translatedKey = 'Présence de RSP AMC';
			formattedValue = value ? 'Oui' : 'Non';
		} else if (key === 'codeDestinataireRoRegime') {
			translatedKey = 'Régime';
		} else if (key === 'codeDestinataireRoCaisse') {
			translatedKey = 'Caisse';
		} else if (key === 'codeDestinataireRoCenter') {
			translatedKey = 'Centre';
		} else if (key === 'codeDestinataireRC') {
			translatedKey = 'Code destinataire AMC';
		} else if (key === 'typeOrganisme') {
			translatedKey = "Type d'organisme";
			formattedValue = ctx.organismeTypePipe.transform(value);
		} else if (key === 'partExtraite') {
			translatedKey = 'Parts prises en compte';
			formattedValue = this.partTypeCases(value);
		} else if (key === 'libelleRo') {
			translatedKey = 'Libellé organisme AMO';
		} else if (key === 'libelleRc') {
			translatedKey = 'Libellé organisme AMC';
		} else if (key === 'centreInfoTypeZero') {
			translatedKey = 'Centre Info. du type 0';
		} else if (key === 'rapproBancaire') {
			translatedKey = 'Rappro. bancaire';
			formattedValue = this.rapproCases(value);
		}else if(key === 'destinationRo'){
			translatedKey ='Organisme destinataire AMO'
		}else if(key === 'destinationRc'){
			translatedKey ='Organisme destinataire AMC'
		}

		return new FilterItem().withId(formattedKey).withLabel(translatedKey).withValue(formattedValue).withValue2(value);
	}

	rapproCases(rappro: Number) :string{
		switch(rappro){
			case 0:
				return 'Non';
			case 1:
				return 'Oui';
			case 2 :
				return 'Partiel';
			default:
				return '';

		}
	}

	partTypeCases(value: string): string {

		switch(value){

			case 'RO':
				return 'AMO';
			case 'RC':
				return 'AMC';
			case 'GS':
				return 'AMO + AMC en gestion séparée';
			case 'GU':
				return 'AMO + AMC en gestion unique';
			case 'AUCUN':
				return 'Aucune';
			default:
				return '';

		}
	}

	removeColumn(columnName: string) {
		const index = this.exportableColumns.indexOf(columnName);
		if (index > -1) {
			this.exportableColumns.splice(index, 1);
		}
	}

	addColumn(columnName: string) {
		if (this.exportableColumns.indexOf(columnName) === -1) {
			this.exportableColumns.push(columnName);
		}
	}
	removeFilter(filter) {
		const ctx = this;
		delete ctx.filters[filter.value];
		let currentFilterList = ctx.filterList;
		const index = findIndex(currentFilterList, (filt) => filt.id === filter.id && filt.value === filter.value);
		currentFilterList.splice(index, 1);
		ctx.filterList = currentFilterList;
		if ((Array.isArray(ctx.filterList) && ctx.filterList.length) || !ctx.isAuthorized()) {
			this.fillFilterObjectFromTagsOnly();
			ctx.launchSearch();
		} else {
			ctx.clearGridFromData();
			this.rowCount = 0;
		}
	}

	fillFilterObjectFromTagsOnly() {
		this.filters = new FseFilterRaw();
		var partExtraiteList: string[] = [];
		this.filterList.forEach(filter => {
			if (filter.id === 'partExtraite') {
				partExtraiteList.push(filter.value2);
			}else {
				this.filters[filter.id] = filter.value2;
			}
		});
		if (partExtraiteList.length > 0) {
			this.selectedItems = partExtraiteList;
			this.filters.partExtraite = partExtraiteList;
		}
	}

	ngOnDestroy() {
		sessionStorage['fseFilters'] = JSON.stringify(this.filters);
		sessionStorage['fseCurrentPage'] = this.gridHelper.gridApi.paginationGetCurrentPage();
		this.gridHelper.saveGridStatePrefix();
	}

	// disable appliquer les filtres button
	disableSearch() {
		if (
			this.isAuthorized() &&
			Object.values(this.filters).every((o) => o === null || o === '' || o === undefined)
		) {
			return true;
		} else {
			return false;
		}
	}

	//clear grid
	clearGridFromData() {
		this.totalResultsHidden = false;
		this.gridHelper.gridApi.setServerSideDatasource(null);
		this.rowCount = 0;
	}


	goToDetailLot(params) {
		if (!this.gridHelper.isCellTextSelected()) {
			this.router.navigate(['/lot-details', params.data.idLot]);
		}
	}

	onPaginationSizeChange(paginationSize: number): void {
		this.gridHelper.changePaginationSize(paginationSize);
		if (this.isAuthorized() && this.rowCount != 0){
			this.launchSearch();
		} else if(!this.isAuthorized()){
			this.launchSearch();
		}
		this.gridHelper.saveGridStatePrefix();
	}

	onSortChanged(params: any): void {
		this.gridHelper.gridApi.paginationGoToFirstPage();
		this.gridHelper.saveGridSort();
	}

	onColumnChanged(params: any): void {
		this.gridHelper.gridColumnApi.setColumnsVisible(["numPs"], this.existGroupNumeroPS());
		this.gridHelper.saveGridColumnState();
	}

	onChoiceChange($event) {
		if (!$event.fromRsp){
			if ($event.filterChoice === "EXACTE") {
				this.filters.dateLotRecuFrom = null;
				this.filters.dateLotRecuTo = null;
			} else if ($event.filterChoice === "PLAGE") {
				this.filters.dateLotRecuExacte = null;
			}
		} else {
			if ($event.filterChoice === "EXACTE") {
				this.filters.dateRspRecuFrom = null;
				this.filters.dateRspRecuTo = null;
			} else if ($event.filterChoice === "PLAGE") {
				this.filters.dateRspRecuExacte = null;
			}
		}
	}

	checkDateRangeValidity(event) {
		this.isDateRangeValid = event;
		if (this.dateEnvoie) {
			this.dateEnvoie.hideTooltlipDebut();
			this.dateEnvoie.hideTooltlipFin();
		}
	}

	showErrorTooltip(type: string): void {
		switch (type) {
			case 'Exacte': this.tooltipExacte.show(); break;
			case 'From': this.tooltipFrom.show(); break;
			case 'To': this.tooltipTo.show(); break;
			default: break;
		}
	}

	hideErrorTooltip(type: string): void {
		switch (type) {
			case 'Exacte': this.tooltipExacte.hide(); break;
			case 'From': this.tooltipFrom.hide(); break;
			case 'To': this.tooltipTo.hide(); break;
			default: break;
		}
	}

	onNumeroFseFilterChange(type: string): void {
		if (type === 'Exacte') {
			if (this.filters.numeroFseFrom != null || this.filters.numeroFseTo != null) {
				this.filters.numeroFseFrom = null;
				this.filters.numeroFseTo = null;
				this.numeroFseError = false;
			}
		} else if (this.filters.numFacture != null) {
			this.filters.numFacture = null;
			this.numeroFseExacteError = false;
		}
	}
	exportByEmail(columns){
		const sorts = gridConvertSort(this.gridHelper.gridSortModel(), []);
			this.fseSvc.exportFsesByEmail(
				{
					page: 0,
					size: this.rowCount,
					export: false,
					sorts
				},
				this.fseDataRaw,
				columns
			).subscribe(()=> {
				// when email received
			});
	}
	exportToExcel(columns){
		this.export = true;
		this.exportState = {
			message : "Vous allez exporter <b>" + this.transformService.transform(this.rowCount) + " ligne(s)</b>, veuillez patienter quelques instants.",
			state: State.INPROGRESS
		}
		const sorts = gridConvertSort(this.gridHelper.gridSortModel(), []);
			this.fseSvc.exportFses(
				{
					page: 0,
					size: this.rowCount,
					export: false,
					sorts
				},
				this.fseDataRaw,
				columns
			).subscribe((response)=> {
				saveAs(response.file, response.fileName);
				this.exportState = {
					message : "Votre fichier a été téléchargé avec succès.",
					state: State.SUCCESS
				}
			},() => {
				this.exportState = {
					message : "Le téléchargement de votre fichier a rencontré un problème.",
					state: State.FAILED
				}
			});
	}
	closeAlert() {
		this.export = false;
	}
}
