import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ScrollTopService } from '../../../../core/utilities/scroll-top.service';

@Component({
	selector: 'careweb-app-ps-site-emission',
	templateUrl: './ps-site-emission.component.html',
	styleUrls: [ './ps-site-emission.component.scss' ]
})
export class PsSiteEmissionComponent implements OnInit {
	@Input() idPs: number;
	@Input() numeroPs: number;
	@Input() idSite: number;
	@Input() codeLastTypeRetour: String;
	@Input() showOngletSiteReroutage: Boolean;
	active = 1;
	constructor(private scrollTopService: ScrollTopService, private modalRef: BsModalRef) {}
	ngOnInit() {
		this.scrollTopService.setScrollTop();
	}

	closeModal() {
		this.modalRef.hide();
	}
}
