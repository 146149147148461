<div *ngFor="let channel of _channelsError" class="row" style="margin-top:20px" >
    <div class="col-md-12">
        <div class="box-bank">
            <div class="box-bank-error-content">
                <div class="row">
                    <div class="col-md-12 box-bank-error-title">
                        <div>{{channel.displayError}} : {{channel.bankName}}</div>
                        <!--<div style="float:right;cursor:pointer">X</div>-->
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div [innerHTML]="channel.message"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 box-bank-error-footer">
                        <div *ngIf="channel.status == 'TOKEN_EXPIRED' || channel.status == 'AUTH_FAILED' || channel.status == 'TOO_MANY_ATTEMPTS' || channel.status == 'PASSWORD_CHANGE_REQUIRED' || channel.status == 'CHALLENGE_CANCELLED' || channel.status == 'CHALLENGE_FAILED'  || channel.status == 'CHALLENGE_REQUIRED'  || channel.status == 'CHALLENGE_TIMED_OUT' || channel.status == 'USER_ACTION_REQUIRED' || channel.status == 'TOKEN_WILL_BE_EXPIRED'">
                            <button class="btn btn-warning" (click)="launchUpdate()">
                                <i class="fa fa-exclamation-triangle"></i> Renseigner les informations de mon compte
                            </button>
                        </div>
                        <div *ngIf="channel.status == 'PARTIAL_SUCCESS' || channel.status == 'FAILED' || channel.status == 'CLOSED' || channel.status == 'SERVICE_UNAVAILABLE'">
                            <button class="btn btn-primary spacing" (click)="refreshCnx()">
                                <i class="fa fa-refresh"></i> Rafraichir la connexion
                            </button>
                            <button class="btn btn-warning" (click)="contactSupport()">
                                <i class="fa fa-exclamation-triangle"></i> Contacter le support
                            </button>
                        </div>

                        <div *ngIf="redirectChannel">
                            <button class="btn btn-warning" (click)="triggerMigration()">
                                <i class="fa fa-exclamation-triangle"></i> Renseigner les informations de mon compte
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>