<div class="modal-header pb-1">
        <h3 class="modal-title" *ngIf="rsp?.typeRsp == 0">Détails du RSP - Paiement</h3>
        <h3 class="modal-title" *ngIf="rsp?.typeRsp == 1">Détails du RSP - Rejet</h3>
        <button class="close" name="btn_display_filter" data-dismiss="modal" type="button"
            title="Fermer" (click)="closeModal()"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body pb-0">
        <h3 class="title-border mt-0">N° de FSE - {{rsp?.numFacture || rsp?.numeroFacture}}</h3>
        <div class="form-block">
            <fieldset class="container-fluid">
                <div class="row">
                    <div class="col-sm-3 col-form-label">Numéro de PS</div>
                    <div class="col-sm-3 form-group">
                        <input type="text" class="form-control" readonly value="{{ps.numeroAdeli}}" />
                    </div>
                    <div class="col-sm-2 col-form-label">Nom du titulaire</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" readonly value="{{ps.nom}}" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3 col-form-label">Etablissement</div>
                    <div class="col-sm-9 form-group">
                        <input type="text" class="form-control" readonly
                            value="{{lot.raisonSociale}}" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3 col-form-label">Organisme émetteur</div>
                    <div class="col-sm-9 form-group">
                        <input type="text" class="form-control" readonly
                            value="{{rsp?.libelleOrganisme}}" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3 col-form-label">Date d'émission RSP</div>
                    <div class="col-sm-3 form-group">
                        <input type="text" class="form-control" readonly value="{{rsp?.fichierRetour.pieceJointeRetour.enveloppeRetour?.dateEnvoie | date:'shortDate'}}" />
                    </div>
                    <div class="col-sm-3 col-form-label">Dernière transmission</div>
                    <div class="col-sm-3 form-group">
                        <input type="text" class="form-control" readonly value="{{rsp?.fichierRetour.pieceJointeRetour.enveloppeRetour?.dateEnvoie | date:'shortDate'}}" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3 col-form-label">Montant demandé AMO</div>
                    <div class="col-sm-3 form-group">
                        <input type="text" class="form-control" readonly value="{{rsp?.montantRo | customCurrency}}" />
                    </div>
                    <div class="col-sm-3 col-form-label">Montant payé AMO</div>
                    <div class="col-sm-3 form-group">
                        <input type="text" class="form-control" readonly value="{{rsp?.montantFactureRo | customCurrency}}" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3 col-form-label">Montant demandé AMC</div>
                    <div class="col-sm-3 form-group">
                        <input type="text" class="form-control" readonly value="{{rsp?.montantRc | customCurrency}}" />
                    </div>
                    <div class="col-sm-3 col-form-label">Montant payé AMC</div>
                    <div class="col-sm-3 form-group">
                        <input type="text" class="form-control" readonly value="{{rsp?.montantFactureRc | customCurrency}}" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3 col-form-label">Montant total demandé AMO+AMC</div>
                    <div class="col-sm-3 form-group">
                        <input type="text" class="form-control" readonly value="{{montantTotalDemande}}" />
                    </div>
                    <div class="col-sm-3 col-form-label">Montant total payé AMO+AMC</div>
                    <div class="col-sm-3 form-group">
                        <input type="text" class="form-control" readonly value="{{montantTotalPaye}}" />
                    </div>
                </div>
                <div class="row" *ngIf="rsp.typeRsp == 1">
                        <div class="col-sm-3 col-form-label">Motif ou signalement</div>
                        <div class="col-sm-9 form-group">
                            <input type="text" class="form-control" readonly value="{{rsp?.libelleRejet}}" />
                        </div>
                </div>
                <div class="row">
                    <div class="col-sm-3 col-form-label">Libellé virement 1</div>
                    <div class="col-sm-9 form-group">
                        <input type="text" class="form-control" readonly value="{{rsp?.libelleVirement1}}" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3 col-form-label">Libellé virement 2</div>
                    <div class="col-sm-9 form-group">
                        <input type="text" class="form-control" readonly value="{{rsp?.libelleVirement2}}" />
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
<div class="modal-footer">

</div>