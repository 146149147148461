import { Pipe, PipeTransform } from '@angular/core';

/**
 * Space the thousands of a given number
 */

@Pipe({
	name: 'formatThousands'
})
export class FormatThousandsPipe implements PipeTransform {
	constructor() {}

	transform(number: Number) {
		if (number) {
            return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ') + ' €';
        } else {
            return '0.00 €';
        }
	}
}
