import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone-number-formatter'
})
export class PhoneNumberFormatterPipe implements PipeTransform {

  transform(phoneNumber: string): string {
    
    return phoneNumber ? phoneNumber.replace(/[^\d]/,'').replace(/\s/g,'').replace( /\./gi,'') : '' ;
    
  }

}
