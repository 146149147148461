<div class="x-large-careweb-container">
    <section class="form-content">
        <div class="row form-title">
            <h2 *ngIf="isNewAbonnement && !isEditAbonnement" class="col-md-6">Ajouter un abonnement</h2>
            <h2 *ngIf="isEditAbonnement && !isNewAbonnement" class="col-md-6">Modification de l'abonnement</h2>
            <div class="col-md-6 text-title">
                <button class="btn btn-default btn-lg" type="button" (click)="goToAbonnementList()">Retour</button>
            </div>
        </div>
        <div id="onglet3" class="tab-pane fade show active">
            <div class="form-block">
                <div class="form-block" *ngIf="showEbicsForm">
                    <div class="row">
                        <div class="col">
                            <h3 class="title-border">
                                <div class="row">
                                    <div class="col-auto me-auto">Paramétre de connexion EBICS</div>
                                    <div class="col-auto"></div>
                                </div>
                            </h3>
                        </div>
                    </div>
                    <form [formGroup]="ebicsForm">
                        <fieldset class="container-fluid">
                            <div class="row">
                                <div class="col-sm-2 col-form-label">Nom de l'abonnement</div>
                                <div class="col-sm-4 form-group">
                                    <input type="text" formControlName="bankName" class="form-control"
                                        [ngClass]="{ 'is-invalid': btnAddClicked && ebicsFields.bankName.errors }"
                                        [readonly]="ebicsParams != null && ebicsParams.actif" required />
                                    <div *ngIf="btnAddClicked && ebicsFields.bankName.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="ebicsFields.bankName.errors"> Le
                                            champ nom de la banque est requis
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 col-form-label">ID d'hôte EBICS</div>
                                <div class="col-sm-4 form-group">
                                    <input type="text" formControlName="ebicsHostId" class="form-control"
                                        [ngClass]="{ 'is-invalid': btnAddClicked && ebicsFields.ebicsHostId.errors }"
                                        [readonly]="ebicsParams != null && ebicsParams.actif" required />
                                    <div *ngIf="btnAddClicked && ebicsFields.ebicsHostId.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="ebicsFields.ebicsHostId.errors"> Le
                                            champ ID
                                            d'hôte EBICS est requis
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="row">
                                
                                <div class="col-sm-2 col-form-label">URL du serveur EBICS</div>
                                <div class="col-sm-4 form-group">
                                    <input type="text" formControlName="ebicsUrl" class="form-control"
                                        [ngClass]="{ 'is-invalid': btnAddClicked && ebicsFields.ebicsUrl.errors }"
                                        [readonly]="ebicsParams != null && ebicsParams.actif" required />
                                    <div *ngIf="btnAddClicked  && ebicsFields.ebicsUrl.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="ebicsFields.ebicsUrl.errors"> Le champ URL
                                            est requis
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 col-form-label">Utilisateur EBICS (USER ID)</div>
                                <div class="col-sm-4 form-group">
                                    <input type="text" formControlName="ebicsUserId" class="form-control"
                                        [ngClass]="{ 'is-invalid': btnAddClicked && ebicsFields.ebicsUserId.errors }"
                                        [readonly]="ebicsParams != null && ebicsParams.actif" required />
                                    <div *ngIf="btnAddClicked && ebicsFields.ebicsUserId.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="ebicsFields.ebicsUserId.errors"> Le
                                            champ
                                            Utilisateur EBICS
                                            est requis
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                
                                <div class="col-sm-2 col-form-label">Identifiant représentant votre
                                    abonnement EBICS (Customer ID)
                                </div>
                                <div class="col-sm-4 form-group">
                                    <input type="text" formControlName="ebicsCustomerId" class="form-control"
                                        [ngClass]="{ 'is-invalid': btnAddClicked && ebicsFields.ebicsCustomerId.errors }"
                                        [readonly]="ebicsParams != null && ebicsParams.actif" required />
                                    
                                        <div *ngIf="btnAddClicked && ebicsFields.ebicsCustomerId.errors" class="invalid-feedback"> Le champ
                                            Customer ID est requis
                                        </div>
                                </div>
                                <div class="col-sm-2 col-form-label">Types de commandes EBICS (EBICS
                                    Order
                                    Types)
                                </div>
                                <div *ngIf="showEbicsForm && !ebicsParams?.actif" class="col-sm-4 form-group">
                                    <ng-select formControlName="ebicsOrderType" bindLabel="name"
                                        style="width:100%; height:calc(1.5em + .75rem + 2px)"
                                        placeholder="Veuillez sélectionner un type"
                                        [ngClass]="{ 'is-invalid': btnAddClicked && ebicsFields.ebicsOrderType.errors }"
                                        [searchable]="false" [clearable]="true" [multiple]="true"
                                        [(ngModel)]="selectedTypeCommande"
                                        (change)="onOrderTypeSelected()" required>
                                        <ng-option *ngFor="let order of listEbicsOrderType trackBy: trackByIndex"
                                            [value]="order">{{order.value}}</ng-option>
                                    </ng-select>
                                    <div *ngIf="btnAddClicked && ebicsFields.ebicsOrderType.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="ebicsFields.ebicsOrderType.errors"> Choisissez
                                            un type de commande.
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="ebicsParams?.actif" class="col-sm-4 form-group">
                                    <input type="text" class="form-control" readonly
                                        value="{{ebicsParams.ebicsOrderType}}" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-2 col-form-label">File Format</div>
                                <div class="col-sm-4 form-group">
                                    <input type="text" formControlName="ebicsFileFormat" class="form-control"
                                        [ngClass]="{ 'is-invalid': btnAddClicked && ebicsFields.ebicsFileFormat.errors }"
                                        [readonly]="ebicsParams != null && ebicsParams.actif" required />
                                    <div *ngIf="btnAddClicked  && ebicsFields.ebicsFileFormat.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="ebicsFields.ebicsFileFormat.errors"> Le champ File Format
                                            est requis
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 col-form-label">L'id de la banque</div>
                                <div class="col-sm-4 form-group">
                                    <input type="text" formControlName="bankId" class="form-control"
                                           [ngClass]="{ 'is-invalid': btnAddClicked && ebicsFields.bankId.errors }"
                                           [readonly]="ebicsParams != null && ebicsParams.actif" required />
                                    <div *ngIf="btnAddClicked  && ebicsFields.bankId.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="ebicsFields.bankId.errors"> Le champ id de la banque
                                            est requis
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="row" style="text-align:right">
                                <div class="col">
                                    <button type="button" class="btn btn-primary" (click)="onSubmit()">
                                        Enregistrer
                                    </button>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    </section>
</div>
