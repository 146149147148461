/**
 * Classe de representation d'un item de la multi-selection.
 */
export class ColumnSelectData {
    id: string;
    selected: boolean;
    label: string;
    inputData: any;
    constructor(data: any) {
        this.inputData = data;
    }
}
