import {Component, Input, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-modalreporting',
  templateUrl: './modalreporting.component.html',
  styleUrls: ['./modalreporting.component.scss']
})
export class ModalreportingComponent implements OnInit {
  @Input() message;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }


}
