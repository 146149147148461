import { ColDef, IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { formatDate } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { defineLocale, frLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { InputValidators } from 'src/main/webapp/src/app/components/validators/InputValidators';
import { isNullOrUndefined } from 'util';
import { AgGridHelper } from '../../../../components/ag-grid/ag-grid-helper';
import { gridConvertSort } from '../../../../components/ag-grid/ag-grid.utils';
import { AuthService } from '../../../../core/services/auth.service';
import { MapEmptyValueToNull } from '../../../../core/utilities/map-empty-value-to-null';
import { ScrollTopService } from '../../../../core/utilities/scroll-top.service';
import { AmcDataRaw } from '../../../../data/amcDataRaw';
import { AmoDataRaw } from '../../../../data/amoDataRaw';
import { CodeEntreeDataRaw } from '../../../../data/codeEntreeDataRaw';
import { OrganismeDataRaw } from '../../../../data/organismeDataRaw';
import { CodeEntreeService } from '../code-entree.service';
import { OrganismePreviewComponent } from '../organismes-subcomponents/organisme-preview.component';
import { OrganismesService } from '../organismes.service';

@Component({
	selector: 'careweb-app-fiche-organisme',
	templateUrl: './fiche-organisme.component.html',
	styleUrls: ['./fiche-organisme.component.scss']
})
export class FicheOrganismeComponent implements OnInit {
	isOrganismeDetailsCollapsed = false;
	isListRegroupementCollapsed = true;
	@Input() currentOrganisme: any;
	isFormOrganismValid = false;
	btnClicked = false;
	showCodeEntreeModal = false;
	orgaismeForm: UntypedFormGroup;
	gridHelperCodeEntree: AgGridHelper;
	codeEntreeDataRaw: CodeEntreeDataRaw;
	showConfirmModal = false;
	modalMessage: string;
	selectedCodeEntree: CodeEntreeDataRaw;
	codeEntreeForm: UntypedFormGroup;
	newCodeEntree: CodeEntreeDataRaw;
	newBtnClicked = false;
	codeEntreeExists = false;
	startDate: any;
	endDate: any;
	@ViewChild('retourForm')
	form: NgForm;
	initalValues: any;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private organismesSvc: OrganismesService,
		private toastr: ToastrService,
		private authSvc: AuthService,
		private fb: UntypedFormBuilder,
		private codeEntreeService: CodeEntreeService,
		private inputValidators: InputValidators,
		private localeService: BsLocaleService,
		private scrollTopService: ScrollTopService,
		private mapEmptyValueToNull: MapEmptyValueToNull
	) {
		// Scroll to top
		this.scrollTopService.setScrollTop();

		this.currentOrganisme = new OrganismeDataRaw();
		this.gridHelperCodeEntree = new AgGridHelper();
		// Define local date fr
		defineLocale('fr', frLocale);
	}

	ngOnInit() {
		const idOrganisme = this.activatedRoute.snapshot.params.id;
		if(!parseInt(idOrganisme)){
			this.router.navigate(['/404'])
		}
		const typeOrganisme = this.activatedRoute.snapshot.params.typeOrganisme;
		this.initForm();

		if (!isNullOrUndefined(idOrganisme) && !isNullOrUndefined(typeOrganisme)) {
			this.getOrganismeByIdAndType(idOrganisme, typeOrganisme);
		}
		this.setLocale();
	}

	initForm() {
		this.orgaismeForm = this.fb.group({
			// organisme.libelleCourt
			sigle: new UntypedFormControl({ value: '', disabled: !this.isAuthorized() }, [
				Validators.required,
				this.inputValidators.inputWithSpaces
			]),
			// organisme.libelleLong
			libelle: new UntypedFormControl({ value: '', disabled: !this.isAuthorized() }, [
				Validators.required,
				this.inputValidators.inputWithSpaces
			]),
			actif: new UntypedFormControl({ value: '', disabled: !this.isAuthorized() }),
			retourRegroupement: new UntypedFormControl({ value: '', disabled: !this.isAuthorized() }, [Validators.required]),
			adresse1: new UntypedFormControl({ value: '', disabled: !this.isAuthorized() }),
			adresse2: new UntypedFormControl({ value: '', disabled: !this.isAuthorized() }),
			codePostal: new UntypedFormControl({ value: '', disabled: !this.isAuthorized() }),
			ville: new UntypedFormControl({ value: '', disabled: !this.isAuthorized() }),
			telephone: new UntypedFormControl({ value: '', disabled: !this.isAuthorized() }),
			fax: new UntypedFormControl({ value: '', disabled: !this.isAuthorized() }),
			commentaires: new UntypedFormControl({ value: '', disabled: !this.isAuthorized() }),
			// Si Type organisme RO
			tousCentres: new UntypedFormControl({ value: '', disabled: !this.isAuthorized() }),
			codeGr: new UntypedFormControl({ value: '', disabled: !this.isAuthorized() }),
			codeCaisseCas: new UntypedFormControl({ value: '', disabled: !this.isAuthorized() }),
			codeCentreCas: new UntypedFormControl({ value: '', disabled: !this.isAuthorized() }),
			codeOrgType1: new UntypedFormControl({ value: '', disabled: !this.isAuthorized() }),
			codeCentreInfoType0: new UntypedFormControl({ value: '', disabled: !this.isAuthorized() }),
			envoisCompresses: new UntypedFormControl({ value: '', disabled: !this.isAuthorized() }),
			codeOrgType1Gen: new UntypedFormControl({ value: '', disabled: !this.isAuthorized() }),
			codeCentreInfoType0Gen: new UntypedFormControl({ value: '', disabled: !this.isAuthorized() }),
			// Si Type organisme RC
			codeOfficiel: new UntypedFormControl({ value: '', disabled: !this.isAuthorized() }),
			rcSeul: new UntypedFormControl({ value: '', disabled: !this.isAuthorized() }),
			aidePerenne: new UntypedFormControl({ value: '', disabled: !this.isAuthorized() })
			//dateDebutValidite: new FormControl({ value: '', disabled: !this.isAuthorized() }),
			//dateFinValidite: new FormControl({ value: '', disabled: !this.isAuthorized() })
		});
	}

	getOrganismeByIdAndType(idOrganisme, typeOrganisme) {
		const getOrganisme = this.organismesSvc
			.getOrganismeByIdAndType({
				idOrganisme: idOrganisme,
				typeOrganisme: typeOrganisme
			})
		if (getOrganisme) {
			getOrganisme.subscribe((data) => {
				if (typeOrganisme === 'RO') {
					this.currentOrganisme = new AmoDataRaw();
				} else if (typeOrganisme === 'RC') {
					this.currentOrganisme = new AmcDataRaw();
				}

				this.currentOrganisme = data;

				this.currentOrganisme.telephone = this.formatPhoneNumbers(this.currentOrganisme.telephone);
				this.currentOrganisme.fax = this.formatPhoneNumbers(this.currentOrganisme.fax);

				this.currentOrganisme.libelleCodeEntree = '';
				if (this.currentOrganisme.typeOrganisme === 'RC') {
					if (!isNullOrUndefined(this.currentOrganisme.codesEntrees)) {
						if (this.currentOrganisme.codesEntrees.length === 1) {
							this.currentOrganisme.libelleCodeEntree = this.currentOrganisme.codesEntrees[0].codeEntree;
						} else if (this.currentOrganisme.codesEntrees.length > 1) {
							this.currentOrganisme.codesEntrees.forEach((codeEntree) => {
								this.currentOrganisme.libelleCodeEntree += codeEntree.codeEntree + ' - ';
							});
						}
					}
					this.currentOrganisme.rcSeul = this.currentOrganisme.rcSeul;
					this.currentOrganisme.aidePerenne = this.currentOrganisme.aidePerenne;
				}

				this.fromObjectToForm();
			},
				err => {
					if (err.status === 400) {
						this.router.navigate(['/404'])
					}
				});
		}
		else {
			this.router.navigate(['/404'])
		}
	}

	goBack() {
		this.router.navigate(['/organismes']);
		//this.location.back();
	}

	cancel() {
		// Rénitialiser les champs avec les anciens valeurs
		if (
			!isNullOrUndefined(this.currentOrganisme.idOrganisme) &&
			!isNullOrUndefined(this.currentOrganisme.typeOrganisme)
		) {
			this.getOrganismeByIdAndType(this.currentOrganisme.idOrganisme, this.currentOrganisme.typeOrganisme);
		}
		this.isFormOrganismValid = false;
		this.btnClicked = false;
	}

	save() {
		this.btnClicked = true;
		this.fromFormToObject();
		if (this.orgaismeForm.valid 
			&& JSON.stringify(this.mapEmptyValueToNull.emptyValueToNull(this.initalValues)) != JSON.stringify(this.mapEmptyValueToNull.emptyValueToNull(this.orgaismeForm.value))) {
			this.initalValues = this.orgaismeForm.value;
			if (this.currentOrganisme.typeOrganisme === 'RO') {
				// if currentOrganisme
				this.organismesSvc
					.saveAmo(this.currentOrganisme)
					.then((res: OrganismeDataRaw) => {
						if (!isNullOrUndefined(res)) {
							this.toastr.success("L'organisme a été mis à jour avec succès");
						}
					})
					.catch((err) => { });
			} else if (this.currentOrganisme.typeOrganisme === 'RC') {
				// if currentOrganisme
				this.organismesSvc
					.saveAmc(this.currentOrganisme)
					.then((res: OrganismeDataRaw) => {
						if (!isNullOrUndefined(res)) {
							this.toastr.success("L'organisme a été mis à jour avec succès");
						}
					})
					.catch((err) => { });
			}
		}
	}

	fromFormToObject() {
		this.orgaismeForm.markAllAsTouched();
		this.orgaismeForm.controls['sigle'].setValue(this.formFields.sigle.value.trim());
		this.orgaismeForm.controls['libelle'].setValue(this.formFields.libelle.value.trim());
		this.orgaismeForm.controls['adresse1'].setValue(this.formFields.adresse1.value.trim());
		this.orgaismeForm.controls['adresse2'].setValue(this.formFields.adresse2.value.trim());
		this.currentOrganisme.actif = this.formFields.actif.value;
		this.currentOrganisme.libelleCourt = this.formFields.sigle.value ? this.formFields.sigle.value.trim() : '';
		this.currentOrganisme.libelleLong = this.formFields.libelle.value ? this.formFields.libelle.value.trim() : '';
		this.currentOrganisme.adresse1 = this.formFields.adresse1.value ? this.formFields.adresse1.value : '';
		this.currentOrganisme.adresse2 = this.formFields.adresse2.value ? this.formFields.adresse2.value : '';
		this.currentOrganisme.codePostal = this.formFields.codePostal.value;
		this.currentOrganisme.ville = this.formFields.ville.value ? this.formFields.ville.value : '';
		this.currentOrganisme.commentaires = this.formFields.commentaires.value
			? this.formFields.commentaires.value
			: '';
		this.currentOrganisme.telephone = this.formFields.telephone.value;
		this.currentOrganisme.fax = this.formFields.fax.value;
		this.currentOrganisme.retourRegroupement = this.formFields.retourRegroupement.value;
		//this.currentOrganisme.dateFinValidite = this.formFields.dateFinValidite.value;
		//this.currentOrganisme.dateDebutValidite = this.formFields.dateDebutValidite.value;

		if (this.currentOrganisme.typeOrganisme === 'RO') {
			this.currentOrganisme.tousCentres = this.orgaismeForm.controls['tousCentres'].value;
			this.orgaismeForm.controls['codeGr'].setValidators([Validators.required]);
			this.currentOrganisme.codeGr = this.orgaismeForm.controls['codeGr'].value;
			this.orgaismeForm.controls['codeCaisseCas'].setValidators([Validators.required]);
			this.currentOrganisme.codeCaisseCas = this.orgaismeForm.controls['codeCaisseCas'].value;
			this.orgaismeForm.controls['codeCentreCas'].setValidators([Validators.required]);
			this.currentOrganisme.codeCentreCas = this.orgaismeForm.controls['codeCentreCas'].value;
			this.orgaismeForm.controls['codeOrgType1'].setValidators([Validators.required]);
			this.currentOrganisme.codeOrgType1 = this.orgaismeForm.controls['codeOrgType1'].value;
			this.orgaismeForm.controls['codeCentreInfoType0'].setValidators([Validators.required]);
			this.currentOrganisme.codeCentreInfoType0 = this.orgaismeForm.controls['codeCentreInfoType0'].value;
			this.orgaismeForm.controls['codeOrgType1Gen'].setValidators([Validators.required]);
			this.currentOrganisme.codeOrgType1Gen = this.orgaismeForm.controls['codeOrgType1Gen'].value;
			this.orgaismeForm.controls['codeCentreInfoType0Gen'].setValidators([Validators.required]);
			this.currentOrganisme.codeCentreInfoType0Gen = this.orgaismeForm.controls['codeCentreInfoType0Gen'].value;
			this.currentOrganisme.envoisCompresses = this.formFields.envoisCompresses.value;
		} else if (this.currentOrganisme.typeOrganisme === 'RC') {
			this.orgaismeForm.controls['codeGr'].setValidators(null);
			this.orgaismeForm.controls['codeCaisseCas'].setValidators(null);
			this.orgaismeForm.controls['codeCentreCas'].setValidators(null);
			this.orgaismeForm.controls['codeOrgType1'].setValidators(null);
			this.orgaismeForm.controls['codeCentreInfoType0'].setValidators(null);
			this.orgaismeForm.controls['codeOrgType1Gen'].setValidators(null);
			this.orgaismeForm.controls['codeCentreInfoType0Gen'].setValidators(null);
			this.currentOrganisme.dateDebutValidite = this.startDate
				? formatDate(this.startDate, 'dd/MM/yyyy', 'fr-FR')
				: null;
			this.currentOrganisme.dateFinValidite = this.endDate
				? formatDate(this.endDate, 'dd/MM/yyyy', 'fr-FR')
				: null;
			this.currentOrganisme.rcSeul = this.formFields.rcSeul.value;
			this.currentOrganisme.aidePerenne = this.formFields.aidePerenne.value;
			this.currentOrganisme.babusiaux = false;
			this.currentOrganisme.codeOfficiel = this.formFields.codeOfficiel.value;
		}
	}

	get formFields() {
		return this.orgaismeForm.controls;
	}

	fromObjectToForm() {
		this.orgaismeForm.controls['actif'].setValue(this.currentOrganisme.actif);
		this.orgaismeForm.controls['sigle'].setValue(
			this.currentOrganisme.libelleCourt ? this.currentOrganisme.libelleCourt.trim() : ''
		);
		this.orgaismeForm.controls['libelle'].setValue(
			this.currentOrganisme.libelleLong ? this.currentOrganisme.libelleLong.trim() : ''
		);
		this.orgaismeForm.controls['adresse1'].setValue(
			this.currentOrganisme.adresse1 ? this.currentOrganisme.adresse1.trim() : ''
		);
		this.orgaismeForm.controls['adresse2'].setValue(
			this.currentOrganisme.adresse2 ? this.currentOrganisme.adresse2.trim() : ''
		);
		this.orgaismeForm.controls['codePostal'].setValue(this.currentOrganisme.codePostal);
		this.orgaismeForm.controls['ville'].setValue(
			this.currentOrganisme.ville ? this.currentOrganisme.ville.trim() : ''
		);
		this.orgaismeForm.controls['commentaires'].setValue(
			this.currentOrganisme.commentaires ? this.currentOrganisme.commentaires.trim() : ''
		);
		this.orgaismeForm.controls['telephone'].setValue(this.currentOrganisme.telephone);
		this.orgaismeForm.controls['fax'].setValue(this.currentOrganisme.fax);
		this.orgaismeForm.controls['retourRegroupement'].setValue(this.currentOrganisme.retourRegroupement);

		if (this.currentOrganisme.typeOrganisme === 'RO') {
			this.orgaismeForm.controls['tousCentres'].setValue(this.currentOrganisme.tousCentres);
			this.orgaismeForm.controls['codeGr'].setValue(this.currentOrganisme.codeGr);
			this.orgaismeForm.controls['codeCaisseCas'].setValue(this.currentOrganisme.codeCaisseCas);
			this.orgaismeForm.controls['codeCentreCas'].setValue(this.currentOrganisme.codeCentreCas);
			this.orgaismeForm.controls['codeOrgType1'].setValue(this.currentOrganisme.codeOrgType1);
			this.orgaismeForm.controls['codeCentreInfoType0'].setValue(this.currentOrganisme.codeCentreInfoType0);
			this.orgaismeForm.controls['codeOrgType1Gen'].setValue(this.currentOrganisme.codeOrgType1Gen);
			this.orgaismeForm.controls['codeCentreInfoType0Gen'].setValue(this.currentOrganisme.codeCentreInfoType0Gen);
			this.orgaismeForm.controls['envoisCompresses'].setValue(this.currentOrganisme.envoisCompresses);
		} else if (this.currentOrganisme.typeOrganisme === 'RC') {

			this.orgaismeForm.controls['codeOfficiel'].setValue(this.currentOrganisme.codeOfficiel);
			this.orgaismeForm.controls['rcSeul'].setValue(this.currentOrganisme.rcSeul);
			this.orgaismeForm.controls['aidePerenne'].setValue(this.currentOrganisme.aidePerenne);

			var dateParts = this.currentOrganisme.dateDebutValidite
				? this.currentOrganisme.dateDebutValidite.split('/')
				: null;
			this.startDate = this.currentOrganisme.dateDebutValidite
				? new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
				: null;

			var datePartsFin = this.currentOrganisme.dateFinValidite
				? this.currentOrganisme.dateFinValidite.split('/')
				: null;
			this.endDate = this.currentOrganisme.dateFinValidite
				? new Date(+datePartsFin[2], datePartsFin[1] - 1, +datePartsFin[0])
				: null;
		}
		this.orgaismeForm.markAllAsTouched();
		this.initalValues = this.orgaismeForm.value;
	}

	openModalCodeEntree() {
		this.showCodeEntreeModal = true;
		this.codeEntreeDataRaw = new CodeEntreeDataRaw();
		this.gridHelperCodeEntree.gridOptions = {
			columnDefs: this.getColumnDefs(),
			defaultColDef: {
				resizable: true
			},
			context: { parentComponent: this },
			components: {
				customInputFilterComponent: this.getInputFloatingFilterComponent()
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 3,
			maxBlocksInCache: 0,
			infiniteInitialRowCount: 1,
			paginationPageSize: 3,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			localeText: this.gridHelperCodeEntree.getLocaleText(),
			onGridReady: (params) => this.onGridReady(params)
			// onGridSizeChanged: (params) => this.onGridSizeChanged(params)
		};

		this.codeEntreeForm = this.fb.group({
			codeEntree: new UntypedFormControl({ value: '', disabled: !this.isAuthorized() }, [
				Validators.required,
				this.inputValidators.inputWithSpaces
			])
		});
	}

	onGridSizeChanged(params) {
		this.gridHelperCodeEntree.gridApi.sizeColumnsToFit();
	}

	setRetourMaxDate() { }

	onGridReady(params) {
		const ctx = this;
		this.gridHelperCodeEntree.gridApi = params.api;
		this.gridHelperCodeEntree.gridColumnApi = params.columnApi;
		ctx.codeEntreeDataRaw.idAmc = ctx.currentOrganisme.idOrganisme;
		this.retrieveDataSource();
	}
	retrieveDataSource() {
		const ctx = this;
		const dataSource: IServerSideDatasource = {
			getRows: function (paramsRows: IServerSideGetRowsParams) {
				const sorts = gridConvertSort(ctx.gridHelperCodeEntree.gridSortModel(), []);
				ctx.codeEntreeService
					.getCodeEntreeFilteredList(
						{
							page: ctx.gridHelperCodeEntree.gridApi.paginationGetCurrentPage(),
							size: ctx.gridHelperCodeEntree.gridApi.paginationGetPageSize(),
							sorts
						},
						ctx.codeEntreeDataRaw
					)
					.subscribe(
						(data) => {
							const rowCount = data.totalElements;
							ctx.gridHelperCodeEntree.manageNoRowsOverlay(rowCount);
							paramsRows.success({"rowData": data.content, "rowCount": rowCount});
						},
						() => paramsRows.fail()
					);
			}
		};
		this.gridHelperCodeEntree.gridApi.setServerSideDatasource(dataSource);
		this.gridHelperCodeEntree.gridApi.sizeColumnsToFit();
	}

	private getColumnDefs(): ColDef[] {
		return [
			{
				headerName: 'Code entrée',
				field: 'codeEntree',
				minWidth: 180,
				lockVisible: true,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true },
				suppressMenu: true,
				sortable: true
			},
			{
				headerName: '',
				field: 'options',
				maxWidth: 70,
				lockVisible: true,
				hide: !this.isAuthorized(),
				cellStyle: this.gridHelperCodeEntree.centerContent(),
				onCellClicked: (params) => this.openModalConfim(params),
				cellRenderer: OrganismePreviewComponent,
				cellRendererParams: {
					action: 'deleteCD',
					iconClass: 'fa fa-trash'
				}
			}
		];
	}
	public closeModal() {
		this.showCodeEntreeModal = false;
		this.codeEntreeExists = false;
		this.newBtnClicked = false;
		// this.btnSaveCodeEntreeClicked = false;
	}
	isAuthorized() {
		return this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
	}

	public openModalConfim(params) {
		this.showConfirmModal = true;
		this.modalMessage = 'Voulez-vous supprimer ce code entrée?';
		this.selectedCodeEntree = params.data;
	}
	/**
   * hide && show modal
   */
	public showModal(showModal: boolean) {
		this.showConfirmModal = showModal;
	}

	/**
   * confirm modal action from child component
   */
	public confirmModal() {
		if (!isNullOrUndefined(this.selectedCodeEntree) && !isNullOrUndefined(this.selectedCodeEntree.idCodeEntree)) {
			// delete code entree
			this.codeEntreeService
				.delete(this.selectedCodeEntree.idCodeEntree)
				.then((ret) => {
					this.showModal(false);
					this.toastr.success('Le code entrée a été supprimé avec succès');
					// rafraichir code entrée liste aprés suppression
					this.retrieveDataSource();
					// rafraichir current organisme pour supprimer le code entrer du formulaire
					this.getOrganismeByIdAndType(this.currentOrganisme.idOrganisme, 'RC');
					this.selectedCodeEntree = new CodeEntreeDataRaw();
				})
				.catch((err) => {
					this.showModal(false);
					this.selectedCodeEntree = new CodeEntreeDataRaw();
					this.toastr.error(
						'Une erreur est survenue lors de la suppression du code entrée !!',
						"Suppression d'un code entrée"
					);
				});
		}
	}

	saveCodeEntree() {
		this.newBtnClicked = true;
		this.codeEntreeExists = false;
		this.newCodeEntree = new CodeEntreeDataRaw();

		if (this.codeEntreeForm.valid && !isNullOrUndefined(this.currentOrganisme.idOrganisme)) {
			this.newCodeEntree.idAmc = this.currentOrganisme.idOrganisme;
			this.newCodeEntree.codeEntree = this.codeEntreeForm.controls['codeEntree'].value;
			// verify if code entree existe deja
			this.codeEntreeService.verifyIfCodeEntreExist(this.newCodeEntree).subscribe((returnedCode) => {
				if (isNullOrUndefined(returnedCode.idCodeEntree)) {
					this.codeEntreeExists = false;
					this.codeEntreeService
						.save(this.newCodeEntree)
						.then((data) => {
							if (!isNullOrUndefined(data)) {
								this.toastr.success('Le code entrée a été ajouté avec succès');
								// rafraichir la liste aprés l'ajout
								this.retrieveDataSource();
								// rafraichir current organisme pour ajouter le nouveau code entrée
								this.getOrganismeByIdAndType(this.currentOrganisme.idOrganisme, 'RC');
							}
							this.newBtnClicked = false;
							this.codeEntreeExists = false;
							this.codeEntreeForm.reset();
						})
						.catch((err) => {
							this.toastr.error(
								'Une erreur est survenue lors du sauvegarde du code entrée',
								'Ajouter un code entrée'
							);
							this.newBtnClicked = false;
							this.codeEntreeExists = false;
						});
				} else {
					this.codeEntreeExists = true;
				}
			});
		}
	}

	getInputFloatingFilterComponent() {
		const ctx = this;
		function InputFloatingFilter() { }

		InputFloatingFilter.prototype.init = function (params) {
			this.eGui = document.createElement('div');
			this.eGui.setAttribute('class', 'ag-input-wrapper');

			this.eGui.innerHTML =
				"<input type='number' class='customFilterInput' style='width:100%; box-shadow: none;' onKeyPress='if(this.value.length==10) return false;'/>";

			this.currentValue = null;
			this.eFilterInput = this.eGui.querySelector('input');
			this.eFilterInput.style.color = params.color;
			const that = this;
			function onInputChanged() {
				const filterField = params.filterParams.colDef.field;
				if (that.eFilterInput.value === '') {
					// Remove the filter
					delete ctx.codeEntreeDataRaw[filterField];
					params.parentFilterInstance(function (instance) {
						instance.onFloatingFilterChanged(null, null);
					});
					return;
				}
				that.currentValue = that.eFilterInput.value;
				if (that.eFilterInput.value.length >= 3) {
					if (that.currentValue !== '') {
						ctx.codeEntreeDataRaw[filterField] = that.eFilterInput.value;
					}
					params.parentFilterInstance(function (instance) {
						instance.onFloatingFilterChanged('equals', that.currentValue);
					});
				}
			}
			this.eFilterInput.addEventListener('input', onInputChanged);
		};

		InputFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
			if (!parentModel) {
				this.eFilterInput.value = '';
				this.currentValue = null;
			} else {
				this.eFilterInput.value = parentModel.filter + '';
				this.currentValue = parentModel.filter;
			}
		};

		InputFloatingFilter.prototype.getGui = function () {
			return this.eGui;
		};

		return InputFloatingFilter;
	}

	formatPhoneNumbers = (params): string => {
		if (!isNullOrUndefined(params)) {
			return params.replace(/\s/g, '').replace(/\./gi, '');
		} else {
			return params;
		}
	};
	private setLocale() {
		this.localeService.use('fr');
	}
}
