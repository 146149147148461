import { Component, ElementRef, HostBinding, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

/**
 * Directive de type menu-déroulant s'assurant que le menu ouvert possède exactement la même largeur que le bouton de sélection.
 *
 * Cette directive fonctionne car elle exploite le fait que le header possède un z-order plus élevé que l'application.
 * Il n'y a qu'à rendre visible le bloc contenant le menu pour qu'il apparaîsse au bon endroit.
 */
@Component({
	selector: 'careweb-header-dropdown',
	template: '<ng-content></ng-content>'
})
export class HeaderDropdownComponent implements OnInit {
	/** La partie mobile du menu. */
	@Input() submenu: ElementRef<any>;
	@Input() leaveWhenMouseLeave=true;

	/** Indique si le menu est ouvert. */
	@HostBinding('class.open') isOpen: boolean;

	constructor(private el: ElementRef, private renderer: Renderer2) {
		this.isOpen = false;
	}

	ngOnInit() {
		// Masque le sous-menu par défaut
		this.updateMenuDisplay();
	}

	private updateMenuDisplay() {
		if (window.matchMedia('(min-width: 992px)').matches) {
			// S'assure que le sous-menu fait la même largeur que le bouton d'ouverture sur un écran au dela de 992px de largeur
			const width = this.el.nativeElement.offsetWidth;
			this.renderer.setStyle(this.submenu, 'width', `${width}px`);
		}
		this.renderer.setStyle(this.submenu, 'display', this.isOpen ? 'block' : 'none');
	}

	@HostListener('click')
	onClick() {
		this.isOpen = !this.isOpen;
		this.updateMenuDisplay();
	}

	@HostListener('mouseleave')
	onMouseLeave() {
		// Referme le menu si la souris s'éloigne
		if (this.isOpen && this.leaveWhenMouseLeave) {
			this.onClick();
		}
	}
}
