import { NgForm } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale, frLocale } from 'ngx-bootstrap/chronos';

@Component({
	selector: 'careweb-date-selecteur-periode',
	templateUrl: './date-selecteur-periode.component.html'
})
export class DateSelecteurPeriodeComponent implements OnInit {
	@Input() startDate: Date;
	@Input() endDate: Date;
	@Input() ranges: number[];
	@Input() applyMindate: boolean = false;

	@Output() dateFilterChange = new EventEmitter<any>();

	@ViewChild('retourForm')
	form: NgForm;
	retourMaxDate: Date;
	nbrDays = 7;
	currentDate: Date = new Date();
	minDate: Date = new Date();
	selectedRange: number;
	isValidDate = true;
	dateFin: Date = new Date();
	dateDebut: Date = new Date();
	isCollapsed = false;

	constructor(private localeService: BsLocaleService) {
		defineLocale('fr', frLocale);
	}

	ngOnInit() {
		this.initSelecteurPeriode();
		this.minDate.setDate(this.minDate.getDate() - 150); // les 5 derniers Mois
	}

	initSelecteurPeriode() {
		this.localeService.use('fr');
		this.dateFin = this.endDate;
		if (this.startDate) {
			this.dateDebut = this.startDate;
		} else {
			this.startDate.setDate(new Date().getDate() - this.nbrDays);
			this.dateDebut.setDate(this.dateFin.getDate() - this.nbrDays);
		}

		const diffDays = Math.round(
			Math.abs((this.endDate.getTime() - this.startDate.getTime()) / (1000 * 60 * 60 * 24))
		);
		this.selectedRange = this.ranges[this.ranges.indexOf(diffDays)];
	}

	datePeriodeClick(period: number): void {
		this.isValidDate = true;
		if (this.selectedRange === period) {
			this.isCollapsed = false;
		} else {
			this.selectedRange = period;
			this.startDate = new Date();
			this.dateDebut = new Date();
			this.endDate = new Date();
			this.dateFin = new Date();
			this.dateDebut.setDate(this.dateDebut.getDate() - period);
			this.startDate.setDate(new Date().getDate() - period);
			this.dateFilterChange.emit({ startDate: this.startDate, endDate: this.endDate, range : true });
			this.isCollapsed = false;
		}
	}

	setRetourMaxDate($event) {
		this.retourMaxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 100));
	}

	onChangeInputEnd($event) {
		if($event.target.value === 'Invalid date'){
			$event.target.value = 'Date invalide'
		}
	}

	onChangeInputStart($event) {
		if($event.target.value === 'Invalid date'){
			$event.target.value = 'Date invalide'
		}
	}

	persoFilter(): void {
		this.selectedRange = 0;
		this.isValidDate = false;
		if (this.startDate && this.endDate) {
			if (this.startDate.getTime() < this.endDate.getTime()) {
				this.isValidDate = true;
				this.dateFilterChange.emit({ startDate: this.startDate, endDate: this.endDate, range : false });
				this.isCollapsed = false;
			}
		}
	}
}
