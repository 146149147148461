import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { InputValidators } from 'src/main/webapp/src/app/components/validators/InputValidators';
import { OctDataRaw } from 'src/main/webapp/src/app/data/octDataRaw';
import { PsDataRaw } from 'src/main/webapp/src/app/data/psDataRaw';
import { MigrationOctDataRaw } from '../../../data/migrationOctDataRaw';
import { MigrationOctService } from '../migration-oct.service';

@Component({
	selector: 'careweb-app-add-migration-oct',
	templateUrl: './add-migration-oct.component.html',
	styleUrls: ['./add-migration-oct.component.scss']
})

export class AddMigrationOctComponent implements OnInit {
	@Input() idPs: number;
	@Input() numeroPS: number;
	@Input() isEditing?: boolean;
	addMigrationForm: FormGroup;
	modBalMetrice;
	currentbalEmettrice;
	selectedPs: PsDataRaw;
	psList: any;
	submitted: Boolean = false;
	currentOct: OctDataRaw;
	title: String;

	constructor(
		private modalRef: BsModalRef,
		private toastr: ToastrService,
		private fb: FormBuilder,
		private migrationSvc: MigrationOctService,
		private inputValidators: InputValidators,
		private modalService: BsModalService,
	) {
	}

	ngOnInit() {
		this.submitted = false;
		this.currentOct = JSON.parse(localStorage.getItem('octCurrent'));
		this.initializeCurrentPage();
		if (this.isEditing) {
			this.title = "Modifier le paramètrage de la migration"
		} else {
			this.title = "Ajouter un compte à migrer"
		}
	}

	initializeCurrentPage() {
		this.validForm();
	}

	validForm() {
		this.addMigrationForm = this.fb.group({
			serveurPop: ['', [Validators.required, this.inputValidators.inputWithSpaces]],
			port: ['', [Validators.required, this.inputValidators.inputWithSpaces]],
			username: ['', [Validators.required, this.inputValidators.inputWithSpaces]],
			numeroPS: ['', [Validators.required, this.inputValidators.inputWithSpaces]],
			numeroFacturation: ['', [Validators.required, this.inputValidators.inputWithSpaces]],
			password: ['', [Validators.required, this.inputValidators.inputWithSpaces]]
		});
	}

	get formFields() {
		return this.addMigrationForm.controls;
	}

	closeModal() {
		this.modalRef.hide();
		this.modalService.onHide.subscribe(() => {
			this.migrationSvc.notifyModalClosed();
		});
	}

	onSubmit() {
		this.submitted = true;

		if (this.addMigrationForm.invalid) {
			return;
		}
		let dataNewSite = this.addMigrationForm.value;
		let currentBoiteMail: MigrationOctDataRaw = new MigrationOctDataRaw();

		currentBoiteMail.serveurPop = dataNewSite.serveurPop
			? (dataNewSite.serveurPop as String).trim()
			: this.currentbalEmettrice ? (this.currentbalEmettrice as String).trim() : '';
		currentBoiteMail.serveurPop = dataNewSite.serveurPop
			? (dataNewSite.serveurPop as String).trim()
			: dataNewSite.serveurPop;
		//currentBoiteMail.idPs = this.idPs;
		currentBoiteMail.idOct = this.currentOct.idOct;
		currentBoiteMail.username = dataNewSite.username
			? (dataNewSite.username as String).trim()
			: dataNewSite.username;
		currentBoiteMail.password = dataNewSite.password
			? (dataNewSite.password as String).trim()
			: dataNewSite.password;
		currentBoiteMail.numeroPS = dataNewSite.numeroPS
			? (dataNewSite.numeroPS as String).trim()
			: dataNewSite.numeroPS;
		currentBoiteMail.numeroFacturation = dataNewSite.numeroFacturation
			? (dataNewSite.numeroFacturation as String).trim()
			: dataNewSite.numeroFacturation;
		currentBoiteMail.port = dataNewSite.port
			? (dataNewSite.port as String).trim()
			: dataNewSite.port;
		//currentBoiteMail.actif = dataNewSite.actif.value;
		this.createBoiteMail(currentBoiteMail);
	}

	createBoiteMail(currentBoiteMail) {
		this.migrationSvc.create(currentBoiteMail).subscribe(
			(data) => {
				if (data) {
					this.toastr.success("La boite mail à migrer a été ajoutée avec succès.");
					this.closeModal();
				}
				this.submitted = false;
			},
			(err) => {
				var reason = "";
				if (err.message.indexOf("PS_NOT_EXIST") > -1) {
					reason = "<br> Le numéro de PS " + currentBoiteMail.numeroPS + " ne correspond pas à un PS existant."
				}
				this.toastr.error("La boite mail à migrer n'a pas pu être ajoutée." + reason, null, {
					enableHtml: true
				});
			}
		);
	}

	reset() {
		this.modalRef.hide();
	}
}
