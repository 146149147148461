import { IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { AgGridHelper } from '../../../../components/ag-grid/ag-grid-helper';
import { gridConvertSort } from '../../../../components/ag-grid/ag-grid.utils';
import { AuthService } from '../../../../core/services/auth.service';
import { ScrollTopService } from '../../../../core/utilities/scroll-top.service';
import { ArlDataRaw } from '../../../../data/arlDataRaw';
import { FseDataRaw } from '../../../../data/fseDataRaw';
import { LotsDataRaw } from '../../../../data/lotsDataRaw';
import { PsDataRaw } from '../../../../data/psDataRaw';
import { RspDataRaw } from '../../../../data/rspDataRaw';
import { PsService } from '../../../ps/ps.service';
import { ARLService } from '../../arl/arl.service';
import { FsePreviewComponent } from '../../fse/fse-subcomposant/fse-preview.component';
import { FseService } from '../../fse/fse.service';
import { RSPService } from '../../rsp/rsp.service';
import { FseDetailsModalComponent } from '../fse-details/fse-details-modal.component';
import { LotPreviewComponent } from '../lots-subcomponents/lot-preview.component';
import { LotsService } from '../lots.service';
import { RspDetailsModalComponent } from '../rsp-details/rsp-details-modal.component';
import { OctService } from '../../../../core/services/oct.service';
import { debounceTime } from 'rxjs/operators';
import { GridDataRaw } from '../../../../data/gridDataRaw';
import { StorageService } from '../../../../shared/storage-service';
import { BsModalService } from 'ngx-bootstrap/modal';
import {RouteHistoryService} from "../../../../shared/route-history.service";

@Component({
	selector: 'careweb-app-fiche-lot',
	templateUrl: './fiche-lot.component.html',
	styleUrls: ['./fiche-lot.component.scss']
})
export class FicheLotComponent implements OnInit {
	@Input() currentLot: LotsDataRaw;
	currentPs: PsDataRaw;
	arlDataRaw: ArlDataRaw;
	fseDataRaw: FseDataRaw;
	rspDataRaw: RspDataRaw;
	arlsList = [];
	fseIdsList = [];
	// FSE grid helper
	gridHelperFse: AgGridHelper;
	gridDataRawFse: GridDataRaw;
	// Rsp payment grid helper
	gridHelperRspPayment: AgGridHelper;
	gridDataRawRspPayment: GridDataRaw;
	// Rsp reject grid helper
	gridHelperRspReject: AgGridHelper;
	gridDataRawRspReject: GridDataRaw;
	// Nombre des RSP payement
	rspPaymentNb;
	// Nombre des RSP rejet
	rspRejetNb;
	// Indique si le lot contient des RSP
	containRsp = false;
	containRspPayement;
	containRspRejet;
	currentJustify = 'left';
	organismeLabel;
	// date pipe
	datePipe: DatePipe;
	currentOct;
	excelStyles: any[] = [
		{
			id: 'stringType',
			dataType: 'String',
		}
	];

	active: 1;
	constructor(
		// Router
		private activatedRoute: ActivatedRoute,
		// Services
		private lotsSvc: LotsService,
		private psService: PsService,
		private arlService: ARLService,
		private fseSvc: FseService,
		private rspSvc: RSPService,
		// Scroll to top
		private scrollTopService: ScrollTopService,
		private authSvc: AuthService,
		private octSvc: OctService,
		private modalService: BsModalService,
		private storageService: StorageService,
		private location: Location,
		private router:Router,
		private routeHistory: RouteHistoryService
	) {
		// Scroll to top
		this.scrollTopService.setScrollTop();

		// FSE grid
		const sessionPageSizeFse = this.storageService.get('fseLotsPaginationPageSize', true);
		this.gridDataRawFse = new GridDataRaw();
		this.gridDataRawFse.size = !isNullOrUndefined(sessionPageSizeFse) ? sessionPageSizeFse : 10;
		this.gridHelperFse = new AgGridHelper('fseLots', this.gridDataRawFse.size, 10);

		// RSP payment grid
		const sessionSizeRspPayment = this.storageService.get('rspPaymentPaginationPageSize', true);
		this.gridDataRawRspPayment = new GridDataRaw();
		this.gridDataRawRspPayment.size = !isNullOrUndefined(sessionSizeRspPayment) ? sessionSizeRspPayment : 10;
		this.gridHelperRspPayment = new AgGridHelper('rspPayment', this.gridDataRawRspPayment.size, 10);

		//RSP reject grid
		const sessionSizeRspReject = this.storageService.get('rspRejectPaginationPageSize', true);
		this.gridDataRawRspReject = new GridDataRaw();
		this.gridDataRawRspReject.size = !isNullOrUndefined(sessionSizeRspReject) ? sessionSizeRspReject : 10;
		this.gridHelperRspReject = new AgGridHelper('rspReject', this.gridDataRawRspReject.size, 10);

		// DataRaw
		this.currentLot = new LotsDataRaw();
		this.currentPs = new PsDataRaw();
		this.arlDataRaw = new ArlDataRaw();
		this.fseDataRaw = new FseDataRaw();
		this.rspDataRaw = new RspDataRaw();
	}

	ngOnInit() {
		// Initiate date pipe format
		this.datePipe = new DatePipe('fr-FR');
		const ctx = this;
		const idLot = parseInt(this.activatedRoute.snapshot.params.id);
		if(!idLot){
			this.router.navigate(['/404'])
		}
		// Initiate FSE table
		this.initiateFseGrid();
		// Initiate RSP payment table
		this.initiateRspPaymentGrid();
		// Initiate RSP Rejet table
		this.initRspRejectGrid();
		// Get current oct
		this.octSvc.currentOctSubject.subscribe((value) => {
			this.currentOct = value;
		});
		// Get current lot
		ctx.lotsSvc
			.getLotById({
				idLot: idLot
			})
			.subscribe((data) => {
				this.currentLot = data;
				if (this.currentLot.nbRsp > 0) {
					ctx.containRsp = true;
				}
				// Get PS Data
				if (this.currentLot.numeroPs) {
					ctx.psService
						.getPsByNumAdeli({
							numAdeli: this.currentLot.numeroPs
						})
						.subscribe((data) => {
							this.currentPs = data[0];
						});
				}
				// Get Arls
				if (this.currentLot.nbArl > 0) {
					ctx.arlService
						.getArlByLotId({
							idLot: idLot
						})
						.subscribe((data) => {
							this.arlsList = data;
							this.arlDataRaw = data;
						});
				}
			},
			err=>{
				if(err.status===400 || err.message==='No message available'){
					this.router.navigate(['/404'])
				}
			});
	}

/* 	onTabChange(event: any) {
		const activeTabId = event.nextId;
		sessionStorage.setItem('fiche-lot-tab', activeTabId);
	} */

	onGridReadyRspPayment(params) {
		this.gridHelperRspPayment.gridApi = params.api;
		this.gridHelperRspPayment.gridColumnApi = params.columnApi;
		this.gridHelperRspPayment.gridApi.sizeColumnsToFit();
		this.gridHelperRspPayment.gridColumnApi.autoSizeAllColumns(true);
		this.restoreGridRspPayment();
		// this.rspDataRaw.facturesId = ctx.fseIdsList;
		// this.rspDataRaw.facturesId = ctx.fseIdsList.filter((item, index) => ctx.fseIdsList.indexOf(item) === index);
		// console.log("rspDataRaw", ctx.rspDataRaw)
		this.setGridDataSource('rspPayment');
	}

	onGridReadyRspReject(params) {
		this.gridHelperRspReject.gridApi = params.api;
		this.gridHelperRspReject.gridColumnApi = params.columnApi;
		this.gridHelperRspReject.gridApi.sizeColumnsToFit();
		this.restoreGridRspReject();
		// this.rspDataRaw.facturesId = ctx.fseIdsList;
		this.setGridDataSource('rspReject');
	}

	setGridDataSource(gridStatePrefix: string): void {
		const ctx = this;
		// Get lot id from active route
		const idLot = this.activatedRoute.snapshot.params.id;
		if (sessionStorage.getItem('lot-filter-nbRspRO')) {
			ctx.fseDataRaw.nbRspRO = Number(sessionStorage.getItem('lot-filter-nbRspRO'));
		}
		if (sessionStorage.getItem('lot-filter-nbRspRC')) {
			ctx.fseDataRaw.nbRspRC = Number(sessionStorage.getItem('lot-filter-nbRspRC'));
		}

		switch (gridStatePrefix) {
			case 'fse':
				const FseLotsDataSource: IServerSideDatasource = {
					getRows: function (paramsRows: IServerSideGetRowsParams) {
						const sorts = gridConvertSort(ctx.gridHelperFse.gridSortModel(), []);
						ctx.fseSvc
							.getFseFilteredListFiltre(
								{
									page: ctx.gridHelperFse.gridApi.paginationGetCurrentPage() || 0,
									size: ctx.gridHelperFse.gridApi.paginationGetPageSize(),
									idOct: ctx.currentOct ? ctx.currentOct.idOct : '',
									sorts
								},
								ctx.fseDataRaw
							)
							.subscribe(
								(data) => {
									const rowCount = data.totalElements;
									ctx.gridHelperFse.manageNoRowsOverlay(rowCount);
									paramsRows.success({ "rowData": data.content, "rowCount": rowCount });
									data.content.forEach((facture) => {
										ctx.fseIdsList.push(facture.idFacture);
									});
								},
								() => paramsRows.fail()
							);
					}
				};
				this.gridHelperFse.gridApi.setServerSideDatasource(FseLotsDataSource);
				this.gridHelperFse.gridApi.sizeColumnsToFit();
				break;
			case 'rspPayment':
				const rspPaymentDataSource: IServerSideDatasource = {
					getRows: function (paramsRows: IServerSideGetRowsParams) {
						ctx.rspSvc
							.getListRspByIdLot({
								page: ctx.gridHelperRspPayment.gridApi.paginationGetCurrentPage() || 0,
								size: ctx.gridHelperRspPayment.gridApi.paginationGetPageSize(),
								idLot: idLot,
								typeRsp: 0
							})
							.subscribe(
								(dataRsp) => {
									const rowCount = dataRsp.totalElements;
									ctx.gridHelperRspPayment.manageNoRowsOverlay(rowCount);
									paramsRows.success({ "rowData": dataRsp.content, "rowCount": rowCount });
									dataRsp.content.length > 0 ? (ctx.rspPaymentNb = rowCount) : (ctx.rspPaymentNb = 0);
									dataRsp.content.length > 0
										? (ctx.containRspPayement = true)
										: (ctx.containRspPayement = false);
								},
								() => paramsRows.fail()
							);
					}
				};
				this.gridHelperRspPayment.gridApi.setServerSideDatasource(rspPaymentDataSource);
				this.gridHelperRspPayment.gridApi.sizeColumnsToFit();
				break;
			case 'rspReject':
				const rspRejectDataSource: IServerSideDatasource = {
					getRows: function (paramsRows: IServerSideGetRowsParams) {
						ctx.rspSvc
							.getListRspByIdLot({
								page: ctx.gridHelperRspReject.gridApi.paginationGetCurrentPage() || 0,
								size: ctx.gridHelperRspReject.gridApi.paginationGetPageSize(),
								idLot: idLot,
								typeRsp: 1
							})
							.subscribe(
								(dataRsp) => {
									const rowCount = dataRsp.totalElements;
									ctx.gridHelperRspReject.manageNoRowsOverlay(rowCount);
									paramsRows.success({ "rowData": dataRsp.content, "rowCount": rowCount });
									dataRsp.content.length > 0 ? (ctx.rspRejetNb = rowCount) : (ctx.rspRejetNb = 0);
									dataRsp.content.length > 0 ? (ctx.containRspRejet = true) : (ctx.containRspRejet = false);
								},
								() => paramsRows.fail()
							);
					}
				};
				this.gridHelperRspReject.gridApi.setServerSideDatasource(rspRejectDataSource);
				this.gridHelperRspReject.gridApi.sizeColumnsToFit();
				break;
			default:
				break;
		}
	}

	initiateRspPaymentGrid() {
		this.gridHelperRspPayment.gridOptions = {
			columnDefs: this.getColumnDefsRspPayment(),
			defaultColDef: {
				resizable: true
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 10,
			maxBlocksInCache: 0,
			infiniteInitialRowCount: 1,
			paginationPageSize: this.gridDataRawRspPayment.size,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			colResizeDefault: 'shift',
			localeText: this.gridHelperRspPayment.getLocaleText(),
			onGridReady: (params) => this.onGridReadyRspPayment(params),
			onColumnMoved: (params) => this.onColumnRspChanged(params)
		};
	}

	initRspRejectGrid() {
		this.gridHelperRspReject.gridOptions = {
			columnDefs: this.getColumnDefsRspPayment(),
			defaultColDef: {
				resizable: true
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 10,
			maxBlocksInCache: 0,
			paginationPageSize: this.gridDataRawRspReject.size,
			infiniteInitialRowCount: 1,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			colResizeDefault: 'shift',
			localeText: this.gridHelperRspReject.getLocaleText(),
			onGridReady: (params) => this.onGridReadyRspReject(params),
			onColumnMoved: (params) => this.onColumnRspRejectChanged(params),
		};
	}

	initiateFseGrid() {
		// FSE grid initialisation
		this.gridHelperFse.gridOptions = {
			columnDefs: this.getColumnDefs(),
			defaultColDef: {
				resizable: true
			},
			context: { parentComponent: this },
			components: {
				customSelectFilterComponent: this.getSelectFloatingFilterComponent(),
				customSelectFilterComponentRC: this.getSelectFloatingFilterComponentRC(),
				customInputFilterComponent: this.getInputFloatingFilterComponent()
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 10,
			maxBlocksInCache: 0,
			infiniteInitialRowCount: 1,
			paginationPageSize: this.gridDataRawFse.size,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			localeText: this.gridHelperFse.getLocaleText(),
			onGridReady: (params) => this.onGridReady(params),
			onColumnMoved: (params) => this.onColumnChanged(params)
		};
	}

	// @TODO : Optimize with one function
	restoreGrid() {
		this.gridHelperFse.restoreGridStatePrefix();
		this.gridDataRawFse.size = this.gridHelperFse.paginationPageSize;
	}

	restoreGridRspPayment() {
		this.gridHelperRspPayment.restoreGridStatePrefix();
		this.gridDataRawRspPayment.size = this.gridHelperRspPayment.paginationPageSize;
	}

	restoreGridRspReject() {
		this.gridHelperRspReject.restoreGridStatePrefix();
		this.gridDataRawRspReject.size = this.gridHelperRspReject.paginationPageSize;
	}

	exportRsp(status) {
		const ctx = this;
		// show numeroAdeli
		status === 'payment'
			? ctx.gridHelperRspPayment.gridOptions.columnApi.setColumnVisible('numeroAdeli', true)
			: ctx.gridHelperRspReject.gridOptions.columnApi.setColumnVisible('numeroAdeli', true);

		const params = {
			skipHeader: false,
			columnGroups: false,
			skipFooters: false,
			skipGroups: true,
			skipPinnedTop: false,
			skipPinnedBottom: false,
			allColumns: true,
			allRows: true,
			onlySelected: false,
			fileName: (status === 'payment' ? 'Liste-paiements-' : 'Liste-rejets-') + this.currentLot.numLot,
			sheetName: (status === 'payment' ? 'Liste-paiements-' : 'Liste-rejets-') + this.currentLot.numLot,
			columnKeys: [
				'numeroAdeli',
				'numLot',
				'numeroFacture',
				'montantFactureRo',
				'montantRo',
				'montantFactureRc',
				'montantRc',
				'dateReception',
				'libelleOrganisme',
				'statutRsp'
			],
			processCellCallback: function (params) {
				if (
					params.column.colDef.field === 'montantFactureRo' ||
					params.column.colDef.field === 'montantRo' ||
					params.column.colDef.field === 'montantFactureRc' ||
					params.column.colDef.field === 'montantRc'
				) {
					params.value = ctx.formatCurrency({
						data: true,
						value: params.value
					});
				} else if (params.column.colDef.field === 'dateReception') {
					params.value = ctx.formatDate({
						value: params.value
					});
				} else if (params.column.colDef.field === 'statutRsp') {
					params.value = ctx.formatRspStatus({
						data: { statutRsp: params.value }
					});
				}

				return params.value;
			}
		};

		status === 'payment'
			? this.gridHelperRspPayment.gridApi.exportDataAsExcel(params)
			: this.gridHelperRspReject.gridApi.exportDataAsExcel(params);

		// hide numeroAdeli
		status === 'payment'
			? ctx.gridHelperRspPayment.gridOptions.columnApi.setColumnVisible('numeroAdeli', false)
			: ctx.gridHelperRspReject.gridOptions.columnApi.setColumnVisible('numeroAdeli', false);
	}

	private getColumnDefsRspPayment() {
		const ctx = this;
		return [
			this.getNumPs(),
			{
				headerName: 'N° du lot',
				field: 'numLot',
				lockVisible: true,
				cellClass: 'stringType',
				minWidth: 150,
				cellStyle: this.gridHelperRspPayment.centerContent(),
				onCellClicked: (params) => this.onCellClicked(params),
				suppressMenu: true
			},
			{
				headerName: 'N° de FSE',
				field: 'numeroFacture',
				lockVisible: true,
				minWidth: 150,
				cellClass: 'stringType',				
				cellStyle: this.gridHelperRspPayment.centerContent(),
				suppressMenu: true,
				onCellClicked: (params) => this.onCellClicked(params)
			},
			{
				headerName: 'Montant AMO',
				field: 'montantFactureRo',
				valueFormatter: this.formatCurrency,
				lockVisible: true,
				minWidth: 150,			
				cellStyle: this.gridHelperRspPayment.centerContent(),
				suppressMenu: true,
				onCellClicked: (params) => this.onCellClicked(params)
			},
			{
				headerName: 'Montant payé AMO',
				field: 'montantRo',
				lockVisible: true,
				minWidth: 150,
				valueFormatter: this.formatCurrency,
				cellStyle: this.gridHelperRspPayment.centerContent(),
				suppressMenu: true,
				onCellClicked: (params) => this.onCellClicked(params)
			},
			{
				headerName: 'Montant AMC',
				field: 'montantFactureRc',
				minWidth: 150,
				valueFormatter: this.formatCurrency,
				lockVisible: true,				
				cellStyle: this.gridHelperRspPayment.centerContent(),
				suppressMenu: true,
				onCellClicked: (params) => this.onCellClicked(params)
			},
			{
				headerName: 'Montant payé AMC',
				field: 'montantRc',
				lockVisible: true,
				minWidth: 150,
				valueFormatter: this.formatCurrency,				
				cellStyle: this.gridHelperRspPayment.centerContent(),
				suppressMenu: true,
				onCellClicked: (params) => this.onCellClicked(params)
			},
			{
				headerName: 'Date de réception',
				field: 'dateReception',
				lockVisible: true,
				minWidth: 150,
				valueFormatter: this.formatDate,				
				cellStyle: this.gridHelperRspPayment.centerContent(),
				suppressMenu: true,
				onCellClicked: (params) => this.onCellClicked(params)
			},
			{
				headerName: 'Organisme émetteur',
				field: 'libelleOrganisme',
				lockVisible: true,
				minWidth: 150,		
				cellStyle: this.gridHelperRspPayment.centerContent(),
				suppressMenu: true,
				onCellClicked: (params) => this.onCellClicked(params)
			},
			{
				headerName: 'Statut du RSP',
				field: 'statutRsp',
				lockVisible: true,
				minWidth: 150,
				valueFormatter: this.formatRspStatus,				
				cellStyle: this.gridHelperRspPayment.centerContent(),
				suppressMenu: true,
				onCellClicked: (params) => this.onCellClicked(params)
			},
			{
				headerName: '',
				field: 'options',
				lockVisible: true,
				minWidth: 95,
				cellStyle: this.gridHelperFse.centerContent(),
				suppressMenu: true,
				cellRenderer: LotPreviewComponent,
				cellRendererParams: {
					iconClass: 'fa fa-eye',
					redirect: 'rspModal',
					lot: ctx
				}
			}
		];
	}

	getNumPs() {
		if (this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN')) {
			return {
				headerName: 'N° de PS',
				field: 'numeroAdeli',
				minWidth: 100,
				maxWidth: 150,
				lockVisible: false,
				cellClass: 'stringType',
				hide: true,
				cellStyle: this.gridHelperRspPayment.centerContent(),
				suppressMenu: true,
				onCellClicked: (params) => this.onCellClicked(params)
			};
		} else {
			// we can also use gridOptions.columnApi.setColumnVisible('columnName',false)
			return {
				hide: true
			};
		}
	}

	private getColumnDefs() {
		const ctx = this;
		return [
			{
				headerName: 'N° FSE',
				field: 'numFacture',
				lockVisible: true,
				cellStyle: this.gridHelperFse.centerContent(),
				sort: 'desc',
				sortable: true,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true, debounceMs: 3000 },
				suppressMenu: true,
				headerClass: 'ag-header-merge',
				onCellClicked: (params) => this.onFseCellClicked(params),
				filterParams: {
					debounceMs: 3000
				}
			},
			{
				headerName: 'Assurance maladie obligatoire',
				headerClass: 'ag-header-group-parent',
				children: [
					{
						headerName: 'Libellé',
						field: 'libelleRo',
						cellRenderer: this.formatRegime,
						cellStyle: this.gridHelperFse.centerContent(),
						filter: 'agTextColumnFilter',
						floatingFilter: true,
						floatingFilterComponent: 'customInputFilterComponent',
						floatingFilterComponentParams: { suppressFilterButton: true },
						suppressMenu: true,
						onCellClicked: (params) => this.onFseCellClicked(params),
						filterParams: {
							debounceMs: 3000
						}
					},
					{
						headerName: 'RSP',
						field: 'nbRspRO',
						cellRenderer: this.formatRspFacture,
						cellStyle: this.gridHelperFse.centerContent(),
						sort: 'asc',
						sortable: true,
						suppressMenu: true,
						filter: 'agTextColumnFilter',
						floatingFilter: true,
						floatingFilterComponent: 'customSelectFilterComponent',
						floatingFilterComponentParams: {
							suppressFilterButton: true,
							fseFilter: ctx.fseDataRaw,
							debounceMs: 3000
						},
						onCellClicked: (params) => this.onFseCellClicked(params),

						filterParams: {
							debounceMs: 3000
						}
					}
				]
			},
			{
				headerName: 'Assurance maladie complémentaire',
				headerClass: 'ag-header-group-parent',
				children: [
					{
						headerName: 'Libellé',
						field: 'libelleRc',
						cellRenderer: this.formatRegime,
						cellStyle: this.gridHelperFse.centerContent(),
						filter: 'agTextColumnFilter',
						floatingFilter: true,
						floatingFilterComponent: 'customInputFilterComponent',
						floatingFilterComponentParams: { suppressFilterButton: true },
						suppressMenu: true,
						onCellClicked: (params) => this.onFseCellClicked(params),
						filterParams: {
							debounceMs: 3000
						}
					},
					{
						headerName: 'RSP',
						field: 'nbRspRC',
						cellRenderer: this.formatRspFacture,
						sortable: true,
						cellStyle: this.gridHelperFse.centerContent(),
						sort: 'asc',
						filter: 'agTextColumnFilter',
						floatingFilter: true,
						floatingFilterComponentParams: { suppressFilterButton: true },
						floatingFilterComponent: 'customSelectFilterComponentRC',
						suppressMenu: true,
						onCellClicked: (params) => this.onFseCellClicked(params),
						filterParams: {
							debounceMs: 3000
						}
					}
				]
			},
			this.getBoLink(),
			{
				headerName: '',
				field: 'options',
				lockVisible: true,
				cellStyle: this.gridHelperFse.centerContent(),
				cellRenderer: LotPreviewComponent,
				headerClass: 'ag-header-merge',
				cellRendererParams: {
					redirect: 'fseModal',
					iconClass: 'fa fa-eye',
					lot: ctx
				},
				suppressMenu: true
			}
		];
	}

	getBoLink() {
		if (this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN')) {
			return {
				headerName: '',
				field: 'lienBO.idFacture',
				headerClass: 'ag-header-merge',
				cellRenderer: FsePreviewComponent,
				cellRendererParams: {
					redirect: 'boLink'
				},
				lockVisible: true,
				cellStyle: this.gridHelperFse.centerContent(),
				suppressMenu: true
			};
		} else {
			// we can also use gridOptions.columnApi.setColumnVisible('columnName',false)
			return {
				hide: true
			};
		}
	}

	onGridReady(params) {
		const ctx = this;
		this.gridHelperFse.gridApi = params.api;
		this.gridHelperFse.gridColumnApi = params.columnApi;
		// Get lot id from active route
		const idLot = this.activatedRoute.snapshot.params.id;
		// Initialize sorting
		this.initSorting();
		ctx.fseDataRaw.idLot = idLot;
		// To remove
		(ctx.fseDataRaw.idOct = ctx.currentOct ? ctx.currentOct.idOct : ''), this.restoreGrid();
		this.gridHelperFse.gridApi.sizeColumnsToFit();
		this.setGridDataSource('fse');
		this.gridHelperFse.gridApi.hidePopupMenu();
	}

	// A refactorer
	getSelectFloatingFilterComponent() {
		const ctx = this;
		function SelectFloatingFilter() { }

		SelectFloatingFilter.prototype.init = function (params) {
			this.eGui = document.createElement('div');
			this.eGui.innerHTML =
				'<select id="select-nbRspRO" style="width:100%; height: 30px;"/><option value="null"></option><option>Oui</option><option>Non</option></select>';
			this.currentValue = null;
			document.body.appendChild(this.eGui);
			this.eFilterInput = document.getElementById('select-nbRspRO');
			this.eFilterInput.value = sessionStorage.getItem('lot-filter-nbRspRO') == '1' ? 'Oui' : sessionStorage.getItem('lot-filter-nbRspRO') == '0' ? 'Non' : '';
			const that = this;
			function onSelectChanged() {
				if (that.eFilterInput.value === '') {
					// Remove the filter
					params.parentFilterInstance(function (instance) {
						instance.onFloatingFilterChanged(null, null);
					});
					return;
				}

				that.currentValue = that.eFilterInput.value;
				const filterField = params.filterParams.colDef.field;
				if (that.currentValue !== 'null') {
					ctx.fseDataRaw[filterField] = that.eFilterInput.value === 'Oui' ? 1 : 0;
					sessionStorage.setItem('lot-filter-nbRspRO', ctx.fseDataRaw[filterField]);
				} else {
					delete ctx.fseDataRaw[filterField];
					sessionStorage.removeItem('lot-filter-nbRspRO');
				}
				params.parentFilterInstance(function (instance) {
					instance.onFloatingFilterChanged('equals', that.currentValue);
				});
			}
			this.eFilterInput.addEventListener('change', onSelectChanged);
		};

		SelectFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
			if (!parentModel) {
				this.eFilterInput.value = '';
				this.currentValue = null;
			} else {
				this.eFilterInput.value = parentModel.filter + '';
				this.currentValue = parentModel.filter;
			}
		};

		SelectFloatingFilter.prototype.getGui = function () {
			return this.eGui;
		};

		return SelectFloatingFilter;
	}

	// A refactorer
	getSelectFloatingFilterComponentRC() {
		const ctx = this;
		function SelectFloatingFilter() { }

		SelectFloatingFilter.prototype.init = function (params) {
			this.eGui = document.createElement('div');
			this.eGui.innerHTML =
				'<select id="select-nbRspRC" style="width:100%; height: 30px;"/><option value="null"></option><option>Oui</option><option>Non</option></select>';
			this.currentValue = null;
			document.body.appendChild(this.eGui);
			this.eFilterInput = document.getElementById('select-nbRspRC');
			this.eFilterInput.value = sessionStorage.getItem('lot-filter-nbRspRC') == '1' ? 'Oui' : sessionStorage.getItem('lot-filter-nbRspRC') == '0' ? 'Non' : '';
			const that = this;
			function onSelectChanged() {
				if (that.eFilterInput.value === '') {
					// Remove the filter
					params.parentFilterInstance(function (instance) {
						instance.onFloatingFilterChanged(null, null);
					});
					return;
				}

				that.currentValue = that.eFilterInput.value;
				const filterField = params.filterParams.colDef.field;
				if (that.currentValue !== 'null') {
					ctx.fseDataRaw[filterField] = that.eFilterInput.value === 'Oui' ? 1 : 0;
					sessionStorage.setItem('lot-filter-nbRspRC', ctx.fseDataRaw[filterField]);
				} else {
					delete ctx.fseDataRaw[filterField];
					sessionStorage.removeItem('lot-filter-nbRspRC');
				}
				params.parentFilterInstance(function (instance) {
					instance.onFloatingFilterChanged('equals', that.currentValue);
				});
			}
			this.eFilterInput.addEventListener('change', onSelectChanged);
		};

		SelectFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
			if (!parentModel) {
				this.eFilterInput.value = '';
				this.currentValue = null;
			} else {
				this.eFilterInput.value = parentModel.filter + '';
				this.currentValue = parentModel.filter;
			}
		};

		SelectFloatingFilter.prototype.getGui = function () {
			return this.eGui;
		};

		return SelectFloatingFilter;
	}

	getInputFloatingFilterComponent() {
		const ctx = this;
		function InputFloatingFilter() { }

		InputFloatingFilter.prototype.init = function (params) {
			this.eGui = document.createElement('div');
			this.eGui.setAttribute('class', 'ag-input-wrapper');

			if (params.filterParams.colDef.field === 'libelleRo' || params.filterParams.colDef.field === 'libelleRc') {
				this.eGui.innerHTML =
					"<input type='text' class='customFilterInput' style='width:100%; box-shadow: none;' onKeyPress='if(this.value.length==255) return false;'/>";
			} else {
				this.eGui.innerHTML =
					"<input type='number' class='customFilterInput' style='width:100%; box-shadow: none;' onKeyPress='return this.value.length!==9 && event.charCode >= 48 && event.charCode <= 57' pattern='^[0-9]' min='0' step='1' max='999999999' oninput='this.value=this.value.replace(/[^d]/,'');'/>";
			}
			this.currentValue = null;
			this.eFilterInput = this.eGui.querySelector('input');
			const that = this;
			function onInputChanged() {
				const filterField = params.filterParams.colDef.field;
				if (that.eFilterInput.value === '') {
					// Remove the filter
					delete ctx.fseDataRaw[filterField];
					params.parentFilterInstance(function (instance) {
						instance.onFloatingFilterChanged(null, null);
					});
					return;
				}

				that.currentValue = that.eFilterInput.value;
				if (that.eFilterInput.value.length >= 3) {
					if (that.currentValue !== '') {
						ctx.fseDataRaw[filterField] = that.eFilterInput.value;
					}
					params.parentFilterInstance(function (instance) {
						debounceTime(5000);
						instance.onFloatingFilterChanged('equals', that.currentValue);
					});
				}
			}
			this.eFilterInput.addEventListener('input', onInputChanged);
		};

		InputFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
			if (!parentModel) {
				this.eFilterInput.value = '';
				this.currentValue = null;
			} else {
				this.eFilterInput.value = parentModel.filter + '';
				this.currentValue = parentModel.filter;
			}
		};

		InputFloatingFilter.prototype.getGui = function () {
			return this.eGui;
		};

		return InputFloatingFilter;
	}

	goToLot() {

		if (isNullOrUndefined(this.routeHistory.getPreviousUrl())) {
			this.router.navigate(['/lots']);
		}else {
			this.location.back();
		}
	}

	private initSorting() {
		const sort = [
			{
				colId: 'numFacture',
				sort: 'asc',
				sortIndex: 0
			}
		];
		this.gridHelperFse.applyColumnState(sort);
	}

	formatRegime = (params): any => {
		if (params.data) {
			if (params.colDef.field === 'libelleRo') {
				return params.data.partRoAPrendreEnCompte
					? this.getOrganismeLabel(params.data, 'RO')
					: 'Part RO non prise en compte';
			}

			if (params.colDef.field === 'libelleRc') {
				return params.data.partRcAPrendreEnCompte
					? this.getOrganismeLabel(params.data, 'RC')
					: 'Part RC non prise en compte';
			}
		}
	};

	getOrganismeLabel = (factureData, organismeType): string => {
		if (organismeType === 'RO') {
			if (factureData && !isNullOrUndefined(factureData.parts) && factureData.parts.length > 0) {
				factureData.parts.forEach((part) => {
					if (part.partType === 'RO' && part.numFacture === factureData.numFacture) {
						this.organismeLabel = part.libelleCourtOrganisme;
						return true;
					}
				});
			}
			return this.organismeLabel;
		} else if (organismeType === 'RC') {
			if (organismeType === 'RC') {
				if (factureData && !isNullOrUndefined(factureData.parts) && factureData.parts.length > 0) {
					factureData.parts.forEach((part) => {
						if (part.partType === 'RC' && part.numFacture === factureData.numFacture) {
							this.organismeLabel = part.libelleCourtOrganisme;
							return true;
						}
					});
				}
				return this.organismeLabel;
			}
		}
	};

	formatRspFacture = (params): string => {
		if (params.data) {
			if (params.colDef.field === 'nbRspRO') {
				return params.data.nbRspRO > 0 ? '<i class= "fa fa-check green"></i>' : '<i class= "fa fa-close red"></i>';
			}
			if (params.colDef.field === 'nbRspRC') {
				return params.data.nbRspRC > 0 ? '<i class= "fa fa-check green"></i>' : '<i class= "fa fa-close red"></i>';
			}
		}
	};

	formatDate = (params): string => {
		return this.datePipe.transform(params.value, 'short');
	};

	formatRspStatus = (params): string => {
		if (params.data) {
			let rspStatusLabel = '';
			switch (params.data.statutRsp) {
				case 0:
					rspStatusLabel = 'RSP reçu';
					break;
				case 1:
					rspStatusLabel = 'RSP intégré';
					break;
				case 2:
					rspStatusLabel = 'RSP rapproché';
					break;
				case 3:
					rspStatusLabel = 'RSP non rapproché';
					break;
				case 4:
					rspStatusLabel = 'RSP rejeté';
					break;
				case 5:
					rspStatusLabel = 'RSP extrait';
					break;
				case 6:
					rspStatusLabel = 'RSP posté';
					break;
				case 7:
					rspStatusLabel = 'RSP en attente de réception';
					break;
				case 8:
					rspStatusLabel = 'Rsp receptionné';
					break;
			}
			return rspStatusLabel;
		}
	};

	formatCurrency = (params): string => {
		if (params.data) {
			if (params.value) {
				return params.value / 100 + ' €';
			} else {
				return '0.00 €';
			}
		}
	};

	onCellClicked(params) {
		const initialState = {
			rsp: params.data,
			lot: this.currentLot,
			ps: this.currentPs
		};
		this.modalService.show(RspDetailsModalComponent, { initialState, class: 'modal-xl' });
	}

	onFseCellClicked(params) {
		const initialState = {
			facture: params.data,
			lot: this.currentLot,
			ps: this.currentPs
		};
		this.modalService.show(FseDetailsModalComponent, { initialState, class: 'modal-xl' });
	}

	onPaginationSizeChange(paginationSize: number, gridStatePrefix: string): void {
		switch (gridStatePrefix) {
			case 'fse':
				this.gridHelperFse.changePaginationSize(paginationSize);
				this.setGridDataSource(gridStatePrefix);
				this.gridHelperFse.saveGridStatePrefix();
				break;
			case 'rspPayment':
				this.gridHelperRspPayment.changePaginationSize(paginationSize);
				this.setGridDataSource(gridStatePrefix);
				this.gridHelperRspPayment.saveGridStatePrefix();
				break;
			case 'rspReject':
				this.gridHelperRspReject.changePaginationSize(paginationSize);
				this.setGridDataSource(gridStatePrefix);
				this.gridHelperRspReject.saveGridStatePrefix();
				break;
			default:
				break;
		}
	}

	ngOnDestroy() {
		this.gridHelperFse.saveGridStatePrefix();
		this.gridHelperRspPayment.saveGridStatePrefix();
		this.gridHelperRspReject.saveGridStatePrefix();
	}

	onColumnChanged(params: any): void {
		this.gridHelperFse.saveGridColumnState();
	}

	onColumnRspChanged(params: any): void {
		this.gridHelperRspPayment.saveGridColumnState();
	}

	onColumnRspRejectChanged(params: any): void {
		this.gridHelperRspReject.saveGridColumnState();
	}
}
