import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'rapp-auto-external-preview',
	template: `
		<a  *ngIf = "this.params.data.fseDto" target="_blank" ngClass="careweb-link"
            container="body"
			[routerLink]="[routerUrl]"
		>{{params.value}}</a>	
		<span *ngIf = "!this.params.data.fseDto">{{params.value}}</span>
	`,
	styles: [ '.careweb-link {color: #21b8cf!important;}' ]
})
export class RappAutoExternalPreviewComponent implements ICellRendererAngularComp {
	params: any;
	routerUrl: any;

	constructor() {}

	agInit(params: any): void {	
		this.params = params;
		if(this.params.data.fseDto){
			if (this.params.colDef.field == 'numLot') {
			this.routerUrl = '/lot-details/' + this.params.data.fseDto.idLot;
			} else if (this.params.colDef.field == 'numeroFacture') {
				this.routerUrl = '/fse-details/' + this.params.data.fseDto.idFacture;
			}
		}	

		//console.log("this.routerUrl", this.routerUrl);
	}

	refresh(): boolean {
		return false;
	}

	/* 	preview(): void {
		
		var url = "";

		if (this.params.colDef.field == "numLot")
			url = this.router.serializeUrl(this.router.createUrlTree([ '/lot-details', this.params.data.fseDto.idLot ], {}));
		else if (this.params.colDef.field == "numeroFacture")
			url = this.router.serializeUrl(this.router.createUrlTree([ '/fse-details', this.params.data.fseDto.idFacture ]));

		console.log(this.router)
		window.open(url, '_blank');
	} */
}
