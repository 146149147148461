import { StatusConnectionOxlin } from "./statusConnectionOxlin";

export class RappComptePsDataRaw {
	idRappComptePs: number;
    idOxlinUser: number;
    idPs: number;
    addressEmail: string;
    secret: string;
    actif: boolean;
    creationDate: Date;
    debutDate: Date;
    finDate: Date;
    derniereConnexion: Date;
    dernierRefresh: Date;
    derniereSync: Date;
    statusConnectionOxlin: StatusConnectionOxlin;
    connectionNumber  : number ;
}
