import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'careweb-hotline-preview',
	template: `
		<div *ngIf="params.colDef.field && params.colDef.field === 'commentaire'" container="body" [tooltip]="tooltip" (click)="previewHotline()"
			 containerClass="tooltip-grid">
			{{params.data.commentaire}}
		</div>
        <i *ngIf="params.colDef.field && params.colDef.field !== 'commentaire'"
			[ngClass]="iconClass"
            container="body"
			(click)="previewHotline()"
			[tooltip]="tooltip"
            containerClass="tooltip-grid"
			(contextmenu)="onRightClick($event)"
		></i>
    `
})
export class HotlinePreviewComponent implements ICellRendererAngularComp, AfterViewInit {
	iconClass: string;
	tooltip: string;
	params: any;

	constructor(private router: Router) {}

	agInit(params: any): void {
		this.params = params;
	}

	ngAfterViewInit() {
		const cellParams = this.params;
		if (cellParams.data) {
			if (cellParams.colDef.field && cellParams.colDef.field === 'optionsRemove') {
				this.iconClass = 'fa fa-trash';
				switch (cellParams.type) {
					case 'motif':
						this.tooltip = 'Supprimer le motif';
						break;
					case 'type':
						this.tooltip = 'Supprimer le type';
						break;
					case 'resolution':
						this.tooltip = 'Supprimer la résolution';
						break;
					case 'groupe':
						this.tooltip = 'Supprimer';
						break;
					case 'hotlineIntervention':
						this.tooltip = "Supprimer l'intervention";
						break;
					default:
						break;
				}
				// this.tooltip = 'Supprimer l\'intervention';
			} else if (cellParams.colDef.field && cellParams.colDef.field === 'optionsHotline') {
				this.iconClass = 'fa fa-eye';
				this.tooltip = "Consulter l'intervention";
			} else if (cellParams.colDef.field && (cellParams.colDef.field === 'optionsModifier'|| cellParams.colDef.field === 'optionsModifierType' || cellParams.colDef.field === 'optionsModifierMotifs'|| cellParams.colDef.field === 'optionsModifierResolution')) {
				this.iconClass = 'fa fa-edit';
				switch (cellParams.type) {
					case 'motif':
						this.tooltip = 'Modifier le motif';
						break;
					case 'type':
						this.tooltip = 'Modifier le type';
						break;
					case 'resolution':
						this.tooltip = 'Modifier la résolution';
						break;
					case 'groupe':
						this.tooltip = 'Modifier';
						break;
					default:
						break;
				}
			} else if (cellParams.colDef.field && cellParams.colDef.field === 'options') {
				this.iconClass = 'fa fa-eye';
				this.tooltip = '';
			} else if (cellParams.colDef.field && cellParams.colDef.field === 'optionsEdit') {
				this.iconClass = 'fa fa-edit';
				this.tooltip = "Modifier l'intervention";
			}else if (cellParams.colDef.field && cellParams.colDef.field === 'commentaire') {
				this.tooltip =cellParams.data.commentaire;
			}

			// Append cursor pointer
			this.iconClass = this.iconClass + ' cursor-pointer';
		}
	}

	refresh(): boolean {
		return false;
	}

	previewHotline(): void {
		if (this.params.redirect === 'hotlineEdit') {
			this.router.navigate([ '/hotline-edit', this.params.data.idIntervention ]);
		}
	}

	onRightClick(event: MouseEvent): void {		
		if (this.params.redirect === 'hotlineEdit') {
			event.preventDefault();
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/careweb-client/hotline-edit',  this.params.data.idIntervention])
			);
			window.open(url, '_blank');
		}
	}
}
