import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONF } from '../../../core/constants';
import { RetourEditableDataRaw } from '../../../data/retourEditableDataRaw';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class RetoursEditablesService {
	private readonly baseUrlRph = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/flux/retours-editables/rph';
	private readonly baseUrlArt = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/flux/retours-editables/art';

	constructor(private httpClient: HttpClient) {}

	getRetoursEditableList(criteria: any, retourEditableDataRaw: RetourEditableDataRaw): Observable<any> {
		if (criteria.typeRetour === 'rph') {
			return this.httpClient.post<any>(
				this.baseUrlRph +
					'/search?page=' +
					criteria.page +
					'&size=' +
					criteria.size +
					'&idOct=' +
					criteria.idOct +
					this.getSortParameters(criteria),
				retourEditableDataRaw
			);
		} else {
			return this.httpClient.post<any>(
				this.baseUrlArt +
					'/search?page=' +
					criteria.page +
					'&size=' +
					criteria.size +
					'&idOct=' +
					criteria.idOct +
					this.getSortParameters(criteria),
				retourEditableDataRaw
			);
		}
	}

	getRetoursEditablePsList(criteria: any, retourEditableDataRaw: RetourEditableDataRaw): Observable<any> {
		if (criteria.typeRetour === 'rph') {
			return this.httpClient.post<any>(
				this.baseUrlRph +
					'/search-ps?page=' +
					criteria.page +
					'&size=' +
					criteria.size +
					this.getSortParameters(criteria),
				retourEditableDataRaw
			);
		} else {
			return this.httpClient.post<any>(
				this.baseUrlArt +
					'/search-ps?page=' +
					criteria.page +
					'&size=' +
					criteria.size +
					this.getSortParameters(criteria),
				retourEditableDataRaw
			);
		}
	}

	getFileContent(criteria: any): Observable<any> {
		const baseUrl = criteria.typeRetour === 'rph' ? this.baseUrlRph : this.baseUrlArt;
		const idCriteria = criteria.typeRetour === 'art' ? criteria.idArt : criteria.idRph;
		return this.httpClient
			.post<HttpResponse<Blob>>(
				baseUrl + '/view/' + idCriteria,
				{},
				{
					headers: new HttpHeaders({
						'Content-Type': 'text/plain',
						AcceptEncoding: 'gzip, deflate',
						Accept: '*/*',
						CacheControl: 'no-cache'
					}),
					observe: 'response' as 'body',
					responseType: 'text' as 'json'
				}
			)
			.pipe(
				map((response: HttpResponse<any>): any => {
					return response.body;
				})
			);
	}

	downloadRetourEditable(criteria: any): Observable<any> {
		const baseUrl = criteria.typeRetour == 'rph' ? this.baseUrlRph : this.baseUrlArt;
		const idCriteria = criteria.typeRetour == 'art' ? criteria.idArt : criteria.idRph;
		return this.httpClient
			.post<HttpResponse<Blob>>(
				baseUrl + '/download/' + idCriteria,
				{},
				{
					headers: new HttpHeaders({
						'Content-Type': 'application/json',
						AcceptEncoding: 'gzip, deflate',
						Accept: '*/*',
						CacheControl: 'no-cache'
					}),
					observe: 'response' as 'body',
					responseType: 'blob' as 'json'
				}
			)
			.pipe(
				map((response: HttpResponse<any>): any => {
					return this.getFileInfosFromResponse(response);
				})
			);
	}

	getSortParameters(criteria) {
		if (criteria.sorts.length == 1) {
			const sort = criteria.sorts[0].property;
			return '&sort=' + sort + '&direction=' + criteria.sorts[0].direction;
		} else {
			// sort par défaut
			return criteria.typeRetour === 'art' ? '&sort=dateEnvoi&direction=ASC' : '&sort=dateEnvoie&direction=ASC';
		}
	}

	// @TODO: transform into serivce since it's used many times

	getFileInfosFromResponse(response: HttpResponse<any>) {
		return {
			file: new Blob([ response.body ], { type: 'application/pdf' }),
			fileName: this.getFileNameFromResponse(response)
		};
	}

	getFileNameFromResponse(response: HttpResponse<object>): string {
		const filenameRegex = /filename="([^"]+)/;
		const fileName = response.headers?.get('Content-Disposition')?.match(filenameRegex)[1];
		return fileName;
	}
}
