import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Router } from '@angular/router';

@Component({
	selector: 'careweb-arl-lot-preview',
	template: `
        <a
			ngClass="link-box"
            container="body"
			(click)="previewLot()"
		>{{params.value}}</a>
    `
})
export class FseLotPreviewComponent implements ICellRendererAngularComp {
	params: any;

	constructor(private router: Router) {}

	agInit(params: any): void {
		this.params = params;
	}

	refresh(): boolean {
		return false;
	}

	previewLot(): void {
		if (this.params.redirect === 'lotDetails') {
			this.router.navigate([ '/lot-details', this.params.data.lot.idLot ]);
		}
	}
}
