import {HttpClient, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONF } from '../../../core/constants';
import { RspDataRaw } from '../../../data/rspDataRaw';
import {CustomSortDataRow} from "../../../data/customSortDatRaw";
import {map} from "rxjs/operators";

@Injectable({
	providedIn: 'root'
})
export class RSPService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/flux/rsp';

	public fromRsp;

	constructor(private httpClient: HttpClient) {}
	getSort(sorts)	{
		if (sorts.length === 1) {
			const sort = sorts[0].property;
			return '?sort=' + sort + '&direction=' + sorts[0].direction;
		} else {
			// sort par défaut
			return '?sort=dateReception&direction=DESC';
		}
	}
	getRSPList(criteria: any): Observable<any> {
		return this.httpClient.get<any>(
			this.baseUrl +
				'/list?page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				this.getSortParameters(criteria.sorts)
		);
	}

	getRspById(idRsp: string): Observable<RspDataRaw> {
		return this.httpClient.get<RspDataRaw>(this.baseUrl + '/' + idRsp);
	}

	getRspFilteredList(criteria: any, rspDataRaw: RspDataRaw): Observable<any> {
		return this.httpClient.post<any>(
			this.baseUrl +
				'/search?page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				this.getSortParameters(criteria.sorts),
			rspDataRaw
		);
	}

	getListRspByIdLot(criteria: any) {
		return this.httpClient.get<any>(
			this.baseUrl +
				'/search-rsp-list?page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				'&idLot=' +
				criteria.idLot +
				'&typeRsp=' +
				criteria.typeRsp
		);
	}

	getRspByNumeroFacture(numeroFacture: string): Observable<any> {
		return this.httpClient.get<any>(this.baseUrl + '/facture?numeroFacture=' + numeroFacture);
	}

	getSortParameters(sorts) {
		if (sorts && sorts.length === 1) {
			const sort = sorts[0].property;
			return '&sort=' + sort + '&direction=' + sorts[0].direction;
		} else {
			// sort par défaut
			return '&sort=dateReception&direction=DESC';
		}
	}

	getRspStatistiques(rspCriteria: any): Observable<any> {
		return this.httpClient.post<any>(this.baseUrl + '/statistiques', rspCriteria);
	}

	searchRspsByIdPsWithDelegues(criteria, rspDataRaw: RspDataRaw): Observable<any> {
		return this.httpClient.post(
			this.baseUrl +
				'/search/ps/' +
				criteria.idPs +
				'/delegues/rsps?' +
				'page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				this.getSortParameters(criteria.sorts),
			rspDataRaw
		);
	}

	searchRspsByIdPsWithDeleguesNumFacture(criteria, rspDataRaw: RspDataRaw): Observable<any> {
		return this.httpClient.post(
			this.baseUrl +
			'/search/ps/' +
			criteria.idPs +
			'/numeroFacture/' +
			criteria.numeroFacture +
			'/delegues/rsps?' +
			'page=' +
			criteria.page +
			'&size=' +
			criteria.size +
			this.getSortParameters(criteria.sorts),
			rspDataRaw
		);
	}
	exportRsps(criteria: any, rspsDataRaw: RspDataRaw, fields: string[], fromRsp: boolean): Observable<any>{
		const sorts: CustomSortDataRow[] = criteria.sorts;
		return this.httpClient.post<HttpResponse<Blob>>(
			this.baseUrl + '/export'
			+this.getSort(sorts)+'&fromRsp='+fromRsp,
			{
				filters: rspsDataRaw,
				fields: fields
			},
			{
				observe: 'response' as 'body',
				responseType: 'blob' as 'json'
			}
		).pipe(
			map((response: HttpResponse<Blob>): any => {
				return this.getFileInfosFromResponse(response,fromRsp);
			})
		);
	}
	exportRspsByEmail(criteria: any, rspsDataRaw: RspDataRaw, fields: string[], fromRsp: boolean): Observable<any>{
		const sorts: CustomSortDataRow[] = criteria.sorts;
		return this.httpClient.post<HttpResponse<Blob>>(
			this.baseUrl + '/export-by-email'+this.getSort(sorts)+'&fromRsp='+fromRsp,
			{
				filters: rspsDataRaw,
				fields: fields
			},
			{
				observe: 'response' as 'body',
				responseType: 'blob' as 'json'
			}
		);
	}
	getFileInfosFromResponse(response: HttpResponse<Blob>,fromRsp: boolean) {
		let fileName = fromRsp ? 'Liste_RSP.xlsx' : 'Liste_Rejets.xlsx';
		return {
			file: new Blob([ response.body ], { type: 'application/vnd.ms-excel' }),
			fileName: fileName
		};
	}
}
