<div class="x-large-careweb-container">
    <form>
        <!-- .form-content : A utiliser pour les pages de formulaire - Ne pas utiliser pour les fenetres modales -->
        <section class="form-content">

            <!-- Titre H2 -->
            <div class="row form-title">
                <h2 class="col-md-6">
                    Détails du lot n°{{currentLot.numLot}}
                </h2>

                <div class="col-md-6 text-title">
                    <button class="btn btn-default btn-lg" type="button" (click)="goToLot()">Retour</button>

                </div>
            </div>

            <!-- NAV TABS -->
                <ul ngbNav #nav="ngbNav" [(activeId)]="active" [destroyOnHide]="false" class="nav-tabs">
                <li [ngbNavItem]="1"id="Informations" title="Informations">
                    		<button ngbNavLink>Informations</button>
		                        <ng-template ngbNavContent>
                        <!-- ONGLET 1 -->
                        <div id="onglet1" class="tab-pane fade show active">

                            <!-- Form-block - Permet de separer les blocs de formulaires entre eux -->
                            <div class="form-block">
                                <!-- Titre -->
                                <div class="row">
                                    <div class="col">
                                        <h3 class="title-border">
                                            Détails du lot
                                            <!-- <a href="#" class="btn-popin"><i class="fa fa-info-circle"></i></a> -->
                                        </h3>
                                    </div>
                                </div>
                                <fieldset class="container-fluid">
                                    <div class="row">
                                        <div class="col-sm-2 col-form-label">Numéro de PS</div>
                                        <div class="col-sm-4 form-group"><input type="text" class="form-control"
                                                readonly value="{{currentLot.numeroPs}}" /></div>
                                        <div class="col-sm-2 col-form-label">Nom du titulaire</div>
                                        <div *ngIf="currentPs.nom" class="col-sm-4 form-group"><input type="text"
                                                class="form-control" readonly value="{{currentPs.nom}}" /></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-2 col-form-label">Etablissement</div>
                                        <div class="col-sm-4 form-group"><input type="text" class="form-control"
                                                readonly value="{{currentLot.raisonSociale}}" /></div>
                                        <div class="col-sm-2 col-form-label">Sécurisé</div>
                                        <div class="col-sm-4 form-group"><input type="text" class="form-control"
                                                readonly value="{{currentLot.securise | yesNoPipe: 'typeLot'}}" /></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-2 col-form-label">ARP reçu</div>
                                        <div class="col-sm-4 form-group"><input type="text" class="form-control"
                                                readonly value="{{currentLot.nbArp  | yesNoPipe: 'typeArp'}}" /></div>
                                        <div class="col-sm-2 col-form-label">ARL reçu</div>
                                        <div class="col-sm-4 form-group"><input type="text" class="form-control"
                                                readonly value="{{currentLot.signeArl | yesNoPipe: 'typeArl'}}" /></div>
                                    </div>
                                    <div class="row">
                                        <!--<div class="col-sm-2 col-form-label">Nombre d'ARL SCOR</div>
                                        <div class="col-sm-4 form-group"><input type="text" class="form-control"
                                                readonly value="{{currentLot.nbreArlScanOrdo}}" /></div>-->
                                        <div class="col-sm-2 col-form-label">Tiers payant</div>
                                        <div class="col-sm-4 form-group"><input type="text" class="form-control"
                                                readonly
                                                value="{{currentLot.tiersPayant | yesNoPipe: 'tiersPayant'}}" /></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-2 col-form-label">Montant total</div>
                                        <div class="col-sm-4 form-group"><input type="text" class="form-control"
                                                readonly value="{{currentLot.montantTotal | customCurrency}}" /></div>
                                        <div class="col-sm-2 col-form-label">Organisme AMO</div>
                                        <div class="col-sm-4 form-group"><input type="text" class="form-control"
                                                readonly value="{{currentLot.libelleOrganisme}}" /></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-2 col-form-label">Montant AMO</div>
                                        <div class="col-sm-4 form-group"><input type="text" class="form-control"
                                                readonly value="{{currentLot.montantRo | customCurrency}}" /></div>
                                        <div class="col-sm-2 col-form-label">Montant AMC</div>
                                        <div class="col-sm-4 form-group"><input type="text" class="form-control"
                                                readonly value="{{currentLot.montantRc | customCurrency}}" /></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-2 col-form-label">Lot reçu le</div>
                                        <div class="col-sm-4 form-group"><input type="text" class="form-control"
                                                readonly
                                                value="{{currentLot.dateReceptionPartition | date:'shortDate'}}" />
                                        </div>
                                        <div class="col-sm-2 col-form-label">Lot créé par le PS le</div>
                                        <div class="col-sm-4 form-group"><input type="text" class="form-control"
                                                readonly value="{{currentLot.dateCreationLot | customDate}}" /></div>
                                    </div>
                                </fieldset>
                            </div>
                            <!-- /form-block -->

                            <!-- Form-block - Permet de separer les blocs de formulaires entre eux -->
                            <div class="form-block">
                                <!-- Titre -->
                                <div class="row">
                                    <div class="col">
                                        <h3 class="title-border">
                                            <div class="row">
                                                <div class="col-auto me-auto">ARL</div>
                                                <div class="col-auto">{{currentLot.nbArl}} ARL</div>
                                            </div>
                                            <!-- <a href="#" class="btn-popin"><i class="fa fa-info-circle"></i></a> -->
                                        </h3>
                                    </div>
                                </div>
                                <!-- Tableau -->
                                <div class="row">
                                    <div class="col">
                                        <!-- Utiliser .table-responsive pour des tableaux optimises en mode mobile -->
                                        <div *ngIf="arlDataRaw" class="table-responsive">
                                            <table class="ui-grid-like">
                                                <thead>
                                                    <tr>
                                                        <th>Nb FSE</th>
                                                        <th>Montant</th>
                                                        <th>Date de réception</th>
                                                        <th>Statut</th>
                                                        <th>Message</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let arl of arlsList">
                                                        <td>{{arl.nbFactures}}</td>
                                                        <td>{{arl.montantFacture | customCurrency}}</td>
                                                        <td>{{arl.dateReception | date:'shortDate'}}</td>
                                                        <td>{{arl.signeArl | yesNoPipe:'typeArlLabel'}}</td>
                                                        <td>{{arl.libelleRejet}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- /form-block -->

                            <!-- Form-block - Permet de separer les blocs de formulaires entre eux -->
                            <div class="form-block">
                                <!-- Titre -->
                                <div class="row">
                                    <div class="col">
                                        <h3 class="title-border">
                                            <div class="row">
                                                <div class="col-auto me-auto">Liste des FSE du lot</div>
                                                <div class="col-auto">{{currentLot.nbFactures}} facture(s)</div>
                                            </div>
                                            <!-- <a href="#" class="btn-popin"><i class="fa fa-info-circle"></i></a> -->
                                        </h3>
                                    </div>
                                </div>

                                <!--SIDEBAR + TOOLBAR-->
                                <div class="row grid-top-btn" style="margin-top:0px">
                                    <div class="col-md-12 conf-col">
                                        <div class="careweb-toolbar">
                                            <careweb-pagination-size id="dv_fse_paging" [pageSizes]="[10, 20, 30, 50, 100]"
                                                [(ngModel)]="gridDataRawFse.size"
                                                (paginationSizeChange)="onPaginationSizeChange($event, 'fse')"
                                                [ngModelOptions]="{standalone: true}">
                                            </careweb-pagination-size>
                                        </div>
                                    </div>
                                </div>
                                <div class="row highlight-row">
                                    <div class="col-md-12 relative">
                                        <ag-grid-angular style="width: 100%;" class="ag-theme-material"
                                            [gridOptions]="gridHelperFse.gridOptions">
                                        </ag-grid-angular>
                                        <careweb-pagination [gridApi]="gridHelperFse.gridApi"></careweb-pagination>
                                    </div>
                                </div>
                            </div>
                            <!-- /form-block -->

                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2"id="Paiements" title="Paiements">
                    		<button ngbNavLink>Paiements</button>
		                    <ng-template ngbNavContent>
                        <!-- Form-block - Permet de separer les blocs de formulaires entre eux -->
                        <div class="form-block">
                            <!-- Titre -->
                            <div class="row">
                                <div class="col">
                                    <h3 class="title-border">
                                        <div class="row" *ngIf="containRsp && rspPaymentNb > 0">
                                            <div class="col-auto me-auto">Liste des RSP</div>
                                            <div class="col-auto">{{rspPaymentNb}} paiement(s)</div>
                                        </div>
                                        <div class="row" *ngIf="!containRsp || rspPaymentNb == 0">
                                                <div class="col-auto me-auto">Aucun RSP disponible</div>
                                            </div>
                                    </h3>
                                </div>
                            </div>

                            <!--SIDEBAR + TOOLBAR-->
                            <div class="row grid-top-btn" style="margin-top:0px" *ngIf="containRsp" [hidden]="!containRspPayement">
                                <div class="col-md-12 conf-col">
                                    <div class="careweb-toolbar">
                                        <span class="btn btn-action small" tooltip="Exporter les résultats (.xlsx)"
                                            placement="top" container="body" name="btn_export_xlsx"
                                            (click)="exportRsp('payment')">
                                            <i class="fa fa-file-excel-o"></i>
                                        </span>
                                        <careweb-pagination-size id="dv_arl_paging" [pageSizes]="[10, 20, 30, 50, 100]"
                                            [(ngModel)]="gridDataRawRspPayment.size"
                                            (paginationSizeChange)="onPaginationSizeChange($event, 'rspPayment')"
                                            [ngModelOptions]="{standalone: true}"
                                            >
                                        </careweb-pagination-size>
                                    </div>
                                </div>
                            </div>

                            <!-- Tableau -->
                            <div class="row highlight-row" *ngIf="containRsp">
                                <div class="col-md-12 relative" [hidden]="!containRspPayement">
                                    <ag-grid-angular style="width: 100%;" class="ag-theme-balham"
                                        [gridOptions]="gridHelperRspPayment.gridOptions"
                                        [excelStyles]="excelStyles">
                                    </ag-grid-angular>
                                    <careweb-pagination [gridApi]="gridHelperRspPayment.gridApi"></careweb-pagination>
                                </div>
                            </div>

                        </div>
                        <div class="row emptyTable" *ngIf="!containRsp || rspPaymentNb == 0">

                        </div>
                        <!-- /form-block -->
                    </ng-template>
                </li>
                <li [ngbNavItem]="3"id="Rejets" title="Rejets">
                    		<button ngbNavLink>Rejets</button>
		<ng-template ngbNavContent>
                        <!-- Form-block - Permet de separer les blocs de formulaires entre eux -->
                        <div class="form-block">
                                <!-- Titre -->
                                <div class="row">
                                    <div class="col">
                                        <h3 class="title-border">
                                            <div class="row" *ngIf="containRsp && rspRejetNb > 0">
                                                <div class="col-auto me-auto">Liste des RSP</div>
                                                <div class="col-auto">{{rspRejetNb}} rejet(s)</div>
                                            </div>
                                            <div class="row" *ngIf="!containRsp || rspRejetNb == 0">
                                                    <div class="col-auto me-auto">Aucun RSP disponible</div>
                                                </div>
                                        </h3>
                                    </div>
                                </div>
    
                                <!--SIDEBAR + TOOLBAR-->
                                <div class="row grid-top-btn" style="margin-top:0px" *ngIf="containRsp" [hidden]="!containRspRejet">
                                    <div class="col-md-12 conf-col">
                                        <div class="careweb-toolbar">
                                            <span class="btn btn-action small" tooltip="Exporter les résultats (.xlsx)"
                                                placement="top" container="body" name="btn_export_xlsx"
                                                (click)="exportRsp('rejet')">
                                                <i class="fa fa-file-excel-o"></i>
                                            </span>
                                            <careweb-pagination-size id="dv_rsp_rejet_paging" [pageSizes]="[10, 20, 30, 50, 100]"
                                                [(ngModel)]="gridDataRawRspReject.size"
                                                (paginationSizeChange)="onPaginationSizeChange($event, 'rspReject')"
                                                [ngModelOptions]="{standalone: true}"
                                                >
                                            </careweb-pagination-size>
                                        </div>
                                    </div>
                                </div>
    
                                <!-- Tableau -->
                                <div class="row highlight-row" *ngIf="containRsp">
                                    <div class="col-md-12 relative" [hidden]="!containRspRejet">
                                        <ag-grid-angular style="width: 100%;" class="ag-theme-balham"
                                            [gridOptions]="gridHelperRspReject.gridOptions">
                                        </ag-grid-angular>
                                        <careweb-pagination [gridApi]="gridHelperRspReject.gridApi"></careweb-pagination>
                                    </div>
                                </div>
                            </div>
                            <div class="row emptyTable" *ngIf="!containRsp || rspRejetNb == 0">
    
                            </div>
                        <!-- /onglet 3 -->
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div> 
        </section>
    </form>
</div>