import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';

@Component({
  selector: 'careweb-detail-email-renderer',
  template: `
    <div class="p-relative">
        <div class="m-3">
            <div><label>Content-Type :</label></div>
            <div><textarea class="form-control-input form-content-detail-grid" readonly rows="2" value="{{ firstRecord.contentType }}"></textarea></div>
        </div>
        <div class="m-3" *ngIf="firstRecord && firstRecord.balMessageContent && firstRecord.balMessageContent[0] && firstRecord.balMessageContent[0].contentDisposition !== null">
        <div><label>Content-Disposition :</label></div>
        <div><textarea class="form-control-input form-content-detail-grid" readonly rows="1" value="{{ firstRecord?.balMessageContent[0]?.contentDisposition }}"></textarea></div>
    </div>
        <div class="m-3">
            <div><label>Message ID :</label></div>
            <div><textarea class="form-control-input form-content-detail-grid" readonly rows="1" value="{{ firstRecord.messageId }}"></textarea></div>
        </div>
        <div class="m-3" *ngIf="firstRecord && firstRecord.balMessageContent && firstRecord.balMessageContent[0] && firstRecord.balMessageContent[0].fileName !== null">
          <div><label>Nom du fichier :</label></div>
          <div><textarea class="form-control-input form-content-detail-grid" readonly rows="1" value="{{ firstRecord?.balMessageContent[0]?.fileName }}"></textarea></div>
        </div>
        <div class="m-3"  *ngIf="firstRecord && firstRecord.balMessageContent && firstRecord.balMessageContent[0] && firstRecord.balMessageContent[0].fileContent !== null">
          <div><label>Pièce jointe :</label></div>
          <div><textarea class="form-control-input form-content-detail-grid" readonly rows="5" value="{{ firstRecord?.balMessageContent[0]?.fileContent }}"></textarea></div>
        </div>
    </div>
  `,
})
export class DetailEmailCellRendererComponent implements ICellRendererAngularComp {
  firstRecord: any;

  agInit(params: any): void {
    this.firstRecord = params.data;
  }

  refresh(params: any): boolean {
    return false;
  }
}