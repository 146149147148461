export class RspDataRaw {
	Facture: String;
	numLot: String;
	idOrganisme: String;
	idPs: String;
	codeRsp: String;
	dateReception: Date;
	designationStatusRsp: String;
	libelleOrganisme: String;
	libelleRejet: String;
	montantFactureRc: Number;
	montantFactureRo: Number;
	montantRc: Number;
	montantRo: Number;
	nomPS: string;
	numFacture: String;
	statutRsp: String;
	telephoneOrganisme: String;
	typeOrganisme: String;
	numeroAdeli: String;
	numeroPs: String;
	numeroFacture: String;
	idFacture: String;
	facturesId: Array<Number>;
	fichierRetour: any;
	pieceJointeRetour: any;
	enveloppeRetour: Object;
	dateEnvoie: Date;
	typeRsp: number;
	raisonSocialEtablissement: String;
	dateDerniereTransmission: string;
	dateReceptionPartitionFrom: string;
	dateReceptionPartitionTo: string;
	dateReceptionPartitionExacte: string;
	rapprochement: Boolean;
	dateReceptionFrom: String;
	dateReceptionTo: String;
	dateReceptionExacte: String;
	idOct: Number;
	libelleVirement1: String;
	libelleVirement2: String;
	rapproBancaire: boolean;
	avecVirement: Boolean;
	listNumeroAdeli: String[];
	signe: Number;
	fromDashboard: boolean;
}
