import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import { AgGridHelper } from '../../components/ag-grid/ag-grid-helper';
import { ColDef } from 'ag-grid-enterprise';
import { GridDataRaw } from '../../data/gridDataRaw';
import { IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { isNullOrUndefined } from 'util';
import { gridConvertSort } from '../../components/ag-grid/ag-grid.utils';
import { StorageService } from '../../shared/storage-service';
import { LogsRapproDataRaw } from "../../data/logsRapproDataRaw";
import { FilterItem } from "../../data/filters/filter-item";
import * as _ from "lodash-es";
import { LogsRapproService } from './logs-rappro.service';
import { DatePipe } from '@angular/common';
import { isEmpty, uniqBy } from 'lodash-es';
import { DateFromToFilterComponent } from '../../components/date-from-to/date-from-to-filter.component';
import { AccordionGroupComponent } from '../../components/accordion/accordion-group.component';
import { ConvertDateFormat } from '../../core/utilities/convert-date-format.service';
import { RapprochementComptePsService } from '../rapprochement/rapprochement.service';
import { RapprochementDataRaw } from '../../data/rapprochementDataRaw';
import { ColumnTooltipComponent } from '../../components/column-tooltip/column-tooltip-component';
import { RappDetailVrtCellRendererComponent } from '../rapprochement/rapp-detail-vrt-cell-enderer.component';
import {ExportModalComponent, ExportMode} from "../export-modal/export-modal.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import { saveAs } from 'file-saver';
import {TransformService} from "../../core/services/transform.service";
import {ExportState, State} from "../../shared/model/storage.model";

@Component({
  selector: 'app-logs-rapprochement',
  templateUrl: './logs-rapprochement.component.html',
  styleUrls: ['./logs-rapprochement.component.scss']
})
export class LogsRapprochementComponent implements OnInit {

  logsRapproGridHelper: AgGridHelper;
  gridHelper: AgGridHelper;
  sorts: any;
  gridDataRaw: GridDataRaw;
  gridDataRawRappro: GridDataRaw;
  showFilter = false;
  initHidden = true;
  filters = new LogsRapproDataRaw();
  logsRapproDataRaw: LogsRapproDataRaw;
  filterList: FilterItem[];
  protected filterListTmp: FilterItem[];
  datePipe: DatePipe;
  public rowCount: number = -1;
  rapprochementDataRaw: RapprochementDataRaw;
  maxDate: Date;

  @ViewChild('dateDemarrage')
  dateDemarrage: DateFromToFilterComponent;

  @ViewChild('dateAccordion')
  dateAccordion: AccordionGroupComponent;

  isDateRangeValid = true;
  showRappAutoDetail: boolean;
  private openedModal: BsModalRef;
  export: boolean = false;
  exportState: ExportState;
  exportableColumns = [
    'dateDemarrage',
    'duree',
    'idPs',
    'vrtAnalyses',
    'vrtRapproches',
    'vrtEtudier',
    'status',
    'modeExecution'
  ];
  currentExecutionId: number;
  static MIN_WIDTH = 1710;

    constructor( private storageService: StorageService, 
                private logsRapproService : LogsRapproService, 
                private convertFormatDate: ConvertDateFormat,
                private rapprochementComptePsService: RapprochementComptePsService,
                 private modalService: BsModalService,
                 private transformService: TransformService) {
        const sessionPageSize = this.storageService.get('logsRappPaginationPageSize', true);
        this.gridDataRaw = new GridDataRaw();
        this.gridDataRaw.size = !isNullOrUndefined(sessionPageSize) ? sessionPageSize : 10;
        this.logsRapproGridHelper = new AgGridHelper('logsRappro', this.gridDataRaw.size, 10);
        this.logsRapproDataRaw = new LogsRapproDataRaw();

        //Get grid size from session if already stored
        const sessionPageSizeRappro = this.storageService.get('logsRappAutoPaginationPageSize', true);
        this.gridDataRawRappro = new GridDataRaw();
        this.gridDataRawRappro.size = !isNullOrUndefined(sessionPageSizeRappro) ? sessionPageSizeRappro : 10;
        this.gridHelper = new AgGridHelper('logsRappAuto', this.gridDataRawRappro.size, 10);
        this.maxDate = new Date();
    }

    ngOnInit() {
      this.datePipe = new DatePipe('fr-FR');
      this.parseFilters();
      this.filters.dateDemarrageExacte = new Date();
      this.filters.idPs = !isNaN(parseInt(localStorage.getItem("numeroPs"))) ? parseInt(localStorage.getItem("numeroPs")) : null;
      sessionStorage['logsRapproFiltre'] = JSON.stringify(this.filters);
      this.initLogsGrid();
    }

    parseFilters() {
      const parsedFilter = JSON.parse(sessionStorage.getItem('logsRapproFiltre'));
      if (!isEmpty(parsedFilter)) {
          this.filters = Object.assign({}, parsedFilter);
          // Convert String dates to Dates format
          this.filters.dateDemarrageExacte ? (this.filters.dateDemarrageExacte = new Date(parsedFilter.dateDemarrageExacte)) : null;
          this.filters.dateDemarrageFrom ? (this.filters.dateDemarrageFrom = new Date(parsedFilter.dateDemarrageFrom)) : null;
          this.filters.dateDemarrageTo ? (this.filters.dateDemarrageTo = new Date(parsedFilter.dateDemarrageTo)) : null;
      } else {
          this.filterList = [];
      }
    }

    initLogsGrid() {
      this.logsRapproGridHelper.gridOptions = {
          columnDefs: this.getColumnDefsLog(),
          defaultColDef: {
              resizable: true,
              suppressMenu: true
          },
          domLayout: 'autoHeight',
          rowHeight: 50,
          headerHeight: 50,
          rowModelType: 'serverSide',
          suppressServerSideInfiniteScroll: false,
          pagination: true,
          cacheBlockSize: 10,
          maxBlocksInCache: 0,
          paginateChildRows:true,
          infiniteInitialRowCount: 1,
          paginationPageSize: this.gridDataRaw.size,
          suppressScrollOnNewData: true,
          suppressPaginationPanel: true,
          suppressContextMenu: true,
          enableCellTextSelection: true,
          localeText: this.logsRapproGridHelper.getLocaleText(),
          onGridReady: (params) => this.onLogsGridReady(params),
          onGridSizeChanged: (params) => this.onGridSizeChanged(params),
      };
    }

    getColumnDefsLog(): ColDef[] {
      return [{
              headerName: 'Date démarrage',
              field: 'dateDemarrage',
              cellClass: ['text-center'],
              cellRenderer: (params) => this.formatDemarrage(params),
              minWidth: 150,
              maxWidth: 200,
              lockVisible: true,
              sortable: true
          },
          {
              headerName: 'Durée exécution',
              field: 'duree',
              cellClass: ['text-center'],
              minWidth: 100,
              maxWidth: 150,
              lockVisible: true,
              valueFormatter: (params) => this.formateDuree(params.value), 
              sortable: true
          },
          {
              headerName: 'N° de PS',
              field: 'idPs',
              cellClass: ['text-center'],
              minWidth: 100,
              maxWidth: 150,
              lockVisible: true,
              sortable: true
          },
          {
              headerName: 'VRT analysés',
              field: 'vrtAnalyses',
              cellClass: ['text-center'],
              minWidth: 80,
              lockVisible: true,
              sortable: true
          },
          {
              headerName: 'VRT rapprochés',
              field: 'vrtRapproches',
              cellClass: ['link-in-selected-row'],
              minWidth:80,
              onCellClicked: (params) => this.onDetailClicked(params),
              lockVisible: true,
              sortable: true
          },
          {
              headerName: 'VRT à étudier',
              field: 'vrtEtudier',
              cellClass: ['text-center'],
              minWidth: 80,
              lockVisible: true,
              sortable: true
          },
          {
              headerName: 'Status',
              field: 'status',
              cellClass: ['text-center'],
              minWidth: 100,
              maxWidth: 150,
              lockVisible: true,
              sortable: true
          },
          {
              headerName: 'Mode exécution',
              field: 'modeExecution',
              cellClass: ['text-center'],
              minWidth: 100,
              lockVisible: true,
              sortable: true
          }
      ];
  }
    onGridSizeChanged(params) {
        this.logsRapproGridHelper.gridApi.sizeColumnsToFit();
    }
  onDetailClicked(params) {
    if (!this.logsRapproGridHelper.isCellTextSelected()) {
        this.showRappAutoDetail = params.data.vrtRapproches == 0 ? false : true;
        if(this.showRappAutoDetail){
          this.currentExecutionId = params.data.executionID ? params.data.executionID : null;

          this.gridHelper.gridOptions = {
            columnDefs: this.getColumnRapproDefs(),
            defaultColDef: {
                resizable: true,
                suppressMenu: true
            },
            domLayout: 'autoHeight',
            rowHeight: 50,
            headerHeight: 50,
            rowModelType: 'serverSide',
            suppressServerSideInfiniteScroll: false,
            pagination: true,
            cacheBlockSize: 10,
            maxBlocksInCache: 0,
            infiniteInitialRowCount: 1,
            paginationPageSize: this.gridDataRawRappro.size,
            suppressScrollOnNewData: true,
            suppressPaginationPanel: true,
            suppressContextMenu: true,
            enableCellTextSelection: true,
            localeText: this.gridHelper.getLocaleText(),
            onGridReady: (params) => this.onGridReadyRappro(params),
            rowSelection: 'multiple',
            suppressRowClickSelection: true,
            masterDetail: true,
            detailCellRenderer: 'myDetailCellRenderer',
            components: {
                myDetailCellRenderer: RappDetailVrtCellRendererComponent,
                columnTooltipCellRenderer: ColumnTooltipComponent
            }
          };
        }

        }
    }

  onGridReadyRappro(params: any) {
    this.gridHelper.gridApi = params.api;
    this.gridHelper.gridColumnApi = params.columnApi;
   // this.gridHelper.restoreGridStatePrefix();
    this.gridDataRawRappro.size = this.gridHelper.paginationPageSize;
    this.launchSearchRappro();
  }

  launchSearchRappro() {
    const ctx = this;
    this.rapprochementDataRaw = new RapprochementDataRaw();
    this.rapprochementDataRaw.executionId = this.currentExecutionId;

    const dataSource: IServerSideDatasource = {
        getRows: function (paramsRows: IServerSideGetRowsParams) {
            ctx.sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
            const sorts = ctx.sorts ? ctx.sorts : null;
            const criteria = {
                page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
                size: ctx.gridHelper.gridApi.paginationGetPageSize(),
                sorts
            };
            ctx.rapprochementComptePsService.getRapprochementsFilteredList(criteria, ctx.rapprochementDataRaw).subscribe(
                (data) => {
                   ctx.gridHelper.manageNoRowsOverlay(data.totalElements);
                   paramsRows.success({
                      "rowData": data.content,
                      "rowCount": data.totalElements
                   });
                    const pageN = 1;
                    if (!isNullOrUndefined(pageN) && pageN !== ctx.gridHelper.gridApi.paginationGetCurrentPage() && pageN > 0) {
                       // ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
                    }
                    sessionStorage['logsRappCurrentPage'] = null;
                },
                () => paramsRows.fail()
            );
        }
    };
      this.gridHelper.gridApi.setServerSideDatasource(dataSource);
}

  getColumnRapproDefs() {
      const columnDefs: any[] = [
        {
          headerName: 'Virements bancaires rapprochés',
          headerClass: 'ag-header-group-parent',
          children: [
            {
              headerName: 'Libellé',
              minWidth: 320,
              field: 'label',
              cellRenderer: 'agGroupCellRenderer',
              cellRendererParams: {
                suppressDoubleClickExpand: true,
                innerRenderer: 'columnTooltipCellRenderer'
              },
              sortable: true
          },
          {
              headerName: 'Montant',
              minWidth: 60,
              field: 'montantTotalRsp',
              cellRenderer: (params) => this.formatAmmout(params),
              cellStyle: this.gridHelper.centerContent(),
              sortable: true
          },
          {
              headerName: 'Nombre RSP rapp',
              minWidth: 120,
              field: 'nbRsp',
              cellStyle: this.gridHelper.centerContent(),
              sortable: true
          },
          {
              headerName: 'Date de rapp',
              minWidth: 70,
              field: 'dateRapprochement',
              valueFormatter: this.formatDate,
              cellStyle: this.gridHelper.centerContent(),
              sortable: true
          },
          {
              headerName: 'Date opération',
              minWidth: 120,
              field: 'dateComptableTr', 
              valueFormatter: this.formatDate,
              cellStyle: this.gridHelper.centerContent(),
              sortable: true,
              sort: 'desc'
           }
          ]
        } 
      ];

      return columnDefs;
  }
  

  public closeModalRappAutoDetail() {
    this.showRappAutoDetail = false;
    this.gridHelper.saveGridStatePrefix();
    this.gridHelper.saveGridStatePrefix();
  }

  onPaginationSizeChange(paginationSize: number): void {
    this.logsRapproGridHelper.changePaginationSize(paginationSize);
    this.launchSearch();
    this.logsRapproGridHelper.saveGridStatePrefix();
  }

  resetGridState() {
    this.logsRapproGridHelper.resetGridState();
    this.gridDataRaw.size = this.logsRapproGridHelper.defaultPaginationPageSize;
  }

  onLogsGridReady(params: any) {
    this.logsRapproGridHelper.gridApi = params.api;
    this.logsRapproGridHelper.gridColumnApi = params.columnApi;
    let parsedFilter = JSON.parse(sessionStorage.getItem('logsRapproFiltre'));
    if (parsedFilter) {
        Object.keys(parsedFilter).forEach(
            (key) => (parsedFilter[key] === undefined || parsedFilter[key] === null ? delete parsedFilter[key] : {})
        );
    }
      if (window.innerWidth > LogsRapprochementComponent.MIN_WIDTH) {
          this.logsRapproGridHelper.gridApi.sizeColumnsToFit();
      }
    this.restoreGrid();
    if (!isEmpty(parsedFilter)) {
         this.launchSearch();
    }else this.clearGridFromData();

    if (window.innerWidth >= 1920) {
      this.logsRapproGridHelper.gridApi.sizeColumnsToFit();
    }
  }

  restoreGrid() {
    this.logsRapproGridHelper.restoreGridStatePrefix();
    this.gridDataRaw.size = this.logsRapproGridHelper.paginationPageSize;
  }

  onKeyDown(event) {
    this.launchSearch();
  }

  isFormValid(): boolean {
    let inputValue = true;
    if (this.filters.dateDemarrageFrom && this.filters.dateDemarrageTo) {
        if (this.isDateRangeValid) {
            this.dateDemarrage.hideTooltlipDebut();
            this.dateDemarrage.hideTooltlipFin();
        } else {
            this.dateAccordion.expansionPannel.open();
            this.dateDemarrage.showTooltipDebut();
            this.dateDemarrage.showTooltipFin();
            inputValue = false;
        }
    }
    return inputValue;
  }
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        let width = event.target.innerWidth;
        if (width >= 1163){
            this.logsRapproGridHelper.gridApi.sizeColumnsToFit();
        }
    }
    resetAllFilter(): void {
        this.initFilter();
        this.filterList = [];
        this.filters = new LogsRapproDataRaw();
        this.fillFilterObjectFromTagsOnly();
        this.resetFilter();
        this.filters.dateDemarrageExacte = new Date();
        sessionStorage['logsRapproFiltre'] = JSON.stringify(this.filters);
        this.isDateRangeValid = true;
        this.launchSearch();
    }

  resetFilter() {
    this.filterList = [];
    this.filterListTmp = [];
    sessionStorage['logsRapproFiltre'] = null;
  }
	//clear grid
	clearGridFromData() {
		//this.totalResultsHidden = false;
    this.rowCount = -1;
		this.logsRapproGridHelper.gridApi.setServerSideDatasource(null);
	}
  
  initFilter() {
    this.resetFilter();
    this.updateFilterList(this.filters);
  }

  updateFilterList(filters) {
    const ctx = this;
    ctx.filterList = [];
    Object.keys(filters).forEach((key) => {
        if (filters[key] !== null && filters[key] !== '') {
            ctx.filterList.push(ctx.translateFilterLabelValue(key, filters[key]));
        }
    });
    // Remove duplicated ids
    ctx.filterList = uniqBy(ctx.filterList, 'id');
    // filter null
    ctx.filterList = ctx.filterList.filter((item) => {
        return item.id != null;
    });
  }

  removeFilter(id) {
    delete this.filters[id];
    const currentFilterList = this.filterList;
    const index = _.findIndex(currentFilterList, (filt) => filt.id === id);
    currentFilterList.splice(index, 1);
    this.filterList = currentFilterList;
    sessionStorage['logsRapproFiltre'] = JSON.stringify(this.filters);
    if (Array.isArray(this.filterList) && this.filterList.length > 0) {
    this.fillFilterObjectFromTagsOnly();
    this.launchSearch();
    }else this.clearGridFromData();
  }

  fillFilterObjectFromTagsOnly() {
    this.filters = new LogsRapproDataRaw();
    this.filterList.forEach((filter) => {
        this.filters[filter.id] = filter.value2;
    });
  }

  onChoiceChange($event: any, type: string): void {
    if ($event.filterChoice === "EXACTE") {
        this.filters[type + 'From'] = null;
        this.filters[type + 'To'] = null;
    } else {
        this.filters[type + 'Exacte'] = null;
    }
  }


  translateFilterLabelValue(key, value) {
    let translatedKey = key;
    let formattedValue = value;
    let formattedKey = key;
    switch (key) {
        case 'idPs':
            translatedKey = 'N° de PS';
            break;
        case 'status':
            translatedKey = 'Statut';
            break;
        case 'dateDemarrageExacte':
            translatedKey = 'Date démarrage';
            formattedValue = this.formatDateForTags({ value: value });
            break;
        case 'dateDemarrageFrom':
            translatedKey = 'Date démarrage apres le';
            formattedValue = this.formatDateForTags({ value: value });
            break;
        case 'dateDemarrageTo':
            translatedKey = 'Date démarrage avant le';
            formattedValue = this.formatDateForTags({ value: value });
            break;
        default:
            break;
    }
    return new FilterItem().withId(formattedKey).withLabel(translatedKey).withValue(formattedValue).withValue2(value);
  }

  formatDateForTags = (params): string => {
    if (params.value !== null && params.value != 'Invalid Date') {
        return this.datePipe.transform(params.value, 'dd/MM/yyyy');
    }
  };
  
  hasChoiceFilter(){
    return this.filters.dateDemarrageExacte || 
          this.filters.dateDemarrageFrom ||
          this.filters.dateDemarrageTo ||
          this.filters.idPs ||
          this.filters.status;
  }

  launchSearch() {
    const ctx = this;
    if (this.isFormValid() && this.hasChoiceFilter()) {
    this.logsRapproDataRaw = new LogsRapproDataRaw();
    this.logsRapproDataRaw.dateDemarrageExacte = this.convertFormatDate.getFormatDateFr(this.filters.dateDemarrageExacte);
    this.logsRapproDataRaw.dateDemarrageFrom = this.convertFormatDate.getFormatDateFr(this.filters.dateDemarrageFrom);
    this.logsRapproDataRaw.dateDemarrageTo = this.convertFormatDate.getFormatDateFr(this.filters.dateDemarrageTo);
    this.logsRapproDataRaw.idPs = this.filters.idPs ? this.filters.idPs : null;
    this.logsRapproDataRaw.status = this.filters.status ? this.filters.status : null;
    this.updateFilterList(this.filters);

    const dataSource: IServerSideDatasource = {
        getRows: function (paramsRows: IServerSideGetRowsParams) {
            ctx.sorts = gridConvertSort(ctx.logsRapproGridHelper.gridSortModel(), []);
            const sortsLogsRappro = ctx.sorts ? ctx.sorts : null;
            const criteria = {
                page: ctx.logsRapproGridHelper.gridApi.paginationGetCurrentPage(),
                size: ctx.logsRapproGridHelper.gridApi.paginationGetPageSize(),
                sortsLogsRappro
            };
            ctx.logsRapproService.getLogsFilteredList(criteria, ctx.logsRapproDataRaw).subscribe(
                (data) => {
                    ctx.rowCount = data.totalElements > 0 ? data.totalElements : -1;
                    ctx.logsRapproGridHelper.manageNoRowsOverlay(data.totalElements);
                    paramsRows.success({
                        "rowData": data.content,
                        "rowCount": data.totalElements
                    });
                    const pageN = Number.parseInt(sessionStorage.getItem('logsRappCurrentPage'));
                    if (!isNullOrUndefined(pageN) && pageN !== ctx.logsRapproGridHelper.gridApi.paginationGetCurrentPage() && pageN > 0) {
                        ctx.logsRapproGridHelper.gridApi.paginationGoToPage(pageN - 1);
                    }
                    sessionStorage['logsRappCurrentPage'] = null;
                },
                () => paramsRows.fail()
            );
        }
    };
      this.logsRapproGridHelper.gridApi.setServerSideDatasource(dataSource);
      sessionStorage['logsRapproFiltre'] = JSON.stringify(this.filters);
      this.logsRapproGridHelper.gridApi.sizeColumnsToFit();
  }
}

formatDemarrage(params) {
    if (params.value !== null && params.value !== 'Invalid Date') {
      const date = new Date(params.value);
        return `<span class='ui-grid-cell-contents' container='body'>${this.datePipe.transform(
          date,
          'dd/MM/yyyy HH:mm:ss'
        )}</span>`;
      
    } else {
      return '-';
    }
  }

  formatAmmout(params) {
    if (params.value !== null) {
     return '<b>' + params.value.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' }) + '</b>';
    } else {
      return '0';
    }
  }
  formatDate = (params): string => {
    if (params.value != 'MULTI') return this.datePipe.transform(params.value, 'dd/MM/yyyy h:mm:ss');
    else return params.value;
  };

  formatDateExport = (value): string => {
    if (value != 'MULTI') return this.datePipe.transform(value, 'dd/MM/yyyy HH:mm:ss');
    else return value;
  };


  toggleFilterDisplay() {
    if (this.initHidden) {
        this.initHidden = false;
        setTimeout(() => {
            this.showFilter = !this.showFilter;
        }, 10);
    } else {
        this.showFilter = !this.showFilter;
    }
  }

  checkDateRangeValidity(event) {
    this.isDateRangeValid = event;
    if (this.dateDemarrage) {
        this.dateDemarrage.hideTooltlipDebut();
        this.dateDemarrage.hideTooltlipFin();
    }
  }

  formateDuree = (value): string => {
    if (value !== null && value != undefined) {
        return  value.replace(/ /g,":");
    }
  }

  rowGroupCallback(params) {
    return params.node.key;
  }

  ngOnDestroy(): void {
      sessionStorage['logsRapproFiltre'] = JSON.stringify(this.filters);
      this.gridHelper.saveGridStatePrefix();
  }

  getColumnsAndFieldDataLogs(columnKeys: string[], dataFields ? : any) {
		let data = new Map();
		columnKeys.forEach(column => {
			switch (column) {
				case "dateDemarrage":
					data.set("Date de démarrage", this.formatDateExport(dataFields.dateDemarrage));
					break;
        case "duree":
					data.set("Durée exécution", this.formateDuree(dataFields.duree));
					break;
        case "idPs":
					data.set("N° de PS", dataFields.idPs);
					break;
        case "vrtAnalyses":
					data.set("VRT analysés", dataFields.vrtAnalyses);
					break;
        case "vrtRapproches":
					data.set("VRT rapprochés", dataFields.vrtRapproches);
					break;
        case "vrtEtudier":
					data.set("VRT à étudier", dataFields.vrtEtudier);
					break;
        case "status":
					data.set("Status", dataFields.status);
					break;
        case "modeExecution":
					data.set("Mode exécution", dataFields.modeExecution);
					break;
			}
		})
		return data;
	}

  exportFileXlsx() {
      const columns: string[] = this.logsRapproGridHelper.gridColumnApi.getAllDisplayedColumns()
          .filter(column => column.getColDef().headerName != "")
          .map(column => column.getColDef().field);
      if (this.rowCount > 0 && this.rowCount <= 1000) {
          this.exportToExcel(columns);
      } else if (this.rowCount > 1000) {
          this.openedModal = this.modalService.show(ExportModalComponent,  { initialState: {
                  nbrElement: this.rowCount
              }, class: 'modal-lg'});
          this.openedModal.content.exportEvent.subscribe((e: any) => {
              if (e.action === ExportMode.EXPORT) {
                  // this.exportFileXlsx();
                  this.exportToExcel(columns);
              }
              if (e.action === ExportMode.EMAIL) {
                  // this.exportFileXlsx();
                  this.exportByEmail(columns);
              }
          });
      }
	}
  exportToExcel(columns) {
      this.export = true;
      this.exportState = {
          message : "Vous allez exporter <b>" + this.transformService.transform(this.rowCount) + " ligne(s)</b>, veuillez patienter quelques instants.",
          state: State.INPROGRESS
      }
      const sorts = gridConvertSort(this.logsRapproGridHelper.gridSortModel(),[]);
      this.logsRapproService.exportLogs(
          {
              sorts: sorts
          },
          this.logsRapproDataRaw,
          columns
      ).subscribe((response) => {
          saveAs(response.file, response.fileName);
          this.exportState = {
              message : "Votre fichier a été téléchargé avec succès.",
              state: State.SUCCESS
          }
      },() => {
          this.exportState = {
              message : "Le téléchargement de votre fichier a rencontré un problème.",
              state: State.FAILED
          }
      })
  }
  exportByEmail(columns) {
      const sorts = gridConvertSort(this.gridHelper.gridSortModel(),[]);
      this.logsRapproService.exportLogsByEmail(
          {
              sorts: sorts
          },
          this.logsRapproDataRaw,
          columns
      ).subscribe((response) => {

      })
  }
    closeAlert() {
        this.export = false;
    }

}
