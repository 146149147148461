import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CONF } from '../../core/constants';
import { catchError } from 'rxjs/operators';
import { HotGroupUsersDataRaw } from '../../data/hotGroupUsersDataRaw';

@Injectable({
	providedIn: 'root'
})
export class GroupUsersService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/group-users';

	constructor(private httpClient: HttpClient) {}

	getGroupUserList(): Observable<any> {
		return this.httpClient.get<any>(this.baseUrl + '/list');
	}

	getGroupUserListPageable(criteria: any): Observable<any> {
		return this.httpClient.get<any>(
			this.baseUrl +
				'/pageable-list?page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				this.getSortParameters(criteria.sortsHotGroupUsers)
		);
	}

	saveHotline(hotGroupUsersDataRaw: HotGroupUsersDataRaw): Observable<any> {
		return this.httpClient
			.post<any>(this.baseUrl + '/create', hotGroupUsersDataRaw)
			.pipe(catchError(this.handleError));
	}

	deleteHotline(idType: number): Promise<any> {
		return this.httpClient.delete(`${this.baseUrl}/${idType}`).toPromise();
	}

	handleError(error: HttpErrorResponse) {
		return throwError(error);
	}

	getSortParameters(sorts) {
		if (sorts.length === 1) {
			let sort = '';
			sort = sorts[0].property;
			return '&sort=' + sort + '&direction=' + sorts[0].direction;
		} else {
			return '&sort=libelleCourt&direction=ASC';
		}
	}
}
