import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class InputValidators {
	emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
	emailRegexPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
	capitalCasePattern = /[A-Z]/;
	smallCasePattern = /[a-z]/;
	numberPattern = /\d/;
	specialCharPattern = /[`~!@#\$%\^&\*\(\)_\-\+=\{\}\[\]\\\|:;"'<>,\.\?\/£]/;

	inputWithSpaces(control: AbstractControl): ValidationErrors | null {
		const isWhitespace = (control.value || '').trim().length === 0;
		const isValid = !isWhitespace;
		return isValid ? null : { whitespaces: true };
	}

	inputWithSpacesNgModel(value): boolean {
		const isWhitespace = (value || '').trim().length === 0;
		const isValid = !isWhitespace;
		return isValid ? true : false;
	}

	isInputTextInNumber(event) {
		return event.charCode === 8 || event.charCode === 0 || event.charCode === 13 || event.value.length >= 16
			? false
			: event.charCode >= 48 && event.charCode <= 57;
	}
}
