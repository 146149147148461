
import { DatePipe } from '@angular/common';
import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { ColDef, IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import * as _ from "lodash-es";
import { isEmpty, uniqBy } from 'lodash-es';
import { BsModalService } from 'ngx-bootstrap/modal';
import { isNullOrUndefined } from 'util';
import { AgGridHelper } from '../../components/ag-grid/ag-grid-helper';
import { gridConvertSort } from '../../components/ag-grid/ag-grid.utils';
import { FilterItem } from "../../data/filters/filter-item";
import { GridDataRaw } from '../../data/gridDataRaw';
import { MigrationOctDataRaw } from '../../data/migrationOctDataRaw';
import { StorageService } from '../../shared/storage-service';
import { AddMigrationOctComponent } from './add-migration-oct/add-migration-oct.component';
import { MigrationOctPreviewComponent } from './migration-oct-preview.component';
import { MigrationOctService } from './migration-oct.service';


@Component({
  selector: 'app-migration-oct',
  templateUrl: './migration-oct.component.html',
  styleUrls: ['./migration-oct.component.scss']
})
export class MigrationOctComponent implements OnInit, AfterViewInit {
  currentJustify = 'left';
  migrationOctGridHelper: AgGridHelper;
  gridHelper: AgGridHelper;
  sorts: any;
  gridDataRaw: GridDataRaw;
  gridDataRawMigration: GridDataRaw;
  showFilter = false;
  initHidden = true;
  filters = new MigrationOctDataRaw();
  migrationOctDataRaw: MigrationOctDataRaw;
  filterList: FilterItem[];
  protected filterListTmp: FilterItem[];
  datePipe: DatePipe;
  public rowCount: number = -1;

  constructor(private storageService: StorageService,
    private migrationOctService: MigrationOctService,
    private modalService: BsModalService,
    private router: Router,
  ) {
    const sessionPageSize = this.storageService.get('migrationOctPaginationPageSize', true);
    this.gridDataRaw = new GridDataRaw();
    this.gridDataRaw.size = !isNullOrUndefined(sessionPageSize) ? sessionPageSize : 10;
    this.migrationOctGridHelper = new AgGridHelper('migrationOct', this.gridDataRaw.size, 10);
    this.migrationOctDataRaw = new MigrationOctDataRaw();
  }

  ngOnInit() {
    this.datePipe = new DatePipe('fr-FR');
    this.parseFilters();
    this.initMigrationGrid();
    this.migrationOctService.modalClosed$.subscribe(() => {
      this.initMigrationGrid();
      this.launchSearchMigration();
    });
  }

  ngAfterViewInit() {
    this.migrationOctService.modalClosed$.subscribe(() => {
      this.initMigrationGrid();
      this.initMigrationGrid();
    });
  }

  parseFilters() {
    const parsedFilter = JSON.parse(sessionStorage.getItem('migrationOctFiltre'));
    if (!isEmpty(parsedFilter)) {
      this.filters = Object.assign({}, parsedFilter);
      // Convert String dates to Dates format
    } else {
      this.filterList = [];
    }
  }

  initMigrationGrid() {
    this.migrationOctGridHelper.gridOptions = {
      columnDefs: this.getColumnDefsMigration(),
      defaultColDef: {
        resizable: true,
        suppressMenu: true
      },
      domLayout: 'autoHeight',
      rowHeight: 50,
      headerHeight: 50,
      rowModelType: 'serverSide',
      serverSideStoreType: 'partial',
      pagination: true,
      cacheBlockSize: 10,
      maxBlocksInCache: 0,
      infiniteInitialRowCount: 1,
      paginationPageSize: this.gridDataRaw.size,
      suppressScrollOnNewData: true,
      suppressPaginationPanel: true,
      suppressContextMenu: true,
      enableCellTextSelection: true,
      localeText: this.migrationOctGridHelper.getLocaleText(),
      onGridReady: (params) => this.onMigrationGridReady(params)
    };
  }

  launchSearchMigration() {
    const ctx = this;
    ctx.migrationOctDataRaw = new MigrationOctDataRaw();
    const dataSource: IServerSideDatasource = {
      getRows: function (paramsRows: IServerSideGetRowsParams) {
        ctx.sorts = gridConvertSort(ctx.migrationOctGridHelper.gridSortModel(), []);
        const sorts = ctx.sorts ? ctx.sorts : null;
        const criteria = {
          page: ctx.migrationOctGridHelper.gridApi.paginationGetCurrentPage() || 0,
          size: ctx.migrationOctGridHelper.gridApi.paginationGetPageSize(),
          sorts
        };
        ctx.migrationOctService.getMigrationOctFilteredList(criteria, ctx.migrationOctDataRaw).subscribe(
          (data) => {
            ctx.migrationOctGridHelper.manageNoRowsOverlay(data.totalElements);
            paramsRows.success({
              "rowData": data.content,
              "rowCount": data.totalElements
            });
            const pageN = 1;
            if (!isNullOrUndefined(pageN) && pageN !== ctx.migrationOctGridHelper.gridApi.paginationGetCurrentPage() && pageN > 0) {
              ctx.migrationOctGridHelper.gridApi.paginationGoToPage(pageN - 1);
            }
            sessionStorage['migrationOctCurrentPage'] = null;
          },
          () => paramsRows.fail()
        );
      }
    };
    this.migrationOctGridHelper.gridApi.setServerSideDatasource(dataSource);
    this.migrationOctGridHelper.gridApi.sizeColumnsToFit();
  }

  onPaginationSizeChange(paginationSize: number): void {
    this.migrationOctGridHelper.changePaginationSize(paginationSize);
    this.launchSearch();
    this.migrationOctGridHelper.saveGridStatePrefix();
  }

  resetGridState() {
    this.migrationOctGridHelper.resetGridState();
    this.gridDataRaw.size = this.migrationOctGridHelper.defaultPaginationPageSize;
  }

  onMigrationGridReady(params: any) {
    this.migrationOctGridHelper.gridApi = params.api;
    this.migrationOctGridHelper.gridColumnApi = params.columnApi;
    let parsedFilter = JSON.parse(sessionStorage.getItem('migrationOctFiltre'));
    if (parsedFilter) {
      Object.keys(parsedFilter).forEach(
        (key) => (parsedFilter[key] === undefined || parsedFilter[key] === null ? delete parsedFilter[key] : {})
      );
    }
    this.setColumnOrder(window.innerWidth);
    this.restoreGrid();
    // if (!isEmpty(parsedFilter)) {
    this.launchSearch();
    // } else this.clearGridFromData();
  }

  restoreGrid() {
    this.migrationOctGridHelper.restoreGridStatePrefix();
    this.gridDataRaw.size = this.migrationOctGridHelper.paginationPageSize;
  }

  onKeyDown(event) {
    this.launchSearch();
  }

  resetAllFilter(): void {
    console.log("reset all filters called")
    this.initFilter();
    this.filterList = [];
    this.filters = new MigrationOctDataRaw();
/*     if (Array.isArray(this.filterList) && this.filterList.length > 0) {
      this.fillFilterObjectFromTagsOnly();
      this.launchSearch();
    } else this.clearGridFromData(); */
    this.launchSearch();
    this.resetFilter();
  }

  resetFilter() {
    this.filterList = [];
    this.filterListTmp = [];
    sessionStorage['migrationOctFiltre'] = null;
  }

  clearGridFromData() {
    this.rowCount = -1;
    this.migrationOctGridHelper.gridApi.setServerSideDatasource(null);
  }

  initFilter() {
    this.resetFilter();
    this.updateFilterList(this.filters);
  }

  updateFilterList(filters) {
    const ctx = this;
    ctx.filterList = [];
    Object.keys(filters).forEach((key) => {
      if (filters[key] !== null && filters[key] !== '') {
        ctx.filterList.push(ctx.translateFilterLabelValue(key, filters[key]));
      }
    });
    // Remove duplicated ids
    ctx.filterList = uniqBy(ctx.filterList, 'id');
    // filter null
    ctx.filterList = ctx.filterList.filter((item) => {
      return item.id != null;
    });
  }

  removeFilter(id) {
    delete this.filters[id];
    const currentFilterList = this.filterList;
    const index = _.findIndex(currentFilterList, (filt) => filt.id === id);
    currentFilterList.splice(index, 1);
    this.filterList = currentFilterList;
    sessionStorage['migrationOctFiltre'] = JSON.stringify(this.filters);
    this.fillFilterObjectFromTagsOnly();
    this.launchSearch();
    /*     if (Array.isArray(this.filterList) && this.filterList.length > 0) {
          this.fillFilterObjectFromTagsOnly();
          this.launchSearch();
        } else this.clearGridFromData(); */
  }

  fillFilterObjectFromTagsOnly() {
    this.filters = new MigrationOctDataRaw();
    this.filterList.forEach((filter) => {
      this.filters[filter.id] = filter.value2;
    });
  }

  translateFilterLabelValue(key, value) {
    let translatedKey = key;
    let formattedValue = value;
    let formattedKey = key;
    switch (key) {
      case 'numeroPS':
        translatedKey = 'N° de PS';
        break;
      case 'balDestinatrice':
        translatedKey = 'Bal de destination';
        break;
      case 'username':
        translatedKey = 'Bal à migrer';
        break;
      case 'status':
        translatedKey = 'Migration lancé';
        formattedValue = value ? 'Oui' : 'Non';
        break;
      /*           case 'lastLogError':
                  translatedKey = 'Migration en erreur';
                  break; */
      default:
        break;
    }
    return new FilterItem().withId(formattedKey).withLabel(translatedKey).withValue(formattedValue).withValue2(value);
  }

  launchSearch() {
    const ctx = this;
    this.migrationOctDataRaw = new MigrationOctDataRaw();
    console.log(this.filters.status)
    this.migrationOctDataRaw.numeroPS = this.filters.numeroPS ? this.filters.numeroPS : null;
    this.migrationOctDataRaw.balDestinatrice = this.filters.balDestinatrice ? this.filters.balDestinatrice : null;
    this.migrationOctDataRaw.username = this.filters.username ? this.filters.username : null;
    this.migrationOctDataRaw.status = isNullOrUndefined(this.filters.status) ? null : this.filters.status;
    // this.migrationOctDataRaw.lastLogError = this.filters.lastLogError ? this.filters.lastLogError : null;
    this.updateFilterList(this.filters);
    const dataSource: IServerSideDatasource = {
      getRows: function (paramsRows: IServerSideGetRowsParams) {
        ctx.sorts = gridConvertSort(ctx.migrationOctGridHelper.gridSortModel(), []);
        const sortsMigrationOct = ctx.sorts ? ctx.sorts : null;
        const criteria = {
          page: ctx.migrationOctGridHelper.gridApi.paginationGetCurrentPage() || 0,
          size: ctx.migrationOctGridHelper.gridApi.paginationGetPageSize(),
          sortsMigrationOct
        };
        ctx.migrationOctService.getMigrationOctFilteredList(criteria, ctx.migrationOctDataRaw).subscribe(
          (data) => {
            ctx.rowCount = data.totalElements > 0 ? data.totalElements : -1;
            ctx.migrationOctGridHelper.manageNoRowsOverlay(data.totalElements);
            paramsRows.success({
              "rowData": data.content,
              "rowCount": data.totalElements
            });
            const pageN = Number.parseInt(sessionStorage.getItem('migrationOctCurrentPage'));
            if (!isNullOrUndefined(pageN) && pageN !== ctx.migrationOctGridHelper.gridApi.paginationGetCurrentPage() && pageN > 0) {
              ctx.migrationOctGridHelper.gridApi.paginationGoToPage(pageN - 1);
            }
            sessionStorage['migrationOctCurrentPage'] = null;
          },
          () => paramsRows.fail()
        );
      }
    };
    this.migrationOctGridHelper.gridApi.setServerSideDatasource(dataSource);
    sessionStorage['migrationOctFiltre'] = JSON.stringify(this.filters);
  }

  getColumnDefsMigration(): ColDef[] {
    const ctx = this;
    return [
      {
        headerName: '',
        field: 'details',
        minWidth: 50,
        maxWidth: 50,
        lockVisible: true,
        cellClass: ['text-center'],
        cellRenderer: MigrationOctPreviewComponent,
        cellRendererParams: {
          iconClass: 'fa fa-eye',
          action: 'view',
        }
      },
      {
        headerName: 'N° de PS',
        field: 'numeroPS',
        cellClass: (params) => this.styleLink(params),
        onCellClicked: (params) => this.onPsCellClicked(params),
        minWidth: 100,
        maxWidth: 150,
        lockVisible: true,
        sortable: true
      },
      {
        headerName: 'Serveur POP',
        field: 'serveurPop',
        cellClass: ['text-center'],
        minWidth: 150,
        lockVisible: true,
        sortable: true
      },
      {
        headerName: 'BAL à migrer',
        field: 'username',
        cellClass: ['text-center'],
        minWidth: 220,
        lockVisible: true,
        sortable: true
      },
      {
        headerName: 'Num. de facturation à migrer',
        field: 'numeroFacturation',
        cellClass: ['text-center'],
        minWidth: 220,
        maxWidth: 300,
        lockVisible: true,
        sortable: true
      },
      {
        headerName: 'BAL de destination',
        field: 'balDestinatrice',
        cellClass: ['text-center'],
        minWidth: 220,
        lockVisible: true,
        sortable: true
      },
      {
        headerName: "Date de démarrage",
        field: 'dateCreation',
        cellClass: ['text-center'],
        valueFormatter: this.formatDateDemarrage,
        minWidth: 150,
        maxWidth: 200,
        lockVisible: true,
        sortable: true
      },
      {
        headerName: 'Date de la dernière exécution',
        field: 'dateDemarrage',
        valueFormatter: this.formatDateDemarrage,
        cellClass: ['text-center'],
        minWidth: 250,
        maxWidth: 300,
        lockVisible: true,
        sortable: true
      },
      {
        headerName: 'Date du dernier message copié',
        field: 'dateDernierMessage',
        cellClass: ['text-center'],
        valueFormatter: this.formatDate,
        minWidth: 250,
        maxWidth: 300,
        lockVisible: true,
        sortable: true
      },
      {
        headerName: 'Nombre de messages copiés',
        field: 'nbMailsCopie',
        cellClass: ['text-center'],
        minWidth: 200,
        maxWidth: 250,
        lockVisible: true,
        sortable: true
      },
      {
        headerName: 'Activation',
        field: 'errorsAct',
        cellClass: ['text-center'],
        minWidth: 100,
        maxWidth: 100,
        lockVisible: true,
        cellRenderer: MigrationOctPreviewComponent,
        cellRendererParams: {
          iconClass: 'fa fa-play',
          action: 'start',
          migration: ctx,
        }
      },
      {
        headerName: 'Boite de destination',
        field: 'errors',
        cellClass: ['text-center'],
        minWidth: 150,
        maxWidth: 150,
        lockVisible: true,
        cellRenderer: MigrationOctPreviewComponent,
        cellRendererParams: {
          iconClass: 'fa fa-link',
          action: 'link',
          migration: ctx,
        }
      },
      {
        headerName: 'Logs',
        field: 'log',
        minWidth: 50,
        maxWidth: 50,
        lockVisible: true,
        cellClass: ['text-center'],
        cellRenderer: MigrationOctPreviewComponent,
        cellRendererParams: {
          action: 'warning',
        }
      },
      {
        headerName: '',
        field: 'remove',
        minWidth: 50,
        maxWidth: 50,
        lockVisible: true,
        cellClass: ['text-center'],
        cellRenderer: MigrationOctPreviewComponent,
        cellRendererParams: {
          iconClass: 'fa fa-trash',
          action: 'delete',
        }
      }
    ];
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    let width = event.target.innerWidth;
    this.setColumnOrder(width);

  }
  setColumnOrder(width:any) {
    const columnDefs = this.getColumnDefsMigration();
    if(width <= 2500){
      const columnApi = this.migrationOctGridHelper.gridColumnApi;
      const optionsColumnIndex = columnDefs.findIndex(column => column.field === 'remove');
      if (optionsColumnIndex !== -1) {
        columnApi.moveColumn('remove', 1);
        columnApi.moveColumn('log', 2);
        columnApi.moveColumn('errors', 3);
        columnApi.moveColumn('errorsAct', 4);
      }
    }else {
      this.migrationOctGridHelper.gridApi.sizeColumnsToFit();
      this.migrationOctGridHelper.gridApi.setColumnDefs(columnDefs);
    }
  }
  formatDate = (params): string => {
    if (params.value != 'MULTI') return this.datePipe.transform(params.value, 'dd/MM/yyyy hh:mm:ss');
    else return params.value;
  };

  formatDateDemarrage = (params): string => {
    if (params.value) {
     return this.datePipe.transform(params.value, 'yyyy-MM-dd HH:mm:ss');
    } else {
      return params.value;
    }
  };


  toggleFilterDisplay() {
    let width =window.innerWidth;
    if (this.initHidden) {
      this.initHidden = false;
      setTimeout(() => {
        this.showFilter = !this.showFilter;
      }, 10);
    } else {
      this.showFilter = !this.showFilter;
      if(!this.showFilter && width>2500){
        setTimeout(() => {
          this.migrationOctGridHelper.gridApi.sizeColumnsToFit();
        }, 200);
      }
    }
  }

  ngOnDestroy(): void {
    sessionStorage['migrationOctFiltre'] = JSON.stringify(this.filters);
    //this.gridHelper.saveGridStatePrefix();
  }

  onTabChange(event: any) {
    const activeTabId = event.nextId;
    sessionStorage.setItem('migration-tab', activeTabId);
  }

  openMigrationModal() {
    let initialState = {};
    this.modalService.show(AddMigrationOctComponent, {
      initialState,
      class: 'modal-xl'
    });
  }

  styleLink(params) {
    return 'link-in-not-selected-rows';
  }

  onPsCellClicked(params) {
    this.router.navigate(['/ps-details', params.data.idPs]);
  }
}
