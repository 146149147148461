import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ScrollTopService } from '../../../../../core/utilities/scroll-top.service';
import { AgGridHelper } from '../../../../../components/ag-grid/ag-grid-helper';
import { IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { PsService } from '../../../ps.service';
import { ToastrService } from 'ngx-toastr';
import { PsLogicielPreviewComponent } from '../ps-logiciel-preview.components';
import { LogicielsService } from '../../../../editeurs/services/logiciels.service';
import { LogicielDataRaw } from 'src/main/webapp/src/app/data/logicielDataRaw';

@Component({
	selector: 'app-ps-logiciel-existant',
	templateUrl: './ps-logiciel-existant.component.html',
	styleUrls: ['./ps-logiciel-existant.component.scss']
})
export class PsLogicielExistantComponent implements OnInit {
	gridHelper: AgGridHelper;
	paginationSize: number;
	params: any;
	logicielDataRaw: LogicielDataRaw;

	@Input() idPs: Number;
	@Input() hasEditor: Boolean;

	constructor(
		private scrollTopService: ScrollTopService,
		private modalRef: BsModalRef,
		private logicielSVC: LogicielsService,
		private psService: PsService,
		private toastr: ToastrService
	) {
		this.gridHelper = new AgGridHelper('logicielExist', 5, 5);
		this.logicielDataRaw = new LogicielDataRaw();
	}

	ngOnInit() {
		this.scrollTopService.setScrollTop();
		this.gridHelper.gridOptions = {
			columnDefs: this.getColumnDefs(),
			defaultColDef: {
				resizable: true,
				suppressMenu: true
			},
			components: {
				customInputFilterComponent: this.getInputFloatingFilterComponent()
			},
			context: { parentComponent: this },
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 5,
			paginationPageSize: 5,
			infiniteInitialRowCount: 1,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			localeText: this.gridHelper.getLocaleText(),
			onGridReady: (params) => this.onGridReady(params),
			// onGridSizeChanged: (params) => this.onGridSizeChanged(params),
			onFilterChanged: (params) => { }
		};
		this.paginationSize = this.gridHelper.paginationPageSize;
	}

	onGridSizeChanged(params) {
		this.gridHelper.gridApi.sizeColumnsToFit();
	}

	restoreGrid() {
		this.gridHelper.restoreGridStatePrefix();
		this.paginationSize = this.gridHelper.paginationPageSize;
	}

	onGridReady(params) {
		const ctx = this;
		this.gridHelper.gridApi = params.api;
		this.gridHelper.gridColumnApi = params.columnApi;
		this.restoreGrid();
		this.gridHelper.gridApi.sizeColumnsToFit();

		const dataSource: IServerSideDatasource = {
			getRows: function (paramsRows: IServerSideGetRowsParams) {
				// const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
				ctx.logicielSVC
					.getLogicielsFiltred(
						{
							page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
							size: ctx.gridHelper.gridApi.paginationGetPageSize(),
							sort: 'editeurRaisonSociale',
							direction: 'ASC'
						},
						ctx.logicielDataRaw
					)
					.subscribe(
						(data) => {
							const rowCount = data.totalElements;
							ctx.gridHelper.manageNoRowsOverlay(rowCount);
							paramsRows.success({"rowData": data.content, "rowCount": rowCount});
						},
						() => paramsRows.fail()
					);
			}
		};
		this.gridHelper.gridApi.setServerSideDatasource(dataSource);
		this.gridHelper.gridApi.sizeColumnsToFit();
		this.gridHelper.gridApi.hidePopupMenu();
	}

	private getColumnDefs() {
		return [
			{
				headerName: 'Raison sociale de l’éditeur',
				field: 'editeurRaisonSociale',
				minWidth: 300,
				lockVisible: true,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true }
			},
			{
				headerName: 'Libellé',
				field: 'libelle',
				minWidth: 250,
				lockVisible: true,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true }
			},
			{
				headerName: "Système d'exploitation",
				field: 'systeme',
				minWidth: 250,
				lockVisible: true,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true }
			},
			{
				headerName: 'Version',
				field: 'version',
				minWidth: 190,
				lockVisible: true,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true }
			},
			{
				headerName: '',
				field: 'options',
				minWidth: 70,
				maxWidth: 70,
				lockVisible: true,
				cellClass: ['link-icon', 'text-center'],
				cellRenderer: PsLogicielPreviewComponent,
				cellStyle: this.gridHelper.centerContent(),
				onCellClicked: (params) => this.attachPsOfLogiciel(params.data),
				cellRendererParams: {
					iconClass: 'fa fa-link',
					redirect: 'attach'
				}
			}
		];
	}

	showCurrentValue(event) { }

	getInputFloatingFilterComponent() {
		const ctx = this;
		function InputFloatingFilter() { }

		InputFloatingFilter.prototype.init = function (params) {
			this.eGui = document.createElement('div');
			this.eGui.setAttribute('class', 'ag-input-wrapper');

			switch (params.filterParams.colDef.field) {
				case 'systeme':
					this.eGui.innerHTML =
						"<input type='text'  class='customFilterInput' style='width:100%; box-shadow: none;' onKeyPress='if(this.value.length==30) return false;'/>";
					break;
				case 'version':
					this.eGui.innerHTML =
						"<input type='text'  class='customFilterInput' style='width:100%; box-shadow: none;' onKeyPress='if(this.value.length==10) return false;'/>";
					break;

				case 'editeurRaisonSociale':
				case 'libelle':
					this.eGui.innerHTML =
						"<input type='text'  class='customFilterInput' style='width:100%; box-shadow: none;' onKeyPress='if(this.value.length==50) return false;'/>";
					break;
				default:
					break;
			}

			this.currentValue = null;
			this.eFilterInput = this.eGui.querySelector('input');
			this.eFilterInput.style.color = params.color;
			const that = this;
			function onInputChanged() {
				const filterField = params.filterParams.colDef.field;
				if (that.eFilterInput.value === '') {
					// Remove the filter
					delete ctx.logicielDataRaw[filterField];
					params.parentFilterInstance(function (instance) {
						instance.onFloatingFilterChanged(null, null);
					});
					return;
				}

				that.currentValue = that.eFilterInput.value;

				if (that.eFilterInput.value.length >= 3) {
					if (that.currentValue !== '') {
						ctx.logicielDataRaw[filterField] = that.eFilterInput.value;
					}
					params.parentFilterInstance(function (instance) {
						instance.onFloatingFilterChanged('equals', that.currentValue);
					});
				}
			}
			this.eFilterInput.addEventListener('input', onInputChanged);
		};

		InputFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
			if (!parentModel) {
				this.eFilterInput.value = '';
				this.currentValue = null;
			} else {
				this.eFilterInput.value = parentModel.filter + '';
				this.currentValue = parentModel.filter;
			}
		};

		InputFloatingFilter.prototype.getGui = function () {
			return this.eGui;
		};

		return InputFloatingFilter;
	}

	attachPsOfLogiciel(logiciel) {
		this.psService.attachPsOfLogiciel(this.idPs, logiciel.idLogiciel).subscribe(
			(data) => {
				if (data) {
					this.toastr.success('Le logiciel a été ajouté avec succès');
					if (!this.hasEditor) {
						this.toastr.success("L'éditeur a été ajouté avec succès");
					}
					this.modalRef.hide();
				}
			},
			() => {
				this.toastr.error("Le logiciel n'a pas pu être ajouté");
			}
		);
	}
}
