import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONF } from '../../../core/constants';
import { LoiDataRaw } from '../../../data/loiDataRaw';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class LoiService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/flux/loi';

	constructor(private httpClient: HttpClient) {}

	sendLoiToPs(loiDataRaw: LoiDataRaw): Observable<any> {
		return this.httpClient.post<any>(this.baseUrl + '/send/ps', loiDataRaw);
	}

	getLastLoiRang(): Observable<any> {
		const criteria = {
			loiType: 1,
			valid: true
		};
		return this.httpClient.post<any>(this.baseUrl + '/rang/' + criteria.loiType + '/' + criteria.valid, {});
	}

	downloadLastLoi() {
		return this.httpClient
			.post<HttpResponse<Blob>>(
				this.baseUrl + '/download',
				{},
				{
					headers: new HttpHeaders({
						'Content-Type': 'application/json',
						AcceptEncoding: 'gzip, deflate',
						Accept: '*/*',
						CacheControl: 'no-cache'
					}),
					observe: 'response' as 'body',
					responseType: 'blob' as 'json'
				}
			)
			.pipe(
				map((response: HttpResponse<any>): any => {
					return this.getFileInfosFromResponse(response);
				})
			);
	}

	getFileInfosFromResponse(response: HttpResponse<any>) {
		return {
			file: new Blob([ response.body ], { type: 'application/pdf' }),
			fileName: this.getFileNameFromResponse(response)
		};
	}

	getFileNameFromResponse(response: HttpResponse<object>): string {
		const filenameRegex = /filename="([^"]+)/;
		const fileName = response.headers.get('Content-Disposition').match(filenameRegex)[1];
		return fileName;
	}
}
