<div class="modal modal-show fade show" id="modalMigrationOct" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel">

    <div class="modal-dialog modal-xxl" role="document">
        <div class="modal-content">
            <div class="inmodal">
                <div class="modal-header pb-1">
                    <h3 class="modal-title">{{title}}</h3>
                    <button class="close" name="btn_display_filter" data-dismiss="modal" type="button" title="Fermer"
                        (click)="closeModal()"><span aria-hidden="true">×</span></button>
                </div>
                <div class="modal-body pb-0">
                    <div class="form-block">
                        <div class="row">
                            <div class="col">
                                <h3 class="title-border">
                                    <div class="row">
                                        <div class="col-auto mr-auto">Informations de l'ancien BAL</div>
                                        <div class="col-auto"></div>
                                    </div>
                                </h3>
                            </div>
                        </div>
                        <form [formGroup]="addMigrationForm" (ngSubmit)="onSubmit()">
                            <fieldset class="container-fluid">
                                <div class="row">
                                    <div class="col-sm-2 col-form-label">Numéro de PS </div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" formControlName="numeroPS"  [ngClass]="{ 'is-invalid': submitted && formFields.numeroPS.errors }"
                                        onKeyPress="if(this.value.length==9) return false;"
                                        pattern="^[0-9]+$" min="0" step="1" max="999999999"
                                        oninput="this.value=this.value.replace(/[^\d]/,'');"
                                        onpaste="if(this.value.length==9) return false;"
                                        />
                                        <div *ngIf="submitted && formFields.numeroPS.errors" class="invalid-feedback">
                                            <div *ngIf="formFields.numeroPS.errors.required || formFields.numeroPS.errors.whitespaces">
                                              Ce champ est obligatoire
                                            </div>
                                          </div>
                                    </div>
                                    <div class="col-sm-3 col-form-label">Numéro de facturation à migrer</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" formControlName="numeroFacturation"  [ngClass]="{ 'is-invalid': submitted && formFields.numeroFacturation.errors }"
                                        onKeyPress="if(this.value.length==9) return false;"
                                        pattern="^[0-9]+$" min="0" step="1" max="999999999"
                                        oninput="this.value=this.value.replace(/[^\d]/,'');"
                                        onpaste="if(this.value.length==9) return false;"
                                        />
                                        <div *ngIf="submitted && formFields.numeroFacturation.errors" class="invalid-feedback">
                                            <div *ngIf="formFields.numeroFacturation.errors.required || formFields.numeroFacturation.errors.whitespaces">
                                              Ce champ est obligatoire
                                            </div>
                                          </div>
                                    </div>
                                    <div class="col-sm-1"></div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-2 col-form-label">Serveur POP</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" formControlName="serveurPop" class="form-control" [ngClass]="{ 'is-invalid': submitted && formFields.serveurPop.errors }"/>
                                        <div *ngIf="submitted && formFields.serveurPop.errors" class="invalid-feedback">
                                            <div *ngIf="formFields.serveurPop.errors.required || formFields.serveurPop.errors.whitespaces">
                                              Ce champ est obligatoire
                                            </div>
                                          </div>
                                    </div>
                                    <div class="col-sm-3 col-form-label">Port</div>
                                    <div class="col-sm-2 form-group">
                                        <input type="text" class="form-control" formControlName="port" [ngClass]="{ 'is-invalid': submitted && formFields.port.errors }"
                                        onKeyPress="if(this.value.length==3) return false;"
                                        pattern="^[0-9]+$" min="0" step="1" max="999"
                                        oninput="this.value=this.value.replace(/[^\d]/,'');"
                                        onpaste="if(this.value.length==3) return false;"
                                        />
                                        <div *ngIf="submitted && formFields.port.errors" class="invalid-feedback">
                                            <div *ngIf="formFields.port.errors.required || formFields.port.errors.whitespaces">
                                              Ce champ est obligatoire
                                            </div>
                                          </div>
                                    </div>
                                    <div class="col-sm-2"></div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-2 col-form-label">Identifiant</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" formControlName="username"/>
                                        <div *ngIf="submitted && formFields.username.errors" class="invalid-feedback">
                                            <div *ngIf="formFields.username.errors.required || formFields.username.errors.whitespaces">
                                              Ce champ est obligatoire
                                            </div>
                                          </div>
                                    </div>
                                    <div class="col-sm-3 col-form-label">Mot de passe</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" formControlName="password" [ngClass]="{ 'is-invalid': submitted && formFields.password.errors }"/>
                                        <div *ngIf="submitted && formFields.password.errors" class="invalid-feedback">
                                            <div *ngIf="formFields.password.errors.required || formFields.password.errors.whitespaces">
                                              Ce champ est obligatoire
                                            </div>
                                          </div>
                                    </div>
                                    <div class="col-sm-1"></div>
                                </div>
                                <!-- TOOLBAR -->
                                <div class="button-bar control">
                                    <button type="button" class="btn btn-default" (click)="reset()"> Annuler </button>
                                    <button type="submit" class="btn btn-primary"> Ajouter </button>
                                </div>

                            </fieldset>
                        </form>
                    </div>
                </div>
                <div class="modal-footer">
                </div>
            </div>
        </div>
    </div>
</div>