import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { isNullOrUndefined } from 'util';
import { AgGridHelper } from '../../../components/ag-grid/ag-grid-helper';
import { GridDataRaw } from '../../../data/gridDataRaw';
import { StorageService } from '../../../shared/storage-service';
import { MigrationOctService } from '../migration-oct.service';
import { MigrationOctDataRaw } from '../../../data/migrationOctDataRaw';
import { ColumnTooltipComponent } from '../../../components/column-tooltip/column-tooltip-component';
import { DetailEmailCellRendererComponent } from './detail-email-cell-enderer.component';
import { ColDef, IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { AnyAaaaRecord } from 'dns';

@Component({
	selector: 'careweb-app-details-migration-oct',
	templateUrl: './details-migration-oct.component.html',
	styleUrls: ['./details-migration-oct.component.scss']
})

export class DetailsMigrationOctComponent implements OnInit {
	@Input() email: string;
	@Input() server: string;
	@Input() password: string;
	@Input() port: AnyAaaaRecord;
	datePipe: DatePipe;
	migrationOctDetailsGridHelper: AgGridHelper;
	gridDataRaw: GridDataRaw;
	gridDataRawMigrationDetails: GridDataRaw;
	migrationOctDetailsDataRaw: MigrationOctDataRaw;
	public rowCount: number = -1;
	activeItem: number | null = null;
	constructor(
		private storageService: StorageService,
		private modalRef: BsModalRef,
		private migrationOctService: MigrationOctService,
	) {
		const sessionPageSize = this.storageService.get('migrationOctPaginationPageSize', true);
		this.gridDataRaw = new GridDataRaw();
		this.gridDataRaw.size = !isNullOrUndefined(sessionPageSize) ? sessionPageSize : 10;
		this.migrationOctDetailsGridHelper = new AgGridHelper('migrationOct', this.gridDataRaw.size, 10);
		this.migrationOctDetailsDataRaw = new MigrationOctDataRaw();
	}

	ngOnInit() {
		this.datePipe = new DatePipe('fr-FR');
		this.initMigrationDetailsGrid();
	}

	toggleItem(index: number) {
		this.activeItem = this.activeItem === index ? null : index;
	  }

	initMigrationDetailsGrid() {
		this.migrationOctDetailsGridHelper.gridOptions = {
			columnDefs: this.getColumnLogsDefs(),
			defaultColDef: {
				resizable: true,
				suppressMenu: true
			},
			alwaysShowVerticalScroll: false,
			detailRowHeight: 550,
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			paginateChildRows: true,
			pagination: true,
			cacheBlockSize: 10,
			maxBlocksInCache: 0,
			infiniteInitialRowCount: 1,
			paginationPageSize: this.gridDataRaw.size,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			enableCellTextSelection: true,
			localeText: this.migrationOctDetailsGridHelper.getLocaleText(),
			onGridReady: (params) => this.onMigrationOctLogsGridReady(params),
			masterDetail: true,
            detailCellRenderer: 'mailsDetailCellRenderer',
			components: {
                mailsDetailCellRenderer: DetailEmailCellRendererComponent,
                columnTooltipCellRenderer: ColumnTooltipComponent
            }
		};
	}

	closeModal() {
		this.modalRef.hide();
	}

	reset() {
		this.modalRef.hide();
	}

	onMigrationOctLogsGridReady(params: any) {
		this.migrationOctDetailsGridHelper.gridApi = params.api;
		this.migrationOctDetailsGridHelper.gridColumnApi = params.columnApi;
		this.restoreGrid();
		//if (!isEmpty(parsedFilter)) {
		this.launchSearch();
		// } else this.clearGridFromData();
	}

	restoreGrid() {
		this.migrationOctDetailsGridHelper.restoreGridStatePrefix();
		this.gridDataRaw.size = this.migrationOctDetailsGridHelper.paginationPageSize;
	}

	launchSearch() {
		const ctx = this;
		this.migrationOctDetailsGridHelper.gridApi.sizeColumnsToFit();
		this.migrationOctDetailsDataRaw = new MigrationOctDataRaw();
		const dataSource: IServerSideDatasource = {
			getRows: function (paramsRows: IServerSideGetRowsParams) {
				const criteria = {
					email: ctx.email,
					server: ctx.server,
					password: ctx.password,
					port: ctx.port,
					page: ctx.migrationOctDetailsGridHelper.gridApi.paginationGetCurrentPage() || 0,
					size: ctx.migrationOctDetailsGridHelper.gridApi.paginationGetPageSize()
				};
				ctx.migrationOctService.getMigrationOctDetails(criteria).subscribe(
					(data) => {
						ctx.rowCount = data.totalElements > 0 ? data.totalElements : -1;
						ctx.migrationOctDetailsGridHelper.manageNoRowsOverlay(data.totalElements);
						paramsRows.success({
							"rowData": data.content,
							"rowCount": data.totalElements
						});
						const pageN = Number.parseInt(sessionStorage.getItem('detailsMigrationOctCurrentPage'));
						if (!isNullOrUndefined(pageN) && pageN !== ctx.migrationOctDetailsGridHelper.gridApi.paginationGetCurrentPage() && pageN > 0) {
							ctx.migrationOctDetailsGridHelper.gridApi.paginationGoToPage(pageN - 1);
						}
						sessionStorage['detailsMigrationOctCurrentPage'] = null;
					},
					() => paramsRows.fail()
				);
			}
		};
		this.migrationOctDetailsGridHelper.gridApi.setServerSideDatasource(dataSource);
		this.migrationOctDetailsGridHelper.gridApi.sizeColumnsToFit();
		
	}

	getColumnLogsDefs(): ColDef[] {
		return [
			{
				headerName: 'Date de récepetion',
				field: 'dateEmail',
				minWidth: 200,
				valueFormatter: this.formatDate,
				cellRenderer: 'agGroupCellRenderer',
				sortable: true,
				cellRendererParams: {
					suppressDoubleClickExpand: true,
					//innerRenderer: 'columnTooltipCellRenderer'
				},
			},
			{
				headerName: 'Expéditeur',
				field: 'emetteur',
				minWidth: 300,
				sortable: true
			},
			{
				headerName: 'Sujet',
				field: 'subject',
				minWidth: 400,
				flex: 1,
				sortable: true
			}]
	}

	formatDate = (params): string => {
		const convertedDate  = new Date(params.value);
		return this.datePipe.transform(convertedDate,  'dd/MM/yyyy HH:mm:ss');
	};

	onPaginationSizeChange(paginationSize: number): void {
		this.migrationOctDetailsGridHelper.changePaginationSize(paginationSize);
		this.launchSearch();
		this.migrationOctDetailsGridHelper.saveGridStatePrefix();
	};

	resetGridState() {
		this.migrationOctDetailsGridHelper.resetGridState();
		this.gridDataRaw.size = this.migrationOctDetailsGridHelper.defaultPaginationPageSize;
	};
}
