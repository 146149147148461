<div class="full-careweb-container">
    <div id="filter" class="filter-container" carewebFilterMinHeight>
        <div class="filter-container-inner" [ngClass]="{ 'show-content': showFilter, 'init-hidden': initHidden }">
            <careweb-accordion-header-buttons (closeFilters)="toggleFilterDisplay()" (resetFilters)="resetAllFilter()"
                (launchSearch)="launchSearch()"></careweb-accordion-header-buttons>
            <div class="filter-content">
                <mat-accordion displayMode="flat" class="accordion-filter">
                    <careweb-accordion-group id="num_amc_otp" title="Numéro AMC/OTP">
                        <input type="text" id="input_amc_otp" [(ngModel)]="filters.organizationNumber" />
                    </careweb-accordion-group>
                </mat-accordion>
            </div>
        </div>
    </div>
    <div class="grid-container" id="grid">
        <h2 class="title-border mt-3">Liste des AMC/OTP</h2>
        <div>
            <section>
                <div class="row grid-top-btn">
                    <div class="col-md-4 filter">
                        <button type="button" name="btn_toggle_filter" class="btn btn-primary small left-open"
                            [hidden]="showFilter" (click)="toggleFilterDisplay()">
                            <i class="fa fa-sliders"></i>
                            Filtrer
                        </button>
                        <careweb-pluralize class="filter-count" [messageKey]="'Résultat(s) trouvé(s)'"
                            [count]="gridHelper.totalRowCount"></careweb-pluralize>
                    </div>
                    <div class="col-md-8 conf-col">
                        <div class="careweb-toolbar">
                            <careweb-pagination-size id="dv_amc_otp_paging" [pageSizes]="[10, 20, 30, 50, 100]"
                                [(ngModel)]="gridDataRaw.size"
                                (paginationSizeChange)="onPaginationSizeChange($event)">
                            </careweb-pagination-size>
                            <span class="btn btn-action small" tooltip="Réinitialiser l'affichage" placement="top"
                                container="body" name="spn_reset_grid" (click)="resetGridState()">
                                <i class="fa fa-refresh"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row grid-top-filter">
                    <div class="col-md-12">
                        <careweb-filter-label *ngFor="let filter of filterList" (closeFilter)="removeFilter(filter.id)">
                            <span *ngIf="filter">{{ filter.label}}: {{filter.value }}</span>
                        </careweb-filter-label>
                    </div>
                </div>
                <div class="row highlight-row">
                    <div class="col-md-12 relative">
                        <ag-grid-angular style="width: 100%" class="ag-theme-balham ag-theme-no-rows"
                            [gridOptions]="gridHelper.gridOptions">
                        </ag-grid-angular>
                        <careweb-pagination [gridApi]="gridHelper.gridApi"></careweb-pagination>
                       <div *ngIf="showError" class="row highlight-row col-12 mt-2 m-0 p-0 " style="color:red;">
                        Une erreur est survenue coté web service, Veuillez réessayer plus tard.
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
   
</div>