import { IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import { AgGridHelper } from '../../../components/ag-grid/ag-grid-helper';
import { gridConvertSort } from '../../../components/ag-grid/ag-grid.utils';
import { AuthService } from '../../../core/services/auth.service';
import { OctService } from '../../../core/services/oct.service';
import { PsFilterRaw } from '../../../data/filters/psFilterRaw';
import { PsDataRaw } from '../../../data/psDataRaw';
import { PsService } from '../../ps/ps.service';
import { LoiService } from './loi.service';
import { OctPsService } from '../../ps/service/octps.service';
import { FileExport } from '../../../data/FileExportDataRaw';
import { saveAs } from 'file-saver';
import { isEmpty } from 'lodash-es';
import { PsDelegueService } from '../../ps/service/ps-delegue.service';
import { PsDelegueDataRaw } from '../../../data/PsDelegueDataRaw';

@Component({
	selector: 'careweb-app-loi',
	templateUrl: './loi.component.html',
	styleUrls: ['./loi.component.scss']
})
export class LoiComponent implements OnInit, OnDestroy {
	gridHelper: AgGridHelper;
	paginationSize: number;
	psDataRaw: PsDataRaw;
	rangLoi;
	currentOct;
	selectedPs;
	filters = new PsFilterRaw();
	lastLoiRang: number;
	@ViewChild('loiForm')
	form: NgForm;
	psDelegueListFilter = new Array<PsDelegueDataRaw>();
	numeroPs;
	constructor(
		private psSvc: PsService,
		private octSvc: OctService,
		private authSvc: AuthService,
		private loiSvc: LoiService,
		private toastr: ToastrService,
		private octPsSvc: OctPsService,
		private psDelegueSVC: PsDelegueService
	) {
		this.gridHelper = new AgGridHelper();
		this.psDataRaw = new PsDataRaw();
	}

	ngOnInit() {
		// Set background style
		const body = document.body;
		body.className = 'app loiPageBackground';
		// Get current oct
		this.getCurrentOct();
		this.getNumeroPs();
		// Apply numero Ps supervised on the filter if exist
		var numeroPsSupervisedLocalStorage = localStorage.getItem('supervised_ps');
		if (!isEmpty(numeroPsSupervisedLocalStorage)) {
			if (numeroPsSupervisedLocalStorage === '-1') {
			}
			else {
				numeroPsSupervisedLocalStorage = numeroPsSupervisedLocalStorage.split(';')[0];
					this.numeroPs=numeroPsSupervisedLocalStorage;
			}
		}
		// Init grid
		this.gridHelper.gridOptions = {
			columnDefs: this.getColumnDefs(),
			defaultColDef: {
				suppressMenu: true,
				resizable: true
			},
			components: {
				customInputFilterComponent: this.getInputFloatingFilterComponent(),
				checkLoiComponent: this.checkLoiComponent
			},
			context: { parentComponent: this },
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 5,
			paginationPageSize: 5,
			infiniteInitialRowCount: 1,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			localeText: this.gridHelper.getLocaleText(),
			onGridReady: (params) => this.onGridReady(params),
			// onGridSizeChanged: (params) => this.onGridSizeChanged(params),
			onFilterChanged: (params) => { }
		};
		this.paginationSize = this.gridHelper.paginationPageSize;

		// Get last rang Loi
		this.getLastLoiRang();

		if (!this.isAuthorized()) {
			this.loadComboBoxFilterNumeroPS_Supervise();
		}
	}
	
	loadComboBoxFilterNumeroPS_Supervise() {
		const userConnected = JSON.parse(localStorage.getItem('careweb_user'));
		if (!isEmpty(userConnected)) {
			if (!isNullOrUndefined(userConnected.idPs)) {
				this.psDelegueSVC.getPsDelegueByIdPs(userConnected.idPs).subscribe((data) => {
					if (!isNullOrUndefined(data) && !isEmpty(data)) {
						this.psDelegueListFilter = data;
						const psCurrent = new PsDelegueDataRaw();
						psCurrent.idPsDelegue = this.psDelegueListFilter[0].idPsAssocie;
						psCurrent.numeroPsDelegue = this.psDelegueListFilter[0].numeroPsAssocie;
						psCurrent.nomPsDelegue = this.psDelegueListFilter[0].nomPsAssocie;
						this.psDelegueListFilter.push(psCurrent);
					}
				});
			}
		}
	}

	existGroupNumeroPS() {
		return this.isAuthorized() || this.psDelegueListFilter.length !== 0;
	}

	getCurrentOct() {
		// Get current oct
		this.octSvc.currentOctSubject.subscribe((value) => {
			this.currentOct = value;
		});
	}
	getNumeroPs(){
		this.numeroPs = !isNaN(parseInt(localStorage.getItem("numeroPs"))) ? parseInt(localStorage.getItem("numeroPs")) : '';
	}

	downloadLoi() {
		this.loiSvc.downloadLastLoi().subscribe((response: FileExport) => {
			saveAs(response.file, response.fileName);
		});
	}

	checkLoiComponent(params) {
		return (
			"<input type='radio' name='selectedPs' style='width:13px; height: 13px;' [value]=" + params.value + ' />'
		);
	}

	onGridSizeChanged(params) {
		this.gridHelper.gridApi.sizeColumnsToFit();
	}

	onGridReady(params) {
		const ctx = this;
		this.gridHelper.gridApi = params.api;
		this.gridHelper.gridColumnApi = params.columnApi;
		this.gridHelper.gridApi.sizeColumnsToFit();
		const dataSource: IServerSideDatasource = {
			getRows: function (paramsRows: IServerSideGetRowsParams) {
				const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
				// Connect By Admin Or Hotline
				if (ctx.isAuthorized()) {
					ctx.psSvc
						.searchByIdOct(
							{
								page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
								size: ctx.gridHelper.gridApi.paginationGetPageSize(),
								idOct: ctx.currentOct
									? ctx.currentOct.idOct
									: JSON.parse(localStorage.getItem('octCurrent')).idOct,
								sorts
							},
							ctx.filters
						)
						.subscribe(
							(data) => {
								const rowCount = data.totalElements;
								ctx.gridHelper.manageNoRowsOverlay(rowCount);
								paramsRows.success({"rowData": data.content, "rowCount": rowCount});
							},
							() => paramsRows.fail()
						);
				} else {
					// Connect By PS
					const userConnected = JSON.parse(localStorage.getItem('careweb_user'));
					// this.psDataRaw = new PsDataRaw();
					ctx.psSvc
						.searchByIdPsWithDelegues(
							{
								page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
								size: ctx.gridHelper.gridApi.paginationGetPageSize(),
								idPs: userConnected.idPs,
								sorts
							},
							ctx.filters
						)
						.subscribe(
							(data) => {
		
								const rowCount = data.totalElements;
								ctx.gridHelper.manageNoRowsOverlay(rowCount);
								paramsRows.success({"rowData": data.content, "rowCount": rowCount});
													
							},
							() => paramsRows.fail()
						);
				}
			}
		};

		this.gridHelper.gridApi.setServerSideDatasource(dataSource);
		this.gridHelper.gridApi.sizeColumnsToFit();
		this.gridHelper.gridApi.hidePopupMenu();
	}

	private getColumnDefs() {
		return [
			{
				headerName: '',
				field: 'numeroAdeli',
				maxWidth: 100,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				onCellClicked: (params) => this.onCellClicked(params),
				cellRenderer: (params) => this.checkLoiComponent(params)
				/* 				cellRendererParams: {
					checkboxName: ctx.selectedPs
				}, */
			},
			{
				headerName: 'N° PS',
				field: 'numeroAdeli',
				minWidth: 150,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true, debounceMs: 3000 },
				onCellClicked: (params) => this.onCellClicked(params)
			},
			{
				headerName: 'Nom',
				field: 'nom',
				minWidth: 150,
				lockVisible: true,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true, debounceMs: 3000 },
				onCellClicked: (params) => this.onCellClicked(params)
			},
			{
				headerName: 'Prénom',
				field: 'prenom',
				minWidth: 150,
				lockVisible: true,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true, debounceMs: 3000 },
				onCellClicked: (params) => this.onCellClicked(params)
			},
			{
				headerName: 'Etablissement',
				field: 'raisonSocialeEtablissement',
				minWidth: 150,
				lockVisible: true,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true, debounceMs: 3000 },
				onCellClicked: (params) => this.onCellClicked(params)
			},
			{
				headerName: 'CP',
				field: 'codepostalEtablissment',
				minWidth: 150,
				lockVisible: true,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true, debounceMs: 3000 },
				onCellClicked: (params) => this.onCellClicked(params)
			},
			{
				headerName: 'Ville',
				field: 'villeEtablissment',
				minWidth: 150,
				lockVisible: true,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true, debounceMs: 3000 },
				onCellClicked: (params) => this.onCellClicked(params)
			}
		];
	}

	onCellClicked(params) {
		this.selectedPs = params.data;
	}

	getInputFloatingFilterComponent() {
		const ctx = this;
		function InputFloatingFilter() { }

		InputFloatingFilter.prototype.init = function (params) {
			this.eGui = document.createElement('div');
			this.eGui.setAttribute('class', 'ag-input-wrapper');
			switch (params.filterParams.colDef.field) {
				case 'numeroAdeli':
					this.eGui.innerHTML =
						`<input type='number' class='customFilterInput' value='${ctx.numeroPs}' style='width:100%; box-shadow: none;' onKeyPress='return this.value.length!==9 && event.charCode >= 48 && event.charCode <= 57' pattern='^[0-9]' min='0' step='1' max='999999999' oninput='this.value'/>`;
					break;
				case 'codepostalEtablissment':
					this.eGui.innerHTML =
						'<input type="number" class="customFilterInput" style="width:100%; box-shadow: none;" onKeyPress="return this.value.length!==5 && event.charCode >= 48 && event.charCode <= 57" pattern="^[0-9]" min="0" step="1" max="99999"/>';
					break;
				case 'nom':
				case 'prenom':
					this.eGui.innerHTML =
						"<input type='text' class='customFilterInput' style='width:100%; box-shadow: none;' onKeyPress='if(this.value.length==255) return false;'/>";
					break;
				case 'raisonSocialeEtablissement':
				case 'villeEtablissment':
					this.eGui.innerHTML =
						"<input type='text' class='customFilterInput' style='width:100%; box-shadow: none;' onKeyPress='if(this.value.length==50) return false;'/>";
					break;
				default:
					break;
			}

			this.currentValue = null;
			this.eFilterInput = this.eGui.querySelector('input');
			this.eFilterInput.style.color = params.color;
			const that = this;
			if (params.filterParams.colDef.field === 'numeroAdeli' && that.eFilterInput.value != '') {
				that.currentValue = that.eFilterInput.value;
				ctx.filters[params.filterParams.colDef.field] = that.eFilterInput.value;
				params.parentFilterInstance(function (instance) {
					instance.onFloatingFilterChanged('equals', that.currentValue);
				});
			}
			function onInputChanged() {
				const filterField = params.filterParams.colDef.field;
				if (that.eFilterInput.value === '') {
					// Remove the filter
					delete ctx.filters[filterField];
					params.parentFilterInstance(function (instance) {
						instance.onFloatingFilterChanged(null, null);
					});
					return;
				}

				that.currentValue = that.eFilterInput.value;
				if (that.eFilterInput.value.length >= 3) {
					if (that.currentValue !== '') {
						ctx.filters[filterField] = that.eFilterInput.value;
					}
					params.parentFilterInstance(function (instance) {
						instance.onFloatingFilterChanged('equals', that.currentValue);
					});
				}
			}
			this.eFilterInput.addEventListener('input', onInputChanged);
		};

		InputFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
			if (!parentModel) {
				this.eFilterInput.value = '';
				this.currentValue = null;
			} else {
				this.eFilterInput.value = parentModel.filter + '';
				this.currentValue = parentModel.filter;
			}
		};

		InputFloatingFilter.prototype.getGui = function () {
			return this.eGui;
		};

		return InputFloatingFilter;
	}

	isAuthorized() {
		return this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
	}

	submitLoiDownload() {
		if (isNullOrUndefined(this.rangLoi)) {
			this.toastr.error('Veuillez saisir un rang LOI.', 'LOI');
			return false;
		} else if (this.rangLoi.toString().length < 4) {
			this.toastr.error('Le rang LOI est incorrect.', 'LOI');
			return false;
		}

		if (this.isAuthorized()) {
			// The user is and admin or hotline
			if (!isNullOrUndefined(this.selectedPs)) {
				// check if PS has an email
				if (!isNullOrUndefined(this.selectedPs.emailEtablissement)) {
					this.toastr.error("Aucun email n'est renseigné pour ce compte", 'LOI');
					return false;
				} else {
					const criteria = {
						type: 2, // (Dloi)
						idOct:
							this.currentOct === undefined
								? this.currentOct.idOct
								: JSON.parse(localStorage.getItem('octCurrent')).idOct,
						dateDemandeDiffusion: new Date(),
						idPs: this.selectedPs.idPs,
						rang: this.rangLoi,
						statutEnvoi: false
					};
					this.loiSvc.sendLoiToPs(criteria).subscribe((data) => {
						if (data) {
							this.toastr.success('Le fichier sera envoyé dans les 10 minutes.', 'LOI');
						}
					});
				}
			} else {
				this.toastr.error("Aucun PS n'a été choisi", 'LOI');
				return false;
			}
		} else {
			this.octPsSvc.getOctPsByIdPs(Number(this.authSvc.currentUserValue.idPs)).subscribe((data) => {
				const criteria = {
					type: 2, // (Dloi)
					idOct: data[0].idOct,
					dateDemandeDiffusion: new Date(),
					idPs: this.authSvc.currentUserValue.idPs,
					rang: this.rangLoi,
					statutEnvoi: false
				};

				this.loiSvc.sendLoiToPs(criteria).subscribe((data) => {
					if (data) {
						this.toastr.success('Le fichier sera envoyé dans les 10 minutes.', 'LOI');
					}
				});
			});
		}
	}

	getLastLoiRang() {
		this.loiSvc.getLastLoiRang().subscribe((data) => {
			this.lastLoiRang = data;
		});
	}

	sendRecieveLoi() {
		if (this.isAuthorized()) {
			// The user is and admin or hotline
			if (!isNullOrUndefined(this.selectedPs)) {
				// check if PS has an email
				if (!isNullOrUndefined(this.selectedPs.emailEtablissement)) {
					this.toastr.error("Aucun email n'est renseigné pour ce compte.", 'LOI');
					return false;
				} else {
					const criteria = {
						type: 1, // (loi)
						idOct:
							this.currentOct === undefined
								? this.currentOct.idOct
								: JSON.parse(localStorage.getItem('octCurrent')).idOct,
						dateDemandeDiffusion: new Date(),
						idPs: this.selectedPs.idPs,
						statutEnvoi: false,
						rang: this.lastLoiRang
					};
					this.loiSvc.sendLoiToPs(criteria).subscribe((data) => {
						if (data) {
							this.toastr.success('Le fichier sera envoyé dans les 10 minutes.', 'LOI');
						}
					});
				}
			} else {
				this.toastr.error("Aucun PS n'a été choisi.", 'LOI');
				return false;
			}
		} else {
			this.octPsSvc.getOctPsByIdPs(Number(this.authSvc.currentUserValue.idPs)).subscribe((data) => {
				const criteria = {
					type: 1, // (loi)
					idOct: data[0].idOct,
					dateDemandeDiffusion: new Date(),
					idPs: this.authSvc.currentUserValue.idPs,
					statutEnvoi: false,
					rang: this.lastLoiRang
				};

				this.loiSvc.sendLoiToPs(criteria).subscribe((data) => {
					if (data) {
						this.toastr.success('Le fichier sera envoyé dans les 10 minutes.', 'LOI');
					}
				});
			});
		}
	}

	ngOnDestroy() {
		const body = document.body;
		body.className = 'app';
	}
	
	  
	  
}
