import { VersionDataRaw } from './versionDataRaw';

export class VersRgptTzeroTpsDataRaw {
	idRgpt: number;
	idTypeZero: number;
	idTypePs: number;
	idVersion: number;
	idNorme: number;
	regroupement: string;
	typeEmetteur: string;
	typeDestinataire: string;
	typePs: string;
	numDestinataire: string;
	version: VersionDataRaw;
	oct: string;
	duo: number;
	idOct: number;
	norme: string;
}
