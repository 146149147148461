import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { AuthService } from '../../core/services/auth.service';

@Injectable()
export class PsIsAuthAccessGuard implements CanActivate {
	constructor(private authSvc: AuthService, private router: Router) {}

	canActivate() {

		let canActive = this.isAuthorized();

		if (!isNullOrUndefined(canActive) && !canActive) {
			this.router.navigate([ '/dashboard' ]);
		}

		return canActive;
	}

	isAuthorized() {
		return this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
	}
}
