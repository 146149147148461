<div class="pagination-panel">
    <div class="pagination-container">
        <div class="pagination-control">
            <button (click)="pageFirstPageClick()" [disabled]="cantPageBackward">
                <div class="first-triangle">
                    <div class="first-bar"></div>
                </div>
            </button>
            <button (click)="pagePreviousPageClick()" [disabled]="cantPageBackward">
                <div class="first-triangle prev-triangle"></div>
            </button>
            <input type="number" min="1" max="{{totalPages}}" [ngModel]="currentPage"
                (keypress)="paginationKeyPressed($event)"
                (input)="paginationKeyPressed($event)"
                (ngModelChange)="changePage($event)" #pagination/>
            <span class="pagination-max-pages-number" [hidden]="totalPages &lt; 1"><abbr>/</abbr>
                {{ totalPages }}</span>
            <button (click)="pageNextPageClick()" id="btLast" [disabled]="cantPageForward">
                <div class="last-triangle next-triangle"></div>
            </button>
            <button (click)="pageLastPageClick()" [disabled]="cantPageForward">
                <div class="last-triangle">
                    <div class="last-bar"></div>
                </div>
            </button>
        </div>
    </div>
</div>