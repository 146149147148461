import { DictRappRule } from './../../../data/dictRappRule';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DictRuleRappService } from '../dictRuleRapp.service';

@Component({
    selector: 'careweb-dict-rule-modal',
    templateUrl: './dict-rule-modal.component.html',
    styleUrls: ['./dict-rule-modal.component.scss']
})
export class DictRuleModalComponent implements OnInit {
    @Input() title: string;
    @Input() dictRule: any;
    @Input() isDeleteModale: any;
    dictRuleForm: UntypedFormGroup;
    isDictRuleFormSubmitted = false;
    rule: DictRappRule = {};
    oldRuleKeyLabel = '';

    constructor(private modalRef: BsModalRef, private fb: UntypedFormBuilder, private dictRuleRapp: DictRuleRappService) {}

    ngOnInit() {
        this.initSolderForm();
    }

    initSolderForm() {
        this.dictRuleForm = this.fb.group({
            'keyLabel': [this.dictRule ? this.dictRule.keyLabel : '', [Validators.required]],
            'organisme': [this.dictRule ? this.dictRule.organismeLabel : '', [Validators.required]],
            'codeRegle': [this.dictRule ? '' + this.dictRule.codeRegle : '', [Validators.required]]
        });
    }

    closeModal() {
        if (this.oldRuleKeyLabel !== '') {
            this.rule = {};
        }
        this.modalRef.hide();
    }

    onSubmit() {
        this.isDictRuleFormSubmitted = true;
        if (this.dictRuleForm.invalid) {
            return;
        }
        this.rule.keyLabel = this.dictRuleForm.get('keyLabel').value;
        this.rule.organismeLabel = this.dictRuleForm.get('organisme').value;
        this.rule.codeRegle = this.dictRuleForm.get('codeRegle').value;

        if (this.dictRule) {
            //update 
            this.rule.idRule = this.dictRule.idRule;
            if (this.rule.keyLabel !== this.dictRule.keyLabel) {
                this.dictRuleRapp.isRuleExists(this.rule.keyLabel).subscribe(isRuleExists => {
                    if (!isRuleExists) {
                        this.modalRef.content.rule = this.rule;
                        this.closeModal();
                    } else {
                        this.oldRuleKeyLabel = this.rule.keyLabel?.valueOf() || '';
                    }
                });
            } else {
                this.modalRef.content.rule = this.rule;
                this.closeModal();
            }
        } else {
            //ajout
            this.dictRuleRapp.isRuleExists(this.rule.keyLabel).subscribe(isRuleExists => {
                if (!isRuleExists) {
                    this.modalRef.content.rule = this.rule;
                    this.oldRuleKeyLabel = '';
                    this.closeModal();
                } else {
                    this.oldRuleKeyLabel = this.rule.keyLabel?.valueOf() || '';
                }
            });
        }

        if (this.isDeleteModale) {
            this.modalRef.content.isToDelete = true;
            this.closeModal();
        }
    }

    get dictRuleFormFields() {
        return this.dictRuleForm.controls;
    }

    resetOldRuleKeyLabel() {
        this.oldRuleKeyLabel = '';
        this.rule = {};
    }

}
