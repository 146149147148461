export class LogsRapproDataRaw{
    executionID?: number;
    dateDemarrage?: Date;
    dateDemarrageExacte?: any;
    dateDemarrageFrom: any;
    dateDemarrageTo: any;
    duree?: string;
    idPs?: number;
    vrtAnalyses?: number;
    vrtRapproches?: number;
    vrtEtudier?: number;
    status?: string;
    modeExecution?: string;
}