import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterService } from '../../core/services/filter-service';
import { SelService } from './sel.service';
import { CONF } from '../../core/constants';

@Injectable({
	providedIn: 'root'
})
export class TracabilityService extends FilterService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/cis';
	flux: any;
	constructor(private httpClient: HttpClient, private selService: SelService) {
		super();
	}

	// API #11 Consultation de la traçabilité
	public getTracabilityService(): Observable<any> {
		const data = new Observable((observer) => {
			this.selService
				.getSelToken()
				.then(() => {
					let url = this.baseUrl + '/traceabilityServices';
					this.httpClient.get<any>(url).subscribe(
						(data) => {
							observer.next(data);
							observer.complete();
						},
						(err) => {
							observer.error(err);
						}
					);
				})
				.catch((err) => {

				});
		});
		return data;
	}

	// API #11bis Consultation de la traçabilité
	public getTracability(criteriat?: any): Observable<any> {
		const data = new Observable((observer) => {
			this.selService
				.getSelToken()
				.then(() => {
					let url = this.baseUrl + '/traceability';
					this.httpClient.post<any>(url, criteriat).subscribe(
						(data) => {
							observer.next(data);
							observer.complete();
						},
						(err) => {
							observer.error(err);
						}
					);
				})
				.catch((err) => {

				});
		});
		return data;
	}

	getFluxDetails() {
		return this.flux;
	}

	setFluxDetails(flux) {
		this.flux = flux;
	}
}
