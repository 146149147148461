import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class VisibilityService implements OnDestroy {
    private visibilityChangeSubject = new Subject<boolean>();
    visibilityChange$ = this.visibilityChangeSubject.asObservable();

    private tabIdentifier: string = this.generateUniqueId();
    private channel: BroadcastChannel;

    constructor() {
        this.initializeVisibilityListener();
        this.setupBroadcastChannel();
    }

    private initializeVisibilityListener() {
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'hidden') {
                this.visibilityChangeSubject.next(false);  // L'onglet est caché
            } else {
                this.visibilityChangeSubject.next(true);   // L'onglet est visible
            }
        });
    }

    private setupBroadcastChannel() {
        this.channel = new BroadcastChannel('app-channel');
        this.channel.onmessage = (event) => {
            if (event.data && event.data.type === 'TAB_CHECK' && event.data.id !== this.tabIdentifier) {
            }
        };

        // Vérifier l'état des autres onglets
        window.addEventListener('storage', (event) => {
            if (event.key === 'tab-check') {
                if (event.newValue === this.tabIdentifier) {
                }
            }
        });
    }

    // Envoyer un message à d'autres onglets
    public sendTabCheck() {
        localStorage.setItem('tab-check', this.tabIdentifier);
        setTimeout(() => localStorage.removeItem('tab-check'), 100);
    }

    // Générer un identifiant unique pour l'onglet
    private generateUniqueId(): string {
        return 'tab-' + Math.random().toString(36).substr(2, 9);
    }

    ngOnDestroy(): void {
        this.channel.close();
        // Supprimer l'écouteur de visibilité
        document.removeEventListener('visibilitychange', () => {});
    }
}
