import {Component} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-ps-modal',
  templateUrl: './ps-modal.component.html'
})
export class PsModalComponent {
  nbrInactifPs: number = 1;
  constructor(private modalRef: BsModalRef) { }
  closeModal(){
    this.modalRef.hide();
  }
}
