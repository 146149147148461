<div class="modal modal-show fade show" id="modalPsEditUser" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel">
    <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
        <div class="modal-dialog modal-xxl" role="document">
            <div class="modal-content">
                <div class="inmodal">

                    <div class="modal-header pb-1">
                        <h3 class="modal-title">{{getTitle()}}</h3>
                        <button class="close" name="btn_display_filter" data-dismiss="modal" type="button"
                            title="Fermer" (click)="closeModal()"><span aria-hidden="true">×</span></button>
                    </div>
                    <div class="modal-body pb-0">
                        <!-- NAV TABS -->
                        <div class="form-block">
                            <div class="row">
                                <div class="col">
                                    <h3 class="title-border">
                                        <div class="row">
                                            <div class="col-auto me-auto">Informations du compte</div>
                                            <div class="col-auto"></div>
                                        </div>
                                    </h3>
                                </div>
                            </div>

                            <fieldset class="container-fluid">
                                <div class="row">
                                    <div class="col-sm-2 col-form-label">Type</div>
                                    <div class="col-sm-4 form-group">
                                        <input type="text" formControlName="typeUser" class="form-control" readonly />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-2 col-form-label">Civilité</div>
                                    <div class="col-sm-4 form-group">
                                        <ng-select formControlName="typeCivilite"
                                            style="width:100%; height:calc(1.5em + .75rem + 2px);" bindLabel="name"
                                            [clearable]="false">
                                            <ng-option *ngFor="let civility of civiliteArray" [value]="civility">
                                                {{civility.viewValue}}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                    <div class="col-sm-2 col-form-label">Fonction</div>
                                    <div class="col-sm-4 form-group">
                                        <input type="text" class="form-control" formControlName="fonction"
                                            onpaste="if(this.value.length==50) return false;"
                                            onKeyPress="if(this.value.length==50) return false;" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-2 col-form-label">Nom</div>
                                    <div class="col-sm-4 form-group">
                                        <input type="text" class="form-control" formControlName="nom"
                                            onpaste="if(this.value.length==50) return false;"
                                            onKeyPress="if(this.value.length==50) return false;" />
                                    </div>
                                    <div class="col-sm-2 col-form-label">Prénom</div>
                                    <div class="col-sm-4 form-group">
                                        <input type="text" class="form-control" formControlName="prenom"
                                            onpaste="if(this.value.length==50) return false;"
                                            onKeyPress="if(this.value.length==50) return false;" /></div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-2 col-form-label">Téléphone</div>
                                    <div class="col-sm-4 form-group">
                                        <input type="text" class="form-control" formControlName="telephone"
                                            oninput="this.value=this.value.replace(/[^\d]/,'');"
                                            onpaste="if(this.value.length==16) return false;"
                                            onKeyPress="if(this.value.length==16) return false;" />
                                    </div>
                                    <div class="col-sm-2 col-form-label">Tél. Mobile</div>
                                    <div class="col-sm-4 form-group">
                                        <input type="text" class="form-control" formControlName="mobile"
                                            oninput="this.value=this.value.replace(/[^\d]/,'');"
                                            onpaste="if(this.value.length==16) return false;"
                                            onKeyPress="if(this.value.length==16) return false;" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-2 col-form-label">Fax</div>
                                    <div class="col-sm-4 form-group">
                                        <input type="text" class="form-control" formControlName="fax"
                                            oninput="this.value=this.value.replace(/[^\d]/,'');"
                                            onpaste="if(this.value.length==16) return false;"
                                            onKeyPress="if(this.value.length==16) return false;" />
                                    </div>
                                    <div class="col-sm-2 col-form-label">Email</div>
                                    <div class="col-sm-4 form-group">
                                        <input type="text" class="form-control" formControlName="email"
                                            onpaste="if(this.value.length==100) return false;"
                                            onKeyPress="if(this.value.length==100) return false;"
                                            [ngClass]="{ 'is-invalid': submitted && formFields.email.errors }" />
                                        <div *ngIf="submitted && formFields.email.errors" class="invalid-feedback">
                                            <div *ngIf="formFields.email.errors.validmail">
                                                Cet email est déjà utilisé
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-2 col-form-label">Actif</div>
                                    <div class="col-sm-4 form-group">
                                        <ng-select formControlName="actif"
                                            style="width:100%; height:calc(1.5em + .75rem + 2px);" bindLabel="name"
                                            [clearable]="false">
                                            <ng-option *ngFor="let actif of actifArray" [value]="actif">
                                                {{actif.viewValue}}
                                            </ng-option>
                                        </ng-select>
                                    </div>

                                    <div class="col-sm-2 col-form-label">Rappro. visible</div>
                                    <div class="col-sm-4 form-group">
                                        <ng-select formControlName="rapproActif"
                                            style="width:100%; height:calc(1.5em + .75rem + 2px);" bindLabel="name" [clearable]="false">
                                            <ng-option *ngFor="let rapproActif of actifArray" [value]="rapproActif">
                                                {{rapproActif.viewValue}}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-2 col-form-label">Commentaire</div>
                                    <div class="col-sm-10 form-group">
                                        <textarea class="form-control-input" rows="3" formControlName="commentaire"
                                            rows="3" style="  resize:horizontal;  resize: vertical;" autosize
                                            onpaste="if(this.value.length==100) return false;"
                                            onKeyPress="if(this.value.length==100) return false;">
                                        </textarea>
                                    </div>
                                </div>

                                <!-- TOOLBAR -->
                                <div class="button-bar control">
                                    <button type="button" class="btn btn-primary float-left"
                                        (click)="onResendInvitationMail()" *ngIf="user.email && !user.hasPasswordHash">Renvoyer l'invitation</button>
                                    <button type="button" class="btn btn-primary float-left"
                                        (click)="onReinitializePassword()">Réinitialiser le mot de passe</button>
                                    <button type="button" class="btn btn-primary float-left" *ngIf="user.blocked" (click)="unblockAccount()">Débloquer</button>
                                    <button type="button" class="btn btn-default" (click)="reset()"> Annuler </button>
                                    <button type="submit" class="btn btn-primary" [disabled]="!userForm.dirty"> Enregistrer </button>
                                </div>

                            </fieldset>
                        </div>
                    </div>
                    <div class="modal-footer">

                    </div>

                </div>
            </div>
        </div>
    </form>
</div>