<div class="modal modal-show fade show" id="modalPsEtablissement" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel">
    <div class="modal-dialog modal-xxl" role="document">
        <div class="modal-content">
            <div class="inmodal">
                <div class="modal-header pb-1">
                    <h3 class="modal-title">Rattacher un abonnement</h3>
                    <button class="close" name="btn_display_filter" data-dismiss="modal" type="button" title="Fermer"
                        (click)="closeModal()"><span aria-hidden="true">×</span></button>
                </div>
                <div class="modal-body pb-0">
                    <form [formGroup]="accountForm" (ngSubmit)="onSubmit()">
                        <div class="tab-pane">
                            <div class="form-block">
                                <fieldset class="container-fluid">
                                    <div class="row">
                                        <div class="col-sm-2 col-form-label">Abonnement
                                        </div>
                                        <div class="col-sm-4 form-group">
                                            <ng-select formControlName="abonnement" bindLabel="name"
                                                style="width:100%; height:calc(1.5em + .75rem + 2px)"
                                                placeholder="Veuillez sélectionner un abonnement"
                                                [ngClass]="{ 'is-invalid': accountFields.abonnement.touched && accountFields.abonnement.errors }"
                                                [searchable]="false" [clearable]="true"
                                                (change)="onAbonnementSelected($event)" required>
                                                <ng-option *ngFor="let abonnement of listAbonnements"
                                                    [value]="abonnement.id">{{'[ '+abonnement.bankName+' ] '}}{{'( '+abonnement.ebicsUserId+' | '+abonnement.ebicsHostId+' )'}}</ng-option>
                                            </ng-select>
                                            <div *ngIf="accountFields.abonnement.touched && accountFields.abonnement.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="accountFields.abonnement.errors"> Choisissez
                                                    un abonnement.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="showIban">
                                        <div class="col-sm-2 col-form-label">IBAN *</div>
                                        <div class="col-sm-10 form-group">
                                            <input type="text" class="form-control" formControlName="IBAN" required appIbanClean 
                                            (keydown)="onKeyDown($event)" />
                                            <div *ngIf="isErrorVerif"><small class="text-danger">{{errorVerifMsg}}</small> </div>
                                        </div>
                                    </div>
                                    <div class="button-bar control">
                                        <button type="button" class="btn btn-default" (click)="closeModal()"> Annuler
                                        </button>
                                        <button type="submit" class="btn btn-primary" (click)="onSubmit"> Ajouter
                                        </button>
                                    </div>

                                </fieldset>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                </div>
            </div>
        </div>
    </div>

</div>