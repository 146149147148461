<div class="x-large-careweb-container">
  <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
    <section class="form-content m-3" *ngIf="!emailSent">
      <div class="row form-title">
        <h2 class="col-md-6">Contacter le support</h2>
      </div>
      <div class="row">
        <div class="col-sm-2 col-form-label">Motif de contact</div>
        <div class="col-sm-9 form-group">
          <input type="text" class="form-control" name="motif" formControlName="motif"
          [ngClass]="{ 'is-invalid': submitted && formFields.motif.errors }" required />
          <div *ngIf="submitted && formFields.motif.errors" class="invalid-feedback">
            <div *ngIf="formFields.motif.errors.required || formFields.motif.errors.whitespaces">
              Ce champ est obligatoire</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 col-form-label">Description</div>
        <div class="col-sm-9 form-group">
          <textarea class="form-control-input" rows="20" name="description" 
          [ngClass]="{ 'is-invalid': submitted && formFields.description.errors }" formControlName="description" required>
          </textarea>
          <div *ngIf="submitted && formFields.description.errors" class="invalid-feedback">
            <div *ngIf="formFields.description.errors.required || formFields.description.errors.whitespaces">
              Ce champ est obligatoire</div>
          </div>
        </div>
      </div>
      <div class="control row">
        <div class="button-bar col-11">
          <button type="button" class="btn btn-default me-3" (click)="cancel()">
            Annuler
          </button>
          <!-- Bouton -->
          <button type="submit" class="btn btn-primary">
            Valider
          </button>
        </div>
      </div>
    </section>
    <section class="form-content" *ngIf="emailSent">
      <div class="msgEmailSent">
        Nous vous informons que votre demande a été prise en compte et qu'une réponse vous sera apportée dans les
          meilleurs délais
      </div>
    </section>
  </form>
</div>