export class LotsFilterRaw {
	numeroPs: String;
	numLotExacte: String;
	numLotFrom: String;
	numLotTo: String;
	securise: Boolean;
	secure: Boolean;
	notSecure: Boolean;
	tiersPayant: Boolean;
	withTiersPayant: Boolean;
	withoutTiersPayant: Boolean;
	presenceRsp: Number;
	rsp: Boolean;
	sansRsp: Boolean;
	presenceArl: String;
	dateEnvoieFrom: Date;
	dateEnvoieTo: Date;
	dateEnvoieExacte: Date;
	sigleOrganisme: String;
	organismeDestinataire: String;
	libelleOrganisme: String;
	numeroPScomboBox: String;
	signeArlFilter: String
	constructor(data = null) {
		Object.assign(this, data);
	}
}
