export class EditeurDataRaw {
	idEditeur: number;
	raisonSociale: string;
	telephone: string;
	fax: string;
	codePostal: string;
	ville: string;
	adresse1: string;
	adresse2: string;
	email: string;
	url: string;
}
