import { EditeurDataRaw } from './editeurDataRaw';

export class LogicielDataRaw {
	idLogiciel: number;
	idEditeur: number;
	libelle: String;
	systeme: String;
	version: String;
	editeur: EditeurDataRaw;
}
