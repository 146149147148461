import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ScrollTopService } from 'src/main/webapp/src/app/core/utilities/scroll-top.service';
import { AccountAssociationDataRaw } from 'src/main/webapp/src/app/data/account-association-data-raw';
import { EbicsService } from 'src/main/webapp/src/app/pages/ps/service/ebics.service';
const ibanPattern: RegExp = /^[A-Za-z]{2}[0-9]{2}[A-Za-z0-9]{11,30}$/;

@Component({
	selector: 'app-add-account-ebics',
	templateUrl: './add-account-ebics.component.html',
	styleUrls: ['./add-account-ebics.component.scss']
})
export class AddAccountEbicsComponent implements OnInit {
	allAccounts: any = null;
	@Input() idPs: Number;
	active = 1;
	submitted: Boolean = false;
	accountForm: UntypedFormGroup;
	listAbonnements: any;
	showIban: boolean = false;
	currentAssociation: AccountAssociationDataRaw = new AccountAssociationDataRaw();
	ibanExistMsgError: string = "L'IBAN que vous avez saisi est déjà utilisé pour ce PS.";
	isErrorVerif: boolean = false;
	errorVerifMsg: string;
	constructor(private fb: UntypedFormBuilder,
		private modalRef: BsModalRef,
		private toastr: ToastrService,
		private scrollTopService: ScrollTopService,
		private ebicsService: EbicsService) { }

	ngOnInit() {
		this.scrollTopService.setScrollTop();
		this.validForm();
		this.ebicsService
			.getAllAbonnementsWithoutFilter()
			.subscribe(
				(data) => {
					this.listAbonnements = data;
				},
				() => this.listAbonnements = null
			);

		this.ebicsService
			.getAllPsEbics()
			.subscribe(
				(data) => {
					this.allAccounts = data;
				},
				() => this.allAccounts = null
			);

	}
	get accountFields() {
		return this.accountForm.controls;
	}
	onAbonnementSelected(event: any) {
		this.showIban = true;

	}
	validForm() {
		this.accountForm = this.fb.group({
			abonnement: [null, [Validators.required]],
			IBAN: ['']
		});
	}
	onKeyDown(event: any) {
		this.isErrorVerif = false;
		this.errorVerifMsg = "";

	}
	onSubmit() {
		this.submitted = true;
		if (this.accountFields.IBAN.errors?.required) {
			this.isErrorVerif = true;
			this.errorVerifMsg = "Ce champ est obligatoire"
			return;
		}
		if (this.isIbanOnError()) {
			this.isErrorVerif = true;
			this.errorVerifMsg = "Format de l'IBAN est incorrect."
			return;
		}
		if (this.verifyIbanExist()) {
			this.isErrorVerif = true;
			this.errorVerifMsg = this.ibanExistMsgError;
			return;
		}
		if (this.accountForm.invalid) {
			return;
		}

		this.currentAssociation.idAbonnement = this.accountForm.value.abonnement;
		this.currentAssociation.idPs = this.idPs;
		this.currentAssociation.iban = this.accountForm.value.IBAN;
		this.currentAssociation.actif = true;

		this.ebicsService.attachPsWithEbicsAccount(this.currentAssociation).subscribe(
			(data) => {
				if (data) {
					this.toastr.success("Le compte EBICS a été associé avec succès");
					this.modalRef.hide();
				}
			},
			(error) => {
				this.toastr.error(error);
			}
		);
	}

	closeModal() {
		this.submitted = false;
		this.accountForm.reset();
		this.modalRef.hide();
	}

	get formFields() {
		return this.accountForm.controls;
	}

	get formFieldsValue() {
		return this.accountForm.value;
	}
	isIbanOnError() {
		//const ibanControl = this.accountForm.get('IBAN');

		this.accountForm.value.IBAN = this.accountForm.value.IBAN.replace(/\s/g, '');
		if (this.accountForm.value.IBAN == "") {
			return false;
		}
		const isInvalid = !ibanPattern.test(this.accountForm.value.IBAN);
		// Mettre à jour la visibilité du message d'erreur
		//this.ibanFormatErrorVisible = isInvalid && (ibanControl.dirty || ibanControl.touched);
		return isInvalid;


	}
	verifyIbanExist() {
		const ibanToCheck = this.accountForm.value.IBAN.toLowerCase(); // ou toUpperCase()
		// Recherche de l'objet correspondant à l'IBAN spécifié
		const account = this.allAccounts.find(objet => objet.iban.toLowerCase() === ibanToCheck);
		// Si un compte correspondant est trouvé, extrayez les autres propriétés de cet objet
		if (account) {
			if (account.idPs == this.idPs) {
				this.ibanExistMsgError = "L'IBAN que vous avez saisi est déjà utilisé pour ce PS.";
			}
			else {
				this.ibanExistMsgError = "L'IBAN que vous avez saisi est déjà utilisé pour le PS " + account.numeroAdeli;
			}
			return true;
		}
		return false;
	}

}
