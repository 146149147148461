import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

export enum ExportMode {
  EXPORT = 'EXPORT', EMAIL = 'EMAIL'
}

@Component({
  selector: 'app-export-modal',
  templateUrl: './export-modal.component.html'
})
export class ExportModalComponent implements OnInit {

  @Input() nbrElement: number;
  @Output() exportEvent = new EventEmitter<any>();

  modeExport = ExportMode.EXPORT;

  constructor(private modalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  export(){
    this.exportEvent.emit({ action: this.modeExport});
    this.modalRef.hide();
  }

  closeModal(){
    this.modalRef.hide();
  }

}
