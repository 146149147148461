import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'numberSeparator'
})
export class NumberSeparatorPipe implements PipeTransform {
    transform(value: number | string): string {
        if (value === null || value === undefined) {
            return '';
        }

        const stringValue = typeof value === 'number' ? value.toString() : value;
        const parts = stringValue.split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

        return parts.join('.');
    }
}