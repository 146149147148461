import { AfterViewInit, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { saveAs } from 'file-saver';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FileExport } from '../../../../data/FileExportDataRaw';
import { RetoursEditablesService } from '../retours-editables.service';
import { RetourDetailsModalComponent } from './retour-details-modal.component';

@Component({
	selector: 'careweb-retour-preview',
	template: `
        <i
			[ngClass]="iconClass"
            container="body"
			(click)="previewLot()"
			[tooltip]="tooltip"
            containerClass="tooltip-grid"
		></i>
    `
})
export class RetourPreviewComponent implements ICellRendererAngularComp, AfterViewInit {
	iconClass: string;
	tooltip: string;
	params: any;

	constructor(private retourEditableSvc: RetoursEditablesService, private modalService: BsModalService) {}

	agInit(params: any): void {
		this.params = params;
	}

	ngAfterViewInit() {
		const cellParams = this.params;
		if (cellParams.data) {
			if (cellParams.colDef.field && cellParams.colDef.field === 'consultation') {
				this.iconClass = 'fa fa-eye';
				this.tooltip = 'Consulter';
			} else if (cellParams.colDef.field && cellParams.colDef.field === 'telechargement') {
				this.iconClass = 'fa fa-save';
				this.tooltip = 'Télécharger';
			}
		}
	}

	refresh(): boolean {
		return false;
	}

	previewLot(): void {
		const ctx = this;
		if (this.params.action === 'consult') {
			const initialState = {
				idRph: ctx.params.data.idRestitutionPs,
				typeRetour: ctx.params.typeRetour,
				idArt: ctx.params.data.idArt,
				dateArt: ctx.params.data.dateEnvoi,
				dateRph: ctx.params.data.dateEnvoie
			};
			this.modalService.show(RetourDetailsModalComponent, { initialState, class: 'modal-xl' });
		} else if (ctx.params.action === 'download') {
			if (ctx.params.typeRetour === 'art') {
				const artCriteria: any = {
					idArt: ctx.params.data.idArt,
					typeRetour: ctx.params.typeRetour
				};
				ctx.retourEditableSvc.downloadRetourEditable(artCriteria).subscribe((response: FileExport) => {
					saveAs(response.file, response.fileName);
				});
			} else {
				const rphCriteria: any = {
					idRph: ctx.params.data.idRestitutionPs,
					typeRetour: ctx.params.typeRetour
				};
				ctx.retourEditableSvc.downloadRetourEditable(rphCriteria).subscribe((response: FileExport) => {
					saveAs(response.file, response.fileName);
				});
			}
		}
	}
}
