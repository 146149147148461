import { Component, Input, OnInit } from '@angular/core';
import { IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { gridConvertSort } from 'src/main/webapp/src/app/components/ag-grid/ag-grid.utils';
import { User } from 'src/main/webapp/src/app/data/userDataRaw';
import { AgGridHelper } from '../../../../../components/ag-grid/ag-grid-helper';
import { ScrollTopService } from '../../../../../core/utilities/scroll-top.service';
import { UsersService } from '../../../../users/users.service';
import { PsService } from '../../../ps.service';
import { PsUserPreviewComponent } from '../ps-user-preview.components';

@Component({
	selector: 'careweb-app-ps-user-existant',
	templateUrl: './ps-user-existant.component.html',
	styleUrls: ['./ps-user-existant.component.scss']
})
export class PsUserExistantComponent implements OnInit {
	gridHelper: AgGridHelper;
	paginationSize: number;
	params: any;
	userDataRaw: User;
	idTypeUserPS = 3;
	@Input() idPs: Number;

	constructor(
		private scrollTopService: ScrollTopService,
		private modalRef: BsModalRef,
		private userSVC: UsersService,
		private psService: PsService,
		private toastr: ToastrService
	) {
		this.gridHelper = new AgGridHelper('userExist', 5, 5);
		this.userDataRaw = new User();
	}

	ngOnInit() {
		this.scrollTopService.setScrollTop();
		this.gridHelper.gridOptions = {
			columnDefs: this.getColumnDefs(),
			defaultColDef: {
				resizable: true,
				suppressMenu: true
			},
			components: {
				customInputFilterComponent: this.getInputFloatingFilterComponent()
			},
			context: { parentComponent: this },
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 5,
			paginationPageSize: 5,
			infiniteInitialRowCount: 1,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			localeText: this.gridHelper.getLocaleText(),
			onGridReady: (params) => this.onGridReady(params),
			onFilterChanged: (params) => {
				//
			}
		};
		this.paginationSize = this.gridHelper.paginationPageSize;
	}

	onGridSizeChanged(params) {
		this.gridHelper.gridApi.sizeColumnsToFit();
	}

	restoreGrid() {
		this.gridHelper.restoreGridStatePrefix();
		this.paginationSize = this.gridHelper.paginationPageSize;
	}

	onGridReady(params) {
		const ctx = this;
		this.gridHelper.gridApi = params.api;
		this.gridHelper.gridColumnApi = params.columnApi;
		this.restoreGrid();
		this.gridHelper.gridApi.sizeColumnsToFit();
		const dataSource: IServerSideDatasource = {
			getRows: function (paramsRows: IServerSideGetRowsParams) {
				const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
				ctx.userSVC
					.getUsersByFilterAndTypeUserAndNotAttachPs(
						{
							idPs: ctx.idPs,
							page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
							size: ctx.gridHelper.gridApi.paginationGetPageSize(),
							sorts
						},
						'PS',
						ctx.userDataRaw
					)
					.subscribe(
						(data) => {
							const rowCount = data.totalElements;
							ctx.gridHelper.manageNoRowsOverlay(rowCount);
							paramsRows.success({"rowData": data.content, "rowCount": rowCount});
						},
						() => paramsRows.fail()
					);
			}
		};

		this.gridHelper.gridApi.setServerSideDatasource(dataSource);
		this.gridHelper.gridApi.sizeColumnsToFit();
		this.gridHelper.gridApi.hidePopupMenu();
	}

	private getColumnDefs() {
		return [
			{
				headerName: 'Nom',
				field: 'nom',
				minWidth: 200,
				lockVisible: true,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true }
			},
			{
				headerName: 'Prénom',
				field: 'prenom',
				minWidth: 150,
				lockVisible: true,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true }
			},
			{
				headerName: 'Fonction',
				field: 'fonction',
				minWidth: 150,
				lockVisible: true,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true }
			},
			{
				headerName: 'Téléphone',
				field: 'telephone',
				minWidth: 170,
				lockVisible: true,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true },
				cellStyle: this.gridHelper.centerContent()
			},
			{
				headerName: 'Mobile',
				field: 'mobile',
				minWidth: 140,
				lockVisible: true,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true }
			},
			{
				headerName: 'Email',
				field: 'email',
				minWidth: 180,
				lockVisible: true,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true }
			},
			{
				headerName: '',
				field: 'options',
				minWidth: 70,
				lockVisible: true,
				cellClass: ['link-icon', 'text-center'],
				cellRenderer: PsUserPreviewComponent,
				cellStyle: this.gridHelper.centerContent(),
				onCellClicked: (params) => this.attachPsOfUser(params.data),
				cellRendererParams: {
					iconClass: 'fa fa-link',
					redirect: 'attach'
				}
			}
		];
	}

	getInputFloatingFilterComponent() {
		const ctx = this;
		function InputFloatingFilter() { }

		InputFloatingFilter.prototype.init = function (params) {
			this.eGui = document.createElement('div');
			this.eGui.setAttribute('class', 'ag-input-wrapper');

			switch (params.filterParams.colDef.field) {
				case 'nom':
				case 'prenom':
				case 'fonction':
					this.eGui.innerHTML =
						"<input type='text'  class='customFilterInput' style='width:100%; box-shadow: none;' onKeyPress='if(this.value.length==50) return false;'/>";
					break;
				case 'telephone':
				case 'mobile':
					this.eGui.innerHTML = this.eGui.innerHTML =
						'<input type="number" class="customFilterInput" style="width:100%; box-shadow: none;" onKeyPress="return this.value.length!==16 && event.charCode >= 48 && event.charCode <= 57" pattern="^[0-9]" min="0" step="1" max="99999"/>';
					break;
				case 'email':
					this.eGui.innerHTML =
						"<input type='text' class='customFilterInput' style='width:100%; box-shadow: none;' onKeyPress='if(this.value.length==100) return false;'/>";
					break;
				default:
					break;
			}

			this.currentValue = null;
			this.eFilterInput = this.eGui.querySelector('input');
			this.eFilterInput.style.color = params.color;
			const that = this;
			function onInputChanged() {
				const filterField = params.filterParams.colDef.field;
				if (that.eFilterInput.value === '') {
					// Remove the filter
					delete ctx.userDataRaw[filterField];
					params.parentFilterInstance(function (instance) {
						instance.onFloatingFilterChanged(null, null);
					});
					return;
				}

				that.currentValue = that.eFilterInput.value;

				if (that.eFilterInput.value.length >= 3) {
					if (that.currentValue !== '') {
						ctx.userDataRaw[filterField] = that.eFilterInput.value;
					}
					params.parentFilterInstance(function (instance) {
						instance.onFloatingFilterChanged('equals', that.currentValue);
					});
				}
			}
			this.eFilterInput.addEventListener('input', onInputChanged);
		};

		InputFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
			if (!parentModel) {
				this.eFilterInput.value = '';
				this.currentValue = null;
			} else {
				this.eFilterInput.value = parentModel.filter + '';
				this.currentValue = parentModel.filter;
			}
		};

		InputFloatingFilter.prototype.getGui = function () {
			return this.eGui;
		};

		return InputFloatingFilter;
	}

	attachPsOfUser(user: User) {
		this.psService.attachPsOfUser(this.idPs, user.idUser).subscribe(
			(data) => {
				if (data) {
					this.toastr.success("L'utilisateur a été ajouté avec succès");
					this.modalRef.hide();
				}
			},
			() => {
				this.toastr.error("L'utilisateur n'a pas pu être ajouté");
			}
		);
	}
}
