import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { AuthService } from '../../core/services/auth.service';
import { UsersService } from '../users/users.service';
import { UserService } from '../../core/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { InputValidators } from '../../components/validators/InputValidators';
import { Router } from '@angular/router';

@Component({
	selector: 'careweb-app-update-password',
	templateUrl: './update-password.component.html',
	styleUrls: [ './update-password.component.scss' ]
})
export class UpdatePasswordComponent implements OnInit {
	passwordForm: UntypedFormGroup;
	currentUser;
	submitted = false;
	incorrectActualPassword = false;
	isPasswordVisible = false;

	constructor(
		private authSvc: AuthService,
		private usersService: UsersService,
		private userService: UserService,
		private fb: UntypedFormBuilder,
		private toastr: ToastrService,
		private inputValidators: InputValidators,
		private router: Router
	) {}

	ngOnInit() {
		this.initForm();
		// get currentUser connected
		this.currentUser = this.authSvc.currentUserValue;
	}

	initForm() {
		this.passwordForm = this.fb.group({
			actualPassword: new UntypedFormControl('', [ Validators.required, this.inputValidators.inputWithSpaces ]),
			newPassword: new UntypedFormControl('', [ Validators.required, this.inputValidators.inputWithSpaces ]),
			confirmPassword: new UntypedFormControl('', [ Validators.required, this.inputValidators.inputWithSpaces ])
		});
	}

	getUserById(idUser) {
		return this.usersService.getUserById(idUser).subscribe((res) => {
			this.currentUser = res;
		});
	}

	onSubmit() {
		var ctx = this;
		this.formFields.actualPassword.setValue(this.formFields.actualPassword.value.trim());
		this.formFields.newPassword.setValue(this.formFields.newPassword.value.trim());
		this.formFields.confirmPassword.setValue(this.formFields.confirmPassword.value.trim());
		this.incorrectActualPassword = false;
		this.submitted = true;
		if (this.passwordForm.invalid) {
			return;
		}
		if (
			this.passwordForm.valid &&
			this.formFields.actualPassword.value !== this.formFields.newPassword.value &&
			this.formFields.newPassword.value === this.formFields.confirmPassword.value
		) {
			// verify if actual password is correct
			ctx.usersService
				.verifyActualPassword(this.currentUser.idUser, this.formFields.actualPassword.value)
				.subscribe(
					(user) => {
						if (!isNullOrUndefined(user)) {
							ctx.incorrectActualPassword = false;
							// update password
							ctx.userService
								.changePassword(this.currentUser.login, null, this.formFields.newPassword.value, this.formFields.actualPassword.value)
								.subscribe((res) => {
									if (!isNullOrUndefined(res)) {
										ctx.toastr.success(
											'Votre mot de passe a été modifié avec succès.',
											'Modification du mot de passe'
										);
										ctx.router.navigate([ '/user-profile' ]);
									} else {
										ctx.toastr.error(
											'Une erreur est survenue lors de la modification du mot de passe',
											'Modification du mot de passe'
										);
									}
								});
						} else {
							this.incorrectActualPassword = true;
						}
					},
					(err) => {
						this.incorrectActualPassword = true;
					}
				);
		}
	}

	cancel() {
		this.formFields.actualPassword.setValue(null);
		this.formFields.newPassword.setValue(null);
		this.formFields.confirmPassword.setValue(null);
		this.router.navigate([ '/user-profile' ]);
	}

	get formFields() {
		return this.passwordForm.controls;
	}

	// affichier le mot de passe
	showPassword(input: string) {
		const htmlInput: any = document.getElementById(input);
		if (htmlInput.type === 'password') {
			htmlInput.type = 'text';
		} else {
			htmlInput.type = 'password';
		}
	}

	// cacher le mot de passe
	hidePassword(input: string) {
		const htmlInput: any = document.getElementById(input);
		htmlInput.type = 'password';
	}
}
