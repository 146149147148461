import { Component, Input } from '@angular/core';


@Component({ selector: 'careweb-pluralize', template: '{{fullMessageKey}}' })

export class PluralizeComponent {
    /** Clé des messages à afficher. */
    @Input()
    messageKey: string;

    /** Nombre à utiliser à l'intérieur du message. */
    @Input()
    count: number;

    /** Fournit la clé du message à afficher en fonction de la quantité. */
    get fullMessageKey(): string {
        return this.count === 0 ? 'Chargement...' 
                    : this.count === -1 ? `${ 0 } ${this.messageKey}` 
                    : `${this.count} ${this.messageKey}`;
    }

    get param() {
        return { count: this.count };
    }
}
