<div class="tab-pane">
    <div class="form-block">
        <fieldset class="container-fluid">
            <div class="row highlight-row">
                <div class="col-md-12 relative" >
                    <ag-grid-angular style="width: 100%;" class="ag-theme-material"
                        [gridOptions]="gridHelper.gridOptions">
                    </ag-grid-angular>
                    <careweb-pagination [gridApi]="gridHelper.gridApi">
                    </careweb-pagination>
                </div>
            </div>
        </fieldset>
    </div>
</div>