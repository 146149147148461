import {Component, OnInit, OnDestroy, ViewChild, HostListener} from '@angular/core';
import {AgGridHelper} from "../../../components/ag-grid/ag-grid-helper";
import {GridDataRaw} from "../../../data/gridDataRaw";
import { isNullOrUndefined } from 'util';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';
import {StorageService} from "../../../shared/storage-service";
import { ColDef , IServerSideDatasource,IServerSideGetRowsParams} from 'ag-grid-enterprise';
import {ColumnSelectorComponent} from "../../../components/ag-grid/grid-column-selector.component";
import {ColumnSpec} from "../../../components/ag-grid/grid-column-spec";
import {AuthService} from "../../../core/services/auth.service";
import {MouvementsFinanciersDataRaw} from "../../../data/MouvementFinancierDataRaw";
import {gridConvertSort} from "../../../components/ag-grid/ag-grid.utils";
import {MouvementFinancierService} from "./Mouvement.financier.service";
import {OctService} from "../../../core/services/oct.service";
import {MvFinanciersFilterRaw} from "../../../data/filters/MvFinanciersFilterRaw";
import { findIndex, isEmpty ,uniqBy} from 'lodash-es';
import {FilterItem} from "../../../data/filters/filter-item";
import {MvFinancierFilterSvc} from "./MvFinancierFilterSvc";
import {CustomDatePipe} from "../../../core/pipes/custom-date.pipe";
import {DateFromToFilterComponent} from "../../../components/date-from-to/date-from-to-filter.component";
import {PsService} from "../../ps/ps.service";
import {PsDelegueDataRaw} from "../../../data/PsDelegueDataRaw";
import {ConvertDateFormat} from "../../../core/utilities/convert-date-format.service";
import {AccordionGroupComponent} from "../../../components/accordion/accordion-group.component";
import {PsDelegueService} from "../../ps/service/ps-delegue.service";
import {ExportModalComponent, ExportMode} from "../../export-modal/export-modal.component";
import {BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import {TransformService} from "../../../core/services/transform.service";
import {ExportState, State} from "../../../shared/model/storage.model";
import { ColumnMouvementComponent } from '../../../components/column-tooltip/column-mf-tooltip-component';

@Component({
    selector: 'careweb-app-mouvements-financiers',
    templateUrl: './mouvements.financiers.component.html',
    styleUrls: ['./mouvement.financier.component.scss']
})
export class MouvementsFinanciersComponent implements OnInit,OnDestroy{

    gridHelper: AgGridHelper;
    gridDataRaw: GridDataRaw;
    datePipe: DatePipe;
    mouvementsFinanciersDataRaw :MouvementsFinanciersDataRaw;
    filters: MvFinanciersFilterRaw;
    filterList: FilterItem[];
    currentOct;
    showFilter = false;
    initHidden = true;
    public rowCount: number = 0;
    totalResultsHidden = false;
    private openedModal: BsModalRef;
    export: boolean = false;
    exportState: ExportState;
    serverName: string;
    static MIN_WIDTH = 1280;

    @ViewChild('jourComptable')
    dateEnvoie: DateFromToFilterComponent;

    psDelegueListFilter = new Array<PsDelegueDataRaw>();
    isDateRangeValid = true;

    @ViewChild('tooltipExacte')
    tooltipExacte: TooltipDirective;
    @ViewChild('tooltipFrom')
    tooltipFrom: TooltipDirective;

    @ViewChild('tooltipTo')
    tooltipTo: TooltipDirective;

    @ViewChild('dateAccordion')
    dateAccordion: AccordionGroupComponent;

    montantMfMinError: boolean = false;
    montantMfMaxError: boolean = false;

    numeroFactureExacteError: Boolean = false;
    numeroFactureError: Boolean = false;



    @ViewChild(ColumnSelectorComponent, { static: true })
    columnSelector: ColumnSelectorComponent;
    exportableColumns: Array<string> = [
        'numFacture',
        'organismeLibelle',
        'montantMF',
        'referenceDette',
        'natureDette',
        'natureOperation',
        'jourComptable'
    ];

    constructor(
        private storageService: StorageService,
        private authSvc: AuthService,
        private octSvc: OctService,
        private psSVC :PsService,
        private psDelegueSVC: PsDelegueService,
        private router: Router,
        private convertFormatDate: ConvertDateFormat,
        private dateFormatPipe : CustomDatePipe,
        private mvFinancierFilterSvc: MvFinancierFilterSvc,
        private mouvementFinancierService: MouvementFinancierService,
        private modalService: BsModalService,
        private transformService: TransformService
    ) {
        //Get grid size from session if already stored
        const sessionPageSize = this.storageService.get('mvFinancierPaginationPageSize', true);
        this.gridDataRaw = new GridDataRaw();
        this.gridDataRaw.size = !isNullOrUndefined(sessionPageSize) ? sessionPageSize : 10;

        this.gridHelper = new AgGridHelper('mvFinancier', this.gridDataRaw.size, 10);
        this.mouvementsFinanciersDataRaw = new MouvementsFinanciersDataRaw();
        this.convertFormatDate = new ConvertDateFormat();
    }

    ngOnInit(){

        var parsedFilter = JSON.parse(sessionStorage.getItem('mvFinancierFilters'));
        // Get filter stored in session if exist
        if (!isEmpty(parsedFilter)) {
            this.filters = Object.assign({}, parsedFilter);
            this.filters.jourComptableFrom
                ? (this.filters.jourComptableFrom = new Date(parsedFilter.jourComptableFrom)) : '';
            this.filters.jourComptableTo
                ? (this.filters.jourComptableTo = new Date(parsedFilter.jourComptableTo)) : '';
            this.filters.jourComptable
                ? (this.filters.jourComptable= new Date(parsedFilter.jourComptable)) : '';

        }else {
            this.filters = new MvFinanciersFilterRaw();
            this.filterList = [new FilterItem()];
        }
        // Get current oct
        this.currentOct = JSON.parse(localStorage.getItem('octCurrent'));
        if (isNullOrUndefined(this.currentOct)) {
            this.octSvc.currentOctSubject.subscribe((value) => {
                this.currentOct = value;
            });
        }

        // Apply numero Ps on the filter if exist
        const numeroPsLocalStorage = localStorage.getItem('numeroPs');
        if (!isEmpty(numeroPsLocalStorage)) {
            if(this.filters.numeroPs && this.filters.numeroPs != numeroPsLocalStorage) {
                this.filters =  new MvFinanciersFilterRaw();
            }
            this.filters.numeroPs = numeroPsLocalStorage;
            sessionStorage['mvFinancierFilters'] = JSON.stringify(this.filters);
        }
        // Apply numero Ps supervised on the filter if exist
		var numeroPsSupervisedLocalStorage = localStorage.getItem('supervised_ps');
		if (!isEmpty(numeroPsSupervisedLocalStorage)) {
			if (numeroPsSupervisedLocalStorage === '-1') {
				this.filters.numeroPScomboBox = null;
			}
			else {
				numeroPsSupervisedLocalStorage = numeroPsSupervisedLocalStorage.split(';')[0];
				if (this.filters.numeroPScomboBox && this.filters.numeroPScomboBox != numeroPsSupervisedLocalStorage) {
					this.filters = new MvFinanciersFilterRaw();
				}
				this.filters.numeroPScomboBox = numeroPsSupervisedLocalStorage;
				sessionStorage['mvFinancierFilters'] = JSON.stringify(this.filters);
			}
		}

        this.gridHelper.gridOptions = {
            columnDefs: this.getColumnDefs(),
            defaultColDef: {
                suppressMenu: true,
                resizable: true
            },
            domLayout: 'autoHeight',
            rowHeight: 50,
            headerHeight: 50,
            rowModelType: 'serverSide',
            suppressServerSideInfiniteScroll: false,
            pagination: true,
            cacheBlockSize: 10,
            paginationPageSize: this.gridDataRaw.size,
            infiniteInitialRowCount: 1,
            suppressScrollOnNewData: true,
            suppressPaginationPanel: true,
            suppressContextMenu: true,
            localeText: this.gridHelper.getLocaleText(),
            components: {
                columnTooltipCellRenderer: ColumnMouvementComponent
            },
            onGridReady: (params) => this.onGridReady(params),
            onGridSizeChanged: (params) => this.onGridSizeChanged(params),
            onColumnMoved: (params) => this.onColumnChanged(params),
            onColumnVisible: (params) => this.onColumnChanged(params),
            enableCellTextSelection: true
        };

        // Init datePipe
        this.datePipe = new DatePipe('fr-FR');

        // load combobox of numero PS if user is PS
        if (!this.isAuthorized()) {
            this.loadComboBoxFilterNumeroPS_Supervise();
        }
    }
    onGridSizeChanged(params) {
        this.gridHelper.gridApi.sizeColumnsToFit();
    }
    private getColumnDefs(): ColDef[] {
        return [
            {
                headerName: 'N° de PS',
                field: 'numeroPs',
                headerClass: 'ag-header-merge',
                minWidth: 100,
                maxWidth: 150,
                lockVisible: true,
                cellClass: (params) => this.styleLink(params),
                cellStyle: this.gridHelper.centerContent(),
                onCellClicked: (params) => this.onPsCellClicked(params),
                hide: !this.existGroupNumeroPS(),
                sortable: true,
            },
            {
                headerName: 'N° de Facture',
                field: 'numFacture',
                minWidth: 100,
                maxWidth: 150,
                lockVisible: true,
                cellStyle: this.gridHelper.centerContent(),
                sortable: true
            },
            {
                headerName: 'Organismes',
                field: 'organismeLibelle',
                minWidth: 100,
                lockVisible: true,
                cellStyle: this.gridHelper.centerContent(),
                sortable: true
            },
            {
                headerName: 'Montant',
                field: 'montantMF',
                minWidth: 100,
                lockVisible: true,
                valueFormatter: this.formatCurrencyMontant,
                cellClass: ['font-weight-bold'],
                cellStyle: this.gridHelper.centerContent(),
                sortable: true
            },
            {
                headerName: 'Solde dette ',
                field: 'soldeDette',
                minWidth: 100,
                hide: true,
                valueFormatter: this.formatCurrency,
                cellStyle: this.gridHelper.centerContent(),
                sortable: true
            },
            {
                headerName: 'Référence dette',
                field: 'referenceDette',
                minWidth: 100,
                maxWidth: 150,
                lockVisible: true,
                cellClass: ['font-weight-bold'],
                cellStyle: this.gridHelper.centerContent(),
                sortable: true
            },
            {
                headerName: 'Nature dette',
                field: 'natureDette',
                cellRenderer: 'agGroupCellRenderer',
                cellRendererParams: {
                    suppressDoubleClickExpand: true,
                    innerRenderer: 'columnTooltipCellRenderer'
                },
                minWidth: 60,
                maxWidth: 200,
                cellClass: ['font-weight-bold'],
                lockVisible: true,
                cellStyle: this.gridHelper.centerContent(),
                sortable: true
            },
            {
                headerName: 'Nature opération',
                field: 'natureOperation',
                cellRenderer: 'agGroupCellRenderer',
                cellRendererParams: {
                    suppressDoubleClickExpand: true,
                    innerRenderer: 'columnTooltipCellRenderer'
                },
                minWidth: 60,
                maxWidth: 200,
                lockVisible: true,
                cellStyle: this.gridHelper.centerContent(),
                sortable: true
            },
            {
                headerName: 'Jour comptable',
                field: 'jourComptable',
                minWidth: 150,
                maxWidth: 200,
                lockVisible: true,
                valueFormatter: this.formatStringToDate,
                cellStyle: this.gridHelper.centerContent(),
                sortable: true
            },
            {
                headerName: 'Date réception partition ',
                field: 'dateReceptionPartition',
                valueFormatter: this.formatDate,
                minWidth: 150,
                maxWidth: 200,
                hide: true,
                cellStyle: this.gridHelper.centerContent(),
                sortable: true
            },
            {
                headerName: 'Date enregistrement dette ',
                field: 'dateEnregisteremntDette',
                minWidth: 150,
                maxWidth: 200,
                hide: true,
                valueFormatter: this.formatStringToDate,
                cellStyle: this.gridHelper.centerContent(),
                sortable: true
            }
        ];
    }
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        let width = event.target.innerWidth;
        if (width > 1280){
            this.gridHelper.gridApi.sizeColumnsToFit();
        }
    }
    onColumnChanged(params: any): void {
        this.gridHelper.gridColumnApi.setColumnsVisible(["numeroPs"], this.existGroupNumeroPS());
        this.gridHelper.saveGridColumnState();
    }

    launchSearch(): void {
        this.initColumnsDisplay();
        const ctx = this;
        this.montantMfMinError = false;
        this.montantMfMaxError = false;
        ctx.totalResultsHidden = true;
        if (this.isFormValid()) {
            ctx.mouvementsFinanciersDataRaw = new MouvementsFinanciersDataRaw();
            this.mouvementsFinanciersDataRaw.numeroPs = this.isAuthorized()
                ? this.filters.numeroPs ? this.filters.numeroPs : null
                : this.filters.numeroPScomboBox ? this.filters.numeroPScomboBox : null;

            this.mouvementsFinanciersDataRaw.numFacture = this.filters.numFacture ? this.filters.numFacture.trim() : null;
            this.mouvementsFinanciersDataRaw.numFactureFrom = (this.filters.numFactureFrom ? this.filters.numFactureFrom.trim() : null) || null;
            this.mouvementsFinanciersDataRaw.numFactureTo = this.filters.numFactureTo ? this.filters.numFactureTo.trim() : null;

            this.mouvementsFinanciersDataRaw.organismeLibelle = this.filters.organismeLibelle || null;
            this.mouvementsFinanciersDataRaw.montantMF = this.filters.montantMf || null;
            this.mouvementsFinanciersDataRaw.montantMfFrom = this.filters.montantMfFrom || null;
            this.mouvementsFinanciersDataRaw.montantMfTo = this.filters.montantMfTo || null;
            this.mouvementsFinanciersDataRaw.referenceDette = this.filters.referenceDette;
            this.mouvementsFinanciersDataRaw.natureDette = this.filters.natureDette;
            this.mouvementsFinanciersDataRaw.natureOperation = this.filters.natureOperation;

            this.mouvementsFinanciersDataRaw.jourComptable = this.convertFormatDate.getFormatDateFrString(this.filters.jourComptable) || null;
            this.mouvementsFinanciersDataRaw.jourComptableFrom= this.filters.jourComptableFrom || null ;
            this.mouvementsFinanciersDataRaw.jourComptableTo = this.filters.jourComptableTo || null ;
            this.updateFilterList(this.filters);
            Object.keys(this.mouvementsFinanciersDataRaw).forEach((key) => {
                if (isNullOrUndefined(this.mouvementsFinanciersDataRaw[key])) {
                    delete this.mouvementsFinanciersDataRaw[key];
                }
            });
            const dataSource: IServerSideDatasource = {
                getRows: function (paramsRows: IServerSideGetRowsParams) {
                    const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
                    // Connect By Admin Or Hotline
                    if (ctx.isAuthorized()) {
                        ctx.mouvementFinancierService.getMvFinancierFilteredList(
                            {
                                page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
                                size: ctx.gridHelper.gridApi.paginationGetPageSize(),
                                idOct: ctx.currentOct ? ctx.currentOct.idOct : '',
                                sorts
                            },
                            ctx.mouvementsFinanciersDataRaw
                        )
                            .subscribe(
                                (data) => {
                                    ctx.rowCount = data.totalElements;
                                    ctx.gridHelper.manageNoRowsOverlay(ctx.rowCount);
                                    paramsRows.success({"rowData": data.content, "rowCount": ctx.rowCount});
                                    const pageN = Number.parseInt(sessionStorage.getItem('mvFinancierCurrentPage'));
                                    if (
                                        !isNullOrUndefined(pageN) &&
                                        pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
                                        pageN > 0
                                    ) {
                                        ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
                                    }
                                    sessionStorage['mvFinancierCurrentPage'] = null;
                                },
                                () => paramsRows.fail()
                            );
                    } else {
                        // Connect By PS
                        const userConnected = JSON.parse(localStorage.getItem('careweb_user'));
                        const criteria = {
                            page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
                            size: ctx.gridHelper.gridApi.paginationGetPageSize(),
                            idPs: userConnected.idPs,
                            sorts
                        };
                        ctx.mouvementFinancierService.getMouvementFinancier(criteria ,ctx.mouvementsFinanciersDataRaw).subscribe(
                            (data) => {
                                ctx.rowCount = data.totalElements;
                                ctx.gridHelper.manageNoRowsOverlay(ctx.rowCount);
                                paramsRows.success({"rowData": data.content, "rowCount": ctx.rowCount});
                                const pageN = Number.parseInt(sessionStorage.getItem('mvFinancierCurrentPage'));
                                if (
                                    !isNullOrUndefined(pageN) &&
                                    pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
                                    pageN > 0
                                ) {
                                    ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
                                }
                                sessionStorage['mvFinancierCurrentPage'] = null;
                            },
                            () => paramsRows.fail()
                        );
                    }
                }
            };
            this.gridHelper.gridApi.setServerSideDatasource(dataSource);
            sessionStorage['mvFinancierFilters'] = JSON.stringify(this.filters);
        }
    }

    onGridReady(params) {
        const ctx = this;
        ctx.initFilter();
        this.gridHelper.gridApi = params.api;
        this.gridHelper.gridColumnApi = params.columnApi;
        this.initSorting();
        this.initColumnsDisplay();
        this.restoreGridState();
        if (window.innerWidth > MouvementsFinanciersComponent.MIN_WIDTH) {
            this.gridHelper.gridApi.sizeColumnsToFit();
        }
        const parsedFilter = JSON.parse(sessionStorage.getItem('mvFinancierFilters'));
        if (parsedFilter) {
            Object.keys(parsedFilter).forEach(
                (key) => (parsedFilter[key] === undefined || parsedFilter[key] === null ? delete parsedFilter[key] : {})
            );
        }
        if (!isEmpty(parsedFilter)) {
            this.launchSearch();
        } else {
            const dataSource: IServerSideDatasource = {
                getRows: function (paramsRows: IServerSideGetRowsParams) {
                    const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
                    if (!ctx.isAuthorized()) {
                        const userConnected = JSON.parse(localStorage.getItem('careweb_user'));
                        const mouvementFinancier = new MouvementsFinanciersDataRaw();
                        const criteria = {
                            page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
                            size: ctx.gridHelper.gridApi.paginationGetPageSize(),
                            idPs: userConnected.idPs,
                            sorts
                        };
                        ctx.mouvementFinancierService.getMouvementFinancier(criteria, mouvementFinancier).subscribe(
                            (data) => {
                                ctx.rowCount = data.totalElements;
                                ctx.gridHelper.manageNoRowsOverlay(ctx.rowCount);
                                paramsRows.success({"rowData": data.content, "rowCount": ctx.rowCount});
                                const pageN = Number.parseInt(sessionStorage.getItem('mvFinancierCurrentPage'));
                                if (
                                    !isNullOrUndefined(pageN) &&
                                    pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
                                    pageN > 0
                                ) {
                                    ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
                                }
                                sessionStorage['mvFinancierCurrentPage'] = null;
                                ctx.totalResultsHidden = true;
                            },
                            () => paramsRows.fail()
                        );
                    }
                }}
            this.gridHelper.gridApi.setServerSideDatasource(ctx.isAuthorized() ? null : dataSource);
        }
    }

    restoreGridState() {
        this.gridHelper.restoreGridStatePrefix();
        this.gridDataRaw.size = this.gridHelper.paginationPageSize;
    }

    isAuthorized() {
        return this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
    }

    styleLink(params) {

        let previouslyClickedRow = sessionStorage.getItem('previouslyClickedLotId');

        if (!isNullOrUndefined(params.data) && params.data.idLot === Number(previouslyClickedRow)) {

            return 'link-in-selected-row';

        } else {

            return 'link-in-not-selected-rows';
        }

    }

    onPsCellClicked(params) {
        var ctx = this;
        if (this.isAuthorized() && !this.gridHelper.isCellTextSelected()) {
            ctx.psSVC
                .getPsByNumAdeli({
                    numAdeli: params.value
                })
                .subscribe((data) => {
                    this.router.navigate(['/ps-details', data[0].idPs]);
                });
        }
    }

    existGroupNumeroPS() {
        return this.isAuthorized() || this.psDelegueListFilter.length != 0;
    }

    loadComboBoxFilterNumeroPS_Supervise() {
        const userConnected = JSON.parse(localStorage.getItem('careweb_user'));
        if (!isEmpty(userConnected)) {
            if (!isNullOrUndefined(userConnected.idPs)) {
                this.psDelegueSVC.getPsDelegueByIdPs(userConnected.idPs).subscribe((data) => {
                    if (!isNullOrUndefined(data) && !isEmpty(data)) {
                        this.psDelegueListFilter = data;
                        const psCurrent = new PsDelegueDataRaw();
                        psCurrent.idPsDelegue = this.psDelegueListFilter[0].idPsAssocie;
                        psCurrent.numeroPsDelegue = this.psDelegueListFilter[0].numeroPsAssocie;
                        psCurrent.nomPsDelegue = this.psDelegueListFilter[0].nomPsAssocie;
                        this.psDelegueListFilter.push(psCurrent);
                    }
                    isNullOrUndefined(this.gridHelper.gridColumnApi) ? null : this.gridHelper.gridColumnApi.setColumnVisible('numeroPs', this.existGroupNumeroPS());
                });
            }
        }
    }

    onPaginationSizeChange(paginationSize: number): void {
        this.gridHelper.changePaginationSize(paginationSize);
        if (this.isAuthorized() && !isNullOrUndefined(localStorage['numeroPs']) && this.rowCount != 0){
            this.launchSearch();
        } else if(!this.isAuthorized()){
            this.launchSearch();
        }
        this.gridHelper.saveGridStatePrefix();
    }

    addColumn(columnName: string) {
        if (this.exportableColumns.indexOf(columnName) === -1) {
            this.exportableColumns.push(columnName);
        }
    }

    private getColumnSpecs(columns: any[]): ColumnSpec[] {
        const allowedColumns = [
            'numFacture',
            'organismeLibelle',
            'montantMF',
            'soldeDette',
            'referenceDette',
            'natureDette',
            'natureOperation',
            'jourComptable',
            'dateEnregisteremntDette',
            'dateReceptionPartition',

        ];
        return columns
            .filter(
                (cd) => cd.colDef.headerName && cd.colDef.headerName.length > 0 && allowedColumns.indexOf(cd.colId) > -1
            )
            .map((cd) => {
                return {
                    id: cd.colDef.field,
                    name: cd.colDef.headerName,
                    label: cd.colDef.headerName
                };
            });
    }

    removeColumn(columnName: string) {
        const index = this.exportableColumns.indexOf(columnName);
        if (index > -1) {
            this.exportableColumns.splice(index, 1);
        }
    }

    selectColumnEvent(col: ColumnSpec) {
        this.gridHelper.gridColumnApi.setColumnVisible(col.id, true);
        this.gridHelper.gridApi.sizeColumnsToFit();
        this.addColumn(col.id);
    }

    deselectColumnEvent(col: ColumnSpec) {
        this.gridHelper.gridColumnApi.setColumnVisible(col.id, false);
        this.gridHelper.gridApi.sizeColumnsToFit();
        this.removeColumn(col.id);
    }

    initColumnsDisplay() {
        const allColumns = this.gridHelper.gridColumnApi.getColumns();
        const selectedColumns = this.gridHelper.gridColumnApi.getAllDisplayedColumns();
        this.columnSelector.setItems(this.getColumnSpecs(allColumns));
        this.columnSelector.setSelectedItems(this.getColumnSpecs(selectedColumns));
    }

    formatCurrency = (params): string => {
        if (params.data) {
            if (params.value) {
                return params.value / 100 + ' €';
            } else {
                return '0.00 €';
            }
        }
    };

    formatCurrencyMontant = (params): string => {
        if (params.data) {
            if (params.value) {
                    return params.value / 100 + ' €';
            } else {
                return '0.00 €';
            }
        }
    };

    formatDate = (params): string => {
        if (params.value !== null && params.value !== 'Invalid Date') {
            return this.datePipe.transform(params.value, 'dd/MM/yyyy HH:mm:ss');        }
    };

    formatStringToDate = (params): string => {
        if (params.value !== null && params.value !== 'Invalid Date') {
            return  this.dateFormatPipe.formatDate(params.value);
        }
    };

    formatDateForTags = (params): string => {
        if (params.value !== null && params.value != 'Invalid Date') {
            return this.datePipe.transform(params.value, 'dd/MM/yyyy');
        }
    };

    private initSorting() {
        let sort = [
            {
                colId: 'jourComptable',
                sort: 'desc',
                sortIndex: 0
            }
        ];
        return this.gridHelper.applyColumnState(sort);
    }


    translateFilterLabelValue(key, value) {
        let translatedKey = key;
        let formattedValue = value;
        let formattedKey = key;
        const ctx = this;
        switch (key) {
            case ('numeroPs') :
                translatedKey = 'Numéro de PS';
                break;
            case ('numeroPScomboBox') :
                translatedKey = 'Numéro de PS';
                break;
            case  'numFacture':
                translatedKey ='Numéro de Facture';
                break;    break;
            case  'numFactureFrom':
                translatedKey = 'N° de Facture supérieur ou égal à';
                break;    break;
            case  'numFactureTo':
                translatedKey = 'N° de Facture inférieur ou égal à';
                break;
            case 'organismeLibelle':
                    translatedKey ='Organisme';
                    break;
            case 'montantMf':
                translatedKey ='Montant';
                formattedValue = value + ' €';
                break;
            case 'montantMfFrom':
                translatedKey ='Montant supérieur ou égal à';
                formattedValue = value + ' €';
                break;
            case 'montantMfTo' :
                translatedKey ='Montant inférieur ou égal à';
                formattedValue = value + ' €';
                break;
            case  'referenceDette':
                translatedKey ='Référence dette';
                break;
            case 'natureDette':
                translatedKey ='Nature dette';
                break;
            case 'natureOperation':
                translatedKey ='Nature Opération';
                break;
            case 'jourComptable':
                translatedKey ='Jour comptable';
                formattedValue = ctx.formatDateForTags({ value: value });
                break;
            case 'jourComptableFrom':
                translatedKey = 'jour comptable après le';
                formattedValue = ctx.formatDateForTags({ value: value });
                break;
            case 'jourComptableTo':
                translatedKey = 'jour comptable  avant le';
                formattedValue = ctx.formatDateForTags({ value: value });
                break;
        }
        return new FilterItem().withId(formattedKey).withLabel(translatedKey).withValue(formattedValue).withValue2(value);

    }

    resetGridState() {
        this.gridHelper.resetGridState();
        this.gridDataRaw.size = this.gridHelper.defaultPaginationPageSize;
        this.initColumnsDisplay();
        this.initSorting();
    }

    clearGridFromData() {
        this.totalResultsHidden = false;
        this.gridHelper.gridApi.setServerSideDatasource(null);
        this.rowCount = 0;
    }

    fillFilterObjectFromTagsOnly() {
        this.filters = new MvFinanciersFilterRaw();
        this.filterList.forEach(filter => {
            this.filters[filter.id] = filter.value2;
        });
    }

    removeFilter(id) {
        const ctx = this;
        delete ctx.filters[id];
        let currentFilterList = ctx.filterList;
        const index = findIndex(currentFilterList, (filt) => filt.id === id);
        currentFilterList.splice(index, 1);
        ctx.filterList = currentFilterList;
        if ((Array.isArray(ctx.filterList) && ctx.filterList.length) || !ctx.isAuthorized()) {
            this.fillFilterObjectFromTagsOnly();
            ctx.launchSearch();
        } else {
            ctx.clearGridFromData();
            this.rowCount = 0;
        }
    }

    initFilter() {
        // Liste des filtres
        this.resetFilter();
        this.updateFilterList(this.filters);
    }

    resetFilter() {
        this.mvFinancierFilterSvc.reset();
    }

    updateFilterList(filters) {
        const ctx = this;
        ctx.filterList = [];
        Object.keys(filters).forEach((key) => {
            if (!isNullOrUndefined(filters[key]) && filters[key] !== '') {
                ctx.filterList.push(ctx.translateFilterLabelValue(key, filters[key]));
            }
        });
        // Remove duplicated ids
        ctx.filterList = uniqBy(ctx.filterList, 'id');
        // filter null
        ctx.filterList = ctx.filterList.filter((item) => {
            return item.id !== null;
        });
    }

    // disable appliquer les filtres button
    disableSearch() {
        if (
            this.isAuthorized() &&
            Object.values(this.filters).every((o) => o === null || o === '' || o === undefined)
        ) {
            return true;
        } else {
            return false;
        }
    }

    toggleFilterDisplay() {
        if (this.initHidden) {
            this.initHidden = false;
            setTimeout(() => {
                this.showFilter = !this.showFilter;
            }, 10);
        } else {
            this.showFilter = !this.showFilter;
        }
    }

    resetAllFilter(): void {
        this.dateEnvoie.isDateRangeValid = true;
        this.isDateRangeValid = true;

        if (!this.isAuthorized()) {
            this.initFilter();
            this.filters = new MvFinanciersFilterRaw();
            this.launchSearch();
            this.mvFinancierFilterSvc.reset();
        } else {
            this.filters = new MvFinanciersFilterRaw();
            this.rowCount = 0;
            this.mvFinancierFilterSvc.reset();
            this.updateFilterList(this.filters);
            this.clearGridFromData();
        }
        this.montantMfMinError = false;
        this.montantMfMaxError = false;
        sessionStorage.removeItem('mvFinancierFilters');
    }
    onKeyDown(event) {
        this.launchSearch();
    }
    onChoiceChange($event: any, type: string): void {
        if ($event.filterChoice === "EXACTE") {
            this.filters[type + 'From'] = null;
            this.filters[type + 'To'] = null;
        } else {
            this.filters[type] = null;
        }
    }

    checkDateRangeValidity(event) {
        if (this.dateEnvoie && this.dateEnvoie.popDebut && this.dateEnvoie.popFin) {
            this.isDateRangeValid = event;
            this.dateEnvoie.hideTooltlipDebut();
            this.dateEnvoie.hideTooltlipFin();
        }
    }

    showErrorTooltip(type: string): void {
        switch (type) {
            case 'Exacte': this.tooltipExacte.show(); break;
            case 'From': this.tooltipFrom.show(); break;
            case 'To': this.tooltipTo.show(); break;
            default: break;
        }
    }

    hideErrorTooltip(type: string): void {
        switch (type) {
            case 'Exacte': this.tooltipExacte.hide(); break;
            case 'From': this.tooltipFrom.hide(); break;
            case 'To': this.tooltipTo.hide(); break;
            default: break;
        }
    }
    isFormValid(): boolean {
        let lotInputValue = true;
        if (this.filters.numFactureTo && this.filters.numFactureFrom &&
            Number(this.filters.numFactureTo) < Number(this.filters.numFactureFrom)
        ) {
            lotInputValue = false;
            this.numeroFactureError = true;
            this.showErrorTooltip('From');
            this.showErrorTooltip('To');
        } else {
            this.numeroFactureError = false;
            this.hideErrorTooltip('From');
            this.hideErrorTooltip('To');
        }
        if (this.filters.montantMfFrom && this.filters.montantMfTo && Number(this.filters.montantMfTo) < Number(this.filters.montantMfFrom)) {
            lotInputValue = false;
            this.montantMfMinError = true;
            this.montantMfMaxError = true;
        }

        if (this.filters.numFacture && this.filters.numFacture.length < 3) {
            lotInputValue = false;
            this.numeroFactureExacteError = true;
            this.showErrorTooltip('Exacte');
        } else {
            this.numeroFactureExacteError = false;
            this.hideErrorTooltip('Exacte');
        }

        if (this.filters.jourComptableFrom && this.filters.jourComptableTo) {
            let newDate = new Date(this.filters.jourComptableFrom);
            let newDate2 = new Date(this.filters.jourComptableTo);
            if (( newDate.getTime() > newDate2.getTime()) || !this.isDateRangeValid) {
                this.dateAccordion.expansionPannel.open();
                this.dateEnvoie.showTooltipDebut();
                this.dateEnvoie.showTooltipFin();
                lotInputValue = false;
            } else {
                this.dateEnvoie.hideTooltlipDebut();
                this.dateEnvoie.hideTooltlipFin();
            }
        }
        return lotInputValue;
    }
    onNumeroFseFilterChange(type: string): void {
        if (type === 'Exacte') {
            if (this.filters.numFactureFrom != null || this.filters.numFactureTo != null) {
                this.filters.numFactureFrom = null;
                this.filters.numFactureTo = null;
                this.numeroFactureError = false;
            }
        } else if (this.filters.numFacture != null) {
            this.filters.numFacture = null;
            this.numeroFactureExacteError = false;
        }
    }

    exportFileXlsxModal(){
        if (this.rowCount > 0 && this.rowCount <= 1000) {
            this.exportToExcel();
        } else if (this.rowCount > 1000) {
            this.openedModal = this.modalService.show(ExportModalComponent,  { initialState: {
                    nbrElement: this.rowCount
                }, class: 'modal-lg'});
            this.openedModal.content.exportEvent.subscribe((e: any) => {
                if (e.action === ExportMode.EXPORT) {
                    this.exportToExcel();
                }
                if (e.action === ExportMode.EMAIL) {
                    this.exportByEmail();
                }
            });
        }
    }

    exportToExcel(){
        this.export = true;
        this.exportState = {
            message : "Vous allez exporter <b>" + this.transformService.transform(this.rowCount) + " ligne(s)</b>, veuillez patienter quelques instants.",
            state: State.INPROGRESS
        }
        const sorts = gridConvertSort(this.gridHelper.gridSortModel(), []);
        const columns = this.gridHelper.gridColumnApi.getAllDisplayedColumns()
            .filter(column => column.getColDef().headerName != "")
            .map(column => column.getColDef().field);
        this.mouvementFinancierService.exportMouvementsFinanciers(
            {
                idOct: this.currentOct ? this.currentOct.idOct : '',
                sorts
            },
            this.mouvementsFinanciersDataRaw,
            columns,
        ).subscribe((response)=> {
            saveAs(response.file, response.fileName);
            this.exportState = {
                message : "Votre fichier a été téléchargé avec succès.",
                state: State.SUCCESS
            }
        },() => {
            this.exportState = {
                message : "Le téléchargement de votre fichier a rencontré un problème.",
                state: State.FAILED
            }
        });
    }
    exportByEmail(){
        const columns = this.gridHelper.gridColumnApi.getAllDisplayedColumns()
            .filter(column => column.getColDef().headerName != "")
            .map(column => column.getColDef().field);
        const sorts = gridConvertSort(this.gridHelper.gridSortModel(), []);
        this.mouvementFinancierService.exportMouvementsFinanciersByEmail(
            {
                idOct: this.currentOct ? this.currentOct.idOct : '',
                sorts
            },
            this.mouvementsFinanciersDataRaw,
            columns,
        ).subscribe(()=> {
            // when email received
        });
    }
    closeAlert() {
        this.export = false;
    }
    ngOnDestroy(): void {
        sessionStorage['mvFinancierFilters'] = JSON.stringify(this.filters);
        sessionStorage['mvFinancierCurrentPage'] = this.gridHelper.gridApi.paginationGetCurrentPage();
        this.gridHelper.saveGridStatePrefix();
    }
}