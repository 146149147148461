import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'pointer-solder-modal',
    templateUrl: './pointer-solder-modal.component.html',
    styleUrls: ['./pointer-solder-modal.component.scss']
})
export class PointerSolderModalComponent implements OnInit {
    @Input() modalEvent: any;
    @Input() title: string;
    @Input() isSolderForm: boolean;
    @Input() motifs: string[];
    pointerSolderForm: UntypedFormGroup;
    isPointerSolderFormSubmitted = false;
    otherMotif = '';
    isOtherMotifSeleted = false;
    erreur

    constructor(private modalRef: BsModalRef, private fb: UntypedFormBuilder) {}

    ngOnInit() {
        this.initSolderForm();
        this.modalEvent.subscribe(data => {
            if (data && data.message === 'erreur') {
                this.erreur = `Le mot clé "${data.data}" est déjà utilisé pour une règle Toujours pointer, veuillez le modifier.`
            }
            else if (data && data.message === 'hide') {
                this.erreur = null
                this.closeModal()
            }
        })
    }

    initSolderForm() {
        this.pointerSolderForm = this.fb.group({
            'motif': [this.motifs[0]],
            'otherMotif': [''],
            'pointerKeyword': ['']
        });
    }

    onMotifChange(motif) {
        this.erreur=null
        this.isOtherMotifSeleted = motif === 'Autre motif';
        if (motif === 'Autre motif') {
            this.pointerSolderFormFields.otherMotif.setValidators([Validators.required]);
        } else if (motif === 'Toujours pointer') {
            this.pointerSolderFormFields.pointerKeyword.setValidators([Validators.required]);
        } else {
            this.pointerSolderFormFields.otherMotif.clearValidators();
            this.pointerSolderFormFields.pointerKeyword.clearValidators();
        }
        this.pointerSolderFormFields.otherMotif.updateValueAndValidity();
        this.pointerSolderFormFields.pointerKeyword.updateValueAndValidity();
    }

    onSubmit() {
        this.erreur=null
        this.isPointerSolderFormSubmitted = true;
        if (this.pointerSolderForm.invalid) {
            return;
        }
        switch (this.pointerSolderForm.get('motif').value) {
			case 'Autre motif':
                this.modalRef.content.motif = ('' + this.pointerSolderForm.get('otherMotif').value).toLowerCase() === 'toujours solder' ? 'Toujours solder' : this.pointerSolderForm.get('otherMotif').value;
                break;
			case 'Toujours pointer':
                this.modalRef.content.motif = this.pointerSolderForm.get('motif').value;
                this.modalRef.content.pointerKeyword = this.pointerSolderForm.get('pointerKeyword').value;
                break;
			default:
                this.modalRef.content.motif = this.pointerSolderForm.get('motif').value;
                break;
		}
        this.modalEvent.emit({message:'valider',data:null})   
    }

    closeModal() {
        this.modalRef.hide();
    }

    get pointerSolderFormFields() {
        return this.pointerSolderForm.controls;
    }

    setPointerKeyword(event){
       this.erreur=null       
    }
}
