<div class="filter-top">
    <button
        type="button"
        name="btn_display_filter"
        title="'Fermer les filtres'"
        class="exit"
        (click)="onCloseFilters()"
    >
        <i class="fa fa-times"></i>
    </button>

    <button
        type="button"
        name="btn_init_filter"
        title="Réinitialiser le filtre"
        class="reinit"
        (click)="onResetFilters()"
    >
        <i class="fa fa-refresh"></i>
    </button>
    <button type="button" name="btn_launch_filter" class="search" [disabled]="disablesearch" (click)="onLaunchSearch()">
        Appliquer les filtres
    </button>
</div>
