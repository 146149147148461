<div class="x-large-careweb-container" *ngIf="!isFichePs">
    <div class="grid-container" id="grid">
        <h2 class="title-border mt-3">Mes comptes bancaires
        </h2>
        <div class="row" style="margin-top:10px">
            <div *ngFor="let pe of comptesAssocies" class="col-md-6">
                <div class="box-bank" >
                    <div class="box-bank-content" [style.background-color]="pe.actif ? '#fff' : '#C0C0C0'">
                        <div class="row title">
                            <div class="col-md-2">
                                <div class="imgebics"></div>
                            </div>
                            <div class="col">
                                <div>
                                    <div class="title text-primary">{{pe.ebicsParams.bankName}}<span *ngIf="!pe.actif" style="color: red;"> (Compte désactivé)</span></div>
                                    <div class="subtitle">ID ABONNEMENT: {{pe.ebicsParams.ebicsCustomerId}}</div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <ng-container *ngIf="userConnected.idTypeUser!=2">
                                    <button type="button" *ngIf="pe.actif" class="btn btn-primary float-right" data-toggle="tooltip" title="Désactiver"
                                            (click)="toggleEbicsAccount(pe.id, pe.idPs)"><i class="fa fa-unlock-alt"></i>
                                    </button>
                                    <button type="button" *ngIf="!pe.actif" class="btn btn-primary float-right" data-toggle="tooltip" title="Activer"
                                            (click)="toggleEbicsAccount(pe.id, pe.idPs)"><i class="fa fa-lock"></i>
                                    </button>
                                </ng-container>
                            </div>
                            <div class="col-md-2" *ngIf="userConnected.idTypeUser!=2">
                                <ng-container *ngIf="userConnected.idTypeUser!=2">
                                    <button type="button" (click)="openConfirmModal(pe.id)" class="btn btn-primary" data-toggle="tooltip" title="Désynchroniser"><i class="fa fa-chain-broken"></i>
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                        <div class="row infos">
                            <div class="col">
                                <div class="row">
                                    <div class="col-md-3">Référence</div>
                                    <div class="col-md-9">{{pe.iban}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">Ajouté le</div>
                                    <div class="col-md-9">
                                        {{pe.createdDate | date:'yyyy-MM-dd HH:mm'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row infos">
                            <div class="col lastSync">
                                <div class="row">
                                    <div class="col-md-6">Dernière synchro effectuée</div>
                                    <div class="col-md-6">
                                        {{pe.lastSyncDate | date:'yyyy-MM-dd HH:mm'}}

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">Statut</div>
                                    <div class="col-md-6" [ngClass]="{
                                                'text-success': pe.lastSyncStatut === 'SUCCESS',
                                                'text-danger': pe.lastSyncStatut !== 'SUCCESS'}">
                                        {{pe.lastSyncStatut}}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row" style="margin-top:10px" *ngIf="isFichePs">
    <div *ngFor="let pe of comptesAssocies" class="col-md-6">
        <div class="box-bank">
            <div class="box-bank-content" [style.background-color]="pe.actif ? '#fff' : '#C0C0C0'">
                <div class="row title">
                    <div class="col-md-2">
                        <div class="imgebics"></div>
                    </div>
                    <div class="col">
                        <div>
                            <div class="title text-primary">{{pe.ebicsParams.bankName}}<span *ngIf="!pe.actif" style="color: red;"> (Compte désactivé)</span></div>
                            <div class="subtitle">ID ABONNEMENT: {{pe.ebicsParams.ebicsCustomerId}}</div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <ng-container *ngIf="userConnected.idTypeUser!=2">
                            <button type="button" *ngIf="pe.actif" class="btn btn-primary float-right" data-toggle="tooltip" title="Désactiver"
                                (click)="toggleEbicsAccount(pe.id, pe.idPs)"><i
                                    class="fa fa-unlock-alt"></i>
                            </button>
                            <button type="button" *ngIf="!pe.actif" class="btn btn-primary float-right" data-toggle="tooltip" title="Activer"
                                (click)="toggleEbicsAccount(pe.id, pe.idPs)"><i
                                    class="fa fa-lock"></i>
                            </button>
                        </ng-container>
                    </div>
                    <div class="col-md-2">
                        <ng-container *ngIf="userConnected.idTypeUser!=2">
                            <button type="button" (click)="openConfirmModal(pe.id)" class="btn btn-primary" data-toggle="tooltip" title="Désynchroniser"><i class="fa fa-chain-broken"></i>
                            </button>
                        </ng-container>
                    </div>
                </div>
                <div class="row infos">
                    <div class="col">
                        <div class="row">
                            <div class="col-md-3">Référence</div>
                            <div class="col-md-9">{{pe.iban}}</div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">Ajouté le</div>
                            <div class="col-md-9">
                                {{pe.createdDate | date:'yyyy-MM-dd HH:mm'}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row infos">
                    <div class="col lastSync">
                        <div class="row">
                            <div class="col-md-6">Dernière synchro effectuée</div>
                            <div class="col-md-6">
                                {{pe.lastSyncDate | date:'yyyy-MM-dd HH:mm'}}

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">Statut</div>
                            <div class="col-md-6" [ngClass]="{
                                        'text-success': pe.lastSyncStatut === 'SUCCESS',
                                        'text-danger': pe.lastSyncStatut !== 'SUCCESS'}">
                                {{pe.lastSyncStatut}}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal confirmation de suppression -->
<careweb-confirm-modal (confirmModal)="deleteEbicsAccount()" (showModal)="showModal($event)" [(visible)]="showConfirmModal"
    [title]="modalMessage" labelValidButton="Désynchroniser" [text]="modalBody" showAlert="true" labelCancelButton="Annuler">
</careweb-confirm-modal>