import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ScrollTopService } from '../../../../core/utilities/scroll-top.service';

@Component({
	selector: 'careweb-app-ps-etablissement',
	templateUrl: './ps-etablissement.component.html',
	styleUrls: [ './ps-etablissement.component.scss' ]
})
export class PsEtablissementComponent implements OnInit {
	@Input() idPs: Number;
	active = 1;
	constructor(private scrollTopService: ScrollTopService, private modalRef: BsModalRef) {}
	
	ngOnInit() {
		this.scrollTopService.setScrollTop();
	}

	closeModal() {
		this.modalRef.hide();
	}
}