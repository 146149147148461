export class SiteEmissionDataRaw {
	idSite: Number;
	idPs: Number;
	idOct: Number;
	balEmettrice: String;
	typeRetourSouhaite: String;
	typeTransport: Number;
	envoisCompresses: String;
	envoiJdre: String;
	actif: Number;
	siteReroute: Number;
	envoiART: Boolean;
	rph2: Boolean;
	siteReroutage: SiteEmissionDataRaw;
	identifiantXModem: String;
	identifiant: String;
	balEmettriceSecondaire: String;
	serveurPop: String;
	nomOCT: String;
	motDePasse: String;
	gen: String;
	numeroMaitre: String;
	numeroEsclave: String;
	parDefaut: Boolean;
	idTypeTransport: Number;
	idSiteExclu: Number;
	idSiteReroutage: Number;
}
