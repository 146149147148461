import { Component, AfterViewInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Router } from '@angular/router';

@Component({
	selector: 'careweb-lot-preview',
	template: `
        <i
			[ngClass]="iconClass"
            container="body"
			(click)="previewLot()"
			[tooltip]="tooltip"
            containerClass="tooltip-grid"
			(contextmenu)="onRightClick($event)"
		></i>
    `
})
export class RspPreviewComponent implements ICellRendererAngularComp, AfterViewInit {
	iconClass: string;
	tooltip: string;
	private params: any;

	constructor(private router: Router) {}

	agInit(params: any): void {
		this.params = params;
	}

	ngAfterViewInit() {
		const cellParams = this.params;
		if (cellParams.data) {
			// Colonne options pour afficher le détail
			if (cellParams.colDef.field && cellParams.colDef.field === 'options') {
				this.iconClass = cellParams.iconClass;
				this.tooltip = 'Consulter détails de la RSP';
			} else if (cellParams.colDef.field && cellParams.colDef.field === 'idFacture') {
				// Colonne rapprochement
				if (cellParams.value === null) {
					(this.iconClass = 'fa fa-exclamation'),
						(this.tooltip = 'Anomalie détectée: aucune FSE retrouvée pour un rapprochement');
				}
			}
		}
	}

	refresh(): boolean {
		return false;
	}

	previewLot(): void {
		if (this.params.redirect === 'rspDetails') {
			this.router?.navigate([ '/rsp-details', this.params.data.idRsp]);
		}
		if (this.params.redirect === 'rspDetailsFse') {
		this.router?.navigate([ '/rsp-details', this.params.data.codeRsp]);
		}
	}

	onRightClick(event: MouseEvent): void {		
		if (this.params.redirect === 'rspDetails') {
			event.preventDefault();
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/careweb-client/rsp-details',  this.params.data.codeRsp])
			);
			window.open(url, '_blank');
		}
	}
}
