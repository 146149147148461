import { Component, AfterViewInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'careweb-rapp-auto-count',
	template: `
    <span 
    [ngClass]="iconClass"
    container="body"
    containerClass="tooltip-grid" 
    [tooltip]="tooltip">{{count}}</span>
    `
})
export class RappAutoCountComponent implements ICellRendererAngularComp, AfterViewInit {
	iconClass: string;
	tooltip: string;
	count: string;
	private params: any;

	constructor() {}
	refresh(params: any): boolean {
		return false;
	}

	agInit(params: any): void {
		this.params = params;
	}

	ngAfterViewInit() {
		const cellParams = this.params;
		if (cellParams.data) {
			// Colonne options pour afficher le détail
			if(cellParams.colDef.field) {
                let countTotal = 0;
                if (cellParams.colDef.field == 'nbRsp')
                    countTotal = cellParams.data.nbRsp;
                else if (cellParams.colDef.field == 'nbTransactions')
                    countTotal = cellParams.data.nbTransactions;

                if(countTotal > 1){
                    this.iconClass = 'bullet-rapp-count';
                    this.count = countTotal.toString();
                    if (cellParams.colDef.field == 'nbRsp'){
                        let multipleVRT = (cellParams.data.countBankTransactions) > 1 ? 'ces' : 'ce' ; 
                        this.tooltip = countTotal + " RSP correspondent à " + multipleVRT + " VRT";
                    }else if (cellParams.colDef.field == 'nbTransactions'){
                        let multipleRSP = (cellParams.data.countRsps) > 1 ? 'ces' : 'ce' ; 
                        this.tooltip = countTotal + " VRT correspondent à " + multipleRSP + " RSP";
                    }
                        
                }
            }
            
            // Append cursor pointer
			this.iconClass = this.iconClass + ' cursor-pointer';
		}
	}

	previewRapprochement(): void {}
}
