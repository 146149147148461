import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { OctService } from '../../core/services/oct.service';
import * as _ from 'lodash-es';

@Injectable()
export class IsOctAllowedAccessGuard implements CanActivate {
	constructor(private octSvc: OctService, private router: Router ,) {}
	
	octCodsList: String[] = [];

	canActivate() {
		let currentUser = JSON.parse(localStorage.getItem('careweb_user'));
		const allowedOct: any[] = [ 'QTP', 'ALL', 'CDS', 'SAN' ];
		let selectedOctCode: string;
		let canActive: boolean;
		
		if(currentUser.idTypeUser == 2){
			this.octCodsList = JSON.parse(localStorage.getItem('psOctsCods'));
			const intersection = _.intersection(allowedOct , this.octCodsList);
			canActive = intersection.length > 0 ;
			if (!canActive) {
				this.router.navigate([ '/ps' ]);
			}						
		}else{
			this.octSvc.currentOctSubject.subscribe((oct) => {
				if (!isNullOrUndefined(oct)) {
					selectedOctCode = oct.code;	
					canActive = allowedOct.indexOf(selectedOctCode) !== -1;
					if (!isNullOrUndefined(canActive) && !canActive) {
						this.router.navigate([ '/ps' ]);
					}
				} else {
					const parsedOctSelected = JSON.parse(localStorage.getItem('octCurrent'));
					if (!isNullOrUndefined(parsedOctSelected)) {
						selectedOctCode = parsedOctSelected.code;
					} else {
						selectedOctCode = 'QTP';
					}
					canActive = allowedOct.indexOf(selectedOctCode) !== -1;
					if (!isNullOrUndefined(canActive) && !canActive) {
						this.router.navigate([ '/ps' ]);
					}
				}
			});
		}
		
		return canActive;
	}
}
