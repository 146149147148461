import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { AuthentificationGuard } from './core/guards/auth.guard';
const routes: Routes = [
	{
		path: '',
		redirectTo: 'login',
		pathMatch: 'full'
	},
	{
		path: '',
		component: AdminLayoutComponent,
		canActivate: [ AuthentificationGuard ],
		canActivateChild:  [ AuthentificationGuard ],
		children: [
			{
				path: '',
				loadChildren: () => import('./layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
			}
		],
	},
	{
		path: '',
		component: AuthLayoutComponent,
		children: [
			{
				path: '',
				loadChildren: () => import('./layouts/auth-layout/auth-layout.module').then(m => m.AuthLayoutModule)
			}
		]
	},
	{
		path: '**',
		redirectTo: '404'
	}
];

@NgModule({
	imports: [ CommonModule, BrowserModule, RouterModule.forRoot(routes, { useHash: false }) ],
	exports: []
})
export class AppRoutingModule {}
