<div>
    <div class="filter-date-input form-group checkbox-filter">
        <label>Entre le</label>
        <p class="input-group no-margin">
           <input  type="text"  [owlDateTime]="dt" [owlDateTimeTrigger]="dt" name="dateMin" size="25" placement="right" [readonly]="true"
                    [(ngModel)]="modelStart"   (ngModelChange)="onChangeInputFrom($event)"
                   placeholder="Choisir une date" autocomplete="off"
                    max="{{maxDate | date:'yyyy-MM-ddTHH:mm:ss'}}"
                    (keydown.enter) = "onKeyEnter2()"
           >
            <label class="example-trigger" [owlDateTimeTrigger]="dt">
            <i class="fas fa-calendar-alt"></i>
            </label>
            <owl-date-time [showSecondsTimer]="true"  #dt></owl-date-time >
            <label class="text-danger" [hidden]="tooltipStart" ><small>Indiquer une date antérieure</small></label>
            <label class="second_label" style="margin-top:15px ; margin-bottom:15px"> Et le</label>

            <input  type="text"  [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" [readonly]="true"
                    name="dateMax" placeholder="Choisir une date" autocomplete="off"
                    size="25"  placement="right"   [(ngModel)]="modelEnd"
                    (ngModelChange)="onChangeInputTo($event)"
                    [min]= modelStart  max="{{maxDate | date:'yyyy-MM-ddTHH:mm:ss'}}"
                    (keydown.enter) = "onKeyEnter()"
            >
            <owl-date-time [showSecondsTimer]="true"  #dt2></owl-date-time >
            <label class="text-danger" [hidden]="tooltipEnd" ><small>Indiquer une date postérieure</small></label>
        </p>
    </div>
</div>
