<careweb-help></careweb-help>
<div class="full-careweb-container">
  <div class="grid-container" id="grid">
    <h2 class="title-border mt-3">Retours éditables
      <careweb-open-help code="help-005"></careweb-open-help>
    </h2>
    <div>
      <section>
        <div class="form-block">
          <div class="row">
            <div class="col">
              <h3 class="title-border">
                Recherche des retours
              </h3>
            </div>
          </div>
          <fieldset class="container-fluid">
            <form #retourForm="ngForm" class="form-content" (ngSubmit)="getRetoursList()" appCompareDate>
            <div class="row">
              <div class="col-sm-2 col-form-label">Type de retour</div>
              <div class="col-sm-4 form-group" style="display:cell">
                <ng-select name="signeArl" id="signeArlSelector" placeholder="Type de retour" [searchable]="false"
                  [(ngModel)]="filters.typeRetour" appendTo="body" [clearable]= "false">
                  <ng-option [value]="'art'">Accusés de réception technique</ng-option>
                  <ng-option [value]="'rph'">Retour RPH (paiements/rejets)</ng-option>
                </ng-select>
              </div>
              <div class="col-sm-2 col-form-label" [hidden]="!existGroupNumeroPS()">Numéro ADELI</div>
                <div class="col-sm-4 form-group" [hidden]="!existGroupNumeroPS()"> 
                  <input type="text" id="input_num_ps" name="numPs" #numPs="ngModel" [(ngModel)]="filters.numeroAdeli"
                    onKeyPress="if(this.value.length==9) return false;" min="0" step="1" max="999999999"
                    oninput="this.value=this.value.replace(/[^\d]/,'');"
                    onpaste="if(this.value.length==9) return false;" [required]="isAuthorized()" [hidden]="!isAuthorized()"/>

                              <!--Afficher Numéro de PS avec ComboBox si l'utilisateur PS-->
                    <ng-select name="numPs" id="numeroPScomboBox" #numPs="ngModel" placeholder="Sélectionner un PS" [searchable]="false"
                      [(ngModel)]="supervisedPs" appendTo="body" [hidden]="isAuthorized()">
                      <ng-option *ngFor="let numeroPScomboBox of psDelegueListFilter" [value]="numeroPScomboBox">
                        {{numeroPScomboBox.numeroPsDelegue}}
                      </ng-option>
                    </ng-select>
                    <careweb-error-messages [field]="numPs" [touched]="true">
                    </careweb-error-messages>
                </div>
            </div>
            <div class="row">
              <div class="col-sm-2 col-form-label">Date de début</div>
              <div class="col-sm-4 form-group required">
                <div class="field">
                 <input type="text" class="form-control" name="dateMin" bsDatepicker
                  [(ngModel)]="filters.dateEnvoieFrom" [maxDate]="filters.dateEnvoieTo ? filters.dateEnvoieTo : currentDate" size="25" placement="bottom"
                  [bsConfig]="{ containerClass: 'theme-kiss', dateInputFormat: 'DD/MM/YYYY'  }" placeholder="Choisir une date" autocomplete="off"
                  (bsValueChange)="setRetourMaxDate($event)" #dateMin="ngModel" (change)="setRetourMaxDate($event);onChangeInput($event)"
                  (blur)="setRetourMaxDate($event)" (input)="setRetourMaxDate($event)" [required]="true"/>
                  <span class="error-messages"
                          *ngIf="(retourForm.submitted || dateMin.touched)  && dateMin.errors?.required">
                          Ce champ est obligatoire
                  </span>
                    <span class="error-messages"
                          *ngIf="!dateMin.errors?.required && !isDateRangeValid">
                          Veuillez respecter la période de recherche d'un mois maximum
                          </span>
                </div>
              </div>
              <div class="col-sm-2 col-form-label">Date de fin</div>
              <div class="col-sm-4 form-group required">
                <div class="field">
                <input type="text" class="form-control" name="dateMax" id="dateMax" #dateMax="ngModel" bsDatepicker
                  [(ngModel)]="filters.dateEnvoieTo" [maxDate]="retourMaxDate ? retourMaxDate : currentDate" size="25" placement="bottom" (change)="onChangeInput($event)"
                  [bsConfig]="{ containerClass: 'theme-kiss', dateInputFormat: 'DD/MM/YYYY' }" placeholder="Choisir une date" [minDate]="filters.dateEnvoieFrom" [required]="true" autocomplete="off"/>
                  
                  <span class="error-messages" *ngIf="(retourForm.submitted || dateMax.touched) && dateMax.errors?.required">
                          Ce champ est obligatoire
                  </span>
                 <span class="error-messages" *ngIf="(dateMax.touched || dateMax.dirty )&& retourForm.errors?.dateMaxError && !dateMax.errors?.required">
                          La date de fin doit être supérieur à la date de début
                 </span>
                 <span class="error-messages"
                          *ngIf="!dateMax.errors?.required && !isDateRangeValid">
                          Veuillez respecter la période de recherche d'un mois maximum
                          </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-form-label text-info">
                <i class="fa fa-info me-2"></i>La fourchette maximale de recherche pour ce document est de 1 mois
              </div>
            </div>
            <div class="row" style="display:flex; text-align:right">
              <div class="col-md-12 text-title">
                <button class="btn btn-default btn-lg" type="submit" [disabled]="haveSupervisedPss() && !isAuthorized()&& supervisedPs==null">Rechercher</button><!--  (click)="getRetoursList()" -->
              </div>
            </div>
<!--             <div class="row">
              <div class="col-sm-12 form-group required">
                <label for="emailAdress">Adresse email</label>
                <div class="field">
                  <input type="email" name="emailAdress" name="emailAdress" class="form-control" #emailAdress="ngModel" (paste)="$event.preventDefault()" [(ngModel)]="connectData.emailAdress" [required]="true" carewebEmail/>
                  <careweb-error-messages [field]="emailAdress" [touched]="true"> </careweb-error-messages>
                </div>
              </div>
            </div> -->
          </form>
          </fieldset>
        </div>
        <div class="form-block" [hidden]="!showGrid">
          <div class="row">
            <div class="col">
              <h3 class="title-border">
                <div class="row">
                  <div class="col-auto me-auto">Résultats de la recherche</div>
                  <div class="col-auto">{{retourNumber}} retours</div>
                </div>
              </h3>
            </div>
          </div>
          <fieldset class="container-fluid">
            <div class="row highlight-row">
              <div class="col-md-12 relative">
                <ag-grid-angular style="width: 100%" class="ag-theme-balham" [gridOptions]="gridHelper.gridOptions">
                </ag-grid-angular>
                <careweb-pagination [gridApi]="gridHelper.gridApi"></careweb-pagination>
              </div>
            </div>
          </fieldset>
        </div>
      </section>
      
      </div>
    </div>
  </div>
