import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'careweb-check-loi',
	template: `
    <input type="radio" name="this.params.value" style="width:15px; height: 15px;" [value]="this.params.value"
        [(ngModel)]="selectedPs" (ngModelChange)="onChange($event)" />
	`
})
export class CheckLoiComponent implements ICellRendererAngularComp {
	params: any;
	selectedPs;

	constructor() {}

	agInit(params: any): void {
		this.params = params;
		this.selectedPs = this.params.value;
	}

	onChange($event) {
		this.params['selectedPs'] = $event;
	}

	refresh(): boolean {
		return false;
	}
}
