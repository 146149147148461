import { OnInit, Component } from '@angular/core';
import { SelService } from '../sel.service';
import { AgGridHelper } from '../../../components/ag-grid/ag-grid-helper';
import { GridOptions, ColDef } from 'ag-grid-enterprise';
import { FilterItem } from '../../../data/filters/filter-item';
import { uniqBy, findIndex, isEmpty } from 'lodash-es';
import { OrgStateFilterRaw } from '../../../data/filters/org-state-filter';

@Component({
	selector: 'app-service-directory-account',
	templateUrl: './service-directory.component.html'
})
export class serviceDirectoryComponent implements OnInit {
	gridHelper: AgGridHelper;
	paginationSize: number;
	filters = new OrgStateFilterRaw();
	filterList: FilterItem[];
	showFilter = false;
	initHidden = true;

	constructor(private selService: SelService) {
		// Body styling
		let body = document.body;
		body.className = 'app';
		this.gridHelper = new AgGridHelper('sel', 10);
	}

	ngOnInit() {
		// Init filters
		var parsedFilter = JSON.parse(sessionStorage.getItem('selFilter'));
		// Get filter stored in session if exist
		if (!isEmpty(parsedFilter)) {
			this.filters = Object.assign({}, parsedFilter);
		} else {
			this.filterList = [new FilterItem()];
		}

		this.gridHelper.gridOptions = <GridOptions>(<unknown>{
			columnDefs: this.getColumnDefs(),
			defaultColDef: {
				resizable: true
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 10,
			maxBlocksInCache: 0,
			infiniteInitialRowCount: 1,
			paginationPageSize: 10,
			paginateChildRows: true,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			localeText: this.gridHelper.getLocaleText(),
			onGridReady: (params) => this.onGridReady(params)
			// onGridSizeChanged: (params) => this.onGridSizeChanged(params)
		});
		this.paginationSize = this.gridHelper.paginationPageSize;
	}

	private getColumnDefs(): ColDef[] {
		return [
			{
				headerName: 'N° AMC/OTP',
				field: 'organizationNumber',
				minWidth: 280,
				lockVisible: true,
				sortable: true
			},
			{
				headerName: 'Libellé AMC/OTP',
				field: 'label',
				minWidth: 180,
				lockVisible: true,
				sortable: true,
				cellStyle: this.gridHelper.centerContent()
			}
		];
	}

	onGridReady(params) {
		this.gridHelper.gridApi = params.api;
		this.gridHelper.gridColumnApi = params.columnApi;
		this.restoreGrid();
		this.initSorting();
		this.gridHelper.gridApi.sizeColumnsToFit();
		this.gridHelper.gridApi.setServerSideDatasource(null);
		this.gridHelper.refreshView();
		var parsedFilter = JSON.parse(sessionStorage.getItem('selFilter'));
		if (!isEmpty(parsedFilter)) {
			this.launchSearch();
		} else {
			// const dataSource: IServerSideDatasource = {
			// 	getRows: function(paramsRows: IServerSideGetRowsParams) {
			// 		const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
			// 		ctx.selService
			// 			.getDistinctOrganizationState({
			// 				page: ctx.gridHelper.gridApi.paginationGetCurrentPage() + 1,
			// 				size: ctx.gridHelper.gridApi.paginationGetPageSize(),
			// 				sorts
			// 			})
			// 			.subscribe(
			// 				(results) => {
			// 					
			// 					let rowCount = results.paging.totalElements;
			// 					ctx.gridHelper.manageNoRowsOverlay(rowCount);
			// 					paramsRows.successCallback(results.data, rowCount);
			// 				},
			// 				() => paramsRows.failCallback()
			// 			);
			// 	}
			// };
			// this.gridHelper.gridApi.setServerSideDatasource(dataSource);
			// this.gridHelper.gridApi.sizeColumnsToFit();
		}
	}

	launchSearch(): void {
		this.filters.organizationNumber = this.filters.organizationNumber
			? this.filters.organizationNumber.trim()
			: null;
		this.updateFilterList(this.filters);

		//this.gridHelper.gridApi.refreshInfiniteCache();
		this.gridHelper.gridApi.setServerSideDatasource(null);
		this.gridHelper.refreshView();
		// const dataSource: IServerSideDatasource = {
		// 	getRows: function(paramsRows: IServerSideGetRowsParams) {
		// 		const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
		// 		ctx.selService
		// 			.getDistinctOrganizationState(
		// 				{
		// 					page: ctx.gridHelper.gridApi.paginationGetCurrentPage() + 1,
		// 					size: ctx.gridHelper.gridApi.paginationGetPageSize(),
		// 					sorts
		// 				},
		// 				ctx.filters
		// 			)
		// 			.subscribe(
		// 				(results) => {
		// 					

		// 					let rowCount = results.paging.totalElements;
		// 					ctx.gridHelper.manageNoRowsOverlay(rowCount);
		// 					paramsRows.successCallback(results.data, rowCount);
		// 				},
		// 				() => paramsRows.failCallback()
		// 			);
		// 	}
		// };

		// this.gridHelper.gridApi.setServerSideDatasource(dataSource);
		sessionStorage['selFilter'] = JSON.stringify(this.filters);
		// this.gridHelper.gridApi.sizeColumnsToFit();
	}

	onGridSizeChanged(params) {
		this.gridHelper.gridApi.sizeColumnsToFit();
	}
	private initSorting() {
		const sort = [
			{
				colId: 'organizationNumber',
				sort: 'asc',
				sortIndex: 0
			}
		];
		return this.gridHelper.applyColumnState(sort);
	}
	restoreGrid() {
		this.gridHelper.restoreGridStatePrefix();
		this.paginationSize = this.gridHelper.paginationPageSize;
	}
	resetGridState() {
		this.gridHelper.resetGridState();
		this.paginationSize = this.gridHelper.defaultPaginationPageSize;
		//this.resetAllFilter();
	}
	toggleFilterDisplay() {
		if (this.initHidden) {
			this.initHidden = false;
			setTimeout(() => {
				this.showFilter = !this.showFilter;
			}, 10);
		} else {
			this.showFilter = !this.showFilter;
		}
	}
	resetAllFilter(): void {
		this.initFilter();
		this.filters = new OrgStateFilterRaw();
		this.launchSearch();
		this.selService.reset();
	}

	initFilter() {
		// Liste des filtres
		this.selService.reset();
		this.updateFilterList(this.filters);
	}

	updateFilterList(filters) {
		var ctx = this;
		ctx.filterList = [];
		Object.keys(filters).forEach((key) => {
			if (filters[key] != null && filters[key] != '') {
				ctx.filterList.push(ctx.translateFilterLabelValue(key, filters[key]));
			}
		});
		// Remove duplicated ids
		ctx.filterList = uniqBy(ctx.filterList, 'id');
		// filter null
		ctx.filterList = ctx.filterList.filter((item) => {
			return item.id != null;
		});
	}

	translateFilterLabelValue(key, value) {
		var translatedKey = key;
		var formattedValue = value;
		var formattedKey = key;
		switch (key) {
			case 'organizationNumber':
				translatedKey = 'N° AMC/OTP';
				break;
			default:
				break;
		}
		return new FilterItem().withId(formattedKey).withLabel(translatedKey).withValue(formattedValue).withValue2(value);
	}

	removeFilter(id) {
		var ctx = this;
		delete ctx.filters[id];
		var currentFilterList = ctx.filterList;
		const index = findIndex(currentFilterList, (filt) => filt.id === id);
		currentFilterList.splice(index, 1);
		ctx.filterList = currentFilterList;
		this.fillFilterObjectFromTagsOnly();
		ctx.launchSearch();
	}

	fillFilterObjectFromTagsOnly() {
		this.filters = new OrgStateFilterRaw();
		this.filterList.forEach(filter => {
			this.filters[filter.id] = filter.value2;
		});
	}
}
