import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONF } from '../../../core/constants';
import { TypePsDataRaw } from '../../../data/typePsDataRaw';

@Injectable({
	providedIn: 'root'
})
export class TypePsService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/ps/types';

	constructor(private httpClient: HttpClient) {}

	getTypePSList(): Promise<any> {
		return this.httpClient.get<any>(this.baseUrl + '/list').toPromise();
	}

	getTypePsById(idTypePs): Observable<TypePsDataRaw> {
		return this.httpClient.get<any>(this.baseUrl + '/' + idTypePs);
	}
}
