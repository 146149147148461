export class TracabilityFilterRaw {
	serviceName: string;
	cgpassExternalReference: string;
	beneficiaryIdentificationNumber: string;
	providerNumber: string;
	returnCodeOK: string;
	startProcessingDate: Date;
	endProcessingDate: Date;
	organizationNumber: string;
}
