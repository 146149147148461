import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CONF} from '../../../core/constants';
import {AmoDataRaw} from '../../../data/amoDataRaw';
import {AmcDataRaw} from '../../../data/amcDataRaw';
import {FilterService} from '../../../core/services/filter-service';
import {isNullOrUndefined} from 'util';
import {CustomSortDataRow} from "../../../data/customSortDatRaw";
import {map} from "rxjs/operators";

@Injectable({
	providedIn: 'root'
})
export class OrganismesService extends FilterService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/organismes';

	constructor(private httpClient: HttpClient) {
		super();
	}

	getOrganismeByIdAndType(criteria: any): Observable < any > {
		if (!isNullOrUndefined(criteria.typeOrganisme) && criteria.typeOrganisme === 'RO') {
			return this.httpClient.get < any > (this.baseUrl + '/amo/' + criteria.idOrganisme);
		} else if (!isNullOrUndefined(criteria.typeOrganisme) && criteria.typeOrganisme === 'RC') {
			return this.httpClient.get < any > (this.baseUrl + '/amc/details/' + criteria.idOrganisme);
		}
	}

	getOrganismesList(criteria: any): Observable < any > {
		return this.httpClient.get < any > (this.baseUrl + '/list?page=' + criteria.page + '&size=' + criteria.size);
	}

	getOrganismesRc(criteria: any, amcDataRow: AmcDataRaw, idPs ? : number): Observable < any > {
		return this.httpClient.post < any > (
			this.baseUrl + '/amc' + '/search?page=' + criteria.page + '&size=' + criteria.size +
			this.getSortParameters(criteria.sorts) + '&idPs=' + idPs, amcDataRow
		);
	}

	getOrganismesRo(criteria: any, amoDataRow: AmoDataRaw): Observable < any > {
		return this.httpClient.post < any > (
			this.baseUrl + '/amo' + '/search?page=' + criteria.page + '&size=' + criteria.size +
			this.getSortParameters(criteria.sorts), amoDataRow
		);
	}

	getAmcByNumOrganisme(criteria: any): Observable < any > {
		return this.httpClient.get < any > (this.baseUrl + '/amc/' + criteria.numOrganismeRc);
	}

	getAmoByCodeOrganismeAndCodeGr(criteria: any): Observable < any > {
		return this.httpClient.get < any > (
			this.baseUrl + '/amo/?codeOrgType1=' + criteria.codeOrgType1 + '&codeGr=' + criteria.codeGr
		);
	}

	getSortParameters(sorts) {
		if (sorts.length === 1) {
			const sort = sorts[0].property;
			return '&sort=' + sort + '&direction=' + sorts[0].direction;
		} else {
			// sort par défaut
			return '&sort=libelleCourt&direction=ASC';
		}
	}

	saveAmo(amo) {
		return this.httpClient.put(this.baseUrl + '/amo', amo).toPromise();
	}

	saveAmc(amc) {
		return this.httpClient.put(this.baseUrl + '/amc', amc).toPromise();
	}

	delete(organismeId, typeOrganisme): Promise < any > {
		if (typeOrganisme === 'RO') {
			return this.httpClient.delete(this.baseUrl + '/amo/' + organismeId).toPromise();
		} else if (typeOrganisme === 'RC') {
			return this.httpClient.delete(this.baseUrl + '/amc/' + organismeId).toPromise();
		}
	}

	getOrganismeTypeById(idOrganisme: number): Observable < any > {
		return this.httpClient.get < any > (this.baseUrl + '/organismType/' + idOrganisme);
	}

	exportAmo(criteria: any, amoDataRaw: AmoDataRaw): Observable < any > {
		return this.httpClient.post(
			this.baseUrl + '/amo/export?' + this.getSortParameters(criteria.sorts).substring(1), amoDataRaw
		)
	}
	exportOrganismeAmc(criteria: any, amcsDataRaw: AmcDataRaw, fields: string[]): Observable<any>{
		const sorts: CustomSortDataRow[] = criteria.sorts;
		return this.httpClient.post<HttpResponse<Blob>>(
			this.baseUrl + '/amc/export?'
			+this.getSortParameters(sorts),
			{
				filters: amcsDataRaw,
				fields: fields
			},
			{
				observe: 'response' as 'body',
				responseType: 'blob' as 'json'
			}
		).pipe(
			map((response: HttpResponse<Blob>): any => {
				return this.getFileInfosFromResponse(response,"Liste-Des-AMC.xlsx");
			})
		);
	}
	exportOrganismeAmo(criteria: any, amosDataRaw: AmoDataRaw, fields: string[]): Observable<any>{
		const sorts: CustomSortDataRow[] = criteria.sorts;
		return this.httpClient.post<HttpResponse<Blob>>(
			this.baseUrl + '/amo/export?'
			+this.getSortParameters(sorts),
			{
				filters: amosDataRaw,
				fields: fields
			},
			{
				observe: 'response' as 'body',
				responseType: 'blob' as 'json'
			}
		).pipe(
			map((response: HttpResponse<Blob>): any => {
				return this.getFileInfosFromResponse(response,"Liste-Des-AMO.xlsx");
			})
		);
	}

	exportOrganismeAmoByEmail(criteria: any, amoDataRaw: AmoDataRaw, fields: string[]): Observable<any> {
		const sorts: CustomSortDataRow = criteria.sorts;
		return this.httpClient.post<HttpResponse<Blob>>(
			this.baseUrl + '/amo/export-by-email?'+ this.getSortParameters(sorts),
			{
				filters: amoDataRaw,
				fields: fields
			},
			{
				observe: 'response' as 'body',
				responseType: 'blob' as 'json'
			}
		);
	}
	exportOrganismeAmcByEmail(criteria: any, amcDataRaw: AmcDataRaw, fields: string[]): Observable<any> {
		const sorts: CustomSortDataRow = criteria.sorts;
		return this.httpClient.post<HttpResponse<Blob>>(
			this.baseUrl + '/amc/export-by-email?'+ this.getSortParameters(sorts),
			{
				filters: amcDataRaw,
				fields: fields
			},
			{
				observe: 'response' as 'body',
				responseType: 'blob' as 'json'
			}
		);
	}
	getFileInfosFromResponse(response: HttpResponse<Blob>,fileName: string) {
		return {
			file: new Blob([ response.body ], { type: 'application/vnd.ms-excel' }),
			fileName: fileName
		};
	}
}