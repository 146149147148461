import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONF } from '../../../core/constants';
import { FilterService } from '../../../core/services/filter-service';

@Injectable({
	providedIn: 'root'
})
export class AssociationService extends FilterService {
	private readonly normeBaseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/norme';
	private readonly versionBaseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/version';
	private readonly assocBaseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/association';

	constructor(private httpClient: HttpClient) {
		super();
	}

	save(association) {
		return this.httpClient.put(this.assocBaseUrl, association).toPromise();
	}

	delete(association) {
		return this.httpClient.request('delete', this.assocBaseUrl, { body: association }).toPromise();
	}

	getRegroupementByTypeZero(idTypeZero): Observable<any> {
		return this.httpClient.get<any>(this.assocBaseUrl + '/list?idTypeZero=' + idTypeZero);
	}

	getNormes(): Observable<any> {
		return this.httpClient.get<any>(this.normeBaseUrl + '/list');
	}

	getVersionsByNormeId(idNorme): Observable<any> {
		return this.httpClient.get<any>(this.versionBaseUrl + '/list?idNorme=' + idNorme);
	}
}
