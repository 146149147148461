import { Component, AfterViewInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'careweb-editeur-preview',
	template: `
        <i
			[ngClass]="iconClass"
            container="body"
			[tooltip]="tooltip"
            containerClass="tooltip-grid"
		></i>
    `
})
export class FicheEditeurRattacherComponent implements ICellRendererAngularComp, AfterViewInit {
	iconClass: string;
	tooltip: string;
	private params: any;

	constructor() {}

	agInit(params: any): void {
		this.params = params;
	}

	ngAfterViewInit() {
		const cellParams = this.params;
		if (cellParams.data) {
			if (cellParams.colDef.field && cellParams.colDef.field == 'options') {
				this.iconClass = cellParams.iconClass;
				this.tooltip = "Rattacher le logiciel";
			}
		}
	}

	refresh(): boolean {
		return false;
	}
}
