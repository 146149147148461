import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomSortDataRow } from '../../data/customSortDatRaw';
import { LogsRapproDataRaw } from '../../data/logsRapproDataRaw';
import { CONF } from '../../core/constants';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class LogsRapproService {

  private readonly baseUrl = `${CONF.envUrl + CONF.appContext + CONF.apiBaseUrl}/v1/logs-rappro`;

  constructor(private http: HttpClient) {}

  getLogsFilteredList(criteria: any, logsDataRaw: LogsRapproDataRaw): Observable<any> {
		const sorts: CustomSortDataRow[] = criteria.sortsLogsRappro;
		return this.http.post<any>(
			this.baseUrl + '/search-filter' + '?page=' + criteria.page + '&size=' + criteria.size,
			{
				filters: logsDataRaw,
				sorts: sorts
			}
		);
	}
	exportLogs(criteria: any, logsrapproDataRaw: LogsRapproDataRaw, fields: string[]): Observable<any> {
	  const sorts: CustomSortDataRow = criteria.sorts;
	  return this.http.post<HttpResponse<Blob>>(
		  this.baseUrl + '/export',
		  {
			  filters: logsrapproDataRaw,
			  fields: fields,
			  sorts: sorts
		  },{
			  observe : 'response' as 'body',
			  responseType : 'blob' as 'json'
		  }
	  ).pipe(
		  map((response: HttpResponse<Blob>): any => {
			  return this.getFileInfosFromResponse(response, "Liste_Journalisation_proc.xlsx");
		  })
	  );
	}
	exportLogsByEmail(criteria: any, logsrapproDataRaw: LogsRapproDataRaw, fields: string[]): Observable<any> {
	  const sorts: CustomSortDataRow = criteria.sorts;
	  return this.http.post<HttpResponse<Blob>>(
		  this.baseUrl + '/export-by-email',
		  {
			  filters: logsrapproDataRaw,
			  fields: fields,
			  sorts: sorts
		  },{
			  observe : 'response' as 'body',
			  responseType : 'blob' as 'json'
		  }
	  );
	}
	getFileInfosFromResponse(response: HttpResponse<Blob>,fileName: string) {
		return {
			file: new Blob([ response.body ], { type: 'application/vnd.ms-excel' }),
			fileName: fileName
		};
	}
}
