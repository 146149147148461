import { Injectable } from '@angular/core';
import { CONF } from '../../../core/constants';
import {HttpClient, HttpResponse} from '@angular/common/http';
import { EbicsParams } from '../../../data/ebicsParams';
import {FilterService} from "../../../core/services/filter-service";
import {AbonnementEbicsFilterRaw} from "../../../data/filters/abonnement-ebics-filter-raw";
import { Observable } from 'rxjs';
import { TypeConnexionRapprochement } from '../../../data/TypeConnexionRapprochement';
import {SuiviEbicsDataRaw} from "../../../data/suiviEbicsDataRaw";
import {CustomSortDataRow} from "../../../data/customSortDatRaw";
import {map} from "rxjs/operators";

@Injectable({
	providedIn: 'root'
})
export class EbicsService extends FilterService{
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/ebics';
	constructor(private httpClient: HttpClient) {
		super();
	}

    getOrderTypesList(): Promise<any> {
		return this.httpClient.get<any>(this.baseUrl + '/ref/order-types').toPromise();
	}
	findTypeCnxForPs(idPs : number): Observable<TypeConnexionRapprochement>{
		return this.httpClient.get<TypeConnexionRapprochement>(this.baseUrl + '/typecnx/'+idPs);
	}
    addEbicsParams(ebicsParams: EbicsParams) {
        return this.httpClient.post<any>(this.baseUrl + '/create/', ebicsParams);
    }
	activateEbicsForPs(idPs: number,value:string) {
        return this.httpClient.get<any>(this.baseUrl + '/activate/'+idPs +'/'+value);
    }

	toggleEbicsAccount(id: number){
		return this.httpClient.post<any>(this.baseUrl + '/toggle/' + id, null);
	}

	deleteEbicsAccount(id: number){
		return this.httpClient.delete<any>(this.baseUrl + '/account/delete/' + id);
	}

	deleteAbonnement(idAbonnement : number){
		return this.httpClient.delete<any>(this.baseUrl + '/delete/'+idAbonnement);
	}
	checkCompteAssocies(idAbonnement : number){
		return this.httpClient.get<any>(this.baseUrl + '/accounts/'+idAbonnement);
	}
	updateEbicsParams(ebicsParams: EbicsParams) {
        return this.httpClient.put<any>(this.baseUrl + '/update/', ebicsParams);
    }
	save(ebicsParams: EbicsParams) {
        return this.httpClient.put<any>(this.baseUrl + '/save/', ebicsParams);
    }

    getEbicsByPsId(psId): Promise<any[]> {
		return this.httpClient.get<any[]>(this.baseUrl + '/' + psId).toPromise();
	}

	getTotalNewTx(psId, requestIds): Promise<number> {
		return this.httpClient.post<number>(this.baseUrl + '/summary/transactions/total/', {psId, requestIds}).toPromise();
	}
	
	getAllAbonnements(criteria: any, abonnementEbicsFilterRaw: AbonnementEbicsFilterRaw){
		return this.httpClient.post<any>(this.baseUrl + '/abonnement?page=' + criteria.page + '&size=' + criteria.size +
			this.getSortParameters(criteria.sorts), abonnementEbicsFilterRaw);
	}
	getAllComptes(criteria: any,idAbonnement:number){
		return this.httpClient.post<any>(this.baseUrl + '/accounts'+'?page=' + criteria.page + '&size=' + criteria.size +
			this.getSortParametersComptes(criteria.sorts),idAbonnement);
	}
	getAllAbonnementsWithoutFilter(){
		return this.httpClient.get<any>(this.baseUrl + '/abonnements' );
	}
	getParsingAbonnements(parsingId) {
		return this.httpClient.get<any>(this.baseUrl + '/parsing/abonements?parsingId=' + parsingId);
	}
	getAllPsEbics(){
		return this.httpClient.get<any>(this.baseUrl + '/comptes' );
	}
	getAbonnementById(id:number){
		return this.httpClient.get<any>(this.baseUrl + '/abonnement/'+id);
	}
	attachPsWithEbicsAccount(currentAssociation): Observable<any> {
		
		return this.httpClient.post<any>(this.baseUrl + '/link', currentAssociation);
	}
	getSortParameters(sorts) {
		if (sorts.length === 1) {
			const sort = sorts[0].property;
			return '&sort=' + sort + '&direction=' + sorts[0].direction;
		} else {
			// sort par défaut
			return '&sort=bankName&direction=ASC';
		}
	}
	getSortParametersComptes(sorts) {
		if (sorts.length === 1) {
			let sort = sorts[0].property;
			if(sort=='idPs') sort= 'ps.idPs';
			if(sort=='numeroAdeli') sort= 'ps.numeroAdeli';
			return '&sort=' + sort + '&direction=' + sorts[0].direction;
		} else {
			// sort par défaut
			return '&sort=ps.numeroAdeli&direction=ASC';
		}
	}
	desactivateEbicsForPs(idPs: number){
        return this.httpClient.get<any>(this.baseUrl + '/desactivate/'+idPs);
    }
	getLastSyncDate(idPs: number){
        return this.httpClient.get<any>(this.baseUrl + '/lastsync/'+idPs);
    }
	

	setHideRappro(ebicsParams: EbicsParams) {
        return this.httpClient.post<any>(this.baseUrl + '/setHideRappro', ebicsParams);
    }

	getSuiviEbicsFilteredList(criteria: any, suiviEbicsDataRaw: SuiviEbicsDataRaw): Observable<any> {
		const sorts: CustomSortDataRow[] = criteria.sortsSuiviEbics;
		return this.httpClient.post(
			this.baseUrl + '/params/summary' + '?page=' + criteria.page + '&size=' + criteria.size,
			{
				filters: suiviEbicsDataRaw,
				sorts: sorts
			}
		);
	}
	getRessourceFile(payloadFilePath: string): Observable<any> {
		return this.httpClient.get(
			this.baseUrl + '/file/' + payloadFilePath,
			{
				observe: 'response' as 'body',
				responseType: 'blob' as 'json'
			}
		).pipe(
			map((response: HttpResponse<Blob>): any => {
				return this.getFileInfosFromResponse(response);
			})
		);
	}
	getFileInfosFromResponse(response: HttpResponse<Blob>) {
		return {
			file: new Blob([ response.body ], { type: 'text/plain' }),
			fileName: 'file.txt'
		};
	}


	getMissingTransactionEbics(missingStartDate: string, missingEndDate: string, idPs : number ,withoutDate : boolean):Observable<any> {
		return this.httpClient.get<any>(
			this.baseUrl +
			'/missing/?missingStartDate='+ missingStartDate +'&missingEndDate='+ missingEndDate +'&idPs=' + idPs + '&withoutDate=' + withoutDate
		);
	}

	renouvlerCerif(params: any) {
		return this.httpClient.post<any>(
			this.baseUrl +'/certificate/'+params,null);
	}
}
