import { Injectable } from '@angular/core';
import {Workbook} from "exceljs";

@Injectable({
  providedIn: 'root'
})
export class ZipService {

  constructor() { }

  generateExcelFile(json: any[],fileName: string,columns: any[]):Promise<any>  {
    return new Promise((resolve, reject) => {
      const header = columns;
      const data = json;
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(fileName);
      const headerRow = worksheet.addRow(header);
      headerRow.eachCell((cell,index) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: '6495ED'},
          bgColor: {argb: 'FFF00FFF'}
        };
        cell.border = {top:{style:'thin'},left:{style:'thin'},bottom:{style:'thin'},right:{style:'thin'}};
        cell.font=  {size:12,bold:true};
        worksheet.getColumn(index).width = header[index-1].length<20?20:header[index-1].length;
      });
      let columnsArray: any[];
      for(const key in json){
        if(json.hasOwnProperty(key)){
          columnsArray = Object.keys(json[key]);
        }
      }
      data.forEach((element: any) => {
        const eachRow:any = [];
        columnsArray.forEach((column)=>{
          eachRow.push(element[column]);
        });
        if(element.isDeleted === 'Y'){
          const deletedRow: any = worksheet.addRows(eachRow);
          deletedRow.eachCell((cell:any)=>{
            cell.font = {name: 'calibri',family:4,size:11,bold:false,strike:true};
          });
        }else{
          worksheet.addRow(eachRow);
        }
      });

      workbook.xlsx.writeBuffer().then((data:ArrayBuffer)=>{
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        resolve ({file: blob,fileName: fileName+ '.xlsx'});
      })

    });
  }
  
  generateExcel(worksheet, json: any[],columns: any[]){
      const header = columns;
      const data = json;
      const headerRow = worksheet.addRow(header);
      headerRow.eachCell((cell,index) => {
        cell.font=  {size:12,bold:false};
        worksheet.getColumn(index).width = header[index-1].length<20?20:header[index-1].length;
      });
      let columnsArray: any[];
      for(const key in json){
        if(json.hasOwnProperty(key)){
          columnsArray = Object.keys(json[key]);
        }
      }
      data.forEach((element: any) => {
        const eachRow:any = [];
        columnsArray.forEach((column)=>{
          eachRow.push(element[column]);
        });
        if(element.isDeleted === 'Y'){
          const deletedRow: any = worksheet.addRows(eachRow);
          deletedRow.eachCell((cell:any)=>{
            cell.font = {name: 'calibri',family:4,size:11,bold:false,strike:true};
          });
        }else{
          worksheet.addRow(eachRow);
        }
      });
      return  worksheet;
  }
}
