import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import { Injectable, Output, EventEmitter } from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import { CONF } from '../../core/constants';
import { PsDataRaw } from '../../data/psDataRaw';
import {catchError, map} from 'rxjs/operators';
import { PsFilterRaw } from '../../data/filters/psFilterRaw';
import { isEmpty } from 'lodash-es';
import { User } from '../../data/userDataRaw';
import {CustomSortDataRow} from "../../data/customSortDatRaw";

@Injectable({
	providedIn: 'root'
})
export class PsService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/ps';
	@Output() eventPsSelected: EventEmitter<any> = new EventEmitter();
	@Output() eventPsSupervisedSelected: EventEmitter<any> = new EventEmitter();
	@Output() eventPsNotHaveOCT: EventEmitter<any> = new EventEmitter();

	@Output() eventDissocier: EventEmitter<any> = new EventEmitter();
	public lastPsConsultedSubject = new BehaviorSubject<String[]>([]);
	lastPsConsulted: String[]= [];
	constructor(private httpClient: HttpClient) {
	}

	getLastPsConsulted() {
		return this.lastPsConsultedSubject.asObservable();
	}

	setLastPsConsulted(ps) {
		return this.lastPsConsultedSubject.next(ps);
	}

	loadLastPss(): void {
		const savedPss = localStorage.getItem("lastPsConsulted");
		if (savedPss) {
			this.lastPsConsulted = JSON.parse(savedPss);
		}
	}

	addPs(ps: string): void {
		this.lastPsConsulted = this.lastPsConsulted.filter(p => p !== ps);
		this.lastPsConsulted.unshift(ps);
		if (this.lastPsConsulted.length > 3) {
			this.lastPsConsulted.pop();
		}
	}

	saveLastPss(): void {
		this.setLastPsConsulted(this.lastPsConsulted);
		localStorage.setItem("lastPsConsulted", JSON.stringify(this.lastPsConsulted));
	}

	getPSList(criteria: any): Observable<any> {
		if (criteria.idOct) {
			return this.httpClient.get<any>(
				this.baseUrl +
					'/list/oct/' +
					criteria.idOct +
					'?page=' +
					criteria.page +
					'&size=' +
					criteria.size +
					this.getSortParameters(criteria.sorts)
			);
		}
	}

	getPsInactifList(criteria: any): Observable<any> {
		if (criteria.idOct) {
			return this.httpClient.get<any>(
				this.baseUrl +
					'/list/oct/' +
					criteria.idOct +
					'/inactif/' +
					'?page=' +
					criteria.page +
					'&size=' +
					criteria.size +
					this.getSortParameters(criteria.sorts)
			);	
		}
	}

	searchByIdOct(criteria: any, psDataRaw: PsFilterRaw): Observable<any> {
		return this.httpClient.post<any>(
			this.baseUrl +
				'/search/oct/' +
				criteria.idOct +
				'?page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				this.getSortParameters(criteria.sorts),
			psDataRaw
		);
	}

	searchByIdPsWithDelegues(criteria: any, psDataRaw: PsFilterRaw): Observable<any> {
		return this.httpClient.post<any>(
			this.baseUrl + '/search/' + criteria.idPs + '/delegues/loi' +
				'?page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				this.getSortParameters(criteria.sorts),
			psDataRaw
		);
	}

	searchPsSuperviseByIdOct(criteria: any, psDataRaw: PsFilterRaw): Observable<any> {
		return this.httpClient.post<any>(
			this.baseUrl +
				'/search/supervise/oct/' +
				criteria.idOct +
				'?page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				this.getSortParameters(criteria.sorts),
			psDataRaw
		);
	}

	getPsByNumAdeli(criteria: any): Observable<any> {
		return this.httpClient.get<any>(this.baseUrl + '/' + criteria.numAdeli);
	}

	getPsById(idPs: any): Promise<PsDataRaw> {
		return this.httpClient.get<any>(this.baseUrl + '/' + idPs + '/full').toPromise();
	}

	getSortParameters(sorts) {
		if (sorts && sorts.length === 1) {
			const sort = sorts[0].property;
			return '&sort=' + sort + '&direction=' + sorts[0].direction;
		} else {
			// sort par défaut
			return '&sort=numeroAdeli&direction=DESC';
		}
	}

	updatePsAndOctPs(idOct, idPs, ps: PsDataRaw): Observable<PsDataRaw> {
		return this.httpClient
			.put<any>(this.baseUrl + '/update/octps?idOct=' + idOct + '&idPs=' + idPs, ps)
			.pipe(catchError(this.handleError));
	}

	savePs(idOct, ps: PsDataRaw): Observable<PsDataRaw> {
		return this.httpClient
			.post<any>(this.baseUrl + '/create?idOct=' + idOct, ps)
			.pipe(catchError(this.handleError));
	}

	handleError(error: HttpErrorResponse) {
		return throwError(error);
	}

	detachPsOfLogiciel(idPs): Promise<any> {
		return this.httpClient.put<any>(this.baseUrl + '/detach/logiciel/' + idPs, {}).toPromise();
	}

	attachPsOfLogiciel(idPs, idLogiciel): Observable<any> {
		return this.httpClient.put<any>(
			this.baseUrl + '/attach/logiciel/?idPs=' + idPs + '&idLogiciel=' + idLogiciel,
			{}
		);
	}

	detachPsOfEditeur(idPs): Promise<any> {
		return this.httpClient.put<any>(this.baseUrl + '/detach/editeur/' + idPs, {}).toPromise();
	}

	detachPsOfEtablissement(idPs): Promise<any> {
		return this.httpClient.put<any>(this.baseUrl + '/detach/etablissement/' + idPs, {}).toPromise();
	}

	attachPsOfEtablissement(idPs, etablissement): Observable<any> {
		return this.httpClient.put<any>(this.baseUrl + '/attach/etablissement/?idPs=' + idPs, etablissement);
	}
	
	attachPsOfEditeur(idPs, idEditeur): Observable<any> {
		return this.httpClient.put<any>(this.baseUrl + '/attach/editeur/?idPs=' + idPs + '&idEditeur=' + idEditeur, {});
	}

	detachPsOfUser(idPs, idUser): Promise<any> {
		return this.httpClient
			.put<any>(this.baseUrl + '/detach/user/?idPs=' + idPs + '&idUser=' + idUser, {})
			.toPromise();
	}

	attachPsOfUser(idPs, idUser): Observable<any> {
		return this.httpClient.put<any>(this.baseUrl + '/attach/user/?idPs=' + idPs + '&idUser=' + idUser, {});
	}

	removeLocalStorageOfNumeroPS() {
		var filters: any;
		const numeroPsLocalStorage = localStorage.getItem('numeroPs');
		if (!isEmpty(numeroPsLocalStorage)) {
			let parsedFilter = JSON.parse(sessionStorage.getItem('lotsFilter'));
			if (!isEmpty(parsedFilter)) {
				filters = Object.assign({}, parsedFilter);
				delete filters['numeroPs'];
				sessionStorage['lotsFilter'] = JSON.stringify(filters);
			}
			parsedFilter = JSON.parse(sessionStorage.getItem('psFilter'));
			if (!isEmpty(parsedFilter)) {
				filters = Object.assign({}, parsedFilter);
				delete filters['numeroAdeli'];
				sessionStorage['psFilter'] = JSON.stringify(filters);
			}
			parsedFilter = JSON.parse(sessionStorage.getItem('arlFilter'));
			if (!isEmpty(parsedFilter)) {
				filters = Object.assign({}, parsedFilter);
				delete filters['numeroPs'];
				sessionStorage['arlFilter'] = JSON.stringify(filters);
			}
			parsedFilter = JSON.parse(sessionStorage.getItem('fseFilters'));
			if (!isEmpty(parsedFilter)) {
				filters = Object.assign({}, parsedFilter);
				delete filters['numeroPs'];
				sessionStorage['fseFilters'] = JSON.stringify(filters);
			}
			parsedFilter = JSON.parse(sessionStorage.getItem('rspFilter'));
			if (!isEmpty(parsedFilter)) {
				filters = Object.assign({}, parsedFilter);
				delete filters['numeroPs'];
				sessionStorage['rspFilter'] = JSON.stringify(filters);
			}
			parsedFilter = JSON.parse(sessionStorage.getItem('rejetFilter'));
			if (!isEmpty(parsedFilter)) {
				filters = Object.assign({}, parsedFilter);
				delete filters['numeroPs'];
				sessionStorage['rejetFilter'] = JSON.stringify(filters);
			}
			parsedFilter = JSON.parse(sessionStorage.getItem('mvFinancierFilters'));
			if (!isEmpty(parsedFilter)) {
				filters = Object.assign({}, parsedFilter);
				delete filters['numeroPs'];
				sessionStorage['mvFinancierFilters'] = JSON.stringify(filters);
			}

			parsedFilter = JSON.parse(sessionStorage.getItem('hotlineFilters'));
			if (!isEmpty(parsedFilter)) {
				filters = Object.assign({}, parsedFilter);
				delete filters['numeroPs'];
				sessionStorage['hotlineFilters'] = JSON.stringify(filters);
			}
		}
		localStorage.removeItem('numeroPs');
		sessionStorage.removeItem('numeroPs');
	}

	saveUserWithIdPs(idPs, user: User): Observable<User> {
		return this.httpClient
			.post<any>(this.baseUrl + '/create/user/' + idPs, user)
			.pipe(catchError(this.handleError));
	}

	checkAdeli(numeroAdeli): Observable<Boolean> {
		return this.httpClient
			.get<Boolean>(this.baseUrl + '/adeli/check/' + numeroAdeli)
			.pipe(catchError(this.handleError));
	}

	getOctsByIdPs(idPs: number):  Observable<any> {
		return this.httpClient.get<any>(this.baseUrl + '/octs/' + idPs);
	}
	exportPss(criteria: any, psDataRaw: PsFilterRaw, fields: string[]): Observable<any> {
		const sorts: CustomSortDataRow = criteria.sorts;
		return this.httpClient.post<HttpResponse<Blob>>(
			this.baseUrl + '/export?idOct=' + criteria.idOct + this.getSortParameters(sorts),
			{
				filters: psDataRaw,
				fields: fields
			},{
				observe: 'response' as 'body',
				responseType: 'blob' as 'json'
			}
		).pipe(
			map((response: HttpResponse<Blob>): any => {
				return this.getFileInfosFromResponse(response,"Liste_Ps.xlsx");
			})
		);
	}
	exportPssByEmail(criteria: any, psDataRaw: PsFilterRaw, fields: string[]): Observable<any> {
		const sorts: CustomSortDataRow = criteria.sorts;
		return this.httpClient.post<HttpResponse<Blob>>(
			this.baseUrl + '/export-by-email?idOct=' + criteria.idOct + this.getSortParameters(sorts),
			{
				filters: psDataRaw,
				fields: fields
			},
			{
				observe: 'response' as 'body',
				responseType: 'blob' as 'json'
			}
		);
	}
	getFileInfosFromResponse(response: HttpResponse<Blob>,fileName: string) {
		return {
			file: new Blob([ response.body ], { type: 'application/vnd.ms-excel' }),
			fileName: fileName
		};
	}
	updateRappVisible(idPs: number, rappVisible: boolean): Observable<any> {
		return this.httpClient.put<any>(this.baseUrl + '/updateRappVisible?idPs=' + idPs + '&rappVisible=' + rappVisible, {});
	}

	getPsDelegueWithRappVisible(idPs: number): Observable<Array<string>>{
		return this.httpClient.get<any>(this.baseUrl + '/rappVisible?idPs=' + idPs );
	}

	setHideRappro(idPs: number, hideRappro: boolean): Observable<any> {
		return this.httpClient.put<any>(this.baseUrl + '/setHideRappro?idPs=' + idPs + '&hideRappro=' + hideRappro, {});
	}
}
