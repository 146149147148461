import {Component, Input, ElementRef, Output, EventEmitter} from '@angular/core';

@Component({
	selector: 'careweb-pagination-rapp',
	templateUrl: './pagination.componentRapp.html',
	styleUrls: [ './pagination.componentRapp.scss' ]
})
export class PaginationComponentRapp {
	@Input() totalCount:Number;
	@Input() currentPage=1;
	@Output() pageChange=new EventEmitter<Number>();
	pagination: ElementRef;


	/**
     * Clic sur le bouton de navigation prémière page.
     */
	pageFirstPageClick() {
		this.currentPage=1;
		this.pageChange.emit(this.currentPage);
	}

	/**
     * Clic sur le bouton de navigation page précedente.
     */
	pagePreviousPageClick() {
		this.currentPage= Number(this.currentPage)-1;
		this.pageChange.emit(this.currentPage);
	}

	/**
     * Clic sur le bouton de navigation page suivante.
     */
	pageNextPageClick() {
		this.currentPage= Number(this.currentPage)+1;
		this.pageChange.emit(this.currentPage);
	}

	/**
     * Clic sur le bouton de navigation dernière page.
     */
	pageLastPageClick() {
		this.currentPage= Number(this.totalCount);
		this.pageChange.emit(this.currentPage);
	}

	/**
     * Traite l'evenement de chagement de page lors de la saisie du numéro de page.
     * @param pageNumber Le numéro de la page à afficher.
     */
	changePage(pageNumber: number) {
		if(pageNumber!=null){
			this.currentPage=pageNumber;
			this.pageChange.emit(pageNumber);
		}
	}

	/**
     * Fournit un indicateur de navigabilité en arrière.
     */
	get cantPageBackward() {
		return  this.currentPage === 1;
	}

	/**
     * Fournit un indicateur de navigabilité en avant.
     */
	get cantPageForward() {
		 return Number(this.currentPage)  >= this.totalCount;
	}

	paginationKeyPressed($event) {
		$event.stopPropagation();
		if ($event.currentTarget.value > this.totalCount) {
			$event.currentTarget.value = this.totalCount;
			return false;
		}
		return $event.charCode === 8 || $event.charCode === 0 || $event.charCode === 13
			? null
			: $event.charCode >= 48 && $event.charCode <= 57;
	}
}
