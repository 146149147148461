import { Directive } from '@angular/core';
import { NG_VALIDATORS, ValidationErrors, UntypedFormGroup, ValidatorFn, AbstractControl, Validator } from '@angular/forms';

@Directive({
  selector: '[appCompareDate]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CompareDateDirective, multi: true }]
})
export class CompareDateDirective implements Validator{



  constructor() { }

  /* validate(formGroup: FormGroup): ValidationErrors {
    return this.customValidator.CompareDate(this.CompareDate[0], this.CompareDate[1])(formGroup);
  } */
    validate(control: AbstractControl): ValidationErrors {
      return this.dateComparator(control);
    }

    dateComparator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    const dateMin = control.get('dateMin');
    const dateMax = control.get('dateMax');
    const typeComptabilite = control.get('typeComptabilite')
    if (typeComptabilite && (typeComptabilite.value == 'REGLE')) {
      return dateMin && dateMax && dateMin.value > dateMax.value ? { 'dateMaxError': true } : null;
    }
  };
}
