import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HotlineService } from '../hotline.service';
import { Location, DatePipe } from '@angular/common';
import { OctService } from '../../../core/services/oct.service';
import { UsersService } from '../../users/users.service';
import { HotResolutionService } from '../hotResolution.service';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import { AgGridHelper } from '../../../components/ag-grid/ag-grid-helper';
import { ColDef, IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { HotlineDataRaw } from '../../../data/hotlineDataRaw';
import { gridConvertSort } from '../../../components/ag-grid/ag-grid.utils';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { GroupUsersService } from '../../users/groupUsers.service';
import { ScrollTopService } from '../../../core/utilities/scroll-top.service';
import { forkJoin } from 'rxjs';

@Component({
	selector: 'careweb-app-hotline-edit',
	templateUrl: './hotline-edit.component.html',
	styleUrls: ['./hotline-edit.component.scss']
})
export class HotlineEditComponent implements OnInit {
	datePipe: DatePipe;
	currentHotline: any;
	interventions: any;
	historisqueNbre: 0;
	currentOct;
	resolutions = [];
	retour: any;
	agents = [];
	raw = new HotlineDataRaw();
	gridHelper: AgGridHelper;
	hotlineDataRaw: HotlineDataRaw;
	interventionStatut: any = [
		{ id: 1, statut: 'En cours' },
		{ id: 2, statut: 'Transférée' },
		{ id: 3, statut: 'Clôturée' }
	];
	hotlineForm: UntypedFormGroup;
	submitted = false;
	groupeUsers = [];

	constructor(
		private hotlineService: HotlineService,
		private activatedRoute: ActivatedRoute,
		private location: Location,
		private router: Router,
		private hotlineSvc: HotlineService,
		private usersService: UsersService,
		private hotResolutionService: HotResolutionService,
		private toastr: ToastrService,
		private octSvc: OctService,
		private fb: UntypedFormBuilder,
		private groupeUsersSvc: GroupUsersService,
		private scrollTopService: ScrollTopService
	) {
		// Scroll to top
		this.scrollTopService.setScrollTop();

		this.gridHelper = new AgGridHelper();
		this.hotlineDataRaw = new HotlineDataRaw();
	}

	ngOnInit() {
		const ctx = this;
		const id = this.activatedRoute.snapshot.params.id;
		if(!parseInt(id)){
			this.router.navigate(['/404'])
		}
		this.datePipe = new DatePipe('fr-FR');
		this.initForm();

		this.currentOct = JSON.parse(localStorage.getItem('octCurrent'));
		if (isNullOrUndefined(this.currentOct)) {
			this.octSvc.currentOctSubject.subscribe((value) => {
				this.currentOct = value;
			});
		}


		this.populateInterventionInfoForm(id);




		ctx.groupeUsersSvc.getGroupUserList().subscribe((data) => {
			this.getGroupeUsers(data);
		});

		this.gridHelper.gridOptions = {
			columnDefs: this.getColumnDefs(),
			defaultColDef: { 
				suppressMenu: true
			},
			domLayout: 'autoHeight',
			rowHeight: 85,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 10,
			infiniteInitialRowCount: 1,
			paginationPageSize: 10,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			localeText: this.gridHelper.getLocaleText(),
			onGridReady: (params) => this.onGridReady(params),
		};
	}

	initForm() {
		this.hotlineForm = this.fb.group({
			dateCreation: new UntypedFormControl(''),
			numeroPs: new UntypedFormControl(''),
			motif: new UntypedFormControl(''),
			//user: new FormControl(''),
			intervenant: new UntypedFormControl(''),
			service: new UntypedFormControl(''),
			niveau: new UntypedFormControl(''),
			intervention: new UntypedFormControl(''),
			reference: new UntypedFormControl(''),
			commentaire: new UntypedFormControl(''),
			statut: new UntypedFormControl('', [Validators.required]),
			agent: new UntypedFormControl(''),
			resolution: new UntypedFormControl('')
		});
	}

	private getColumnDefs(): ColDef[] {
		return [
			{
				headerName: 'Date/Heure',
				field: 'dateFinIntervention',
				width: 80,
				valueFormatter: this.formatDate,
				lockVisible: true,
				sortable: true,
				cellClass: 'text-center',
			},
			{
				headerName: 'Intervenant',
				field: 'intervenant',
				width: 80,
				sortable: true,
				cellClass: 'text-center',

			},
			/* 			{
				headerName: 'Service',
				field: 'groupeDestinataire',
				minWidth: 100,
				lockVisible: true,
				sortable: true
			}, */
			{
				headerName: 'Commentaire',
				field: 'commentaire',
				minWidth: 200,
				flex: 1,
				lockVisible: true,

				cellStyle: {
					'white-space': 'normal',
					'line-height': '25px !important',
					'overflow-y': 'auto'
				}
			},
			{
				headerName: 'Action engagée',
				field: 'statut',
				valueFormatter: this.getstatutFormatted,
				width: 80,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),

			},
			{
				headerName: 'Agent assigné',
				field: 'agent',
				width: 90,
				lockVisible: true,
				cellClass: 'text-center',
			}
		];
	}

	onGridReady(params) {
		const ctx = this;
		this.gridHelper.gridApi = params.api;
		this.gridHelper.gridColumnApi = params.columnApi;
		this.gridHelper.gridApi.sizeColumnsToFit();
		const dataSource: IServerSideDatasource = {
			getRows: function (paramsRows: IServerSideGetRowsParams) {
				const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
				ctx.hotlineSvc
					.getHotLineFilteredListFiltre(
						{
							page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
							size: ctx.gridHelper.gridApi.paginationGetPageSize(),
							idOct: ctx.currentOct ? ctx.currentOct.idOct : '',
							sorts
						},
						ctx.hotlineDataRaw
					)
					.subscribe(
						(data) => {
							const rowCount = data.totalElements;
							ctx.gridHelper.manageNoRowsOverlay(rowCount);
							paramsRows.success({"rowData": data.content, "rowCount": rowCount});
							ctx.historisqueNbre = data.content.length;
						},
						() => paramsRows.fail()
					);
			}
		};
		this.gridHelper.gridApi.setServerSideDatasource(dataSource);
		this.gridHelper.gridApi.sizeColumnsToFit();
	}

	onGridHotTypeSizeChanged() {
		this.gridHelper.gridApi.sizeColumnsToFit();
	}

	exportXlsx() {
		const ctx = this;
		const params = {
			skipHeader: false,
			columnGroups: false,
			skipFooters: false,
			skipGroups: true,
			skipPinnedTop: false,
			skipPinnedBottom: false,
			onlySelected: false,
			fileName: 'Liste-Interventions',
			sheetName: 'Liste-Interventions',
			processCellCallback: function (params) {
				if (params.column.colDef.field === 'statut') {
					params.value = ctx.getstatutFormatted({ data: { statut: params.node.data.statut } });
				} else if (params.column.colDef.field === 'dateDebutIntervention') {
					params.value = ctx.formatDate({
						value: params.node.data.dateDebutIntervention
					});
				}
				return params.value;
			},
			processRowGroupCallback: (params) => this.rowGroupCallback(params)
		};
		this.gridHelper.gridApi.exportDataAsExcel(params);
	}

	rowGroupCallback(params) {
		return params.node.key;
	}

	getHotlineById(id: number) {
		return this.hotlineService.getHotlineById(id);
	}

	getStatut(params: any): String {
		if (params) {
			let statut: string;

			switch (Number(params)) {
				case 1: {
					statut = 'En cours';
					break;
				}
				case 2: {
					statut = 'Transférée';
					break;
				}
				case 3: {
					statut = 'Clôturée';
					break;
				}
			}
			return statut;
		}
	}

	formatDate = (params): string => {
		if (params.value != null && params.value !== 'Invalid Date' && params.value) {
			return this.datePipe.transform(params.value, 'short');
		} else {
			return params.value;
		}
	};

	getResolution = (data: any): String => {
		if (data) {
			this.resolutions.forEach((element) => {
				if (element.id === data) {
					this.retour = element.value;
				}
			});
		}
		return this.retour;
	};

	fillResolutions = (data: any) => {
		if (data) {
			data.forEach((element) => {
				const resolution = {
					id: element.idResolution,
					value: element.libelle.toUpperCase()
				};
				this.resolutions.push(resolution);
			});
		}
		return this.retour;
	};

	doTextareaValueChange(ev) {
		this.currentHotline.commentaire = ev.target.value;
	}

	getGroupeUsers = (data: any) => {
		if (data) {
			data.forEach((element) => {
				const groupe = {
					id: element.idGroupe,
					value: element.libelleLong.toUpperCase()
				};
				this.groupeUsers.push(groupe);
			});
		}
	};

	fillAgents = (data: any) => {
		if (data) {
			data.forEach((element) => {
				const agent = {
					id: element.idUser,
					value: `${element.prenom ? element.prenom : ''} ${element.nom ? element.nom.toUpperCase() : ''}`
				};
				this.agents.push(agent);
			});
		}
	};

	getAgentByName(name): string {
		if (!isNullOrUndefined(name)) {
			let agentId;
			this.agents.forEach((element) => {
				if (name && element.value.toUpperCase() == name.toUpperCase()) {
					agentId = element.id;
					return agentId;
				}
			});
			return agentId;
		} else {
			return null;
		}
	}

	getstatutFormatted = (params): any => {
		if (params.data) {
			let statut: string;
			switch (params.data.statut) {
				case 1: {
					statut = 'En cours';
					break;
				}
				case 2: {
					statut = 'Transférée';
					break;
				}
				case 3: {
					statut = 'Clôturée';
					break;
				}
			}
			return statut;
		}
	};

	updateHotline() {
		var ctx = this
		this.submitted = true;
		this.fromFormToObject();
		if (!isNullOrUndefined(this.raw)) {
			this.raw.oct = ctx.currentOct.idOct.toString();
			this.hotlineSvc.updateHotline(this.raw).subscribe(
				(data) => {
					this.currentHotline = data;
					this.currentHotline.agent = !isNullOrUndefined(this.currentHotline.agent)
						? this.currentHotline.agent.toUpperCase()
						: this.currentHotline.agent;
					// this.router.navigate([ '/hotline-edit', data.idIntervention ]);
					this.toastr.success("L'intervention a été mise à jour avec succès");
				},
				(err) => {
					this.toastr.error("Une erreur est survenue lors de modification de l'intervention");
				}
			);
		}
	}

	cancel() {
		this.submitted = false;
		this.fromObjectToForm();
	}

	fromObjectToForm() {
		this.hotlineForm.controls['dateCreation'].setValue(this.currentHotline.dateDebutIntervention);
		this.hotlineForm.controls['numeroPs'].setValue(this.currentHotline.numeroPs);
		this.hotlineForm.controls['motif'].setValue(this.currentHotline.motif);
		//this.hotlineForm.controls['user'].setValue(this.currentHotline.user);
		this.hotlineForm.controls['intervenant'].setValue(this.currentHotline.intervenant);
		this.hotlineForm.controls['service'].setValue(this.currentHotline.service ? this.currentHotline.service.toUpperCase() : '');
		this.hotlineForm.controls['niveau'].setValue(this.currentHotline.niveau);
		this.hotlineForm.controls['intervention'].setValue(this.currentHotline.intervention);
		this.hotlineForm.controls['reference'].setValue(this.currentHotline.reference);
		this.hotlineForm.controls['commentaire'].setValue(this.currentHotline.commentaire);
		this.hotlineForm.controls['statut'].setValue(this.getStatut(this.currentHotline.statut));
		this.hotlineForm.controls['agent'].setValue(this.currentHotline.agent);
		this.hotlineForm.controls['resolution'].setValue(this.getResolution(this.currentHotline.resolution));
	}

	fromFormToObject() {
		this.raw.dateCreation = this.formFields.dateCreation.value;
		this.raw.numeroPs = this.formFields.numeroPs.value;
		this.raw.motif = this.formFields.motif.value;
		this.raw.user = this.currentHotline.user;
		this.raw.intervenant = this.formFields.intervenant.value;
		if (!isNullOrUndefined(this.formFields.service.value) && this.formFields.service.value != '') {
			this.raw.service = this.groupeUsers.find((serv) => serv.value.toLowerCase() == this.formFields.service.value.toLowerCase()).id;
		}

		this.raw.niveau = this.formFields.niveau.value;
		this.raw.intervention = this.formFields.intervention.value;
		this.raw.reference = this.formFields.reference.value;
		this.raw.commentaire = this.formFields.commentaire.value;
		const oldStatut = this.getStatut(this.currentHotline.statut);
		if (this.formFields.statut.touched && oldStatut !== this.formFields.statut.value) {
			this.raw.statut = this.formFields.statut.value;
		} else {
			this.raw.statut = this.currentHotline.statut;
		}

		if (this.formFields.agent.touched && this.currentHotline.agent !== this.formFields.agent.value) {
			this.raw.agent = this.formFields.agent.value;
		} else {
			this.raw.agent = this.getAgentByName(this.formFields.agent.value);
		}

		let oldResolution = this.getResolution(this.currentHotline.resolution);

		if (this.formFields.resolution.touched && oldResolution !== this.formFields.resolution.value) {
			this.raw.resolution = this.formFields.resolution.value;
		} else {
			this.raw.resolution = this.currentHotline.resolution;
		}
	}

	getServiceByLibelle(libelle): any {
		if (!isNullOrUndefined(libelle)) {
			let service = null;
			this.groupeUsers.forEach((element) => {
				if ((element.value === libelle)) {
					service = element.id;
					return service;
				}
			});
			return service;
		} else {
			return null;
		}
	}

	get formFields() {
		return this.hotlineForm.controls;
	}

	goBack(): void {
		this.router.navigate(['/hotline']);
		//this.location.back();
	}

	populateInterventionInfoForm(id) {
		forkJoin([
			this.usersService.getHotlineUsersList(this.currentOct.idOct),
			this.hotResolutionService.getResolutionList(),
		]).subscribe((data) => {
			this.fillAgents(data[0]);
			this.fillResolutions(data[1]);
			this.getHotlineById(id).subscribe((data) => {
				if (data) {
					this.currentHotline = data;
					this.currentHotline.agent = !isNullOrUndefined(this.currentHotline.agent)
						? this.currentHotline.agent.toUpperCase()
						: this.currentHotline.agent;
					this.fromObjectToForm();

					if (data.reference) {
						this.hotlineDataRaw.reference = data.reference;
						this.hotlineDataRaw.withReference = false;
					}
				} else {
					this.location.back();
				}
			});
		},
		err=>{
			if(err.status===400){
				this.router.navigate(['/404'])
			}
		});
	}
}
