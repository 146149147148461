export class TransactionDataRaw {
	idBankTransaction: number;
	idUser: number;
	idOxlinTransaction: string;
	idOxlinAccount: string;
	idEbicsPs:number;
	idPs:number;
	emetteur: string;
	dateComptable: Date;
	dateImport: Date;
	label: string;
	notes: string;
	montant: number;
	traitee: number;
	idRapprochement: number;
	idRappComptePs: number;
	dateOperationFrom: string;
    dateOperationTo: string;
	montantFrom: number;
	montantTo: number;
	/* [CAW-1600-] Desactiver la checkAll & uncheckAll */
	idBankTransactions: number[];
}
