import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import * as _ from 'lodash-es';

@Component({
	selector: 'app-rapp-error-message',
	templateUrl: './rapp-error-message.component.html',
	styleUrls: [ './rapp-error-message.component.scss' ]
})
export class RappErrorMessageComponent implements OnInit {


    @Input() connection: any;
    @Output() contactEvent = new EventEmitter<any>();
    @Output() authEvent = new EventEmitter<any>();
    @Output() refreshEvent = new EventEmitter<any>();
    @Output() possibleMigrationEvent = new EventEmitter<any>();

    public _channelsError: any[];
    redirectChannel: any ;

    constructor() { 
    }

    ngOnInit() {
        this._channelsError = [];
        this.manageErrorMessage();  
    }

    contactSupport(){
        this.contactEvent.next();
    }

    
    refreshCnx(){
        this.refreshEvent.next();
    }


    launchUpdate(){
        this.authEvent.next(this.connection);
    }

    triggerMigration(){
        let migration = {
            'connection' : this.connection ,
            'redirectChannel' : this.redirectChannel

        }
        this.possibleMigrationEvent.next(migration)
    }

    private manageErrorMessage(){

        this.redirectChannel = this.getChannelRedirectIfAvailable(this.connection);

        _.forEach(this.connection.channels, (channel) => {

            //channel.status = "AUTH_FAILED";

            var channelError = {
                "bankName" : this.connection.name,
                "status" : channel.status
            };
            switch(channel.status){
                case "AUTH_FAILED": this.generateErrorMessage_AUTH_FAILED(channel, channelError); break;
                case "CHALLENGE_CANCELLED": this.generateErrorMessage_CHALLENGE_CANCELLED(channel, channelError); break;
                case "CHALLENGE_FAILED": this.generateErrorMessage_CHALLENGE_FAILED(channel, channelError); break;
                case "CHALLENGE_REQUIRED": this.generateErrorMessage_CHALLENGE_REQUIRED(channel, channelError); break;
                case "CHALLENGE_TIMED_OUT": this.generateErrorMessage_CHALLENGE_TIMED_OUT(channel, channelError); break;
                case "CLOSED": this.generateErrorMessage_CLOSED(channel, channelError); break;
                case "FAILED": this.generateErrorMessage_FAILED(channel, channelError); break;
                case "PARTIAL_SUCCESS": this.generateErrorMessage_PARTIAL_SUCCESS(channel, channelError); break;
                case "PASSWORD_CHANGE_REQUIRED": this.generateErrorMessage_PASSWORD_CHANGE_REQUIRED(channel, channelError); break;
                case "SERVICE_UNAVAILABLE": this.generateErrorMessage_SERVICE_UNAVAILABLE(channel, channelError); break;
                case "TOKEN_EXPIRED": this.generateErrorMessage_TOKEN_EXPIRED(channel, channelError); break;
                case "TOO_MANY_ATTEMPTS": this.generateErrorMessage_TOO_MANY_ATTEMPTS(channel, channelError); break;
                case "USER_ACTION_REQUIRED": this.generateErrorMessage_USER_ACTION_REQUIRED(channel, channelError); break;
            }
        });
        
        if(this.redirectChannel){
        
            let channelError = {
                "bankName" : this.connection.name,
                "status" :''
            };

            this.generateErrorMessage_POSSIBLE_MIGRATION_TO_REDIRECT(channelError)
        }
        
        const tokenWillBeExpiredAfterDays = this.redirectChannelWillBeExpiredAfterDays(this.connection);
        if (tokenWillBeExpiredAfterDays > 0 && tokenWillBeExpiredAfterDays <= 15) {
            let channelError = {
                "bankName" : this.connection.name,
                "status" : 'TOKEN_WILL_BE_EXPIRED'
            };

            this.generateErrorMessage_TOKEN_WILL_BE_EXPIRED(tokenWillBeExpiredAfterDays, channelError);
        }
    }

    //TODO - Mettre les informations du support

    private generateErrorMessage_AUTH_FAILED(channel: any, channelError: any){
        channelError.displayError = "Problème d'authentification";
        channelError.message = '<div>Vos comptes n\'ont pas été synchronisés en raison d\'un problème de connexion avec votre banque.</div><div>Veuillez saisir vos identifiants en cliquant sur le bouton ci-dessous.</div>';
        this._channelsError.push(channelError);
    }
    private generateErrorMessage_CHALLENGE_CANCELLED(channel: any, channelError: any){
        channelError.displayError = "Demande d'informations";
        channelError.message = '<div>Vos comptes n\'ont pas été synchronisés car vous avez annulé la demande d\'informations de votre banque.</div><div>Veuillez vous authentifier en cliquant sur le bouton ci-dessous et saisissez les informations demandées par votre banque. </div>';
        this._channelsError.push(channelError);
    }
    private generateErrorMessage_CHALLENGE_FAILED(channel: any, channelError: any){
        channelError.displayError = "Informations incorrectes";
        channelError.message = '<div>Vos comptes n\'ont pas été synchronisés car vous avez fourni des informations érronées lors de la dernière synchronisation.</div><div>Veuillez vous authentifier en cliquant sur le bouton ci-dessous et saisissez les informations demandées par votre banque. </div>';
        this._channelsError.push(channelError);
    }
    private generateErrorMessage_CHALLENGE_REQUIRED(channel: any, channelError: any){
        channelError.displayError = "Double authentification utilisateur";
        channelError.message = '<div>Vos comptes n\'ont pas été synchronisés car votre banque demande une double authentification.</div><div>Veuillez vous authentifier en cliquant sur le bouton ci-dessous et saisissez les informations demandées par votre banque. </div>';
        this._channelsError.push(channelError);
    }
    private generateErrorMessage_CHALLENGE_TIMED_OUT(channel: any, channelError: any){
        channelError.displayError = "Délai de réponse dépassé";
        channelError.message = '<div>Vos comptes n\'ont pas été synchronisés car vous n\'avez pas répondu à temps à la double authentification de votre banque.</div><div>Veuillez vous authentifier en cliquant sur le bouton ci-dessous et saisissez les informations demandées par votre banque. </div>';
        this._channelsError.push(channelError);
    }
    private generateErrorMessage_CLOSED(channel: any, channelError: any){
        channelError.displayError = "Connexion à votre banque suspendue";
        channelError.message = '<div>Vos comptes n\'ont pas été synchronisés car la connexion à votre banque n\'est plus valide.</div><div>Si vous n\'êtes pas à l\'origine de cette action, merci de contacter le support en cliquant sur le bouton ci-dessous. </div>';
        this._channelsError.push(channelError);
    }
	private generateErrorMessage_FAILED(channel: any, channelError: any) {
		channelError.displayError = 'Les comptes liés à votre connexion ne peuvent être lus pour l\'instant';
		channelError.message =
			'<div>Les administrateurs de Careweb ont été prévenus de la situation et sont en train de remédier au problème.</div>' +
            '<div>Si vous souhaitez plus d\'information, nous vous invitons à contacter le support en cliquant  sur le bouton ci-dessous. </div>';
		this._channelsError.push(channelError);
    }
    
	private generateErrorMessage_PARTIAL_SUCCESS(channel: any, channelError: any) {
		channelError.displayError = 'Synchronisation partielle';
		channelError.message =
            '<div>Vos comptes n\'ont pas été synchronisés car une erreur s\'est produite.</div><div>Si le problème persiste, merci de contacter le support en cliquant sur le bouton ci-dessous. </div>';    
        this._channelsError.push(channelError);
    }
    
	private generateErrorMessage_PASSWORD_CHANGE_REQUIRED(channel: any, channelError: any) {
		channelError.displayError = 'Changement de mot de passe requis';
		channelError.message =
			"<div>Vos comptes n'ont pas été synchronisés car votre mot de passe bancaire a été changé ou doit être changé <u>sur le site de votre banque</u>.</div><div>Une fois le mot de passe changé, veuillez saisir votre nouveau mot de passe en cliquant sur le bouton ci-dessous. </div>";
		this._channelsError.push(channelError);
	}
	private generateErrorMessage_SERVICE_UNAVAILABLE(channel: any, channelError: any) {
		channelError.displayError = 'Service indisponible';
		channelError.message =
			'<div>Vos comptes n\'ont pas été synchronisés car le service est actuellement indisponible.</div><div>Si le problème persiste, merci de contacter le support en cliquant sur le bouton ci-dessous. </div>'; 
		this._channelsError.push(channelError);
    }
    
	private generateErrorMessage_TOKEN_EXPIRED(channel: any, channelError: any) {
		channelError.displayError = 'Token expiré';
		channelError.message =
			'<div>Vos comptes n\'ont pas été synchronisés car votre authentification est expirée.</div><div>Veuillez vous authentifier en cliquant sur le bouton ci-dessous et saisissez les informations demandées par votre banque. </div>';
		this._channelsError.push(channelError);
    }
    
	private generateErrorMessage_TOO_MANY_ATTEMPTS(channel: any, channelError: any) {
		channelError.displayError = 'Trop de tentatives de connexion effectuées';
		channelError.message =
			"<div>Vos comptes n'ont pas été synchronisés en raison d'un trop grand nombre de d'essais de connexion.</div><div>Veuillez vous authentifier en cliquant sur le bouton ci-dessous et saisissez les informations demandées par votre banque. </div><div>En fonction de votre banque, la synchronisation peut prendre quelques heures.</div>";
		this._channelsError.push(channelError);
    }
    
	private generateErrorMessage_USER_ACTION_REQUIRED(channel: any, channelError: any) {
		channelError.displayError = 'Action utilisateur requise sur la connexion';
		channelError.message =
			"<div>Vos comptes n'ont pas été synchronisés car votre banque requiert une action de votre part.</div><div>Veuillez vous authentifier en cliquant sur le bouton ci-dessous et saisissez les informations demandées par votre banque. </div>";
		this._channelsError.push(channelError);
	}

    private generateErrorMessage_POSSIBLE_MIGRATION_TO_REDIRECT(channelError: any) {
		channelError.displayError = 'Action utilisateur requise sur la connexion';
		channelError.message =
			"<div>Veuillez mettre à jour votre connexion en cliquant sur le bouton ci-dessous et saisissez les informations demandées par votre banque.</div>";
		this._channelsError.push(channelError);
    }
    
    private generateErrorMessage_TOKEN_WILL_BE_EXPIRED(tokenWillBeExpiredAfterDays: number, channelError: any): void {
		channelError.displayError = tokenWillBeExpiredAfterDays + ' jour(s) avant de vous ré-authentifier sur la connexion';
        channelError.message =
            "<div>Vos comptes ne seront plus synchronisés dans " + tokenWillBeExpiredAfterDays + " jour(s), car votre banque requiert de se ré-authentifier tous les 3 mois.</div><div>Veuillez cliquer sur le bouton ci-dessous et saisissez les informations demandées par votre banque. </div>";
		this._channelsError.push(channelError);
	}
   
    getChannelRedirectIfAvailable(connection){
        if(connection.status === "SUCCESS"){ 
            return [...connection.channels].find(channel => channel.mode === "REDIRECT" && channel.status === "EMPTY");
        }
        return null; 
    }

    redirectChannelWillBeExpiredAfterDays(connection: any): number {
        if(connection.status === "SUCCESS") {
            const redirectChannel = [...connection.channels].find(channel => channel.mode === "REDIRECT" && channel.status === "SUCCESS");
            if (redirectChannel) {
                return Math.round(Math.abs((redirectChannel.expires * 1000 - new Date().getTime()) / (1000 * 60 * 60 * 24))) + 1;
            }
        }
        return -1;
    }
    
      /* 
    AUTH_FAILED 
    CHALLENGE_CANCELLED 
    CHALLENGE_FAILED
    CHALLENGE_REQUIRED
    CHALLENGE_TIMED_OUT 
    CLOSED 
    FAILED
    PARTIAL_SUCCESS
    PASSWORD_CHANGE_REQUIRED 
    SERVICE_UNAVAILABLE 
    TOKEN_EXPIRED
    TOO_MANY_ATTEMPTS 
    USER_ACTION_REQUIRED 
    */
}
