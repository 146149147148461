import { Component } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {EbicsParams} from "../../../data/ebicsParams";
import {EbicsService} from "../../ps/service/ebics.service";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../core/services/auth.service";
import {AbonnementDataRaw} from "../../../data/abonnement-data-raw";
import {isNullOrUndefined} from "util";

@Component({
  selector: 'app-edit-abonnement-ebics',
  templateUrl: './edit-abonnement-ebics.component.html',
  styleUrls: ['./edit-abonnement-ebics.component.scss']
})
export class EditAbonnementEbicsComponent {
  showEbicsForm: Boolean = true;
  ebicsDataRaw: AbonnementDataRaw
  ebicsForm: UntypedFormGroup;
  ebicsParams: EbicsParams;
  listEbicsOrderType: any = [
    {value: 'FDL', viewValue: 'FDL'},
  ];
  btnAddClicked = false;
  isNewAbonnement=false;
  isEditAbonnement=false;
  initalValues: any;
  selectedTypeCommande: any ;
    constructor(
      private formBuilder: UntypedFormBuilder,
      private ebicsService: EbicsService,
      private toastr: ToastrService,
      private activatedRoute: ActivatedRoute,
      private authSvc: AuthService,
      private router: Router,
  ){

  }
  
  ngOnInit() {
    const idAbonnement = this.activatedRoute.snapshot.params.id;
    this.validForm();
    if (!this.isAuthorized()) {
      this.router.navigate([ '/dashboard' ]);
    }

    this.ebicsDataRaw = new AbonnementDataRaw();
    this.validForm();

    if (isNullOrUndefined(idAbonnement)) {
      this.isNewAbonnement = true;
      this.isEditAbonnement = false;

    } else {
      this.isEditAbonnement = true;
      this.isNewAbonnement = false;
      this.ebicsService.getAbonnementById(idAbonnement).subscribe((data) => {
            this.ebicsDataRaw = data;
            if (!isNullOrUndefined(this.ebicsDataRaw)) {
              this.fromObjectToForm();
            }
          },
          err=>{
            if(err.status===400){
              this.router.navigate(['/404'])
            }
          });
    }
  }
  
    fromObjectToForm() {
        this.ebicsForm.controls['ebicsHostId'].setValue(this.ebicsDataRaw.ebicsHostId);
        this.ebicsForm.controls['ebicsUserId'].setValue(this.ebicsDataRaw.ebicsUserId);
        this.ebicsForm.controls['ebicsUrl'].setValue(this.ebicsDataRaw.ebicsUrl);
        this.ebicsForm.controls['bankName'].setValue(this.ebicsDataRaw.bankName);
        this.ebicsForm.controls['ebicsCustomerId'].setValue(this.ebicsDataRaw.ebicsCustomerId);
        this.ebicsForm.controls['ebicsOrderType'].setValue(this.ebicsDataRaw.ebicsOrderType);
        this.ebicsForm.controls['ebicsFileFormat'].setValue(this.ebicsDataRaw.ebicsFileFormat);
      this.ebicsForm.controls['bankId'].setValue(this.ebicsDataRaw.bankId);
      this.selectedTypeCommande=this.listEbicsOrderType.filter(v=>v.value==this.ebicsDataRaw.ebicsOrderType);
        this.initalValues = this.ebicsForm.value;
    }
  trackByIndex(index, item) {
    return index;
  }
  isAuthorized() {
    return this.authSvc.userAuthorized('ADMIN');
  }
  get ebicsFields() {
    return this.ebicsForm.controls;
  }
  onOrderTypeSelected(): void {
    //console.log("orderTypes", this.psForm.value.ebicsOrderTypes)
  }
  validForm() {
    this.ebicsForm = this.formBuilder.group({
      ebicsHostId: ['', [Validators.required]],
      ebicsUserId: ['', [Validators.required]],
      ebicsUrl: ['', [Validators.required]],
      bankName: ['', [Validators.required]],
      ebicsCustomerId: ['', [Validators.required]],
      ebicsOrderType: [[], [Validators.required]],
      ebicsFileFormat:['', [Validators.required]],
      bankId:['', [Validators.required]]
    })

  }
  goToAbonnementList(){
    this.btnAddClicked = false;
    this.router.navigate([ '/abonnements-ebics' ]);

  }
  onSubmit() {
		this.btnAddClicked = true;

    if (this.ebicsForm.valid) {
      if (this.showEbicsForm) {
        //EBICS
        var ebicsParams = new EbicsParams();
        ebicsParams.id = this.activatedRoute.snapshot.params.id;
        ebicsParams.ebicsHostId = this.ebicsForm.value.ebicsHostId;
        ebicsParams.ebicsOrderType = this.ebicsForm.value.ebicsOrderType[0].value;
        ebicsParams.ebicsCustomerId = this.ebicsForm.value.ebicsCustomerId;
        ebicsParams.ebicsUrl = this.ebicsForm.value.ebicsUrl;
        ebicsParams.ebicsUserId = this.ebicsForm.value.ebicsUserId;
        ebicsParams.bankName = this.ebicsForm.value.bankName;
        ebicsParams.ebicsFileFormat = this.ebicsForm.value.ebicsFileFormat;
        ebicsParams.bankId = this.ebicsForm.value.bankId;

        this.ebicsService.save(ebicsParams).subscribe(
            (data) => {
              this.ebicsDataRaw = data;
              if (this.isNewAbonnement) {
								this.toastr.success('L\'abonnement a été ajouté avec succès');
								// afficher la page en mode édition
								this.isEditAbonnement = true;
								this.isNewAbonnement = false;
							} else if (this.isEditAbonnement) {
								this.toastr.success('L\'abonnement a été mis à jour avec succès');
							}
              this.router.navigate([ '/abonnements-ebics']);
            },
            (error) => {
              if (this.isNewAbonnement) {
								this.toastr.error('Erreur lors de l\'ajout de l\'abonnement');
              }
              else if (this.isEditAbonnement) {
								this.toastr.error('Erreur lors de la mise à jour de l\'abonnement');
							}
            }
        )


      }
    }
  }

}
