<div class="x-large-careweb-container">
    <form>
        <section class="form-content">
            <div class="row form-title" *ngIf="currentFse">
                <h2 class="col-md-6" >
                    Détails de la FSE - N° {{ currentFse.numFacture }}
                </h2>
                <div class="col-md-6 text-title">
                    <button class="btn btn-default me-2" type="button" (click)="goToLotDetails()" [hidden]="!currentFse.idLot"> Détails du lot</button>
                    <button class="btn btn-default btn-lg" type="button" (click)="goToFSE()">Retour</button>
                </div>
            </div>
                <ul ngbNav #nav="ngbNav" [(activeId)]="active"  [destroyOnHide]="false" class="nav-tabs">
                <li [ngbNavItem]="1"id="Informations" title="Informations">
                    		<button ngbNavLink id="Informations">Informations</button>
		                <ng-template ngbNavContent>
                        <!-- ONGLET FSE -->
                        <div id="ongletFSE" class="tab-pane fade show active">
                            <div class="form-block mt-3" *ngIf="currentFse && currentPs">
                                <fieldset class="container-fluid">
                                    <div class="row">
                                        <div class="col-sm-3 col-form-label">Numéro de PS</div>
                                        <div class="col-sm-3 form-group">
                                            <input type="text" class="form-control" readonly
                                                value="{{currentFse.numPs}}" />
                                        </div>
                                        <div class="col-sm-3 col-form-label">Numéro INSEE</div>
                                        <div class="col-sm-3 form-group">
                                            <input type="text" class="form-control" readonly
                                                value="{{currentFse.numAssure}}" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-3 col-form-label">Nom du titulaire</div>
                                        <div class="col-sm-9 form-group">
                                            <input type="text" class="form-control" readonly
                                                value="{{currentPs.nom}}" />
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-3 col-form-label">Etablissement</div>
                                        <div class="col-sm-9 form-group">
                                            <input type="text" class="form-control" readonly
                                                value="{{currentFse.lot.raisonSociale }}" />
                                        </div>
                                    </div>

                                    <div class="row">
                                     <!--   <div class="col-sm-3 col-form-label">ARL SCOR</div>
                                        <div class="col-sm-3 form-group">
                                            <input type="text" class="form-control" readonly value="{{ currentFse.lot.signeArl | yesNoPipe: 'typeArl' }}" />
                                        </div>-->
                                        <div class="col-sm-3 col-form-label">Date de naissance</div>
                                        <div class="col-sm-3 form-group">
                                            <input type="text" class="form-control" readonly
                                                value="{{ currentFse.dateNaissance != null ? (currentFse.dateNaissance) : '' }}" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-3 col-form-label">Date réception lot</div>
                                        <div class="col-sm-3 form-group">
                                            <input type="text" class="form-control" readonly
                                                value="{{ currentFse.dateReceptionPartition | date:'shortDate'}}" />
                                        </div>
                                        <div class="col-sm-3 col-form-label">Dernière transmission</div>
                                        <div class="col-sm-3 form-group">
                                            <input type="text" class="form-control" readonly value="" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-3 col-form-label">N° ADELI médecin traitant</div>
                                        <div class="col-sm-3 form-group">
                                            <input type="text" class="form-control" readonly
                                                value="{{currentPs.numeroAdeli}}" />
                                        </div>
                                        <div class="col-sm-3 col-form-label">Tiers-payant</div>
                                        <div class="col-sm-3 form-group">
                                            <input type="text" class="form-control" readonly value="{{tpStatus}}" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-3 col-form-label">Organisme AMO</div>
                                        <div class="col-sm-3 form-group">
                                            <input type="text" class="form-control" readonly
                                                value="{{ currentRO.libelleCourtOrganisme }}" />
                                        </div>
                                        <div class="col-sm-3 col-form-label">Montant AMO</div>
                                        <div class="col-sm-3 form-group">
                                            <input type="text" class="form-control" readonly
                                                value="{{ currentRO.montant | customCurrency}}" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-3 col-form-label">Organisme AMC</div>
                                        <div class="col-sm-3 form-group">
                                            <input type="text" class="form-control" readonly
                                                value="{{ currentRC.libelleCourtOrganisme || currentRC.undefinedRc }}" />
                                        </div>
                                        <div class="col-sm-3 col-form-label">Montant AMC</div>
                                        <div class="col-sm-3 form-group">
                                            <input type="text" class="form-control" readonly
                                                value="{{ currentRC.montant | customCurrency}}" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-3 col-form-label">RSP AMO disponible</div>
                                        <div class="col-sm-3 form-group">
                                            <input type="text" class="form-control" readonly
                                                value="{{currentFse.nbRspRO | yesNoPipe:'nbRsp'}}" />
                                        </div>
                                        <div class="col-sm-3 col-form-label">Part AMO prise en compte</div>
                                        <div class="col-sm-3 form-group">
                                            <input type="text" class="form-control" readonly
                                                value="{{currentRO.libelleCourtOrganisme != null | yesNoPipe:'partRc'}}" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-3 col-form-label">RSP AMC disponible</div>
                                        <div class="col-sm-3 form-group">
                                            <input type="text" class="form-control" readonly
                                                value="{{currentFse.nbRspRC | yesNoPipe:'nbRsp'}}" />
                                        </div>
                                        <div class="col-sm-3 col-form-label">Part AMC prise en compte</div>
                                        <div class="col-sm-3 form-group">
                                            <input type="text" class="form-control" readonly
                                                value="{{currentRC.libelleCourtOrganisme != null  | yesNoPipe:'partRc'}}" />
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col">
                                <h3 class="title-border" style="margin-top:30px">
                                    Détail des RSP
                                </h3>
                            </div>
                        </div>
                        <section>
                            <div class="row grid-top-btn">
                                <div class="col-md-4 filter">
                                    <careweb-pluralize *ngIf="userConnectedIdPs!=null"  class="filter-count" [messageKey]="'Résultat(s) trouvé(s)'"
                                                        [count]="gridHelper.totalRowCountFseDetail"></careweb-pluralize>
                                </div>
                                <div class="col-md-8 conf-col">
                                    <div class="careweb-toolbar">
                                        <careweb-pagination-size id="dv_paging" [pageSizes]="[10, 20,30, 50, 100]"
                                           [(ngModel)]="gridDataRaw.size" [ngModelOptions]="{standalone: true}" (paginationSizeChange)="onPaginationSizeChange($event)">
                                        </careweb-pagination-size>
                                        <span class="btn btn-action small" tooltip="Réinitialiser l'affichage" placement="top" container="body"
                                              name="spn_reset_grid" (click)="resetGridState()">
                                           <i class="fa fa-refresh"></i>
                                         </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row highlight-row">
                                <div class="col-md-12 relative">
                                    <ag-grid-angular style="width: 100%" class="ag-theme-balham" [gridOptions]="gridHelper.gridOptions">
                                    </ag-grid-angular>
                                    <careweb-pagination [gridApi]="gridHelper.gridApi"></careweb-pagination>
                                </div>
                            </div>
                        </section>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2"id="amo" title="Assurance maladie obligatoire">
                    		<button ngbNavLink>Assurance maladie obligatoire</button>
		<ng-template ngbNavContent>
                        <!-- ONGLET RO-->
                        <div class="form-block mt-3">
                            <fieldset class="container-fluid">
                                <div class="row">
                                    <div class="col-sm-3 col-form-label">Sigle</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" readonly
                                            value="{{amoData.libelleCourt}}" />
                                    </div>
                                    <div class="col-sm-3 col-form-label">Organisme actif</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" readonly
                                            value="{{amoData.actif | yesNoPipe:'actif'}}" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 col-form-label">Libellé</div>
                                    <div class="col-sm-9 form-group">
                                        <input type="text" class="form-control" readonly
                                            value="{{amoData.libelleLong}}" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 col-form-label">Adresse 1</div>
                                    <div class="col-sm-9 form-group">
                                        <input type="text" class="form-control" readonly value="{{amoData.adresse1}}" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 col-form-label">Adresse 2</div>
                                    <div class="col-sm-9 form-group">
                                        <input type="text" class="form-control" readonly value="{{amoData.adresse2}}" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 col-form-label">Code Postal</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" readonly
                                            value="{{amoData.codePostal}}" />
                                    </div>
                                    <div class="col-sm-3 col-form-label">Ville</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" readonly value="{{amoData.ville}}" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 col-form-label">Téléphone</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" readonly
                                            value="{{amoData.telephone}}" />
                                    </div>
                                    <div class="col-sm-3 col-form-label">Fax</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" readonly value="{{amoData.fax}}" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 col-form-label">Commentaire</div>
                                    <div class="col-sm-9 form-group">
                                        <input type="text" class="form-control" readonly
                                            value="{{amoData.commentaires}}" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 col-form-label">Tous centres</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" readonly
                                            value="{{amoData.tousCentres | yesNoPipe:'actif'}}" />
                                    </div>
                                    <div class="col-sm-3 col-form-label">Code grand Régime</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" readonly value="{{amoData.codeGr}}" />
                                    </div>
                                </div>
                                <div class="row">

                                    <div class="col-sm-3 col-form-label">Compression</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" readonly
                                            value="{{amoData.envoisCompresses | yesNoPipe:'actif'}}" />
                                    </div>
                                    <div class="col-sm-3 col-form-label">Caisse C.A.S.</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" readonly
                                            value="{{amoData.codeCaisseCas}}" />
                                    </div>
                                </div>
                                <div class="row">

                                    <div class="col-sm-3 col-form-label">Type 0 Centre info.</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" readonly
                                               value="{{amoData.codeCentreInfoType0}}" />
                                    </div>

                                    <div class="col-sm-3 col-form-label">Centre C.A.S.</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" readonly
                                            value="{{amoData.codeCentreCas}}" />
                                    </div>

                                </div>
                                <div class="row">

                                    <div class="col-sm-3 col-form-label">Type 1</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" readonly
                                            value="{{amoData.codeOrgType1}}" />
                                    </div>
                                    <div class="col-sm-3 col-form-label">Code organisme type 0 générique</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" readonly
                                            value="{{amoData.codeCentreInfoType0Gen | yesNoPipe:'actif'}}" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 col-form-label">Code organisme type 1 générique</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" readonly
                                            value="{{amoData.codeOrgType1Gen | yesNoPipe:'actif'}}" />
                                    </div>
                                    <div class="col-sm-6"></div>
                                </div>
                            </fieldset>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3"id="amc" title="Assurance maladie complémentaire">
                    		<button ngbNavLink>Assurance maladie complémentaire</button>
		<ng-template ngbNavContent>
                        <!-- ONGLET RC -->
                        <div class="form-block mt-3" *ngIf="(amcData|json) != null">
                            <fieldset class="container-fluid">
                                <div class="row">
                                    <div class="col-sm-3 col-form-label">Sigle</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" readonly
                                            value="{{amcData.libelleCourt}}" />
                                    </div>
                                    <div class="col-sm-3 col-form-label">Organisme actif</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" readonly
                                            value="{{amcData.actif | yesNoPipe:'actif'}}" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 col-form-label">Libellé</div>
                                    <div class="col-sm-9 form-group">
                                        <input type="text" class="form-control" readonly
                                            value="{{amcData.libelleLong}}" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 col-form-label">Adresse 1</div>
                                    <div class="col-sm-9 form-group">
                                        <input type="text" class="form-control" readonly value="{{amcData.adresse1}}" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 col-form-label">Adresse 2</div>
                                    <div class="col-sm-9 form-group">
                                        <input type="text" class="form-control" readonly value="{{amcData.adresse2}}" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 col-form-label">Code Postal</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" readonly
                                            value="{{amcData.codePostal}}" />
                                    </div>
                                    <div class="col-sm-3 col-form-label">Ville</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" readonly value="{{amcData.ville}}" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 col-form-label">Téléphone</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" readonly
                                            value="{{amcData.telephone}}" />
                                    </div>
                                    <div class="col-sm-3 col-form-label">Fax</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" readonly value="{{amcData.fax}}" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 col-form-label">Commentaire</div>
                                    <div class="col-sm-9 form-group">
                                        <input type="text" class="form-control" readonly
                                            value="{{amcData.commentaires}}" />
                                    </div>
                                </div>
                                <div class="row">

                                    <div class="col-sm-3 col-form-label">Code officiel</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" readonly
                                            value="{{amcData.codeOfficiel}}" />
                                    </div>
                                    <div class="col-sm-3 col-form-label">Code entrée</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" readonly value="" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 col-form-label">Aide pérenne</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" readonly
                                            value="{{amcData.aidePerenne | yesNoPipe: 'actif'}}" />
                                    </div>
                                    <div class="col-sm-3 col-form-label">AMC seul</div>
                                    <div class="col-sm-3 form-group">
                                        <input type="text" class="form-control" readonly
                                            value="{{amcData.rcSeul | yesNoPipe:'actif'}}" />
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        <div class="form-block mt-3" *ngIf="(amcData|json) == null">
                            <fieldset class="container-fluid">
                                <div class="row">
                                        <div class="col-auto me-auto text-info emptyData">La part complémentaire n'est pas prise en compte pour cet FSE</div> 
                                </div>
                                <div class="row emptyTable">
    
                                </div>

                            </fieldset>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div> 
        </section>
    </form>
</div>