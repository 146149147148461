import { Component } from '@angular/core';

@Component({
    selector: 'careweb-column-organism-tooltip',
    template: `
        <p  container="body"
            [tooltip]="tolTemplate"
            placement="bottom"
            containerClass="tooltip-grid"
            class="overflow-hidden text-truncate rappro-tooltip"
        >
            {{value}}
        </p>
        
        <ng-template #tolTemplate><div [innerHtml]="tooltipValue"></div></ng-template>
    `
} )
export class ColumnOrganismTooltipComponent {
    value: string;
    tooltipValue = '';
    constructor() {
    }

    agInit(params: any): void {
        if(params.colDef.field === 'libelleOrganisme' || params.colDef.field === 'libelleLong') {
           this.tooltipValue = 'Téléphone : ' + params.data.telephone + '<br> Cliquer pour plus de détail';
        }
        this.value = params.value;
    }
}