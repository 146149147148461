<div class="modal modal-show fade show" id="modalDetailsMigrationOct" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel">

    <div class="modal-dialog modal-xxl" role="document">
        <div class="modal-content">
            <div class="inmodal">
                <div class="modal-header pb-1">
                    <h3 class="modal-title">Contenu de la boite mail à migrer</h3>
                    <button class="close" name="btn_display_filter" data-dismiss="modal" type="button" title="Fermer"
                        (click)="closeModal()"><span aria-hidden="true">×</span></button>
                </div>
                <div class="modal-body pb-0">
                    <div class="form-block">
                        <fieldset class="container-fluid">
                            <div class="row mb-2 align-right">
                                <div class="col-md-12 conf-col align-right">
                                    <div class="careweb-toolbar">
       <!--                                  <span class="btn btn-primary small" tooltip="Ajouter un compte" placement="top"
                                            container="body" name="add_account_oct">
                                            Copier
                                        </span>
                                        <span class="btn btn-primary small" tooltip="Ajouter un compte" placement="top"
                                            container="body" name="add_account_oct">
                                            Renvoyer
                                        </span> -->
                                         <careweb-pagination-size id="dv_rule_paging" [pageSizes]="[10, 20,30, 50, 100]"
                                            [(ngModel)]="gridDataRaw.size"
                                            (paginationSizeChange)="onPaginationSizeChange($event)">
                                        </careweb-pagination-size>
                                        <span class="btn btn-action small" tooltip="Réinitialiser l'affichage"
                                            placement="top" container="body" name="spn_reset_grid"
                                            (click)="resetGridState()">
                                            <i class="fa fa-refresh"></i>
                                        </span> 
                                    </div>
                                </div>
                            </div>
                             <div class="row">
                                <div class="col-md-12 text-title noPadding ">
                                    <ag-grid-angular style="width: 100%;" class="ag-theme-balham"
                                        [gridOptions]="migrationOctDetailsGridHelper.gridOptions">
                                    </ag-grid-angular>
                                    <careweb-pagination [gridApi]="migrationOctDetailsGridHelper.gridApi">
                                    </careweb-pagination>
                                </div>
                            </div>
<!--                             <div class="row">
                                <div class="col-sm-12">
                                <div class="accordion" id="emails_accordion">
                                    <div *ngFor="let item of items; let i = index" class="card">
                                      <div class="card-header" id="heading{{i}}">
                                        <h2 class="mb-0">
                                          <button
                                            class="btn btn-link btn_emails_accordion"
                                            type="button"
                                            data-toggle="collapse"
                                            [attr.data-target]="'#collapse' + i"
                                            [attr.aria-expanded]="activeItem === i ? 'true' : 'false'"
                                            (click)="toggleItem(i)"
                                          >
                                          <div class="row align-left">
                                            <div class="col-sm-6">
                                                {{ item.subject }}
                                            </div>
                                            <div class="col-sm-4">
                                                {{ item.sender }}
                                            </div>
                                            <div class="col-sm-2">
                                                {{ item.date }}
                                            </div>
                                          </div>
                                            
                                          </button>
                                        </h2>
                                      </div>
                                  
                                      <div
                                        [ngClass]="{ 'collapse show': activeItem === i, 'collapse': activeItem !== i }"
                                        id="collapse{{i}}"
                                        class="card-body"
                                      >
                                        {{ item.content }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div> -->
                            <div class="button-bar control">
                                <button type="button" class="btn btn-primary" (click)="reset()"> Fermer </button>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div class="modal-footer">
                </div>
            </div>
        </div>
    </div>
</div>