import { AfterViewInit, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { BsModalService } from 'ngx-bootstrap/modal';
import { isNullOrUndefined } from 'util';
import {  URL_BO_BOITE_MAIL } from '../../core/constants';
import { AddMigrationOctComponent } from './add-migration-oct/add-migration-oct.component';
import { DeleteMigrationOctComponent } from './delete-migration-oct/delete-migration-oct.component';
import { DetailsMigrationOctComponent } from './details-migration-oct/details-migration-oct.component';
import { LogsMigrationOctComponent } from './logs-migration-oct/logs-migration-oct.component';
import { StartMigrationOctComponent } from './start-migration-oct/start-migration-oct.component';

@Component({
    selector: 'careweb-migration-preview',
    template: `
      <i 
        [ngClass]="iconClass"
        container="body"
        (click)="migrationOctActionClick()"
        [tooltip]="tooltip"
        containerClass="tooltip-grid"
	  ></i>
    `
})
export class MigrationOctPreviewComponent implements ICellRendererAngularComp, AfterViewInit {
    private params: any;
    iconClass: string;
    tooltip = '';
    showConfirmModal = false;
    modalMessage: string;

    constructor(
        private modalService: BsModalService
    ) { }

    agInit(params: any): void {
        this.params = params;
    }

    ngAfterViewInit() {
        const cellParams = this.params;
        if (cellParams.data) {
            if (!isNullOrUndefined(cellParams.iconClass)) {
                this.iconClass = cellParams.iconClass;
            }

            if (!isNullOrUndefined(cellParams.action)) {
                if (cellParams.action === 'edit') {
                    this.tooltip = 'Modifier les paramètres de la migration';
                } else if (cellParams.action === 'warning') {
                    if (cellParams.data.lastLogError) {
                        this.iconClass = 'fa fa-exclamation-circle red'
                    } else {
                        this.iconClass = 'fa fa-exclamation-circle'
                    }
                    this.tooltip = 'Accèder aux logs de cette migration';
                } else if (cellParams.action === 'start' && (cellParams.data.status == null || cellParams.data.status == false)) {// Migration arrêté
                    this.iconClass = 'fa fa-play'
                    this.tooltip = 'Commencer le processus de migration';
                } else if (cellParams.action === 'start' && cellParams.data.status == true) { // Migration lancé
                    this.iconClass = 'fa fa-pause'
                    this.tooltip = 'Arrêter le processus de migration';
                } else if (cellParams.action === 'view') {
                    this.tooltip = 'Consulter le contenu de la BAL à migrer';
                }
                else if (cellParams.action === 'delete') {
                    this.tooltip = 'Supprimer cette migration';
                } else if (cellParams.action === 'link') {
                    if (cellParams.data.idBalDestinatrice) {
                        this.iconClass = 'fa fa-link'
                    } else {
                        this.iconClass = 'fa fa-minus'
                    }
                    this.tooltip = 'Consulter le contenu de la BAL de destination';
                }
            }
        }
    }

    refresh(): boolean {
        return false;
    }

    migrationOctActionClick(): void {
        if (
            !isNullOrUndefined(this.params.action) &&
            this.params.action === 'edit'
        ) {
            //Open modal for edit
            this.modalService.show(AddMigrationOctComponent, { initialState: {} });
        }
        if (
            !isNullOrUndefined(this.params.action) &&
            this.params.action === 'start'
        ) {
            if (this.params.data.status == null || this.params.data.status == false) {
                //Open modal for start the process
                this.modalService.show(StartMigrationOctComponent, {
                    initialState: {
                        idBoiteMail: this.params.data.idBoiteMail,
                        idPs: this.params.data.idPs,
                        showModalStart: true
                    }
                });
            } else {
               // const modalMessage = `Le processus de migration est actuellement activé sur cette BAL, en poursuivant cette action, ce processus sera désactivé.
                // Souhaitez-vous poursuivre ?`;
                this.modalService.show(StartMigrationOctComponent, {
                    initialState: {
                        idBoiteMail: this.params.data.idBoiteMail,
                        idPs: this.params.data.idPs,
                        showModalStop: true
                    }
                });
            }
        }

        if (
            !isNullOrUndefined(this.params.action) &&
            this.params.action === 'warning'
        ) {
            //Open modal for warnings
            this.modalService.show(LogsMigrationOctComponent, {
                initialState: {
                    idBoiteMail: this.params.data.idBoiteMail,
                }
            });
        }

        if (
            !isNullOrUndefined(this.params.action) &&
            this.params.action === 'view'
        ) {
            //Open modal for warnings
            this.modalService.show(DetailsMigrationOctComponent, {
                initialState: {
                    email: this.params.data.username,
                    server: this.params.data.serveurPop,
                    password: this.params.data.password,
                    port: this.params.data.port
                }
            });
        }

        if (
            !isNullOrUndefined(this.params.action) &&
            this.params.action === 'edit'
        ) {
            //Open modal for warnings
            const initialState = {
                email: this.params.data.username,
                server: this.params.data.serveurPop,
                password: this.params.data.password,
                currentBoiteMail: this.params.data,
                isEditing: true
            }
            this.modalService.show(AddMigrationOctComponent, {
                initialState: initialState
            });
        }

        if (
            !isNullOrUndefined(this.params.action) &&
            this.params.action === 'delete'
        ) {
            //Open modal for warnings
            const initialState = {
                idBoiteMail: this.params.data.idBoiteMail,
            }
            this.modalService.show(DeleteMigrationOctComponent, {
                initialState: initialState
            });
        }

        if (
            !isNullOrUndefined(this.params.action) &&
            this.params.action === 'link'
        ) {
            window.open(`${URL_BO_BOITE_MAIL}${this.params.data.idBalDestinatrice}`);
        }
    }

    confirmModal() {
    }
}
