import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { BsModalService } from 'ngx-bootstrap/modal';
import { URL_BO } from '../../../../core/constants';
import { LotsDataRaw } from '../../../../data/lotsDataRaw';
import { FseDetailsModalComponent } from '../fse-details/fse-details-modal.component';
import { RspDetailsModalComponent } from '../rsp-details/rsp-details-modal.component';
import { StorageService } from './../../../../shared/storage-service';

@Component({
	selector: 'careweb-lot-preview',
	template: `
        <i
			[ngClass]="iconClass"
            container="body"
			(click)="previewLot()"
			[tooltip]="tooltip"
			containerClass="tooltip-grid"
			(contextmenu)="onRightClick($event)"
		></i>
    `
})
export class LotPreviewComponent implements ICellRendererAngularComp {
	iconClass: string;
	tooltip: string;
	currentLot: LotsDataRaw;
	private params: any;

	constructor(private router: Router, private modalService: BsModalService, private storageService: StorageService) {}

	agInit(params: any): void {
		if (params.data) {
			this.params = params;
			if (params.iconClass !== undefined) {
				this.iconClass = params.iconClass;
				this.tooltip =
					this.params.redirect ===  'lotDetails'
						? 'Consulter détails du lot'
						: this.params.redirect ===  'fseModal'
							? 'Consulter détails de la facture'
							: 'Consulter détails de la RSP';
			} else if (params.colDef.field && params.colDef.field ===  'securise') {
				params.value ===  true
					? ((this.iconClass = 'fa fa-lock green'), (this.tooltip = 'Sécurisé'))
					: ((this.iconClass = 'fa fa-unlock red'), (this.tooltip = 'Non sécurisé'));
			} else if (params.colDef.field && params.colDef.field ===  'sv') {
				params.value ==  true
					? ((this.iconClass = 'fa fa-check green'), (this.tooltip = 'Sésam-Vitale'))
					: ((this.iconClass = 'fa fa-close red'), (this.tooltip = 'Non Sésam-Vitale'));
			} else if (params.colDef.field && params.colDef.field ===  'tiersPayant') {
				params.value ===  true
					? ((this.iconClass = 'fa fa-check green'), (this.tooltip = 'Tiers-Payant'))
					: ((this.iconClass = 'fa fa-close red'), (this.tooltip = 'Non Tiers-Payant'));
			} else if (
				params.colDef.field &&
				params.colDef.field ===  'idFlux'
			) {
				(this.iconClass = 'fa fa-link'), (this.tooltip = 'Lien BO');
			}
			// Append cursor pointer
			this.iconClass = this.iconClass + ' cursor-pointer';
		}
	}

	refresh(): boolean {
		return false;
	}

	previewLot(): void {
		if (this.params.redirect ===  'lotDetails') {
			this.storageService.set('previouslyClickedLotId', this.params.node.data.idLot, true);
			this.router.navigate([ '/lot-details', this.params.data.idLot ]);
		} else if (this.params.redirect ===  'fseModal') {
			const initialState = {
				facture: this.params.data,
				lot: this.params.lot.currentLot,
				ps: this.params.lot.currentPs
			};
			this.modalService.show(FseDetailsModalComponent, { initialState, class: 'modal-xl' });
		} else if (this.params.redirect ===  'rspModal') {
			const initialState = {
				rsp: this.params.data,
				lot: this.params.lot.currentLot,
				ps: this.params.lot.currentPs
			};
			this.modalService.show(RspDetailsModalComponent, { initialState, class: 'modal-xl' });
		} else if (this.params.redirect ===  'boLink') {
			window.open(`${URL_BO}${this.params.value}`);
		}
	}

	onRightClick(event: MouseEvent): void {
		if (this.params.redirect === 'lotDetails') {
			event.preventDefault();
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/careweb-client/lot-details',  this.params.data.idLot])
			);
			window.open(url, '_blank');
		}
	}
}
