import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Statusable } from '../../core/interfaces/statusable';

/** Cell renderer pour l'affichage d'un statut d'un objet implémentant l'interface Statusable. */
@Component({
	template: `
        <ng-template #tooltipTemplate><div [innerHtml]="statusTooltip"></div></ng-template>
        <span
            class="ui-grid-cell-contents bullet-label"
            [ngClass]="statusClass"
            placement="top"
            container="body"
            containerClass="tooltip-grid"
        >
            {{ value }}
        </span>
    `
})
export class IconRendererComponent implements ICellRendererAngularComp {
	value: string;
	statusClass: string[];
	statusTooltip: string;

	constructor() {}

	agInit(params: any): void {
		this.value = params.valueFormatted;
		let data: Statusable = params.data;
		let statusColor = params.value ? 'green' : 'red';

		if (params.originalView == 'lots') {
			statusColor = params.value == true ? 'green' : params.value == false ? 'red' : '';
			if (data && (params.value == true || params.value == false)) {
				this.statusClass = [ statusColor, 'status', 'bullet' ];
			}
		} else {
			if (data) {
				this.statusClass = [ statusColor, 'status', 'bullet' ];
			}
		}
	}

	refresh(): boolean {
		return false;
	}
}
