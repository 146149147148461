import { CiviliteDataRaw } from './civiliteDataRaw';

export class ContactsDataRaw {
	idContact: number;
	idCivilite: number;
	idEditeur: number;
	nom: string;
	prenom: string;
	telephone: string;
	mobile: string;
	mail: string;
	fax: string;
	civilite: CiviliteDataRaw;

	ContactsDataRaw() {}
}
