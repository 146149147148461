import { AfterViewInit, Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NgModel, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
    selector: '[carewebCompareTo]',
    providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => CompareToDirective), multi: true }]
})

export class CompareToDirective implements Validator, AfterViewInit {
    @Input('carewebCompareTo')
    otherModelValue: NgModel;
    @Input()
    ignoreCase: boolean;

    private selfControl: AbstractControl;

    constructor() {}

    ngAfterViewInit() {
        this.otherModelValue.valueChanges.subscribe(() => {
            if (this.selfControl) {
                this.selfControl.updateValueAndValidity();
            }
        });
    }

    /**
     * Implémentation de la validation.
     */
    validate(control: AbstractControl): { [key: string]: boolean } {
        if (!this.selfControl && control) {
            this.selfControl = control;
        }

        // Vérification de l'égalité des valeurs
        const equal = this.equal(this.otherModelValue.value, control.value);
        if (!equal) {
            return {
                compareTo: false
            };
        }
        return null;
    }

    equal(toConfirm: string, confirmation: string) {
        if (this.ignoreCase) {
            toConfirm = !!toConfirm ? toConfirm.toLowerCase() : null;
            confirmation = !!confirmation ? confirmation.toLowerCase() : null;
        }
        return toConfirm === confirmation;
    }
}
