import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isNullOrUndefined } from 'util';

@Component({
	selector: 'careweb-confirm-modal',
	templateUrl: './confirm-modal.component.html',
	styles: [`.modal {
		top: 0;
		left: 0;
		z-index: 1050;
		width: 100%;
		margin-top: 20vh;
		height: 100%;
		overflow: hidden;
		outline: 0;
	}`
	]
})
export class ConfirmModalComponent implements OnInit {
	@Input() public visible: boolean;
	@Input() public text: string;
	@Input() public idOrganisme: number;
	@Input() public typeOrganisme: string;
	@Output() public showModal = new EventEmitter<boolean>();
	@Output() public confirmModal = new EventEmitter();
	@Input() public title: string;
	@Input() public labelValidButton;
	@Input() public labelCancelButton;
	@Input() public showAlert: boolean = false;
	constructor() {}

	ngOnInit() {
		if (isNullOrUndefined(this.title)) {
			this.title = 'Confirmation';
		}
		if (isNullOrUndefined(this.labelValidButton)) {
			this.labelValidButton = 'Oui';
		}
		if (isNullOrUndefined(this.labelCancelButton)) {
			this.labelCancelButton = 'Non';
		}

	}

	public confirm() {
		this.confirmModal.emit();
	}

	public cancel() {
		this.showModal.emit(false);
	}
}
