<div class="modal-header pb-1">
    <h3 class="modal-title">{{title}}</h3>
    <button class="close" name="btn_display_filter" data-dismiss="modal" type="button" title="Fermer"
        (click)="closeModal()"><span aria-hidden="true">×</span></button>
</div>
<div class="modal-body pb-0 retour-modal">
    <div class="form-block">
        <fieldset class="container-fluid">
            <pre>
                <code>
                    {{retourContent}}
                </code>
            </pre>
        </fieldset>
    </div>
</div>
<div class="modal-footer">

</div>