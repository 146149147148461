import { ColDef, IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { findIndex, isEmpty, uniqBy } from 'lodash-es';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { AgGridHelper } from '../../../components/ag-grid/ag-grid-helper';
import { gridConvertSort } from '../../../components/ag-grid/ag-grid.utils';
import { FilterItem } from '../../../data/filters/filter-item';
import { TypeZeroFilterRaw } from '../../../data/filters/typeZerofilterRaw';
import { NumeroEmetteurDataRaw } from '../../../data/numeroEmetteurDataRaw';
import { RegroupementDataRaw } from '../../../data/regroupementDataRaw';
import { TypeZeroDataRaw } from '../../../data/typeZeroDataRaw';
import { OrganismePreviewComponent } from '../organismes/organismes-subcomponents/organisme-preview.component';
import { RegroupementsService } from '../regroupements/regroupement.service';
import { TypeZeroService } from './type0.service';
import { RouteHistoryService } from '../../../shared/route-history.service';
import { GridDataRaw } from '../../../data/gridDataRaw';
import { StorageService } from '../../../shared/storage-service';
import {ExportModalComponent, ExportMode} from "../../export-modal/export-modal.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import { saveAs } from 'file-saver';
import {TransformService} from "../../../core/services/transform.service";
import {ExportState, State} from "../../../shared/model/storage.model";

@Component({
	selector: 'app-type0',
	templateUrl: './type0.component.html',
	styleUrls: ['./type0.component.scss']
})
export class TypeZeroComponent implements OnInit, OnDestroy {
	params: any;
	initHidden = true;
	showFilter = false;
	gridHelper: AgGridHelper;
	filters: TypeZeroFilterRaw;
	typeZeroDataRaw: TypeZeroDataRaw;
	dataSourceRegroupement: Observable<any>;
	regroupementSelected: string;
	asyncSelected: string;
	regroupementList: RegroupementDataRaw[];
	filterList: FilterItem[];
	gridDataRaw: GridDataRaw;
	rowCount: number;
	private openedModal: BsModalRef;
	exportType0: boolean = false;
	exportState: ExportState;
	serverName: string;
	static MIN_WIDTH = 1173;
	constructor(
		private typeZeroSvc: TypeZeroService,
		private regroupementsSvc: RegroupementsService,
		private router: Router,
		private routeHistory: RouteHistoryService,
		private storageService: StorageService,
		private modalService: BsModalService,
		private transformService: TransformService
	) {
		//Get grid size from session if already stored
		const sessionPageSize = this.storageService.get('types0PaginationPageSize', true);
		this.gridDataRaw = new GridDataRaw();
		this.gridDataRaw.size = !isNullOrUndefined(sessionPageSize) ? sessionPageSize : 10;

		this.gridHelper = new AgGridHelper('types0', this.gridDataRaw.size, 10);
		this.typeZeroDataRaw = new TypeZeroDataRaw();
	}

	ngOnInit() {
		// verify previous url if not details remove session currentPage
		if (this.routeHistory?.getPreviousUrl()?.indexOf('type0-details') === -1) {
			sessionStorage['types0CurrentPage'] = null;
		}
		// Init filters
		const parsedFilter = JSON.parse(sessionStorage.getItem('types0Filter'));
		// Get filter stored in session if exist
		if (!isEmpty(parsedFilter)) {
			this.filters = Object.assign({}, parsedFilter);
		} else {
			this.filterList = [new FilterItem()];
			this.filters = new TypeZeroFilterRaw();
		}
		this.gridHelper.gridOptions = {
			columnDefs: this.getColumnDefs(),
			defaultColDef: {
				resizable: true,
				suppressMenu: true
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 10,
			maxBlocksInCache: 0,
			infiniteInitialRowCount: 1,
			paginationPageSize: this.gridDataRaw.size,
			paginateChildRows: true,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			localeText: this.gridHelper.getLocaleText(),
			onRowClicked: (params) => this.onRowClicked(params),
			onGridReady: (params) => this.onGridReady(params),
			// onGridSizeChanged: (params) => this.onGridSizeChanged(params),
			onColumnMoved: (params) => this.onColumnMoved(params)
		};

		this.regroupementsSvc.getAllRegroupementsList().subscribe((regroupements) => {
			if (!isNullOrUndefined(regroupements)) {
				this.regroupementList = regroupements;
				if (isNullOrUndefined(this.filters.regroupement) || this.filters.regroupement === '')
					this.filters.regroupement = 'Tous';
			}
		});
	}

	onGridReady(params) {
		this.initFilter();
		const ctx = this;
		this.gridHelper.gridApi = params.api;
		this.gridHelper.gridColumnApi = params.columnApi;
		this.restoreGrid();
		if (window.innerWidth > TypeZeroComponent.MIN_WIDTH) {
			this.gridHelper.gridApi.sizeColumnsToFit();
			this.setColumnOrder(window.innerWidth);
		}
		const parsedFilter = JSON.parse(sessionStorage.getItem('types0Filter'));
		if (!isEmpty(parsedFilter)) {
			this.launchSearch();
		} else {
			const dataSource: IServerSideDatasource = {
				getRows: function (paramsRows: IServerSideGetRowsParams) {
					const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
					ctx.typeZeroSvc
						.getTypeZero({
							page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
							size: ctx.gridHelper.gridApi.paginationGetPageSize(),
							sorts
						})
						.subscribe(
							(data) => {
								ctx.rowCount = data.totalElements;
								ctx.gridHelper.manageNoRowsOverlay(ctx.rowCount);
								paramsRows.success({"rowData": data.content, "rowCount": ctx.rowCount});
								const pageN = Number.parseInt(sessionStorage.getItem('types0CurrentPage'));
								if (
									!isNullOrUndefined(pageN) &&
									pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
									pageN > 0
								) {
									ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
								}
								sessionStorage['types0CurrentPage'] = null;
							},
							() => paramsRows.fail()
						);
				}
			};
			this.gridHelper.gridApi.setServerSideDatasource(dataSource);
		}
	}
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		let width = event.target.innerWidth;
		this.setColumnOrder(width);
	}
	setColumnOrder(width:any) {
		const columnDefs = this.getColumnDefs();
		if(width<=1280){
			const optionsColumnIndex = columnDefs.findIndex(column => column.field === 'options');
			if (optionsColumnIndex !== -1) {
				columnDefs.splice(0, 0, columnDefs.splice(optionsColumnIndex, 1)[0]);
				this.gridHelper.gridApi.setColumnDefs(columnDefs);
			}
		}else {
			this.gridHelper.gridApi.sizeColumnsToFit();
			this.gridHelper.gridApi.setColumnDefs(columnDefs);
		}
	}
	onGridSizeChanged(params) {
		this.gridHelper.gridApi.sizeColumnsToFit();
	}

	onRowClicked(params) {
		this.router.navigate(['/type0-details', params.data.idTypeZero]);
	}

	restoreGrid() {
		this.gridHelper.restoreGridStatePrefix();
		this.gridDataRaw.size = this.gridHelper.paginationPageSize;
	}

	onKeyDown(event) {
		this.launchSearch();
	}

	toggleFilterDisplay() {
		let width =window.innerWidth;
		if (this.initHidden) {
			this.initHidden = false;
			setTimeout(() => {
				this.showFilter = !this.showFilter;
			}, 10);
		} else {
			this.showFilter = !this.showFilter;
			if(!this.showFilter && width>1400){
				setTimeout(() => {
					this.gridHelper.gridApi.sizeColumnsToFit();
				}, 200);
			}
		}
	}

	resetGridState() {
		this.gridHelper.resetGridState();
		this.gridDataRaw.size = this.gridHelper.defaultPaginationPageSize;
		//this.resetAllFilter();
	}

	export() {
		const ctx = this;
		const params = {
			skipHeader: false,
			columnGroups: false,
			skipFooters: false,
			skipGroups: true,
			skipPinnedTop: false,
			skipPinnedBottom: false,
			allColumns: true,
			onlySelected: false,
			fileName: 'Type_0_liste',
			sheetName: 'Type_0_liste',
			processCellCallback: function (params) {
				if (params.column.colDef.field === 'compactage' || params.column.colDef.field === 'cryptage') {
					params.value = ctx.formatResultBool({
						data: true,
						value: params.value
					});
				}
				return params.value;
			}
		};
		this.gridHelper.gridApi.exportDataAsExcel(params);
	}

	refresh(): boolean {
		return false;
	}

	resetAllFilter(): void {
		this.filters = new TypeZeroFilterRaw();
		this.filters.regroupement = 'Tous';
		this.launchSearch();
	}

	launchSearch(): void {
		const ctx = this;

		this.filters.typeEmetteur = this.filters.typeEmetteur ? this.filters.typeEmetteur.trim() : null;
		this.typeZeroDataRaw.typeEmetteur = !isNullOrUndefined(this.filters.typeEmetteur)
			? this.filters.typeEmetteur
			: '';
		this.typeZeroDataRaw.numeroEmetteur = new NumeroEmetteurDataRaw();
		this.typeZeroDataRaw.numeroEmetteur.numEmetteur = !isNullOrUndefined(this.filters.emetteur)
			? this.filters.emetteur
			: '';
		this.filters.typeDestinataire = this.filters.typeDestinataire ? this.filters.typeDestinataire.trim() : null;
		this.typeZeroDataRaw.typeDestinataire = !isNullOrUndefined(this.filters.typeDestinataire)
			? this.filters.typeDestinataire
			: '';
		this.filters.numDestinataire = this.filters.numDestinataire ? this.filters.numDestinataire.trim() : null;
		this.typeZeroDataRaw.numDestinataire = !isNullOrUndefined(this.filters.numDestinataire)
			? this.filters.numDestinataire
			: '';
		this.typeZeroDataRaw.compactage = isNullOrUndefined(this.filters.compactage) ? null : this.filters.compactage;
		this.typeZeroDataRaw.cryptage = isNullOrUndefined(this.filters.cryptage) ? null : this.filters.cryptage;
		this.typeZeroDataRaw.regroupement =
			!isNullOrUndefined(this.filters.regroupement) && this.filters.regroupement !== 'Tous'
				? this.filters.regroupement
				: '';

		this.updateFilterList(this.filters);
		const dataSource: IServerSideDatasource = {
			getRows: function (paramsRows: IServerSideGetRowsParams) {
				const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
				ctx.typeZeroSvc
					.getTypeZeroFilteredList(
						{
							page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
							size: ctx.gridHelper.gridApi.paginationGetPageSize(),
							sorts
						},
						ctx.typeZeroDataRaw
					)
					.subscribe(
						(data) => {
							ctx.rowCount = data.totalElements;
							ctx.gridHelper.manageNoRowsOverlay(ctx.rowCount);
							paramsRows.success({"rowData": data.content, "rowCount": ctx.rowCount});
							const pageN = Number.parseInt(sessionStorage.getItem('types0CurrentPage'));
							if (
								!isNullOrUndefined(pageN) &&
								pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
								pageN > 0
							) {
								ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
							}
							sessionStorage['types0CurrentPage'] = null;
						},
						() => paramsRows.fail()
					);
			}
		};
		sessionStorage['types0Filter'] = JSON.stringify(this.filters);
		this.gridHelper.gridApi.setServerSideDatasource(dataSource);
		// this.gridHelper.gridApi.sizeColumnsToFit();
	}

	private getColumnDefs(): ColDef[] {
		return [
			{
				headerName: 'Type émetteur',
				field: 'typeEmetteur',
				minWidth: 60,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				sortable: true
			},
			{
				headerName: 'Émetteur',
				field: 'numeroEmetteur.numEmetteur',
				minWidth: 80,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				sortable: true
			},
			{
				headerName: 'Type destinataire',
				field: 'typeDestinataire',
				minWidth: 60,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				sortable: true
			},
			{
				headerName: 'N° Destinataire',
				field: 'numDestinataire',
				minWidth: 80,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				sortable: true
			},
			{
				headerName: 'Application',
				field: 'application',
				minWidth: 60,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				sortable: true
			},
			{
				headerName: 'Compactage',
				field: 'compactage',
				minWidth: 60,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				cellRenderer: this.iconRenderer,
				sortable: true
			},
			{
				headerName: 'Cryptage',
				field: 'cryptage',
				minWidth: 60,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				cellRenderer: this.iconRenderer,
				sortable: true
			},
			{
				headerName: 'Programme Émetteur',
				field: 'programmeEmetteur',
				minWidth: 100,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				sortable: true
			},
			{
				headerName: 'Programme destinataire',
				field: 'programmeDestinataire',
				minWidth: 100,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				sortable: true
			},
			{
				headerName: '',
				field: 'options',
				minWidth: 50,
				maxWidth: 50,
				cellStyle: this.gridHelper.centerContent(),
				cellRenderer: OrganismePreviewComponent,
				cellRendererParams: {
					iconClass: 'fa fa-edit',
					action: 'edit',
					redirect: 'type0Details',
					type: 'type0'
				}
			}
		];
	}

	getCompactage() {
		let compactage = null;
		if (!this.filters.compactageOui && this.filters.compactageNon) {
			compactage = false;
		} else if (this.filters.compactageOui && !this.filters.compactageNon) {
			compactage = true;
		}
		return compactage;
	}

	updateFilterList(filters) {
		const ctx = this;
		ctx.filterList = [];
		Object.keys(filters).forEach((key) => {
			if (
				filters[key] !== null &&
				filters[key] !== '' &&
				(key !== 'regroupement' || (key === 'regroupement' && filters[key] !== 'Tous'))
			) {
				ctx.filterList.push(ctx.translateFilterLabelValue(key, filters[key]));
			}
		});
		// Remove duplicated ids
		ctx.filterList = uniqBy(ctx.filterList, 'id');
		// filter null
		ctx.filterList = ctx.filterList.filter((item) => {
			return item.id !== null;
		});
		sessionStorage['type0Filter'] = JSON.stringify(this.filters);
	}

	translateFilterLabelValue(key, value) {
		let translatedKey = key;
		let formattedValue = value;
		let formattedKey = key;
		switch (key) {
			case 'typeEmetteur':
				translatedKey = 'Type émetteur';
				break;
			case 'emetteur':
				translatedKey = 'Emetteur';
				break;
			case 'typeDestinataire':
				translatedKey = 'Type destinataire';
				break;
			case 'numDestinataire':
				translatedKey = 'N° Destinataire';
				break;
			case 'compactage':
				translatedKey = 'Compactage';
				formattedValue = value ? 'Oui' : 'Non';
				break;
			case 'cryptage':
				translatedKey = 'Cryptage';
				formattedValue = value ? 'Oui' : 'Non';
				break;
			case 'regroupement':
				translatedKey = 'Regroupement';
				break;
			default:
				break;
		}

		return new FilterItem().withId(formattedKey).withLabel(translatedKey).withValue(formattedValue).withValue2(value);
	}

	initFilter() {
		// Liste des filtres
		this.resetFilter();
		this.updateFilterList(this.filters);
	}

	removeFilter(id) {
		const ctx = this;
		delete ctx.filters[id];
		let currentFilterList = ctx.filterList;
		const index = findIndex(currentFilterList, (filt) => filt.id === id);
		currentFilterList.splice(index, 1);
		ctx.filterList = currentFilterList;
		this.fillFilterObjectFromTagsOnly();
		ctx.launchSearch();
		if (isNullOrUndefined(ctx.filters.regroupement) || ctx.filters.regroupement === '') {
			this.filters.regroupement = 'Tous';
		}
	}

	fillFilterObjectFromTagsOnly() {
		this.filters = new TypeZeroFilterRaw();
		this.filterList.forEach(filter => {
			this.filters[filter.id] = filter.value2;
		});
	}

	ngOnDestroy() {
		sessionStorage['types0Filter'] = JSON.stringify(this.filters);
		sessionStorage['types0CurrentPage'] = this.gridHelper.gridApi.paginationGetCurrentPage();
		this.gridHelper.saveGridStatePrefix();
	}

	resetFilter() {
		this.typeZeroSvc.reset();
	}

	iconRenderer = (params): string => {
		if (!isNullOrUndefined(params.value)) {
			return params.value
				? '<span><i class="fa fa-check"></i></span>'
				: '<span><i class="fa fa-close"></i></span>';
		} else {
			return '';
		}
	}

	formatResultBool = (params): string => {
		if (params.data) {
			return params.value ? 'Oui' : 'Non';
		}
	}

	onPaginationSizeChange(paginationSize: number): void {
		this.gridHelper.changePaginationSize(paginationSize);
		this.launchSearch();
		this.gridHelper.saveGridStatePrefix();
	}

	onColumnMoved(params: any): void {
		this.gridHelper.saveGridColumnState();
	}

	ExportDataOfTypeZero() {
		const columns: string[] = this.gridHelper.gridColumnApi.getAllDisplayedColumns()
			.filter(column => column.getColDef().headerName != "")
			.map(column => column.getColDef().field);
		if (this.rowCount > 0 && this.rowCount <= 1000) {
			this.exportToExcel(columns);
		} else if (this.rowCount > 1000) {
			this.openedModal = this.modalService.show(ExportModalComponent,  { initialState: {
					nbrElement: this.rowCount
				}, class: 'modal-lg'});
			this.openedModal.content.exportEvent.subscribe((e: any) => {
				if (e.action === ExportMode.EXPORT) {
					// this.exportFileXlsx();
					this.exportToExcel(columns);
				}
				if (e.action === ExportMode.EMAIL) {
					// this.exportFileXlsx();
					this.exportByEmail(columns);
				}
			});
		}
		}
		exportToExcel(columns) {
			this.exportType0 = true;
			this.exportState = {
				message : "Vous allez exporter <b>" + this.transformService.transform(this.rowCount) + " ligne(s)</b>, veuillez patienter quelques instants.",
				state: State.INPROGRESS
			}
			const sorts = gridConvertSort(this.gridHelper.gridSortModel(),[]);
			this.typeZeroSvc.exportTypeZeros(
				{
					page: 0,
					size: this.rowCount,
					export: false,
					sorts
				},
				this.typeZeroDataRaw,
				columns
			).subscribe((response) => {
				saveAs(response.file, response.fileName);
				this.exportState = {
					message : "Votre fichier a été téléchargé avec succès.",
					state: State.SUCCESS
				}
			},() => {
				this.exportState = {
					message : "Le téléchargement de votre fichier a rencontré un problème.",
					state: State.FAILED
				}
			});
		}
		exportByEmail(columns) {
			const sorts = gridConvertSort(this.gridHelper.gridSortModel(),[]);
			this.typeZeroSvc.exportTypeZerosByEmail(
				{
					page: 0,
					size: this.rowCount,
					export: false,
					sorts
				},
				this.typeZeroDataRaw,
				columns
			).subscribe((response) => {
				// when email received
			});
		}
	closeAlert() {
		this.exportType0 = false;
	}
	getColumnsAndFieldDataType0(columnKeys: string[], dataFields ? : any) {
		let data = new Map();
		columnKeys.forEach(column => {
			switch (column) {
				case "typeEmetteur":
					data.set("Type émetteur", dataFields.typeEmetteur);
					break;
				case "typeDestinataire":
					data.set("Type destinataire", dataFields.typeDestinataire);
					break;
				case "numDestinataire":
					data.set("N° Destinataire", dataFields.numDestinataire);
					break;
				case "application":
					data.set("Application", dataFields.application);
					break;
				case "compactage":
					data.set("Compactage", dataFields.compactage ? "Oui" : "Non");
					break;
				case "cryptage":
					data.set("Cryptage", dataFields.cryptage ? "Oui" : "Non");
					break;
				case "programmeEmetteur":
					data.set("Programme Émetteur", dataFields.programmeEmetteur);
					break;
				case "programmeDestinataire":
					data.set("Programme destinataire", dataFields.programmeDestinataire);
					break;
				case "numeroEmetteur.numEmetteur":
					data.set("Émetteur", dataFields.numeroEmetteur !== undefined ? dataFields.numeroEmetteur.numEmetteur : '');
					break;
			}
		})
		return data;
	}
}