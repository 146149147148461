<form [formGroup]="etablissementForm" (ngSubmit)="onSubmit()">
    <div class="tab-pane">
        <div class="form-block">
            <fieldset class="container-fluid">
                <div class="row">
                    <div class="col-sm-2 col-form-label">Raison Sociale *</div>
                    <div class="col-sm-10 form-group">
                        <input type="text" class="form-control" formControlName="raisonSociale"
                            onKeyPress="if(this.value.length==50) return false;" />

                        <div *ngIf="submitted && formFields.raisonSociale.errors" class="invalid-feedback">
                            <div
                                *ngIf="formFields.raisonSociale.errors.required || formFields.raisonSociale.errors.whitespaces">
                                Ce champ est obligatoire</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Adresse 1</div>
                    <div class="col-sm-10 form-group">
                        <input type="text" class="form-control" formControlName="adresse1"
                            onKeyPress="if(this.value.length==50) return false;" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Adresse 2</div>
                    <div class="col-sm-10 form-group">
                        <input type="text" class="form-control" formControlName="adresse2"
                            onKeyPress="if(this.value.length==50) return false;" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Code Postal</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" formControlName="codePostal"
                            oninput="this.value=this.value.replace(/[^\d]/,'');"
                            onpaste="if(this.value.length==5) return false;"
                            onKeyPress="if(this.value.length==5) return false;" />
                    </div>
                    <div class="col-sm-2 col-form-label">Ville</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" formControlName="ville"
                            onKeyPress="if(this.value.length==50) return false;" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Téléphone</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" formControlName="telephone"
                            oninput="this.value=this.value.replace(/[^\d]/,'');"
                            onpaste="if(this.value.length==16) return false;"
                            onKeyPress="if(this.value.length==16) return false;" />
                    </div>
                    <div class="col-sm-2 col-form-label">Fax</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" formControlName="fax"
                            oninput="this.value=this.value.replace(/[^\d]/,'');"
                            onpaste="if(this.value.length==40) return false;"
                            onKeyPress="if(this.value.length==40) return false;" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Email *</div>
                    <div class="col-sm-10 form-group">
                        <input type="text" class="form-control" formControlName="email"
                            onKeyPress="if(this.value.length==150) return false;" />
                            <div *ngIf="(submitted || formFields.email.touched || formFields.email.dirty) && formFields.email.errors">
                                <small *ngIf="formFields.email.errors?.required" class="text-danger">Ce champ est obligatoire</small>
                                <!--question mark(?) is a safe navigation operator-->
                                <small *ngIf="formFields.email.errors?.pattern" class="text-danger">Le format du champ E-mail est invalide</small>
                              </div>
                    </div>
                </div>

                <div class="button-bar control">
                    <button type="button" class="btn btn-default" (click)="onReset()"> Annuler </button>
                    <button type="submit" class="btn btn-primary"> Ajouter </button>
                </div>

            </fieldset>
        </div>
    </div>
</form>