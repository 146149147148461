

import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Injectable()
export class IsNotPsGuard implements CanActivate {
	constructor(private authSvc: AuthService, private router: Router) {}

	canActivate() {
		const isPs = this.authSvc.userAuthorized('PS');
		if (isPs) {
			this.router.navigate([ '/dashboard' ]);
		}
		return !isPs;
	}
}
