import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { MigrationOctService } from '../migration-oct.service';

@Component({
	selector: 'careweb-app-delete-migration-oct',
	templateUrl: './delete-migration-oct.component.html',
	styleUrls: ['./delete-migration-oct.component.scss']
})

export class DeleteMigrationOctComponent implements OnInit {
	@Input() idBoiteMail: number;
	@Input() showModalDelete: boolean;
	@Output() modalClosed = new EventEmitter<void>();
	modalMessage: string = 'Vous êtes sur le point de supprimer ce processus de migration. Souhaitez-vous poursuivre ?'

	constructor(
		private modalRef: BsModalRef,
		private toastr: ToastrService,
		private migrationSvc: MigrationOctService,
		private modalService: BsModalService,

	) { }

	ngOnInit() {
		this.showModalDelete = true;
	}

	closeModal() {
		this.modalRef.hide();
		this.showModalDelete = false;
		this.modalClosed.emit();
		this.modalService.onHide.subscribe(() => {
			this.migrationSvc.notifyModalClosed();
		});
	}

	reset() {
		this.modalRef.hide();
	}

	confirmModal() {
		this.migrationSvc.delete(this.idBoiteMail).subscribe(
			() => {
				this.toastr.success("Le processus de migration a été supprimé.", "", { timeOut: 60000 });
				this.closeModal();
			},
			(err) => {
				this.toastr.error("Une erreur est survenue lors de la suppression du processus de migration.", "", { timeOut: 60000 });
			}
		);
	}

	showModal(showModal: boolean): void {
		this.showModalDelete = false;
		this.closeModal();
	}
}
