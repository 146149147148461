import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { RappComptePsDataRaw } from '../../data/rappComptePsDataRaw';
import { User } from '../../data/userDataRaw';
import { CONF } from '../constants';
import { UserService } from '../services/user.service';
import { UsersService } from './../../pages/users/users.service';
import { EbicsParams } from '../../data/ebicsParams';

/** Service de gestion de l'authentification d'un utilisateur. */
@Injectable({ providedIn: 'root' })
export class AuthService {
    private readonly apiPath = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl;
    private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/login';
    private readonly baseUrlOxlin = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/rapp';
    private readonly baseUrlEbics = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/ebics';
    public currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private usersDA: UserService, public userSvc: UsersService, private httpClient: HttpClient,  private router: Router) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('careweb_user')));
    }

    public get currentUserValue(): User {
        if (localStorage.getItem('careweb_token')) {
            return this.currentUserSubject.value;
        } else {
            return null;
        }
    }

    public set setUserValue(value: BehaviorSubject<User>) {
        this.currentUserSubject = value;
    }

    public userAuthorized(role): boolean {
        let roleId;
        switch (role) {
            case 'HOTLINER':
                roleId = 3;
                break;
            case 'PS':
                roleId = 2;
                break;
            case 'ADMIN':
                roleId = 1;
                break;
            case 'ADMIN_SEL':
                roleId = 4;
                break;
        }
        const token = localStorage.getItem('careweb_token');
        if (token) {
            const decodedToken = jwt_decode(token);
            return decodedToken.userType == roleId;
        } else {
            return false;
        }
    }

    /** Authentifie un utilisateur avec un login et un mot de passe. */
    login(username: string, password: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                ContentType: 'application/json'
            })
        };
        return this.httpClient
            .post<any>(this.baseUrl + '/authenticate', { username: username, password: password,number : sessionStorage['nbr'] }, httpOptions)
            .pipe(
                map((token) => {
                    // La réponse doit contenir les informations de l'utilisateur et un token
                    if (token.jwtToken) {
                        // On enregistre le jwt token dans le local storage
                        localStorage.setItem('careweb_token', token.jwtToken);
                    }
                    return token;
                }, catchError(this.handleError))
            );
    }

    handleError(error: HttpErrorResponse) {
        return throwError(error);
    }

    /** Authentifie un utilisateur ayant perdu son mot de passe. */
    loginNewPwd(login: string, token: string, newPassword: string): Observable<User> {
        return this.usersDA.resetPwd(login, token, newPassword)
        .pipe(tap((userCtx) => {
            localStorage.setItem('careweb_user', JSON.stringify(userCtx));
            this.currentUserSubject = new BehaviorSubject<User>(userCtx);
            this.currentUser = this.currentUserSubject.asObservable();
        }));
	}

    getUserInfo(credentials): Observable<User> {
        const httpOptions = credentials
            ? {
                  headers: new HttpHeaders({
                      Authorization: 'Bearer ' + credentials.jwtToken,
                      ContentType: 'application/json'
                  })
              }
            : {};
        return this.httpClient.get<any>(this.baseUrl + '/user-info/', httpOptions).pipe(
            map((user) => {
				// si utilisateur est un PS qui n'a pas idPs rataché
                if (user.idTypeUser === 2 && isNullOrUndefined(user.idPs)) {
                    return user;
                } else {
                    localStorage.setItem('careweb_user', JSON.stringify(user));
                    this.currentUserSubject = new BehaviorSubject<User>(user);
                    this.currentUser = this.currentUserSubject.asObservable();
                }
                return user;
            })
        );
    }

    getRappUserInfo(credentials, idPs): Observable<RappComptePsDataRaw> {
        const httpOptions = credentials
            ? {
                  headers: new HttpHeaders({
                      Authorization: 'Bearer ' + credentials.jwtToken,
                      ContentType: 'application/json'
                  })
              }
            : {};
        return this.httpClient.get<any>(this.baseUrlOxlin + '/id/' + idPs, httpOptions).pipe(
            map((rappComptePs) => {
                if (rappComptePs != null && rappComptePs.actif) {
                    delete rappComptePs.addressEmail;
                    localStorage.setItem('careweb_rapp_user', JSON.stringify(rappComptePs));
                }else{
                    return null;
                }
                return rappComptePs;
            })
        );
    }

    getEbicsUserInfo(credentials, idPs): Observable<EbicsParams> {
        const httpOptions = credentials
            ? {
                  headers: new HttpHeaders({
                      Authorization: 'Bearer ' + credentials.jwtToken,
                      ContentType: 'application/json'
                  })
              }
            : {};
        return this.httpClient.get<any>(this.baseUrlEbics + '/' + idPs, httpOptions).pipe(
            map((data) => {
                console.log("data auth service",data);
                if (data != null && data[0]?.actif) {
                    var carewebUser = JSON.parse(localStorage.getItem('careweb_user'));
                    carewebUser.typeRappro='EBICS';
                    localStorage.setItem('careweb_user', JSON.stringify(carewebUser));
                }
                return data[0];
            })
        );
    }

    // Check the validity of the token
    isTokenExpired(token?: string): boolean {
        if (!token) {
            token = this.getToken();
        }
        if (!token) {
            this.clearStorages();
            return true;
        }

        const date = this.getTokenExpirationDate(token);
        if (date != undefined && date.valueOf() <= new Date().valueOf()) {
            this.clearStorages();
            return true;
        } else {
            return false;
        }
    }

    getToken(): string {
        return localStorage.getItem('careweb_token');
    }

    getCurrentUserStatut(): Observable<boolean> {
        return this.userSvc.getUserStatutById(this.currentUserSubject.value.idUser); 
    }

    getTokenExpirationDate(token: string): Date {
        const decoded = jwt_decode(token);
        const date = new Date(0);
        if (decoded.exp == undefined) {
            return null;
        }
        date.setUTCSeconds(decoded.exp);
        return date;
    }

    clearStorages(): void {
        localStorage.removeItem('careweb_token');
        localStorage.removeItem('careweb_user');
        localStorage.removeItem('hotlineFilters');
    }

    logout(): Observable<void> {
        if (this.router.url != '/login') {
            sessionStorage.setItem("lastRoute", this.router.url)
        }
        if (this.router.url === '/organization-list') {
            sessionStorage.removeItem('lastRoute');
        }
        return this.httpClient.post<void>(`${this.apiPath}/v1/logout`, {});
    }
}
