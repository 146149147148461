import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { DynamicGridComponent } from './dynamic-grid.component';
import { DynamicGridSvc } from './dynamic-grid.service';
import { AgGridModule } from 'ag-grid-angular';

@NgModule({
    imports: [CommonModule, AgGridModule, HttpClientModule],
    declarations: [DynamicGridComponent],
    providers: [DynamicGridSvc],
    exports: [DynamicGridComponent]
})
export class DynamicGridModule {}
