import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'organismeType'
})
export class OrganismeTypePipe implements PipeTransform {

  transform(organismeType: string): string {

  switch(organismeType){

    case 'RO':
      return 'AMO';
    case 'RC':
      return 'AMC';
    default:
      return '';
      
  }

  }
}
