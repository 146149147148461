import {AbstractControl } from "@angular/forms";

export function validateText(Token: any){
return (control:AbstractControl):{[key:string]:boolean} | null =>   {
    const input = control.value;
    if(!input){
        return null;
    }

    if(Token === 2){
        const regex = /^\d{4}(\/\d{4})*$/;
        const line = input;
        if(!regex.test(line)){
            return  {'invalidFormat' : true};
        }
        return null;
    }
    else{
        const regex = /^\d{9}#\d{4}(\/\d{4})*$/;
        const lines = input.split("\n");
        const invalidLines = [];
        lines.forEach((line:any) => {
            if(!regex.test(line)){
                invalidLines.push(line);
            }
        });
        if(invalidLines.length){
            return  {'invalidFormat' : true};
        }
        return null;
    }


}
}