import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { CONF } from '../../../core/constants';
import { PsDataRaw } from '../../../data/psDataRaw';
import { OctPsDataRaw } from '../../../data/octpsDataRaw';
import { catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class OctPsService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/ps/oct';

	constructor(private httpClient: HttpClient) {}

	getPsByIdPsAndIdOct(idOct: number, idPs: number): Observable<OctPsDataRaw> {
		return this.httpClient.get<any>(this.baseUrl + '/?idOct=' + idOct + '&idPs=' + idPs);
	}

	getOctPsByIdPs(idPs: number): Observable<OctPsDataRaw> {
		return this.httpClient.get<any>(this.baseUrl + '/' + idPs);
	}


	updatePs(idOct, idPs, ps: PsDataRaw): Observable<PsDataRaw> {
		return this.httpClient
			.put<any>(this.baseUrl + '/update/?idOct=' + idOct + '&idPs=' + idPs, ps)
			.pipe(catchError(this.handleError));
	}

	handleError(error: HttpErrorResponse) {
		return throwError(error);
	}

	getInactifPs(criteria: OctPsDataRaw): Observable<number> {
		return this.httpClient.post<number>(this.baseUrl + '/count/actifStatus', criteria);
	}

	getDerniereTransmissionByPs(idPs: number): Observable<OctPsDataRaw> {
		return this.httpClient.get<any>(this.baseUrl + '/last/?idPs=' + idPs);
	}

}
