import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { OctService } from '../../core/services/oct.service';
import { isNullOrUndefined } from 'util';
import { AuthService } from '../../core/services/auth.service';

@Injectable()
export class EditeursAccessGuard implements CanActivate {
	constructor(private authSvc: AuthService, private octSvc: OctService, private router: Router) {}

	canActivate() {
		const allowedOct: any[] = [ 'QTP', 'CDS', 'SAN' ];

		let selectedOctCode: string;
		let canActive: boolean;

		this.octSvc.currentOctSubject.subscribe((oct) => {
			if (!isNullOrUndefined(oct)) {
				selectedOctCode = oct.code;

				//canActive = allowedOct.indexOf(selectedOctCode) !== -1 && this.isAuthorized();
				canActive = this.isAuthorized();
				if (!isNullOrUndefined(canActive) && !canActive) {
					this.router.navigate([ '/dashboard' ]);
				}
			} else {
				const parsedOctSelected = JSON.parse(localStorage.getItem('octCurrent'));
				if (!isNullOrUndefined(parsedOctSelected)) {
					selectedOctCode = parsedOctSelected.code;
				} else {
					selectedOctCode = 'QTP';
				}
				canActive = allowedOct.indexOf(selectedOctCode) !== -1 && this.isAuthorized();
				if (!isNullOrUndefined(canActive) && !canActive) {
					this.router.navigate([ '/dashboard' ]);
				}
			}
		});

		return canActive;
	}

	isAuthorized() {
		return this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
	}
}
