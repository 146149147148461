import { Injectable } from '@angular/core';
import some from 'lodash-es/some';
import { FilterService } from '../../../core/services/filter-service';
import { FilterItem } from '../../../data/filters/filter-item';

@Injectable()
export class ArlsFilterSvc extends FilterService {
	constructor() {
		super();
	}

	/**
     * Supprime un filtre de la liste des filtres
     *
     * @param {Object} filters - Variables bindé sur les champs de saisi des filtres
     * @param {string} id - Identifiant du filtre à supprimer
     *
     */
    removeFilter(id: string): void {
        this.removeFilterTmp(id);
        const index = this.filterList.findIndex(filt => filt.id === id);
        this.filterList.splice(index, 1);
    }

	/**
     * Ajout d'un filtre dans la liste temporaires des filtres.
     *
     * @param filterItem - Nouveau filtre à ajouter
     *
     */
	addFilterTmp(filterItem: FilterItem): void {
		// Si un filtre avec un identifiant similaire on le supprime
		if (some(this.filterListTmp, { id: filterItem.id })) {
			this.removeFilterTmp(filterItem.id);
		}
		this.filterListTmp.push(filterItem);
	}

	/**
     * Supprime un filtre de la liste temporaire des filtres
     *
     * @param filters - Variables bindé sur les champs de saisi des filtres
     * @param id - Identifiant du filtre à supprimer
     *
     */
    removeFilterTmp(id: string): void {
        const index = this.filterListTmp.findIndex(filt => filt.id === id);
        this.filterListTmp.splice(index, 1);
    }
}
