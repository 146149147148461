export class RspRappSummaryFilterRaw {
	numLot: string;
	numFacture: string;
	dateReception: Date;
	dateReceptionFacture: Date;
	libelleOrganisme: string;
	organismes: Array<string>;
    periode: number;
    dateComptableFrom: Date;
    dateComptableTo: Date;
    numLotFrom: string;
    numLotTo: string;

    montantRspFrom: number;
    montantRspTo: number;
    montantRspExacte: number;
    montantRspFilter:FilterChoiceEnum;

    montantGroupeRspFrom: number;
    montantGroupeRspTo: number;
    montantGroupeRspExacte: number;
    montantGroupeRspFilter:FilterChoiceEnum;

    idPs: number;
    libelleVirement: string;
    idOrganisme: string;
    rsp30Jours: Boolean;
    rspIndu: Boolean;
}

export enum FilterChoiceEnum {
    PLAGE = 'PLAGE',
	EXACTE = 'EXACTE'
}
