import {Injectable} from '@angular/core';
import {Workbook} from 'exceljs/dist/exceljs.min.js';
import {saveAs} from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ExportUtilService {

  private workbook = new Workbook();
  private worksheet = null;
  private titleRow = null;
  private headerRow = null;

  constructor() {}

  setNameSheet(name: string) {
    this.worksheet = this.workbook.addWorksheet(name)
  }

  setTitle(title: string) {
    this.titleRow.value = title
  }

  setFontTitleSize(size: number, isBold: boolean) {
    this.titleRow.font = {
      family: 4,
      size: size,
      bold: isBold,
    }
  }

  setCellTitle(cell: string) {
    this.titleRow = this.worksheet.getCell(cell);
  }

  setHeadersToExport(headerformat: any) {
    this.headerRow = this.worksheet.addRow(headerformat);
  }

  setFontHeaderSize(size: number, isBold: boolean) {
    this.headerRow.eachCell((cell) => {
      cell.font = {
        size: size,
        bold: isBold
      }
    })
  }

  setRowData(jsonObject: any) {
    this.worksheet.addRow(Object.values(jsonObject))
  }

  addBorderStyle() {
    this.worksheet.columns.forEach(column => {
      column.border = {
        top: {
          style: "thick"
        },
        left: {
          style: "thick"
        },
        bottom: {
          style: "thick"
        },
        right: {
          style: "thick"
        }
      }
    });
    this.worksheet.eachRow((row) => {
      if (row.number === 1) {
        row.border = {}
      }
    });
  }


  SetWidthBeetwenColumns(size: number) {
    this.worksheet.columns.forEach((col) => {
      col.alignment = {
        vertical: 'middle',
        horizontal: 'left'
      }
      const lengths = col.values.map(v => v.toString().length);
      const maxLength = Math.max(...lengths.filter(v => typeof v === 'number')) + 5;
      col.width = maxLength;
    })
  }

  ExportData(FileName: string) {
    this.workbook.xlsx.writeBuffer().then(excelData => {
      const blob = new Blob([excelData],{
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
      saveAs(blob, FileName + '.xlsx')
    })
  }

  ExportCsv(FileName){
    this.workbook.csv.writeBuffer().then(excelData => {
      const blob = new Blob([
        new Uint8Array([0xEF, 0xBB, 0xBF]), // UTF-8 BOM
        excelData
        ],{
          type: "text/plain;charset=utf-8" 
      })
      saveAs(blob, FileName + '.csv')
    })
  }
}