import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterService } from '../../core/services/filter-service';
import { isNullOrUndefined } from 'util';
import { SelService } from './sel.service';
import { CONF } from '../../core/constants';

@Injectable({
	providedIn: 'root'
})
export class OrgStateService extends FilterService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/cis/organizationState';
	private readonly baseUrlProvider = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/cis/provider';

	constructor(private httpClient: HttpClient, private selService: SelService) {
		super();
	}

	// API #5bis Consultation de la liste des AMC
	public getDistinctOrganizationState(criteria: any, amcDataRaw?: any): Observable<any> {
		const data = new Observable((observer) => {
			this.selService
				.getSelToken()
				.then(() => {
			let url =
				this.baseUrl +
				'/distinct?page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				this.getSortParameters(criteria.sorts);

			if (!isNullOrUndefined(amcDataRaw) && !isNullOrUndefined(amcDataRaw.organizationNumber)) {
				url = url + '&organizationNumber=' + amcDataRaw.organizationNumber;
			}

			this.httpClient.get<any>(url).subscribe(
				(data) => {
					observer.next(data);
					observer.complete();
				},
				(err) => {
					observer.error(err);
				}
			);
			})
			.catch((err) => {
				
			});
		});
		return data;
	}

	// API #5 Consultation des états d’une AMC
	public getOrganizationState(criteria: any): Observable<any> {
		const data = new Observable((observer) => {
			this.selService
				.getSelToken()
				.then(() => {
					let url =
						this.baseUrl +
						'/?organizationNumber=' +
						criteria.organizationNumber +
						'&page=' +
						criteria.page +
						'&size=' +
						criteria.size;

					this.httpClient.get<any>(url).subscribe(
						(data) => {
							observer.next(data);
							observer.complete();
						},
						(err) => {
							observer.error(err);
						}
					);
				})
				.catch((err) => {
					
				});
		});
		return data;
	}

	// API #7 Consultation d'une situation Conventionnelle
	public getConventionalSituation(criteria: any): Observable<any> {
		const data = new Observable((observer) => {
			this.selService
				.getSelToken()
				.then(() => {
					let url =
						this.baseUrl +
						'/conventionalsituation?organizationNumber=' +
						criteria.organizationNumber +
						'&page=' +
						criteria.page +
						'&size=' +
						criteria.size;

					if (!isNullOrUndefined(criteria.providerNumber)) {
						url = url + '&providerNumber=' + criteria.providerNumber;
					}

					this.httpClient.get<any>(url).subscribe(
						(data) => {
							observer.next(data);
							observer.complete();
						},
						(err) => {
							observer.error(err);
						}
					);
				})
				.catch((err) => {
					
				});
		});
		return data;
	}

	// API #8 Relance des demandes de pratique
	createPracticeRequest(criteriat: any) {
		const data = new Observable((observer) => {
			this.selService
				.getSelToken()
				.then(() => {
					let url = this.baseUrl + '/practicerequest';

					this.httpClient.post<any>(url, criteriat).subscribe(
						(data) => {
							observer.next(data);
							observer.complete();
						},
						(err) => {
							observer.error(err);
						}
					);
				})
				.catch((err) => {
					
				});
		});
		return data;
	}

	// API # 6 Modification d'un paramétrage AMC
	updateOrganizationState(id: any, criteriat: any) {
		const data = new Observable((observer) => {
			this.selService
				.getSelToken()
				.then(() => {
					let url = this.baseUrl + '/' + id;

					this.httpClient.put<any>(url, criteriat).subscribe(
						(data) => {
							observer.next(data);
							observer.complete();
						},
						(err) => {
							observer.error(err);
						}
					);
				})
				.catch((err) => {
					
				});
		});
		return data;
	}

	getSortParameters(sorts) {
		if (sorts.length == 1) {
			var sort = sorts[0].property;
			return '&sort=' + sort + '&direction=' + sorts[0].direction;
		} else {
			// sort par défaut
			return '&sort=organizationNumber&direction=ASC';
		}
	}

	// API #10 Consultation du détail d’un PS
	public getPsDetailsByProvider(providerNumber: any): Observable<any> {
		const data = new Observable((observer) => {
			this.selService
				.getSelToken()
				.then(() => {
					let url =
						this.baseUrlProvider +'?providerNumber=' + providerNumber;

					this.httpClient.get<any>(url).subscribe(
						(data) => {
							observer.next(data);
							observer.complete();
						},
						(err) => {
							observer.error(err);
						}
					);
				})
				.catch((err) => {
					
				});
		});
		return data;
	}
}
