import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { EMAIL_REGEXP } from '../core/constants';

@Directive({
    selector: '[carewebEmail]',
    providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => EmailValidatorDirective), multi: true }]
})
export class EmailValidatorDirective implements Validator {
    /**
     * Implémentation de la validation.
     */
    validate(control: AbstractControl): { [key: string]: boolean } {
        const email = control.value;
        if (email && !EMAIL_REGEXP.test(email)) {
            return {
                email: false
            };
        }
        return null;
    }
}
