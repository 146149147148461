import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class RappAccessGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): boolean {
    const currentPsStorage = localStorage.getItem('current_ps');
    
    if (!currentPsStorage) {
      this.router.navigate(['/dashboard']);
      return false;
    }

    const currentPs = JSON.parse(currentPsStorage);
    const canActivate = (currentPs.typeRappro === 'OXLIN' && currentPs.rappComptePs.actif) ||
                        (currentPs.typeRappro === 'EBICS' && currentPs.psEbics.some((e: any) => e.actif));

    if (!canActivate) {
      this.router.navigate(['/dashboard']);
      return false;
    }

    if (this.isEmpty(localStorage.getItem('supervised_ps'))) {
      return canActivate;
    } else {
      const supervisedRappVisible = localStorage.getItem('supervised_rapp_visible');
      if (supervisedRappVisible === null || supervisedRappVisible === undefined) {
        this.router.navigate(['/dashboard']);
        return false;
      }
      const rappVisible = JSON.parse(supervisedRappVisible);
      if (!rappVisible) {
        this.router.navigate(['/dashboard']);
        return false;
      }
      return true;
    }
  }

  private isEmpty(value: string | null): boolean {
    return value === null || value === undefined || value.trim() === '';
  }
}