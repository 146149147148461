import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { UsersService } from '../users.service';
import { User } from '../../../data/userDataRaw';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { LoginService } from '../../login/login.service';
import { OctDataRaw } from '../../../data/octDataRaw';
import { ToastrService } from 'ngx-toastr';
import { OctService } from '../../../core/services/oct.service';
import { InputValidators } from '../../../components/validators/InputValidators';
import { isEmpty , remove } from 'lodash-es';
import { HeaderMenuComponent } from '../../../components/header/header-menu.component';
import { ScrollTopService } from '../../../core/utilities/scroll-top.service';
import { AuthService } from '../../../core/services/auth.service';

@Component({
	providers: [ HeaderMenuComponent ],
	selector: 'careweb-app-fiche-utilisateur-oct',
	templateUrl: './fiche-userOCT.component.html',
	styleUrls: [ './fiche-userOCT.component.scss' ]
})
export class FicheUtilisateurOCTComponent implements AfterViewInit, OnInit {
	initialEmail;
	submitted = false;
	isUserDetailsCollapsed = false;
	currentUser: User;
	civiliteArray: any = [ { value: 1, viewValue: 'M' }, { value: 2, viewValue: 'Mme' }];
	actifArray: any = [ { value: true, viewValue: 'Oui' }, { value: false, viewValue: 'Non' } ];
	SuperviseurArray: any = [ { value: true, viewValue: 'Oui' }, { value: false, viewValue: 'Non' } ];
	typeUserArray = [
		{ value: '1', viewValue: 'Administrateur' },
		{ value: '3', viewValue: 'Hotline' }/* ,
		{ value: '2', viewValue: 'Professionnel de santé' } */
	];
	listOCT;
	userForm: UntypedFormGroup;
	nbOctActif = 0;
	createUserComponent = true;
	octObject = { value: '', viewValue: '', clicked: false };
	isUpdateDisabled = false;
	isAdmiSEL = false;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private octSvc: OctService,
		private userService: UsersService,
		private fb: UntypedFormBuilder,
		private loginSvc: LoginService,
		private toastr: ToastrService,
		private inputValidators: InputValidators,
		private headerMenuComponent: HeaderMenuComponent,
		private scrollTopService: ScrollTopService,
		private authSvc: AuthService
	) {
		this.currentUser = new User();
	}

	ngOnInit() {
		const authUserId = this.authSvc.currentUserValue.idUser;
		this.getOctByIdUser(authUserId);
		this.scrollTopService.setScrollTop();
		this.validForm();
		if (this.authSvc.userAuthorized('ADMIN') && this.typeUserArray.length == 2) {
			this.typeUserArray.push({ value: '4', viewValue: 'Administrateur SEL' });
		}
	}

	ngAfterViewInit(): void {
		this.getUserById();
		if (!this.authSvc.userAuthorized('ADMIN')) {
			if (this.createUserComponent) {
				remove(this.typeUserArray, typeUser => typeUser.value === '1');
			}
		}
	}

	getOctByIdUser(idUser: String): void {
		this.octSvc.getOctByIdUser(idUser).subscribe((data) => {
			if (data) {
				this.listOCT = [];
				data.forEach((oct) => {
					this.octObject.value = oct.code;
					this.octObject.viewValue = oct.nom;
					this.listOCT.push(Object.assign({}, this.octObject));
				});
					this.getUserById();
			}
		},
		err=>{
			if(err.status===400){
				this.router.navigate(['/404'])
			}
		});
	}

	validForm() {
		this.userForm = this.fb.group({
			typeCivility: new UntypedFormControl('', [ Validators.required ]),
			nom: [ '', [ Validators.required, this.inputValidators.inputWithSpaces ] ],
			prenom: new UntypedFormControl(''),
			email: [
				'',
				[
					Validators.required,
					this.inputValidators.inputWithSpaces,
					Validators.pattern(this.inputValidators.emailPattern)
				]
			],
			fonction: new UntypedFormControl(''),
			telephone: new UntypedFormControl(''),
			fax: new UntypedFormControl(''),
			mobile: new UntypedFormControl(''),
			selectActif: new UntypedFormControl(''),
			selectSuperviseur: new UntypedFormControl(''),
			selectTypeUser: new UntypedFormControl(this.getTypeUser(3))
		});
	}

	goToListUsers() {
			this.router.navigate([ '/users' ]);
	}

	getUserById() {
		const idUser = this.activatedRoute.snapshot.params.id;
				
		if (idUser) {
			this.createUserComponent = false;			

			return this.userService.getUserById(idUser).subscribe((res) => {
				res.idUser = idUser;
				this.currentUser = res;
				this.currentUser.telephone = this.formatWithoutSeperater(this.currentUser.telephone);
				this.currentUser.fax = this.formatWithoutSeperater(this.currentUser.fax);
				this.currentUser.mobile = this.formatWithoutSeperater(this.currentUser.mobile);
				this.initialEmail = res.email;
				this.userForm.patchValue({
					typeCivility: this.getTypeCivility(),
					nom: res.nom,
					prenom: res.prenom,
					fonction: res.fonction,
					telephone: res.telephone,
					fax: res.fax,
					mobile: res.mobile,
					email: res.email,
					selectActif: this.actifArray.find((actif) => actif.value === this.currentUser.actif),
					selectSuperviseur: this.SuperviseurArray.find(
						(superviseur) => superviseur.value === this.currentUser.supprime
					),
					selectTypeUser: this.getTypeUser(this.currentUser.idTypeUser)
				});
				if (res.idUser) {
					this.fillListButtonOCT();
				}
				if (!this.authSvc.userAuthorized('ADMIN')) {
					if (this.currentUser.idTypeUser !== 1) {
						remove(this.typeUserArray, typeUser => typeUser.value === '1');
					} else {
						this.isUpdateDisabled = true;
					}
				}
			});
		}
	}

	getTypeCivility() {
		if (this.currentUser.idCivilite === 3) {
			this.currentUser.idCivilite = 2;
		}
	 return	this.civiliteArray.find((civilite) => civilite.value === this.currentUser.idCivilite)
	}

	fillListButtonOCT() {
		if (this.currentUser.octs) {
			this.currentUser.octs.forEach((octFor) => {
				if (this.listOCT) {
					const index = this.listOCT.findIndex((oct) => oct.value === octFor.code);
					if(index!==-1){this.listOCT[index].clicked = true;}					
				}
			});
			if (this.listOCT) {
				this.nbOctActif = this.listOCT.filter((oct) => oct.clicked === true).length;
			}
		}
	}

	getTypeUser(value) {
		return this.typeUserArray.find((type) => type.value == value);
	}

	getTitle() {
	//	this.editOctListntduer(value);
		let message = '';
		if(this.userForm.value.selectTypeUser.value == 4){
			this.isAdmiSEL=true;
		}
		if (this.createUserComponent) {
			message = 'Ajouter un utilisateur';
		} else {
			message = `Détails du compte - ${this.formatCivility(this.currentUser.idCivilite)} ${this.userForm.value.nom
				? this.userForm.value.nom
				: ''}  ${this.userForm.value.prenom ? this.userForm.value.prenom : ''} (${this.formatActif(
				this.currentUser.actif
			)})`;
		}
		return message;
	}

	formatCivility = (value): string => {
		return value === 1 ? 'M' : 'Mme';
	};

	formatActif(value) {
		return value === true ? 'ACTIF' : 'INACTIF';
	}

	formatWithoutSeperater(value) {
		if (value) {
			return value.replace(/\s/g, '').replace(/\./gi, '');
		}
	}

	getColorButton(value) {
		const oct = this.listOCT.find((oct) => oct.value === value);
		if (oct) {
			return oct.clicked === true ? 'btn btn-primary' : 'btn btn-default';
		} else {
			return null;
		}
	}

	octListAccordingUserType(value) {
		if(value.target.value == '2: Object'){
			this.isAdmiSEL =true;
			this.listOCT.forEach((oct) => {
				oct.clicked = true;  });
			this.nbOctActif = this.listOCT.filter((oct) => oct.clicked == true).length;
		}else{
			this.isAdmiSEL =false;
		}
	}

	editOctList(value) {
		const index = this.listOCT.findIndex((oct) => oct.value === value);
		this.listOCT[index].clicked = !this.listOCT[index].clicked;
		this.nbOctActif = this.listOCT.filter((oct) => oct.clicked == true).length;
	}

	onReset() {
		this.submitted = false;
		this.userForm.reset();
		this.listOCT.forEach((oct) => {
			oct.clicked = false;
		});
		this.nbOctActif = 0;

		this.ngOnInit();
		this.ngAfterViewInit();
		if (this.createUserComponent) {
			this.userForm.value.selectTypeUser = this.getTypeUser(3);
		}
	}

	onReinitializePassword() {
		if (!this.currentUser.email) {
			this.toastr.error("Réinitialisation de mot passe : Un problème est survenu, l'adresse mail est invalide");
		} else {
			this.loginSvc.askResetPwd(this.currentUser.email.toString()).subscribe(
				() => {
					this.toastr.success('Réinitialisation de mot passe : Un email vous a été envoyé');
				},
				(error) => {
					this.toastr.error(
						"Réinitialisation de mot passe : Un problème est survenu, l'adresse mail est invalide"
					);
				}
			);
		}
	}

	onSubmit() {
		this.submitted = true;
		if (this.userForm.invalid) {
			return;
		}
		// at least select a oct if the user type is not Admin SEL
		if (this.userForm.value.selectTypeUser !== this.getTypeUser(4) && this.nbOctActif === 0) {
			this.toastr.error('Au moins un OCT doit être sélectionné');
			return;
		}
		// verif exist email in database
		if (this.isEmailChange() === true) {
			this.userService.getUsersByEmail(this.userForm.value.email).subscribe(
				(data) => {
					if (data != null) {
						this.toastr.error('Cet email est déjà utilisé');
					} else {
						this.insertValidForm();
					}
				},
				(err) => {}
			);
		} else {
			this.insertValidForm();
		}
	}

	insertValidForm() {
		this.currentUser.idCivilite = this.userForm.value.typeCivility.value;
		this.currentUser.nom = this.userForm.value.nom;
		this.currentUser.prenom = this.userForm.value.prenom
			? (this.userForm.value.prenom as String).trim()
			: this.userForm.value.prenom;
		this.currentUser.fonction = this.userForm.value.fonction
			? (this.userForm.value.fonction as String).trim()
			: this.userForm.value.fonction;
		this.currentUser.telephone = this.userForm.value.telephone;
		this.currentUser.email = this.userForm.value.email;
		this.currentUser.mobile = this.userForm.value.mobile;
		this.currentUser.fax = this.userForm.value.fax;
		this.currentUser.actif = this.userForm.value.selectActif.value;
		this.currentUser.idTypeUser = this.userForm.value.selectTypeUser.value;
		this.currentUser.supprime = this.userForm.value.selectSuperviseur.value;
		// List Octs selected
		this.currentUser.octs = [];
		this.listOCT.filter((oct) => oct.clicked === true).map((oct) => {
			this.currentUser.octs.push(new OctDataRaw().withCode(oct.value));
		});

		if (this.createUserComponent) {
			this.createUser();
		} else {
			this.updateUser();
		}
	}

	createUser() {
		this.userService.saveUser(this.currentUser).subscribe((data) => {});
		// Show Toastr
		this.toastr.success('Le nouvel utilisateur a été ajouté avec succès');
		this.toastr.success('Un email de création de mot de passe a été envoyé');
		
		setTimeout(() => {
			this.router.navigate([ '/users' ]);
		}, 3000); // 3s
	}

	updateUser() {
		this.userService.update(this.currentUser.idUser, this.currentUser).subscribe((data) => {
			this.toastr.success("L'utilisateur a été mis à jour avec succès");
		});

		// Refersh if update the octs of the current user

		const userConnected = JSON.parse(localStorage.getItem('careweb_user'));
		if (!isEmpty(userConnected)) {
			if (userConnected.idUser === this.currentUser.idUser) {
				localStorage.removeItem('octCurrent');
				setTimeout(() => {
					this.headerMenuComponent.ngOnInit();
					this.router.navigate([ '/dashboard' ]);
				}, 3000); // 3s
			} else {
				setTimeout(() => {
					this.router.navigate([ '/users' ]);
				}, 3000); // 3s
			}
		} else {
			setTimeout(() => {
				this.router.navigate([ '/users' ]);
			}, 3000); // 3s
		}
	}

	get formFields() {
		return this.userForm.controls;
	}

	isEmailChange(): boolean {
		return this.userForm.value.email !== this.initialEmail;
	}

	unblockAccount() {
		// Vérifier si le compte est désactivé
		if (this.currentUser.actif) {
			// Appeler l'API pour débloquer le compte
			this.userService.unblockAccount(this.currentUser).subscribe(
				() => {
					this.currentUser.blocked = false;
				}
			);
		}
	}
}
