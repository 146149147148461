export class RspFilterRaw {
	idPs: number;
	numeroPs: String;
	numLot: String;
	numeroFacture: String;
	numFacture: String;
	numLotFrom: String;
	numLotTo: String;
	dateReceptionLotFrom: Date;
	dateReceptionLotTo: Date;
	dateReceptionLotExacte: Date;
	codeOrganisme: String;
	libelleOrganisme: String;
	libelleVirement1: string;
	libelleVirement2: string;
	dateReceptionRspFrom: Date;
	dateReceptionRspTo: Date;
	dateReceptionRspExacte: Date;
	statutRsp: String;
	rapprochement: Boolean;
	nomTitulaire: string;
	raisonSocialEtablissement: string;
	dateDerniereTransmission: string;
	libelleRejet: string;
	numeroPScomboBox: String;
	rapproBancaire: boolean;
	avecVirement: Boolean;

	constructor(data = null) {
		Object.assign(this, data);
	}
}
