import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ArlDataRaw } from '../../../../data/arlDataRaw';
import { isNullOrUndefined } from 'util';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'careweb-arl-details-modal',
    templateUrl: './arl-details-modal.component.html'
})
export class ArlDetailsModalComponent implements OnInit {
    @Input() arl: ArlDataRaw;
	datePipe: DatePipe;

    constructor(private modalRef: BsModalRef) {}

    ngOnInit(): void {
		// Initiate date pipe format
		this.datePipe = new DatePipe('fr-FR');
	}

    cancel() {
        this.modalRef.hide();
    }

    closeModal() {
        this.modalRef.hide();
    }

    formatSigneArl = (params): string => {
        return !isNullOrUndefined(params) && params ? 'Positif' : 'Négatif';
    };
    formatCurrency = (params): string => {
        if (params) {
            return params / 100 + ' €';
        } else {
            return '0.00 €';
        }
	};
	formatDate = (params): string => {
		return this.datePipe.transform(params, 'short');
	};
}
