<div class="full-careweb-container">
  <div id="filter" class="filter-container" carewebFilterMinHeight>
    <div class="filter-container-inner" [ngClass]="{ 'show-content': showFilter, 'init-hidden': initHidden }">
      <careweb-accordion-header-buttons (closeFilters)="toggleFilterDisplay()" (resetFilters)="resetAllFilter()"
        (launchSearch)="launchSearch()"></careweb-accordion-header-buttons>
      <div class="filter-content">
        <mat-accordion displayMode="flat" class="accordion-filter">
          <!-- Raison sociale -->
          <careweb-accordion-group id="raison_sociale" title="Raison Sociale">
            <input type="text" id="input_raison_sociale" [(ngModel)]="filters.raisonSociale" (keydown.enter)="onKeyDown($event)"
             onKeyPress="if(this.value.length==50) return false;" onpaste="if(this.value.length==50) return false;"/>
          </careweb-accordion-group>
          <!-- Téléphone -->
          <careweb-accordion-group id="telephone" title="Téléphone">
            <input type="text" id="input_telephone" [(ngModel)]="filters.telephone" (keydown.enter)="onKeyDown($event)"
            onKeyPress="if(this.value.length==16) return false;" onpaste="this.value=this.value.replace(/[^\d]/,'').replace(/\s/g,'').replace( /\./gi,''); if(this.value.length==16) return false;" pattern="[0-9]*" min="0"
            oninput="this.value=this.value.replace(/[^\d]/,'').replace(/\s/g,'').replace( /\./gi,'');"/>
          </careweb-accordion-group>
          <!-- Code postal -->
          <careweb-accordion-group id="code_postale" title="Code Postal">
            <input type="text" id="input_code_postale" [(ngModel)]="filters.codePostal" (keydown.enter)="onKeyDown($event)"
            onKeyPress="if(this.value.length==5) return false;" onpaste="if(this.value.length==5) return false;" pattern="^[0-9]" min="0"
            oninput="this.value=this.value.replace(/[^\d]/,'');"/>
          </careweb-accordion-group>
          <!-- Ville -->
          <careweb-accordion-group id="ville" title="Ville">
            <input type="text" id="input_ville" [(ngModel)]="filters.ville" (keydown.enter)="onKeyDown($event)"
            onKeyPress="if(this.value.length==50) return false;" onpaste="if(this.value.length==50) return false;"/>
          </careweb-accordion-group>
        </mat-accordion>
      </div>
    </div>
  </div>
  <div class="grid-container" id="grid">
    <h2 class="title-border mt-3">Liste des éditeurs</h2>
    <div>
      <section>
          <div class="row grid-top-btn">
              <div class="col-md-4 filter">
                <button type="button" name="btn_toggle_filter" class="btn btn-primary small left-open" [hidden]="showFilter"
                  (click)="toggleFilterDisplay()">
                  <i class="fa fa-sliders"></i>
                  Filtrer
                </button>
                <careweb-pluralize
                class="filter-count"
                [messageKey]="'Résultat(s) trouvé(s)'"
                [count]="gridHelper.totalRowCount"
            ></careweb-pluralize>
              </div>
              <div class="col-md-8 conf-col">
                <div class="careweb-toolbar">
                  <div [tooltip]="gridHelper.isAvailableData() ? 'Exporter les résultats (.xlsx)' : 'Aucune ligne à exporter, veuillez affiner votre recherche'"
                  placement="top" container="body">
                    <span class="btn btn-action small" [class.disabled]="!gridHelper.isAvailableData()"
                           name="btn_export_xlsx" (click)="exporter()">
                    <i class="fa fa-file-excel-o"></i>
                    </span>
                  </div>
                  <careweb-pagination-size id="dv_editeurs_paging" [pageSizes]="[10, 20, 30, 50, 100]" [(ngModel)]="gridDataRaw.size"
                    (paginationSizeChange)="onPaginationSizeChange($event)">
                  </careweb-pagination-size>
                  <span class="btn btn-action small" tooltip="Réinitialiser l'affichage" placement="top" container="body" name="spn_reset_grid"
                    (click)="resetGridState()">
                    <i class="fa fa-refresh"></i>
                  </span>
                </div>
              </div>
            </div>
          <app-alert [export]="export" [exportState]="exportState" (close)="closeAlert()"></app-alert>
            <div class="row grid-top-filter">
              <div class="col-md-12">
                <careweb-filter-label *ngFor="let filter of filterList" (closeFilter)="removeFilter(filter.id)">
                  <span *ngIf="filter">{{ filter.label}}: {{filter.value }}</span>
                </careweb-filter-label>
              </div>
             </div>
             <div class="row highlight-row">
              <div class="col-md-12 relative">
                <ag-grid-angular style="width: 100%" class="ag-theme-balham ag-theme-no-rows" [gridOptions]="gridHelper.gridOptions">
                </ag-grid-angular>
                <careweb-pagination [gridApi]="gridHelper.gridApi" ></careweb-pagination>
              </div>
            </div>
      </section>
    </div>
  </div>
</div>
