import { Pipe, PipeTransform } from '@angular/core';

/**
 * formatte un montant en euro
 */

@Pipe({
	name: 'currency'
})
export class CurrencyPipe implements PipeTransform {
	constructor() {}

	transform(data, value: String) {
		data ? (value = data + ' €') : '';
		return value;
	}
}
