import { Component, AfterViewInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'rapp-reconciliation-rate',
	template: `
		<progressbar class="progress center-content" [value]="stacked" [max]="100"></progressbar>
    `,
	styles: [`
	  .center-content {
		display: flex;
		justify-content: center;
		align-items: center;
		height:25px;
		margin-top: 10px;
	  }
	`]
})
export class RappReconciliationRatecomponent implements ICellRendererAngularComp, AfterViewInit {
	iconClass: string;
	tooltip: string;
	private params: any;

	constructor() {
	 }

	refresh(params: any): boolean {
		return false;
	}

	agInit(params: any): void {
		this.params = params;
	}

	ngAfterViewInit() {
		const cellParams = this.params;
		this.stacked = [];
		if (cellParams.data) {
			let rapp = (cellParams.data.totalRspRapp / cellParams.data.totalRsp) * 100;
			let notRapp = ((cellParams.data.totalRsp - cellParams.data.totalRspRapp) / cellParams.data.totalRsp) * 100;
			let label = (Math.round(rapp * 100) / 100) + '% (' + (cellParams.data.totalRspRapp + '/' + cellParams.data.totalRsp+')');
			this.stacked.push({
				value: Math.round(rapp * 100) / 100,
				type: 'success',
				label: rapp >= notRapp ? label : ''
			});
			this.stacked.push({
				value: Math.round(notRapp * 100) / 100,
				type: 'danger',
				label: notRapp > rapp ? label : ''
			});
		}
	}

	type?: string;
	stacked = [];
	randomStacked(): void {
		this.stacked.push({
			value: 80,
			type: 'success',
			label: '80 %'
		});
		this.stacked.push({
			value: 20,
			type: 'danger',
			label: '20 %'
		});
	}
}
