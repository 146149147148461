import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONF } from '../../../core/constants';
import { EditeurDataRaw } from '../../../data/editeurDataRaw';
import { FilterService } from '../../../core/services/filter-service';
import { isNullOrUndefined } from 'util';
import { map } from 'rxjs/operators';
import { CustomSortDataRow } from '../../../data/customSortDatRaw';

@Injectable({
	providedIn: 'root'
})
export class EditeurService extends FilterService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/editeurs';

	constructor(private httpClient: HttpClient) {
		super();
	}

	getEditeurById(criteria: any): Observable<any> {
		return this.httpClient.get<any>(this.baseUrl + '/' + criteria.idEditeur);
	}

	getEditeursList(criteria: any): Observable<any> {
		return this.httpClient.get<any>(
			this.baseUrl +
				'/list?page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				this.getSortParameters(criteria.sorts)
		);
	}

	getEditeursFilteredList(criteria: any, editeurDataRaw: EditeurDataRaw): Observable<any> {
		return this.httpClient.post<any>(
			this.baseUrl +
				'/search?page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				this.getSortParameters(criteria.sorts),
			editeurDataRaw
		);
	}

	getSortParameters(sorts) {
		if (!isNullOrUndefined(sorts) && sorts.length === 1) {
			const sort = sorts[0].property;
			return '&sort=' + sort + '&direction=' + sorts[0].direction;
		} else {
			// sort par défaut
			return '&sort=raisonSociale&direction=ASC';
		}
	}

	saveEditeur(editeur): Observable<any> {
		return this.httpClient.put(this.baseUrl, editeur);
	}

	getEditeurByRaisonSocial(raisonSoc: any): Observable<any> {
		return this.httpClient.get<any>(this.baseUrl + '/raison-soc/' + raisonSoc);
	}

	exportEditeurs(criteria: any, editeurDataRaw: EditeurDataRaw, fields: string[]): Observable<any> {
		const sorts: CustomSortDataRow[] = criteria.sorts;
		return this.httpClient.post<HttpResponse<Blob>>(
			this.baseUrl + '/export?' + this.getSortParameters(sorts),
			{
				filters: editeurDataRaw,
				fields: fields
			},
			{
				observe: 'response' as 'body',
				responseType: 'blob' as 'json'
			}
		).pipe(
			map((response: HttpResponse<Blob>): any => {
				return this.getFileInfosFromResponse(response, "Liste_Editeurs.xlsx");
			})
		);
	}

	exportEditeursByEmail(criteria: any,  editeurDataRaw: EditeurDataRaw, fields: string[]): Observable<any>{
		const sorts: CustomSortDataRow = criteria.sorts;
		return this.httpClient.post<HttpResponse<Blob>>(
			this.baseUrl + '/export-by-email?' + this.getSortParameters(sorts),
			{
				filters: editeurDataRaw,
				fields: fields
			},
			{
				observe: 'response' as 'body',
				responseType: 'blob' as 'json'
			}
		);
	}

	getFileInfosFromResponse(response: HttpResponse<Blob>,fileName: string) {
		return {
			file: new Blob([ response.body ], { type: 'application/vnd.ms-excel' }),
			fileName: 'Liste-Editeurs.xlsx'
		};
	}
}
