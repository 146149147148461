import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterService } from '../../core/services/filter-service';
import { SelService } from './sel.service';
import { CONF } from '../../core/constants';
import { isNullOrUndefined } from 'util';

@Injectable({
	providedIn: 'root'
})
export class ServiceDirectoryService extends FilterService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/cis';

	constructor(private httpClient: HttpClient, private selService: SelService) {
		super();
	}

	public getServicedirectory(criteria: any): Observable<any> {
		const data = new Observable((observer) => {
			this.selService
				.getSelToken()
				.then(() => {
					let url =
						this.baseUrl +
						'/serviceDirectory?organizationNumber=' +
						criteria.organizationNumber +
						'&page=' +
						criteria.page +
						'&size=' +
						criteria.size;

					if (!isNullOrUndefined(criteria.service)) {
						url = url + '&service=' + criteria.service;
					}

					this.httpClient.get<any>(url).subscribe((data) => {
						observer.next(data);
						observer.complete();
					});
				})
				.catch((err) => {
					
				});
		});
		return data;
	}

	// API #2 Création d'un service dans le méta-annuaire
	createServiceDirectory(paramSelObject: any) {
		const data = new Observable((observer) => {
			this.selService
				.getSelToken()
				.then(() => {
					let url = this.baseUrl + '/serviceDirectory/';

					this.httpClient.post<any>(url, paramSelObject).subscribe((data) => {
						observer.next(data);
						observer.complete();
					});
				})
				.catch((err) => {
					
				});
		});
		return data;
	}

	// API #3 Modification d'un service dans le méta-annuaire
	updateServiceDirectory(id: any, paramSelObject: any) {
		const data = new Observable((observer) => {
			this.selService
				.getSelToken()
				.then(() => {
					let url = this.baseUrl + '/serviceDirectory/' + id;

					this.httpClient.put<any>(url, paramSelObject).subscribe((data) => {
						observer.next(data);
						observer.complete();
					});
				})
				.catch((err) => {
					
				});
		});
		return data;
	}

	// API #4 Suppression d'un service de service dans le méta-annuaire
	deleteServiceDirectory(servicedirectoryId: any) {
		const data = new Observable((observer) => {
			this.selService
				.getSelToken()
				.then(() => {
					let url = this.baseUrl + '/serviceDirectory/' + servicedirectoryId;

					this.httpClient.delete<any>(url).subscribe((data) => {
						observer.next(data);
						observer.complete();
					});
				})
				.catch((err) => {
					
				});
		});
		return data;
	}
	getSortParameters(sorts) {
		if (sorts.length == 1) {
			var sort = sorts[0].property;
			return '&sort=' + sort + '&direction=' + sorts[0].direction;
		} else {
			// sort par défaut
			return '&sort=organizationNumber&direction=ASC';
		}
	}
}
