import { PsDataRaw } from './psDataRaw';

export class ArlDataRaw {
	ps: PsDataRaw;
	numeroPs: String;
	numLot: string;
	dateReceptionPartitionFrom: string;
	dateReceptionPartitionTo: string;
	dateReceptionPartitionExacte: string;
	signeArl: Boolean;
	rapprochement: Boolean;
	nbFactures: number;
	montantFacture: number;
	dateReception: Date;
	libelleRejet: string;
	lotSecurise: Boolean;
	isArpReceived: Boolean;
	idOct: number;
	libelleOrganisme: string;
	nomPs: string;
	listNumeroAdeli: String[];
	fields: string[]
}
