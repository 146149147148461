import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Injectable()
export class MigrationOctGuard implements CanActivate {
	allowedUsers: String[] =["tristan.girard@cegedim.com",
	"adrien.tichoux@cegedim.com"
	, "anis.dhouieb@cegedim.com"
	, "nada.mehamdi@cegedim.com", "cedric.lachaise@cegedim.fr", "meryem.elyoussi@cegedim.com"];
	constructor(private authSvc: AuthService) { }

	canActivate() {
		return this.hasAccess();
	}

	hasAccess() {
		const currentUser = this.authSvc.currentUserValue
		return this.allowedUsers.includes(currentUser.email);
	}
}
