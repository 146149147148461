<div class="x-large-careweb-container">
    <form>
        <section class="form-content">
            <!-- Titre H2 -->
            <div class="row form-title">
                <h2 class="col-md-6">
                    Détails du RSP du lot n°{{rsp.numLot}}
                </h2>
                <div class="col-md-6 text-title">

                    <button class="btn btn-default me-2" type="button" (click)="goToDetailLot()"
                        [hidden]="!showButtonDetailFse"> Détails du lot</button>
                    <button class="btn btn-default " type="button" (click)="goToDetailFSE()"
                        [hidden]="!showButtonDetailFse"> Détails de FSE </button>
                    <button class="btn btn-default btn-lg ms-4" type="button" (click)="goToRSP()">Retour</button>

                </div>
            </div>
            <div class="form-block">
                <!-- Titre -->
                <div class="row">
                    <div class="col">
                        <h3 class="title-border">
                            Détails du RSP
                            <!-- <a href="#" class="btn-popin"><i class="fa fa-info-circle"></i></a> -->
                        </h3>
                    </div>
                </div>
                <!-- DETAILS -->
                <fieldset class="container-fluid">
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Numéro de PS</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" value="{{rsp.numeroAdeli}}" name="numeroAdeli"
                                readonly />
                        </div>

                        <div class="col-sm-2 col-form-label">Nom du titulaire</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" value="{{rsp.nomPS}}" name="nomPS" readonly /></div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Etablissement</div>
                        <div class="col-sm-10 form-group">
                            <input type="text" class="form-control" value="{{rsp.raisonSocialEtablissement}}"
                                readonly /></div>
                        <!-- value="{{currentPs.defaultEtablissement.raisonSociale}}" -->
                    </div>

                    <div class="row">
                        <div class="col-sm-2 col-form-label">Dernière transmission</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" readonly
                                value="{{rsp.dateDerniereTransmission | date:'shortDate'}}" />
                        </div>
                        <div class="col-sm-2 col-form-label">Statut</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" readonly value="{{rsp.designationStatusRsp }}"
                                name="Statut" /></div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Date d'émission RSP</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" readonly
                                value="{{rsp.dateDerniereTransmission | date:'shortDate'}}" />
                        </div>
                        <div class="col-sm-2 col-form-label">N° FSE</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" readonly value="{{rsp.numFacture}}"
                                name="numFacture" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-2 col-form-label">Organisme émetteur</div>
                        <div class="col-sm-10 form-group">
                            <input type="text" class="form-control" value="{{rsp.libelleOrganisme}}"
                                name="libelleOrganisme" readonly />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-2 col-form-label">Motif ou signalement</div>
                        <div class="col-sm-10 form-group">
                            <input type="text" class="form-control" value="{{rsp.libelleRejet}}" name="libelleRejet"
                                readonly /></div>
                    </div>

                    <div class="row">
                        <div class="col-sm-2 col-form-label">Montant demandé AMO</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" value="{{rsp.montantFactureRo | customCurrency}}"
                                name="montantFactureRo" readonly />
                        </div>
                        <div class="col-sm-2 col-form-label">Montant payé AMO</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" value="{{formatWithSigne(rsp.montantRo,rsp.signe)}}"
                                name="montantRo" readonly />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Montant demandé AMC</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" value="{{rsp.montantFactureRc | customCurrency}}"
                                name="montantFactureRc" readonly />
                        </div>
                        <div class="col-sm-2 col-form-label">Montant payé AMC</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" value="{{formatWithSigne(rsp.montantRc,rsp.signe)}}"
                                name="montantRc" readonly />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Montant total demandé AMO+AMC</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" value="{{formatCurrency(montantTotalDemande)}}"
                                name="montantTotalDemande" readonly />
                        </div>
                        <div class="col-sm-2 col-form-label">Montant total payé AMO+AMC</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" value="{{formatCurrency(montantTotalPaye)}}"
                                name="montantTotalFacture" readonly />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Libellé de virement 1</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" value="{{rsp.libelleVirement1}}" readonly />
                        </div>
                        <div class="col-sm-2 col-form-label">Libellé de virement 2</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" value="{{rsp.libelleVirement2}}" readonly />
                        </div>
                    </div>
                </fieldset>
            </div>
        </section>
    </form>
</div>