<div class="modal modal-show fade show" id="modalDetailsComptes" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel">

    <div class="modal-dialog modal-xxl" role="document">
        <div class="modal-content">
            <div class="inmodal">
                <div class="modal-header pb-1">
                    <h3 class="modal-title">Détails des comptes configurés</h3>
                    <button class="close" name="btn_display_filter" data-dismiss="modal" type="button" title="Fermer"
                        (click)="closeModal()"><span aria-hidden="true">×</span></button>
                </div>
                <div class="modal-body pb-0">
                    <div class="form-block">
                        <fieldset class="container-fluid">
                            <div class="row grid-top-btn">

                                <div class="col-md-4 filter">
                                    <careweb-pluralize class="filter-count" [messageKey]="'Résultat(s) trouvé(s)'"
                                        [count]="gridHelperComptesAssocies.totalRowCount"></careweb-pluralize>
                                </div>
                                <div class="col-md-8 conf-col">
                                    <div class="careweb-toolbar">

                                        <careweb-pagination-size id="dv_rule_paging" [pageSizes]="[10, 20,30, 50, 100]"
                                            [(ngModel)]="gridDataRawComptesAssocies.size"
                                            (paginationSizeChange)="onComptesPaginationSizeChange($event)">
                                        </careweb-pagination-size>
                                        <span class="btn btn-action small" tooltip="Réinitialiser l'affichage"
                                            placement="top" container="body" name="spn_reset_grid"
                                            (click)="resetComptesGridState()">
                                            <i class="fa fa-refresh"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 text-title noPadding ">
                                    <ag-grid-angular style="width: 100%;" class="ag-theme-balham"
                                        [gridOptions]="gridHelperComptesAssocies.gridOptions">
                                    </ag-grid-angular>
                                    <careweb-pagination [gridApi]="gridHelperComptesAssocies.gridApi">
                                    </careweb-pagination>
                                </div>
                            </div>

                            <div class="button-bar control">
                                <button type="button" class="btn btn-primary" (click)="closeModal()"> Fermer </button>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div class="modal-footer">
                </div>
            </div>
        </div>
    </div>
</div>