import { Component, AfterViewInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'careweb-fiche-type0-rattacher',
	template: `
        <i
			[ngClass]="iconClass"
            container="body"
			[tooltip]="tooltip"
            containerClass="tooltip-grid"
		></i>
    `
})
export class FicheTypeZeroRattacherComponent implements ICellRendererAngularComp, AfterViewInit {
	iconClass: string;
	tooltip: string;
	private params: any;

	constructor() {}

	agInit(params: any): void {
		this.params = params;
	}

	ngAfterViewInit() {
		const cellParams = this.params;
		if (cellParams.data) {
			if (cellParams.colDef.field && cellParams.colDef.field === 'options') {
				this.iconClass = cellParams.iconClass;
				this.tooltip = 'Rattacher le regrouppement';
			} else if (cellParams.colDef.field && cellParams.colDef.field === 'brcsi' && cellParams.data) {
				cellParams.data.brcsi
					? ((this.iconClass = 'fa fa-check'), (this.tooltip = 'Regroupement RC interdit'))
					: ((this.iconClass = 'fa fa-close'), (this.tooltip = 'Regroupement RC valide'));
			}
		}
	}

	refresh(): boolean {
		return false;
	}
}
