import { RapprochementComptePsService } from './../../rapprochement/rapprochement.service';
import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'careweb-virement-etudier-modal',
	templateUrl: './virement-etudier-modal.component.html'
})
export class VirementEtudierModalComponent implements OnInit {
	@Input() numeroAdeli: String;
	@Input() idOct: number;
	@Input() startDate: Date;
	@Input() endDate: Date;

	virementsAetudier: string[];

	constructor(private modalRef: BsModalRef, private rapprochementComptePsService: RapprochementComptePsService) {}

	ngOnInit(): void {
		this.rapprochementComptePsService.getVrtEtudierListByCriteriat
		({
			numeroAdeli: this.numeroAdeli,
			startDate: this.startDate,
			endDate: this.endDate
		})
		.toPromise().then((data) => {
			data.forEach(item => {
				if(item[3] === null) {
					item[3] = '';
				}
			});
			this.virementsAetudier = data;
		});
	}

	cancel() {
		this.modalRef.hide();
	}

	closeModal() {
		this.modalRef.hide();
	}

}
