import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'careweb-add-rule-column',
  template: `
  <i [ngClass]="'fa fa-plus'" container="body"  [tooltip]="'Ajouter la règle'"
    containerClass="tooltip-grid"></i>
    `
})
export class AddRuleColumnComponent implements ICellRendererAngularComp {

  params;

  constructor() { }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(): boolean {
		return false;
	}

}
