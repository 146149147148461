<div class="modal-header pb-1">
    <h3 class="modal-title">Déconnexion</h3>
    <button class="close" name="btn_display_filter" data-dismiss="modal" type="button" title="Fermer"
        (click)="closeModal()"><span aria-hidden="true">×</span></button>
</div>
<div class="modal-body pb-0">
    <form #logoutForm="ngForm" class="form-block">
        <fieldset class="container-fluid">
            <div class="row">
                <div class="col-sm-12 col-form-label form-group">
                    <span>Vous allez être déconnecté(e)</span> 
                </div>
            </div>
        </fieldset>
       <!--  <div class="row"> -->
            <div class="button-bar control">
                <button type="button" (click)="cancel()" class="btn btn-default "> Annuler
                </button>
                <button type="button" (click)="logoutUser()" class="btn btn-primary">
                    <span>Valider</span>
                </button>
            </div>
      <!--   </div> -->
    </form>
</div>
<div class="modal-footer">

</div>