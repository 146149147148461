export class ARLFilterRaw {
	numeroPs: String;
	numLot: string;
	dateReceptionPartitionFrom: Date;
	dateReceptionPartitionTo: Date;
	dateReceptionPartitionExacte: Date;
    rapprochement: Boolean;
    statut: Boolean;
    securise: Boolean;
    arp: Boolean;
    lotSecurise: boolean;
    isArpReceived: boolean;
    numeroPScomboBox: String;
    idOct: Number;
    listNumeroAdeli: String[];
}
