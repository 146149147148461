<div class="full-careweb-container">
    <div id="filter" class="filter-container" carewebFilterMinHeight>
        <div class="filter-container-inner" [ngClass]="{ 'show-content': showFilter, 'init-hidden': initHidden }">
            <careweb-accordion-header-buttons (closeFilters)="toggleFilterDisplay()" (resetFilters)="resetAllFilter()"
                                              (launchSearch)="launchSearch()"></careweb-accordion-header-buttons>
            <div class="filter-content">
                <mat-accordion displayMode="flat" class="accordion-filter">
                    <careweb-accordion-group id="datReponse" title="Date réponse" #dateAccordion>
                        <careweb-date-from-to-filter labelFrom="entre le" [(modelFrom)]="filters.dateDemarrageFrom"
                                                     [(modelTo)]="filters.dateDemarrageTo" [(modelExacte)]="filters.dateDemarrageExacte"
                                                     [maxDate]="maxDate" [isExactePlageChoice]="true" id="dateReponse"
                                                     (filterChange)="this.onChoiceChange($event, 'dateDemarrage')"
                                                     (dateRangeValidityChange) = "checkDateRangeValidity($event)"  #dateReponse (onEnter) = "onKeyDown($event)">
                        </careweb-date-from-to-filter>
                    </careweb-accordion-group>
                    <careweb-accordion-group id="hostId" title="HOST ID">
                        <input type="text" id="input_hostId" [(ngModel)]="filters.hostId"
                            maxlength="150" (keydown.enter)="onKeyDown($event)" />
                    </careweb-accordion-group>
                    <!-- Ebics Statut -->
                    <careweb-accordion-group id="ebicsStatut" title="Statut">
                        <ng-select name="ebicsStatut" id="ebicsStatut" placeholder="Statut" [searchable]="false"
                            [(ngModel)]="filters.ebicsStatut" appendTo=".filter-container"
                            (keydown.enter)="onKeyDown($event)">
                            <ng-option [value]="'SUCCESS'">SUCCESS</ng-option>
                            <ng-option [value]="'ERROR'">ERROR</ng-option>
                            <ng-option [value]="'AMBIGUOUS'">AMBIGUOUS</ng-option>
                            <ng-option [value]="'NEW'">NEW</ng-option>
                            <ng-option [value]="'PROGRESS'">PROGRESS</ng-option>
                            <ng-option [value]="'NO_DATA_AVAILABLE'">NO_DATA_AVAILABLE</ng-option>
                        </ng-select>
                    </careweb-accordion-group>
                    <careweb-accordion-group id="erreurCategorie" title="Catégorie d'erreur">
                        <input type="text" id="input_erreurCategorie" [(ngModel)]="filters.erreurCategorie"
                            maxlength="150" (keydown.enter)="onKeyDown($event)" />
                    </careweb-accordion-group>
                    <careweb-accordion-group id="erreur" title="Erreur">
                        <input type="text" id="input_erreur" [(ngModel)]="filters.erreur"
                            maxlength="150" (keydown.enter)="onKeyDown($event)" />
                    </careweb-accordion-group>
                    
                </mat-accordion>
            </div>
        </div>

    </div>
    <div class="grid-container" id="grid">
        <div>
            <div class="row mt-3">
                <div class="col-lg-9">
                    <h2 class="title-border">Suivi EBICS
                    </h2>
                </div>
            </div>
            <section>
                <div class="row grid-top-btn">
                    <div class="col-md-4 filter">
                        <button type="button" name="btn_toggle_filter" class="btn btn-primary small left-open"
                                [hidden]="showFilter" (click)="toggleFilterDisplay()">
                            <i class="fa fa-sliders"></i>
                            Filtrer
                        </button>
                        <careweb-pluralize  class="filter-count" [messageKey]="'Résultat(s) trouvé(s)'"
                                            [count]="rowCount"></careweb-pluralize>
                    </div>
                    <div class="col-md-8 conf-col">
                        <div class="careweb-toolbar">
                            <careweb-pagination-size id="dv_rule_paging" [pageSizes]="[10, 20,30, 50, 100]"
                                                     [(ngModel)]="gridDataRaw.size" (paginationSizeChange)="onPaginationSizeChange($event)">
                            </careweb-pagination-size>
                            <span class="btn btn-action small" tooltip="Réinitialiser l'affichage" placement="top" container="body"
                                  name="spn_reset_grid" (click)="resetGridState()">
                           <i class="fa fa-refresh"></i>
                          </span>
                        </div>
                    </div>
                </div>
                <div class="row grid-top-filter">
                    <div class="col-md-12">
                        <careweb-filter-label *ngFor="let filter of filterList" (closeFilter)="removeFilter(filter.id)">
                            <span *ngIf="filter">{{ filter.label}}: {{filter.value }}</span>
                        </careweb-filter-label>
                    </div>
                </div>
                <div class="row highlight-row">
                    <div class="col-md-12 relative">
                        <ag-grid-angular style="width: 100%" class="ag-theme-balham" [gridOptions]="suiviEbicsGridHelper.gridOptions">
                        </ag-grid-angular>
                        <careweb-pagination [gridApi]="suiviEbicsGridHelper.gridApi"></careweb-pagination>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>
<div *ngIf="showDetailModal">
    <div class="modal modal-show fade show" id="modalDeleteType" tabindex="-1">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <div class="inmodal">
                    <div class="modal-header pb-1 d-flex">
                        <h3>ActiveMQTextMessage</h3>
                        <a class="ms-auto"><i class="fa fa-close" (click)="cancel()"></i></a>
                    </div>
                    <div class="modal-body pb-0 scrollable-modal-body">
                        <div class="form-block">
                            <fieldset class="container-fluid">
                                <div class="row">
                                    <ng-container *ngFor="let key of objectKeys(jsonMessage)">
                                        <div>
                                            <strong>{{key}}:</strong>
                                            <ng-container *ngIf="!isObject(jsonMessage[key])">
                                                {{jsonMessage[key]}}
                                            </ng-container>
                                            <ng-container *ngIf="isObject(jsonMessage[key])">
                                                <div style="margin-left: 20px;">
                                                    <ng-container *ngFor="let subKey of objectKeys(jsonMessage[key])">
                                                        <div><strong>{{subKey}}:</strong> {{jsonMessage[key][subKey]}}</div>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="button-bar control mb-2">
                            <button type="button" (click)="cancel()" class="btn btn-default"> Retour
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
