import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CONF } from '../../core/constants';
import { MigrationOctDataRaw } from '../../data/migrationOctDataRaw';
import { MigrationOctLogsDataRaw } from '../../data/migrationOctLogsDataRaw';

@Injectable({
	providedIn: 'root'
})
export class MigrationOctService {

	private readonly baseUrl = `${CONF.envUrl + CONF.appContext + CONF.apiBaseUrl}/v1/migration`;

	private modalClosedSource = new Subject<void>();

	modalClosed$ = this.modalClosedSource.asObservable();

	constructor(private http: HttpClient) { }

	getMigrationOctFilteredList(criteria: any, migrationOctDataRaw: MigrationOctDataRaw): Observable<any> {
		return this.http.post<any>(
			this.baseUrl +
			'/search/' + '?page=' +
			criteria.page +
			'&size=' +
			criteria.size +
			this.getSortParameters(criteria.sorts),
			migrationOctDataRaw
		);
	}

	getMigrationOctLogs(criteria: any, migrationOctLogsDataRaw: MigrationOctLogsDataRaw): Observable<any> {
		return this.http.get<any>(
			this.baseUrl + '/log/list?page=' + criteria.page + '&size=' + criteria.size +
			this.getSortParametersLogs(criteria.sortsRules) + '&idBoiteMail=' + criteria.idBoiteMail
		);
	}

	//server=pop-1.edi.cegedim.net&email=972044838@e-fse.qualipharma.fr&password=rourer.dui
	getMigrationOctDetails(criteria: any): Observable<any> {
		return this.http.get<any>(
			this.baseUrl +
			'/read/' + '?page=' +
			criteria.page +
			'&size=' +
			criteria.size +
			this.getSortParametersRead(criteria.sorts) +
			'&server=' + criteria.server +
			'&email=' +
			criteria.email + '&password=' + criteria.password + '&port=' + criteria.port 
			//this.getSortParameters(criteria.sorts),
			//migrationOctLogsDataRaw
		);
	}

	//@TODO to optimize in one method
	getSortParameters(sorts) {
		if (sorts && sorts.length === 1) {
			const sort = sorts[0].property;
			return '&sort=' + sort + '&direction=' + sorts[0].direction;
		} else {
			// sort par défaut
			return '&sort=serveurPop&direction=DESC';
		}
	}

	getSortParametersRead(sorts) {
		if (sorts && sorts.length === 1) {
			const sort = sorts[0].property;
			return '&sort=' + sort + '&direction=' + sorts[0].direction;
		} else {
			// sort par défaut
			return '&sort=dateEmail&direction=DESC';
		}
	}

	getSortParametersLogs(sorts) {
		if (sorts && sorts.length === 1) {
			const sort = sorts[0].property;
			return '&sort=' + sort + '&direction=' + sorts[0].direction;
		} else {
			// sort par défaut
			return '&sort=logDate&direction=DESC';
		}
	}

	create(migrationOctDataRaw: MigrationOctDataRaw): Observable<any> {
		return this.http.post<any>(this.baseUrl + '/create', migrationOctDataRaw).pipe(catchError(this.handleError));
	}

	start(criteria: any): Observable<any> {
		return this.http.post<any>(this.baseUrl + '/start?idBoiteMail=' + criteria.idBoiteMail + '&idSite=' + criteria.idSiteDestination, null).pipe(catchError(this.handleError));
	}

	stop(idBoiteMail: number): Observable<any> {
		return this.http.post<any>(this.baseUrl + '/stop?idBoiteMail=' + idBoiteMail, null).pipe(catchError(this.handleError));
	}

	handleError(error: HttpErrorResponse) {
		return throwError(error);
	}

	delete(idBoiteMail: number ): Observable<any> {
		return this.http.delete<any>(`${this.baseUrl}/${idBoiteMail}`);
	}

	notifyModalClosed() {
		this.modalClosedSource.next();
	}
}
