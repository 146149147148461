import { Component, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'careweb-user-preview',
	template: `
        <i
			[ngClass]="iconClass"
            container="body"
			(click)="previewUser()"
			[tooltip]="tooltip"
            containerClass="tooltip-grid"
			(contextmenu)="onRightClick($event)"
		></i>
    `
})
export class UserPreviewComponent implements ICellRendererAngularComp, AfterViewInit {
	iconClass: string;
	tooltip: string;
	private params: any;

	constructor(private router: Router) {}

	agInit(params: any): void {
		this.params = params;
	}

	ngAfterViewInit() {
		const cellParams = this.params;
		if (cellParams.data) {
			// Colonne options pour afficher le détail
			if (cellParams.colDef.field && cellParams.colDef.field === 'options') {
				this.iconClass = cellParams.iconClass;
				this.tooltip = 'Consulter le compte OCT';
				this.params.redirect='compteOCT'
			} else if (cellParams.colDef.field && cellParams.colDef.field === 'actif') {
				// Colonne Actif
				cellParams.value === true ? (this.iconClass = 'fa fa-check') : (this.iconClass = 'fa fa-close');
			}
			else if (cellParams.colDef.field && cellParams.colDef.field === 'blocked') {
				// Colonne Compte bloqué
				cellParams.value === true ? (this.iconClass = 'fa fa-close red') : (this.iconClass = '');
			}
		}
	}

	refresh(): boolean {
		return false;
	}

	previewUser(): void {
		// if (this.params.redirect == 'rspDetails') {
		// 	this.router.navigate([ '/rsp-details', this.params.data.idRsp ]);
		// }
	}

	onRightClick(event: MouseEvent): void {	
		if (this.params.redirect === 'compteOCT') {
			event.preventDefault();
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/careweb-client/user-oct-details',  this.params.data.idUser])
			);
			window.open(url, '_blank');
		}
	}
}
