import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HeaderDropdownComponent } from './header/header-dropdown.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { HttpClientModule } from '@angular/common/http';
import { DynamicGridSvc } from './ag-grid/dynamic-grid.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderMenuComponent } from './header/header-menu.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { ErrorMessagesComponent } from './error-messages/error-messages.component';
import { LogoutModalComponent } from '../pages/logout/logout-modal.component';
import { VersionService } from '../pages/login/version.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalConfirmRappAutoComponent } from './modal-confirm-rapp-auto/modal-confirm-rapp-auto.component';
import { PointerSolderModalComponent } from '../pages/rapprochement/pointer-solder-modal/pointer-solder-modal.component';
import { DictRuleModalComponent } from '../pages/dict-rule-rapp/dict-rule-modal/dict-rule-modal.component';
import {DictRuleCpamModalComponent} from "../pages/dict-rule-rapp/dict-rule-cpam-modal/dict-rule-modal.component";


@NgModule({
    imports: [
        CollapseModule,
        CommonModule,
        RouterModule,
        NgbModule,
        FontAwesomeModule,
        HttpClientModule,
        NgSelectModule,
        ReactiveFormsModule,
        FormsModule,
        BsDropdownModule,
        MatTableModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatExpansionModule,
        BsDatepickerModule,
        //HelpComponent,
        //OpenHelpComponent
    ],
    declarations: [
        FooterComponent,
        HeaderMenuComponent,
        HeaderDropdownComponent,
        ErrorMessagesComponent,
        LogoutModalComponent,
        ModalConfirmRappAutoComponent,
        PointerSolderModalComponent,
        DictRuleModalComponent,
        DictRuleCpamModalComponent
    ],
    providers: [DynamicGridSvc, VersionService],
    exports: [
        FooterComponent,
        HeaderMenuComponent,
        HeaderDropdownComponent,
        MatTableModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        ErrorMessagesComponent,
        //HelpComponent,
        //OpenHelpComponent
    ]
})
export class ComponentsModule {}
