<form [formGroup]="siteForm" (ngSubmit)="onSubmit()">
    <div class="tab-pane">
        <div class="form-block">
            <fieldset class="container-fluid">
                <div class="col-sm-12">
                    <div *ngIf="balEmettriceExist" class="alert alert-warning p-3 rounded" style="margin-top:-13px; "><i class="fa fa-exclamation-triangle"></i>
                        La BAL émettrice que vous avez saisie est déjà associée à une autre fiche.
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">BAL emettrice principale *</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" formControlName="balEmettrice" class="form-control"
                                   onpaste="if(this.value.length==255) return false;"
                                   onKeyPress="if(this.value.length==255) return false;" (input)="setBalemettrice($event)"/>

                            <div *ngIf="submitted && formFields.balEmettrice.errors" class="invalid-feedback">
                                <div
                                        *ngIf="formFields.balEmettrice.errors.required || formFields.balEmettrice.errors.whitespaces">
                                    Ce champ est obligatoire
                                </div>
                                <div *ngIf="formFields.balEmettrice.errors.balEmettriceUnique">
                                    Ce BAL emettrice principale est déjà utilisé
                                </div>
                                <div *ngIf="formFields.balEmettrice.errors.fieldTypeEmail">
                                    Ce champ n'est pas valide
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-2 col-form-label">BAL emettrice secondaire</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" formControlName="balEmettriceSecondaire"
                                   onpaste="if(this.value.length==255) return false;"
                                   onKeyPress="if(this.value.length==255) return false;" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label"> <span>Serveur de <br> messagerie</span></div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" formControlName="serveurPop"
                                   onpaste="if(this.value.length==50) return false;"
                                   onKeyPress="if(this.value.length==50) return false;" />
                        </div>
                        <div class="col-sm-2 col-form-label">OCT</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" formControlName="nomOCT" readonly />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Identifiant XModem</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" formControlName="identifiantXModem"
                                   onpaste="if(this.value.length==50) return false;"
                                   onKeyPress="if(this.value.length==50) return false;" />
                        </div>
                        <div class="col-sm-2 col-form-label">Mot de passe</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" formControlName="motDePasse"
                                   onpaste="if(this.value.length==50) return false;"
                                   onKeyPress="if(this.value.length==50) return false;" /></div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Type de Retour</div>
                        <div class="col-sm-4 form-group">
                            <ng-select formControlName="typeRetourSouhaite"
                                       style="width:100%; height:calc(1.5em + .75rem + 2px);" bindLabel="name" [clearable]="false">
                                <ng-option *ngFor="let type of typeRetourArray" [value]="type">
                                    {{type.viewValue}}
                                </ng-option>
                            </ng-select>
                        </div>
                        <div class="col-sm-2 col-form-label">Envoi ART</div>
                        <div class="col-sm-4 form-group">
                            <ng-select formControlName="envoiART" style="width:100%; height:calc(1.5em + .75rem + 2px);"
                                       bindLabel="name" [clearable]="false">
                                <ng-option *ngFor="let parDefaut of envoiARTArray" [value]="parDefaut">
                                    {{parDefaut.viewValue}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Type de Transport</div>
                        <div class="col-sm-4 form-group">
                            <ng-select formControlName="typeTransport"
                                       style="width:100%; height:calc(1.5em + .75rem + 2px);" bindLabel="name" [clearable]="false"
                                       appendTo="body" placeholder="Type de Transport" (change)="changeTypeTransport($event)">
                                <ng-option *ngFor="let typeTransport of typeTransportArray" [value]="typeTransport">
                                    {{typeTransport.viewValue}}
                                </ng-option>
                            </ng-select>
                        </div>
                        <div class="col-sm-2 col-form-label">GEN</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" formControlName="gen"
                                   onpaste="if(this.value.length==5) return false;"
                                   onKeyPress="if(this.value.length==5) return false;" />
                            <div *ngIf="submitted && formFields.gen.errors" class="invalid-feedback">
                                <div *ngIf="formFields.gen.errors.required">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Numéro maître</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" formControlName="numeroMaitre"
                                   onpaste="if(this.value.length==5) return false;"
                                   onKeyPress="if(this.value.length==5) return false;" />
                            <div *ngIf="submitted && formFields.numeroMaitre.errors" class="invalid-feedback">
                                <div *ngIf="formFields.numeroMaitre.errors.required">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-2 col-form-label">Numéro esclave</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" formControlName="numeroEsclave"
                                   onpaste="if(this.value.length==5) return false;"
                                   onKeyPress="if(this.value.length==5) return false;" />
                            <div *ngIf="submitted && formFields.numeroEsclave.errors" class="invalid-feedback">
                                <div *ngIf="formFields.numeroEsclave.errors.required">
                                    Ce champ est obligatoire
                                </div>
                                <div *ngIf="formFields.numeroEsclave.errors.controlNumeroEsclaveUnique">
                                    Numéro esclave déjà existant.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Envois compressés</div>
                        <div class="col-sm-4 form-group">
                            <ng-select formControlName="envoisCompresses"
                                       style="width:100%; height:calc(1.5em + .75rem + 2px);" bindLabel="name" [clearable]="false">
                                <ng-option *ngFor="let envoisCompresses of envoiCompresserArray" [value]="envoisCompresses">
                                    {{envoisCompresses.viewValue}}
                                </ng-option>
                            </ng-select>
                        </div>
                        <div class="col-sm-2 col-form-label">Site par défaut</div>
                        <div class="col-sm-4 form-group">
                            <ng-select formControlName="parDefaut" style="width:100%; height:calc(1.5em + .75rem + 2px);"
                                       bindLabel="name" [clearable]="false">
                                <ng-option *ngFor="let parDefaut of parDefautArray" [value]="parDefaut">
                                    {{parDefaut.viewValue}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Envoi Jdre</div>
                        <div class="col-sm-4 form-group">
                            <ng-select formControlName="envoiJdre" style="width:100%; height:calc(1.5em + .75rem + 2px);"
                                       bindLabel="name" [clearable]="false">
                                <ng-option *ngFor="let jdre of envoiJDREArray" [value]="jdre">
                                    {{jdre.viewValue}}
                                </ng-option>
                            </ng-select>
                        </div>
                        <div class="col-sm-2 col-form-label">RPH2</div>
                        <div class="col-sm-4 form-group">
                            <ng-select formControlName="rph2" style="width:100%; height:calc(1.5em + .75rem + 2px);"
                                       bindLabel="name" [clearable]="false">
                                <ng-option *ngFor="let rph2 of rph2Array" [value]="rph2">
                                    {{rph2.viewValue}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Actif</div>
                        <div class="col-sm-4 form-group">
                            <ng-select formControlName="actif" style="width:100%; height:calc(1.5em + .75rem + 2px);"
                                       bindLabel="name" [clearable]="false">
                                <ng-option *ngFor="let actif of actifArray" [value]="actif">
                                    {{actif.viewValue}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>

                <!-- TOOLBAR -->
                <div class="button-bar control">
                    <button type="button" class="btn btn-default" (click)="reset()"> Annuler </button>
                    <button type="submit" class="btn btn-primary" [disabled]="!siteForm.dirty"> Enregistrer </button>
                </div>
            </fieldset>
        </div>
    </div>
</form>