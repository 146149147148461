import { Injectable } from '@angular/core';
import { CONF } from '../../core/constants';
import {HttpClient, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private readonly baseurl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/ps';
  constructor(private httpclient: HttpClient) { }
  getRecapSimp(numAdeli: String,year: String): Observable<any>{
    return this.httpclient.get<any>(this.baseurl + "/reporting/simp/"+numAdeli+"/"+year);
  }
  getRecapDetailled(numAdeli: String,year: String): Observable<any>{
    return this.httpclient.get<any>(this.baseurl + "/reporting/det/"+numAdeli+"/"+year);
  }
  getRecapSimpAllReport(criteria, oct): Observable<any> {
    return this.httpclient.post<any>(this.baseurl + "/generateAllReport/"+oct,
        criteria,
        {
          observe: 'response' as 'body',
          responseType: 'blob' as 'json'
        }).pipe(
        map((response: HttpResponse<Blob>): any => {
          const type = 'application/zip';
          const fileName = 'Recap_Annuel_RC.zip'
          return this.getFileInfosFromResponse(response, type, fileName);
        })
    );
  }

  getRecapSimpReport(numeroAdeli, date, oct): Observable<any> {
    return this.httpclient.get<any>(this.baseurl + "/generateReport/"+oct+"?numeroAdeli="+
        numeroAdeli + "&date=" + date,
        {
          observe: 'response' as 'body',
          responseType: 'blob' as 'json'
        }).pipe(
        map((response: HttpResponse<Blob>): any => {
          const type = 'application/pdf';
          const fileName = 'Récap_'+numeroAdeli+'_'+date
          return this.getFileInfosFromResponse(response, type, fileName);
        })
    );
  }

    getRecapDetAllReport(criteria, oct): Observable<any> {
        return this.httpclient.post<any>(this.baseurl + "/generateAllReport-detail/"+oct,
            criteria,
            {
                observe: 'response' as 'body',
                responseType: 'blob' as 'json'
            }).pipe(
            map((response: HttpResponse<Blob>): any => {
                const type = 'application/zip';
                const fileName = 'Récap_Annuel_RC.zip'
                return this.getFileInfosFromResponse(response, type, fileName);
            })
        );
    }

    getRecapDetReport(numeroAdeli, date, oct): Observable<any> {
        return this.httpclient.get<any>(this.baseurl + "/generateReport-detail/"+oct+"?numeroAdeli="+
            numeroAdeli + "&date=" + date,
            {
                observe: 'response' as 'body',
                responseType: 'blob' as 'json'
            }).pipe(
            map((response: HttpResponse<Blob>): any => {
                const type = 'application/pdf';
                const fileName = 'Récap_'+numeroAdeli+'_'+date
                return this.getFileInfosFromResponse(response, type, fileName);
            })
        );
    }
  getFileInfosFromResponse(response: HttpResponse<Blob>, type, fileName) {
    return {
      file: new Blob([ response.body ], { type: type }),
      fileName: fileName
    };
  }
}
