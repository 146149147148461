<div class="modal-body pb-0">
    <div class="form-block">
        <div class="row">
            <div class="col">
                <h3 class="title-border">
                    <div class="row">
                        <div class="col-auto me-auto">Site de Reroutage</div>
                        <div class="col-auto"></div>
                    </div>
                </h3>
            </div>
        </div>

        <div *ngIf="showMessage" style="text-align:center;"><span> {{statusMessage}} </span></div>

        <!-- Tableau -->
        <div class="row">
            <div class="col">
                <div class="table-responsive">
                    <table class="ui-grid-like" *ngIf="!showMessage">
                        <thead>
                            <tr>
                                <th>BAL Emettrice Principale</th>
                                <th>Type de Retour Souhaité</th>
                                <th>Type Transport</th>
                                <th style="width:100px">Site par défaut</th>
                                <th style="width:20px">Envois compressés</th>
                                <th style="width:20px">Envoi Jdre</th>                               
                                <th style="width:20px">Actif</th>
                                <th style="width:20px"></th> 
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="siteReroutage!==null">
                                <td>{{siteReroutage.balEmettrice}}</td>
                                <td>{{siteReroutage.typeRetourSouhaite}}</td>
                                <td>{{getNomTypeTransport(siteReroutage.idTypeTransport)}}</td>
                                <td class="text-center">
                                    <i [ngClass]="(siteReroutage.parDefaut)? 'fa fa-check' :'fa fa-close'"></i>
                                </td>
                                <td class="text-center">
                                    <i [ngClass]="(siteReroutage.envoisCompresses)? 'fa fa-check' :'fa fa-close'"></i>
                                </td>
                                <td class="text-center">
                                    <i [ngClass]="(siteReroutage.envoiJdre)? 'fa fa-check' :'fa fa-close'"></i>
                                </td>
                                <td class="text-center">
                                    <i [ngClass]="(siteReroutage.actif)? 'fa fa-check' :'fa fa-close'"></i>
                                </td>                                
                                <td class="text-center" style="font-size: 1rem">
                                    <a>
                                    <i class="fa fa-chain-broken gridCellStyle" (click)="dettachSiteReroutage()"
                                        data-toggle="tooltip" title="Détacher le site de reroutage"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>

    <div class="form-block">
        <div class="row">
            <div class="col">
                <h3 class="title-border">
                    <div class="row">
                        <div class="col-auto me-auto">Rattacher un site de reroutage</div>
                        <div class="col-auto"></div>
                    </div>
                </h3>
            </div>
        </div>

        <!-- Tableau -->
        <div class="row">
            <div class="col">
                <div class="table-responsive">
                    <table class="ui-grid-like">
                        <thead>
                            <tr>
                                <th>BAL Emettrice Principale</th>
                                <th>Serveur de messagerie</th>
                                <th>Identifiant XModem</th>
                                <th style="width:20px"></th> 
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let site of siteEmissionList">
                                <td>{{site.balEmettrice}}</td>
                                <td>{{site.serveurPop}}</td>
                                <td>{{site.identifiantXModem}}</td>
                               
                                <td class="text-center" style="font-size: 1rem">
                                    <a>
                                    <i class="fa fa-link gridCellStyle" (click)="attachSiteReroutage(site.idSite)"
                                        data-toggle="tooltip" title="Ajouter ce site de reroutage"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</div>