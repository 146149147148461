export class HotlineDataRaw {
	idIntervention: number;
	numeroPs: String;
	dateCreation: String;
	intervention: String;
	motif: String;
	dateDebutIntervention: String;
	statut: Number;
	groupe: String;
	reference: String;
	agent: String;
	resolution: Number;
	niveau: Number;
	service: String;
	user: String;
	userName: String;
	commentaire: String;
	autre: String;
	intervenant: String;
	oct: String;
	withReference: Boolean;
	idOct: Number;
}
