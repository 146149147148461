export class MigrationOctDataRaw{
    idPs?: number;
    serveurPop: String;
    idOct: Number;
    password: String;
    username: String;
    numeroPS: String;
    numeroFacturation: String;
    port: String;
    balAmigrer: String;
    balDestination: String;
    balDestinatrice?: String;
    status: boolean;
    idBoiteMailDestinatrice?: number;
    lastLogError: boolean;

}