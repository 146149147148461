<div class="x-large-careweb-container">
    <form #interventionForm="ngForm" class="form-content" (ngSubmit)="saveHotline()">
        <section class="form-content">
            <!-- Titre H2 -->
            <div class="row ">
                <h2 class="col-md-6">
                    Ajouter une nouvelle intervention
                </h2>
                <div class="col-md-6 text-title">
                    <button class="btn btn-default btn-lg" type="button" (click)="backClicked()">Retour</button>
                </div>
            </div>
            <div class="form-block">
                <!-- Titre -->
                <div class="row">
                    <div class="col">
                        <h3 class="title-border">
                            Informations
                        </h3>
                    </div>
                </div>
                <fieldset class="container-fluid">
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Numéro de PS</div>
                        <div class="col-sm-4 form-group">
                            <input class="form-control form-control-input" placeholder="" [(ngModel)]="numeroPs"
                                name="numeroPs" [value]="numeroPs" disabled="disabled" value="numeroPs">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Type d'intervention</div>
                        <div class="col-sm-4 form-group required">
                            <div class="field">
                                <ng-select placeholder="Type d'intervention" name="intervention" #intervention="ngModel"
                                    [(ngModel)]="hotlineDataRaw.intervention" name="intervention" appendTo="body"
                                    class="  mb-3 mt-1" [clearable]="false" [required]="true">
                                    <ng-option *ngFor="let intervention of interventions " [value]="intervention.id">
                                        {{intervention.value}}</ng-option>
                                </ng-select>
                                <careweb-error-messages [field]="intervention" [touched]="true">
                                </careweb-error-messages>
                            </div>
                        </div>
                        <div class="col-sm-2 col-form-label">Initiateur</div>
                        <div class="col-sm-4 form-group">
                            <input class="form-control form-control-input" placeholder=""
                                [(ngModel)]="hotlineDataRaw.userName" name="userName" [value]="hotlineDataRaw.userName"
                                disabled="disabled" value="userName" type="text">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Motif de l'intervention *</div>
                        <div class="col-sm-4 form-group required">
                            <div class="field">
                                <ng-select placeholder="Motif de l'intervention" name="motif" #motif="ngModel"
                                    [(ngModel)]="hotlineDataRaw.motif" appendTo="body" class=" mb-3 mt-1"
                                    [clearable]="false" [required]="true">
                                    <ng-option *ngFor="let motif of motifs" [value]="motif.id">
                                        {{motif.value}}</ng-option>
                                </ng-select>
                                <careweb-error-messages [field]="motif" [touched]="true">
                                </careweb-error-messages>
                            </div>
                        </div>
                        <div class="col-sm-2 col-form-label">Niveau d'intervention</div>
                        <div class="col-sm-4 form-group" style="display:cell">
                            <ng-select placeholder="Niveau d'intervention" [(ngModel)]="hotlineDataRaw.niveau"
                                appendTo="body" name="niveau" class="mb-3 mt-1" [clearable]="false">
                                <ng-option *ngFor="let niveau of niveaux" [value]="niveau.id">
                                    {{niveau.value}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Statut de l'intervention</div>
                        <div class="col-sm-4 form-group">
                            <ng-select placeholder="Statut de l'intervention" [(ngModel)]="hotlineDataRaw.statut"
                                name="statut" class="mb-3 mt-1" (change)="getValues($event)" [clearable]="false">
                                <!--                                 <ng-option value="1">En cours</ng-option>
                                <ng-option value="2">Transférée</ng-option>
                                <ng-option value="3">Clôturée</ng-option> -->
                                <ng-option *ngFor="let status of interventionStatus " [value]="status.id">
                                    {{status.value}}</ng-option>
                            </ng-select>
                        </div>
                        <div class="col-sm-2 col-form-label">Service du destinataire</div>
                        <div class="col-sm-4 form-group">
                            <ng-select placeholder="Service du destinataire de l'intervention"
                                [(ngModel)]="hotlineDataRaw.service" name="service" class="mb-3 mt-1"
                                [disabled]="hotlineDataRaw.statut!==2" [clearable]="false">
                                <ng-option *ngFor="let service of groupeUsers" [value]="service.id">
                                    {{service.value}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Résolution de l'intervention</div>
                        <div class="col-sm-4 form-group">
                            <ng-select placeholder="Résolution de l'intervention"
                                [(ngModel)]="hotlineDataRaw.resolution" appendTo="body" name="resolution"
                                class="mb-3 mt-1" [clearable]="false" [disabled]="hotlineDataRaw.statut!==3">
                                <ng-option *ngFor="let resolution of resolutions" [value]="resolution.id">
                                    {{resolution.value}}</ng-option>
                            </ng-select>
                        </div>
                        <div class="col-sm-2 col-form-label">Agent assigné</div>
                        <div class="col-sm-4 form-group">
                            <ng-select placeholder="Agent assigné" [(ngModel)]="hotlineDataRaw.agent" appendTo="body"
                                name="agent" class="  mb-3 mt-1">
                                <ng-option *ngFor="let agent of agents " [value]="agent.id">
                                    {{agent.value}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Commentaire</div>
                        <div class="col-sm-10 form-group">
                            <textarea class="form-control-input" placeholder="" maxlength="1000" name="commentaire"
                                rows="3" style="  resize:horizontal;  resize: vertical;"
                                [(ngModel)]="hotlineDataRaw.commentaire" [value]="commentaire" #message='ngModel'
                                autosize (change)="doTextareaValueChange($event)"></textarea>
                        </div>
                    </div>
                </fieldset>
                <div class="row grid-top-btn" style="margin-top:0px">
                    <h2 class="col-md-6"></h2>
                    <div class="col-md-6 text-title">
                        <button type="button" name="btn_cancel" class="btn btn-default" (click)="cancel()">
                            Annuler
                        </button>&nbsp;&nbsp;
                        <button type="submit" name="btn_validate" class="btn btn-primary">
                            Ajouter
                        </button>
                    </div>
                </div>
            </div>
        </section>
    </form>
</div>