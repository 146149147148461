import { EtablissementDataRaw } from './EtablissementDataRaw';
import { User } from './userDataRaw';
import { SiteEmissionDataRaw } from './siteEmissionDataRaw';
import { OctPsDataRaw } from './octpsDataRaw';
import { EbicsParams } from './ebicsParams';

export class PsDataRaw {
	numeroAdeli: String;
	nom: String;
	idPs: number;
	babusiaux: number;
	bordereauCaisse: number;
	duo: number;
	idLogiciel: number;
	idSsii: number;
	idTypePs: Number;
	rpps: String;
	defaultEtablissement?: EtablissementDataRaw;
	idUser: Number;
	idEtablissement: Number;
	raisonSocialeEtablissement: String;
	telephoneEtablissment: String;
	adresse1Etablissement: String;
	adresse2Etablissement: String;
	codepostalEtablissment: String;
	villeEtablissment: String;
	faxEtablissment: String;
	emailEtablissment: String;
	psUsers: User[];
	siteEmissions: SiteEmissionDataRaw[];
	octPsDtos: OctPsDataRaw[];
	sel: boolean;
	rappComptePs: any;
	psEbics: any;
	ebicsParams: EbicsParams[];
	typeRappro: String;
	hideRappro: String;
	rappVisible: boolean;
}
