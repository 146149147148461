<div class="full-careweb-container">
    <div class="grid-container" id="grid">
        <h2 class="title-border mt-3">Mentions légales</h2>
        <div>
            <section>
                <div class="row" style="background-color:white; padding:20px">
                    <div class="col">
                        <div class="mentions-group">
                            <div class="mentions-title">Identité de la société</div>
                            <div>le Site est édité par Cegedim SA</div>
                            <div>Société Anonyme au capital social de 13 336 506,43 €, immatriculée au RCS de Nanterre sous le numéro B 350 422 622 ;</div>
                        </div>
                        <div class="mentions-group">
                            <div class="mentions-title">Siège social</div>
                            <div>137 rue d'Aguesseau, 92100 Boulogne-Billancourt </div>
                        </div>
                        <div class="mentions-group">
                            <div class="mentions-title">Téléphone</div>
                            <div>01 49 09 22 00</div>
                        </div>
                        <div class="mentions-group">
                            <div class="mentions-title">Directeur de la publication</div>
                            <div>Pierre MARUCCHI </div>
                        </div>
                        <div class="mentions-group">
                            <div class="mentions-title">Conception / Edition</div>
                            <div>Cegedim SA.</div>
                        </div>
                        <div class="mentions-group">
                            <div class="mentions-title">Hébergement du site</div>
                            <div>Cegedim.cloud, hébergeur certifié en conformité avec la réglementation en vigueur, RCS 790 173 066, 137 rue d’Aguesseau 92100 Boulogne Billancourt, 01.49.09.22.00</div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div> 
            