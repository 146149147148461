export class RspRappSummaryDataRaw{
    idRsp: number;
    idPs: number;
    numLot: string;
    numFacture: string;
    montantFactureRo: string;
    montantFactureRc: string;
    montantRo: string;
    montantRc: string;
    dateReception: Date;
    dateReceptionFacture: Date;
    libelleOrganisme: string;
    organismes: Array<string>;
    idRapprochement: number;
    statutPaiement: number;
    signe: number;
    idFacture: number;
    dateComptableFrom: string;
    dateComptableTo: string;
    numLotFrom: string;
    numLotTo: string;
    montantFrom: number;
    montantTo: number;
    montantExacte: number;
    montantGroupeFrom: number;
    montantGroupeTo: number;
    montantGroupeExacte: number;
    libelleVirement1: String;
    libelleVirement2: String;
    idOrganisme: string;
    libelleVirement: string;
    /* [CAW-1600-] Desactiver la checkAll & uncheckAll */
    idRspRappSummaries: number[];
    rsp30Jours: Boolean;
    rspIndu: Boolean;
}
