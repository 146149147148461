import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
	selector: 'careweb-filter-choice-exacte-plage',
	templateUrl: './filter-choice-exacte-plage.component.html'
})
export class FilterChoiceExactePlageComponent implements OnInit {
	@Input() filterChoiceFrom: number;
	@Input() filterChoiceTo: number;
	@Input() id: string;
	@Output() filterChange = new EventEmitter<any>();
	public filterChoice = 'EXACTE';

	ngOnInit(): void {
		this.initFilterChoice();
	}

	initFilterChoice() {
		if (this.filterChoiceFrom || this.filterChoiceTo) {
			this.filterChoice = 'PLAGE';
		} else {
			this.filterChoice = 'EXACTE';
		}
	}

	reserFilterChoice() {
		this.filterChoice = 'EXACTE'
	}

	onChoiceChange(filterChoice) {
		this.filterChange.emit({
			filterChoice: filterChoice
		});
	}
	
}
