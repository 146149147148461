<form #retourForm="ngForm" class="form-content p-relative" id="period-form">
    <div class="div-dropdown row">
        <button class="btn btn-action align-left w-100 no-wrap" data-toggle="collapse"
            (click)="isCollapsed = !isCollapsed">
            <i class="fa fa-calendar"></i>
            Période du {{startDate | date:'shortDate'}} au {{endDate | date:'shortDate'}}
            <i class="fa fa-sort-down p-relative"></i>
        </button>
    </div>
    <div class="box-content row box-dropdown align-left p-absolute" [ngClass]="{ 'collapse': !isCollapsed }">
        <div class="col-md-12">
            <div class="row" *ngFor="let range of ranges">
                <div class="col-md-12">
                    <span (click)="datePeriodeClick(range)" [ngClass]="{'period-option-active': selectedRange == range}"
                        class="period-option cursor-pointer">Les {{range}} derniers jours</span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <span class="date-perso">Personnaliser :</span>
                </div>
            </div>
            <div class="row date-div">
                <div class="col-4 p-0">
                    <input type="text" class="form-control" name="dashDateMin" id="dashDateMin" #dashDateMin="ngModel"
                        bsDatepicker size="25" placement="bottom" [(ngModel)]="startDate" [maxDate]="endDate" [minDate]=" applyMindate ? minDate : null"
                        placeholder="Choisir une date" (bsValueChange)="setRetourMaxDate($event)"
                        (change)="setRetourMaxDate($event);onChangeInputStart($event)" (blur)="setRetourMaxDate($event)" autocomplete="off"
                        [bsConfig]="{ containerClass: 'theme-kiss', showWeekNumbers: false }" />
                    <careweb-error-messages [field]="dashDateMin" [touched]="true">
                    </careweb-error-messages>
                </div>
                <div class="col-2 p-0">
                    <button type="button" class="btn btn-disabled p-0 w-100 border-radius-0" disabled="true">
                        à
                    </button>
                </div>
                <div class="col-4 p-0">
                    <input type="text" class="form-control border-radius-0" name="dashDateMax" id="dashDateMax"
                        #dashDateMax="ngModel" bsDatepicker size="25" placement="bottom"
                        [bsConfig]="{ containerClass: 'theme-kiss', showWeekNumbers: false }"
                        placeholder="Choisir une date" [(ngModel)]="endDate" [minDate]="startDate" (change)="onChangeInputEnd($event)"
                        [maxDate]="currentDate" />
                    <careweb-error-messages [field]="dashDateMax" [touched]="true">
                    </careweb-error-messages>
                </div>
                <div class="col-2 p-0">
                    <button type="button" class="btn btn-primary p-0 w-100" (click)="persoFilter()">
                        OK
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <span class="spanError" *ngIf="!isValidDate">
                        Veuillez choisir des dates de début et de fin correctes
                    </span>
                </div>
            </div>
        </div>
    </div>
</form>