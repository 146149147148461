import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'careweb-app-auth-layout',
	templateUrl: './auth-layout.component.html',
	styleUrls: [ './auth-layout.component.scss' ]
})
export class AuthLayoutComponent implements OnInit, OnDestroy {
	test: Date = new Date();
	public isCollapsed = true;

	constructor(private router: Router) {}

	ngOnInit() {
		const html = document.getElementsByTagName('html')[0];
		html.classList.add('auth-layout');
		this.router.events.subscribe((event) => {
			this.isCollapsed = true;
		});
	}

	ngOnDestroy() {
		const html = document.getElementsByTagName('html')[0];
		html.classList.remove('auth-layout');
	}
}
