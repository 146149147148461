import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InputValidators } from '../../../../components/validators/InputValidators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ReglesParsingDataRaw } from '../../../../data/ReglesParsingDataRaw';
import { ReglesParsingService } from '../../../ps/service/regles-parsing.service';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import { EbicsService } from '../../../ps/service/ebics.service';

@Component({
  selector: 'app-add-regle-parsing-xml',
  templateUrl: './add-regle-parsing-xml.component.html',
  styleUrls: ['./add-regle-parsing-xml.component.scss']
})
export class AddRegleParsingXmlComponent implements OnInit {
  @Input() isEditing?: boolean = false;
  @Input() regleToModify?: ReglesParsingDataRaw;
  addRegleParsingForm: FormGroup;
  reglesParsingDataRaw:ReglesParsingDataRaw;
  submitted: Boolean = false;
  title: String;
  listAbonnements: any;
  placeholder;

  constructor(
    private fb: FormBuilder,
    private inputValidators: InputValidators,
    private modalRef: BsModalRef,
    private reglesParsingService: ReglesParsingService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private ebicsService: EbicsService
  ) {
  }
  ngOnInit() {
    this.submitted = false;
    this.initializeCurrentPage();
    this.ebicsService
            .getParsingAbonnements(this.regleToModify ? this.regleToModify.id : '')
			.subscribe(
				(data) => {
					this.listAbonnements = data;
				},
				() => this.listAbonnements = null
			);
    if (this.isEditing && this.regleToModify) {
      this.title = "Modifier une règle";
      this.placeholder = "Veuillez sélectionner un abonnement"
      this.reglesParsingDataRaw=this.regleToModify;
      if (!isNullOrUndefined(this.reglesParsingDataRaw)) {
        this.fromObjectToForm();
      }
    } else {
      this.title = "Ajouter une règle"
      this.placeholder = "Aucun abonnement sélectionné";
    }

  }
 
  fromObjectToForm() {
    this.addRegleParsingForm.controls['ebicsParamsId'].setValue(this.reglesParsingDataRaw.ebicsParamsId);
    this.addRegleParsingForm.controls['statement'].setValue(this.reglesParsingDataRaw.statement);
    this.addRegleParsingForm.controls['iban'].setValue(this.reglesParsingDataRaw.iban);
    this.addRegleParsingForm.controls['ntry'].setValue(this.reglesParsingDataRaw.ntry);
    this.addRegleParsingForm.controls['dateImport'].setValue(this.reglesParsingDataRaw.dateImport);
    this.addRegleParsingForm.controls['label'].setValue(this.reglesParsingDataRaw.label);
    this.addRegleParsingForm.controls['transactionId'].setValue(this.reglesParsingDataRaw.transactionId);
    this.addRegleParsingForm.controls['emetteur'].setValue(this.reglesParsingDataRaw.emetteur);
    this.addRegleParsingForm.controls['motif'].setValue(this.reglesParsingDataRaw.motif);
    this.addRegleParsingForm.controls['dateComptable'].setValue(this.reglesParsingDataRaw.dateComptable);
    this.addRegleParsingForm.controls['montant'].setValue(this.reglesParsingDataRaw.montant);
    this.addRegleParsingForm.controls['detail'].setValue(this.reglesParsingDataRaw.detail);
}
  initializeCurrentPage() {
    this.validForm();
  }
  get formFields() {
    return this.addRegleParsingForm.controls;
  }
  validForm() {
    this.addRegleParsingForm = this.fb.group({
      ebicsParamsId: [null, [Validators.required]],
      statement: ['', [Validators.required, this.inputValidators.inputWithSpaces]],
      iban: ['', [Validators.required, this.inputValidators.inputWithSpaces]],
      ntry: ['', [Validators.required, this.inputValidators.inputWithSpaces]],
      dateImport: ['', [Validators.required, this.inputValidators.inputWithSpaces]],
      label: ['', [Validators.required, this.inputValidators.inputWithSpaces]],
      transactionId: ['', [Validators.required, this.inputValidators.inputWithSpaces]],
      emetteur: ['', [Validators.required, this.inputValidators.inputWithSpaces]],
      motif: ['', [Validators.required, this.inputValidators.inputWithSpaces]],
      dateComptable: ['', [Validators.required, this.inputValidators.inputWithSpaces]],
      montant: ['', [Validators.required, this.inputValidators.inputWithSpaces]],
      detail: ['', [Validators.required, this.inputValidators.inputWithSpaces]]
    });
  }
  reset() {
    this.modalRef.hide();
  }
  onSubmit() {
    this.submitted = true;


    if (this.addRegleParsingForm.invalid) {
      return;
    }
    let form = this.addRegleParsingForm.value;
    let regleParsing: ReglesParsingDataRaw = new ReglesParsingDataRaw();
    if(this.isEditing && this.regleToModify){
      regleParsing.id=this.regleToModify.id;
    }
    regleParsing.ebicsParamsId = form.ebicsParamsId;
    regleParsing.statement = form.statement;
    regleParsing.iban = form.iban;
    regleParsing.ntry = form.ntry;
    regleParsing.dateImport = form.dateImport;
    regleParsing.label = form.label;
    regleParsing.transactionId = form.transactionId;
    regleParsing.emetteur = form.emetteur;
    regleParsing.motif = form.motif;
    regleParsing.dateComptable = form.dateComptable;
    regleParsing.montant = form.montant;
    regleParsing.detail = form.detail;

    this.reglesParsingService.save(regleParsing).subscribe(
      (data) => {
        if (!this.isEditing) {
          this.toastr.success('La règle de parsing a été ajouté avec succès');
        } else {
          this.toastr.success('La règle de parsing a été mise à jour avec succès');
        }
        this.closeModal();
      },
      (error) => {
        if (!this.isEditing) {
          this.toastr.error('Erreur lors de l\'ajout de la règle de parsing');
        }
        else {
          this.toastr.error('Erreur lors de la mise à jour de la règle de parsing');
        }
      }
    )

  }
  closeModal() {
    this.modalRef.hide();
    this.modalService.onHide.subscribe(() => {
      this.reglesParsingService.notifyModalClosed();
    });
  }
}
