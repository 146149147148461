import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrgStateService } from '../../org-state.service';
import { IServerSideDatasource, IServerSideGetRowsParams, GridOptions, ColDef } from 'ag-grid-enterprise';
import { AgGridHelper } from '../../../../components/ag-grid/ag-grid-helper';
import { OrgStatePreviewComponent } from '../org-state-preview.component';
import { ServiceDirectoryService } from '../../service-directory.service';
import { ConventionelPreviewComponent } from '../conventionel-preview.component';
import { isNullOrUndefined } from 'util';
import { ToastrService } from 'ngx-toastr';
import { OrgStateDataRaw } from '../../../../data/orgStateDataRaw';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, NgForm } from '@angular/forms';
import { ParametrageSelDataRaw } from '../../../../data/parametrageSelDataRaw';
import { debounceTime } from 'rxjs/operators';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale, frLocale } from 'ngx-bootstrap/chronos';

@Component({
	selector: 'app-fiche-org-state',
	templateUrl: './fiche-org-state.component.html',
	styleUrls: ['./fiche-org-state.component.scss']
})
export class FicheOrgStateComponent implements OnInit {
	gridHelper: AgGridHelper;
	gridHelperParamSel: AgGridHelper;
	gridHelperSituation: AgGridHelper;
	organizationNumber: string;
	label: string;
	convType: string;
	csr: string;
	selectedOrgState: OrgStateDataRaw;
	showEditStatutModal: boolean;
	stateForm: UntypedFormGroup;
	existeInParamSel: boolean = false;
	showParamSelModal: boolean = false;
	isUpdateParamSel: boolean = false;
	paramSelForm: UntypedFormGroup;
	selectedParamSel: ParametrageSelDataRaw;
	oldParamSel: ParametrageSelDataRaw;
	paramSelDataRaw: ParametrageSelDataRaw;
	providerNumber: string;
	showConfirmModal = false;
	modalMessage: string;
	today: Date;
	@ViewChild('retourForm')
	form: NgForm;
	domainArray: any = [
		{ code: 'AMI', label: 'Infirmier(e)' },
		{ code: 'AMM', label: 'Kinésithérapie' },
		{ code: 'AMY', label: 'Orthoptie' },
		{ code: 'AMO', label: 'Orthophonie' },
		{ code: 'AMP', label: 'Pédicure podologue' },
		{ code: 'AUDI', label: 'Audioprothèse' },
		{ code: 'CSTD', label: 'Centre de santé dentaire' },
		{ code: 'CSTE', label: 'Centre de santé hors dentaire' },
		{ code: 'CURE', label: 'Cure thermale' },
		{ code: 'DENT', label: 'Dentaire' },
		{ code: 'EXTE', label: 'Soins externes hospitaliers' },
		{ code: 'HOSP', label: 'Séjour hospitalier' },
		{ code: 'LABO', label: 'Laboratoire' },
		{ code: 'LPPS', label: 'Fournisseur hors optique / audio' },
		{ code: 'MEDG', label: 'Médecine générale' },
		{ code: 'MEDS', label: 'Médecine spéc sf radio' },
		{ code: 'OPTI', label: 'Optique' },
		{ code: 'PHAR', label: 'Pharmacie' },
		{ code: 'RADL', label: 'Radiologie' },
		{ code: 'SAGE', label: 'Sage-femme' },
		{ code: 'TRAN', label: 'Transport' }
	];

	submitted = false;

	constructor(
		private activatedRoute: ActivatedRoute,
		private orgStateService: OrgStateService,
		private serviceDirectoryService: ServiceDirectoryService,
		private toastr: ToastrService,
		private fb: UntypedFormBuilder,
		private localeService: BsLocaleService,
		private router: Router
	) {
		// Body styling
		let body = document.body;
		body.className = 'app';
		this.gridHelper = new AgGridHelper('orgState', 10);
		this.gridHelperParamSel = new AgGridHelper('paramSel', 10);
		this.gridHelperSituation = new AgGridHelper('situation', 10);
		// Define local date fr
		defineLocale('fr', frLocale);
	}

	ngOnInit() {
		// Set local date
		this.setLocale();

		this.organizationNumber = this.activatedRoute.snapshot.params.organizationNumber;
		this.label = this.activatedRoute.snapshot.params.label;
		this.initListOrgStat();
		this.initListParametreSel();
		this.initListSituation();
		this.selectedParamSel = new ParametrageSelDataRaw();
		this.oldParamSel = new ParametrageSelDataRaw();
		this.selectedOrgState = new OrgStateDataRaw();
		this.paramSelDataRaw = new ParametrageSelDataRaw();
		this.today = new Date();
		this.orgStateService
			.getOrganizationState({
				page: 1,
				size: 10,
				organizationNumber: this.organizationNumber
			})
			.subscribe((results) => {
				for (let item of results.data) {
					if (item.label === this.label) {
						this.convType = item.convType;
						this.csr =item.secondaryCriteria;
					}
				}
		    },
			err=>{
				if(err.status===400){
					this.router.navigate(['/404'])
				}
			});
	}

	initListOrgStat() {
		this.gridHelper.gridOptions = <GridOptions>(<unknown>{
			columnDefs: this.getColumnDefs(),
			defaultColDef: {
				resizable: true,
				suppressMenu: true
			},
			context: {
				componentParent: this
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 10,
			maxBlocksInCache: 0,
			infiniteInitialRowCount: 1,
			paginationPageSize: 10,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			localeText: this.gridHelper.getLocaleText(),
			onGridReady: (params) => this.onGridReady(params)
			// onGridSizeChanged: (params) => this.onGridSizeChanged(params)
		});
	}

	initListParametreSel() {
		this.gridHelperParamSel.gridOptions = <GridOptions>(<unknown>{
			columnDefs: this.getColumnDefsParamSel(),
			defaultColDef: {
				resizable: true,
				suppressMenu: true
			},
			context: { parentComponent: this },
			components: {
				customSelectFilterComponent: this.getSelectFloatingFilterComponent()
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 10,
			maxBlocksInCache: 0,
			infiniteInitialRowCount: 1,
			paginationPageSize: 10,
			paginateChildRows: true,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			localeText: this.gridHelper.getLocaleText(),
			onGridReady: (params) => this.onGridReadyParamSel(params)
			// onGridSizeChanged: (params) => this.onGridSizeChangedParamSel(params)
		});
	}

	initListSituation() {
		this.gridHelperSituation.gridOptions = <GridOptions>(<unknown>{
			columnDefs: this.getColumnDefsSituation(),
			defaultColDef: {
				resizable: true,
				suppressMenu: true
			},
			context: { parentComponent: this },
			components: {
				customInputFilterComponent: this.getInputFloatingFilterComponent()
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			alwaysShowHorizontalScroll: true,
			pagination: true,
			cacheBlockSize: 10,
			maxBlocksInCache: 0,
			infiniteInitialRowCount: 1,
			paginationPageSize: 10,
			paginateChildRows: true,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			localeText: this.gridHelper.getLocaleText(),
			onGridReady: (params) => this.onGridReadySituation(params)
			// onGridSizeChanged: (params) => this.onGridSizeChangedSituation(params)
		});
	}

	onGridReady(params) {
		this.gridHelper.gridApi = params.api;
		this.gridHelper.gridColumnApi = params.columnApi;
		this.getDataSourceOrgStatut();
	}

	getDataSourceOrgStatut() {
		const ctx = this;

		const dataSource: IServerSideDatasource = {
			getRows: function (paramsRows: IServerSideGetRowsParams) {
				ctx.orgStateService
					.getOrganizationState({
						page: ctx.gridHelper.gridApi.paginationGetCurrentPage() + 1,
						size: ctx.gridHelper.gridApi.paginationGetPageSize(),
						organizationNumber: ctx.organizationNumber
					})
					.subscribe(
						(results) => {
							let rowData = [];
							results.data.forEach(data=>{
								if((ctx.label==data.label && ctx.csr ==data.secondaryCriteria ) || (ctx.label==data.label && isNullOrUndefined(ctx.csr) ) ){
									rowData.push(data);
								}
							});
							let rowCount = rowData.length;
							ctx.gridHelper.manageNoRowsOverlay(rowCount);
							paramsRows.success({"rowData": rowData, "rowCount": rowCount});
						},
						() => paramsRows.fail()
					);
			}
		};
		this.gridHelper.gridApi.setServerSideDatasource(dataSource);
		this.gridHelper.gridApi.sizeColumnsToFit();
	}

	onGridReadyParamSel(params) {
		this.gridHelperParamSel.gridApi = params.api;
		this.gridHelperParamSel.gridColumnApi = params.columnApi;
		this.getDataSourceParamSel();
	}

	getDataSourceParamSel() {
		const ctx = this;
		const dataSource: IServerSideDatasource = {
			getRows: function (paramsRows: IServerSideGetRowsParams) {
				ctx.serviceDirectoryService
					.getServicedirectory({
						page: ctx.gridHelper.gridApi.paginationGetCurrentPage() + 1,
						size: ctx.gridHelper.gridApi.paginationGetPageSize(),
						organizationNumber: ctx.organizationNumber,
						service: ctx.paramSelDataRaw.service
					})
					.subscribe(
						(results) => {
							let rowData = [];
							results.data.forEach(data=>{
								if( (ctx.convType==data.agreementType  && ctx.csr ==data.secondaryCriteria ) || (ctx.label==data.label && isNullOrUndefined(ctx.csr)) ){
									rowData.push(data);
								}
							});
							let rowCount = rowData.length;
							ctx.gridHelperParamSel.manageNoRowsOverlay(rowCount);
							paramsRows.success({"rowData": rowData, "rowCount": rowCount});
						},
						() => paramsRows.fail()
					);
			}
		};
		this.gridHelperParamSel.gridApi.setServerSideDatasource(dataSource);
		this.gridHelperParamSel.gridApi.sizeColumnsToFit();
	}

	onGridReadySituation(params) {
		this.gridHelperSituation.gridApi = params.api;
		this.gridHelperSituation.gridColumnApi = params.columnApi;
		// this.gridHelper.gridApi.sizeColumnsToFit();
		this.getDataSourceConvSituation();
	}

	getDataSourceConvSituation() {
		const ctx = this;

		const dataSource: IServerSideDatasource = {
			getRows: function (paramsRows: IServerSideGetRowsParams) {
				ctx.orgStateService
					.getConventionalSituation({
						page: ctx.gridHelper.gridApi.paginationGetCurrentPage() + 1,
						size: ctx.gridHelper.gridApi.paginationGetPageSize(),
						organizationNumber: ctx.organizationNumber,
						providerNumber: ctx.providerNumber
					})
					.subscribe(
						(results) => {
							let rowData = [];
							results.data.forEach(data=>{
								if((ctx.convType==data.convType  && ctx.csr ==data.secondaryCriteria ) || (ctx.label==data.label && isNullOrUndefined(ctx.csr) )){
									rowData.push(data);
								}
							});
							let rowCount = rowData.length
							ctx.gridHelperSituation.manageNoRowsOverlay(rowCount);
							paramsRows.success({"rowData": rowData, "rowCount": rowCount});
						},
						() => paramsRows.fail()
					);
			}
		};
		this.gridHelperSituation.gridApi.setServerSideDatasource(dataSource);
		this.gridHelperSituation.gridApi.sizeColumnsToFit();
	}

	private getColumnDefs(): ColDef[] {
		return [
			{
				headerName: 'Type de convention',
				field: 'convType',
				minWidth: 180,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent()
			},
			{
				headerName: 'Critère secondaire',
				field: 'secondaryCriteria',
				minWidth: 180,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent()
			},
			{
				headerName: 'Inscrit à la plateforme',
				field: 'subscribedPlatform',
				cellRenderer: OrgStatePreviewComponent,
				minWidth: 150,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent()
			},
			{
				headerName: 'InterAMC',
				field: 'interAmc',
				cellRenderer: OrgStatePreviewComponent,
				minWidth: 150,
				maxWidth: 200,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent()
			},
			{
				headerName: 'SEL InterAMC',
				field: 'interAmcSel',
				minWidth: 150,
				maxWidth: 200,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent()
			},
			{
				headerName: '',
				field: 'options',
				minWidth: 70,
				maxWidth: 70,
				cellRenderer: OrgStatePreviewComponent,
				cellRendererParams: {
					iconClass: 'fa fa-edit',
					action: 'orgStateEdit'
				},
				onCellClicked: (params) => this.editStatutAmcOtp(params),
				cellStyle: this.gridHelper.centerContent()
			}
		];
	}

	private getColumnDefsParamSel(): ColDef[] {
		const ctx = this;

		return [
			{
				headerName: 'Type de convention',
				field: 'agreementType',
				minWidth: 180,
				lockVisible: true,
				cellStyle: ctx.gridHelper.centerContent()
			},
			{
				headerName: 'Critère secondaire',
				field: 'secondaryCriteria',
				minWidth: 180,
				lockVisible: true,
				cellStyle: ctx.gridHelper.centerContent()
			},
			{
				headerName: 'Domaine',
				field: 'agreementDomain',
				minWidth: 180,
				lockVisible: true,
				cellStyle: ctx.gridHelper.centerContent()
			},
			{
				headerName: 'Code service',
				headerClass: 'ag-header-group-parent',
				field: 'service',
				minWidth: 180,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customSelectFilterComponent',
				floatingFilterComponentParams: {
					suppressFilterButton: true,
					paramSelFilter: ctx.paramSelDataRaw,
					debounceMs: 3000
				},
				filterParams: {
					debounceMs: 3000
				},
				suppressMenu: true,
				lockVisible: true,
				cellStyle: ctx.gridHelper.centerContent()
			},
			{
				headerName: 'Norme service',
				field: 'norm',
				minWidth: 180,
				lockVisible: true,
				cellStyle: ctx.gridHelper.centerContent()
			},
			{
				headerName: 'Version de norme',
				field: 'normVersion',
				minWidth: 180,
				lockVisible: true,
				cellStyle: ctx.gridHelper.centerContent()
			},
			{
				headerName: 'Type d’adresse',
				field: 'adressType',
				minWidth: 180,
				lockVisible: true,
				cellStyle: ctx.gridHelper.centerContent()
			},
			{
				headerName: 'Url de routage',
				field: 'routingUrl',
				minWidth: 180,
				lockVisible: true,
				cellStyle: ctx.gridHelper.centerContent()
			},
			{
				headerName: 'Méthode',
				field: 'method',
				minWidth: 180,
				lockVisible: true,
				cellStyle: ctx.gridHelper.centerContent()
			},
			{
				headerName: 'Date de début',
				field: 'startDate',
				minWidth: 180,
				lockVisible: true,
				cellStyle: ctx.gridHelper.centerContent()
			},
			{
				headerName: '',
				field: 'options',
				minWidth: 50,
				maxWidth: 70,
				cellRenderer: OrgStatePreviewComponent,
				cellRendererParams: {
					iconClass: 'fa fa-edit',
					action: 'paramSelEdit'
				},
				cellStyle: ctx.gridHelper.centerContent(),
				onCellClicked: (params) => this.openParamSelModal(params)
			},
			{
				headerName: '',
				field: 'options',
				minWidth: 50,
				maxWidth: 70,
				cellRenderer: OrgStatePreviewComponent,
				cellRendererParams: {
					iconClass: 'fa fa-trash',
					action: 'paramSelDelete'
				},
				onCellClicked: (params) => this.openDeleteParamSelModal(params),
				cellStyle: this.gridHelper.centerContent()
			}
		];
	}

	private getColumnDefsSituation(): ColDef[] {
		return [
			{
				headerName: 'Identifiant de facturation PS',
				field: 'providerNumber',
				minWidth: 200,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true },
				suppressMenu: true,
				filterParams: {
					debounceMs: 3000
				}
			},
			{
				headerName: 'Type de convention',
				field: 'convType',
				minWidth: 160,
				maxWidth: 180,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent()
			},
			{
				headerName: 'Critère secondaire',
				field: 'secondaryCriteria',
				minWidth: 180,
				maxWidth: 180,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent()
			},
			{
				headerName: 'Service IDB accessible',
				field: 'idbServiceAvailable',
				cellRenderer: OrgStatePreviewComponent,
				minWidth: 160,
				maxWidth: 160,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent()
			},
			{
				headerName: 'SEL interAMC accessible',
				field: 'interAMCServiceAvailable',
				cellRenderer: OrgStatePreviewComponent,
				minWidth: 160,
				maxWidth: 180,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent()
			},
			{
				headerName: 'Motif de refus',
				field: 'koLabel',
				minWidth: 230,
				lockVisible: true,
				valueFormatter: this.formatMotif,
				cellStyle: this.gridHelper.centerContent()
			},
			{
				headerName: '',
				field: 'options',
				minWidth: 70,
				maxWidth: 70,
				cellRenderer: ConventionelPreviewComponent,
				cellRendererParams: {
					action: 'btn_relancer'
				},
				cellStyle: this.gridHelper.centerContent()
			},
			{
				headerName: '',
				field: 'warning',
				minWidth: 50,
				maxWidth: 50,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				cellRenderer: OrgStatePreviewComponent,
				cellRendererParams: {
					iconClass: 'fa fa-exclamation-circle',
					action: 'warning'
				}
			}
		];
	}

	onGridSizeChanged(params) {
		this.gridHelper.gridApi.sizeColumnsToFit();
	}

	onGridSizeChangedParamSel(params) {
		this.gridHelperParamSel.gridApi.sizeColumnsToFit();
	}

	onGridSizeChangedSituation(params) {
		this.gridHelperSituation.gridApi.sizeColumnsToFit();
	}

	public openModalConfim(toUpdateObject: any) {
		// this.showConfirmModal = true;
		// this.selectedOrgState = toUpdateObject;
	}

	public relancerTout(requestType) {
		this.orgStateService
			.createPracticeRequest({
				organizationNumber: this.organizationNumber,
				requestType: requestType
			})
			.toPromise()
			.then((res) => {
				if (res == 200) {
					this.toastr.success('Les demandes de pratique ont été effectuées avec succès.');
				} else {
					this.toastr.error('Une erreur est survenue lors de lancement des demandes de pratique.');
				}
			})
			.catch((err) => {
				this.toastr.error('Une erreur est survenue lors de lancement des demandes de pratique.');
			})
			.finally(() => {
				// refresh conventional situation list
				this.getDataSourceConvSituation();
			});
	}

	editStatutAmcOtp(params) {
		this.showEditStatutModal = true;
		if (!isNullOrUndefined(params.data)) {
			this.selectedOrgState = params.data;
			this.initStateForm();
			this.existeInParamSelValue();
		}
	}

	initStateForm() {
		this.stateForm = this.fb.group({
			convType: new UntypedFormControl(),
			secondaryCriteria: new UntypedFormControl(),
			subscribedPlatform: new UntypedFormControl(),
			interAmc: new UntypedFormControl(),
			interAmcSel: new UntypedFormControl(),
			id: null
		});
		// fill form with selectedState
		this.fillStateFormBySelectedState();
	}

	saveOrgState() {
		let interAmc =
			this.stateForm.controls['interAmc'].value == 'Oui'
				? true
				: this.stateForm.controls['interAmc'].value == 'Non'
					? false
					: this.stateForm.controls['interAmc'].value;
		let interAmcSel = this.stateForm.controls['interAmcSel'].value;
		let subscribedPlatform =
			this.stateForm.controls['subscribedPlatform'].value == 'Oui'
				? true
				: this.stateForm.controls['subscribedPlatform'].value == 'Non'
					? false
					: this.stateForm.controls['subscribedPlatform'].value;

		this.orgStateService
			.updateOrganizationState(this.selectedOrgState.id, {
				interAmc: interAmc,
				subscribedPlatform: subscribedPlatform,
				interAmcSel: interAmcSel
			})
			.toPromise()
			.then((res) => {
				if (res == 200) {
					this.toastr.success("L'état de l'AMC/OTP a été mis à jour avec succès");
					// Lancer demande de pratique
					//Avec requestType = C si subscribedPlatform passe à true.
					if (subscribedPlatform) {
						this.relancerTout('C');
					} else {
						//Avec requestType = R si subscribedPlatform passe à false.
						this.relancerTout('R');
					}
				} else {
					this.toastr.error("Une erreur est survenue lors de la modification de l'AMC/OTP");
				}
			})
			.catch((err) => {
				this.toastr.error("Une erreur est survenue lors de la modification de l'AMC/OTP");
			})
			.finally(() => {
				// refresh list of statut
				this.getDataSourceOrgStatut();
				this.selectedOrgState = new OrgStateDataRaw();
				this.closeModal();
			});
	}

	reinitialiserOrgState() {
		this.fillStateFormBySelectedState();
		this.closeModal();
	}

	fillStateFormBySelectedState() {
		this.stateForm.controls['id'].setValue(this.selectedOrgState.id);
		this.stateForm.controls['convType'].setValue(this.selectedOrgState.convType);
		this.stateForm.controls['secondaryCriteria'].setValue(this.selectedOrgState.secondaryCriteria);
		this.stateForm.controls['subscribedPlatform'].setValue(this.selectedOrgState.subscribedPlatform ? 'Oui' : 'Non');
		this.stateForm.controls['interAmc'].setValue(this.formatInterAmc(this.selectedOrgState.interAmc));
		this.stateForm.controls['interAmcSel'].setValue(this.selectedOrgState.interAmcSel);
	}

	closeModal() {
		this.showEditStatutModal = false;
		this.showParamSelModal = false;
		this.submitted = false;
		this.oldParamSel = new ParametrageSelDataRaw();
	}

	openParamSelModal(params?: any) {
		if (!isNullOrUndefined(params) && params.data.initSource == 'CAREWEB') {
			this.showParamSelModal = true;
			this.initParamSelForm();
			this.oldParamSel = params.data;
			this.fillParamSelForm(this.oldParamSel);
		} else if (isNullOrUndefined(params)) {
			this.showParamSelModal = true;
			this.oldParamSel.id = null;
			this.initParamSelForm();
		}
	}

	initParamSelForm() {
		this.paramSelForm = this.fb.group({
			service: ['IDB'],
			normVersion: ['IDB1'],
			routingUrl: new UntypedFormControl(null, [
				Validators.required,
				Validators.pattern('(http[s]?://){1}[a-zA-Z0-9.-:/?=&@%+~#-_]*')
			]),
			adressType: ['EDI'],
			agreementDomain: new UntypedFormControl(),
			norm: ['MEDAMC'],
			startDate: new UntypedFormControl(),
			initSource: ['CAREWEB'],
			agreementType: null,
			secondaryCriteria: null,
			method: null,
			id: null
		});
	}

	fillParamSelForm(paramSel) {
		this.paramSelForm.controls['service'].setValue(paramSel.service);
		this.paramSelForm.controls['normVersion'].setValue(paramSel.normVersion);
		this.paramSelForm.controls['routingUrl'].setValue(paramSel.routingUrl);
		this.paramSelForm.controls['adressType'].setValue(paramSel.adressType);
		this.paramSelForm.controls['agreementDomain'].setValue(paramSel.agreementDomain);
		this.paramSelForm.controls['norm'].setValue(paramSel.norm);
		this.paramSelForm.controls['startDate'].setValue(this.formatDate(paramSel.startDate));
		this.paramSelForm.controls['initSource'].setValue(paramSel.initSource);
		this.paramSelForm.controls['agreementType'].setValue(paramSel.agreementType);
		this.paramSelForm.controls['secondaryCriteria'].setValue(paramSel.secondaryCriteria);
		this.paramSelForm.controls['method'].setValue(paramSel.method);
	}

	saveServiceDirectory() {
		this.submitted = true;
		let service = this.paramSelForm.controls.service.value;
		let normVersion = this.paramSelForm.controls.normVersion.value;
		let routingUrl = this.paramSelForm.controls['routingUrl'].value;
		let adressType = this.paramSelForm.controls['adressType'].value;
		let agreementDomain = this.paramSelForm.controls['agreementDomain'].value;
		let norm = this.paramSelForm.controls['norm'].value;
		let startDate = null;
		if (this.paramSelForm.controls['startDate'].touched) {
			if (!isNullOrUndefined(this.paramSelForm.controls['startDate'].value)) {
				startDate = this.formatDateWS(this.paramSelForm.controls['startDate'].value);
			}
		} else if (!isNullOrUndefined(this.oldParamSel.startDate)) {
			startDate = this.oldParamSel.startDate;
		} else {
			startDate = new Date();
		}
		let initSource = this.paramSelForm.controls['initSource'].value;
		let agreementType = this.paramSelForm.controls['agreementType'].value;
		let secondaryCriteria = this.paramSelForm.controls['secondaryCriteria'].value;
		let method = this.paramSelForm.controls['method'].value;

		if (this.paramSelForm.valid) {
			// verify if is update and if the paramSel was created by CAREWEB
			if (!isNullOrUndefined(this.oldParamSel.id) && this.oldParamSel.initSource == 'CAREWEB') {
				this.serviceDirectoryService
					.updateServiceDirectory(this.oldParamSel.id, {
						// id: this.oldParamSel.id,
						organizationNumber: this.organizationNumber,
						service: service,
						normVersion: normVersion,
						routingUrl: routingUrl,
						adressType: adressType,
						agreementDomain: agreementDomain,
						norm: norm,
						startDate: startDate,
						initSource: initSource,
						agreementType: agreementType ? agreementType.toUpperCase() : null,
						secondaryCriteria: secondaryCriteria,
						method: method
					})
					.toPromise()
					.then((res) => {
						if (res == 200) {
							this.toastr.success('Le paramétrage SEL a été modifié avec succès.');
							this.closeModal();
						} else if (res == 401) {
							this.toastr.error("Une ligne existe déjà avec les mêmes critères d'accès.");
						} else {
							this.toastr.error('Une erreur est survenue lors de la modification de paramétrage SEL.');
						}
					})
					.catch((err) => {
						this.toastr.error('Une erreur est survenue lors de la modification de paramétrage SEL.');
					})
					.finally(() => {
						// refresh paramSel list
						this.getDataSourceParamSel();
					});
			} else {
				// if creation
				this.serviceDirectoryService
					.createServiceDirectory({
						organizationNumber: this.organizationNumber,
						service: service,
						normVersion: normVersion,
						routingUrl: routingUrl,
						adressType: adressType,
						agreementDomain: agreementDomain,
						norm: norm,
						startDate: startDate,
						initSource: initSource,
						agreementType: agreementType ? agreementType.toUpperCase() : null,
						secondaryCriteria: secondaryCriteria,
						method: method
					})
					.toPromise()
					.then((res) => {
						if (res == 200) {
							this.toastr.success('Le paramétrage SEL a été créée avec succès.');
							this.closeModal();
						} else if (res == 401) {
							this.toastr.error("Une ligne existe déjà avec les mêmes critères d'accès.");
						} else {
							this.toastr.error("Une erreur est survenue lors de l'ajout de paramétrage SEL.");
						}
					})
					.catch((err) => {
						this.toastr.error("Une erreur est survenue lors de l'ajout de paramétrage SEL.");
					})
					.finally(() => {
						// refresh paramSel list
						this.getDataSourceParamSel();
					});
			}
		}
	}

	reinitialiserParamSel() {
		this.submitted = false;
		if (!isNullOrUndefined(this.oldParamSel.id)) {
			// Modification
			this.fillParamSelForm(this.oldParamSel);
		} else {
			// Creation
			this.paramSelForm.controls['service'].setValue('IDB');
			this.paramSelForm.controls['normVersion'].setValue('IDB1');
			this.paramSelForm.controls['routingUrl'].setValue(null);
			this.paramSelForm.controls['adressType'].setValue('EDI');
			this.paramSelForm.controls['agreementDomain'].setValue(null);
			this.paramSelForm.controls['norm'].setValue('MEDAMC');
			this.paramSelForm.controls['startDate'].setValue(null);
			this.paramSelForm.controls['initSource'].setValue('CAREWEB');
			this.paramSelForm.controls['agreementType'].setValue(null);
			this.paramSelForm.controls['secondaryCriteria'].setValue(null);
		}
		this.closeModal();
	}

	get paramselFormFields() {
		return this.paramSelForm.controls;
	}

	existeInParamSelValue() {
		this.serviceDirectoryService
			.getServicedirectory({
				page: 1,
				size: 100,
				organizationNumber: this.organizationNumber,
				service: 'PRA'
			})
			.subscribe((results) => {
				let rowCount = results.paging.totalElements;
				if (rowCount > 0) {
					return (this.existeInParamSel = true);
				} else {
					return (this.existeInParamSel = false);
				}
			});
	}

	public showModal(showModal: boolean) {
		this.showConfirmModal = showModal;
	}

	openDeleteParamSelModal(params) {
		if (!isNullOrUndefined(params.data) && params.data.initSource == 'CAREWEB') {
			this.showConfirmModal = true;
			this.modalMessage = 'Voulez-vous supprimer ce paramétrage SEL ?';
			this.selectedParamSel = params.data;
		}
	}

	deleteParamSel() {
		if (!isNullOrUndefined(this.selectedParamSel && this.selectedParamSel.initSource == 'CAREWEB')) {
			this.serviceDirectoryService
				.deleteServiceDirectory(this.selectedParamSel.id)
				.toPromise()
				.then((res) => {
					if (res == 200) {
						this.toastr.success('Le paramétrage SEL a été supprimé avec succès.');
					} else {
						this.toastr.error('Une erreur est survenue lors de la suppression du paramétrage SEL.');
					}
				})
				.catch((err) => {
					this.toastr.error('Une erreur est survenue lors de la suppression du paramétrage SEL.');
				})
				.finally(() => {
					// refresh paramSel list
					this.getDataSourceParamSel();
					this.showModal(false);
				});
		}
	}

	codeServiceChange($event) {
		if ($event == 'IDB') {
			this.paramSelForm.controls.normVersion.setValue('IDB1');
			this.paramSelForm.controls.adressType.setValue('EDI');
			this.paramSelForm.controls.norm.setValue('MEDAMC');
		} else if ($event == 'CLC') {
			this.paramSelForm.controls.normVersion.setValue('CLC1');
			this.paramSelForm.controls.adressType.setValue('EDI');
			this.paramSelForm.controls.norm.setValue('MEDAMC');
		} else if ($event == 'PRA') {
			this.paramSelForm.controls.normVersion.setValue('PRA1');
			this.paramSelForm.controls.adressType.setValue(null);
			this.paramSelForm.controls.norm.setValue(null);
		}
	}

	goBack() {
		this.router.navigate(['/organization-list']);
		//this.location.back();
	}

	formatMotif = (params): string => {
		if (
			!isNullOrUndefined(params.data) &&
			params.data.idbServiceAvailable === false &&
			params.data.interAMCServiceAvailable === false
		) {
			return params.value;
		} else {
			return '';
		}
	};

	formatInterAmc = (interAmc): string => {
		if (!isNullOrUndefined(interAmc)) {
			if (interAmc) {
				return 'Oui';
			} else {
				return 'Non';
			}
		} else {
			return interAmc;
		}
	};

	private setLocale() {
		this.localeService.use('fr');
	}

	formatDate(date: Date) {
		return formatDate(date, 'dd/MM/yyyy', 'fr-FR');
	}

	formatDateWS(date: Date) {
		return formatDate(date, 'yyyy-MM-dd', 'fr-FR');
	}

	getSelectFloatingFilterComponent() {
		const ctx = this;
		function SelectFloatingFilter() { }

		SelectFloatingFilter.prototype.init = function (params) {
			this.eGui = document.createElement('div');
			this.eGui.style = 'height: 60px;';
			this.eGui.innerHTML =
				'<select style="width:100%; height: 50%;"/><option value="null"></option><option>IDB</option><option>CLC</option><option>PRA</option></select>';
			this.currentValue = null;
			this.eFilterInput = this.eGui.querySelector('select');
			const that = this;
			function onSelectChanged() {
				if (that.eFilterInput.value === '') {
					// Remove the filter
					params.parentFilterInstance(function (instance) {
						instance.onFloatingFilterChanged(null, null);
					});
					return;
				}

				that.currentValue = that.eFilterInput.value;
				const filterField = params.filterParams.colDef.field;
				if (that.currentValue !== 'null') {
					ctx.paramSelDataRaw[filterField] = that.eFilterInput.value;
				} else {
					delete ctx.paramSelDataRaw[filterField];
				}
				params.parentFilterInstance(function (instance) {
					instance.onFloatingFilterChanged('equals', that.currentValue);
				});
			}
			this.eFilterInput.addEventListener('change', onSelectChanged);
		};

		SelectFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
			if (!parentModel) {
				this.eFilterInput.value = '';
				this.currentValue = null;
			} else {
				this.eFilterInput.value = parentModel.filter + '';
				this.currentValue = parentModel.filter;
			}
		};

		SelectFloatingFilter.prototype.getGui = function () {
			return this.eGui;
		};

		return SelectFloatingFilter;
	}

	getInputFloatingFilterComponent() {
		const ctx = this;
		function InputFloatingFilter() { }

		InputFloatingFilter.prototype.init = function (params) {
			this.eGui = document.createElement('div');
			this.eGui.setAttribute('class', 'ag-input-wrapper');
			this.eGui.style = 'height: 100%; margin-bottom: 18px;';
			this.eGui.innerHTML =
				"<input type='text' class='customFilterInputCIS' onKeyPress='if(this.value.length==10) return false;' onpaste='if(this.value.length==10 ) return false;'  pattern='^[0-9]'/>";
			this.currentValue = null;
			this.eFilterInput = this.eGui.querySelector('input');
			const that = this;
			function onInputChanged() {
				that.currentValue = that.eFilterInput.value;
				if (that.eFilterInput.value === '') {
					// Remove the filter
					delete ctx.providerNumber;
					params.parentFilterInstance(function (instance) {
						instance.onFloatingFilterChanged(null, null);
					});
					return;
				}

				if (that.eFilterInput.value.length >= 9) {
					if (that.currentValue !== '') {
						ctx.providerNumber = that.eFilterInput.value;
					}
					params.parentFilterInstance(function (instance) {
						debounceTime(5000);
						instance.onFloatingFilterChanged('equals', that.currentValue);
					});
				}
			}
			this.eFilterInput.addEventListener('input', onInputChanged);
		};

		InputFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
			if (!parentModel) {
				this.eFilterInput.value = '';
				this.currentValue = null;
			} else {
				this.eFilterInput.value = parentModel.filter + '';
				this.currentValue = parentModel.filter;
			}
		};

		InputFloatingFilter.prototype.getGui = function () {
			return this.eGui;
		};

		return InputFloatingFilter;
	}
}
