import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CONF } from '../../core/constants';
import { catchError } from 'rxjs/operators';
import { HotMotifsDataRaw } from '../../data/hotMotifsDataRaw';
@Injectable({
	providedIn: 'root'
})
export class HotMotifsService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/hotline/motifs';

	constructor(private httpClient: HttpClient) {}

	getHotlineList(): Observable<any> {
		return this.httpClient.get<any>(this.baseUrl + '/list');
	}

	getMotifsListPageable(criteria: any): Observable<any> {
		return this.httpClient.get<any>(
			this.baseUrl +
				'/pageable-list?page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				this.getSortParameters(criteria.sortsMotif)
		);
	}
	saveHotMotif(hotMotifsDataRaw: HotMotifsDataRaw): Observable<any> {
		return this.httpClient.post<any>(this.baseUrl + '/create', hotMotifsDataRaw).pipe(catchError(this.handleError));
	}

	deleteHotMotif(idType: number): Promise<any> {
		return this.httpClient.delete(`${this.baseUrl}/${idType}`).toPromise();
	}

	handleError(error: HttpErrorResponse) {
		return throwError(error);
	}
	getSortParameters(sorts) {
		if (sorts.length === 1) {
			return '&sort=libelle&direction=' + sorts[0].direction;
		} else {
			return '&sort=libelle&direction=ASC';
		}
	}
}
