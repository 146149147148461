import {Component, OnInit, OnDestroy} from '@angular/core';
import { HotlineFilterRaw } from '../../../data/filters/hotlineFilterRaw';
import { AgGridHelper } from '../../../components/ag-grid/ag-grid-helper';
import { HotlinePreviewComponent } from '../hotline-subcomposant/hotline-preview.component';
import { ColDef, IServerSideGetRowsParams, IServerSideDatasource } from 'ag-grid-enterprise';
import { HotTypeService } from '../hotType.service';
import { gridConvertSort } from '../../../components/ag-grid/ag-grid.utils';
import { OctService } from '../../../core/services/oct.service';
import { HotResolutionService } from '../hotResolution.service';
import { HotMotifsService } from '../hotMotifs.service';
import { HotTypeDataRaw } from '../../../data/hotTypeDataRaw';
import { ToastrService } from 'ngx-toastr';
import { HotResolutionDataRaw } from '../../../data/hotResolutionDataRaw';
import { HotMotifsDataRaw } from '../../../data/hotMotifsDataRaw';
import { isNullOrUndefined } from 'util';
import { InputValidators } from '../../../components/validators/InputValidators';
import { HotGroupUsersDataRaw } from '../../../data/hotGroupUsersDataRaw';
import { GroupUsersService } from '../../users/groupUsers.service';

@Component({
	selector: 'careweb-app-hotline-parameters',
	templateUrl: './hotline-parameters.component.html',
	styleUrls: ['./hotline-parameters.component.scss']
})
export class HotlineParametersComponent implements OnInit, OnDestroy {
	hotlineTypeList = [];
	hotResolutionList = {};
	hotMotifsList = {};
	groupUsersList = {};
	currentOct;
	params: any;
	gridHelperHotType: AgGridHelper;
	gridHelperHotResolution: AgGridHelper;
	gridHelperHotMotifs: AgGridHelper;
	paginationSize: number;
	filters = new HotlineFilterRaw();
	isDesableValidedButton = true;
	showAddTypetModal = false;
	showDeleteTypeModal = false;
	showAddMotifModal = false;
	showDeleteMotifModal = false;
	showDeleteResolutionModal = false;
	showAddResolutiontModal = false;
	type = new HotTypeDataRaw();
	oldType: any;
	motif = new HotMotifsDataRaw();
	oldMotif: any;
	resolution = new HotResolutionDataRaw();
	oldResolution: any;
	sorts;
	ckeckStatus = 1;
	messageEmptyField = false;
	btnName = 'Ajouter';
	currentMotif = new HotMotifsDataRaw();
	isModifyMotif = false;
	isModifyType = false;
	isModifyResolution = false;
	isModifyService = false;
	showAddGroupUserstModal = false;
	showDeleteGroupUsersModal = false;
	groupe = new HotGroupUsersDataRaw();
	oldGroupe: any;
	gridHelperGroupUsers: AgGridHelper;
	active = 1;
	constructor(
		private hotTypeSvc: HotTypeService,
		private hotResolutionSvc: HotResolutionService,
		private hotMotifsService: HotMotifsService,
		private octSvc: OctService,
		private toastr: ToastrService,
		private groupUsersSvc: GroupUsersService,
		private inputValidators: InputValidators
	) {
		this.gridHelperHotType = new AgGridHelper();
		this.gridHelperHotResolution = new AgGridHelper();
		this.gridHelperHotMotifs = new AgGridHelper();
		this.gridHelperGroupUsers = new AgGridHelper();
	}

	ngOnInit() {
		this.octSvc.currentOctSubject.subscribe((value) => {
			this.currentOct = value;
		});

		this.starterHotType();
		this.starterHotResolution();
		this.starterHotMotifs();
		this.starterGroupUsers();
		this.executeOnDestroyWhenBrowserRefresh();
	}
	/*** HotType */
	starterHotType() {
		this.type.idType = null;
		this.gridHelperHotType.gridOptions = {
			columnDefs: this.getColumnDefsHotType(),
			defaultColDef: {
				resizable: true,
				suppressMenu: true
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			//suppressMenuHide : false,
			pagination: true,
			cacheBlockSize: 10,
			maxBlocksInCache: 0,
			infiniteInitialRowCount: 1,
			paginationPageSize: 10,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			localeText: this.gridHelperHotType.getLocaleText(),
			onGridReady: (params) => this.onGridReadyHotType(params)
		};
	}

	onGridReadyHotType(params) {
		const ctx = this;
		this.gridHelperHotType.gridApi = params.api;
		this.gridHelperHotType.gridColumnApi = params.columnApi;
		this.restoreGrid(this.gridHelperHotType);
		const dataSource: IServerSideDatasource = {
			getRows: function (paramsRows: IServerSideGetRowsParams) {
				ctx.sorts = gridConvertSort(ctx.gridHelperHotType.gridSortModel(), []);
				const sortsType = ctx.sorts;
				const criteria = {
					page: ctx.gridHelperHotType.gridApi.paginationGetCurrentPage(),
					size: ctx.gridHelperHotType.gridApi.paginationGetPageSize(),
					idOct: ctx.currentOct ? ctx.currentOct.idOct : '',
					sortsType
				};
				ctx.hotTypeSvc.getHotlineTypeList(criteria).subscribe(
					(data) => {
						const rowCount = data.totalElements;
						ctx.gridHelperHotType.manageNoRowsOverlay(rowCount);
						paramsRows.success({"rowData": data.content, "rowCount": rowCount});
					},
					() => paramsRows.fail()
				);
			}
		};
		this.gridHelperHotType.gridApi.setServerSideDatasource(dataSource);
	}

	restoreGrid(gridHelper: AgGridHelper) {
		//gridHelper.restoreGridStatePrefix();
		this.paginationSize = gridHelper.paginationPageSize;
	}

	private getColumnDefsHotType(): ColDef[] {
		return [
			{
				headerName: 'Libellé',
				headerClass: 'text-right',
				field: 'libelle',
				minWidth: 300,
				lockVisible: true,
				sortable: true,
				flex: 1
			},
			{
				headerName: '',
				field: 'optionsModifierType',
				minWidth: 50,
				cellRenderer: HotlinePreviewComponent,
				onCellClicked: (params) => this.onCellClickedHotType(params),
				lockVisible: true,
				cellStyle: this.gridHelperHotType.centerContent(),
				cellRendererParams: {
					type: 'type'
				}},
				{
					headerName: '',
					field: 'optionsRemove',
					minWidth: 50,
					cellRenderer: HotlinePreviewComponent,
					onCellClicked: (params) => this.onCellClickedDeleteHotType(params),
					lockVisible: true,
					cellStyle: this.gridHelperHotType.centerContent(),
					cellRendererParams: {
						type: 'type'
					}
				}
		];
	}

	onCellClickedHotType(params) {
		this.btnName = 'Modifier';
		this.type = params.data;
		this.oldType = new HotTypeDataRaw();
		this.oldType.idType = params.data.idType;
		this.oldType.libelle = params.data.libelle;
		this.isModifyType = true;
		this.showAddTypetModal = true;
	}

	onCellClickedDeleteHotType(params) {
		this.btnName = 'Valider';
		this.type = params.data;
		this.showDeleteTypeModal = true;
	}

	saveType() {
		// Verifier si le champ est vide
		if (
			isNullOrUndefined(this.type.libelle) ||
			this.type.libelle.length === 0 ||
			!this.inputValidators.inputWithSpacesNgModel(this.type.libelle)
		) {
			// Afficher le message d erreur 'Veuillez remplir tous les champs obligatoires *'
			this.messageEmptyField = true;
		} else {
			this.messageEmptyField = false;
			this.hotTypeSvc.getHotTypeList().subscribe(
				(data) => {
					let itemsProcessed = 0;
					data.forEach((element) => {
						itemsProcessed++;

						// Verifier si la valeur renseignée exite deja dans la table
						if (element.libelle.toLowerCase().trim() === this.type.libelle.toLowerCase().trim()) {
							// la valeur renseignée exite deja dans la table, donc le ckeckStatus egale 3
							this.ckeckStatus = 3;
						}

						if (itemsProcessed === data.length && this.ckeckStatus === 1) {
							this.refreshTableAfterAddType();
						} else if (itemsProcessed === data.length) {
							this.toastr.error('Type déjà existant');
						}
					});
				},
				(err) => this.showErrorMessage()
			);

			this.ckeckStatus = 1;
		}
	}

	refreshTableAfterAddType() {
		// Enregistrement du type Hotline
		this.hotTypeSvc.saveHotline(this.type).subscribe((data) => {
			this.showAddTypetModal = false;
			const ctx = this;

			// Reinsialisation du tableau avec les nouvelles  valeurs
			this.restoreGrid(this.gridHelperHotType);
			const dataSource: IServerSideDatasource = {
				getRows: function (paramsRows: IServerSideGetRowsParams) {
					const sortsType = gridConvertSort(ctx.gridHelperHotType.gridSortModel(), []);
					const criteria = {
						page: ctx.gridHelperHotType.gridApi.paginationGetCurrentPage(),
						size: ctx.gridHelperHotType.gridApi.paginationGetPageSize(),
						idOct: ctx.currentOct ? ctx.currentOct.idOct : '',
						sortsType
					};
					ctx.hotTypeSvc.getHotlineTypeList(criteria).subscribe(
						(data) => {
							if (isNullOrUndefined(ctx.type.libelle) === false) {
								if (isNullOrUndefined(ctx.type.idType)) {
									ctx.toastr.success("Le type d'intervention a été ajouté avec succès");
								} else {
									ctx.toastr.success("Le type d'intervention a été mis à jour avec succès");
								}
							}
							ctx.type = new HotTypeDataRaw();
							const rowCount = data.totalElements;
							ctx.gridHelperHotType.manageNoRowsOverlay(rowCount);
							paramsRows.success({"rowData": data.content, "rowCount": rowCount});
						},
						() => paramsRows.fail()
					);
				}
			};
			this.gridHelperHotType.gridApi.setServerSideDatasource(dataSource);
		});
	}

	deleteType() {
		this.hotTypeSvc.deleteHotline(Number(this.type.idType)).then(
			(ret) => {
				this.showDeleteTypeModal = false;
				const ctx = this;
				this.restoreGrid(this.gridHelperHotType);
				const dataSource: IServerSideDatasource = {
					getRows: function (paramsRows: IServerSideGetRowsParams) {
						const sortsType = gridConvertSort(ctx.gridHelperHotType.gridSortModel(), []);
						const criteria = {
							page: ctx.gridHelperHotType.gridApi.paginationGetCurrentPage(),
							size: ctx.gridHelperHotType.gridApi.paginationGetPageSize(),
							idOct: ctx.currentOct ? ctx.currentOct.idOct : '',
							sortsType
						};
						ctx.hotTypeSvc.getHotlineTypeList(criteria).subscribe(
							(data) => {
								if (!isNullOrUndefined(ctx.type.libelle)) {
									ctx.toastr.success("Le type d'intervention a été supprimé avec succès");
								}
								ctx.type = new HotTypeDataRaw();
								const rowCount = data.totalElements;
								paramsRows.success({"rowData": data.content, "rowCount": rowCount});
							},
							() => paramsRows.fail()
							
						);
					}
				};
				this.gridHelperHotType.gridApi.setServerSideDatasource(dataSource);
			},
			(err) => {
				this.closeModal();
				this.toastr.error("Le type d'intervention ne peut pas être supprimé");
			}
		);
	}
	public openModalAddType() {
		this.btnName = 'Ajouter';
		this.type = new HotTypeDataRaw();
		this.showAddTypetModal = true;
	}

	refresh() {
		if (!this.isModifyService) {
			this.groupe = new HotGroupUsersDataRaw();
		} else {
			this.groupe.libelleLong = this.oldGroupe.libelleLong;
		}
		this.closeModal();
	}

	closeModal() {
		this.showAddTypetModal = false;
		this.showDeleteTypeModal = false;
		this.showAddMotifModal = false;
		this.showDeleteMotifModal = false;
		this.showDeleteResolutionModal = false;
		this.showAddResolutiontModal = false;
		this.showAddGroupUserstModal = false;
		this.showDeleteGroupUsersModal = false;
		this.type = new HotTypeDataRaw();
		this.motif = new HotMotifsDataRaw();
		this.resolution = new HotResolutionDataRaw();
		this.groupe = new HotGroupUsersDataRaw();
		this.messageEmptyField = false;
		//this.modalRef.hide();
	}

	/**** HotResolutions*/

	starterHotResolution() {
		this.gridHelperHotResolution.gridOptions = {
			columnDefs: this.getColumnDefsHotResolution(),
			defaultColDef: {
				resizable: true,
				suppressMenu: true
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 10,
			maxBlocksInCache: 0,
			infiniteInitialRowCount: 1,
			paginationPageSize: 10,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			localeText: this.gridHelperHotResolution.getLocaleText(),
			onGridReady: (params) => this.onGridReadyHotResolution(params)
		};
	}

	onGridHotResolutionSizeChanged(params) {
		this.gridHelperHotResolution.gridApi.sizeColumnsToFit();
	}

	onGridReadyHotResolution(params) {
		const ctx = this;
		this.gridHelperHotResolution.gridApi = params.api;
		this.gridHelperHotResolution.gridColumnApi = params.columnApi;
		this.restoreGrid(this.gridHelperHotResolution);
		const dataSource: IServerSideDatasource = {
			getRows: function (paramsRows: IServerSideGetRowsParams) {
				ctx.sorts = gridConvertSort(ctx.gridHelperHotResolution.gridSortModel(), []);
				const sortsResolution = ctx.sorts;
				const criteria = {
					page: ctx.gridHelperHotResolution.gridApi.paginationGetCurrentPage(),
					size: ctx.gridHelperHotResolution.gridApi.paginationGetPageSize(),
					idOct: ctx.currentOct ? ctx.currentOct.idOct : '',
					sortsResolution
				};
				ctx.hotResolutionSvc.getResolutionListPageable(criteria).subscribe(
					(data) => {
						const rowCount = data.totalElements;
						ctx.gridHelperHotResolution.manageNoRowsOverlay(rowCount);
						paramsRows.success({"rowData": data.content, "rowCount": rowCount});
					},
					() => paramsRows.fail()
				);
			}
		};
		this.gridHelperHotResolution.gridApi.setServerSideDatasource(dataSource);
	}

	private getColumnDefsHotResolution(): ColDef[] {
		return [
			{
				headerName: 'Libellé',
				field: 'libelle',
				minWidth: 300,
				lockVisible: true,
				sortable: true,
				flex: 1
			},
			{
				headerName: '',
				field: 'optionsModifierResolution',
				minWidth: 50,
				headerClass: 'ag-header-merge',
				cellRenderer: HotlinePreviewComponent,
				onCellClicked: (params) => this.onCellClickedHotResolution(params),
				lockVisible: true,
				cellStyle: this.gridHelperHotResolution.centerContent(),
				cellRendererParams: {
					type: 'resolution'
				}
			}
		];
	}

	onCellClickedHotResolution(params) {
		this.btnName = 'Modifier';
		this.resolution = params.data;
		this.oldResolution = new HotResolutionDataRaw();
		this.oldResolution.idResolution = params.data.idResolution;
		this.oldResolution.libelle = params.data.libelle;
		this.isModifyResolution = true;
		this.showAddResolutiontModal = true;
	}

	onCellClickedDeleteHotResolution(params) {
		this.btnName = 'Valider';
		this.resolution = params.data;
		this.showDeleteResolutionModal = true;
	}

	saveResolution() {
		// Verifier si le champ est vide
		if (
			isNullOrUndefined(this.resolution.libelle) ||
			this.resolution.libelle.length === 0 ||
			!this.inputValidators.inputWithSpacesNgModel(this.resolution.libelle)
		) {
			// Afficher le message d erreur 'Veuillez remplir tous les champs obligatoires *'
			this.messageEmptyField = true;
		} else {
			this.messageEmptyField = false;
			this.hotResolutionSvc.getResolutionList().subscribe((data) => {
				let itemsProcessed = 0;
				data.forEach((element) => {
					itemsProcessed++;

					// Verifier si la valeur renseignée exite deja dans la table
					if (element.libelle.toLowerCase().trim() === this.resolution.libelle.toLowerCase().trim()) {
						// la valeur renseignée exite deja dans la table, donc le ckeckStatus egale 3
						this.ckeckStatus = 3;
					}
					if (itemsProcessed === data.length && this.ckeckStatus === 1) {
						this.refreshTableAfterAddResolution();
					} else if (itemsProcessed === data.length) {
						this.toastr.error('Résolution déjà existante');
					}
				});
			});

			this.ckeckStatus = 1;
		}
	}

	refreshTableAfterAddResolution() {
		// Enregistrement du resolution Hotline
		this.hotResolutionSvc.saveHotResolution(this.resolution).subscribe((data) => {
			this.showAddResolutiontModal = false;
			const ctx = this;

			// Reinsialisation du tableau avec les nouvelles valeurs
			this.restoreGrid(this.gridHelperHotResolution);
			const dataSource: IServerSideDatasource = {
				getRows: function (paramsRows: IServerSideGetRowsParams) {
					const sortsResolution = gridConvertSort(ctx.gridHelperHotResolution.gridSortModel(), []);
					const criteria = {
						page: ctx.gridHelperHotResolution.gridApi.paginationGetCurrentPage(),
						size: ctx.gridHelperHotResolution.gridApi.paginationGetPageSize(),
						idOct: ctx.currentOct ? ctx.currentOct.idOct : '',
						sortsResolution
					};
					ctx.hotResolutionSvc.getResolutionListPageable(criteria).subscribe(
						(data) => {
							if (isNullOrUndefined(ctx.resolution.libelle) === false) {
								if (isNullOrUndefined(ctx.resolution.idResolution)) {
									ctx.toastr.success('La résolution a été ajoutée avec succès');
								} else {
									ctx.toastr.success('La résolution a été mise à jour avec succès');
								}
							}

							ctx.resolution = new HotResolutionDataRaw();
							const rowCount = data.totalElements;
							ctx.gridHelperHotResolution.manageNoRowsOverlay(rowCount);
							paramsRows.success({"rowData": data.content, "rowCount": rowCount});
						},
						() => paramsRows.fail()
					);
				}
			};
			this.gridHelperHotResolution.gridApi.setServerSideDatasource(dataSource);
		});
	}

	deleteResolution() {
		this.hotResolutionSvc.deleteHotResolution(Number(this.resolution.idResolution)).then(
			(ret) => {
				this.showDeleteResolutionModal = false;
				const ctx = this;
				this.restoreGrid(this.gridHelperHotResolution);
				//this.gridHelperHotResolution.gridApi.sizeColumnsToFit();
				const dataSource: IServerSideDatasource = {
					getRows: function (paramsRows: IServerSideGetRowsParams) {
						const sortsResolution = gridConvertSort(ctx.gridHelperHotResolution.gridSortModel(), []);
						const criteria = {
							page: ctx.gridHelperHotResolution.gridApi.paginationGetCurrentPage(),
							size: ctx.gridHelperHotResolution.gridApi.paginationGetPageSize(),
							idOct: ctx.currentOct ? ctx.currentOct.idOct : '',
							sortsResolution
						};
						ctx.hotResolutionSvc.getResolutionListPageable(criteria).subscribe(
							(data) => {
								if (isNullOrUndefined(ctx.resolution.libelle) === false) {
									ctx.toastr.success('La résolution a été supprimée avec succès');
								}
								ctx.resolution = new HotResolutionDataRaw();
								const rowCount = data.totalElements;
								ctx.gridHelperHotResolution.manageNoRowsOverlay(rowCount);
								paramsRows.success({"rowData": data.content, "rowCount": rowCount});
							},
							() => paramsRows.fail()
						);
					}
				};
				this.gridHelperHotResolution.gridApi.setServerSideDatasource(dataSource);
			},
			(err) => {
				this.closeModal();
				this.toastr.error("Une erreur s'est produite lors de la suppression de la résolution");
			}
		);
	}

	public openModalAddResolution() {
		this.btnName = 'Ajouter';
		this.resolution = new HotResolutionDataRaw();
		this.showAddResolutiontModal = true;
	}

	starterHotMotifs() {
		this.gridHelperHotMotifs.gridOptions = {
			columnDefs: this.getColumnDefsHotMotifs(),
			defaultColDef: {
				resizable: true,
				suppressMenu: true
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 10,
			maxBlocksInCache: 0,
			infiniteInitialRowCount: 1,
			paginationPageSize: 10,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			localeText: this.gridHelperHotMotifs.getLocaleText(),
			onGridReady: (params) =>  {
				this.onGridReadyHotMotifs(params)}
		};
	}

	onGridReadyHotMotifs(params) {
		const ctx = this;
		this.gridHelperHotMotifs.gridApi = params.api;
		this.gridHelperHotMotifs.gridColumnApi = params.columnApi;
		this.restoreGrid(this.gridHelperHotMotifs);

		const dataSource: IServerSideDatasource = {
			getRows: function (paramsRows: IServerSideGetRowsParams) {
				ctx.sorts = gridConvertSort(ctx.gridHelperHotMotifs.gridSortModel(), []);
				const sortsMotif = ctx.sorts;
				const criteria = {
					page: ctx.gridHelperHotMotifs.gridApi.paginationGetCurrentPage(),
					size: ctx.gridHelperHotMotifs.gridApi.paginationGetPageSize(),
					idOct: ctx.currentOct ? ctx.currentOct.idOct : '',
					sortsMotif
				};
				ctx.hotMotifsService.getMotifsListPageable(criteria).subscribe(
					(data) => {
						const rowCount = data.totalElements;
						ctx.gridHelperHotMotifs.manageNoRowsOverlay(rowCount);
						paramsRows.success({"rowData": data.content, "rowCount": rowCount});
					},
					() => paramsRows.fail()
				);
			}
		};
		this.gridHelperHotMotifs.gridApi.setServerSideDatasource(dataSource);
		//this.gridHelperHotMotifs.gridApi.sizeColumnsToFit();
	}

	private getColumnDefsHotMotifs(): ColDef[] {
		return [
			{
				headerName: 'Libellé',
				field: 'libelle',
				minWidth: 300,
				lockVisible: true,
				sortable: true,
				flex: 1
			},
			{
				headerName: '',
				field: 'optionsModifierMotifs',
				maxWidth: 50,
				cellRenderer: HotlinePreviewComponent,
				onCellClicked: (params) => this.onCellClickedEditHotMotif(params),
				lockVisible: true,
				cellStyle: this.gridHelperHotMotifs.centerContent(),
				cellRendererParams: {
					type: 'motif'
				}
			},{
				headerName: '',
				field: 'optionsRemove',
				maxWidth: 50,
				cellRenderer: HotlinePreviewComponent,
				onCellClicked: (params) => this.onCellClickedDeleteHotMotif(params),
				lockVisible: true,
				cellStyle: this.gridHelperHotMotifs.centerContent(),
				cellRendererParams: {
					type: 'motif'
				}
			}
		];
	}

	onCellClickedEditHotMotif(params) {
		this.isModifyMotif = true;
		this.btnName = 'Modifier';
		this.motif = params.data;
		this.oldMotif = new HotMotifsDataRaw();
		this.oldMotif.idMotifs = params.data.idMotifs;
		this.oldMotif.libelle = params.data.libelle;
		this.saveHotMotifParameter(params.data);
		this.showAddMotifModal = true;
	}

	onCellClickedDeleteHotMotif(params) {
		this.btnName = 'Valider';
		this.motif = params.data;
		this.showDeleteMotifModal = true;
	}

	saveMotif() {
		// Verifier si le champ est vide
		if (
			isNullOrUndefined(this.motif.libelle) ||
			this.motif.libelle.length === 0 ||
			!this.inputValidators.inputWithSpacesNgModel(this.motif.libelle)
		) {
			// Afficher le message d erreur 'Veuillez remplir tous les champs obligatoires *'
			this.messageEmptyField = true;
		} else {
			this.messageEmptyField = false;
			this.hotMotifsService.getHotlineList().subscribe((data) => {
				let itemsProcessed = 0;
				data.forEach((element) => {
					itemsProcessed++;

					// Verifier si la valeur renseignée exite deja dans la table
					if (element.libelle.toLowerCase().trim() === this.motif.libelle.toLowerCase().trim()) {
						// la valeur renseignée exite deja dans la table, donc le ckeckStatus egale 3
						this.ckeckStatus = 3;
					}

					if (itemsProcessed === data.length && this.ckeckStatus === 1) {
						this.refreshTableAfterAddMotif();
					} else if (itemsProcessed === data.length) {
						this.toastr.error('motif déjà existant');
					}
				});
			});

			this.ckeckStatus = 1;
		}
	}

	refreshTableAfterAddMotif() {
		// Enregistrement du motif Hotline
		this.hotMotifsService.saveHotMotif(this.motif).subscribe((data) => {
			this.showAddMotifModal = false;

			const ctx = this;
			// Reinsialisation du tableau avec les nouvelles  valeurs
			this.restoreGrid(this.gridHelperHotMotifs);
			const dataSource: IServerSideDatasource = {
				getRows: function (paramsRows: IServerSideGetRowsParams) {
					const sortsMotif = gridConvertSort(ctx.gridHelperHotMotifs.gridSortModel(), []);
					const criteria = {
						page: ctx.gridHelperHotMotifs.gridApi.paginationGetCurrentPage(),
						size: ctx.gridHelperHotMotifs.gridApi.paginationGetPageSize(),
						idOct: ctx.currentOct ? ctx.currentOct.idOct : '',
						sortsMotif
					};

					ctx.hotMotifsService.getMotifsListPageable(criteria).subscribe(
						(data) => {
							if (isNullOrUndefined(ctx.motif.libelle) === false) {
								if (isNullOrUndefined(ctx.motif.idMotifs)) {
									ctx.toastr.success("Le motif d'intervention a été ajouté avec succès");
								} else {
									ctx.toastr.success("Le motif d'intervention a été mis à jour avec succès");
								}
							}
							ctx.motif = new HotMotifsDataRaw();
							const rowCount = data.totalElements;
							ctx.gridHelperHotMotifs.manageNoRowsOverlay(rowCount);
							paramsRows.success({"rowData": data.content, "rowCount": rowCount});
						},
						() => paramsRows.fail()
					);
				}
			};
			this.gridHelperHotMotifs.gridApi.setServerSideDatasource(dataSource);
		});
	}

	public openModalAddMotif() {
		this.btnName = 'Ajouter';
		this.motif = new HotMotifsDataRaw();
		this.showAddMotifModal = true;
	}

	deleteModif() {
		this.hotMotifsService.deleteHotMotif(Number(this.motif.idMotifs)).then(
			(ret) => {
				this.showDeleteMotifModal = false;
				const ctx = this;
				this.restoreGrid(this.gridHelperHotMotifs);
				const dataSource: IServerSideDatasource = {
					getRows: function (paramsRows: IServerSideGetRowsParams) {
						const sortsMotif = gridConvertSort(ctx.gridHelperHotMotifs.gridSortModel(), []);
						const criteria = {
							page: ctx.gridHelperHotMotifs.gridApi.paginationGetCurrentPage(),
							size: ctx.gridHelperHotMotifs.gridApi.paginationGetPageSize(),
							idOct: ctx.currentOct ? ctx.currentOct.idOct : '',
							sortsMotif
						};
						ctx.hotMotifsService.getMotifsListPageable(criteria).subscribe(
							(data) => {
								if (isNullOrUndefined(ctx.motif.libelle) === false) {
									ctx.toastr.success("Le motif d'intervention a été supprimé avec succès");
								}
								ctx.motif = new HotMotifsDataRaw();
								const rowCount = data.totalElements;
								ctx.gridHelperHotMotifs.manageNoRowsOverlay(rowCount);
								paramsRows.success({"rowData": data.content, "rowCount": rowCount});
							},
							() => paramsRows.fail()
						);
					}
				};
				this.gridHelperHotMotifs.gridApi.setServerSideDatasource(dataSource);
			},
			(err) => {
				this.closeModal();
				this.toastr.error("Le motif d'intervention ne peut pas être supprimé");
			}
		);
	}

	refreshIntervention() {
		if (!this.isModifyMotif) {
			this.motif = new HotMotifsDataRaw();
		} else {
			this.motif.libelle = this.oldMotif.libelle;
		}
		this.closeModal();
	}

	saveHotMotifParameter(params) {
		sessionStorage['HotMotifParameter'] = JSON.stringify(params);
	}

	refreshHotType() {
		if (!this.isModifyType) {
			this.type = new HotTypeDataRaw();
		} else {
			this.type.libelle = this.oldType.libelle;
		}
		this.closeModal();
	}

	refreshResolution() {
		if (!this.isModifyResolution) {
			this.resolution = new HotResolutionDataRaw();
		} else {
			this.resolution.libelle = this.oldResolution.libelle;
		}
		this.closeModal();
	}

	executeOnDestroyWhenBrowserRefresh() {
		window.onbeforeunload = () => this.ngOnDestroy();
	}

	deleteSessions() {
		delete sessionStorage['HotGroupeParameter'];
		delete sessionStorage['HotMotifParameter'];
		delete sessionStorage['HotResolutionParameter'];
		delete sessionStorage['HotTypeParameter'];
	}

	ngOnDestroy(): void {
		this.deleteSessions();
	}

	starterGroupUsers() {
		this.gridHelperGroupUsers.gridOptions = {
			columnDefs: this.getColumnDefsGroupUsers(),
			defaultColDef: {
				resizable: true,
				suppressMenu: true
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 10,
			maxBlocksInCache: 0,
			infiniteInitialRowCount: 1,
			paginationPageSize: 10,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			localeText: this.gridHelperGroupUsers.getLocaleText(),
			onGridReady: (params) => this.onGridReadyGroupUsers(params)
		};
	}

	onGridReadyGroupUsers(params) {
		const ctx = this;
		this.gridHelperGroupUsers.gridApi = params.api;
		this.gridHelperGroupUsers.gridColumnApi = params.columnApi;
		this.restoreGrid(this.gridHelperGroupUsers);
		const dataSource: IServerSideDatasource = {
			getRows: function (paramsRows: IServerSideGetRowsParams) {
				ctx.sorts = gridConvertSort(ctx.gridHelperGroupUsers.gridSortModel(), []);
				const sortsHotGroupUsers = ctx.sorts;
				const criteria = {
					page: ctx.gridHelperGroupUsers.gridApi.paginationGetCurrentPage(),
					size: ctx.gridHelperGroupUsers.gridApi.paginationGetPageSize(),
					idOct: ctx.currentOct ? ctx.currentOct.idOct : '',
					sortsHotGroupUsers
				};
				ctx.groupUsersSvc.getGroupUserListPageable(criteria).subscribe(
					(data) => {
						const rowCount = data.totalElements;
						ctx.gridHelperGroupUsers.manageNoRowsOverlay(rowCount);
						paramsRows.success({"rowData": data.content, "rowCount": rowCount});
					},
					() => paramsRows.fail()
				);
			}
		};
		this.gridHelperGroupUsers.gridApi.setServerSideDatasource(dataSource);
	}

	private getColumnDefsGroupUsers(): ColDef[] {
		return [
			{
				headerName: 'Libellé',
				colId: 'libelleLong',
				field: 'libelleLong',
				minWidth: 200,
				lockVisible: true,
				sortable: true,
				flex: 1
			},
			{
				headerName: '',
				field: 'optionsModifier',
				minWidth: 50,
				headerClass: 'ag-header-merge',
				cellRenderer: HotlinePreviewComponent,
				onCellClicked: (params) => this.onCellClickedHotGroupUsers(params),
				lockVisible: true,
				cellStyle: this.gridHelperGroupUsers.centerContent(),
				cellRendererParams: {
					type: 'groupe'
				}
			}
		];
	}

	resetGridStateHotGroupUsers() {
		this.gridHelperGroupUsers.resetGridState();
		this.paginationSize = this.gridHelperGroupUsers.defaultPaginationPageSize;
	}

	onCellClickedHotGroupUsers(params) {
		this.btnName = 'Modifier';
		this.isModifyService = true;
		this.groupe = params.data;
		this.oldGroupe = new HotGroupUsersDataRaw();
		this.oldGroupe.idGroupe = params.data.idGroupe;
		this.oldGroupe.libelleCourt = params.data.libelleCourt;
		this.oldGroupe.libelleLong = params.data.libelleLong;
		this.showAddGroupUserstModal = true;
	}

	saveHotGroupUsers() {
		//Verifier si le champ est vide
		if (
			//isNullOrUndefined(this.groupe.libelleCourt) ||
			isNullOrUndefined(this.groupe.libelleLong) ||
			//this.groupe.libelleCourt.length == 0 ||
			this.groupe.libelleLong.length == 0 ||
			//!this.inputValidators.inputWithSpacesNgModel(this.groupe.libelleCourt) ||
			!this.inputValidators.inputWithSpacesNgModel(this.groupe.libelleLong)
		) {
			//Afficher le message d erreur 'Veuillez remplir tous les champs obligatoires *'
			this.messageEmptyField = true;
		} else {
			this.messageEmptyField = false;
			this.groupUsersSvc.getGroupUserList().subscribe((data) => {
				var itemsProcessed = 0;
				data.forEach((element) => {
					itemsProcessed++;

					/* 					// Verifier si la valeur renseignée exite deja dans la table
					if (element.libelleCourt.toLowerCase().trim() == this.groupe.libelleCourt.toLowerCase().trim()) {
						// la valeur de libelleCourt renseignée exite deja dans la table, donc le ckeckStatus egale 3
						this.ckeckStatus = 3;
					} else  */ if (
						element.libelleLong.toLowerCase().trim() == this.groupe.libelleLong.toLowerCase().trim()
					) {
						// la valeur de libelleLong renseignée exite deja dans la table, donc le ckeckStatus egale 2
						this.ckeckStatus = 2;
					}

					if (itemsProcessed === data.length && this.ckeckStatus == 1) {
						this.refreshTableAfterAddHotGroupUsers();
					} else if (itemsProcessed === data.length && this.ckeckStatus == 2) {
						this.toastr.error('Service déjà existant');
					} else if (itemsProcessed === data.length && this.ckeckStatus == 3) {
						this.toastr.error('Libellé court déjà existant');
					}
				});
			});

			this.ckeckStatus = 1;
		}
	}

	refreshTableAfterAddHotGroupUsers() {
		// Enregistrement du groupe Hotline
		this.groupe.libelleLong = this.groupe.libelleLong.trim();
		this.groupUsersSvc.saveHotline(this.groupe).subscribe((data) => {
			this.showAddGroupUserstModal = false;
			const ctx = this;

			// Reinsialisation du tableau avec les nouvelles  valeurs
			this.restoreGrid(this.gridHelperGroupUsers);
			const dataSource: IServerSideDatasource = {
				getRows: function (paramsRows: IServerSideGetRowsParams) {
					const sortsHotGroupUsers = gridConvertSort(ctx.gridHelperGroupUsers.gridSortModel(), []);
					const criteria = {
						page: ctx.gridHelperGroupUsers.gridApi.paginationGetCurrentPage(),
						size: ctx.gridHelperGroupUsers.gridApi.paginationGetPageSize(),
						idOct: ctx.currentOct ? ctx.currentOct.idOct : '',
						sortsHotGroupUsers
					};
					ctx.groupUsersSvc.getGroupUserListPageable(criteria).subscribe(
						(data) => {
							if (isNullOrUndefined(ctx.groupe.libelleCourt) == false) {
								if (isNullOrUndefined(ctx.groupe.idGroupe)) {
									ctx.toastr.success('Le service a été ajouté avec succès');
								} else {
									ctx.toastr.success('Le service a été mis à jour avec succès');
								}
							}
							ctx.groupe = new HotGroupUsersDataRaw();
							const rowCount = data.totalElements;
							ctx.gridHelperGroupUsers.manageNoRowsOverlay(rowCount);
							paramsRows.success({"rowData": data.content, "rowCount": rowCount});
						},
						() => paramsRows.fail()
					);
				}
			};
			this.gridHelperGroupUsers.gridApi.setServerSideDatasource(dataSource);
		});
	}

	deleteHotGroupUsers() {
		this.groupUsersSvc.deleteHotline(Number(this.groupe.idGroupe)).then(
			(ret) => {
				this.showDeleteGroupUsersModal = false;
				const ctx = this;
				this.restoreGrid(this.gridHelperGroupUsers);
				const dataSource: IServerSideDatasource = {
					getRows: function (paramsRows: IServerSideGetRowsParams) {
						const sortsHotGroupUsers = gridConvertSort(ctx.gridHelperGroupUsers.gridSortModel(), []);
						const criteria = {
							page: ctx.gridHelperGroupUsers.gridApi.paginationGetCurrentPage(),
							size: ctx.gridHelperGroupUsers.gridApi.paginationGetPageSize(),
							idOct: ctx.currentOct ? ctx.currentOct.idOct : '',
							sortsHotGroupUsers
						};
						ctx.groupUsersSvc.getGroupUserListPageable(criteria).subscribe(
							(data) => {
								ctx.toastr.success('Le service a été supprimé avec succès');
								ctx.groupe = new HotGroupUsersDataRaw();
								const rowCount = data.totalElements;
								ctx.gridHelperGroupUsers.manageNoRowsOverlay(rowCount);
								paramsRows.success({"rowData": data.content, "rowCount": rowCount});
							},
							() => paramsRows.fail()
						);
					}
				};
				this.gridHelperGroupUsers.gridApi.setServerSideDatasource(dataSource);
			},
			(err) => {
				this.closeModal();
				this.toastr.error('Le service du destinataire ne peut pas être supprimé');
			}
		);
	}

	public openModalAddHotGroupUsers() {
		this.btnName = 'Ajouter';
		this.groupe = new HotGroupUsersDataRaw();
		this.showAddGroupUserstModal = true;
	}

	showErrorMessage() {
		this.toastr.error("Erreur lors de l'operation");
	}

	changeCheck() {
		if (
			isNullOrUndefined(this.groupe.libelleLong) ||
			this.groupe.libelleLong.length == 0 ||
			!this.inputValidators.inputWithSpacesNgModel(this.groupe.libelleLong)
		) {
			this.isDesableValidedButton = true;
		} else {
			this.isDesableValidedButton = false;
		}
	}
}
