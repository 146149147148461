import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserService } from '../../../core/services/user.service';
import { User } from '../../../data/userDataRaw';
import {SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard-helpvideo-modal',
  templateUrl: './dashboard-helpvideo-modal.component.html',
  styleUrls: ['./dashboard-helpvideo-modal.component.scss']
})
export class DashboardHelpvideoModalComponent implements OnInit {
  
  safeUrl: SafeUrl;//we get it from the initial state of the moadal
  user : User; //we get it from the initial state of the moadal

  constructor(private modalRef: BsModalRef ,
              private userService:UserService , 
             ) {}

  ngOnInit() {
    const userPreferencesDataRaw = this.user.userPreferences;
    userPreferencesDataRaw.proposeVideo = (this.user.userPreferences.proposeVideo === null  || this.user.userPreferences.proposeVideo === true) ? false : true;
    this.user.userPreferences = userPreferencesDataRaw;
  }

  onSelectionChanged() {
    const userPreferencesDataRaw = this.user.userPreferences;
    userPreferencesDataRaw.proposeVideo = !this.user.userPreferences.proposeVideo;
    this.user.userPreferences = userPreferencesDataRaw;
  }

  closeModal() {
    this.onCloseModal();
    this.modalRef.hide();
  }

  onCloseModal(){
    if (this.user.userPreferences.proposeVideo === false) {
      this.userService.updateUserPreferences(this.user.userPreferences)
        .subscribe(_=> {
          localStorage.setItem('careweb_user', JSON.stringify(this.user));
        }); 
    }
  }
 
}
