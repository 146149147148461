export interface Reporting {
    text: string;
    years: number[];
    // simpl (true) or details (false)
    mode?: boolean;
}

export interface TokenModel {
    sub: string;
    userType: number;
    exp: number;
    iat: number;
}

export interface VersionModel {
    title: string,
    content: string
}

export interface ExportState {
    message: string,
    state: State
}

export enum State {
    INPROGRESS,
    SUCCESS,
    FAILED
}
