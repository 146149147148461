import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {UntypedFormGroup, UntypedFormBuilder} from '@angular/forms';
import { OctDataRaw } from 'src/main/webapp/src/app/data/octDataRaw';
import { InputValidators } from 'src/main/webapp/src/app/components/validators/InputValidators';
import { SiteEmissionService } from '../../../service/site-emission.service';
import { SiteEmissionDataRaw } from 'src/main/webapp/src/app/data/siteEmissionDataRaw';
import { ToastrService } from 'ngx-toastr';
import { isEmpty, isEqual } from 'lodash-es';
import { MapEmptyValueToNull } from '../../../../../core/utilities/map-empty-value-to-null';
import {isNullOrUndefined} from "util";

@Component({
	selector: 'careweb-app-ps-site-emission-modification',
	templateUrl: './ps-site-emission-modification.component.html',
	styleUrls: [ './ps-site-emission-modification.component.scss' ]
})
export class PsSiteEmissionModificationComponent implements OnInit {
	@Input() idPs: number;
	@Input() numeroPs: number;
	// @Input() type: String;
	@Input() idSite: number;
	@Input() codeLastTypeRetour: String;
	siteForm: UntypedFormGroup;
	balEmettriceExist: Boolean = false;
	// isCreate: Boolean = true;
	modBalMetrice;
	siteReroutage: SiteEmissionDataRaw;
	currentbalEmettriceSecondaire;
	currentbalEmettrice;
	typeTransportArray = [
		{ value: 1, code: 'MAIL', viewValue: 'Mail' },
		{ value: 2, code: 'X_MODEM', viewValue: 'X Modem' },
		{ value: 3, code: 'THALCOM', viewValue: 'Thalcom' }
	];

	typeRetourArray = [
		{ value: 0, code: 'NOEMIE', viewValue: 'NOEMIE' },
		{ value: 3, code: 'NOEMIE_XMODEM', viewValue: 'NOEMIE_XMODEM' },
		{ value: 1, code: 'RPH_GROUPE', viewValue: 'RPH_GROUPE' },
		{ value: 2, code: 'RPH_SEPARE', viewValue: 'RPH_SEPARE' },
		{ value: 4, code: 'RPH_SOLDANT_SEUL', viewValue: 'RPH_SOLDANT_SEUL' }
	];

	actifArray: any = [ { value: true, viewValue: 'Oui' }, { value: false, viewValue: 'Non' } ];
	envoiCompresserArray: any = [ { value: true, viewValue: 'Oui' }, { value: false, viewValue: 'Non' } ];
	parDefautArray: any = [ { value: true, viewValue: 'Oui' }, { value: false, viewValue: 'Non' } ];
	envoiARTArray: any = [ { value: true, viewValue: 'Oui' }, { value: false, viewValue: 'Non' } ];
	envoiJDREArray: any = [ { value: true, viewValue: 'Oui' }, { value: false, viewValue: 'Non' } ];
	rph2Array: any = [ { value: true, viewValue: 'Oui' }, { value: false, viewValue: 'Non' } ];
	submitted: Boolean = false;
	currentOct: OctDataRaw;
	initalValues: any;

	constructor(
		private modalRef: BsModalRef,
		private toastr: ToastrService,
		private fb: UntypedFormBuilder,
		private siteEmissionSvc: SiteEmissionService,
		private inputValidators: InputValidators,
		private mapEmptyValueToNull: MapEmptyValueToNull
	) {
		this.siteReroutage = new SiteEmissionDataRaw();
	}

	ngOnInit() {
		this.submitted = false;
		this.currentOct = JSON.parse(localStorage.getItem('octCurrent'));
		this.validForm();
		this.refreshCurrentPage();
	}

	refreshCurrentPage() {
		this.siteForm.patchValue({
			nomOCT: this.currentOct.nom
		});
		this.getSiteByIdSite(this.idSite);
	}

	getSiteByIdSite(idSite) {
		this.siteEmissionSvc.getSiteEmissionById(idSite).subscribe((data) => {
			this.modBalMetrice = data.balEmettrice;
			this.changeTypeTransport(this.typeTransportArray.find((type) => type.value == data.idTypeTransport));
			this.siteReroutage = data.siteReroutage;
			this.siteForm.patchValue({
				balEmettrice: data.balEmettrice,
				balEmettriceSecondaire: data.balEmettriceSecondaire,
				serveurPop: data.serveurPop,
				identifiantXModem: data.identifiantXModem,
				motDePasse: data.motDePasse,
				typeRetourSouhaite: this.typeRetourArray.find((type) => type.code === data.typeRetourSouhaite),
				envoiART: this.envoiARTArray.find((envoi) => envoi.value === data.envoiART),
				typeTransport: this.typeTransportArray.find((type) => type.value == data.idTypeTransport),
				gen: data.gen,
				numeroMaitre: data.numeroMaitre,
				numeroEsclave: data.numeroEsclave,
				envoisCompresses: this.envoiCompresserArray.find(
					(envoisCompresser) => envoisCompresser.value === data.envoisCompresses
				),
				parDefaut: this.parDefautArray.find((parDefaut) => parDefaut.value === data.parDefaut),
				envoiJdre: this.envoiJDREArray.find((envoi) => envoi.value === data.envoiJdre),
				rph2: this.rph2Array.find((envoi) => envoi.value === data.rph2),
				actif: this.actifArray.find((actif) => actif.value === data.actif)
			});
			this.initalValues = this.siteForm.value;
		});
	}

	getTypeTransportByValue(value) {
		return this.typeTransportArray.find((type) => type.value == value);
	}

	getTypeTransportByCode(code) {
		return this.typeTransportArray.find((type) => type.code === code);
	}

	getTypeRetourByValue(value) {
		return this.typeRetourArray.find((type) => type.value === value);
	}

	changeTypeTransport(event) {
		if (event.value == 3) {
			this.currentbalEmettriceSecondaire = this.siteForm.value.balEmettriceSecondaire;
			this.currentbalEmettrice = this.numeroPs;
			// Type de transport 'Thalcom'
			this.siteForm.controls.balEmettrice.disable();
			this.siteForm.controls.balEmettriceSecondaire.disable();
			this.siteForm.controls.serveurPop.disable();
			this.siteForm.controls.identifiantXModem.disable();
			this.siteForm.controls.motDePasse.disable();
			this.siteForm.controls.gen.enable();
			this.siteForm.controls.numeroMaitre.enable();
			this.siteForm.controls.numeroEsclave.enable();
			this.siteForm.patchValue({
				balEmettrice: this.numeroPs,
				parDefaut: this.parDefautArray.find((parDefaut) => parDefaut.value === true),
				actif: this.actifArray.find((actif) => actif.value === true),
				serveurPop: '',
				identifiantXModem: '',
				motDePasse: '',
				gen: 'gen'
			});
		} else {
			this.siteForm.controls.balEmettrice.enable();
			this.siteForm.controls.balEmettriceSecondaire.enable();
			this.siteForm.controls.serveurPop.enable();
			this.siteForm.controls.identifiantXModem.enable();
			this.siteForm.controls.motDePasse.enable();
			this.siteForm.controls.gen.disable();
			this.siteForm.controls.numeroMaitre.disable();
			this.siteForm.controls.numeroEsclave.disable();
			this.siteForm.patchValue({
				parDefaut: this.parDefautArray.find((parDefaut) => parDefaut.value === false),
				actif: this.actifArray.find((actif) => actif.value === false),
				gen: '',
				numeroMaitre: '',
				numeroEsclave: ''
			});
		}
	}

	validForm() {
		this.siteForm = this.fb.group({
			balEmettrice: [ '', [ this.inputValidators.inputWithSpaces ] ],
			balEmettriceSecondaire: [ '' ],
			serveurPop: [ '' ],
			nomOCT: [ '' ],
			identifiantXModem: [ '' ],
			motDePasse: [ '' ],
			typeRetourSouhaite: [ this.getTypeRetourByValue(0) ],
			envoiART: [ this.envoiARTArray.find((envoi) => envoi.value === true) ],
			typeTransport: [ this.getTypeTransportByValue(1) ],
			gen: [ '' ],
			numeroMaitre: [ '' ],
			numeroEsclave: [ '' ],
			envoisCompresses: [
				this.envoiCompresserArray.find((envoisCompresser) => envoisCompresser.value === false)
			],
			parDefaut: [ this.parDefautArray.find((parDefaut) => parDefaut.value === false) ],
			envoiJdre: [ this.envoiJDREArray.find((envoi) => envoi.value === false) ],
			rph2: [ this.rph2Array.find((envoi) => envoi.value === false) ],
			actif: [ this.actifArray.find((actif) => actif.value === false) ]
		});
	}

	get formFields() {
		return this.siteForm.controls;
	}

	closeModal() {
		this.modalRef.hide();
	}
	formatActif(value) {
		return value === true ? 'ACTIF' : 'INACTIF';
	}

	getTitle() {
		let title = ``;
			let actif = '';
			if (this.siteForm.value.actif) {
				actif = this.formatActif(this.siteForm.value.actif.value);
			}
			title += `Modification du paramètre - ${this.siteForm.value.balEmettrice
				? this.siteForm.value.balEmettrice
				: this.modBalMetrice} - (${actif})`;
			return title;
	}

	onSubmit() {
		this.submitted = true;

		const isMail = this.siteForm.value.typeTransport.code == this.getTypeTransportByCode('MAIL').code;
		if (isMail) {
			const controlBalEmettrice = this.siteForm.controls['balEmettrice'];
			const EMAIL_REGEX = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
			if (!EMAIL_REGEX.test(this.siteForm.value.balEmettrice)) {
				controlBalEmettrice.setErrors({ fieldTypeEmail: true });
			}
		}
		const isXModem = this.siteForm.value.typeTransport.code == this.getTypeTransportByCode('X_MODEM').code;
		if (isXModem || (isXModem &&this.siteForm.value.balEmettrice?.trim().length===0 )) {
			const controlBalEmettrice = this.siteForm.controls['balEmettrice'];
			controlBalEmettrice.clearValidators();
			controlBalEmettrice.updateValueAndValidity();
		}

		const isTHALCOM = this.siteForm.value.typeTransport.code == this.getTypeTransportByCode('THALCOM').code;
		if (isTHALCOM) {
			const controlGen = this.siteForm.controls['gen'];
			if (isEmpty(this.siteForm.value.gen)) {
				controlGen.setErrors({ required: true });
			}

			const controNumeroMaitre = this.siteForm.controls['numeroMaitre'];
			if (isEmpty(this.siteForm.value.numeroMaitre)) {
				controNumeroMaitre.setErrors({ required: true });
			}

			const controlNumeroEsclave = this.siteForm.controls['numeroEsclave'];
			if (isEmpty(this.siteForm.value.numeroEsclave)) {
				controlNumeroEsclave.setErrors({ required: true });
			}
		}

		if (this.siteForm.invalid || 
			isEqual(this.mapEmptyValueToNull.emptyValueToNull(this.initalValues), this.mapEmptyValueToNull.emptyValueToNull(this.siteForm.value))) {
			return;
		}
		let dataNewSite = this.siteForm.value;
		//  else {
		let siteEmissionDataRaw = new SiteEmissionDataRaw();
		siteEmissionDataRaw.idOct = this.currentOct.idOct;
		switch (this.currentOct.code) {
			case 'THA':
				if (dataNewSite.typeTransport.code == 'THALCOM') {
						this.saveSiteEmission();
				} else {
					siteEmissionDataRaw.balEmettrice = dataNewSite.balEmettrice
						? (dataNewSite.balEmettrice as String).trim()
						: this.currentbalEmettrice ? (this.currentbalEmettrice as String).trim() : '';
					siteEmissionDataRaw.idSiteExclu = this.idSite;
					this.controlUniciteBalEmetrice(siteEmissionDataRaw);
				}
				break;
			case 'SAN':
				this.saveSiteEmission();
				break;
			default:
				siteEmissionDataRaw.balEmettrice = dataNewSite.balEmettrice
					? (dataNewSite.balEmettrice as String).trim()
					: this.currentbalEmettrice ? (this.currentbalEmettrice as String).trim() : '';
				siteEmissionDataRaw.idSiteExclu = this.idSite;

				this.controlUniciteBalEmetrice(siteEmissionDataRaw);
				break;
		}
	}

	controlUniciteBalEmetrice(siteEmissionDataRaw) {
		this.saveSiteEmission();
	}

	saveSiteEmission() {
		let dataNewSite = this.siteForm.value;
		let currentSite: SiteEmissionDataRaw = new SiteEmissionDataRaw();

		currentSite.balEmettrice = dataNewSite.balEmettrice
			? (dataNewSite.balEmettrice as String).trim()
			: this.currentbalEmettrice ? (this.currentbalEmettrice as String).trim() : '';
		currentSite.balEmettriceSecondaire = dataNewSite.balEmettriceSecondaire
			? (dataNewSite.balEmettriceSecondaire as String).trim()
			: this.currentbalEmettriceSecondaire ? (this.currentbalEmettriceSecondaire as String).trim() : '';
		currentSite.serveurPop = dataNewSite.serveurPop
			? (dataNewSite.serveurPop as String).trim()
			: dataNewSite.serveurPop;
		currentSite.idPs = this.idPs;
		currentSite.idOct = this.currentOct.idOct;
		currentSite.identifiantXModem = dataNewSite.identifiantXModem
			? (dataNewSite.identifiantXModem as String).trim()
			: dataNewSite.identifiantXModem;
		currentSite.motDePasse = dataNewSite.motDePasse
			? (dataNewSite.motDePasse as String).trim()
			: dataNewSite.motDePasse;
		currentSite.typeRetourSouhaite = dataNewSite.typeRetourSouhaite.value;
		currentSite.envoiART = dataNewSite.envoiART.value;
		currentSite.idTypeTransport = dataNewSite.typeTransport.value;
		currentSite.gen = dataNewSite.gen ? (dataNewSite.gen as String).trim() : dataNewSite.gen;
		currentSite.numeroMaitre = dataNewSite.numeroMaitre
			? (dataNewSite.numeroMaitre as String).trim()
			: dataNewSite.numeroMaitre;
		currentSite.numeroEsclave = dataNewSite.numeroEsclave
			? (dataNewSite.numeroEsclave as String).trim()
			: dataNewSite.numeroEsclave;
		currentSite.envoisCompresses = dataNewSite.envoisCompresses.value;
		currentSite.parDefaut = dataNewSite.parDefaut.value;
		currentSite.envoiJdre = dataNewSite.envoiJdre.value;
		currentSite.rph2 = dataNewSite.rph2.value;
		currentSite.actif = dataNewSite.actif.value;


			this.updateSiteEmission(currentSite);
	}
	setBalemettrice($event){
		const balEmettrice=$event.target.value;
		let siteEmissionDataRaw = new SiteEmissionDataRaw();
		siteEmissionDataRaw.balEmettrice = balEmettrice?.trim();
		if(!isNullOrUndefined(siteEmissionDataRaw.balEmettrice) && siteEmissionDataRaw.balEmettrice.length!==0){
			this.siteEmissionSvc.search(siteEmissionDataRaw).subscribe((data) => {
				if(data && data.length !== 0 && this.modBalMetrice !== siteEmissionDataRaw.balEmettrice) {
					this.balEmettriceExist=true;
				}else{
					this.balEmettriceExist=false;
				}});
		}else{
			this.balEmettriceExist=false;
		}
	}
	updateSiteEmission(currentSite) {
		this.siteEmissionSvc.updateSiteEmission(this.idSite, currentSite).subscribe(
			(data) => {
				if (data) {
					this.toastr.success("Le site d'émission a été mis à jour avec succès");
					this.closeModal();
				}
				this.submitted = false;
			},
			(err) => {
				this.toastr.error("Le site d'émission n'a pas pu être modifié");
			}
		);
	}

	reset() {
		this.changeTypeTransport(this.siteForm.controls.typeTransport);
		this.siteForm.reset();
		this.refreshCurrentPage();
		this.modalRef.hide();
	}

	existRoutage() {
		return !isEmpty(this.siteReroutage);
	}
}
