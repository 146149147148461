import { GridOptions, IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { HotlineDashboardService } from '../hotline-dashboard.service';
import { AuthService } from '../../../core/services/auth.service';
import { AgGridHelper } from '../../../components/ag-grid/ag-grid-helper';
import { HotlineDataRaw } from '../../../data/hotlineDataRaw';
import { DatePipe } from '@angular/common';
import { gridConvertSort } from '../../../components/ag-grid/ag-grid.utils';
import { HotlineFilterRaw } from '../../../data/filters/hotlineFilterRaw';
import { Router } from '@angular/router';
import { HotlinePreviewComponent } from '../hotline-subcomposant/hotline-preview.component';
import { isNullOrUndefined } from 'util';
import { RouteHistoryService } from '../../../shared/route-history.service';

@Component({
	selector: 'careweb-app-hotline-dashboard',
	templateUrl: './hotline-dashboard.component.html',
	styleUrls: ['./hotline-dashboard.component.scss']
})
export class HotlineDashboardComponent implements OnInit, OnDestroy {
	hotDashboardDataRaw;
	gridHelper: AgGridHelper;
	paginationSize: number;
	datePipe: DatePipe;
	hotlineDataRaw: HotlineDataRaw;
	sortRowCount = new Array();
	filters = new HotlineFilterRaw();
	numeroPs: string = null;

	constructor(
		private hotlineDashboardService: HotlineDashboardService,
		private authSvc: AuthService,
		private router: Router,
		private routeHistory: RouteHistoryService
	) {
		this.gridHelper = new AgGridHelper('hotline', 10);
		this.hotlineDataRaw = new HotlineDataRaw();
	}

	ngOnInit() {
		// Get numero de PS séléctionné
		this.numeroPs = localStorage.getItem('numeroPs') ? localStorage.getItem('numeroPs') : '';
		// verify previous url if not details remove session currentPage
		if (this.routeHistory?.getPreviousUrl()?.indexOf('hotline-edit') === -1) {
			sessionStorage['hotlineCurrentPage'] = null;
		}
		// Get Today Count of Tickets States
		this.hotDashboardDataRaw = this.hotlineDashboardService.getDailyHotlineReport(this.numeroPs).subscribe((value) => {
			this.hotDashboardDataRaw = value;
		});
		// Init datePipe
		this.datePipe = new DatePipe('fr-FR');

		// Init grid options
		this.gridHelper.gridOptions = <GridOptions>(<unknown>{
			columnDefs: this.getColumnDefs(),
			defaultColDef: { 
				suppressMenu: true
			},
			context: {
				componentParent: this
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 10,
			maxBlocksInCache: 0,
			infiniteInitialRowCount: 1,
			paginationPageSize: 10,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			localeText: this.gridHelper.getLocaleText(),
			onGridReady: (params) => this.onGridReady(params)
			// onGridSizeChanged: (params) => this.onGridSizeChanged(params)
		});
		this.paginationSize = this.gridHelper.paginationPageSize;
	}

	onGridReady(params) {
		const ctx = this;
		this.gridHelper.gridApi = params.api;
		this.gridHelper.gridColumnApi = params.columnApi;
		this.restoreGridState();
		this.initSorting();

		const dataSource: IServerSideDatasource = {
			getRows: function (paramsRows: IServerSideGetRowsParams) {
				const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
				const criteria = {
					page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
					size: ctx.gridHelper.gridApi.paginationGetPageSize(),
					sorts,
					numeroPs: ctx.numeroPs
				};
				ctx.hotlineDashboardService.getDailyInterventionList(criteria).subscribe(
					(data) => {
						const rowCount = data.totalElements;
						ctx.gridHelper.manageNoRowsOverlay(rowCount);
						paramsRows.success({"rowData": data.content, "rowCount": rowCount});
						const pageN = Number.parseInt(sessionStorage.getItem('hotlineCurrentPage'));
						if (
							!isNullOrUndefined(pageN) &&
							pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
							pageN > 0
						) {
							ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
						}
						sessionStorage['hotlineCurrentPage'] = null;
					},
					() => paramsRows.fail()
				);
			}
		};
		this.gridHelper.gridApi.setServerSideDatasource(dataSource);
		this.gridHelper.gridApi.sizeColumnsToFit();
	}

	// restore grid state if their is in session
	restoreGridState() {
		this.gridHelper.restoreGridStatePrefix();
		this.paginationSize = this.gridHelper.paginationPageSize;
	}

	// initial Sort
	private initSorting() {
		const sort = [
			{
				colId: 'dateDebutIntervention',
				sort: 'DESC',
				sortIndex: 0
			}
		];
		return this.gridHelper.applyColumnState(sort);
	}

	// Defined Columns
	private getColumnDefs() {
		const columnDefs: any[] = [
			{
				headerName: 'Référence',
				field: 'reference',
				maxWidth: 130,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				enableRowGroup: true,
				sortable: true,
				onCellClicked: (params) => this.onCellClicked(params)
			},
			{
				headerName: 'Numéro de PS',
				field: 'numeroPs',
				maxWidth: 130,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				sortable: true,
				onCellClicked: (params) => this.onCellClicked(params)
			},
			{
				headerName: 'Motif',
				field: 'motif',
				maxWidth: 150,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				sortable: true,
				onCellClicked: (params) => this.onCellClicked(params)
			},
			{
				headerName: 'Statut',
				field: 'statut',
				cellRenderer: this.getStatus,
				maxWidth: 130,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				sortable: true,
				onCellClicked: (params) => this.onCellClicked(params)
			},
			{
				headerName: 'Date action',
				field: 'dateDebutIntervention',
				valueFormatter: this.formatDate,
				maxWidth: 150,
				lockVisible: true,
				sort: 'desc',
				sortable: true,
				cellStyle: this.gridHelper.centerContent(),
				onCellClicked: (params) => this.onCellClicked(params)
			},
			{
				headerName: 'Commentaire',
				field: 'commentaire',
				onCellClicked: (params) => this.onCellClicked(params),
				minWidth: 200,
				lockVisible: true,
				sortable: true,
				resizable: false
			},
			{
				headerName: '',
				field: 'optionsHotline',
				maxWidth: 50,
				onCellClicked: (params) => this.onCellClicked(params),
				cellRenderer: HotlinePreviewComponent,
				cellRendererParams: {
					redirect: 'hotlineEdit'
				},
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent()
			}
		];
		return columnDefs;
	}

	onCellClicked(params) {
		this.router.navigate(['/hotline-edit', params.data.idIntervention]);
	}

	formatDate = (params): string => {
		if (params.value != null && params.value !== 'Invalid Date') {
			return this.datePipe.transform(params.value, 'short');
		}
	};

	onGridSizeChanged(params) {
		this.gridHelper.gridApi.sizeColumnsToFit();
	}

	voir(status) {
		this.filters.statut = status;
		let todayDate = new Date();
		todayDate.setHours(0, 0, 0, 0);
		this.filters.dateDebutIntervention = todayDate;
		sessionStorage['hotlineFilters'] = JSON.stringify(this.filters);
		this.router.navigate(['/hotline']);
	}

	ngOnDestroy() {
		sessionStorage['hotlineCurrentPage'] = this.gridHelper.gridApi.paginationGetCurrentPage();
		this.gridHelper.saveGridStatePrefix();
	}

	getStatus = (params): any => {
		if (params.data) {
			let statut: string;
			switch (params.data.statut) {
				case 1: {
					statut = 'En cours ';
					break;
				}
				case 2: {
					statut = 'Transférée';
					break;
				}
				case 3: {
					statut = 'Clôturée';
					break;
				}
			}
			return statut;
		}
	};

	isAuthorized() {
		return this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
	}
}
