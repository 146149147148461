import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from 'util';

/**
 * formatte le type de lot (Sécurisé/Non sécurisé)
 */

@Pipe({
	name: 'yesNoPipe'
})
export class YesNoPipe implements PipeTransform {
	constructor() {}

	transform(data, value: String) {
		let formattedValue = '';
		// TODO: à cleaner
		if (value === 'typeArp' || value === 'nbRsp') {
			data && data > 0 ? (formattedValue = 'Oui') : (formattedValue = 'Non');
		}

		if (value === 'typeArl') {
		 !isNullOrUndefined(data) ? data === true ? (formattedValue = 'Oui (Positif)') : (formattedValue = 'Oui (Négatif)') : (formattedValue = 'Non');
		}

		if (value === 'typeArlLabel') {
			data ? (formattedValue = 'Positif') : (formattedValue = 'Négatif');
		}

		if (value === 'tiersPayant' || value === 'typeLot' || value === 'partRc' || value === 'actif') {
			data ? (formattedValue = 'Oui') : (formattedValue = 'Non');
		}

		return formattedValue;
	}
}
