import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Injectable()
export class IsNotAdminSelAccessGuard implements CanActivate {
	constructor(private authSvc: AuthService, private router: Router) {}

	canActivate() {
		const canActive = this.authSvc.userAuthorized('ADMIN_SEL');
		if (canActive) {
			this.router.navigate([ '/organization-list' ]);
		}

		return !canActive;
	}
}
