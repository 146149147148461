export class HotlineFilterRaw {
	numeroPs: String;
	dateCreation: Date;
	intervenant: any;
	motif: String;
	dateDebutIntervention: Date;
	statut: Number;
	groupe: any;
	reference: String;
	agent: any;
}
