import { Pipe, PipeTransform } from '@angular/core';
import { PsDelegueDataRaw } from '../../data/PsDelegueDataRaw';

/**
 * formatte Le nom du Ps numeroAdeli - raison Sociale
 */

@Pipe({
    name: 'PsPipe'
})
export class PsPipe implements PipeTransform {
    constructor() { }

    transform(data: PsDelegueDataRaw) {
        if (!data) return '';
        
        return data.nomPsDelegue ? 
                    data.numeroPsDelegue + ' - ' + data.nomPsDelegue : 
                    data.numeroPsDelegue;
    }
}
