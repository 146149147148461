import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterService } from '../../core/services/filter-service';
import { CONF } from '../../core/constants';

@Injectable({
	providedIn: 'root'
})
export class SelService extends FilterService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/cis';
	private selToken: any;

	constructor(private httpClient: HttpClient) {
		super();
	}

	public getSelToken() {
		if (this.isTokenExpired()) {
			let promise = new Promise((resolve, reject) => {
				this.httpClient.post<any>(this.baseUrl + '/auth', '').toPromise().then((res) => {
					this.selToken = res.access_token;
					this.setToken(this.selToken);
					var expiresIn = new Date();
					// expired in timespam 300 ==> 5 minutes
					expiresIn.setMinutes(expiresIn.getMinutes() + 5);
					localStorage.setItem('sel_token_expires_in', expiresIn.toString());
					resolve(res);
				});
			});
			return promise;
		} else {
			let promise = new Promise((resolve, reject) => {
				resolve(this.getToken());
			});
			return promise;
		}
	}

	setToken(token: string): void {
		localStorage.setItem('sel_token', token);
	}

	getTokenExpirationDate(expires_in: number): Date {
		if (expires_in === undefined) return null;
		const date = new Date(expires_in);
		return date;
	}

	isTokenExpired(): boolean {
		var expires_in = Date.parse(localStorage.getItem('sel_token_expires_in'));
		const date = new Date(expires_in);
		if (date === undefined) return false;
		return !(date.valueOf() > new Date().valueOf());
	}

	getToken(): string {
		return localStorage.getItem('sel_token');
	}
}
