import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable()
export class RouteHistoryService {
	private secondLastUrl: string = undefined;
	private beforePreviousUrl: string = undefined;
	private previousUrl: string = undefined;
	private currentUrl: string = undefined;

	constructor(private router: Router) {
		this.currentUrl = this.router.url;
		router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.secondLastUrl = this.beforePreviousUrl;
				this.beforePreviousUrl = this.previousUrl;
				this.previousUrl = this.currentUrl;
				this.currentUrl = event.url;
			}
		});
	}

	public getCurrentUrl() {
		return this.currentUrl;
	}

	public getPreviousUrl() {
		return this.previousUrl;
	}

	public getBeforeSecondLastUrl(): string {
		return this.secondLastUrl;
	}
}
