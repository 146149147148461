export class OrganismeFilterRaw {
	sigle: string;
	libelle: string;
	commentaire: string;
	typeOrganisme: string;
	codeGrandRegime: string;
	codeCaisseCAS: string;
	codeCentreCAS: string;
	codeOfficiel: string;
	codeEntree: string;
	aidePerenne: Boolean;
	actif: Boolean;
	etat: string;
	constructor(data = null) {
		Object.assign(this, data);
	}
}
