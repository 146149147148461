<footer class="">
  <ul class="footer-copyright">
    <li *ngIf="version">v {{version.version}}</li>
    <li *ngIf="version">-</li>
    <li>©</li>
    <li>2024 Careweb</li>
  </ul>
  <nav class="footer-nav">
    <ul>
      <li><a [routerLink]="['/mentions-legales']" >Mentions légales</a></li>
      <li><a [routerLink]="['/conditions-utilisation']">Conditions d'utilisation</a></li>
      <li>
        <a id="a_salesdep" target="_blank" rel="noopener noreferrer"
          href="http://www.cegedim-ebusiness.com/">Cegedim E-business</a>
      </li>
    </ul>
  </nav>
</footer>