<ag-grid-angular
    class="ag-theme-balham"
    style="width: 100%"
    rowHeight="50"
    headerHeight="50"
    [gridOptions]="gridHelper.gridOptions"
    *ngIf="dynamicGridModel"
>
</ag-grid-angular>
<!--     domLayout="autoHeight" -->
<!-- <careweb-pagination [gridApi]="gridHelper.gridApi"> </careweb-pagination> -->
