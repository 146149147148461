<div class="colum-select-container">
    <button type="button" name="btn_toggle_filter" class="btn btn-action small dropdown-button" (click)="toggleHiden()" aria-haspopup="true">
		<span class="">Gérer les colonnes</span>
    </button>
    <div class="colum-select-dropdown-list" [hidden]="hidden">
        <div class="colum-select-arrow colum-select-arrow-up"></div>
        <div class="colum-select-arrow-up"></div>
        <div class="colum-select-dropdown-list-area" style="max-height: 210px; overflow-y: auto;">
            <ul *ngFor="let item of items; let i = index; trackBy: trackByIndex" (click)="toggleItemSelection(item)">
                <li class="colum-select-checkbox-list">
                    <input type="checkbox" [ngModel]="item.selected" (ngModelChange)="onChange($event, item)" />
                    <label>{{ item.label }}</label>
                </li>
            </ul>
        </div>
    </div>
</div>
