import { Component, AfterViewInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Router } from '@angular/router';
import { URL_BO_FSE } from '../../../../core/constants';



@Component({
	selector: 'careweb-fse-preview',
	template: `
        <i
			[ngClass]="iconClass"
            container="body"
			(click)="previewFSE()"
			[tooltip]="tooltip"
            containerClass="tooltip-grid"
			(contextmenu)="onRightClick($event)"
		></i>
    `
})
export class FsePreviewComponent implements ICellRendererAngularComp, AfterViewInit {
	iconClass: string;
	tooltip: string;
	private params: any;

	constructor(private router: Router) {}

	agInit(params: any): void {
		this.params = params;
	}

	ngAfterViewInit() {
		const cellParams = this.params;
		if (cellParams.data) {
			if (cellParams.colDef.field && cellParams.colDef.field === 'lienBO.idFacture') {
				this.iconClass = 'fa fa-link';
				this.tooltip = 'Lien BO';
			} else if (cellParams.colDef.field && cellParams.colDef.field === 'optionsRemove') {
				this.iconClass = 'fa fa-trash';
				this.tooltip = 'Supprimer';
			} else if (cellParams.colDef.field && cellParams.colDef.field === 'optionsHotline') {
				this.iconClass = 'fa fa-eye';
				this.tooltip = 'Consulter';
			} else if (cellParams.colDef.field && cellParams.colDef.field === 'options') {
				this.iconClass = 'fa fa-eye';
				this.tooltip = 'Consulter détails de la FSE';
			} else if (cellParams.colDef.field && cellParams.colDef.field === 'numeroLot') {
				this.iconClass = cellParams.iconClass;
				this.tooltip =
					this.params.redirect === 'lotDetails'
						? 'Consulter détails du lot'
						: this.params.redirect === 'fseModal'
							? 'Consulter détails de la facture'
							: 'Consulter détails de la RSP';
			} else if (cellParams.colDef.field && cellParams.colDef.field === 'destinataireDuReglement') {
				Number(cellParams.value) > 0
					? ((this.iconClass = 'fa fa-check green'), (this.tooltip = 'Tiers-Payant'))
					: ((this.iconClass = 'fa fa-close red'), (this.tooltip = 'Non Tiers-Payant'));
			} else if (cellParams.colDef.field && cellParams.colDef.field === 'nbRspRO') {
				Number(cellParams.value) > 0 ? (this.iconClass = 'fa fa-check green') : (this.iconClass = 'fa fa-close red');
			} else if (cellParams.colDef.field && cellParams.colDef.field === 'nbRspRC') {
				Number(cellParams.value) > 0 ? (this.iconClass = 'fa fa-check green') : (this.iconClass = 'fa fa-close red');
			}

			// Append cursor pointer
			this.iconClass = this.iconClass + ' cursor-pointer';
		}
	}

	refresh(): boolean {
		return false;
	}

	previewFSE(): void {
		if (this.params.redirect === 'boLink') {
			window.open(`${URL_BO_FSE}${this.params.data.idFacture}`);
		} else {
			this.router.navigate([ 'fse-details', this.params.data.idFacture ]);
		}
	}

	onRightClick(event: MouseEvent): void {		
		if (this.params.redirect === 'fseDetail') {
			event.preventDefault();
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/careweb-client/fse-details',  this.params.data.idFacture])
			);
			window.open(url, '_blank');
		}
	}
}
