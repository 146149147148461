import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RspDataRaw } from '../../../../data/rspDataRaw';
import { PsDataRaw } from '../../../../data/psDataRaw';
import { LotsDataRaw } from '../../../../data/lotsDataRaw';

@Component({
	selector: 'careweb-rsp-details-modal',
	templateUrl: './rsp-details-modal.component.html'
})
export class RspDetailsModalComponent implements OnInit {
	@Input() rsp: RspDataRaw;
	@Input() ps: PsDataRaw;
	@Input() lot: LotsDataRaw;
	montantTotalDemande;
	montantTotalPaye;
	constructor(private modalRef: BsModalRef) {}

	ngOnInit(): void {
		const ctx = this;
		ctx.montantTotalDemande = ctx.getMontantTotal(ctx.rsp.montantRo, ctx.rsp.montantRc);
		ctx.montantTotalPaye = ctx.getMontantTotal(ctx.rsp.montantFactureRo, ctx.rsp.montantFactureRc);
	}

	cancel() {
		this.modalRef.hide();
	}

	closeModal() {
		this.modalRef.hide();
	}

	getMontantTotal(montantRo, montantRc) {
		let cumulMontantDemande = '';
		if (montantRo != null && montantRc === null) {
			cumulMontantDemande = (parseFloat(montantRo) / 100).toFixed(2);
		} else if (montantRo === null && montantRc != null) {
			cumulMontantDemande = (parseFloat(montantRc) / 100).toFixed(2);
		} else if (montantRo === null && montantRc === null) {
			cumulMontantDemande = '0,00';
		} else {
			cumulMontantDemande = (parseFloat(montantRo) / 100 + parseFloat(montantRc) / 100).toFixed(2);
		}
		return cumulMontantDemande + ' €';
	}
}
