import {OnInit, Component, OnDestroy, HostListener} from '@angular/core';
import { AgGridHelper } from '../../../components/ag-grid/ag-grid-helper';
import { GridOptions, ColDef, IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { FilterItem } from '../../../data/filters/filter-item';
import { uniqBy, findIndex, isEmpty } from 'lodash-es';
import { OrgStatePreviewComponent } from './org-state-preview.component';
import { Router } from '@angular/router';
import { OrgStateService } from '../org-state.service';
import { OrgStateFilterRaw } from '../../../data/filters/org-state-filter';
import { gridConvertSort } from '../../../components/ag-grid/ag-grid.utils';
import { isNullOrUndefined } from 'util';
import { RouteHistoryService } from '../../../shared/route-history.service';
import { GridDataRaw } from '../../../data/gridDataRaw';
import { StorageService } from '../../../shared/storage-service';

@Component({
	selector: 'app-organization-state-account',
	templateUrl: './organization-state.component.html'
})
export class OrganizationStateComponent implements OnInit, OnDestroy {
	gridHelper: AgGridHelper;
	filters = new OrgStateFilterRaw();
	filterList: FilterItem[];
	showFilter = false;
	initHidden = true;
	showError = false;
	gridDataRaw: GridDataRaw;
	static MIN_WIDTH = 1173;

	constructor(
		private orgStateService: OrgStateService,
		private router: Router,
		private routeHistory: RouteHistoryService,
		private storageService: StorageService
	) {
		// Body styling
		let body = document.body;
		body.className = 'app';

		//Get grid size from session if already stored
		const sessionPageSize = this.storageService.get('selPaginationPageSize', true);
		this.gridDataRaw = new GridDataRaw();
		this.gridDataRaw.size = !isNullOrUndefined(sessionPageSize) ? sessionPageSize : 10;

		this.gridHelper = new AgGridHelper('sel', this.gridDataRaw.size, 10);
	}

	ngOnInit() {
		// verify previous url if not details remove session currentPage
		if (this.routeHistory?.getPreviousUrl()?.indexOf('org-state-details') === -1) {
			sessionStorage['selCurrentPage'] = null;
		}
		// Init filters
		var parsedFilter = JSON.parse(sessionStorage.getItem('selFilter'));
		// Get filter stored in session if exist
		if (!isEmpty(parsedFilter)) {
			this.filters = Object.assign({}, parsedFilter);
		} else {
			this.filterList = [];
		}

		this.gridHelper.gridOptions = <GridOptions>(<unknown>{
			columnDefs: this.getColumnDefs(),
			defaultColDef: {
				resizable: true,
				suppressMenu: true
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 10,
			maxBlocksInCache: 0,
			infiniteInitialRowCount: 1,
			paginationPageSize: this.gridDataRaw.size,
			paginateChildRows: true,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			localeText: this.gridHelper.getLocaleText(),
			onRowClicked: (params) => this.onRowClicked(params),
			getRowClass: (params) => this.stylePreviouslyClickedRow(params),
			onGridReady: (params) => this.onGridReady(params),
			 onGridSizeChanged: (params) => this.onGridSizeChanged(params),
			onSortChanged: (params) => this.onSortChanged(params)
		});
	}

	private getColumnDefs(): ColDef[] {
		return [
			{
				headerName: 'N° AMC/OTP',
				field: 'organizationNumber',
				lockVisible: true,
				sortable: true,
				cellStyle: this.gridHelper.centerContent()
			},
			{
				headerName: 'Libellé AMC/OTP',
				field: 'label',
				lockVisible: true,
				sortable: true,
				cellStyle: this.gridHelper.centerContent()
			},
			{
				headerName: '',
				field: 'options',
				minWidth: 50,
				maxWidth: 70,
				cellRenderer: OrgStatePreviewComponent,
				cellRendererParams: {
					iconClass: 'fa fa-eye',
					redirect: 'orgStateDetails'
				},
				cellStyle: this.gridHelper.centerContent()
			}
		];
	}

	onGridReady(params) {
		const ctx = this;
		this.gridHelper.gridApi = params.api;
		this.gridHelper.gridColumnApi = params.columnApi;
		this.initSorting();
		this.restoreGrid();
		this.gridHelper.gridApi.setServerSideDatasource(null);
		this.gridHelper.refreshView();
		var parsedFilter = JSON.parse(sessionStorage.getItem('selFilter'));
		if (!isEmpty(parsedFilter)) {
			this.launchSearch();
		} else {
			const dataSource: IServerSideDatasource = {
				getRows: function (paramsRows: IServerSideGetRowsParams) {
					const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
					ctx.orgStateService
						.getDistinctOrganizationState({
							page: ctx.gridHelper.gridApi.paginationGetCurrentPage() + 1,
							size: ctx.gridHelper.gridApi.paginationGetPageSize(),
							sorts
						})
						.subscribe(
							(results) => {
								ctx.showError = false;
								let rowCount = results.paging.totalElements;
								ctx.gridHelper.manageNoRowsOverlay(rowCount);
								paramsRows.success({"rowData": results.data, "rowCount": rowCount});
								const pageN = Number.parseInt(sessionStorage.getItem('selCurrentPage'));
								if (
									!isNullOrUndefined(pageN) &&
									pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
									pageN > 0
								) {
									ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
								}
								sessionStorage['selCurrentPage'] = null;
							},
							(err) => {
								ctx.showError = true;
								ctx.gridHelper.manageNoRowsOverlay(0);
								paramsRows.fail();
							}
						);
				}
			};
			this.gridHelper.gridApi.setServerSideDatasource(dataSource);
			this.gridHelper.gridApi.sizeColumnsToFit();
		}
	}

	launchSearch(): void {
		const ctx = this;
		this.filters.organizationNumber = this.filters.organizationNumber
			? this.filters.organizationNumber.trim()
			: null;
		this.updateFilterList(this.filters);

		//this.gridHelper.gridApi.refreshInfiniteCache();
		this.gridHelper.gridApi.setServerSideDatasource(null);
		this.gridHelper.refreshView();
		const dataSource: IServerSideDatasource = {
			getRows: function (paramsRows: IServerSideGetRowsParams) {
				const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
				ctx.orgStateService
					.getDistinctOrganizationState(
						{
							page: ctx.gridHelper.gridApi.paginationGetCurrentPage() + 1,
							size: ctx.gridHelper.gridApi.paginationGetPageSize(),
							sorts
						},
						ctx.filters
					)
					.subscribe(
						(results) => {
							ctx.showError = false;
							let rowCount = results.paging.totalElements;
							ctx.gridHelper.manageNoRowsOverlay(rowCount);
							paramsRows.success({"rowData": results.data, "rowCount": rowCount});
							const pageN = Number.parseInt(sessionStorage.getItem('selCurrentPage'));
							if (
								!isNullOrUndefined(pageN) &&
								pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
								pageN > 0
							) {
								ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
							}
							sessionStorage['selCurrentPage'] = null;
						},
						(err) => {
							ctx.showError = true;
							ctx.gridHelper.manageNoRowsOverlay(0);
							paramsRows.fail();
						}
					);
			}
		};

		this.gridHelper.gridApi.setServerSideDatasource(dataSource);
		sessionStorage['selFilter'] = JSON.stringify(this.filters);
		this.gridHelper.gridApi.sizeColumnsToFit();
	}
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.gridHelper.gridApi.sizeColumnsToFit();
	}
	setColumnOrder(width:any) {
		const columnDefs = this.getColumnDefs();
		if(width<=853){
			const columnApi = this.gridHelper.gridColumnApi;
			const optionsIndex = columnDefs.findIndex(column => column.field === 'options');
			if (optionsIndex !== -1 ) {
				columnApi.moveColumn('options', 0);
			}
		}else {
			this.gridHelper.gridApi.sizeColumnsToFit();
			this.gridHelper.gridApi.setColumnDefs(columnDefs);
		}
	}
	onGridSizeChanged(params) {
		this.gridHelper.gridApi.sizeColumnsToFit();
	}
	private initSorting() {
		const sort = [
			{
				colId: 'organizationNumber',
				sort: 'asc',
				sortIndex: 0
			}
		];
		return this.gridHelper.applyColumnState(sort);
	}

	restoreGrid() {
		this.gridHelper.restoreGridStatePrefix();
		this.gridDataRaw.size = this.gridHelper.paginationPageSize;
	}

	resetGridState() {
		this.gridHelper.resetGridState();
		this.gridDataRaw.size = this.gridHelper.defaultPaginationPageSize;
		this.gridHelper.gridApi.sizeColumnsToFit();
		this.initSorting();
		//this.resetAllFilter();
	}
	toggleFilterDisplay() {
		if (this.initHidden) {
			this.initHidden = false;
			setTimeout(() => {
				this.showFilter = !this.showFilter;
			}, 10);
		} else {
			this.showFilter = !this.showFilter;
		}
	}
	resetAllFilter(): void {
		this.initFilter();
		this.filters = new OrgStateFilterRaw();
		this.launchSearch();
		this.orgStateService.reset();
	}

	initFilter() {
		// Liste des filtres
		this.orgStateService.reset();
		this.updateFilterList(this.filters);
	}

	updateFilterList(filters) {
		var ctx = this;
		ctx.filterList = [];
		Object.keys(filters).forEach((key) => {
			if (filters[key] != null && filters[key] != '') {
				ctx.filterList.push(ctx.translateFilterLabelValue(key, filters[key]));
			}
		});
		// Remove duplicated ids
		ctx.filterList = uniqBy(ctx.filterList, 'id');
		// filter null
		ctx.filterList = ctx.filterList.filter((item) => {
			return item.id != null;
		});
	}

	translateFilterLabelValue(key, value) {
		var translatedKey = key;
		var formattedValue = value;
		var formattedKey = key;
		switch (key) {
			case 'organizationNumber':
				translatedKey = 'N° AMC/OTP';
				break;
			default:
				break;
		}
		return new FilterItem().withId(formattedKey).withLabel(translatedKey).withValue(formattedValue).withValue2(value);
	}

	removeFilter(id) {
		var ctx = this;
		delete ctx.filters[id];
		var currentFilterList = ctx.filterList;
		const index = findIndex(currentFilterList, (filt) => filt.id === id);
		currentFilterList.splice(index, 1);
		ctx.filterList = currentFilterList;
		this.fillFilterObjectFromTagsOnly();
		ctx.launchSearch();
	}

	fillFilterObjectFromTagsOnly() {
		this.filters = new OrgStateFilterRaw();
		this.filterList.forEach(filter => {
			this.filters[filter.id] = filter.value2;
		});
	}

	onRowClicked(params) {
		this.storageService.set('previouslyClickedOrgNumber', params.node.data.organizationNumber, true);
		this.router.navigate(['/org-state-details', params.data.label, params.data.organizationNumber]);
	}

	stylePreviouslyClickedRow(params: any) {
		const rowData = params.node.data;

		if (!isNullOrUndefined(rowData)) {
			const previouslyClickedRow = this.storageService.get('previouslyClickedOrgNumber', true);

			if (rowData.organizationNumber === previouslyClickedRow) {
				return 'ag-row-selected';
			}
		}
	}

	ngOnDestroy() {
		sessionStorage['selFilter'] = JSON.stringify(this.filters);
		sessionStorage['selCurrentPage'] = this.gridHelper.gridApi.paginationGetCurrentPage();
		this.gridHelper.saveGridStatePrefix();
	}

	onPaginationSizeChange(paginationSize: number): void {
		this.gridHelper.changePaginationSize(paginationSize);
		this.launchSearch();
		this.gridHelper.saveGridStatePrefix();
	}

	onSortChanged(params: any): void {
		this.gridHelper.saveGridSort();
	}
}
