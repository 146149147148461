import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'careweb-tracability-details-modal',
	templateUrl: './tracability-details-modal.component.html'
})
export class TracabilityDetailsModalComponent implements OnInit {
	@Input() url: String;
	@Input() request: String;
	@Input() response: string;
	montantTotalDemande;
	montantTotalPaye;
	constructor(private modalRef: BsModalRef) {}

	ngOnInit(): void {
	}

	cancel() {
		this.modalRef.hide();
	}

	closeModal() {
		this.modalRef.hide();
	}

	getMontantTotal(montantRo, montantRc) {
		let cumulMontantDemande = '';
		if (montantRo != null && montantRc === null) {
			cumulMontantDemande = (parseFloat(montantRo) / 100).toFixed(2);
		} else if (montantRo === null && montantRc != null) {
			cumulMontantDemande = (parseFloat(montantRc) / 100).toFixed(2);
		} else if (montantRo === null && montantRc === null) {
			cumulMontantDemande = '0,00';
		} else {
			cumulMontantDemande = (parseFloat(montantRo) / 100 + parseFloat(montantRc) / 100).toFixed(2);
		}
		return cumulMontantDemande + ' €';
	}
}
