import {OnInit, Component, OnDestroy, HostListener} from '@angular/core';
import { AgGridHelper } from '../../../components/ag-grid/ag-grid-helper';
import { GridOptions, ColDef, IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { FilterItem } from '../../../data/filters/filter-item';
import { isEmpty, uniqBy, findIndex } from 'lodash-es';
import { TracabilityService } from '../tracability.service';
import { TracabilityPreviewComponent } from './tracability-preview.component';
import { TracabilityFilterRaw } from '../../../data/filters/tracabilityFilterRaw';
import { isNullOrUndefined } from 'util';
import { DatePipe} from '@angular/common';
import { TracabilityDetailsModalComponent } from './fiche-tracability/tracability-details-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import {GridDataRaw} from "../../../data/gridDataRaw";
import {StorageService} from "../../../shared/storage-service";

@Component({
	selector: 'app-tracability-account',
	templateUrl: './tracability.component.html'
})
export class TracabilityComponent implements OnInit, OnDestroy {
	gridHelper: AgGridHelper;
	paginationSize: number;
	filterList: FilterItem[];
	showFilter = false;
	initHidden = true;
	filters: TracabilityFilterRaw;
	datePipe: DatePipe;
	currentDate: Date = new Date();
	private columnDefs;
	private detailCellRendererParams;
	rowCount = 0;
	showError = false;
	serviceList: Array<any>;
	gridDataRaw: GridDataRaw;

	constructor(private tracabilityService: TracabilityService, private modalService: BsModalService,private storageService: StorageService) {
		// Body styling
		let body = document.body;
		body.className = 'app';
		const sessionPageSize = this.storageService.get('tracabilityPaginationPageSize', true);
		this.gridDataRaw = new GridDataRaw();
		this.gridDataRaw.size = !isNullOrUndefined(sessionPageSize) ? sessionPageSize : 10;
		this.gridHelper = new AgGridHelper('tracability', this.gridDataRaw.size, 10);
		this.filters = new TracabilityFilterRaw();
		this.serviceList = new Array();
	}

	ngOnInit() {
		// Init datePipe
		this.datePipe = new DatePipe('fr-FR');

		// // Init filters
		var parsedFilter = JSON.parse(sessionStorage.getItem('tracabilityFilter'));
		// Get filter stored in session if exist
		if (!isEmpty(parsedFilter)) {
			this.filters = Object.assign({}, parsedFilter);
			// Convert String dates to Dates format
		} else {
			this.filterList = [];
		}
		// get service list
		this.getServiceList();

		this.gridHelper.gridOptions = <GridOptions>(<unknown>{
			columnDefs: this.getColumnDefs(),
			defaultColDef: {
				resizable: true,
				suppressMenu: true
			},
			domLayout: 'autoHeight',
			rowHeight: 85,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			cacheBlockSize: 10,
			pagination: true,
			suppressPaginationPanel: true,
			maxBlocksInCache: 0,
			infiniteInitialRowCount: 1,
			paginationPageSize: this.gridDataRaw.size,
			suppressScrollOnNewData: true,
			masterDetail: true,
			detailCellRendererParams: this.detailCellRendererParams,
			animateRows: true,
			localeText: this.gridHelper.getLocaleText(),
			onGridReady: (params) => this.onGridReady(params),
			// onGridSizeChanged: (params) => this.onGridSizeChanged(params),
			onColumnMoved: (params) => this.onColumnChanged(params)
		});
		sessionStorage['tracabilityCurrentPage'] = this.gridHelper.gridApi?.paginationGetCurrentPage()||0;
		this.currentDate = new Date();
	}

	private getColumnDefs(): ColDef[] {
		this.columnDefs = [
			{
				headerName: 'Nom du service',
				field: 'parentTrace.serviceName',
				cellRenderer: 'agGroupCellRenderer',
				lockVisible: true,
				minWidth: 80,
				cellStyle: this.gridHelper.centerContent()
			},
			{
				headerName: "Date de l'appel",
				field: 'parentTrace.processingdate',
				lockVisible: true,
				minWidth: 150,
				maxWidth: 200,
				cellStyle: this.gridHelper.centerContent()
			},
			{
				headerName: 'Temps de réponse',
				field: 'parentTrace.responseTime',
				lockVisible: true,
				minWidth: 100,
				maxWidth: 150,
				cellStyle: this.gridHelper.centerContent()
			},
			{
				headerName: 'N° de PS',
				field: 'parentTrace.providerNumber',
				lockVisible: true,
				minWidth: 100,
				maxWidth: 150,
				cellStyle: this.gridHelper.centerContent()
			},
			{
				headerName: 'N° AMC/OTP',
				field: 'parentTrace.organizationNumber',
				lockVisible: true,
				minWidth: 80,
				maxWidth: 100,
				cellStyle: this.gridHelper.centerContent()
			},
			{
				headerName: 'INSEE du bénéficiaire',
				field: 'parentTrace.beneficiaryIdentificationNumber',
				lockVisible: true,
				minWidth: 150,
				maxWidth: 200,
				cellStyle: this.gridHelper.centerContent()
			},
			{
				headerName: 'Référence externe CGPASS',
				field: 'parentTrace.cgpassExternalReference',
				lockVisible: true,
				minWidth: 200,
				maxWidth: 250,
				cellStyle: this.gridHelper.rightContent()
			},
			{
				headerName: 'Code retour',
				field: 'parentTrace.returnCode',
				lockVisible: true,
				minWidth: 80,
				maxWidth: 100,
				cellStyle: this.gridHelper.centerContent()
			},
			{
				headerName: 'Libellé retour',
				field: 'parentTrace.returnlabel',
				lockVisible: true,
				minWidth: 300,
				cellStyle: {
					'white-space': 'normal',
					'align-items': 'center',
					'word-break': 'break-word',
					'font-size': '10px',
				},
				resizable: true
			},
			{
				headerName: '',
				field: 'options',
				minWidth: 50,
				maxWidth: 50,
				cellRenderer: TracabilityPreviewComponent,
				cellRendererParams: {
					iconClass: 'fa fa-eye',
					redirect: 'tracabilityDetails'
				},
				onCellClicked: (params) => this.onViewClicked(params, 'parent'),
				cellStyle: this.gridHelper.centerContent()
			}
		];
		this.detailCellRendererParams = {
			detailGridOptions: {
				columnDefs: [
					{ headerName: 'Nom du service', field: 'serviceName', minWidth: 100 },
					{ headerName: "Date de l'appel", field: 'processingdate', minWidth: 150 , maxWidth: 200},
					{ headerName: 'Temps de réponse', field: 'responseTime', minWidth: 100, maxWidth: 150 },
					{ headerName: 'N° de PS', field: 'providerNumber', minWidth: 100, maxWidth: 150 },
					{ headerName: 'N° AMC/OTP', field: 'organizationNumber', minWidth: 80 ,maxWidth: 100 },
					{ headerName: 'INSEE du bénéficiaire', field: 'beneficiaryIdentificationNumber', minWidth: 150 ,maxWidth: 200},
					{ headerName: 'Référence externe CGPASS', field: 'cgpassExternalReference', minWidth: 200,maxWidth: 250 },
					{ headerName: 'Code retour', field: 'returnCode', minWidth: 80 ,maxWidth: 100 },
					{ headerName: 'Libellé retour', field: 'returnlabel', minWidth: 200 },
					{
						headerName: '',
						field: 'options',
						minWidth: 50,
						maxWidth: 50,
						cellRenderer: TracabilityPreviewComponent,
						cellRendererParams: {
							iconClass: 'fa fa-eye',
							redirect: 'tracabilityDetails'
						},
						onCellClicked: (params) => this.onViewClicked(params, 'child'),
						cellStyle: this.gridHelper.centerContent()
					}
				],
				rowHeight: 50,
				headerHeight: 50,
				defaultColDef: { flex: 1, resizable: true, suppressMenu: true },
				localeText: this.gridHelper.getLocaleText()
			},
			getDetailRowData: function (params) {
				params.successCallback(params.data.childTrace);
			}
		};
		return this.columnDefs;
	}

	onGridReady(params) {
		const ctx = this;
		this.gridHelper.gridApi = params.api;
		this.gridHelper.gridColumnApi = params.columnApi;
		this.restoreGrid();
		this.gridHelper.gridApi.sizeColumnsToFit();
		this.setColumnOrder(window.innerWidth);
		this.gridHelper.gridApi.setServerSideDatasource(null);
		this.gridHelper.refreshView();
		var parsedFilter = JSON.parse(sessionStorage.getItem('tracabilityFilter'));
		if (!isEmpty(parsedFilter)) {
			this.launchSearch();
		} else {
			const dataSource: IServerSideDatasource = {
				getRows: function (paramsRows: IServerSideGetRowsParams) {
					ctx.tracabilityService.getTracability().subscribe(
						(results) => {
							ctx.rowCount = results.data.length;
							ctx.gridHelper.manageNoRowsOverlay(ctx.rowCount);

							// Set the pagination page size (number of rows per page)
							const pageSize = ctx.gridHelper.gridApi.paginationGetPageSize();
							const currentPage = ctx.gridHelper.gridApi.paginationGetCurrentPage();
							const startIndex = pageSize * currentPage;
							const endIndex = startIndex + pageSize;

							const displayedData = results.data.slice(startIndex, endIndex);
							paramsRows.success({ "rowData": displayedData, "rowCount": ctx.rowCount });
							const pageN = Number.parseInt(sessionStorage.getItem('tracabilityCurrentPage'));
							if (
								!isNullOrUndefined(pageN) &&
								pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
								pageN > 0
							) {
								ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
							}
							sessionStorage['tracabilityCurrentPage'] = null;
						},
						(err) => {
							ctx.showError = true;
							ctx.gridHelper.manageNoRowsOverlay(0);
							paramsRows.failCallback();
						}
					);
				}
			};
			this.gridHelper.gridApi.setServerSideDatasource(dataSource);
			this.gridHelper.gridApi.sizeColumnsToFit();
		}
	}

	launchSearch(): void {
		const ctx = this;
		this.filters.beneficiaryIdentificationNumber = this.filters.beneficiaryIdentificationNumber
			? this.filters.beneficiaryIdentificationNumber.trim()
			: null;
		this.filters.cgpassExternalReference = this.filters.cgpassExternalReference
			? this.filters.cgpassExternalReference.trim()
			: null;
		this.filters.providerNumber = this.filters.providerNumber ? this.filters.providerNumber : null;
		this.filters.serviceName = this.filters.serviceName ? this.filters.serviceName : null;
		// this.filters.returnCodeOK = isNullOrUndefined(this.filters.returnCodeOK) ? null : this.filters.returnCodeOK;

		this.filters.organizationNumber = this.filters.organizationNumber
			? this.filters.organizationNumber.trim()
			: null;

		// Convert String dates to Dates format
		this.filters.startProcessingDate = this.filters.startProcessingDate ;

		this.filters.endProcessingDate = this.filters.endProcessingDate;

		let formattedFilters = JSON.parse(JSON.stringify(this.filters));

		formattedFilters.startProcessingDate = this.filters.startProcessingDate;

		formattedFilters.endProcessingDate = this.filters.endProcessingDate;

		this.updateFilterList(formattedFilters);
		Object.keys(formattedFilters).forEach((key) => {
			if (isNullOrUndefined(formattedFilters[key])) {
				delete formattedFilters[key];
			}
		});
		const dataSource: IServerSideDatasource = {
			getRows: function (paramsRows: IServerSideGetRowsParams) {
				ctx.tracabilityService.getTracability(formattedFilters).subscribe(
					(results) => {
						ctx.rowCount = results.data.length;
						ctx.gridHelper.manageNoRowsOverlay(ctx.rowCount);

						// Set the pagination page size (number of rows per page)
						const pageSize = ctx.gridHelper.gridApi.paginationGetPageSize();
						const currentPage = ctx.gridHelper.gridApi.paginationGetCurrentPage();
						const startIndex = pageSize * currentPage;
						const endIndex = startIndex + pageSize;

						const displayedData = results.data.slice(startIndex, endIndex);
						paramsRows.success({ "rowData": displayedData, "rowCount": ctx.rowCount });

						const pageN = Number.parseInt(sessionStorage.getItem('tracabilityCurrentPage'));
						if (
							!isNullOrUndefined(pageN) &&
							pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
							pageN > 0
						) {
							ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
						}
						sessionStorage['tracabilityCurrentPage'] = null;
						ctx.showError = false;
					},
					(err) => {
						ctx.showError = true;
						ctx.gridHelper.manageNoRowsOverlay(0);
						paramsRows.fail();
					}
				);
			}
		};

		this.gridHelper.gridApi.setServerSideDatasource(dataSource);
		sessionStorage['tracabilityFilter'] = JSON.stringify(this.filters);
	}
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		let width = event.target.innerWidth;
		this.setColumnOrder(width);
	}
	setColumnOrder(width:any) {
		const columnDefs = this.getColumnDefs();
		if(width<=1422){
			const columnApi = this.gridHelper.gridColumnApi;
			const optionsColumnIndex = columnDefs.findIndex(column => column.field === 'options');
			if (optionsColumnIndex !== -1) {
				columnApi.moveColumn('options', 0);
			}
		}else {
			this.gridHelper.gridApi.sizeColumnsToFit();
			this.gridHelper.gridApi.setColumnDefs(columnDefs);
		}
	}
	onGridSizeChanged(params) {
		this.gridHelper.gridApi.sizeColumnsToFit();
	}

	restoreGrid() {
		this.gridHelper.restoreGridStatePrefix();
		this.gridDataRaw.size = this.gridHelper.paginationPageSize;
	}

	resetGridState() {
		this.gridHelper.resetGridState();
		this.gridDataRaw.size = this.gridHelper.defaultPaginationPageSize;
		this.gridHelper.gridApi.sizeColumnsToFit();
		//this.resetAllFilter();
	}

	toggleFilterDisplay() {
		if (this.initHidden) {
			this.initHidden = false;
			setTimeout(() => {
				this.showFilter = !this.showFilter;
			}, 10);
		} else {
			this.showFilter = !this.showFilter;
		}
	}
	resetAllFilter(): void {
		this.currentDate = new Date();
		this.initFilter();
		this.filters = new TracabilityFilterRaw();
		this.launchSearch();
		this.tracabilityService.reset();
	}

	initFilter() {
		// Liste des filtres
		this.tracabilityService.reset();
		this.updateFilterList(this.filters);
	}

	updateFilterList(filters) {
		var ctx = this;
		ctx.filterList = [];
		Object.keys(filters).forEach((key) => {
			if (!isNullOrUndefined(filters[key]) && filters[key] !== '') {
				ctx.filterList.push(ctx.translateFilterLabelValue(key, filters[key]));
			}
		});
		// Remove duplicated ids
		ctx.filterList = uniqBy(ctx.filterList, 'id');
		// filter null
		ctx.filterList = ctx.filterList.filter((item) => {
			return item.id != null;
		});
		this.currentDate= new Date();
	}

	translateFilterLabelValue(key, value) {
		var translatedKey = key;
		var formattedValue = value;
		var formattedKey = key;
		switch (key) {
			case 'organizationNumber':
				translatedKey = 'Numéro AMC/OTP';
				break;
			case 'serviceName':
				translatedKey = 'Nom du service';
				break;
			case 'cgpassExternalReference':
				translatedKey = 'Référence externe CGPASS';
				break;
			case 'beneficiaryIdentificationNumber':
				translatedKey = 'INSEE du bénéficiaire';
				break;
			case 'providerNumber':
				translatedKey = 'Numéro de PS';
				break;
			case 'returnCodeOK':
				translatedKey = 'Code retour';
				formattedValue = value ? 'OK' : 'KO';
				break;
			case 'startProcessingDate':
				translatedKey = "Date de l'appel après le";
				formattedValue = this.formatDate({ value: value });
				break;
			case 'endProcessingDate':
				translatedKey = "Date de l'appel avant le";
				formattedValue = this.formatDate({ value: value });
				break;
			default:
				break;
		}
		return new FilterItem().withId(formattedKey).withLabel(translatedKey).withValue(formattedValue).withValue2(value);
	}

	removeFilter(id) {
		var ctx = this;
		delete ctx.filters[id];
		var currentFilterList = ctx.filterList;
		const index = findIndex(currentFilterList, (filt) => filt.id === id);
		currentFilterList.splice(index, 1);
		ctx.filterList = currentFilterList;
		this.fillFilterObjectFromTagsOnly();
		ctx.launchSearch();
	}

	fillFilterObjectFromTagsOnly() {
		this.filters = new TracabilityFilterRaw();
		this.filterList.forEach(filter => {
			this.filters[filter.id] = filter.value2;
		});
	}

	getServiceList() {
		this.tracabilityService.getTracabilityService().subscribe((results) => {
			this.serviceList = results.data;
		});
	}

	onViewClicked(params, type) {
		if (type == 'parent') {
			const initialState = {
				url: params.data.parentTrace.url,
				request: params.data.parentTrace.request,
				response: params.data.parentTrace.response
			};
			this.modalService.show(TracabilityDetailsModalComponent, { initialState, class: 'modal-xl' });
		} else {
			const initialState = {
				url: params.data.url,
				request: params.data.request,
				response: params.data.response
			};
			this.modalService.show(TracabilityDetailsModalComponent, { initialState, class: 'modal-xl' });
		}
	}

	formatDate = (params): string => {
		if (params.value !== null && params.value !== 'Invalid Date') {
			return this.datePipe.transform(params.value, 'dd/MM/yyyy T HH:mm:ss.SS');
		}
	};
	onPaginationSizeChange(paginationSize: number): void {
		this.gridHelper.changePaginationSize(paginationSize);
		this.launchSearch();
		this.gridHelper.saveGridStatePrefix();
	}

	ngOnDestroy() {
		sessionStorage['tracabilityFilter'] = JSON.stringify(this.filters);
		sessionStorage['tracabilityCurrentPage'] = this.gridHelper.gridApi.paginationGetCurrentPage();
		this.gridHelper.saveGridStatePrefix();
	}
	onKeyDown(event) {
		this.launchSearch();
	}
	onColumnChanged(params: any): void {
		this.gridHelper.saveGridColumnState();
	}
}
