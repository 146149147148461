import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[submitOnEnter]'
})
export class SubmitOnEnterDirective {
  @Input('submitOnEnter') form: any;

  constructor() { }

  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
    event.keyCode === 13 ? this.form.ngSubmit.emit() : null ;
  }

}
