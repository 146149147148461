export class PsFilterRaw {
	numeroAdeli: String;
	rpps: String;
	nom: String;
	raisonSocialeEtablissement: String;
	telephoneEtablissment: String;
	codepostalEtablissment: String;
	villeEtablissment: String;
	faxEtablissment: String;
	balEmettriceSiteEmission: String;
	genSiteEmission: String;
	numeroMaitreSiteEmission: String;
	numeroEsclaveSiteEmission: String;
	idPsNot: Number;
	idPs: Number;
	isActif: boolean;
	sel: Number;
	dateEndDerniereTransmission: Date;
	dateStartDerniereTransmission: Date;
	dateExacteDerniereTransmission: Date;
	rappBancaire: boolean;
	email: string;
	constructor(data = null) {
		Object.assign(this, data);
	}
}
