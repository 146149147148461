import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

/**
 * formatte une date
 */

@Pipe({
	name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {
	datePipe: DatePipe;
	constructor() {
		this.datePipe = new DatePipe('fr-FR');
	}

	transform(data) {
		let formattedDate = new Date();
		if (data) {
			formattedDate.setFullYear(2000 + parseInt(data.substring(0, 2), 10));
			formattedDate.setMonth(parseInt(data.substring(2, 4), 10) - 1);
			formattedDate.setDate(data.substring(4, 6));
		}
		return this.datePipe.transform(formattedDate, 'shortDate');
	}

	 formatDate(data) {
		 let formattedDate = new Date();
		 if (data) {
			 formattedDate.setFullYear(2000 + parseInt(data.substring(4, 6)),10);
			 formattedDate.setMonth(parseInt(data.substring(2, 4), 10) - 1);
			 formattedDate.setDate(data.substring(0, 2));
		 }
		 return this.datePipe.transform(formattedDate, 'shortDate');
	}
}
