import { Component, OnInit, Input } from '@angular/core';
import { SiteEmissionService } from '../../../service/site-emission.service';
import { isNullOrUndefined } from 'util';
import { ToastrService } from 'ngx-toastr';
import { SiteEmissionDataRaw } from 'src/main/webapp/src/app/data/siteEmissionDataRaw';

@Component({
	selector: 'careweb-app-ps-site-reroutage',
	templateUrl: './ps-site-reroutage.component.html',
	styleUrls: [ './ps-site-reroutage.component.scss' ]
})
export class PsSiteReroutageComponent implements OnInit {
	showMessage: Boolean = true;
	statusMessage: String;
	@Input() idSite: number;
	@Input() idPs: number;
	siteReroutage: SiteEmissionDataRaw;
	siteEmissionList: SiteEmissionDataRaw[];

	typeTransportArray = [
		{ value: 1, code: 'MAIL', viewValue: 'Mail' },
		{ value: 2, code: 'X_MODEM', viewValue: 'X Modem' },
		{ value: 3, code: 'THALCOM', viewValue: 'Thalcom' }
	];

	constructor(private toastr: ToastrService, private siteEmissionSvc: SiteEmissionService) {
		this.siteEmissionList = new Array<SiteEmissionDataRaw>();
	}

	ngOnInit() {
		this.getListSiteEmissionByIdPs(this.idPs);
	}

	getListSiteEmissionByIdPs(idPs: number) {
		this.siteEmissionSvc.getSiteEmissionsByIdPs(idPs).subscribe((data) => {
			if (data) {
				this.siteEmissionList = new Array<SiteEmissionDataRaw>();
				this.siteEmissionList = data;
				this.siteReroutage = this.siteEmissionList.filter(
					(site) => site.idSite === this.idSite
				)[0].siteReroutage;
				this.siteEmissionList = this.siteEmissionList.filter((site) => site.idSite !== this.idSite);
				if (isNullOrUndefined(this.siteReroutage)) {
					this.showMessage = true;
					this.statusMessage = "Aucun Site de Reroutage n'a été défini pour ce site d'émission";
				} else {
					this.siteEmissionList = this.siteEmissionList.filter(
						(site) => site.idSite !== this.siteReroutage.idSite
					);
					this.showMessage = false;
				}
			}
		});
	}

	attachSiteReroutage(idSiteReroutage) {
		this.siteEmissionSvc.attachSiteReroutage(this.idSite, idSiteReroutage).subscribe(
			(data) => {
				this.toastr.success('Le Site Reroutage a été ajouté avec succès');
				this.getListSiteEmissionByIdPs(this.idPs);
			},
			(err) => {
				this.toastr.error(
					"Une erreur est survenue lors de l'ajout du site reroutage !!",
					'Ajout du site reroutage'
				);
			}
		);
	}

	dettachSiteReroutage() {
		this.siteEmissionSvc.dettachSiteReroutage(this.idSite).subscribe(
			(data) => {
				this.toastr.success('Le Site Reroutage a été détaché avec succès');
				this.siteReroutage = null;
				this.getListSiteEmissionByIdPs(this.idPs);
			},
			(err) => {
				this.toastr.error(
					'Une erreur est survenue lors de la supprssion du site reroutage !!',
					'Suppression du site reroutage'
				);
			}
		);
	}

	getNomTypeTransport(idTypeTransport) {
		return this.typeTransportArray.find((type) => type.value == idTypeTransport).viewValue;
	}
}
