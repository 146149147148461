import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorAnalyzer } from '../exceptions/http-error-analyzer';
/**
 * Réception d'une erreur 503 de la part de wildlfy.
 */
@Injectable()
export class ServiceUnavailableInterceptor implements HttpInterceptor {
	constructor() {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError((error) => {
				const analyzer = new HttpErrorAnalyzer(error);
				if (analyzer.isHttpServiceUnavailable()) {
					// @TODO create service unavailable file
					window.location.href = '../careweb-client/html-503/index.html';
					return EMPTY;
				} else {
					return throwError(error);
				}
			})
		);
	}
}
