import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RetoursEditablesService } from '../retours-editables.service';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'careweb-rsp-details-modal',
	templateUrl: './retour-details-modal.component.html',
	styleUrls: [ './retour-details-modal.component.scss' ]
})
export class RetourDetailsModalComponent implements OnInit {
	@Input() idRph;
	@Input() idArt;
	@Input() typeRetour;
	@Input() initialState;
	@Input() dateArt;
	@Input() dateRph;
	retourContent;
	datePipe: DatePipe;
	title;
	constructor(private modalRef: BsModalRef, private retourEditableSvc: RetoursEditablesService) {}

	ngOnInit(): void {
		this.datePipe = new DatePipe('fr-FR');
		const ctx = this;
		const criteria = {
			idRph: this.idRph,
			idArt: this.idArt,
			typeRetour: this.typeRetour
		};
		this.retourEditableSvc.getFileContent(criteria).subscribe((data) => {
			if (this.typeRetour == 'rph') {
				data = `${data}`.split("@@@").pop();
			}
			ctx.retourContent = `${data}`.split('\n').map((line, index) => `${index + 1}.   ${line}`).join('\n');
		});
		if (this.typeRetour == 'art') {
			this.title = 'ART_' + this.datePipe.transform(this.dateArt, 'shortDate');
		} else {
			this.title = 'RPH_' +  this.datePipe.transform(this.dateRph, 'shortDate');
		}
	}

	cancel() {
		this.modalRef.hide();
	}

	closeModal() {
		this.modalRef.hide();
	}
}
