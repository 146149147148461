import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CONF } from '../../core/constants';
import { HotlineDataRaw } from '../../data/hotlineDataRaw';
import {catchError, map} from 'rxjs/operators';
import { CustomSortDataRow } from '../../data/customSortDatRaw';
@Injectable({
	providedIn: 'root'
})
export class HotlineService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/hotline';
	constructor(private httpClient: HttpClient) {}

	getHotLineFilteredListFiltre(criteria: any, hotlineDataRaw: HotlineDataRaw): Observable<any> {
		const sorts: CustomSortDataRow[] = this.processSort(criteria.sorts);
		return this.httpClient.post<any>(
			this.baseUrl +
				'/search-filter?page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				'&idOct=' +
				criteria.idOct,
			{
				filters: hotlineDataRaw,
				sorts: sorts
			}
		);
	}

	processSort(sorts: CustomSortDataRow[]): CustomSortDataRow[] {
		for (let index = 0; index < sorts.length; index++) {
			const sort = sorts[index];

			if (sort.property === 'agent') {
				sort.property = 'agentAssigne.nom';

				const newSort = new CustomSortDataRow();
				newSort.property = 'agentAssigne.prenom';
				newSort.direction = sort.direction;
				newSort.priority = sort.priority;

				sorts.splice(index, 0, newSort);
			}
		}
		return sorts;
	}

	saveHotline(hotlineDataRaw: HotlineDataRaw): Observable<any> {
		return this.httpClient.post<any>(this.baseUrl + '/create', hotlineDataRaw).pipe(catchError(this.handleError));
	}

	updateHotline(hotlineDataRaw: HotlineDataRaw): Observable<any> {
		return this.httpClient.post<any>(this.baseUrl + '/update', hotlineDataRaw).pipe(catchError(this.handleError));
	}

	handleError(error: HttpErrorResponse) {
		return throwError(error);
	}

	getHotlineById(idInterventtion): Observable<HotlineDataRaw> {
		return this.httpClient.get<HotlineDataRaw>(this.baseUrl + '/' + idInterventtion);
	}

	getHotlineByIdReference(idReference): Observable<HotlineDataRaw> {
		return this.httpClient.get<HotlineDataRaw>(this.baseUrl + '/reference/' + idReference);
	}

	deleteIntervention(idIntervention: number): Observable<any> {
		return this.httpClient.delete(`${this.baseUrl}/${idIntervention}`);
	}
	exportHotline(criteria: any, hotlineDataRaw: HotlineDataRaw, fields: string[]): Observable<any> {
		const sorts: CustomSortDataRow[] = this.processSort(criteria.sorts);
		return this.httpClient.post<HttpResponse<Blob>>(
			this.baseUrl + '/export',
			{
				filters: hotlineDataRaw,
				fields: fields,
				sorts: sorts
			},
			{
				observe: 'response' as 'body',
				responseType: 'blob' as 'json'
			}
		).pipe(
			map((response: HttpResponse<Blob>): any => {
				return this.getFileInfosFromResponse(response, "Liste-Interventions.xlsx");
			})
		)
	}
	exportHotlineByEmail(criteria: any, hotlineDataRaw: HotlineDataRaw, fields: string[]): Observable<any> {
		const sorts: CustomSortDataRow[] = this.processSort(criteria.sorts);
		return this.httpClient.post<HttpResponse<Blob>>(
			this.baseUrl + '/export-by-email',
			{
				filters: hotlineDataRaw,
				fields: fields,
				sorts: sorts
			},
			{
				observe: 'response' as 'body',
				responseType: 'blob' as 'json'
			}
		);
	}
	getFileInfosFromResponse(response: HttpResponse<Blob>,fileName: string) {
		return {
			file: new Blob([ response.body ], { type: 'application/vnd.ms-excel' }),
			fileName: fileName
		};
	}

}
