<div>
    <div class="modal modal-show fade show" id="modalAddContact" tabindex="-1">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="inmodal">
                     <div class="modal-header pb-1">
                        <h3 class="modal-title mb-3">Exporter</h3>
                        <button class="close" name="btn_display_filter" data-dismiss="modal" type="button"
                            title="Fermer" (click)="closeModal()"><span aria-hidden="true">×</span></button>
                      </div>
                    <div class="modal-body pb-0">
                            <p> Vous allez exporter <b>{{ nbrElement | numberSeparator}}</b> lignes. </p>
                            <p>Le temps de génération peut prendre quelques minutes, comment voulez-vous procéder ?</p>
                            <div style="display: grid">
                                <div style="display:inline-flex;margin-right:10px">
                                    <input type="radio" name="modeExport" id="radioPlage_1" [(ngModel)]="modeExport" class="me-1" value="EXPORT"/>
                                    <label style="margin-bottom: 0.1rem" for="radioPlage_1">1 : Télécharger le fichier via le navigateur web, cela prendra quelques minutes.</label>
                                </div>
                                <div style="display:inline-flex ">
                                    <input type="radio" id="radioPlage_2" [(ngModel)]="modeExport" name="modeExport" class="me-1" value="EMAIL"/>
                                    <label style="margin-bottom: 0.1rem" for="radioPlage_2">2 : Recevoir le fichier Excel par mail.</label>
                                </div>
                            </div>
                       
                        <div class="form-block mt-4">
                                <div class="button-bar control">
                                    <button type="button" (click)="closeModal()"  class="btn btn-default "> Annuler
                                    </button>
                                    <button *ngIf="nbrElement > 0" type="button" (click)="export()" class="btn btn-primary">
                                      Exporter
                                    </button>
                                </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
