<div class="x-large-careweb-container">
    <section class="form-content">
        <!-- Titre H2 -->
        <div class="row form-title">
            <h2 class="col-md-6" *ngIf="isAuthorized()">Modification de l'organisme {{currentOrganisme.libelleCourt}}
            </h2>
            <h2 class="col-md-6" *ngIf="!isAuthorized()">Détails de l'organisme {{currentOrganisme.libelleCourt}}</h2>
            <div class="col-md-6 text-title">
                <button class="btn btn-default btn-lg" type="button" (click)="goBack()">Retour</button>
            </div>
        </div>
        <div class="form-block">
            <!-- Titre -->
            <div class="row">
                <div class="col">
                    <h3 class="title-border">Détails</h3>
                </div>
            </div>
            <div [formGroup]="orgaismeForm" class="container-fluid">
                <div class="row">
                    <div class="col-sm-2 col-form-label">Sigle *</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" formControlName="sigle" maxlength="13" [required]="true"
                            [ngClass]="{ 'is-invalid': btnClicked && formFields.sigle.errors }" />
                        <div *ngIf="btnClicked && formFields.sigle.errors" class="invalid-feedback">
                            Ce champ est obligatoire
                        </div>
                    </div>
                    <div class="col-sm-2 col-form-label">Libellé *</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" formControlName="libelle" maxlength="155"
                            [required]="true" [ngClass]="{ 'is-invalid': btnClicked && formFields.libelle.errors }" />
                        <div *ngIf="btnClicked && formFields.libelle.errors" class="invalid-feedback">
                            Ce champ est obligatoire
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Actif</div>
                    <div class="col-sm-4 form-group">
                        <ng-select name="actif" id="actifSelector" formControlName="actif" appendTo="body"
                            [clearable]="false">
                            <ng-option [value]="true">Oui</ng-option>
                            <ng-option [value]="false">Non</ng-option>
                        </ng-select>
                    </div>

                    <div *ngIf="isAuthorized()" class="col-sm-2 col-form-label">Retour regroupement *</div>
                    <div *ngIf="isAuthorized()" class="col-sm-4 form-group">
                        <ng-select name="retourRegroupement" id="retourRegroupementSelector" [clearable]="false"
                            formControlName="retourRegroupement" appendTo="body" [required]="true"
                            style="width:100%; height:calc(1.8em + .75rem + 2px)!important;" [clearable]="false"
                            [ngClass]="{ 'is-invalid': btnClicked && formFields.retourRegroupement.errors?.required }">
                            <ng-option [value]="true">Oui</ng-option>
                            <ng-option [value]="false">Non</ng-option>
                        </ng-select>
                        <div *ngIf="btnClicked && formFields.retourRegroupement.errors" class="invalid-feedback">
                            <div *ngIf="formFields.retourRegroupement.errors.required">Ce champ est obligatoire</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Adresse 1</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" formControlName="adresse1" maxlength="100" />
                    </div>
                    <div class="col-sm-2 col-form-label">Adresse 2</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" formControlName="adresse2" maxlength="100" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Code Postal</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" formControlName="codePostal"
                            onKeyPress="if(this.value.length==5) return false;"
                            oninput="this.value=this.value.replace(/[^\d]/,'').replace(/\s/g,'').replace( /\./gi,'');"
                            onpaste="this.value=this.value.replace(/[^\d]/,'').replace(/\s/g,'').replace( /\./gi,''); if(this.value.length==5) return false;"
                            pattern="[0-9]*" min="0" />
                    </div>
                    <div class="col-sm-2 col-form-label">Ville</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" formControlName="ville" maxlength="50" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Téléphone</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" formControlName="telephone"
                            onKeyPress="if(this.value.length==10) return false;"
                            oninput="this.value=this.value.replace(/[^\d]/,'').replace(/\s/g,'').replace( /\./gi,'');"
                            onpaste="this.value=this.value.replace(/[^\d]/,'').replace(/\s/g,'').replace( /\./gi,''); if(this.value.length==10) return false;"
                            pattern="[0-9]*" min="0" />
                    </div>
                    <div class="col-sm-2 col-form-label">Fax</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" formControlName="fax"
                            onKeyPress="if(this.value.length==10) return false;"
                            oninput="this.value=this.value.replace(/[^\d]/,'').replace(/\s/g,'').replace( /\./gi,'');"
                            onpaste="this.value=this.value.replace(/[^\d]/,'').replace(/\s/g,'').replace( /\./gi,''); if(this.value.length==10) return false;"
                            pattern="[0-9]*" min="0" />
                    </div>
                </div>
                <div *ngIf="isAuthorized()" class="row">
                    <div class="col-sm-2 col-form-label">Commentaire</div>
                    <div class="col-sm-10 form-group">
                        <textarea class="form-control-input" style="resize:horizontal;  resize: vertical;" autosize
                            rows="2" formControlName="commentaires">
                        </textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Régime</div>
                    <div class="col-sm-4 form-group">
                        <input readonly type="text" class="form-control" [value]= "currentOrganisme.typeOrganisme | organismeType" />
                    </div>
                    <div *ngIf="currentOrganisme.typeOrganisme=='RO'" class="col-sm-2 col-form-label">Tous centres</div>
                    <div *ngIf="currentOrganisme.typeOrganisme=='RO'" class="col-sm-4 form-group">
                        <ng-select name="tousCentres" id="tousCentresSelector" formControlName="tousCentres"
                            [clearable]="false" appendTo="body">
                            <ng-option [value]="true">Oui</ng-option>
                            <ng-option [value]="false">Non</ng-option>
                        </ng-select>
                    </div>
                    <div *ngIf="currentOrganisme.typeOrganisme=='RC'" class="col-sm-2 col-form-label">Code Officiel
                    </div>
                    <div *ngIf="currentOrganisme.typeOrganisme=='RC'" class="col-sm-4 form-group">
                        <input type="text" class="form-control" formControlName="codeOfficiel" />
                    </div>
                </div>
                <!-- Si Type organisme RO -->
                <div *ngIf="currentOrganisme.typeOrganisme=='RO'">
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Code G.R. *</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" formControlName="codeGr"
                                onKeyPress="if(this.value.length==2) return false;"
                                onpaste="if(this.value.length==2) return false;"
                                oninput="this.value=this.value.replace(/[^\d]/,'');"
                                onkeyup="this.value=this.value.replace(/[^\d]/,'');"
                                onkeydown="this.value=this.value.replace(/[^\d]/,'');" [required]="true"
                                [ngClass]="{ 'is-invalid': btnClicked && formFields.codeGr.errors?.required }" />
                            <div *ngIf="btnClicked && formFields.codeGr.errors" class="invalid-feedback">
                                <div *ngIf="formFields.codeGr.errors.required">Ce champ est obligatoire</div>
                            </div>
                        </div>
                        <div class="col-sm-2 col-form-label">Caisse C.A.S. *</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" formControlName="codeCaisseCas"
                                onKeyPress="if(this.value.length==3) return false;"
                                onpaste="if(this.value.length==3) return false;"
                                oninput="this.value=this.value.replace(/[^\d]/,'');"
                                onkeyup="this.value=this.value.replace(/[^\d]/,'');"
                                onkeydown="this.value=this.value.replace(/[^\d]/,'');" [required]="true"
                                [ngClass]="{ 'is-invalid': btnClicked && formFields.codeCaisseCas.errors?.required }" />
                            <div *ngIf="btnClicked && formFields.codeCaisseCas.errors" class="invalid-feedback">
                                <div *ngIf="formFields.codeCaisseCas.errors.required">Ce champ est obligatoire</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Centre C.A.S. * </div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" formControlName="codeCentreCas"
                                onKeyPress="this.value=this.value.replace(/[^\d]/,'');if(this.value.length==4) return false;"
                                onpaste="this.value=this.value.replace(/[^\d]/,'');if(this.value.length==4) return false;"
                                oninput="this.value=this.value.replace(/[^\d]/,'');"
                                onkeyup="this.value=this.value.replace(/[^\d]/,'');"
                                onkeydown="this.value=this.value.replace(/[^\d]/,'');" [required]="true"
                                [ngClass]="{ 'is-invalid': btnClicked && formFields.codeCentreCas.errors?.required }" />
                            <div *ngIf="btnClicked && formFields.codeCentreCas.errors" class="invalid-feedback">
                                <div *ngIf="formFields.codeCentreCas.errors.required">Ce champ est obligatoire</div>
                            </div>
                        </div>
                        <div *ngIf="isAuthorized()" class="col-sm-2 col-form-label">Code Organisme Type 1 *</div>
                        <div *ngIf="isAuthorized()" class="col-sm-4 form-group">
                            <input type="text" class="form-control" formControlName="codeOrgType1"
                                onKeyPress="this.value=this.value.replace(/[^\d]/,'');if(this.value.length==3) return false;"
                                onpaste="this.value=this.value.replace(/[^\d]/,'');if(this.value.length==3) return false;"
                                oninput="this.value=this.value.replace(/[^\d]/,'');"
                                onkeyup="this.value=this.value.replace(/[^\d]/,'');"
                                onkeydown="this.value=this.value.replace(/[^\d]/,'');" [required]="true"
                                [ngClass]="{ 'is-invalid': btnClicked && formFields.codeOrgType1.errors?.required }" />
                            <div *ngIf="btnClicked && formFields.codeOrgType1.errors" class="invalid-feedback">
                                <div *ngIf="formFields.codeOrgType1.errors.required">Ce champ est obligatoire</div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="isAuthorized()">
                        <div class="col-sm-2 col-form-label">Centre Info. du Type 0 *</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" formControlName="codeCentreInfoType0"
                                onKeyPress="this.value=this.value.replace(/[^\d]/,'');if(this.value.length==3) return false;"
                                onpaste="this.value=this.value.replace(/[^\d]/,'');if(this.value.length==3) return false;"
                                oninput="this.value=this.value.replace(/[^\d]/,'');"
                                onkeyup="this.value=this.value.replace(/[^\d]/,'');"
                                onkeydown="this.value=this.value.replace(/[^\d]/,'');"
                                [ngClass]="{ 'is-invalid': btnClicked && formFields.codeCentreInfoType0.errors?.required }" />
                            <div *ngIf="btnClicked && formFields.codeCentreInfoType0.errors" class="invalid-feedback">
                                <div *ngIf="formFields.codeCentreInfoType0.errors.required">Ce champ est obligatoire
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-2 col-form-label">Compression</div>
                        <div class="col-sm-4 form-group">
                            <ng-select name="envoisCompresses" id="envoisCompressesSelector" [clearable]="false"
                                formControlName="envoisCompresses" appendTo="body">
                                <ng-option [value]="true">Oui</ng-option>
                                <ng-option [value]="false">Non</ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="row" *ngIf="isAuthorized()">
                        <div class="col-sm-2 col-form-label">Code organisme type 1 générique *</div>
                        <div class="col-sm-4 form-group">
                            <ng-select name="codeOrgType1Gen" id="codeOrgType1GenSelector" [clearable]="false"
                                formControlName="codeOrgType1Gen" appendTo="body" [required]="true"
                                style="width:100%; height:calc(1.8em + .75rem + 2px)!important;" [clearable]="false"
                                [ngClass]="{ 'is-invalid': btnClicked && formFields.codeOrgType1Gen.errors?.required }">
                                <ng-option [value]="true">Oui</ng-option>
                                <ng-option [value]="false">Non</ng-option>
                            </ng-select>
                            <div *ngIf="btnClicked && formFields.codeOrgType1Gen.errors" class="invalid-feedback">
                                <div *ngIf="formFields.codeOrgType1Gen.errors.required">Ce champ est obligatoire</div>
                            </div>
                        </div>

                        <div class="col-sm-2 col-form-label pr-0">Code centre info type 0 générique *</div>
                        <div class="col-sm-4 form-group">
                            <ng-select name="codeCentreInfoType0Gen" id="codeCentreInfoType0GenSelector"
                                [clearable]="false" formControlName="codeCentreInfoType0Gen" appendTo="body"
                                [required]="true"
                                style="width:100%; height:calc(1.8em + .75rem + 2px)!important;" [clearable]="false"
                                [ngClass]="{ 'is-invalid': btnClicked && formFields.codeCentreInfoType0Gen.errors }">
                                <ng-option [value]="true">Oui</ng-option>
                                <ng-option [value]="false">Non</ng-option>
                            </ng-select>
                            <div *ngIf="btnClicked && formFields.codeCentreInfoType0Gen.errors?.required"
                                class="invalid-feedback">
                                <div *ngIf="formFields.codeCentreInfoType0Gen.errors.required">Ce champ est obligatoire
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End RO -->
                <!-- RC -->
                <form #retourForm="ngForm" appCompareDate>
                    <div class="row" *ngIf="isAuthorized() && currentOrganisme.typeOrganisme=='RC'">
                        <div class="col-sm-2 col-form-label">Début</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" name="dateMin" id="dateMin" #dateMin="ngModel"
                                bsDatepicker size="25" placement="bottom" [(ngModel)]="startDate" [maxDate]="endDate"
                                placeholder="Choisir une date" autocomplete="off"
                                value="{{ startDate | date:'dd/MM/yyyy' }}" [bsConfig]="{ containerClass: 'theme-kiss'  }" />
                            <careweb-error-messages [field]="dateMin" [touched]="true">
                            </careweb-error-messages>
                        </div>

                        <div class="col-sm-2 col-form-label pr-0">Fin</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" name="dateMax" id="dateMax" #dateMax="ngModel"
                                bsDatepicker size="25" placement="bottom" [bsConfig]="{ containerClass: 'theme-kiss'  }"
                                placeholder="Choisir une date" [(ngModel)]="endDate" [minDate]="startDate" autocomplete="off"
                                value="{{ endDate | date:'dd/MM/yyyy' }}" [maxDate]="null" />
                            <careweb-error-messages [field]="dateMax" [touched]="true">
                            </careweb-error-messages>
                        </div>
                    </div>
                </form>

                <div class="row" *ngIf="isAuthorized() && currentOrganisme.typeOrganisme=='RC'">
                    <div class="col-sm-2 col-form-label">AMC Seul</div>
                    <div class="col-sm-4 form-group">
                        <ng-select name="rcSeul" id="rcSeul" [clearable]="false" formControlName="rcSeul"
                            appendTo="body" [required]="true" class="p-0 form-control form-control-input"
                            style="width:100%; height:calc(1.8em + .75rem + 2px)!important;" [clearable]="false"
                            [ngClass]="{ 'is-invalid': btnClicked && formFields.rcSeul.errors?.required }">
                            <ng-option [value]="true">Oui</ng-option>
                            <ng-option [value]="false">Non</ng-option>
                        </ng-select>
                        <div *ngIf="btnClicked && formFields.rcSeul.errors" class="invalid-feedback">
                            <div *ngIf="formFields.rcSeul.errors.required">Ce champ est obligatoire</div>
                        </div>
                    </div>
                    <div class="col-sm-2 col-form-label">Aide Perenne</div>
                    <div class="col-sm-4 form-group">
                        <ng-select name="aidePerenne" id="aidePerenne" [clearable]="false" formControlName="aidePerenne"
                            appendTo="body" [required]="true" class="p-0 form-control form-control-input"
                            style="width:100%; height:calc(1.8em + .75rem + 2px)!important;" [clearable]="false"
                            [ngClass]="{ 'is-invalid': btnClicked && formFields.aidePerenne.errors?.required }">
                            <ng-option [value]="true">Oui</ng-option>
                            <ng-option [value]="false">Non</ng-option>
                        </ng-select>
                        <div *ngIf="btnClicked && formFields.aidePerenne.errors" class="invalid-feedback">
                            <div *ngIf="formFields.aidePerenne.errors.required">Ce champ est obligatoire</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="currentOrganisme.typeOrganisme=='RC'">
                    <div class="row" *ngIf="isAuthorized()">
                        <div class="col-sm-2 col-form-label">Code Entrée</div>
                        <div class="col-sm-9">
                            <textarea readonly class="form-control-input" style="resize:horizontal;  resize: vertical;"
                                autosize rows="2">{{currentOrganisme.libelleCodeEntree}}</textarea>
                        </div>
                        <div class="col-sm-1" style="float: right;text-align: center;">
                            <button type="button" class="btn btn-secondary pt-0 pb-0 pl-3 pr-3"
                                (click)="openModalCodeEntree()">
                                <i class="fa fa-edit" tooltip="Modifier les codes entrées" container="body"
                                    containerClass="tooltip-grid"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- End RC -->
            </div>
        </div>
        <div class="button-bar control mb-3">
            <button type="button" *ngIf="isAuthorized()" class="btn btn-default me-3" (click)="cancel()">
                Annuler
            </button>
            <!-- Bouton -->
            <button *ngIf="isAuthorized()" type="button" [disabled]="!orgaismeForm.dirty" class="btn btn-primary" (click)="save()">
                Enregistrer
            </button>
        </div>
    </section>
</div>
<!-- Modal logiciel add -->
<div *ngIf="showCodeEntreeModal">
    <div class="modal-backdrop"></div>
    <div class="modal modal-show fade show" id="modalAddLogiciel" tabindex="-1">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="inmodal">
                    <div class="modal-header pb-1">
                        <h3 class="modal-title">Gestion des codes d'entrée</h3>
                        <button class="close" (click)="closeModal()" name="btn_display_filter" type="button"
                            title="Fermer"><span aria-hidden="true">×</span></button>
                    </div>
                    <div class="modal-body pb-0">
                        <div class="form-block">
                            <div *ngIf="isAuthorized()" class="row m-2" [formGroup]="codeEntreeForm">
                                <div class="col-sm-2 col-form-label">Code Entrée</div>
                                <div class="col-sm-6">
                                    <input type="text" class="form-control" required formControlName="codeEntree"
                                        onKeyPress="if(this.value.length==10) return false;"
                                        oninput="this.value=this.value.replace(/[^\d]/,'').replace(/\s/g,'').replace( /\./gi,'');"
                                        onpaste="this.value=this.value.replace(/[^\d]/,'').replace(/\s/g,'').replace( /\./gi,''); if(this.value.length==10) return false;"
                                        pattern="[0-9]*" min="0"
                                        [ngClass]="{ 'is-invalid': newBtnClicked && this.codeEntreeForm.controls.codeEntree?.errors }" />
                                    <div *ngIf="newBtnClicked" class="invalid-feedback">
                                        <div *ngIf="this.codeEntreeForm.controls.codeEntree?.errors?.required">Ce champ
                                            est obligatoire
                                        </div>

                                        <div *ngIf="this.codeEntreeExists">Ce code entrée existe déjà</div>
                                    </div>
                                </div>
                                <div class="control">
                                    <button type="button" class="btn btn-primary" (click)="saveCodeEntree()">
                                        Ajouter
                                    </button>
                                </div>
                            </div>

                            <div id="myTabContent" class="tab-content">
                                <fieldset class="container-fluid">
                                    <ag-grid-angular style="width: 100%;" class="ag-theme-material"
                                        [gridOptions]="gridHelperCodeEntree.gridOptions">
                                    </ag-grid-angular>
                                    <careweb-pagination [gridApi]="gridHelperCodeEntree.gridApi"></careweb-pagination>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
            </div>
        </div>
    </div>
</div>
<!-- Modal confirmation de suppression -->
<careweb-confirm-modal (confirmModal)="confirmModal()" (showModal)="showModal($event)" [(visible)]="showConfirmModal"
    [text]="modalMessage">
</careweb-confirm-modal>