import { Pipe, PipeTransform } from '@angular/core';

/**
 * Ajout un caractére (val) au début d'une valeur (data) pour compléter un nombre minimale (limit)
 */
@Pipe({
	name: 'padStart'
})
export class PadStartPipe implements PipeTransform {
	constructor() {}

	transform(data, limit, val) {
		if (data) {
			return data.padStart(limit , val);
		}
	}
}
