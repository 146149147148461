import { RappComptePsDataRaw } from './../../data/rappComptePsDataRaw';
import { CustomSortDataRow } from './../../data/customSortDatRaw';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONF } from '../../core/constants';
import { TransactionDataRaw } from '../../data/transactionDataRaw';
import { BankTransactionDataRaw } from '../../data/bankTransactionDataRaw';

@Injectable({
	providedIn: 'root'
})
export class TransactionService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/rapp';
	private readonly baseEbicsUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/ebics';

	constructor(private httpClient: HttpClient) {}

	getTransactionsFilteredList(criteria: any, transactionDataRaw: TransactionDataRaw): Observable<any> {
		const sorts: CustomSortDataRow[] = criteria.sorts;
		return this.httpClient.post<any>(
			this.baseUrl + '/transactions/searchFilter' + '?page=' + criteria.page + '&size=' + criteria.size,
			{
				filters: transactionDataRaw,
				sorts: sorts
			}
		);
	}
	getTransactionsList(transactionDataRaw: TransactionDataRaw): Observable<any> {
		return this.httpClient.post<any>(this.baseUrl + '/transactions/getVrtList',transactionDataRaw) ;
	}

	public synchronizeOxlinTransactions(rappComptePsDataRaw: RappComptePsDataRaw): Observable<any> {
		return this.httpClient.post<any>(this.baseUrl + '/oxlin_synchronise', rappComptePsDataRaw);
	}
	
	public synchronizeEbicsTransactions(idPs: number): Observable<number[]> {
		return this.httpClient.get<number[]>(this.baseEbicsUrl + '/synchronise/'+ idPs);
	}

	public getBankTransactionsByDate(bankTransactionDataRaw: BankTransactionDataRaw, idPs: number): Observable<any> {
		return this.httpClient.post<any>(
			this.baseUrl + '/transactions/bankTransactionsByDate/' + idPs,
			bankTransactionDataRaw
		);
	}

	getAllCheckedVRT(bankTransactionDataRaw: BankTransactionDataRaw) {
		return this.httpClient.post<any>(this.baseUrl + '/transactions/allCheckedVRT', bankTransactionDataRaw);
    }
}
