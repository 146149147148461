import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ScrollTopService } from 'src/main/webapp/src/app/core/utilities/scroll-top.service';

@Component({
	selector: 'app-delete-account-ebics-modal',
	templateUrl: './delete-account-ebics-modal.component.html',
	styleUrls: ['./delete-account-ebics-modal.component.scss']
})
export class DeleteAccountEbicsModalComponent implements OnInit {
	@Input() idPs: Number;
	@Input() comptesAssocies : any;
	constructor(
		private modalRef: BsModalRef,
		private scrollTopService: ScrollTopService) { }

	ngOnInit() {
		this.scrollTopService.setScrollTop();
	}

	closeModal() {
		this.modalRef.hide();
	}


	
}
