import { Component } from '@angular/core';

@Component({
    selector: 'careweb-column-tooltip',
    template: `
        <p  container="body"
            [tooltip]="tooltipValue"
            placement="bottom"
            containerClass="tooltip-grid"
            class="overflow-hidden text-truncate rappro-tooltip"
        >
            {{value}}
        </p>
    `
})
export class ColumnMouvementComponent {
    value: string;
    tooltipValue = '';

    constructor() { }

    agInit(params: any): void {
        this.tooltipValue = this.getTooltipValue(params.value)
        this.value = params.value;
    }

    getTooltipValue(value) {
        switch (value) {
            case 'ACC': { return 'Acompte sur prestations'; break; }
            case 'AFF': { return 'Affranchissement'; break; }
            case 'API': { return 'Acompte caisse centralisatrice des paiements'; break; }
            case 'APR': { return 'Avance sur prestations'; break; }
            case 'IEC': { return 'Indu suite à erreur comptable'; break; }
            case 'IND': { return 'Indu sur prestations (erreur technique de destination)'; break; }
            case 'IPH': { return 'Indu sur avance pharmacie'; break; }
            case 'IPI': { return 'Créance non gérée par caisse gestionnaire (en dispositif caisse centralisatrice des paiements)'; break; }
            case 'ISP': { return 'Impayé mandat ou virement solde'; break; }
            case 'ODL': { return 'Opposition interne paiement en double'; break; }
            case 'OID': { return 'Opposition interne diverse'; break; }
            case 'REL': { return 'Reliquat'; break; }
            case 'VMI': { return 'Virement ou mandat impayé'; break; }
            case 'PSQ': { return 'Indu article L133.4'; break; }
            case 'ANT': { return 'Annulation totale'; break; }
            case 'CIN': { return 'Constat initial'; break; }
            case 'LOP': { return 'Main levée d’opposition partielle'; break; }
            case 'LOT': { return 'Main levée d’opposition totale'; break; }
            case 'NVP': { return 'Admission en non-valeur partielle'; break; }
            case 'NVT': { return 'Admission en non-valeur totale'; break; }
            case 'PAI': { return 'Paiement'; break; }
            case 'PPR': { return 'Passage en profit'; break; }
            case 'RAS': { return "Remboursement à l'assuré"; break; }
            case 'RDP': { return 'Remise de dette partielle'; break; }
            case 'RDS': { return 'Remise de dette (seuil)'; break; }
            case 'RDT': { return 'Remise de dette totale'; break; }
            case 'REL': { return "Rectification d'un élément signalétique"; break; }
            case 'RMO': { return "Rectification d'un montant"; break; }
            case 'RPR': { return 'Retenue sur prestation'; break; }
            default: { return value; break; }

        }
    }

}
