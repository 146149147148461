<div class="modal modal-show fade show" *ngIf="visible" id="confirmationModal" tabindex="-1">
  <!-- La largeur se précise ici ex : .modal-dialog .modal-xl -->
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{title}}</h5>
        <button type="button" (click)="cancel()" class="close" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p *ngIf="!showAlert">{{text}}</p>
        <div class="alert alert-info" *ngIf="showAlert">
          <p>{{text}}</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="cancel()" class="btn btn-default" *ngIf="labelCancelButton">{{labelCancelButton}}</button>
        <button type="button" (click)="confirm()" class="btn btn-primary" *ngIf="labelValidButton">{{labelValidButton}}</button>
      </div>
    </div>
  </div>
</div>