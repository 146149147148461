import { DictRuleModalComponent } from './dict-rule-modal/dict-rule-modal.component';
import { Component, HostListener, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DictRuleRappService } from './dictRuleRapp.service';
import { ToastrService } from 'ngx-toastr';
import { isEmpty, uniqBy } from 'lodash-es';
import { AgGridHelper } from '../../components/ag-grid/ag-grid-helper';
import { IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { gridConvertSort } from '../../components/ag-grid/ag-grid.utils';
import { ColDef } from 'ag-grid-enterprise';
import { GridDataRaw } from '../../data/gridDataRaw';
import { isNullOrUndefined } from 'util';
import { StorageService } from '../../shared/storage-service';
import { EditRuleColumnComponent } from './edit-rule-column/edit-rule-column.component';
import { DeleteRuleColumnComponent } from './delete-rule-column/delete-rule-column.component';
import { DictRappRule } from "../../data/dictRappRule";
import { FilterItem } from "../../data/filters/filter-item";
import * as _ from "lodash-es";
import { DictRuleCpamModalComponent } from "./dict-rule-cpam-modal/dict-rule-modal.component";
import { DictRappkeyword } from "../../data/dictRappMotCle";
import { AddRuleColumnComponent } from './add-rule-column/add-rule-column.component';

@Component({
    selector: 'careweb-app-dict-rule-rapp',
    templateUrl: './dict-rule-rapp.component.html',
    styleUrls: ['./dict-rule-rapp.component.scss']
})
export class DictRuleRappComponent implements OnInit {
    dictRuleModal: BsModalRef;
    rulesGridHelper: AgGridHelper;
    rulesGridHelperCpam: AgGridHelper;
    sorts: any;
    gridDataRaw: GridDataRaw;
    gridDataRaw2: GridDataRaw;
    showFilter = false;
    initHidden = true;
    showGridRappro = true;
    showGridRapproTypeCpam = false;
    filters = new DictRappRule();
    filtersCpam = new DictRappkeyword();
    rulesDataRaw: DictRappRule;
    rulesCpamDataRaw: DictRappkeyword;
    dictRappkeyword: DictRappkeyword;
    paginationSize: number;
    filterList: FilterItem[];
    filterCpamList: FilterItem[];
    protected filterListTmp: FilterItem[];
    protected filterListCpamTmp: FilterItem[];
    active: number = 1;
    static MIN_WIDTH = 1163;
    countDisabledCPAM = 0;
    showAlert = true;

    constructor(private modalService: BsModalService, private dictRuleRapp: DictRuleRappService, private toastr: ToastrService,
        private storageService: StorageService) {
        const sessionPageSize = this.storageService.get('rulePaginationPageSize', true);
        this.gridDataRaw = new GridDataRaw();
        this.gridDataRaw2 = new GridDataRaw();
        this.gridDataRaw.size = !isNullOrUndefined(sessionPageSize) ? sessionPageSize : 10;
        this.gridDataRaw2.size = !isNullOrUndefined(sessionPageSize) ? sessionPageSize : 10;
        this.rulesGridHelper = new AgGridHelper('rule', this.gridDataRaw.size, 10);
        this.rulesGridHelperCpam = new AgGridHelper('ruleCpam', this.gridDataRaw2.size, 10);
        this.rulesDataRaw = new DictRappRule();
        this.dictRappkeyword = new DictRappkeyword();
        this.getActiveTab();
    }

    ngOnInit() {
        this.parseFilters();
        this.initRulesGrid();
        this.initRulesGridCpam();
        this.loadDisabledCpam();
    }

    loadDisabledCpam() {
        this.dictRuleRapp.countDisabledRule().subscribe(count => {
            this.countDisabledCPAM = count;
        })
    }

    parseFilters() {
        const parsedFilter = JSON.parse(sessionStorage.getItem('dictRuleFiltre'));
        const parsedFilterCpam = JSON.parse(sessionStorage.getItem('dictMotFiltre'));
        if (!isEmpty(parsedFilter)) {
            this.filters = Object.assign({}, parsedFilter);
        } else {
            this.filterList = [new FilterItem()];
        }
        if (!isEmpty(parsedFilterCpam)) {
            this.filtersCpam = Object.assign({}, parsedFilterCpam);
        } else {
            this.filterCpamList = [new FilterItem()];
        }
    }

    toggleFilterDisplay() {
        if (this.initHidden) {
            this.initHidden = false;
            setTimeout(() => {
                this.showFilter = !this.showFilter;
            }, 10);
            this.sizeColumnsToFit();
        } else {
            this.showFilter = !this.showFilter;
            this.sizeColumnsToFit();
        }
    }
    sizeColumnsToFit() {
        setTimeout(() => {
            this.rulesGridHelper.gridApi.sizeColumnsToFit();
            this.rulesGridHelperCpam.gridApi?.sizeColumnsToFit();
        }, 200);
    }
    sizeColumnsToFitRules() {
        setTimeout(() => {
            this.rulesGridHelper.gridApi.sizeColumnsToFit();
        }, 200);
    }
    sizeColumnsToFitRuleCpam() {
        setTimeout(() => {
            this.rulesGridHelperCpam.gridApi?.sizeColumnsToFit();
        }, 200);
    }
    resetAllFilter(): void {
        this.initFilter();
        if (this.showGridRappro) {
            this.filters = new DictRappRule();
            this.launchSearch();
            this.resetFilter();
        } else {
            this.filtersCpam = new DictRappkeyword();
            this.launchSearchCpam();
            this.resetFilterCpam();
        }
    }

    resetFilter() {
        this.filterList = [];
        this.filterListTmp = [];
    }

    resetFilterCpam() {
        this.filterCpamList = [];
        this.filterListCpamTmp = [];
    }

    updateFilterList(filters) {
        const ctx = this;
        ctx.filterList = [];
        Object.keys(filters).forEach((key) => {
            if (filters[key] !== null && filters[key] !== '') {
                ctx.filterList.push(ctx.translateFilterLabelValue(key, filters[key]));
            }
        });
        // Remove duplicated ids
        ctx.filterList = uniqBy(ctx.filterList, 'id');
        // filter null
        ctx.filterList = ctx.filterList.filter((item) => {
            return item.id != null;
        });
    }

    updateFilterListCpam(filters) {
        const ctx = this;
        ctx.filterCpamList = [];
        Object.keys(filters).forEach((key) => {
            if (filters[key] !== null && filters[key] !== '') {
                ctx.filterCpamList.push(ctx.translateFilterLabelValue(key, filters[key]));
            }
        });
        ctx.filterCpamList = uniqBy(ctx.filterCpamList, 'id');
        ctx.filterCpamList = ctx.filterCpamList.filter((item) => {
            return item.id != null;
        })
    }

    initFilter() {
        if (this.showGridRappro) {
            this.resetFilter();
            this.updateFilterList(this.filters);
        } else {
            this.resetFilterCpam();
            this.updateFilterListCpam(this.filtersCpam);
        }
    }

    removeFilter(id) {
        if (this.showGridRappro) {
            delete this.filters[id];
            const currentFilterList = this.filterList;
            const index = _.findIndex(currentFilterList, (filt) => filt.id === id);
            currentFilterList.splice(index, 1);
            this.filterList = currentFilterList;
            this.fillFilterObjectFromTagsOnly();
            this.launchSearch();
        } else {
            delete this.filtersCpam[id];
            const currentFilterCpamList = this.filterCpamList;
            const index = _.findIndex(currentFilterCpamList, (filt) => filt.id === id);
            currentFilterCpamList.splice(index, 1);
            this.filterCpamList = currentFilterCpamList;
            this.fillFilterObjectFromTagsOnly();
            this.launchSearchCpam();
        }
    }

    fillFilterObjectFromTagsOnly() {
        if (this.showGridRappro) {
            this.filters = new DictRappRule();
            this.filterList.forEach((filter) => {
                this.filters[filter.id] = filter.value2;
            });
        } else {
            this.filtersCpam = new DictRappkeyword();
            this.filterCpamList.forEach((filter) => {
                this.filtersCpam[filter.id] = filter.value2
            });
        }
    }

    translateFilterLabelValue(key, value) {
        let translatedKey = key;
        let formattedValue = value;
        let formattedKey = key;
        switch (key) {
            case 'keyLabel':
                translatedKey = 'Mot clé virement';
                break;
            case 'organismeLabel':
                translatedKey = 'Organisme';
                break;
            case 'codeRegle':
                translatedKey = 'Code Règle';
                break;
            case 'motCleLabelVrt':
                translatedKey = 'Mot clé virement';
                break;
            case 'motCleLabelV1':
                translatedKey = 'Mot clé du "Libellé de VRT 1" des RSP';
                break;
            default:
                break;
        }
        return new FilterItem().withId(formattedKey).withLabel(translatedKey).withValue(formattedValue).withValue2(value);
    }

    openDictRuleModal(titre: string, dictRule?: any, isDeleteModale?: boolean): void {
        this.dictRuleModal = this.modalService.show(DictRuleModalComponent, {
            initialState: {
                title: titre,
                dictRule: dictRule,
                isDeleteModale: isDeleteModale
            }
        });
        const sub = this.modalService.onHide.subscribe(() => {
            if (!isEmpty(this.dictRuleModal.content.rule) && !isDeleteModale) {
                if (dictRule) {
                    this.dictRuleRapp.updateRule(this.dictRuleModal.content.rule).subscribe(data => {
                        this.toastr.success("La règle de rapprochement automatique a bien été modifiée pour l'organisme "
                            + data.organismeLabel);
                        this.initRulesGridData();
                    });
                } else {
                    this.dictRuleRapp.saveRule(this.dictRuleModal.content.rule).subscribe(data => {
                        this.toastr.success("La nouvelle règle de rapprochement automatique a bien été ajoutée au dictionnaire pour l'organisme " + data.organismeLabel);
                        this.initRulesGridData();
                    });
                }
            } else if (this.dictRuleModal.content.isToDelete) {
                
                this.dictRuleRapp.deleteRule(dictRule.idRule).subscribe(data => {
                    this.toastr.success("La règle de rapprochement automatique a bien été supprimée pour l'organisme " + data.organismeLabel);
                    this.initRulesGridData();
                });
            }
            sub.unsubscribe();
        },
            error => this.toastr.error("Error: " + error)
        );
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        let width = event.target.innerWidth;
        this.setColumnOrderRule(width);
        this.setColumnOrderRulesCpam(width);
    }

    setColumnOrderRule(width: any) {
        const columnDefs = this.getColumnDefsRule();
        if (width < 1380) {
            const columnApi = this.rulesGridHelper.gridColumnApi;
            const optionsColumnIndex = columnDefs.findIndex(column => column.field === 'option');
            if (optionsColumnIndex !== -1) {
                columnApi.moveColumn('option', 0);
                columnApi.moveColumn('edit', 1);
            }
        } else {
            this.rulesGridHelper.gridApi.setColumnDefs(columnDefs);
        }
        this.sizeColumnsToFitRules();

    }

    setColumnOrderRulesCpam(width: any) {
        const columnDefs = this.getColumnDefsRuleCpam();
        if (width < 1280) {
            const columnApi = this.rulesGridHelperCpam.gridColumnApi;
            const optionsColumnIndex = columnDefs.findIndex(column => column.field === 'editCpam');
            if (optionsColumnIndex !== -1) {
                columnApi.moveColumn('optionCpam', 0);
                columnApi.moveColumn('editCpam', 1);
            }
        } else {
            this.rulesGridHelperCpam.gridApi.setColumnDefs(columnDefs);
        }
        this.sizeColumnsToFitRuleCpam();

    }

    enableRuleCpam(rule) {
        this.dictRuleRapp.enableRuleCpam(rule.idDictRapp).subscribe(data => {
            this.toastr.success("La nouvelle règle de rapprochement automatique a bien été activée ");
            this.initRulesCpamGridData();
            this.loadDisabledCpam();
        });
    }

    openDictRuleCpamModal(titre: string, dictRule?: any, isDeleteModale?: boolean): void {
        this.dictRuleModal = this.modalService.show(DictRuleCpamModalComponent, {
            initialState: {
                title: titre,
                dictRuleCpam: dictRule,
                isDeleteModale: isDeleteModale
            }
        });
        const sub = this.modalService.onHide.subscribe(() => {
            if (!isEmpty(this.dictRuleModal.content.dictRappkeyword) && !isDeleteModale) {
                if (dictRule) {
                    this.dictRuleRapp.updateRuleCpam(this.dictRuleModal.content.dictRappkeyword).subscribe(data => {
                        this.toastr.success("La règle de rapprochement automatique a bien été modifiée pour l'organisme " + data.motCleLabelVrt);
                        this.initRulesCpamGridData();
                    });
                } else {
                    this.dictRuleRapp.saveRuleCpam(this.dictRuleModal.content.dictRappkeyword).subscribe(data => {
                        this.toastr.success("La nouvelle règle de rapprochement automatique a bien été ajoutée au dictionnaire pour l'organisme " + data.motCleLabelVrt);
                        this.initRulesCpamGridData();
                    });
                }
            } else if (this.dictRuleModal.content.isToDelete) {
                this.dictRuleRapp.deleteRuleCpam(dictRule.idDictRapp).subscribe(data => {
                    this.toastr.success("La règle de rapprochement automatique a bien été supprimée pour l'organisme " + data.motCleLabelVrt);
                    this.initRulesCpamGridData();
                    this.loadDisabledCpam();
                });
            }
            sub.unsubscribe();
        },
            error => this.toastr.error("Error: " + error)
        );
    }

    initRulesGrid() {
        this.rulesGridHelper.gridOptions = {
            columnDefs: this.getColumnDefsRule(),
            defaultColDef: {
                resizable: true,
                suppressMenu: true
            },
            domLayout: 'autoHeight',
            rowHeight: 50,
            headerHeight: 50,
            rowModelType: 'serverSide',
            suppressServerSideInfiniteScroll: false,
            pagination: true,
            cacheBlockSize: 10,
            maxBlocksInCache: 0,
            infiniteInitialRowCount: 1,
            paginationPageSize: this.gridDataRaw.size,
            paginateChildRows: true,
            suppressScrollOnNewData: true,
            suppressPaginationPanel: true,
            suppressContextMenu: true,
            enableCellTextSelection: true,
            localeText: this.rulesGridHelper.getLocaleText(),
            onGridReady: (params) => this.onRulesGridReady(params)
            // onGridSizeChanged: () => this.onGridRulesSizeChanged()
        };
    }

    initRulesGridCpam() {
        this.rulesGridHelperCpam.gridOptions = {
            columnDefs: this.getColumnDefsRuleCpam(),
            defaultColDef: {
                resizable: true,
                suppressMenu: true
            },
            domLayout: 'autoHeight',
            rowHeight: 50,
            headerHeight: 50,
            rowModelType: 'serverSide',
            suppressServerSideInfiniteScroll: false,
            pagination: true,
            cacheBlockSize: 10,
            maxBlocksInCache: 0,
            infiniteInitialRowCount: 1,
            paginationPageSize: this.gridDataRaw2.size,
            paginateChildRows: true,
            suppressScrollOnNewData: true,
            suppressPaginationPanel: true,
            suppressContextMenu: true,
            enableCellTextSelection: true,
            localeText: this.rulesGridHelper.getLocaleText(),
            onGridReady: (params) => this.onRulesCpamGridReady(params),
            // onGridSizeChanged: () => this.onGridRulesSizeChanged(),
            rowClassRules: {
                'dict-item-disabled': (params) => {
                    return params.data && params.data.enabled === false;
                }
            }
        };
    }

    getColumnDefsRule(): ColDef[] {
        return [{
            headerName: 'Mot clé virement',
            field: 'keyLabel',
            cellClass: ['text-center'],
            minWidth: 100,
            lockVisible: true,
            sortable: true
        },
        {
            headerName: 'Organisme',
            field: 'organismeLabel',
            cellClass: ['text-center'],
            minWidth: 100,
            lockVisible: true,
            sortable: true
        },
        {
            headerName: 'Code Régle',
            field: 'codeRegle',
            cellClass: ['text-center'],
            minWidth: 100,
            maxWidth: 100,
            lockVisible: true,
            sortable: true
        },
        {
            headerName: '',
            field: 'edit',
            minWidth: 50,
            maxWidth: 50,
            onCellClicked: (params) => this.openDictRuleModal('Modifier la règle du dictionnaire', params.data),
            cellRenderer: EditRuleColumnComponent,
            lockVisible: true,
            cellClass: ['text-center'],
        },
        {
            headerName: '',
            field: 'option',
            minWidth: 50,
            maxWidth: 50,
            onCellClicked: (params) => this.openDictRuleModal('Supprimer la règle du dictionnaire', params.data, true),
            cellRenderer: DeleteRuleColumnComponent,
            lockVisible: true,
            cellClass: ['text-center'],
        }
        ];
    }

    getColumnDefsRuleCpam(): ColDef[] {
        return [{
            headerName: 'Mot clé virement',
            field: 'motCleLabelVrt',
            cellClass: ['text-center'],
            minWidth: 150,
            lockVisible: true,
            sortable: true
        }, {
            headerName: 'Mot clé du "Libellé de VRT 1" des RSP',
            field: 'motCleLabelV1',
            cellClass: ['text-center'],
            minWidth: 150,
            lockVisible: true,
            sortable: true
        }, {
            headerName: '',
            field: 'editCpam',
            minWidth: 60,
            maxWidth: 60,
            onCellClicked: (params) => {
                if (params.data.enabled) {
                    this.openDictRuleCpamModal('Modification de la règle de dictionnaire', params.data);
                } else {
                    this.enableRuleCpam(params.data);
                }
            },
            cellRendererSelector: (params) => {
                if (params.data.enabled) {
                    return {
                        component: EditRuleColumnComponent,
                    };
                } else {
                    return {
                        component: AddRuleColumnComponent,
                    };
                }
            },
            // cellRenderer: EditRuleColumnComponent,
            lockVisible: true,
            cellClass: ['text-center'],
        }, {
            headerName: '',
            field: 'optionCpam',
            minWidth: 60,
            maxWidth: 60,
            onCellClicked: (params) => this.openDictRuleCpamModal('Supprimer la règle du dictionnaire', params.data, true),
            cellRenderer: DeleteRuleColumnComponent,
            lockVisible: true,
            cellClass: ['text-center'],
        }
        ];
    }

    onRulesGridReady(params: any) {
        this.rulesGridHelper.gridApi = params.api;
        this.rulesGridHelper.gridColumnApi = params.columnApi;
        this.restoreGrid();
        if (this.active == 1) {
            this.initRulesGridData();
        }
        this.setColumnOrderRule(window.innerWidth);
    }

    onRulesCpamGridReady(params: any) {
        this.rulesGridHelperCpam.gridApi = params.api;
        this.rulesGridHelperCpam.gridColumnApi = params.columnApi;
        this.restoreGridCPAM();
        if (this.active == 2) {
            this.initRulesCpamGridData();
        }
        if (window.innerWidth > DictRuleRappComponent.MIN_WIDTH) {
            this.sizeColumnsToFitRuleCpam();
        }
    }

    restoreGridCPAM() {
        this.rulesGridHelperCpam.restoreGridStatePrefix();
        this.gridDataRaw2.size = this.rulesGridHelperCpam.paginationPageSize;
        //this.sizeColumnsToFitRuleCpam();
    }

    onGridRulesSizeChanged() {
        this.sizeColumnsToFitRules();
    }

    restoreGrid() {
        this.rulesGridHelper.restoreGridStatePrefix();
        this.gridDataRaw.size = this.rulesGridHelper.paginationPageSize;
    }

    onPaginationSizeChange(paginationSize: number): void {
        this.rulesGridHelper.changePaginationSize(paginationSize);
        this.launchSearch();
        this.rulesGridHelper.saveGridStatePrefix();
    }

    onPaginationSizeChangeTypeCpam(paginationSize: number): void {
        this.rulesGridHelperCpam.changePaginationSize(paginationSize);
        this.launchSearchCpam();
        this.rulesGridHelperCpam.saveGridStatePrefix();
    }

    resetGridState() {
        this.rulesGridHelper.resetGridState();
        this.gridDataRaw.size = this.rulesGridHelper.defaultPaginationPageSize;
    }

    resetGridStateCpam() {
        this.rulesGridHelperCpam.resetGridState();
        //this.sizeColumnsToFitRuleCpam();
        this.gridDataRaw2.size = this.rulesGridHelperCpam.defaultPaginationPageSize;
    }

    initRulesGridData() {

        this.launchSearch();
        //this.sizeColumnsToFitRules();
    }

    initRulesCpamGridData() {
        this.launchSearchCpam();
    }

    onKeyDown(event) {
        if (this.showGridRappro) {
            this.launchSearch();
        } else if (this.showGridRapproTypeCpam) {
            this.launchSearchCpam();
        }
    }

    launchSearch() {
        const ctx = this;
        // this.showGridRappro=true;
        // this.showGridRapproTypeCpam=false
        //this.rulesGridHelper.gridApi.sizeColumnsToFit();
        this.rulesDataRaw = new DictRappRule();
        this.rulesDataRaw.keyLabel = this.filters.keyLabel ? this.filters.keyLabel : null;
        this.rulesDataRaw.organismeLabel = this.filters.organismeLabel ? this.filters.organismeLabel : null;
        this.rulesDataRaw.codeRegle = this.filters.codeRegle ? this.filters.codeRegle : null;
        this.updateFilterList(this.filters);

        const dataSource: IServerSideDatasource = {
            getRows: function (paramsRows: IServerSideGetRowsParams) {
                ctx.sorts = gridConvertSort(ctx.rulesGridHelper.gridSortModel(), []);
                const sortsRules = ctx.sorts ? ctx.sorts : null;
                const criteria = {
                    page: ctx.rulesGridHelper.gridApi.paginationGetCurrentPage() || 0,
                    size: ctx.rulesGridHelper.gridApi.paginationGetPageSize(),
                    sortsRules
                };
                ctx.dictRuleRapp.getRulesFilteredList(criteria, ctx.rulesDataRaw).subscribe(
                    (data) => {
                        const rowCount = data.totalElements;
                        ctx.rulesGridHelper.manageNoRowsOverlay(rowCount);
                        paramsRows.success({
                            "rowData": data.content,
                            "rowCount": rowCount
                        });
                        const pageN = Number.parseInt(sessionStorage.getItem('ruleCurrentPage'));
                        if (!isNullOrUndefined(pageN) && pageN !== ctx.rulesGridHelper.gridApi.paginationGetCurrentPage() && pageN > 0) {
                            ctx.rulesGridHelper.gridApi.paginationGoToPage(pageN - 1);
                        }
                        sessionStorage['ruleCurrentPage'] = null;
                        ctx.setColumnOrderRule(window.innerWidth);
                        ctx.showGridRapproTypeCpam = false;
                        ctx.showGridRappro = true;
                    },
                    () => paramsRows.fail()
                );
            }
        };
        this.rulesGridHelper.gridApi.setServerSideDatasource(dataSource);
        sessionStorage['dictRuleFiltre'] = JSON.stringify(this.filters);
    }

    launchSearchCpam() {
        const ctx = this;
        // this.showGridRappro=false;
        // this.showGridRapproTypeCpam=true;
        this.rulesCpamDataRaw = new DictRappkeyword();
        this.rulesCpamDataRaw.motCleLabelVrt = this.filtersCpam.motCleLabelVrt ? this.filtersCpam.motCleLabelVrt : null;
        this.rulesCpamDataRaw.motCleLabelV1 = this.filtersCpam.motCleLabelV1 ? this.filtersCpam.motCleLabelV1 : null;
        this.updateFilterListCpam(this.filtersCpam);
        // this.rulesGridHelperCpam.gridApi.sizeColumnsToFit();
        const dataSource: IServerSideDatasource = {
            getRows: function (paramsRows: IServerSideGetRowsParams) {
                ctx.sorts = gridConvertSort(ctx.rulesGridHelperCpam.gridSortModel(), []);
                const sortsRules = ctx.sorts ? ctx.sorts : null;
                const criteria = {
                    page: ctx.rulesGridHelperCpam.gridApi.paginationGetCurrentPage() || 0,
                    size: ctx.rulesGridHelperCpam.gridApi.paginationGetPageSize(),
                    sortsRules
                };
                ctx.dictRuleRapp.getListRulesMotClePageable(criteria, ctx.rulesCpamDataRaw).subscribe(
                    (data) => {
                        const rowCount = data.totalElements;
                        ctx.rulesGridHelperCpam.manageNoRowsOverlay(rowCount);
                        paramsRows.success({
                            "rowData": data.content,
                            "rowCount": rowCount
                        });
                        const pageN = Number.parseInt(sessionStorage.getItem('ruleCpamCurrentPage'));
                        if (!isNullOrUndefined(pageN) && pageN !== ctx.rulesGridHelperCpam.gridApi.paginationGetCurrentPage() && pageN > 0) {
                            ctx.rulesGridHelperCpam.gridApi.paginationGoToPage(pageN - 1);
                        }
                        sessionStorage['ruleCpamCurrentPage'] = null;
                        ctx.setColumnOrderRulesCpam(window.innerWidth);
                        ctx.showGridRappro = false;
                        ctx.showGridRapproTypeCpam = true;
                    },
                    () => paramsRows.fail()
                );
            }
        };
        this.rulesGridHelperCpam.gridApi.setServerSideDatasource(dataSource);
        if (window.innerWidth > DictRuleRappComponent.MIN_WIDTH) {
            this.sizeColumnsToFitRuleCpam();
        }
        sessionStorage['dictMotFiltre'] = JSON.stringify(this.filtersCpam);
    }

    ngOnDestroy(): void {
        sessionStorage['dictRuleFiltre'] = JSON.stringify(this.filters);
        sessionStorage['dictMotFiltre'] = JSON.stringify(this.filtersCpam);
    }
    onTabChange(event: any) {
        const activeTabId = event.nextId;
        this.active = activeTabId;
        sessionStorage.setItem('dictionnaire-tab', activeTabId);
    }
    getActiveTab() {
        if (sessionStorage.getItem('dictionnaire-tab') && _.isInteger(parseInt(sessionStorage.getItem('dictionnaire-tab')))) {
            this.active = parseInt(sessionStorage.getItem('dictionnaire-tab'));
        } else {
            this.active = 1;
        }
    }


}