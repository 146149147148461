<div class="modal modal-show fade show" id="modalPslogiciel" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel">
    <div class="modal-dialog modal-xxl" role="document">
        <div class="modal-content">
            <div class="inmodal">

                <div class="modal-header pb-1">
                    <h3 class="modal-title">Informations du paramètre</h3>
                    <button class="close" name="btn_display_filter" data-dismiss="modal" type="button" title="Fermer"
                        (click)="closeModal()"><span aria-hidden="true">×</span></button>
                </div>
                <div class="modal-body pb-0">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="active"  [destroyOnHide]="false" class="nav-tabs">
                        <li [ngbNavItem]="1"title="Informations du paramètre">
                            		<button ngbNavLink>Informations du paramètre</button>
		                        <ng-template ngbNavContent>
                                <careweb-app-ps-site-emission-modification
                                    [idPs]="idPs"
                                    [numeroPs]="numeroPs"
                                    [idSite]="idSite"
                                    [codeLastTypeRetour]="codeLastTypeRetour">
                                </careweb-app-ps-site-emission-modification>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2"title="Site de Reroutage" *ngIf="showOngletSiteReroutage">
                            		<button ngbNavLink>Site de Reroutage</button>
		                <ng-template ngbNavContent>
                                <careweb-app-ps-site-reroutage 
                                    [idSite]="idSite"
                                    [idPs]="idPs">
                                </careweb-app-ps-site-reroutage>
                            </ng-template>
                        </li>
                    </ul> 
                    <div [ngbNavOutlet]="nav" class="mt-2"></div> 
                </div>
                <div class="modal-footer">
                </div>

            </div>
        </div>
    </div>

</div>