<careweb-help></careweb-help>
<div class="full-careweb-container">
  <div class="grid-container" id="grid">
    <h2 class="title-border mt-3">Comptabilité
      <careweb-open-help code="help-006"></careweb-open-help>
    </h2>
    <div>

      <section>
        <div class="form-block">
          <fieldset class="container-fluid">
            <div class="form-block">
              <!-- Titre -->
              <div class="row">
                <div class="col">
                  <h3 class="title-border">
                    Paramètres de la comptabilité
                  </h3>
                </div>
              </div>
              <fieldset class="container-fluid">
                <form #retourForm="ngForm" class="form-content"  (ngSubmit)="checkComptabiliteReport()" appCompareDate>
                  <div class="row">
                    <div class="col-sm-2 col-form-label">Rapport</div>
                    <div class="col-sm-4 form-group" style="display:cell">
                      <div class="field">
                        <ng-select name="typeComptabilite" [(ngModel)]="filters.reportId"
                          style="width:100%; height:calc(1.5em + .75rem + 2px)" [searchable]="false" appendTo="body"
                          (change)="changeInputs($event)"  [clearable]="false">
                          <ng-option value="COMP">Complémentaires transmises aux mutuelles</ng-option>
                          <ng-option value="RETARD">Factures en retard</ng-option>
                          <ng-option value="REJET">Factures rejetées</ng-option>
                          <ng-option value="REGLE">Factures réglées</ng-option>
                          <ng-option value="PRESTA">Prestations diverses</ng-option>
                        </ng-select>
                      </div>
                    </div>

                    <div class="col-sm-2 col-form-label" [hidden]="!existGroupNumeroPS()">Numéro ADELI</div>
                    <div class="col-sm-4 form-group required">
                      <!-- <h3 style="color:red" [hidden]="existGroupNumeroPS()">*
                        Ce professionnel de santé n'est pas reconnu
                      </h3> -->
                      <div class="field" [hidden]="!existGroupNumeroPS()">
                        <input type="text" id="input_num_ps_compta" name="numPs" #numPs="ngModel"
                          [(ngModel)]="filters.numeroAdeli" min="0" step="1" max="999999999" minlength="9"
                          oninput="this.value=this.value.replace(/[^\d]/,'');" onKeyPress="if(this.value.length==9) return false;" 
                          onpaste="if(this.value.length==9) return false;" [required]="true" [hidden]="!isAuthorized()" 
                          (input)="hideDownloadDiv()"/>
                          <careweb-error-messages  [hidden]="!isAuthorized() || isInputChange" [field]="numPs" [touched]="true">
                          </careweb-error-messages>

                          <ng-select #numeroPScomboBox="ngModel" name="numeroPScomboBox" id="numeroPScomboBox" placeholder="Sélectionner un PS" [searchable]="false" [clearable]="false"
                          [(ngModel)]="supervisedPs" appendTo="body" [hidden]="isAuthorized()" (change)="hideDownloadDiv()">
                          <ng-option *ngFor="let numeroPScomboBox of psDelegueListFilter" [value]="numeroPScomboBox">
                            {{numeroPScomboBox.numeroPsDelegue}}
                          </ng-option>
                        </ng-select>
                        <careweb-error-messages [hidden]="isAuthorized()|| isInputChange" [field]="numeroPScomboBox" [touched]="true">
                        </careweb-error-messages>
                        <!-- @TODO: le composant careweb-error-messages ne gère pas le minLength  -->
                        <!-- <div class="error-messages error" *ngIf="numPs.errors.minlength">error</div> -->
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-2 col-form-label">Date de début</div>
                    <div class="col-sm-4 form-group required">
                      <div class="field">
                        <input type="text" class="form-control" name="dateMin" id="dateMin" bsDatepicker
                          [(ngModel)]="filters.beginDate" 
                          [maxDate]="filters.endDate && filters.reportId != 'RETARD' ? filters.endDate : currentDate"
                          size="25" placement="bottom"
                          [bsConfig]="{ containerClass: 'theme-kiss'  }" placeholder="Choisir une date"
                          autocomplete="off"
                          (bsValueChange)="setRetourMaxDate($event)" #dateMin="ngModel"
                          (change)="setRetourMaxDate($event)" (blur)="setRetourMaxDate($event)"
                          (input)="setRetourMaxDate($event)" [required]="true" />
                          
                          <span class="error-messages"
                          *ngIf="retourForm.submitted && dateMin.errors?.required">
                          Ce champ est obligatoire
                          </span>
                          <span class="error-messages"
                              *ngIf="!dateMin.errors?.required && !isDateRangeValid">
                          Veuillez respecter la période de recherche d'un mois maximum
                          </span>
                      </div>
                    </div>
                    <div class="col-sm-2 col-form-label" [hidden]="filters.reportId == 'RETARD' ">Date de fin</div>
                    <div class="col-sm-4 form-group required"  [hidden]="filters.reportId == 'RETARD' ">
                      <div class="field">
                        <input type="text" class="form-control" name="dateMax" id="dateMax" #dateMax="ngModel"
                          bsDatepicker [(ngModel)]="filters.endDate" [maxDate]="retourMaxDate ? retourMaxDate : currentDate" size="25"
                          placement="bottom" [bsConfig]="{ containerClass: 'theme-kiss'  }" placeholder="Choisir une date" autocomplete="off"
                          [minDate]="filters.beginDate" [required]="true" (bsValueChange)="hideDownloadDiv()" />

                          <span class="error-messages"
                          *ngIf="(retourForm.submitted) && dateMax.errors?.required">
                          Ce champ est obligatoire
                          </span>
                         <span class="error-messages"
                              *ngIf="!dateMax.errors?.required && !isDateRangeValid">
                          Veuillez respecter la période de recherche d'un mois maximum
                          </span>
                          <span class="error-messages"
                          *ngIf="(dateMax.touched || dateMax.dirty ) && retourForm.errors?.dateMaxError && !dateMax.errors?.required">
                          La date de fin doit être supérieur à la date de début
                          </span>
                          
                      </div>
                    </div>
                  </div>
                  <div class="row"  *ngIf="filters.reportId == 'COMP' || filters.reportId == 'REGLE' ">
                    <div class="col-md-12 col-form-label text-info">
                      <i class="fa fa-info me-2"></i>La fourchette maximale de recherche pour ce document est de 1 mois
                    </div>
                  </div>
                  <div class="row" style="display:flex; text-align:right">
                    <div class="col-md-12 text-title">
                      <button class="btn btn-primary btn-lg" type="submit" (click)="onSearch()" [disabled]="haveSupervisedPss() && !isAuthorized()&& supervisedPs==null">Rechercher</button>
                    </div>
                  </div>
                </form>
              </fieldset>
            </div>
          </fieldset>
        </div>
        <div class="form-block" *ngIf="canDownload">
          <!-- Titre -->
          <div class="row">
            <div class="col">
              <h3 class="title-border">
                Télécharger le fichier
              </h3>
            </div>
          </div>
          <fieldset class="container-fluid" [hidden]="!isAuthorized()">
            <div class="row">
              <div class="col-sm-4 col-form-label" style="text-align:center"><label>PDF</label></div>
              <div class="col-sm-4 col-form-label" style="text-align:center"><label>WORD</label></div>
              <div class="col-sm-4 col-form-label" style="text-align:center"><label>EXCEL</label></div>
            </div>
            <div class="row">
              <div class="col-sm-4" style="text-align:center">
                <div (click)="getComptabiliteContent('PDF')">
                  <i class="fa fa-file-pdf-o fa-4x" style="cursor:pointer;"></i>
                </div>
              </div>
              <div class="col-sm-4" style="text-align:center">
                <div (click)="getComptabiliteContent('DOC')">
                  <i class="fa fa-file-word-o fa-4x" style="cursor:pointer;"></i>
                </div>
              </div>
              <div class="col-sm-4" style="text-align:center">
                <div (click)="getComptabiliteContent('XLS')">
                  <i class="fa fa-file-excel-o fa-4x" style="cursor:pointer;"></i>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset class="container-fluid" [hidden]="isAuthorized()">
            <div class="row">
              <div class="col-sm-6 col-form-label" style="text-align:center"><label>PDF</label></div>
              <div class="col-sm-6 col-form-label" style="text-align:center"><label>EXCEL</label></div>
            </div>
            <div class="row">
              <div class="col-sm-6" style="text-align:center">
                <div (click)="getComptabiliteContent('PDF')">
                  <i class="fa fa-file-pdf-o fa-4x" style="cursor:pointer;"></i>
                </div>
              </div>
              <div class="col-sm-6" style="text-align:center">
                <div (click)="getComptabiliteContent('XLS')">
                  <i class="fa fa-file-excel-o fa-4x" style="cursor:pointer;"></i>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </section>

    </div>
  </div>
</div>