import { Observable } from 'rxjs';
import { CONF } from '../../../core/constants';
import { FilterService } from '../../../core/services/filter-service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ContactsService extends FilterService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/contacts';

	constructor(private httpClient: HttpClient) {
		super();
	}

	getContactsByEditeurId(criteria: any): Observable<any> {
		return this.httpClient.get<any>(this.baseUrl + '/search/?idEditeur=' + criteria.idEditeur);
	}

	deleteByContactId(idContact: number): Promise<any> {
		return this.httpClient.delete(`${this.baseUrl}/${idContact}`).toPromise();
	}

	enregistrerContact(contact) {
		return this.httpClient.put(this.baseUrl, contact).toPromise();
	}
}
