<div class="full-careweb-container">
    <div class="row" style="margin: 20px">
      <div class="col">
        <!--- INDICATEURS HAUT --->
        <div class="row">
          <div class="col">
            <a class="link-box">
              <div class="box-content box-content-default">
                <div class="title">
                  <span>Interventions</span>
                  <div class="subtitle text-primary">Clôturées</div>
                  <div class="number">{{hotDashboardDataRaw.nmbreTicketClotures}}</div>
                </div>
                <div class="row align-right">
									<div class="col-md-12 mt-2">
									  <a class="more" role="button" (click)="voir(3)">Voir</a>
									</div>
                </div>
              </div>
            </a>
          </div>
          <div class="col">
            <a class="link-box">
              <div class="box-content box-content-default">
                <div class="title">
                  <span>Interventions</span>
                  <div class="subtitle text-primary">En cours</div>
                  <div class="number">{{hotDashboardDataRaw.nmbreTicketEnCours}}</div>
                </div>
                <div class="row align-right">
									<div class="col-md-12 mt-2">
									  <a class="more" role="button" (click)="voir(1)">Voir</a>
									</div>
                </div>
              </div>
            </a>
          </div>
          <div class="col">
            <a class="link-box">
              <div class="box-content box-content-default">
                <div class="title">
                  <span>Interventions</span>
                  <div class="subtitle text-primary">Transférées</div>
                  <div class="number">{{hotDashboardDataRaw.nmbreTicketTransferes}}</div>
                </div>
                <div class="row align-right">
									<div class="col-md-12 mt-2">
									  <a class="more" role="button" (click)="voir(2)">Voir</a>
									</div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <!--- FIN INDICATEURS HAUT  --->
        <!--  Debut Ticket table -->
        <div class="row">
          <div class="col">
              <!-- Grid -->
                <div class="grid-container box-content box-content-default" id="grid">
                  <h2 class="title-border mt-3 align-left">Liste des tickets mis à jour</h2>
                  <div>
                    <section>
                      <div class="row highlight-row">
                        <div class="col-md-12 relative">
                          <ag-grid-angular style="width: 100%;" class="ag-theme-balham" [gridOptions]="gridHelper.gridOptions">
                          </ag-grid-angular>
                          <careweb-pagination [gridApi]="gridHelper.gridApi"></careweb-pagination>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
          </div>
        </div>
        <!--  fin Ticket table -->
      </div>
    </div>
  </div>