import { Component, AfterViewInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'careweb-rapp-auto-total-montant-rsp',
	template: `<span>{{totalDisplay}}</span>
    `
})
export class RappAutoTotalMontantRspComponent implements ICellRendererAngularComp, AfterViewInit {
	total: number;
	totalDisplay: string;
	private params: any;

	constructor() {}
	refresh(params: any): boolean {
		throw new Error('Method not implemented.');
	}

	agInit(params: any): void {
		this.params = params;
	}

	ngAfterViewInit() {
		const cellParams = this.params;
		if (cellParams.data) {
			// Colonne options pour afficher le détail
			if (
				cellParams.colDef.field &&
				cellParams.colDef.field === 'rsps' &&
				cellParams.colDef.headerName === 'Montant'
			) {
				this.total = 0;
				cellParams.data.rsps.forEach((rsp) => {
					if (rsp.montantFactureRo !== undefined) this.total += rsp.montantFactureRo;
					if (rsp.montantFactureRc !== undefined) this.total += rsp.montantFactureRc;
				});
				this.totalDisplay = this.total + ' €';
			}
		}
	}

	previewRapprochement(): void {}

	formatCurrency = (params): number => {
		if (params.data) {
			if (params.value) {
				return params.value / 100;
			} else {
				return 0;
			}
		}
	};
}
