import { Component, AfterViewInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';


@Component({
	selector: 'ps-editeur-preview',
	template: `
        <i
			[ngClass]="iconClass"
            container="body"
			(click)="previewPs()"
			[tooltip]="tooltip"
			containerClass="tooltip-grid"
		></i>
    `
})
export class PsEditeurPreviewComponent implements ICellRendererAngularComp, AfterViewInit {
	iconClass: string;
	tooltip: string;
	private params: any;

	constructor() {}

	agInit(params: any): void {
		this.params = params;
	}

	ngAfterViewInit() {
		const cellParams = this.params;
		if (cellParams.data) {
			if (cellParams.iconClass !== undefined) {
				this.iconClass = cellParams.iconClass;
				this.tooltip = this.params.redirect === 'attach' ? 'Rattacher l\'éditeur' : '';
			}
			// Append cursor pointer
			this.iconClass = this.iconClass + ' cursor-pointer';
		}
	}

	refresh(): boolean {
		return false;
	}

	previewPs(): void {}
}
