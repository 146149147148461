import { LotsDataRaw } from './lotsDataRaw';

export class FseDataRaw {
	idLot: Number;
	idOct: Number;
	numFacture: String;
	numPs: String;
	numAssure: String;
	dateNaissance: string;
	partRcAPrendreEnCompte: boolean;
	nbRspRO: Number;
	nbRspRC: Number;
	totalRemboursableAmo: String;
	totalRemboursableAmc: String;
	prescripteur?: String;
	dateReceptionPartition: string;
	numOrganismeRc: String;
	codeGr: String;
	codeOrganismeDestinataireAmo: String;
	lot: LotsDataRaw;
	libelleRo: string;
	libelleRc: string;
	numeroLot: String;
	tiersPayant: Boolean;
	dateLotRecuFrom: string;
	dateLotRecuTo: string;
	dateLotRecuExacte: string;
	dateRspRecuFrom: string;
	dateRspRecuTo: string;
	dateRspRecuExacte: string;
	numeroFseFrom: string;
	numeroFseTo: string;
	presenceArlScor: Boolean;
	presenceRspRo: Boolean;
	presenceRspRc: Boolean;
	codeDestinataireRoRegime: String;
	codeDestinataireRoCaisse: String;
	codeDestinataireRoCenter: String;
	codeDestinataireRC: String;
	typeOrganisme: String;
	partExtraite: string[];
	// pour les remboursement de type paiement
	statutRsp: String;
	centreInfoTypeZero: String;
	listNumeroPs: String[];
	rapproBancaire: Number;
}
