<div class="modal-header pb-1">
        <h3 class="modal-title">Détails du Flux</h3>
        <button class="close" name="btn_display_filter" data-dismiss="modal" type="button"
            title="Fermer" (click)="closeModal()"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body pb-0">
        <div class="form-block">
            <fieldset class="container-fluid">
                <div class="row">
                    <div class="col-sm-3 col-form-label">Url</div>
                    <div class="col-sm-9 form-group">
                        <input type="text" class="form-control" readonly value="{{url}}" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3 col-form-label">La demande au format xml</div>
                        <div class="col-sm-9 form-group">
                            <textarea class="form-control-input" placeholder="" maxlength="1000" name="request" rows="5"
                                style="resize:horizontal;  resize: vertical;" value="{{request}}" autosize
                                readonly></textarea>
                        </div>
                </div>
                <div class="row">
                    <div class="col-sm-3 col-form-label">La réponse au format xml</div>
                    <div class="col-sm-9 form-group">
                        <textarea class="form-control-input" placeholder="" maxlength="1000" name="request" rows="5"
                        style="resize:horizontal;  resize: vertical;" value="{{response}}" autosize
                        readonly></textarea>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
<div class="modal-footer">

</div>