<!-- les choix Exacte et Plage-->
<div class="accordion-content">
    <div style="display:inline-flex;margin-right:10px">
        <input type="radio" id="radioExacte_{{id}}" name="choix_{{id}}" (ngModelChange)="onChoiceChange($event)" class="me-1" value="EXACTE" [(ngModel)]="filterChoice"/>
        <label for="radioExacte_{{id}}">Recherche exacte</label>
    </div>
    <div style="display:inline-flex">
        <input type="radio" id="radioPlage_{{id}}" name="choix_{{id}}" (ngModelChange)="onChoiceChange($event)" class="me-1" value="PLAGE" [(ngModel)]="filterChoice"/>
        <label for="radioPlage_{{id}}">ou par plage :</label>
    </div>
</div>
<!-- Exacte-->
<div *ngIf="filterChoice=='EXACTE'">
    <ng-content select="[EXACTE]"></ng-content>
</div>
<!-- Par plage-->
<div *ngIf="filterChoice=='PLAGE'">
    <label for="input_from">Entre</label>
    <ng-content select="[FROM]"></ng-content>
    <label for="input_to" class="mt-2">Et</label>
    <ng-content select="[TO]"></ng-content>
</div>