import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DynamicGridModel } from './dynamic-grid-model';

@Injectable()

export class DynamicGridSvc {
	constructor(private http: HttpClient) {}

	getGridSpecs(): Observable<DynamicGridModel[]> {
		return this.http.get<DynamicGridModel[]>('../careweb-web/resources/grid/grid-specs.json');
	}
}
