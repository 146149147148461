import { FilterItem } from '../../data/filters/filter-item';

export abstract class FilterService {
    /** Filtre actif */
    protected filterList: FilterItem[];
    /** Filtre en cours de construction */
    protected filterListTmp: FilterItem[];

    /**
     * Filtre en cours de construction avec son type par defaut
     * ce champs nous permet de reactiver les filtres
    */
    protected sendBoxFilter: any;

    constructor() {
        this.reset();
    }

    /** Vide les filtres actifs et en construction. */
    public reset() {
        this.filterList = [];
        this.filterListTmp = [];
        this.sendBoxFilter = null;
    }

    /**
     * Getter de la liste des filtres temporaires
     *
     */
    getFilterListTmp(): FilterItem[] {
        return this.filterListTmp;
    }

    /**
     * Getter de la liste des filtres
     */
    getFilterList(): FilterItem[] {
        return this.filterList;
    }

    /**
     * Récupère une liste de filtre en fonction de son type (recherche parmis les filtres termporaires)
     *
     *  @param type - type de filtre qu'on souhaite obtenir
     *
     *  @returns liste des filtres du type désiré
     */
    getFilterTmpByType(type: string): FilterItem[] {
        return this.filterByType(this.filterListTmp, type);
    }

    /**
     * Récupère le nombre de filtre ajoutés pour un type donnée
     *
     * @param type - type de filtre qu'on souhaite compter
     *
     * @returns La quantité de filtre de ce type
     */
    countFilterByType(type: string): number {
        return this.filterByType(this.filterList, type).length;
    }

    private filterByType(items: FilterItem[], type: string): FilterItem[] {
        return items.filter(item => item.type === type);
    }


    getSendBoxFilter(): any {
        return this.sendBoxFilter;
    }

    setSendBoxFilter(sendBoxFilter: any) {
        this.sendBoxFilter = sendBoxFilter;
    }
}
