import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'careweb-filter-label',
	template: `<li class="filter-label">
        <span [ngClass]="(isOrganisme && filter=='typeOrganisme') || isCloseToDisable ? 'noClose':'fa fa-times'" (click)="close($event)"></span>
        <span><ng-content></ng-content></span></li>`,
	styleUrls: [ './filter-label.component.scss' ]
})
export class FilterLabelComponent {
	@Input() filter: any;
	@Input() isOrganisme: boolean;
	@Input() isCloseToDisable: boolean;
	@Output() closeFilter = new EventEmitter<MouseEvent>();

	close(event: MouseEvent) {
		if (!this.isCloseToDisable) {
			this.closeFilter.emit(event);
		}
	}
}
