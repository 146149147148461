import { Injectable } from "@angular/core";
import { isNullOrUndefined } from "util";

@Injectable({
	providedIn: 'root'
})
export class MapEmptyValueToNull {

	constructor() {}

		// we change every value of '' to null.
		emptyValueToNull(object) {
			if(!isNullOrUndefined(object)){
				Object.keys(object).forEach((key) => {
				if(object[key] === '') {
					object[key] = null;
				}
				});
				return object;
			}
		}
}
