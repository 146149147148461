import { findIndex } from 'lodash-es';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'careweb-filter-multiple',
	templateUrl: './filter-multiple.html'
})
export class FilterMultipleComponent implements OnInit {
	@Input() title: string;
	@Input() property: string;
	@Input() isDateRangeValid = true;

	textValue: string;

	@Input() criterias: Array<string> = [];
	@Output() criteriasSubmitted = new EventEmitter<any>();


	ngOnInit() {
		if (!this.criterias) {
			this.criterias = new Array<string>();
		}
	}

	get badgeCount(): number {
		return this.criterias ? this.criterias.length : 0;
	}

	getInputID(): string {
		return `filter-multi-${this.property}`;
	}

	addFilter() {
		if (!this.criterias) {
			this.criterias = new Array<string>();
		}
		if (this.textValue && this.textValue !== '') {
			if(!this.criterias.includes(this.textValue)) {
				this.criterias.push(this.textValue);
				this.textValue = '';
				this.criteriasSubmitted.emit(this.criterias);
			}
		}
	}

	removeFilter(filter: string) {
		const index = findIndex(this.criterias, (filt) => filt === filter);
		this.criterias.splice(index, 1);
		this.criteriasSubmitted.emit(this.criterias);
	}
}
