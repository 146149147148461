import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONF } from '../../../core/constants';
import { ArlDataRaw } from '../../../data/arlDataRaw';
import { isNullOrUndefined } from 'util';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ARLService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/flux/arl';

	constructor(private httpClient: HttpClient) {}

	// Return Arl list by lot id
	getArlByLotId(criteria: any): Observable<any> {
		return this.httpClient.get<any>(this.baseUrl + '/lots/?idLot=' + criteria.idLot);
	}

	getSortParameters(sorts) {
		if (!isNullOrUndefined(sorts) && sorts.length === 1) {
			const sort = sorts[0].property;
			return '&sort=' + sort + '&direction=' + sorts[0].direction;
		} else {
			// sort par défaut
			return '&sort=dateReception&direction=DESC';
		}
	}

	searchArlsByIdPsWithDelegues(criteria, arlDataRaw: ArlDataRaw): Observable<any> {
		return this.httpClient.post(
			this.baseUrl +
				'/search/ps/' +
				criteria.idPs +
				'/delegues/arls?' +
				'page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				this.getSortParameters(criteria.sorts),
			arlDataRaw
		);
	}

	getArlsFilteredList(criteria: any, arlDataRaw: ArlDataRaw): Observable<any> {
		return this.httpClient.post(
			this.baseUrl +
				'/search?page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				'&idOct=' +
				criteria.idOct +
				this.getSortParameters(criteria.sorts),
			arlDataRaw
		);
	}

	export(criteria: any, arlDataRaw: ArlDataRaw, fields: string[]): Observable<any> {
		arlDataRaw.fields = fields;
		return this.httpClient.post<HttpResponse<Blob>>(
			this.baseUrl +
				'/export?idOct=' + criteria.idOct +
				this.getSortParameters(criteria.sorts),
			arlDataRaw,
			{
				observe: 'response' as 'body',
				responseType: 'blob' as 'json'
			}
		).pipe(
			map((response: HttpResponse<Blob>): any => {
				return this.getFileInfosFromResponse(response);
			})
		);
	}

	exportByEmail(criteria: any, arlDataRaw: ArlDataRaw, fields: string[]): Observable<any> {
		arlDataRaw.fields = fields;
		return this.httpClient.post<HttpResponse<Blob>>(
			this.baseUrl +
				'/export-by-email?idOct=' + criteria.idOct +
				this.getSortParameters(criteria.sorts),
			arlDataRaw,
			{
				observe: 'response' as 'body',
				responseType: 'blob' as 'json'
			}
		).pipe(
			map((response: HttpResponse<Blob>): any => {
				return this.getFileInfosFromResponse(response);
			})
		);
	}

	getFileInfosFromResponse(response: HttpResponse<Blob>) {
		return {
			file: new Blob([ response.body ], { type: 'application/vnd.ms-excel' }),
			fileName: 'Liste_ARL.xlsx'
		};
	}

	// Retourne le nombre des ARLs négatives
	countArl(arlDataRaw: ArlDataRaw): Observable<any> {
		return this.httpClient.post(this.baseUrl + '/count', arlDataRaw);
	}

	countDelayedArls(criteria: any): Observable<any> {
		return this.httpClient.get<any>(this.baseUrl + '/count/delay/?idOct=' + criteria.idOct);
	}

	exportArlsByIdPsWithDelegues(criteria, arlDataRaw: ArlDataRaw): Observable<any> {
		return this.httpClient.post(
			this.baseUrl +
				'/export/ps/' +
				criteria.idPs +
				'/delegues/arls?'+
				'page=' +
				criteria.page +
			    '&size=' +
				criteria.size + '&'+
				this.getSortParameters(criteria.sorts).substring(1),
			arlDataRaw
		);
	}
}