import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ColDef, IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { isNullOrUndefined } from 'util';
import { AgGridHelper } from '../../../components/ag-grid/ag-grid-helper';
import { GridDataRaw } from '../../../data/gridDataRaw';
import { MigrationOctLogsDataRaw } from '../../../data/migrationOctLogsDataRaw';
import { StorageService } from '../../../shared/storage-service';
import { MigrationOctService } from '../migration-oct.service';

@Component({
	selector: 'careweb-app-logs-migration-oct',
	templateUrl: './logs-migration-oct.component.html',
	styleUrls: ['./logs-migration-oct.component.scss']
})

export class LogsMigrationOctComponent implements OnInit {
	@Input() idBoiteMail: number;
	datePipe: DatePipe;
	migrationOctLogsGridHelper: AgGridHelper;
	gridDataRaw: GridDataRaw;
	gridDataRawMigrationLogs: GridDataRaw;
	migrationOctLogsDataRaw: MigrationOctLogsDataRaw;
	public rowCount: number = -1;
	constructor(
		private storageService: StorageService,
		private modalRef: BsModalRef,
		private migrationOctService: MigrationOctService,
	) {
		const sessionPageSize = this.storageService.get('migrationOctPaginationPageSize', true);
		this.gridDataRaw = new GridDataRaw();
		this.gridDataRaw.size = !isNullOrUndefined(sessionPageSize) ? sessionPageSize : 10;
		this.migrationOctLogsGridHelper = new AgGridHelper('migrationOct', this.gridDataRaw.size, 10);
		this.migrationOctLogsDataRaw = new MigrationOctLogsDataRaw();
	}

	ngOnInit() {
		this.initMigrationLogsGrid();
		this.datePipe = new DatePipe('fr-FR');
	}

	initMigrationLogsGrid() {
		this.migrationOctLogsGridHelper.gridOptions = {
			columnDefs: this.getColumnLogsDefs(),
			defaultColDef: {
				resizable: true,
				suppressMenu: true,
				autoHeight: true,
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			serverSideStoreType: 'partial',
			pagination: true,
			cacheBlockSize: 10,
			maxBlocksInCache: 0,
			infiniteInitialRowCount: 1,
			paginationPageSize: this.gridDataRaw.size,
			paginateChildRows: true,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			enableCellTextSelection: true,
			localeText: this.migrationOctLogsGridHelper.getLocaleText(),
			onGridReady: (params) => this.onMigrationOctLogsGridReady(params)
		};
	}

	closeModal() {
		this.modalRef.hide();
	}

	reset() {
		this.modalRef.hide();
	}

	onMigrationOctLogsGridReady(params: any) {
		this.migrationOctLogsGridHelper.gridApi = params.api;
		this.migrationOctLogsGridHelper.gridColumnApi = params.columnApi;
		this.migrationOctLogsGridHelper.gridApi.sizeColumnsToFit();
		this.restoreGrid();
		//if (!isEmpty(parsedFilter)) {
		this.launchSearch();
		// } else this.clearGridFromData();
	}

	restoreGrid() {
		this.migrationOctLogsGridHelper.restoreGridStatePrefix();
		this.gridDataRaw.size = this.migrationOctLogsGridHelper.paginationPageSize;
	}

	launchSearch() {
		const ctx = this;
		this.migrationOctLogsGridHelper.gridApi.sizeColumnsToFit();
		this.migrationOctLogsDataRaw = new MigrationOctLogsDataRaw();

		const dataSource: IServerSideDatasource = {
			getRows: function (paramsRows: IServerSideGetRowsParams) {
				const criteria = {
					page: ctx.migrationOctLogsGridHelper.gridApi.paginationGetCurrentPage() || 0,
					size: ctx.migrationOctLogsGridHelper.gridApi.paginationGetPageSize(),
					idBoiteMail: ctx.idBoiteMail
				};
				ctx.migrationOctService.getMigrationOctLogs(criteria, ctx.migrationOctLogsDataRaw).subscribe(
					(data) => {
						ctx.rowCount = data.totalElements > 0 ? data.totalElements : -1;
						ctx.migrationOctLogsGridHelper.manageNoRowsOverlay(data.totalElements);
						paramsRows.success({
							"rowData": data.content,
							"rowCount": data.totalElements
						});
						const pageN = Number.parseInt(sessionStorage.getItem('migrationOctCurrentPage'));
						if (!isNullOrUndefined(pageN) && pageN !== ctx.migrationOctLogsGridHelper.gridApi.paginationGetCurrentPage() && pageN > 0) {
							ctx.migrationOctLogsGridHelper.gridApi.paginationGoToPage(pageN - 1);
						}
						sessionStorage['migrationOctCurrentPage'] = null;
					},
					() => paramsRows.fail()
				);
			}
		};
		this.migrationOctLogsGridHelper.gridApi.setServerSideDatasource(dataSource);
		this.migrationOctLogsGridHelper.gridApi.sizeColumnsToFit();
	}

	getColumnLogsDefs(): ColDef[] {
		return [
			{
				headerName: 'Horodatage',
				minWidth: 200,
				field: 'logDate',
				valueFormatter: this.formatDate,
				cellRenderer: 'agGroupCellRenderer',
				sortable: true
			},
			{
				headerName: 'Utilisateur',
				minWidth: 250,
				field: 'username',
				sortable: false
			},
			{
				headerName: 'Message',
				minWidth: 610,
				field: 'logLabel',
				sortable: false,
				wrapText: true,
			}]
	}

	formatDate = (params): string => {
		if (params.value !== null && params.value !== 'Invalid Date') {
			return this.datePipe.transform(params.value, 'dd/MM/yyyy HH:mm:ss');
		}
	};
}
