import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONF } from '../../../core/constants';
import { RegroupementDataRaw } from '../../../data/regroupementDataRaw';

@Injectable({
	providedIn: 'root'
})
export class RegroupementsService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/regroupements';

	constructor(private httpClient: HttpClient) {}

	getRegroupementsList(criteria: any): Observable<any> {
		return this.httpClient.get<any>(this.baseUrl + '/list?page=' + criteria.page + '&size=' + criteria.size);
	}

	getAllRegroupementsList(): Observable<any> {
		return this.httpClient.get<any>(this.baseUrl + '/list');
	}

	getRegroupementFilteredList(criteria: any, regroupementDataRaw: RegroupementDataRaw): Observable<any> {
		return this.httpClient.post<any>(
			this.baseUrl +
				'/search?page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				this.getSortParameters(criteria.sorts),
			regroupementDataRaw
		);
	}
	getSortParameters(sorts) {
		if (sorts.length === 1) {
			const sort = sorts[0].property;
			return '&sort=' + sort + '&direction=' + sorts[0].direction;
		} else {
			// sort par défaut
			return '&sort=libelle&direction=ASC';
		}
	}
}
