import { AfterViewInit, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';


@Component({
    selector: 'careweb-refresh-preview',
    template: `
      
      <i
              [ngClass]="['fa fa-refresh', disabled ? 'disabled' : '']"
              container="body"
              [ngbTooltip]="tooltip"
              containerClass="tooltip-grid"
              [style.pointer-events]="disabled ? 'auto' : 'auto'"
      ></i>

    `
})
export class RefreshPreviewComponent implements ICellRendererAngularComp, AfterViewInit {
    private params: any;
    iconClass: string;
    tooltip = '';
    disabled: boolean;

    agInit(params: any): void {
        this.params = params;
    }

    ngAfterViewInit() {
        const cellParams = this.params;
        if (cellParams.data) {
            if (cellParams.data.certificatesState && cellParams.data.certificatesState !== "VALID") {
                this.tooltip = "Renouveler la certification";
                this.disabled = false;
            } else if (cellParams.data.certificatesState && cellParams.data.certificatesState === "VALID") {
                this.tooltip = "Certificat valide";
                this.disabled = true;
            }
        }

    }

    refresh(): boolean {
        return false;
    }
}
