import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common'


@Injectable()
export class CustomRouteHistoryService {
    private history: string[] = []

    constructor(private router: Router, private location: Location) { }

    public startSaveHistory(): void {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.history.push(event.urlAfterRedirects)
            }
        })
    }

    public getHistory(): string[] {
        return this.history;
    }

    public goBack(): void {
        this.history.pop();
        if (this.history.length > 0) {
            this.location.back()
        }
    }

}
