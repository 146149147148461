

import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Injectable()
export class IsNotAdminHotlineGuard implements CanActivate {
    constructor(private authSvc: AuthService, private router: Router) {}

    canActivate() {
        const isAuthorized = this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
        if (isAuthorized) {
            this.router.navigate([ '/dashboard' ]);
        }
        return !isAuthorized;
    }
}
