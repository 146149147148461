<div class="full-careweb-container">
    <div id="filter" class="filter-container" carewebFilterMinHeight>
        <div class="filter-container-inner" [ngClass]="{ 'show-content': showFilter, 'init-hidden': initHidden }">
            <careweb-accordion-header-buttons (closeFilters)="toggleFilterDisplay()" (resetFilters)="resetAllFilter()"
                                              (launchSearch)="launchSearch()"></careweb-accordion-header-buttons>
            <div class="filter-content">
                <mat-accordion displayMode="flat" class="accordion-filter">
             
                     <!-- N° ps -->
                    <careweb-accordion-group id="numeroPS" title="N° de PS">
                        <input type="text" id="input_ps" [(ngModel)]="filters.numeroPS"
                               (keydown.enter)="onKeyDown($event)" placeholder="000000000" />
                    </careweb-accordion-group>
                    <careweb-accordion-group id="username" title="BAL à migrer">
                        <input type="text" id="input_balAmigrer" [(ngModel)]="filters.username"
                               (keydown.enter)="onKeyDown($event)"/>
                    </careweb-accordion-group>
                    <careweb-accordion-group id="balDestinatrice" title="BAL de destination">
                        <input type="text" id="input_balDestination" [(ngModel)]="filters.balDestinatrice"
                               (keydown.enter)="onKeyDown($event)"/>
                    </careweb-accordion-group>
                    <careweb-accordion-group id="status" title="Statut de la migration">
                        <ng-select name="status" id="status-select" placeholder="Statut" [searchable]="false"
                        [(ngModel)]="filters.status" appendTo=".filter-container" (keydown.enter)="onKeyDown($event)">
                        <ng-option [value]="true">Démarré</ng-option>
                        <ng-option [value]="false">Arrêté</ng-option>
                      </ng-select>
                      </careweb-accordion-group>
<!--                       <careweb-accordion-group id="id-has-error" title="Migration en erreur">
                        <ng-select name="lastLogError" id="lastLogError" placeholder="En erreur" [searchable]="false"
                        [(ngModel)]="filters.lastLogError" appendTo=".filter-container" (keydown.enter)="onKeyDown($event)">
                        <ng-option [value]="''"> </ng-option>
                        <ng-option [value]="true">Oui</ng-option>
                        <ng-option [value]="false">Non</ng-option>
                      </ng-select>
                      </careweb-accordion-group> -->
                </mat-accordion> 
            </div>
        </div>

    </div>
    <div class="grid-container" id="grid">
        <div>
            <div class="row mt-3">
                <div class="col-lg-9">
                    <h2 class="title-border">Migration des comptes OCT
                    </h2>
                </div>
            </div>
                <div id="onglet3" class="tab-pane fade show active">
                        <section class="mt-5">  
                            <div class="row grid-top-btn">
                                <div class="col-md-4 filter">
                                    <button type="button" name="btn_toggle_filter" class="btn btn-primary small left-open"
                                            [hidden]="showFilter" (click)="toggleFilterDisplay()">
                                        <i class="fa fa-sliders"></i>
                                        Filtrer
                                    </button>
                                <careweb-pluralize  class="filter-count" [messageKey]="'Résultat(s) trouvé(s)'"
                                [count]="migrationOctGridHelper.totalRowCount"></careweb-pluralize>
                                </div>
                                <div class="col-md-8 conf-col">
                                    <div class="careweb-toolbar">
                                        <span class="btn btn-primary small" 
                                        tooltip="Ajouter un compte" placement="top" container="body" name="add_account_oct" (click)="openMigrationModal()"
                                        ><!--  (click)="exportFileXlsx()" -->
                                        Ajouter
                                    </span>
                                    <careweb-pagination-size id="dv_rule_paging" [pageSizes]="[10, 20,30, 50, 100]"
                                    [(ngModel)]="gridDataRaw.size" (paginationSizeChange)="onPaginationSizeChange($event)">
                                    </careweb-pagination-size>
                                    <span class="btn btn-action small" tooltip="Réinitialiser l'affichage" placement="top" container="body"
                                    name="spn_reset_grid" (click)="resetGridState()">
                                        <i class="fa fa-refresh"></i>
                                    </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row grid-top-filter">
                                <div class="col-md-12">
                                    <careweb-filter-label *ngFor="let filter of filterList" (closeFilter)="removeFilter(filter.id)">
                                        <span *ngIf="filter">{{ filter.label}}: {{filter.value }}</span>
                                    </careweb-filter-label>
                                </div>
                            </div>
            
                            <div class="row highlight-row">
                            <div class="col-md-12 relative">
                                <ag-grid-angular style="width: 100%" class="ag-theme-balham" [gridOptions]="migrationOctGridHelper.gridOptions">
                                </ag-grid-angular>
                                <careweb-pagination [gridApi]="migrationOctGridHelper.gridApi"></careweb-pagination>
                            </div>
                            </div>
                        </section>
                </div>
        </div>
    </div>
  </div>