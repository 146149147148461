import { FilterChoiceEnum } from "./rspRappSummaryFilterRaw";

export class TransactionFilterRaw {
	emetteur: string;
	dateComptable: Date;
	dateImport: Date;
	label: string;
	notes: string;
	montant: number;
    montantFrom: number;
    montantTo: number;
	montantFilter:FilterChoiceEnum;
    dateOperationFrom: Date;
    dateOperationTo: Date;
}
