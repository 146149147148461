import { EventEmitter, Input, OnChanges, Output, SimpleChanges, Component } from '@angular/core';
import { CellClickedEvent } from 'ag-grid-community';
import { ValueFormatterParams, ColDef } from 'ag-grid-enterprise';
import { AgGridHelper } from './ag-grid-helper';
import { DynamicGridColumnType } from './dynamic-grid-column-type';
import { DynamicGridModel } from './dynamic-grid-model';

@Component({
	selector: 'careweb-dynamic-grid',
	templateUrl: './dynamic-grid.component.html',
	styleUrls: []
})
export /**
 * Composant d'une grille dynamique.
 */

class DynamicGridComponent implements OnChanges {
	@Input() dynamicGridModel: DynamicGridModel[];
	@Input() gridHelper: AgGridHelper;
	@Output() cellClicked = new EventEmitter<(event: CellClickedEvent) => void>();

	constructor() {}

	initGrid() {
		const statusCol = this.gridHelper.gridOptions.columnDefs.find(
			(col) => col.headerName === 'grid.col_name.status'
		);
		const fixedColumns: ColDef[] = this.gridHelper.gridOptions.columnDefs.filter(
			(col: ColDef) => col.lockVisible === true
		);
		const columnDefs: ColDef[] = statusCol ? [ statusCol ] : [];
		fixedColumns.forEach((fixedCol) => columnDefs.push(fixedCol));
		this.gridHelper.gridOptions.columnDefs = columnDefs;
	}

	ngOnChanges(changes: SimpleChanges) {
		const chng = changes['dynamicGridModel'];
		if (chng && chng.currentValue) {
			this.initGrid();
		}
	}

	getValueFormater(type: DynamicGridColumnType): (params: ValueFormatterParams) => string | string {
		let formater: (params: ValueFormatterParams) => string | string;
		return formater;
	}

	onCellClicked(params) {
		this.cellClicked.emit(params);
	}
}
