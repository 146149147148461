export class FilterItem {
	id;
	type;
	type2;
	value;
	value2;
	label: string;
	paramLabel;

	// premet d'indiquer si le filtre est visible
	private visible = true;

	withId(id) {
		this.id = id;
		return this;
	}

	withType(type) {
		this.type = type;
		return this;
	}

	withValue(value) {
		this.value = value;
		return this;
	}

	withValue2(value2) {
		this.value2 = value2;
		return this;
	}

	withLabel(label: string) {
		this.label = label;
		return this;
	}

	withType2(type2) {
		this.type2 = type2;
		return this;
	}

	withParamLabel(paramLabel) {
		this.paramLabel = paramLabel;
		return this;
	}

	isVisible(): boolean {
		return this.visible;
	}

	withVisibility(visible) {
		this.visible = visible;
		return this;
	}
}
