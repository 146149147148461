export class ReglesParsingFilterRaw {
    statement : String;
    iban : String;
    ntry : String;
    dateImport : String;
    label : String;
    transactionId : String;
    emetteur : String;
    motif : String;
    dateComptable : String;
    montant : String;
    detail : String;
    constructor(data = null) {
        Object.assign(this, data);
    }
}