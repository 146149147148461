import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { CONF } from '../../../core/constants';
import { map } from 'rxjs/operators';
import { SiteEmissionDataRaw } from '../../../data/siteEmissionDataRaw';

@Injectable({
	providedIn: 'root'
})
export class SiteEmissionService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/siteemission';

	constructor(private httpClient: HttpClient) {}

	removeSiteEmissionWithReroute(idSite): Observable<any> {
		return this.httpClient.delete(this.baseUrl + '/' + idSite + '/reroute', { responseType: 'text' }).pipe(
			map((message) => {
				return message;
			})
		);
	}

	saveSiteEmission(siteEmission: SiteEmissionDataRaw): Observable<SiteEmissionDataRaw> {
		return this.httpClient.post<SiteEmissionDataRaw>(this.baseUrl + '/create', siteEmission);
	}

	updateSiteEmission(idSite, siteEmission): Observable<SiteEmissionDataRaw> {
		return this.httpClient.put<SiteEmissionDataRaw>(this.baseUrl + '/update/' + idSite, siteEmission);
	}

	getSiteEmissionById(idSite): Observable<SiteEmissionDataRaw> {
		return this.httpClient.get<SiteEmissionDataRaw>(this.baseUrl + '/' + idSite);
	}

	getSiteEmissionsByIdPs(idPs: Number): Observable<Array<SiteEmissionDataRaw>> {
		return this.httpClient.get<Array<SiteEmissionDataRaw>>(this.baseUrl + '/ps/' + idPs);
	}

	findSiteEmissionByBalEmettrice(balEmettrice: String): Observable<SiteEmissionDataRaw> {
		return this.httpClient.get<SiteEmissionDataRaw>(this.baseUrl + '/balEmettrice/' + balEmettrice);
	}

	isSiteEmissionAttach(idSite): Observable<any> {
		return this.httpClient.get(this.baseUrl + '/attach/' + idSite, { responseType: 'text' }).pipe(
			map((message) => {
				return message;
			})
		);
	}

	search(siteEmission: SiteEmissionDataRaw): Observable<any> {
		return this.httpClient.post<any>(this.baseUrl + '/search', siteEmission);
	}

	attachSiteReroutage(idSite: Number, idSiteReroutage: Number): Observable<any> {
		return this.httpClient.put<any>(
			this.baseUrl + '/attach/reroutage?' + 'idSiteEmission=' + idSite + '&idSiteReroutage=' + idSiteReroutage,
			{}
		);
	}

	dettachSiteReroutage(idSite: number): Observable<any> {
		return this.httpClient.put<any>(this.baseUrl + '/dettach/reroutage?' + 'idSiteEmission=' + idSite, {});
	}

	handleError(error: HttpErrorResponse) {
		return throwError(error);
	}
}
