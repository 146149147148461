import { GridSortModel } from './grid-sort-model';
import { PageCriteriaSortRaw } from './page-criteria-sort';
import { ColumnFieldMap } from './column-field-map';

/**
 * Convertit les informations de tri d'ag-grid en information valide pour les api careweb.
 * @param columns Liste des colonnes à trier (SortModel de ag-grid)
 * @param columnFieldMap Liste contenant le nom des colonnes à convertir avant l'appel serveur
 * @returns un tableau de colonnes à trier
 */
export const gridConvertSort = (columns: GridSortModel[], columnFieldMap: ColumnFieldMap[]) => {
	const result = [];
	if (columns) {
		columns.forEach((column, index) => {
			const columnField = columnFieldMap.filter((element) => {
				return element.field === column.colId;
			});
			result.push(
				new PageCriteriaSortRaw({
					direction: column.sort.toUpperCase(),
					property: columnField.length > 0 ? columnField[0].property : column.colId,
					priority: column.sortIndex
				})
			);
		});
	}
	return result;
};
