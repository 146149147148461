<div class="x-large-careweb-container">
    <section class="form-content">
        <!-- Titre H2 -->
        <div class="row form-title">
            <h2 *ngIf="isNewTypeZero && !isEditTypeZero" class="col-md-6">Ajouter un type 0</h2>
            <h2 *ngIf="isEditTypeZero && !isNewTypeZero" class="col-md-6">Modification du type 0</h2>
            <div class="col-md-6 text-title">
                <button class="btn btn-default btn-lg" type="button" (click)="goToTypeZero()">Retour</button>
            </div>
        </div>
        <form [formGroup]="typeZeroForm" (ngSubmit)="onSubmit()">
            <div class="form-block">
                <!-- Titre -->
                <div class="row">
                    <div class="col">
                        <h3 class="title-border">Détails</h3>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-3 col-form-label">Type émetteur *</div>
                        <div class="col-sm-3 form-group">
                            <input type="text" class="form-control" formControlName="typeEmetteur" maxlength="2"
                                [required]="true"
                                [ngClass]="{ 'is-invalid': submitted && formFields.typeEmetteur.errors }" />
                            <div *ngIf="submitted && formFields.typeEmetteur.errors" class="invalid-feedback">
                                <div
                                    *ngIf="formFields.typeEmetteur.errors.required || formFields.typeEmetteur.errors.whitespaces">
                                    Ce champ est obligatoire</div>
                            </div>
                        </div>
                        <div class="col-sm-3 col-form-label">Émetteur *</div>
                        <div class="col-sm-3 form-group">
                            <ng-select [clearable]="false"
                                [ngClass]="{ 'is-invalid': submitted && formFields.numEmetteur.errors }"
                                formControlName="numEmetteur" 
                                style="width:100%; height:calc(1.8em + .75rem + 2px)!important;" appendTo="body">
                                <ng-option *ngFor="let emetteur of emetteurs" [value]="emetteur">
                                    {{emetteur.numEmetteur}}</ng-option>
                            </ng-select>
                            <div *ngIf="submitted && formFields.numEmetteur.errors" class="invalid-feedback">
                                <div
                                    *ngIf="formFields.numEmetteur.errors?.required">
                                    Ce champ est obligatoire</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3 col-form-label">Type destinataire *</div>
                        <div class="col-sm-3 form-group">
                            <input type="text" class="form-control" formControlName="typeDestinataire"
                                [ngClass]="{ 'is-invalid': submitted && formFields.typeDestinataire.errors }"
                                maxlength="2" [required]="true" />
                            <div *ngIf="submitted && formFields.typeDestinataire.errors" class="invalid-feedback">
                                <div
                                    *ngIf="formFields.typeDestinataire.errors.required   || formFields.typeDestinataire.errors.whitespaces">
                                    Ce champ est obligatoire</div>
                            </div>
                        </div>

                        <div class="col-sm-3 col-form-label">N° destinataire*</div>
                        <div class="col-sm-3 form-group">
                            <input type="text" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && formFields.numDestinataire.errors }"
                                formControlName="numDestinataire" maxlength="14" />
                            <div *ngIf="submitted && formFields.numDestinataire.errors" class="invalid-feedback">
                                <div
                                    *ngIf="formFields.numDestinataire.errors.required   || formFields.numDestinataire.errors.whitespaces">
                                    Ce champ est obligatoire</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3 col-form-label">N° destinataire dynamique</div>
                        <div class="col-sm-3 form-group">
                            <ng-select [clearable]="false" name="actif" id="actifSelector" formControlName="numDestDyn"
                                appendTo="body" [ngClass]="{ 'is-invalid': submitted && formFields.numDestDyn.errors }">
                                <ng-option [value]="true">Oui</ng-option>
                                <ng-option [value]="false">Non</ng-option>
                            </ng-select>
                        </div>

                        <div class="col-sm-3 col-form-label">Application*</div>
                        <div class="col-sm-3 form-group">
                            <input type="text" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && formFields.application.errors }"
                                formControlName="application" maxlength="2" />
                            <div *ngIf="submitted && formFields.application.errors" class="invalid-feedback">
                                <div
                                    *ngIf="formFields.application.errors.required  || formFields.application.errors.whitespaces">
                                    Ce champ est obligatoire</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3 col-form-label">Identifiant fichier*</div>
                        <div class="col-sm-3 form-group">
                            <input type="text" class="form-control"
                                formControlName="identifiantFichier"
                                [ngClass]="{ 'is-invalid': submitted && formFields.identifiantFichier.errors }"
                                oninput="this.value=this.value.replace(/[^\d]/,'').replace(/\s/g,'').replace( /\./gi,'');"
                                onpaste="this.value=this.value.replace(/[^\d]/,'').replace(/\s/g,'').replace( /\./gi,'');"
                                pattern="[0-9]*" min="0" />
                            <div *ngIf="submitted && formFields.identifiantFichier.errors" class="invalid-feedback">
                                <div
                                    *ngIf="formFields.identifiantFichier.errors.required  || formFields.identifiantFichier.errors.whitespaces">
                                    Ce champ est obligatoire</div>
                            </div>
                        </div>

                        <div class="col-sm-3 col-form-label">Longueur d'enregistrement</div>
                        <div class="col-sm-3 form-group">
                            <input type="text" class="form-control" formControlName="longueurEnregistrement"
                                [ngClass]="{ 'is-invalid': submitted && formFields.longueurEnregistrement.errors }"
                                oninput="this.value=this.value.replace(/[^\d]/,'').replace(/\s/g,'').replace( /\./gi,'');"
                                onpaste="this.value=this.value.replace(/[^\d]/,'').replace(/\s/g,'').replace( /\./gi,'');"
                                pattern="[0-9]*" min="0" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3 col-form-label">Compactage</div>
                        <div class="col-sm-3 form-group">
                            <ng-select [clearable]="false" name="actif" id="actifSelector" formControlName="compactage"
                                appendTo="body" [ngClass]="{ 'is-invalid': submitted && formFields.compactage.errors }">
                                <ng-option [value]="true">Oui</ng-option>
                                <ng-option [value]="false">Non</ng-option>
                            </ng-select>
                        </div>

                        <div class="col-sm-3 col-form-label">Cryptage</div>
                        <div class="col-sm-3 form-group">
                            <ng-select [clearable]="false" name="actif" id="actifSelector" formControlName="cryptage"
                                appendTo="body" [ngClass]="{ 'is-invalid': submitted && formFields.cryptage.errors }">
                                <ng-option [value]="true">Oui</ng-option>
                                <ng-option [value]="false">Non</ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3 col-form-label">Programme émetteur</div>
                        <div class="col-sm-3 form-group">
                            <input type="text" class="form-control" formControlName="programmeEmetteur" maxlength="6" />
                        </div>
                        <div class="col-sm-3 col-form-label">Programme destinataire</div>
                        <div class="col-sm-3 form-group">
                            <input type="text" class="form-control" formControlName="programmeDestinataire"
                                maxlength="6" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="button-bar control mb-3">
                <button type="button" class="btn btn-default me-3" (click)="cancel()">
                    Annuler
                </button>
                <!-- Bouton -->
                <button type="submit" class="btn btn-primary" [disabled]="!typeZeroForm.dirty">
                    Enregistrer
                </button>
            </div>
        </form>

        <div *ngIf="isEditTypeZero && !isNewTypeZero" class="form-block">
            <!-- Titre -->
            <div class="row">
                <div class="col">
                    <h3 class="title-border">
                        <div class="row">
                            <div class="col-auto me-auto">Liste des associations</div>
                            <div class="col-auto" *ngIf="regroupementsList.length == 1">{{regroupementsList.length}}
                                association
                            </div>
                            <div class="col-auto" *ngIf="regroupementsList.length > 1">{{regroupementsList.length}}
                                association(s)
                            </div>
                        </div>
                    </h3>
                </div>
            </div>

            <!-- TOOLBAR -->
            <div class="row grid-top-btn" style="margin-top:0px">
                <div class="col-md-12 conf-col">
                    <div class="sy-toolbar">

                        <!-- TOOLBAR -->
                        <div>
                            <!-- Bouton -->
                            <button type="button" class="btn btn-primary" (click)="openModalAddAssoc()">
                                Ajouter une association au type 0
                            </button>
                        </div>

                    </div>
                </div>
            </div>
            <!-- Tableau -->
            <div class="row" *ngIf="regroupementsList.length > 0">
                <div class="col">
                    <!-- Utiliser .table-responsive pour des tableaux optimises en mode mobile -->
                    <div class="table-responsive">
                        <table class="ui-grid-like">
                            <thead>
                                <tr>
                                    <th class="p-2">Regroupement</th>
                                    <th class="p-2" style="min-width:100px">Type émetteur</th>
                                    <th class="p-2" style="min-width:100px">Type destinataire</th>
                                    <th class="p-2" style="min-width:100px">N° destinataire</th>
                                    <th class="p-2" style="min-width:100px">OCT*</th>
                                    <th style="width:50px">Norme</th>
                                    <th style="width:50px">Version</th>
                                    <th class="p-2" style="min-width:100px">Type de PS</th>
                                    <th class="p-2" style="width:50px"></th>
                                    <th class="p-2" style="width:50px"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let reg of regroupementsList">
                                    <td class="p-2">{{reg.regroupement}}</td>
                                    <td class="p-2" style="min-width:100px">{{reg.typeEmetteur}}</td>
                                    <td class="p-2" style="min-width:100px">{{reg.typeDestinataire}}</td>
                                    <td class="p-2" style="min-width:100px">{{reg.numDestinataire}}</td>
                                    <td class="p-2" style="min-width:100px">{{reg.oct}}</td>
                                    <td style="width:50px">{{reg.norme}}</td>
                                    <td style="width:50px">{{reg.version.libelle}}</td>
                                    <td class="p-2" style="min-width:100px">{{reg.typePs}}</td>
                                    <td class="p-2" style="width:50px">
                                        <a class="text-gray" (click)="openModalAddAssoc(reg)" data-placement="top"
                                            tooltip="Modifier le regroupement">
                                            <i class="fa fa-edit gridCellStyle" container="body">
                                            </i>
                                        </a>
                                    </td>
                                    <td class="p-2" style="width:50px">
                                        <a (click)="openModalConfim(reg)" class="text-gray" data-placement="top"
                                            tooltip="Supprimer le regroupement">
                                            <i class="fa fa-trash gridCellStyle" container="body">
                                            </i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<!-- Modal Association add -->
<div *ngIf="showAddAssoModal">
    <div class="modal-backdrop"></div>
    <div class="modal modal-show fade show" id="modalAddAssoc" tabindex="-1">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header pb-1">
                    <h3 class="modal-title" *ngIf="!isUpdateAssociation">Ajouter une association au type 0</h3>
                    <h3 class="modal-title" *ngIf="isUpdateAssociation">Modification de l'association
                        {{selectedAssociation.regroupement}}</h3>
                    <button class="close" (click)="closeModal()" name="btn_close" type="button" title="Fermer"><span
                            aria-hidden="true">×</span></button>
                </div>
                <div class="modal-body pb-0">
                    <div class="form-block">
                        <ul class="nav nav-tabs">
                            <li class="nav-item"><a (click)="showOnglet('onglet1')"
                                    [ngClass]="{'active': showAddAssoModalOnglet1}" class="nav-link"
                                    data-toggle="tab">Informations</a></li>
                            <li class="nav-item"><a (click)="showOnglet('onglet2')"
                                    [ngClass]="{'active': showAddAssoModalOnglet2}" class="nav-link" data-toggle="tab">
                                    Regroupements</a></li>
                        </ul>
                        <div id="myTabContent" class="tab-content">
                            <div id="onglet1" [ngClass]="{'show active': showAddAssoModalOnglet1}"
                                class="tab-pane fade">
                                <fieldset class="container-fluid">
                                    <form [formGroup]="regroupementForm">
                                        <div class="form-block">
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <div class="col-sm-3 col-form-label">Type émetteur</div>
                                                    <div class="col-sm-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="typeEmetteur" readonly
                                                            [ngClass]="{ 'is-invalid': addAssocBtnClicked && regptFormFields.typeEmetteur.errors }" />
                                                        <div *ngIf="addAssocBtnClicked && regptFormFields.typeEmetteur.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="regptFormFields.typeEmetteur.errors.required">*
                                                                Ce champ est obligatoire</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-3 col-form-label">Émetteur</div>
                                                    <div class="col-sm-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="numEmetteur" readonly
                                                            [ngClass]="{ 'is-invalid': addAssocBtnClicked && regptFormFields.numEmetteur.errors }" />
                                                        <div *ngIf="addAssocBtnClicked && regptFormFields.numEmetteur.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="regptFormFields.numEmetteur.errors.required">*
                                                                Ce champ est obligatoire</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-3 col-form-label">Type destinataire</div>
                                                    <div class="col-sm-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="typeDestinataire" readonly
                                                            [ngClass]="{ 'is-invalid': addAssocBtnClicked && regptFormFields.typeDestinataire.errors }" />
                                                        <div *ngIf="addAssocBtnClicked && regptFormFields.typeDestinataire.errors"
                                                            class="invalid-feedback">
                                                            <div
                                                                *ngIf="regptFormFields.typeDestinataire.errors.required">
                                                                Ce champ est obligatoire</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-3 col-form-label">N° destinataire*</div>
                                                    <div class="col-sm-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="numDestinataire" readonly
                                                            [ngClass]="{ 'is-invalid': addAssocBtnClicked && regptFormFields.numDestinataire.errors }" />
                                                        <div *ngIf="addAssocBtnClicked && regptFormFields.numDestinataire.errors"
                                                            class="invalid-feedback">
                                                            <div
                                                                *ngIf="regptFormFields.numDestinataire.errors.required">
                                                                Ce champ est obligatoire</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-3 col-form-label">Oct*</div>
                                                    <div class="col-sm-3 form-group">
                                                        <ng-select [clearable]="false"
                                                            [ngClass]="{ 'is-invalid': addAssocBtnClicked && regptFormFields.oct.errors }"
                                                            formControlName="oct" bindLabel="nom" bindValue="idOct"
                                                            appendTo=".modal-content" [items]="octs"
                                                            style="width:100%; height:calc(1.8em + .75rem + 2px)!important;">
                                                        </ng-select>
                                                        <div *ngIf="addAssocBtnClicked && regptFormFields.oct.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="regptFormFields.oct.errors.required">* Champ
                                                                obligatoire</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-3 col-form-label">Norme</div>
                                                    <div class="col-sm-3 form-group">
                                                        <ng-select [clearable]="false"
                                                            [ngClass]="{ 'is-invalid': addAssocBtnClicked && regptFormFields.norme.errors }"
                                                            formControlName="norme" bindLabel="libelle"
                                                            bindValue="idNorme" appendTo=".modal-content" [items]="normes"
                                                            (change)="onChangeNorme($event)">
                                                        </ng-select>
                                                        <div *ngIf="addAssocBtnClicked && regptFormFields.norme.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="regptFormFields.norme.errors.required">* Champ
                                                                obligatoire</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-3 col-form-label">Version</div>
                                                    <div class="col-sm-3 form-group">
                                                        <ng-select [clearable]="false"
                                                            [ngClass]="{ 'is-invalid': addAssocBtnClicked && regptFormFields.version.errors }"
                                                            formControlName="version" bindLabel="libelle"
                                                            bindValue="idVersionNorme" appendTo=".modal-content"
                                                            [items]="versions">
                                                        </ng-select>
                                                        <div *ngIf="addAssocBtnClicked && regptFormFields.version.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="regptFormFields.version.errors.required">* Champ
                                                                obligatoire</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-3 col-form-label">Type de PS</div>
                                                    <div class="col-sm-3 form-group">
                                                        <ng-select [clearable]="false"
                                                            [ngClass]="{ 'is-invalid': addAssocBtnClicked && regptFormFields.typePs.errors }"
                                                            formControlName="typePs" name="typePs"
                                                            bindLabel="libelleLong" bindValue="idTypePs" appendTo=".modal-content"
                                                            [items]="typePss">
                                                        </ng-select>
                                                        <div *ngIf="addAssocBtnClicked && regptFormFields.typePs.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="regptFormFields.typePs.errors.required">* Champ
                                                                obligatoire</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="control row">
                                                    <div class="col-6 mt-3">
                                                        <span class="spanError"
                                                            *ngIf="!isUpdateAssociation && addAssocBtnClicked && regroupementDataRaw.idRegroupement==null">Veuillez
                                                            sélectionner un regroupement avant d’enregister</span>
                                                    </div>
                                                    <div class="button-bar col-6">
                                                        <button type="button" (click)="renitialiser()"
                                                            class="btn btn-default">Annuler</button>
                                                        <button type="button" (click)="saveAssociation()"
                                                            class="btn btn-primary">Enregistrer</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div id="myTabContent" class="tab-content">
                        <div id="onglet2" [ngClass]="{'show active': showAddAssoModalOnglet2}" class="tab-pane fade">
                            <fieldset class="container-fluid">
                                <div class="row mb-2">
                                    <div class="col">
                                        <h3>Regroupement selectionné :
                                            <span
                                                *ngIf="regroupementDataRaw.idRegroupement!=null && regroupementDataRaw.libelle!=null">
                                                {{regroupementDataRaw.libelle}}
                                            </span>
                                            <span
                                                *ngIf="isUpdateAssociation && regroupementDataRaw.idRegroupement==null">{{selectedAssociation.regroupement}}
                                            </span>
                                            <span
                                                *ngIf="!isUpdateAssociation && regroupementDataRaw.idRegroupement==null">Aucun</span>
                                        </h3>
                                    </div>
                                </div>
                                <ag-grid-angular style="width: 100%;" class="ag-theme-material mb-0"
                                    [gridOptions]="gridHelperRegroupement.gridOptions">
                                </ag-grid-angular>
                                <careweb-pagination class="m-0" [gridApi]="gridHelperRegroupement.gridApi">
                                </careweb-pagination>
                                <!-- TOOLBAR -->
                                <div class="row m-0">
                                    <div class="col-6 mt-0">
                                        <span class="spanError"
                                            *ngIf="!isUpdateAssociation && addAssocBtnClicked && regroupementDataRaw.idRegroupement==null">Veuillez
                                            sélectionner un regroupement avant d’enregister</span>
                                    </div>
                                    <div class="button-bar col-6 mt-0">
                                        <button type="button" (click)="renitialiser()"
                                            class="btn btn-default">Annuler</button>
                                        <button type="button" (click)="saveAssociation()"
                                            class="btn btn-primary">Enregistrer</button>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal confirmation de suppression -->
<careweb-confirm-modal (confirmModal)="confirmModal()" (showModal)="showModal($event)" [(visible)]="showConfirmModal"
    [text]="modalMessage">
</careweb-confirm-modal>