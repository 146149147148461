import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONF } from '../../../core/constants';
import { CodeEntreeDataRaw } from '../../../data/codeEntreeDataRaw';
import { FilterService } from '../../../core/services/filter-service';

@Injectable({
	providedIn: 'root'
})
export class CodeEntreeService extends FilterService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/organismes/codeEntree';

	constructor(private httpClient: HttpClient) {
		super();
	}

	getCodeEntreeFilteredList(criteria: any, codeEntreeDataRaw: CodeEntreeDataRaw): Observable<any> {
		return this.httpClient.post<any>(
			this.baseUrl +
				'/search?page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				this.getSortParameters(criteria.sorts),
			codeEntreeDataRaw
		);
	}

	verifyIfCodeEntreExist(codeEntree: CodeEntreeDataRaw): Observable<any> {
		return this.httpClient.get<any>(
			this.baseUrl + '/search/' + codeEntree.codeEntree + '/amc/' + codeEntree.idAmc
		);
	}

	getSortParameters(sorts) {
		if (sorts.length === 1) {
			const sort = sorts[0].property;
			return '&sort=' + sort + '&direction=' + sorts[0].direction;
		} else {
			// sort par défaut
			return '&sort=codeEntree&direction=ASC';
		}
	}

	save(codeEntree) {
		return this.httpClient.put(this.baseUrl, codeEntree).toPromise();
	}

	delete(codeEntreeId): Promise<any> {
		return this.httpClient.delete(this.baseUrl + '/' + codeEntreeId).toPromise();
	}
}
