import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { isNullOrUndefined } from 'util';

/*
	Allow to render icons on Organisme table
*/

@Component({
	selector: 'careweb-organisme-preview',
	template: `
      <i [hidden]="hide"
        [ngClass]="iconClass"
        container="body"
        (click)="organismeActionClick()"
        [tooltip]="tooltip"
        containerClass="tooltip-grid"
		(contextmenu)="onRightClick($event)"
	  ></i>
    `
})
export class OrganismePreviewComponent implements ICellRendererAngularComp, AfterViewInit {
	private params: any;
	iconClass: string;
	tooltip = '';
	hide = false;
	showConfirmModal = false;
	modalMessage: string;

	constructor(private router: Router) {}

	agInit(params: any): void {
		this.params = params;
	}

	ngAfterViewInit() {
		const cellParams = this.params;
		if (cellParams.data) {
			if (!isNullOrUndefined(cellParams.iconClass)) {
				this.iconClass = cellParams.iconClass;
			}

			if (!isNullOrUndefined(cellParams.action)) {
				if (cellParams.action === 'view') {
					this.tooltip = 'Consulter';
				} else if (cellParams.action === 'delete') {
					this.tooltip = 'Supprimer';
				} else if (cellParams.action === 'edit' && cellParams.type === 'organisme') {
					this.tooltip = "Modifier l'organisme";
				} else if (cellParams.action === 'edit' && cellParams.type === 'type0') {
					this.tooltip = 'Modifier le type 0';
				} else if (cellParams.action === 'warning') {
					if (!isNullOrUndefined(this.params.data) && !isNullOrUndefined(this.params.data.dateFinValidite)) {
						this.hide = false;
						this.tooltip = "Cet organisme n'est actif que pour des dates restreintes";
					} else {
						this.hide = true;
					}
				} else if (cellParams.action === 'deleteCD') {
					this.tooltip = 'Supprimer le code entrée';
				}
			}
		}
	}

	refresh(): boolean {
		return false;
	}

	organismeActionClick(): void {
		if (
			!isNullOrUndefined(this.params.action) &&
			this.params.action === 'view' &&
			this.params.redirect === 'organismeDetails'
		) {
			this.router.navigate([
				'/organisme-details',
				this.params.data.idOrganisme,
				this.params.data.typeOrganisme
			]);
		} else if (
			!isNullOrUndefined(this.params.action) &&
			this.params.action === 'edit' &&
			this.params.redirect === 'type0Details'
		) {
			this.router.navigate([ '/type0-details', this.params.data.idTypeZero ]);
		}
	}

	onRightClick(event: MouseEvent): void {		
		if (this.params.redirect === 'organismeDetails') {
			event.preventDefault();
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/careweb-client/organisme-details',  this.params.data.idOrganisme,this.params.data.typeOrganisme])
			);
			window.open(url, '_blank');
		}
	}
}
