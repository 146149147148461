import { ColDef, IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import { AgGridHelper } from '../../../components/ag-grid/ag-grid-helper';
import { CiviliteDataRaw } from '../../../data/civiliteDataRaw';
import { ContactsDataRaw } from '../../../data/contactsDataRaw';
import { EditeurDataRaw } from '../../../data/editeurDataRaw';
import { LogicielDataRaw } from '../../../data/logicielDataRaw';
import { ContactsService } from '../services/contacts.service';
import { EditeurService } from '../services/editeurs.service';
import { LogicielsService } from '../services/logiciels.service';
import { FicheEditeurRattacherComponent } from './rattacher-logiciel/fiche-editeur-rattacher.component';

@Component({
	selector: 'careweb-app-fiche-editeur',
	templateUrl: './fiche-editeur.component.html',
	styleUrls: ['./fiche-editeur.component.scss']
})
export class FicheEditeurComponent implements OnInit {
	@Input() selectedEditeur: EditeurDataRaw;
	logicielList = new Array();
	logicielDataRaw: LogicielDataRaw;
	contactsList = new Array();
	showConfirmModal = false;
	showAddLogicielModal = false;
	showAddContactModal = false;
	showNewSoft = false;
	showExistingSoft = false;
	isUpdateContact = false;
	contactFormSubmitted = false;
	logicielFormSubmitted = false;
	modalMessage: string;
	selectedLogiciel: LogicielDataRaw;
	selectedContact: ContactsDataRaw;
	selectedContactBeforeEdit: ContactsDataRaw;
	gridHelperLogiciel: AgGridHelper;
	paginationSizeLogiciel: number;
	attachedLogiciel: LogicielDataRaw;
	newLogiciel: LogicielDataRaw;
	logicielAlreadyAttached = false;
	contactForm: UntypedFormGroup;
	logicielForm: UntypedFormGroup;
	editeurForm: UntypedFormGroup;
	editeurFormSubmitted = false;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private editeurService: EditeurService,
		private logicielsService: LogicielsService,
		private contactsService: ContactsService,
		private toastr: ToastrService,
		private fb: UntypedFormBuilder,
		private cdRef: ChangeDetectorRef
	) {
		this.selectedEditeur = new EditeurDataRaw();
		this.selectedLogiciel = new LogicielDataRaw();
		this.logicielDataRaw = new LogicielDataRaw();
		this.isUpdateContact = false;
		this.newLogiciel = new LogicielDataRaw();
		this.gridHelperLogiciel = new AgGridHelper();
	}

	ngOnInit() {
		this.initEditeurForm();
		this.initContactForm();
		this.initLogicielForm();
		const idEditeur = this.activatedRoute.snapshot.params.id;
		if(!parseInt(idEditeur)){
			this.router.navigate(['/404'])
		}
		// get selected editeur
		this.editeurService.getEditeurById({ idEditeur: idEditeur }).subscribe((data) => {
			this.selectedEditeur = data;
			// formater les nmeros de téléphone
			this.selectedEditeur.telephone = this.formatPhoneNumbers(this.selectedEditeur.telephone);
			this.selectedEditeur.fax = this.formatPhoneNumbers(this.selectedEditeur.fax);
			// Fill form editeur
			this.fromModelToEditeurForm();
			// get list of logiciels
			this.getListLogiciel(this.selectedEditeur.idEditeur);
			// get list of contacts
			this.getListContact(this.selectedEditeur.idEditeur);
		},
		err=>{
			if(err.status===400){
				this.router.navigate(['/404'])
			}
		});

		this.selectedContact = new ContactsDataRaw();
	}

	initEditeurForm() {
		this.editeurForm = this.fb.group({
			raisonSociale: new UntypedFormControl(''),
			adresse1: new UntypedFormControl(''),
			adresse2: new UntypedFormControl(''),
			ville: new UntypedFormControl(''),
			codePostal: new UntypedFormControl(''),
			telephone: new UntypedFormControl(''),
			fax: new UntypedFormControl(''),
			email: new UntypedFormControl(''),
			url: new UntypedFormControl(''),
			idEditeur: ''
		});
	}

	initContactForm() {
		this.contactForm = this.fb.group({
			civilite: new UntypedFormControl('', [Validators.required]),
			nom: new UntypedFormControl('', [Validators.required]),
			prenom: new UntypedFormControl('', [Validators.required]),
			telephone: new UntypedFormControl('', [Validators.required]),
			fax: new UntypedFormControl(''),
			mobile: new UntypedFormControl(''),
			mail: new UntypedFormControl(''),
			idContact: ''
		});
	}
	initLogicielForm() {
		this.logicielForm = this.fb.group({
			libelle: new UntypedFormControl('', [Validators.required]),
			systeme: new UntypedFormControl(''),
			version: new UntypedFormControl('')
		});
	}

	fromEditeurFormToModel() {
		this.selectedEditeur.idEditeur = this.editeurForm.controls.idEditeur.value;
		this.selectedEditeur.raisonSociale = this.editeurForm.controls.raisonSociale.value;
		this.selectedEditeur.adresse1 = this.editeurForm.controls.adresse1.value;
		this.selectedEditeur.adresse2 = this.editeurForm.controls.adresse2.value;
		this.selectedEditeur.ville = this.editeurForm.controls.ville.value;
		this.selectedEditeur.codePostal = this.editeurForm.controls.codePostal.value;
		this.selectedEditeur.email = this.editeurForm.controls.email.value;
		this.selectedEditeur.url = this.editeurForm.controls.url.value;
		this.selectedEditeur.telephone = this.editeurForm.controls.telephone.value;
		this.selectedEditeur.fax = this.editeurForm.controls.fax.value;
	}

	fromModelToEditeurForm() {
		this.editeurForm.controls.idEditeur.setValue(this.selectedEditeur.idEditeur);
		this.editeurForm.controls.raisonSociale.setValue(this.selectedEditeur.raisonSociale);
		this.editeurForm.controls.adresse1.setValue(this.selectedEditeur.adresse1);
		this.editeurForm.controls.adresse2.setValue(this.selectedEditeur.adresse2);
		this.editeurForm.controls.ville.setValue(this.selectedEditeur.ville);
		this.editeurForm.controls.codePostal.setValue(this.selectedEditeur.codePostal);
		this.editeurForm.controls.email.setValue(this.selectedEditeur.email);
		this.editeurForm.controls.url.setValue(this.selectedEditeur.url);
		this.editeurForm.controls.telephone.setValue(this.selectedEditeur.telephone);
		this.editeurForm.controls.fax.setValue(this.selectedEditeur.fax);
	}
	get editeurFormFields() {
		return this.editeurForm.controls;
	}
	get contactFormFields() {
		return this.contactForm.controls;
	}
	get logicielFormFields() {
		return this.logicielForm.controls;
	}
	reinitialiserEditeur() {
		this.editeurFormSubmitted = false;
		this.editeurService.getEditeurById({ idEditeur: this.selectedEditeur.idEditeur }).subscribe((data) => {
			this.selectedEditeur = data;
			this.selectedEditeur.telephone = this.formatPhoneNumbers(this.selectedEditeur.telephone);
			this.selectedEditeur.fax = this.formatPhoneNumbers(this.selectedEditeur.fax);
			this.fromModelToEditeurForm();
		});
	}
	reinitialiserContact() {
		if (this.isUpdateContact) {
			this.fromModelToContactForm(this.selectedContactBeforeEdit);
			this.cdRef.detectChanges();
		} else {
			this.selectedContact = new ContactsDataRaw();
			this.selectedContact.civilite = new CiviliteDataRaw();
			this.fromModelToContactForm(this.selectedContact);
			this.selectedContact.civilite = new CiviliteDataRaw();
			this.cdRef.detectChanges();
		}
		this.closeModal();
	}

	isObject(item) {
		return item && typeof item === 'object' && !Array.isArray(item);
	}

	mergeDeep(target, ...sources) {
		if (!sources.length) {
			return target;
		}
		const source = sources.shift();

		if (this.isObject(target) && this.isObject(source)) {
			for (const key in source) {
				if (this.isObject(source[key])) {
					if (!target[key]) Object.assign(target, { [key]: {} });
					this.mergeDeep(target[key], source[key]);
				} else {
					Object.assign(target, { [key]: source[key] });
				}
			}
		}

		return this.mergeDeep(target, ...sources);
	}

	refrechContacts() {
		// refresh list of contacts
		this.getListContact(this.selectedEditeur.idEditeur);
		this.closeModal();
	}

	getListContact(idEditeur) {
		this.contactsService.getContactsByEditeurId({ idEditeur: idEditeur }).subscribe((data) => {
			this.contactsList = data;
		});
	}

	getListLogiciel(idEditeur) {
		this.logicielsService.getLogicielByEditeurId({ idEditeur: idEditeur }).subscribe((data) => {
			this.logicielList = data;
		});
	}

	public openModalConfim(type: string, toDeleteObject: any) {
		this.showConfirmModal = true;
		if (type === 'logiciel') {
			this.modalMessage = 'Voulez-vous supprimer ce logiciel ?';
			this.selectedLogiciel = toDeleteObject;
		} else if (type === 'contact') {
			this.modalMessage = 'Voulez-vous supprimer ce contact ?';
			this.selectedContact = toDeleteObject;
		}
	}

	public openModalAddLogiciel() {
		this.showAddLogicielModal = true;
		this.showExistingSoft = true;
		this.showNewSoft = false;
		this.logicielDataRaw = new LogicielDataRaw();
		this.gridHelperLogiciel.gridOptions = {
			defaultColDef: {
				resizable: true
			},
			columnDefs: this.getColumnDefs(),
			context: { parentComponent: this },
			components: {
				customInputFilterComponent: this.getInputFloatingFilterComponent()
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 3,
			maxBlocksInCache: 0,
			infiniteInitialRowCount: 1,
			paginationPageSize: 3, // ????
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			localeText: this.gridHelperLogiciel.getLocaleText(),
			onGridReady: (params) => this.onGridReady(params)
			// onGridSizeChanged: (params) => this.onGridSizeChanged(params)
		};
		this.paginationSizeLogiciel = this.gridHelperLogiciel.paginationPageSize;
	}

	onGridSizeChanged(params) {
		this.gridHelperLogiciel.gridApi.sizeColumnsToFit();
	}

	onGridReady(params) {
		const ctx = this;
		this.gridHelperLogiciel.gridApi = params.api;
		this.gridHelperLogiciel.gridColumnApi = params.columnApi;

		ctx.logicielDataRaw.idEditeur = ctx.selectedEditeur.idEditeur;

		const dataSource: IServerSideDatasource = {
			getRows: function (paramsRows: IServerSideGetRowsParams) {
				ctx.logicielsService
					.getLogicielsByCriteria(
						{
							page: ctx.gridHelperLogiciel.gridApi.paginationGetCurrentPage(),
							size: ctx.gridHelperLogiciel.gridApi.paginationGetPageSize()
						},
						ctx.logicielDataRaw
					)
					.subscribe(
						(dataLogiciel) => {
							const rowCount = dataLogiciel.totalElements;
							ctx.gridHelperLogiciel.manageNoRowsOverlay(rowCount);
							paramsRows.success({"rowData": dataLogiciel.content, "rowCount": rowCount});
						},
						() => paramsRows.fail()
					);
			}
		};
		this.gridHelperLogiciel.gridApi.setServerSideDatasource(dataSource);
		this.gridHelperLogiciel.gridApi.sizeColumnsToFit();
	}

	private getColumnDefs(): ColDef[] {
		return [
			{
				headerName: 'Libellé',
				field: 'libelle',
				minWidth: 80,
				lockVisible: true,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true },
				suppressMenu: true
			},
			{
				headerName: "Système d'exploitation",
				field: 'systeme',
				minWidth: 130,
				lockVisible: true,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true },
				suppressMenu: true
			},
			{
				headerName: 'Version',
				field: 'version',
				minWidth: 130,
				lockVisible: true,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true },
				suppressMenu: true
			},
			{
				headerName: '',
				field: 'options',
				maxWidth: 70,
				lockVisible: true,
				cellStyle: this.gridHelperLogiciel.centerContent(),
				onCellClicked: (params) => this.rattacher(params),
				cellRenderer: FicheEditeurRattacherComponent,
				cellRendererParams: {
					iconClass: 'fa fa-link'
				}
			}
		];
	}

	public rattacher(params) {
		this.attachedLogiciel = new LogicielDataRaw();
		if (!isNullOrUndefined(params.data) && !isNullOrUndefined(this.selectedEditeur)) {
			this.attachedLogiciel.idEditeur = this.selectedEditeur.idEditeur;
			this.attachedLogiciel.libelle = params.data.libelle;
			this.attachedLogiciel.version = params.data.version;
			this.attachedLogiciel.systeme = params.data.systeme;

			this.logicielsService.rattacher(this.attachedLogiciel).then((returnAttachedLogiciel) => {
				if (!isNullOrUndefined(returnAttachedLogiciel)) {
					this.toastr.success('Le logiciel a été ajouté avec succès');
					// refresh logiciel list
					this.getListLogiciel(this.selectedEditeur.idEditeur);
					this.closeModal();
				} else {
					this.toastr.error(
						'Une erreur est survenue lors de rattachement de logiciel ',
						'Rattacher un logiciel'
					);
				}
			});
		}
	}

	ajouterLogiciel() {
		this.logicielFormSubmitted = true;
		this.logicielAlreadyAttached = false;
		if (this.logicielForm.valid && !this.isAttachedToSelectedEditeur()) {
			this.newLogiciel.idEditeur = this.selectedEditeur.idEditeur;
			this.newLogiciel.libelle = this.newLogiciel.libelle ? this.newLogiciel.libelle.trim() : '';
			this.newLogiciel.systeme = this.newLogiciel.systeme ? this.newLogiciel.systeme.trim() : '';
			this.newLogiciel.version = this.newLogiciel.version ? this.newLogiciel.version.trim() : '';
			this.logicielsService.rattacher(this.newLogiciel).then((returnAttachedLogiciel) => {
				if (!isNullOrUndefined(returnAttachedLogiciel)) {
					this.toastr.success('Le logiciel a été ajouté avec succès');
					// refresh logiciel list
					this.getListLogiciel(this.selectedEditeur.idEditeur);
					this.closeModal();
				} else {
					this.toastr.error(
						'Une erreur est survenue lors de rattachement de logiciel ',
						'Rattacher un logiciel'
					);
				}
			});
			this.logicielFormSubmitted = false;
		}
	}

	isAttachedToSelectedEditeur() {
		if (!isNullOrUndefined(this.newLogiciel) && !isNullOrUndefined(this.newLogiciel.libelle)) {
			this.logicielList.forEach((logiciel) => {
				if (logiciel.libelle.trim().toUpperCase() === this.newLogiciel.libelle.trim().toUpperCase()) {
					this.logicielAlreadyAttached = true;
					return this.logicielAlreadyAttached;
				}
			});
		}
		return this.logicielAlreadyAttached;
	}

	public openModalAddContact(contcatToUpdate?: any) {
		// Manage visibility of modals
		this.showAddLogicielModal = false;
		this.showExistingSoft = false;
		this.showNewSoft = false;
		this.showAddContactModal = true;
		this.contactFormSubmitted = false;
		// Get the selected contact
		if (!isNullOrUndefined(contcatToUpdate)) {
			// il s'agit d'une modification
			this.isUpdateContact = true;
			this.selectedContactBeforeEdit = new ContactsDataRaw();
			this.selectedContactBeforeEdit.civilite = new CiviliteDataRaw();
			this.mergeDeep(this.selectedContactBeforeEdit, contcatToUpdate);
			this.fromModelToContactForm(this.selectedContactBeforeEdit);
		} else {
			this.isUpdateContact = false;
			this.selectedContact = new ContactsDataRaw();
			this.selectedContact.civilite = new CiviliteDataRaw();
			this.fromModelToContactForm(this.selectedContact);
		}
		this.cdRef.detectChanges();
	}

	public enregistrerContact() {
		this.contactFormSubmitted = true;
		if (this.contactForm.valid) {
			this.fromContactFormToModel();

			if (this.selectedContact.civilite.libelleCourt === 'Mr') {
				this.selectedContact.idCivilite = 1;
				this.selectedContact.civilite.idCivilite = 1;
			} else if (this.selectedContact.civilite.libelleCourt === 'Mme') {
				this.selectedContact.idCivilite = 2;
				this.selectedContact.civilite.idCivilite = 2;
			}
			this.selectedContact.idEditeur = this.selectedEditeur.idEditeur;
			this.selectedContact.nom = this.selectedContact.nom ? this.selectedContact.nom.trim() : '';
			this.selectedContact.prenom = this.selectedContact.prenom ? this.selectedContact.prenom.trim() : '';
			this.selectedContact.mail = this.selectedContact.mail ? this.selectedContact.mail.trim() : '';

			this.contactsService
				.enregistrerContact(this.selectedContact)
				.then((updatedContact) => {
					if (!isNullOrUndefined(updatedContact)) {
						if (this.isUpdateContact) {
							this.toastr.success('Le contact a été mis à jour avec succès');
						} else {
							this.toastr.success('Le contact a été ajouté avec succès');
						}
						// rafraichire la liste
						this.refrechContacts();
					}
				})
				.catch((err) => {
					this.refrechContacts();

					if (this.isUpdateContact) {
						this.toastr.error(
							'Une erreur est survenue lors de la modification du contact !!',
							"Modification d'un contact"
						);
					} else {
						this.toastr.error(
							"Une erreur est survenue lors de l'ajout du contact !!",
							"Ajout d'un contact"
						);
					}
				});
		}
	}

	public closeModal() {
		this.showAddLogicielModal = false;
		this.showAddContactModal = false;
		this.isUpdateContact = false;
		this.newLogiciel = new LogicielDataRaw();
	}

	reinitialiserLogicielForm() {
		this.newLogiciel.libelle = '';
		this.newLogiciel.systeme = '';
		this.newLogiciel.version = '';
		this.closeModal();
	}

	public showModal(showModal: boolean) {
		this.showConfirmModal = showModal;
	}

	public confirmModal() {
		if (!isNullOrUndefined(this.selectedLogiciel) && !isNullOrUndefined(this.selectedLogiciel.idLogiciel)) {
			// delete logiciel
			this.logicielsService
				.deleteByLogicielId(this.selectedLogiciel.idLogiciel)
				.then((ret) => {
					this.showModal(false);
					this.toastr.success('Le logiciel a été supprimé avec succès');

					// refresh logiciel liste after deleting
					const index: number = this.logicielList.indexOf(this.selectedLogiciel);
					if (index !== -1) {
						this.logicielList.splice(index, 1);
					}
					this.selectedLogiciel = new LogicielDataRaw();
				})
				.catch((err) => {
					this.showModal(false);
					this.selectedLogiciel = new LogicielDataRaw();
					this.toastr.error(
						'Une erreur est survenue lors de la suppression du logiciel !!',
						'Suppression de logiciel'
					);
				});
		} else if (!isNullOrUndefined(this.selectedContact) && !isNullOrUndefined(this.selectedContact.idContact)) {
			// delete contact
			this.contactsService
				.deleteByContactId(this.selectedContact.idContact)
				.then((ret) => {
					this.showModal(false);
					this.toastr.success('Le contact a été supprimé avec succès');
					// refresh Contacts liste after deleting
					const index: number = this.contactsList.indexOf(this.selectedContact);
					if (index !== -1) {
						this.contactsList.splice(index, 1);
					}
					this.selectedContact = new ContactsDataRaw();
				})
				.catch((err) => {
					this.selectedContact = new ContactsDataRaw();
					this.showModal(false);
					this.toastr.error(
						'Une erreur est survenue lors de la suppression du contact !!',
						"Suppression d'un contact"
					);
				});
		}
		this.showModal(false);
	}

	public enregistrerEditeur() {
		this.editeurFormSubmitted = true;
		this.fromEditeurFormToModel();

		if (this.editeurForm.valid && !isNullOrUndefined(this.selectedEditeur)) {
			this.selectedEditeur.adresse1 = this.selectedEditeur.adresse1 ? this.selectedEditeur.adresse1.trim() : '';
			this.selectedEditeur.adresse2 = this.selectedEditeur.adresse2 ? this.selectedEditeur.adresse2.trim() : '';
			this.selectedEditeur.ville = this.selectedEditeur.ville ? this.selectedEditeur.ville.trim() : '';
			this.selectedEditeur.email = this.selectedEditeur.email ? this.selectedEditeur.email.trim() : '';
			this.selectedEditeur.url = this.selectedEditeur.url ? this.selectedEditeur.url.trim() : '';

			this.editeurService.saveEditeur(this.selectedEditeur).subscribe(
				(updatedEditeur) => {
					if (!isNullOrUndefined(updatedEditeur)) {
						this.toastr.success("L'éditeur a été mis à jour avec succès");
					} else
						this.toastr.error(
							"Une erreur est survenue lors de la modification d'éditeur !!",
							"Modification d'un éditeur"
						);
				},
				(err) => {
					this.toastr.error(
						"Une erreur est survenue lors de la modification d'éditeur !!",
						"Modification d'un éditeur"
					);
				}
			);
		}
	}

	goToEditeur() {
		this.router.navigate(['/editeurs']);
	}

	showRattacherLogiciel(onglet) {
		if (onglet === 'newSoft') {
			this.showNewSoft = true;
			this.showExistingSoft = false;
		} else if (onglet === 'existingSoft') {
			this.showNewSoft = false;
			this.showExistingSoft = true;
		}
	}

	fromContactFormToModel() {
		this.selectedContact.idContact = this.contactFormFields.idContact.value;
		this.selectedContact.civilite = new CiviliteDataRaw();
		this.selectedContact.civilite.libelleCourt = this.contactFormFields.civilite.value;
		this.selectedContact.nom = this.contactFormFields.nom.value;
		this.selectedContact.prenom = this.contactFormFields.prenom.value;
		this.selectedContact.mail = this.contactFormFields.mail.value;
		this.selectedContact.telephone = this.contactFormFields.telephone.value;
		this.selectedContact.fax = this.contactFormFields.fax.value;
		this.selectedContact.mobile = this.contactFormFields.mobile.value;
	}

	fromModelToContactForm(selectedContact) {
		this.contactFormFields.idContact.setValue(selectedContact.idContact);
		this.contactFormFields.civilite.setValue(selectedContact.civilite.libelleCourt);
		this.contactFormFields.nom.setValue(selectedContact.nom);
		this.contactFormFields.prenom.setValue(selectedContact.prenom);
		this.contactFormFields.mail.setValue(selectedContact.mail);
		this.contactFormFields.telephone.setValue(selectedContact.telephone);
		this.contactFormFields.fax.setValue(selectedContact.fax);
		this.contactFormFields.mobile.setValue(selectedContact.mobile);
	}

	getInputFloatingFilterComponent() {
		const ctx = this;
		function InputFloatingFilter() { }

		InputFloatingFilter.prototype.init = function (params) {
			this.eGui = document.createElement('div');
			this.eGui.setAttribute('class', 'ag-input-wrapper');

			if (params.filterParams.colDef.field === 'libelle')
				this.eGui.innerHTML =
					"<input type='text' class='customFilterInput' style='width:100%; box-shadow: none;' maxlength='50' onKeyPress='if(this.value.length==50) return false;'/>";
			else if (params.filterParams.colDef.field === 'systeme')
				this.eGui.innerHTML =
					"<input type='text' class='customFilterInput' style='width:100%; box-shadow: none;' maxlength='30' onKeyPress='if(this.value.length==30) return false;'/>";
			else if (params.filterParams.colDef.field === 'version')
				this.eGui.innerHTML =
					"<input type='text' class='customFilterInput' style='width:100%; box-shadow: none;' maxlength='10' onKeyPress='if(this.value.length==10) return false;'/>";

			this.currentValue = null;
			this.eFilterInput = this.eGui.querySelector('input');
			this.eFilterInput.style.color = params.color;
			const that = this;
			function onInputChanged() {
				const filterField = params.filterParams.colDef.field;
				if (that.eFilterInput.value === '') {
					// Remove the filter
					delete ctx.logicielDataRaw[filterField];
					params.parentFilterInstance(function (instance) {
						instance.onFloatingFilterChanged(null, null);
					});
					return;
				}

				that.currentValue = that.eFilterInput.value;
				if (that.eFilterInput.value.length >= 3) {
					if (that.currentValue !== '') {
						ctx.logicielDataRaw[filterField] = that.eFilterInput.value;
					}
					params.parentFilterInstance(function (instance) {
						instance.onFloatingFilterChanged('equals', that.currentValue);
					});
				}
			}
			this.eFilterInput.addEventListener('input', onInputChanged);
		};

		InputFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
			if (!parentModel) {
				this.eFilterInput.value = '';
				this.currentValue = null;
			} else {
				this.eFilterInput.value = parentModel.filter + '';
				this.currentValue = parentModel.filter;
			}
		};

		InputFloatingFilter.prototype.getGui = function () {
			return this.eGui;
		};

		return InputFloatingFilter;
	}

	formatPhoneNumbers = (params): string => {
		if (!isNullOrUndefined(params)) {
			return params.replace(/\s/g, '').replace(/\./gi, '');
		} else {
			return params;
		}
	};
}
