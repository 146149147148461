import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { defineLocale, frLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { isNullOrUndefined } from 'util';

@Component({
	selector: 'careweb-date-personnalize-filter',
	templateUrl: './date-filter-personalize.html'
})
export class DateFilterpersonalizeComponent implements OnInit {
	@Input() labelFrom: string;

	@Input() modelFrom: Date;
	@Output() modelFromChange = new EventEmitter<Date>();

	@Input() maxDate?: Date | null = new Date();

	@Output() onEnter = new EventEmitter<any>();

	constructor(private localeService: BsLocaleService) {
		defineLocale('fr', frLocale);
	}

	ngOnInit() {
		this.setLocale();
		this.maxDate = !isNullOrUndefined(this.maxDate) ? this.maxDate : new Date();
	}

	onModelFromChange(date: Date | Event) {
		this.emitChange(date, this.modelFromChange);
	}

	onChangeInput($event) {
		if ($event.target.value === 'Invalid date'){
			$event.target.value = 'Date invalide';
		}
	}

	private setLocale() {
		this.localeService.use('fr');
	}

	private emitChange(date: Date | Event, emitter: EventEmitter<Date>) {
		if (date) {
			let value = null;
			if (date instanceof Date) {
				value = date;
			}
			emitter.emit(value);
		}
	}

	onKeyEnter() {
		this.onEnter.emit();
	}
}
