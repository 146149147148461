<div class="full-careweb-container">
    <div class="grid-container" id="grid">
        <div class="row form-title mt-3">
            <h2 class="col-md-6">
                Conditions générales d'utilisation
            </h2>
        <div>
            <section>

                <div class="row" style="background-color:white; padding:20px">
                    <div class="col">

                        <div class="conditions-group">
                            <div class="conditions-title">1.	OBJET</div>
                            <div>
                                <p>
Les présentes Conditions Générales d’Utilisation (ci-après dénommée « CGU ») ont pour objet de définir les termes et conditions applicables à l’accès et l’utilisation du Portail CAREWeb ®, (ci-après dénommé « Portail ») et de décrire le traitement des Feuilles de Soins Electroniques gérées au travers dudit Portail.
                                </p>
                            </div>
                        </div>

                        <div class="conditions-group">
                            <div class="conditions-title">2.	DEFINITIONS</div>
                            <div>
                                <p>
                                    <b>« CAREWeb® »</b> marque déposée par Cegedim.
                                    <br/><br/>
                                    <b>« Délégué à la Protection des Données »</b> : personne désignée au sens de l’article 37 du Règlement RGPD.
                                    <br/><br/>
                                    <b>« Données à Caractère Personnel » ou « Données »</b> : toute information se rapportant à une personne physique identifiée ou identifiable. Une « personne physique identifiable » est une personne physique qui peut être identifiée, directement ou indirectement, notamment par référence à un identifiant, tel qu'un nom, un numéro d'identification, des données de localisation, un identifiant en ligne, ou à un ou plusieurs éléments spécifiques propres à son identité physique, physiologique, génétique, psychique, économique, culturelle ou sociale.
                                    <br/><br/>
                                    <b>« Données Relatives à l’état de Santé » ou « Données de Santé »</b> : Données à Caractère Personnel relatives à la santé physique ou mentale des Personnes Concernées, y compris celles relatives à la prestation de services de soins de santé, qui révèlent des informations sur l'état de santé de ces personnes.
                                    <br/><br/>
                                    <b>« Droits des Personnes » </b>: droit d’accès, de rectification, d’effacement et d’opposition, droit à la limitation du traitement, droit à la portabilité des Données, droit de ne pas faire l’objet d’une décision individuelle automatisée (y compris le profilage) des Personnes Concernées.
                                    <br/><br/>
                                    <b>« Demande de Remboursement Electronique (DRE) »</b> : désigne la dématérialisaion d’une demande de prise en charge par les organismes d’assurance maladie complémentaire des frais engagés par le patient, ou par le professionnel de santé dans le cadre du tiers-payant.
                                    <br/><br/>
                                    <b>« Feuille(s) de soin(s) Electronique(s) »</b>: désigne la dématérialisation d’une feuille de soins de santé, permettant l’éventuelle prise en charge par les organismes d’assurance maladie (CPAM, mutuelles) des frais engagés par le patient, ou par le professionnel de santé dans le cadre du tiers-payant.
                                    <br/><br/>
                                    <b>« Finalité(s) »</b> : Objectif(s) du traitement dans le cadre des prestations définies à l’article 5 des présentes CGU.
                                    <br/><br/>
                                    <b>« OCT » </b>: désigne l’Organisme Concentrateur Technique de Cegedim qui assure le routage vers les organismes d’assurance maladie des FSE et DRE émises par les Professionnels de Santé, et qui au travers du Portail CAREWeb permet la consultation des informations résultant de leur traitement, bénéficier de la traçabilité de l’information et assister les  Professionnels de Santé en cas de problème ou de recherche de renseignement (hotline).
                                    <br/><br/>
                                    <b>« Partie(s) </b>: désigne(nt) Cegedim et/ou Professionnel de Santé
                                    <br/><br/>
                                    <b>« Personne Concernée » </b>: personne à laquelle se rapportent les Données à Caractère Personnel qui font l'objet du traitement.
                                    <br/><br/>
                                    <b>« Portail CAREWeb® » </b>(ci-après dénommé « Portail ») : désigne le portail développé par Cegedim permettant aux Professionnels de santé et aux Utilisateurs OCT de suivre le traitement des Feuilles de Soins.
                                    <br/><br/>
                                    <b>« Professionnel (s) de Santé » ou « Utilisateur </b> » : désigne(nt) désigne(nt) la personne morale ou les personnes morales inscrite(s) sur le Portail au travers duquel ils vérifient la bonne transmission des Feuilles de Soins Electroniques vers leurs destinataires (CPAM, mutuelles) et ce en vue de s’assurer du remboursement de ces dernières. Ces professionnels de santé sont les phamacies de ville (et pharmacies mutualistes, pharmacies minières, fournisseurs de matériel médical), médecins, auxiliaires medicaux  ou des centres de Santé.
                                    <br/><br/>
                                    <b>« Responsable de traitement » </b>: la personne morale qui détermine les finalités et les moyens du traitement. Dans le cadre des présentes CGU, le Responsable de traitement est le Professionnel de Santé
                                    <br/><br/>
                                    <b>« Sous-traitant » </b>: la personne morale qui traite des Données à Caractère Personnel pour le compte du Responsable de traitement. Dans le cadre des présentes CGU, le Sous-traitant est CEGEDIM.
                                    <br/><br/>
                                    <b>« Traitement de Données à Caractère Personnel » ou « Traitement » </b>: toute opération ou tout ensemble d'opérations effectuées ou non à l'aide de procédés automatisés et appliquées à des Données ou des ensembles de Données à Caractère Personnel, telles que la collecte, l'enregistrement, l'organisation, la structuration, la conservation, l'adaptation ou la modification, l'extraction, la consultation, l'utilisation, la communication par transmission, la diffusion ou toute autre forme de mise à disposition, le rapprochement ou l'interconnexion, la limitation, l'effacement ou la destruction.
                                    <br/><br/>
                                    <b>Utilisateur (s)  </b>: personne(s) physique(s), désigné(s) et habilité(s) par le Professionnel de Santé [= <b>Utilisateur(s) externe(s)</b>]  ou l’OCT [(= <b>Utilisateur(s) OCT</b>)]  pour accéder et utiliser les fonctionnalités du Portail.
                                    <br/><br/>
                                    <b>« Violation de Données à Caractère personnel »</b> : une violation de la sécurité entraînant, de manière accidentelle ou illicite, la destruction, la perte, l'altération, la divulgation non autorisée de Données à Caractère Personnel transmises, conservées ou traitées d'une autre manière, ou l'accès non autorisé à de telles données.
                                </p>
                            </div>
                        </div>

                        <div class="conditions-group">
                            <div class="conditions-title">3.	ACCEPTATION DES CONDITIONS GENERALES</div>
                            <div>
                                <p>
                                    L’accès et l’utilisation du Portail sont soumis à la saisie des données nécessaires à l’inscription et à l’acceptation préalable des présentes Conditions Générales d’Utilisation. 
                                    <br/><br/>L’Utilisateur coche la case « J’accepte les conditions générales du Portail CAREWeb® » et clique sur « Valider mon inscription » ; par cette validation, l’Utilisateur reconnait expressément avoir lu les présentes conditions générales d’utilisation et déclare en accepter l’intégralité, sans réserve.
                                    <br/><br/>Cegedim se réserve le droit de modifier à tout moment, en tout ou partie, les présentes Conditions Générales d’Utilisation. Toute modification entre en vigueur à compter de sa mise en ligne ou, le cas échéant, à toute autre date mentionnée aux présentes Conditions Générales d’Utilisation.
                                    <br/><br/>Il est recommandé à l’Utilisateur de consulter, s’assurer de l’existence de telles modifications des présentes Conditions Générales d’Utilisation. 
                                    <br/><br/>L’accès et l’utilisation du portail par l’Utilisateur valent acceptation des modifications des Conditions Générales d’Utilisation.
                                </p>
                            </div>
                        </div>

                        <div class="conditions-group">
                            <div class="conditions-title">4.    FONCTIONNEMENT DU PORTAIL CAREWEB® – DESCRIPTION DES SERVICES</div>
                            <div>
                                    <u>Fonctionnalités Professionnels de Santé</u>
                                    <ul>
                                        <li>Suivi des flux de FSE/DRE télétransmis sur leur(s) compte(s) client(s)</li>
                                        <li>Consultation du référentiel organismes (Caisses d’Assurance Maladie du Régime Obligatoire, Mutuelles, Gestionnaires de Mutuelles et Courtiers d’Assurance)</li>
                                        <li>Téléchargement de rapports comptables (état des factures, prestations CPAM)</li>
                                        <li>Téléchargement de versions éditables des retours de traitement</li>
                                        <li>Mise à jour de la Liste d’Opposition Incrémentale (LOI)</li>
                                    </ul>
                                    
                                    <br/>
                                    <u>Fonctionnalités OCT</u>

                                    Les Utilisateurs OCT disposent des mêmes fonctionnalités que les utilisateurs externes, et en complément :
                                    <ul>
                                        <li>Création, maintenance et paramétrage des comptes des Professionnels de Santé </li>
                                        <li>Suivi global des flux de FSE/DRE télétransmis par tous les Professionnels de Santé </li>
                                        <li>Traçabilité des interventions support</li>
                                        <li>Maintenance et paramétrage du référentiel organismes</li>
                                    </ul>
                                    <br/>
                                    <u>Accès et disponibilité du Portail</u><br/>
                                    Le Portail est accessible sur le réseau Internet à l’adresse: https://www.careweb.fr à partir d’un terminal équipé des différents OS Windows, Mac OS ou Linux et des navigateurs standards (dernières versions de Explorer/Edge, Firefox et Chrome). L’utilisation à partir d’un smartphone n’est pas supportée. L'Utilisateur reconnaît également avoir vérifié que la configuration informatique qu'il utilise ne contient aucun virus et qu'elle est en parfait état de fonctionnement.
                                    <br/>Une assistance (hotline) est disponible pour les Utilisateurs externes  par téléphone dont le numéro est indiqué dans l’aide en ligne sur le site careweb.fr: <br/><br/>
                                    Cegedim s'engage à faire ses meilleurs efforts pour assurer aux utilisateurs une accessibilité du Site web à tout moment 24 heures sur 24 et 7 jours sur 7, sous réserve noyamment d’opérations ponctuelles de maintenance, d’incidents techniques ou d’une impossibilité de fonctionnement résultant d'un cas de Force Majeure. Toutefois, elle ne pourra être tenue responsable en cas d'indisponibilité du Portail/site web, pour quelque cause que ce soit. L'utilisateur reconnaît disposer de la compétence et des moyens nécessaires pour accéder au site web et l'utiliser. Cegedim ne peut garantir l'exactitude et la complétude des informations diffusées sur son site web ni la permanence de son bon fonctionnement ou sa totale sécurité informatique. Cegedim met en œuvre les moyens pour offrir aux utilisateurs des informations et des outils disponibles et vérifiés, mais ne saurait être tenue responsable des erreurs, d'une absence de disponibilité des informations et/ou de la présence de virus ou autres infections logiques sur son site web. Les informations fournies par Cegedim le sont à titre indicatif. Cegedim ne saurait garantir l'exactitude, la complétude, l'actualité des informations diffusées sur le site web. L'utilisateur reconnaît utiliser les informations et outils disponibles sur le site web sous sa responsabilité exclusive.
                                    
                                    
                                    <br/><br/><u>Obligations des Utilisateurs</u><br/>
                                    L’identifiant de connexion et le mot de passe sont strictement personnels. L’Utilisateur du Portail est personnellement responsable de son identifiant de connexion ainsi que de son mot de passe et par conséquent doit s’assurer de la sécurité et de la confidentialité de ceux-ci. En cas de perte (identifiant, mot de passe), l’Utilisateur peut les récupérer en ligne. Dans l’hypothèse d’une violation par l’Utilisateur de la présente obligation de confidentialité ou en cas de négligence concernant la sécurité de l’identifiant et/ou mot de passe, l’Utilisateur sera seul responsable des dommages qui pourraient en résulter, la responsabilité de Cegedim ne pouvant en aucun cas être engagée de ce fait.
                                    <br/><br/>L’Utilisateur s’interdit d’entraver de quelque manière que ce soit le bon fonctionnement du Portail, notamment en transmettant des éléments susceptibles de contenir un virus ou de nature à endommager ou affecter le Portail, et plus globalement, le système informatique de Cegedim.
                                    <br/><br/>Par ailleurs, l’Utilisateur s’interdit de transmettre via le Portail des informations illicites, à savoir contraire aux bonnes mœurs, à la règlementation en vigueur, notamment : atteinte à la règlementation applicable en matière de propriété intellectuelle, incitation au crime contre l’humanité, incitation à la haine et à la discrimination raciale, pédophilie, diffamation, racolage, prostitution, propos diffamatoires, injurieux, racistes, dénigrements, promotion de tel ou tel service Web diffuseur de telles informations illicites ou des informations de nature à propager le piratage, la contrefaçon, la fraude et les virus informatiques. 
                                    <br/><br/>L’Utilisateur garantit à ce titre Cegedim contre tout recours de tiers relatif à la propriété et/ou au contenu des documents transmis et/ou déposés sur le Portail, ainsi que tout préjudice subi par Cegedim de ce fait.
                            </div>
                        </div>

                        <div class="conditions-group">
                            <div class="conditions-title">5.	DESACTIVATION - RESILIATION</div>
                            <div>
                                <p>
                                En cas de résiliation, l’Utilisateur externe se verra dans l’impossibilité d’accéder aux fonctionnalités du Portail.<br/><br/>
                                Par ailleurs, en cas d’utilisation abusive ou inapproprié du Portail, en cas de manquement aux présentes Conditions générales d’utilisation ou en cas de non paiement du service, Cegedim se réserve le droit de désactiver sans préavis le compte Utilisateur, et par conséquent, de suspendre tout accès Portail et aux services associés. 
                                </p>
                            </div>
                        </div>

                        <div class="conditions-group">
                            <div class="conditions-title">6.	RESPONSABILITE DE CEGEDIM</div>
                            <div>
                                <p>
                                    Au titre de ses engagements de service, Cegedim est soumise à une obligation de moyens et ne saurait être tenue pour responsable de tout dommage indirect et/ou immatériel pouvant résulter d’un dysfonctionnement ou d’une indisponibilité du Portail.
                                    <br/><br/>Cegedim ne pourra être tenue responsable en cas d’utilisation frauduleuse du Portail. L’Utilisateur est seul responsable de l’utilisation qu’il fait du Portail ainsi que tout dommage qui pourrait résulter d’une utilisation non-conforme.
                                    <br/><br/>La responsabilité de Cegedim est quelle qu’en soit la cause limitée à dix (10) euros. Toute action relative à un éventuel dommage est prescrite sous un (1) mois à compter de sa survenance.
                                </p>
                            </div>
                        </div>

                        <div class="conditions-group">
                            <div class="conditions-title">7.	PROPRIETES INTELLECTUELLES</div>
                            <div>
                                <p>
                                    <u>Protection du Portail</u><br/>
                                    La technologie et le contenu du Portail sont la propriété de Cegedim, ses sociétés sœurs filiales ou partenaires.
                                    <br/><br/>L’accès au Portail n’entraine aucun transfert des droits de propriétés intellectuelles, Cegedim restant titulaire de ces droits inhérents au Portail.
                                    <br/><br/>Cegedim concède à l’Utilisateur, sur le ou les applications nécessaires à l’utilisation du Portail, un droit d’utilisation. 
                                    <br/><br/>L’utilisation peut aussi comporter l’impression de tout ou partie des éléments écrits et/ou graphiques et ce, uniquement pour un usage non commercial et sous réserve du respect des droits d’auteur.
                                    
                                    <br/><br/><u>Protection des signes distinctifs</u><br/>
                                    Tous les droits attachés aux marques, dénominations sociales, graphiques, logos, nom de domaine sont la propriété exclusive de Cegedim, ses société sœurs, filiales ou partenaires et sont protégés par le droit de la propriété intellectuelle à travers le monde.
                                    <br/><br/>Toute représentation ou reproduction partielle de ces signes distinctifs, par quelque procédé que ce soit, dans l’autorisation expresse de Cegedim est interdite au sens notamment des articles L. 713-2 et L. 713-3 du code de la propriété intellectuelle.
                                    <br/><br/>Sont expressément interdites toute représentation et/ou reproduction, même partielle du contenu de ce site et/ou de l'un de ses éléments et notamment à but commercial et/ou de distribution, et/ou l'extraction répétée et systématique d'éléments protégés ou non du site causant un préjudice quelconque à Cegedim. Sont notamment visés les éléments protégés par la Loi N° 98-536 du 1er juillet 1998, concernant la protection juridique des bases de données.
                                    
                                    <br/><br/><u>Liens</u><br/>
                                    Les liens éventuellement proposés vers des sites tiers ne sauraient engager la responsabilité de Cegedim quant à leurs conditions d'accès et à leur contenu. Les utilisateurs du Portail ne peuvent établir de lien profond en direction de ce Portail sans l'autorisation préalable et écrite de Cegedim. En aucun cas, cette autorisation ne pourra être qualifiée de convention implicite d'affiliation.
                                    
                                </p>
                            </div>
                        </div>

                        <div class="conditions-group">
                            <div class="conditions-title">8.	INFORMATIQUE ET LIBERTES</div>
                            <div>
                                Chaque Partie doit :
                                <ul>
                                    <li>se conformer à tout moment aux obligations qu’elle a conformément à la réglementation applicable en matière de protection des données à caractère personnel et notamment respecter les règles édictées par le Règlement européen (UE) 2016/679 (« règlement général sur la protection des données » ou « RGPD »). A ce titre, on entend par Données à Caractère Personnel, toutes informations permettant directement ou indirectement d’identifier une personne physique ;</li>
                                    <li>déposer, maintenir et/ou modifier (si nécessaire) tout dépôt, enregistrement ou autre acte nécessaire conformément à la loi applicable ;</li>
                                    <li>informer l’autre Partie en cas de changement substantiel pouvant avoir un impact sur les droits et libertés des personnes physiques.</li>
                                </ul>
                                Cegedim, en sa qualité de Sous-traitant s’engage :
                                <ul>
                                    <li>A utiliser ou exploiter les Données à Caractère Personnel uniquement en vue de la réalisation des prestations objet des présentes CGU et sur instruction documentée du Profesionnel de Santé , étant entendu que Cegedim est réputé agir sur instruction documentée du du Profesionnel de Santé dans le cadre de l’exécution normale desdites prestations.</li>
                                    <li>A tenir à jour la documentation contenant les informations nécessaires pour apporter la preuve du respect des obligations prévues au présent article.
                                            Dans l’hypothèse où le Profesionnel de Santé ne souhaite plus bénéficier des prestations décrites à l’article 5 des présentes CGU, Cegedim s’engage à restituer les Données à Caractère Personnel et/ou les fichiers qu’il aurait pu être amené à conserver ou à créer, sous quelque forme que ce soit, dans le cadre de l’exécution des prestations.</li>
                                    <li>A ce que des mesures techniques et d’organisation appropriées eu égard à l’état de l’art, à la politique de sécurité du groupe, au risque et aux exigences de sécurité soient mises en place en vue de prévenir un traitement non autorisé ou illégal ainsi que les perte, dommage, altération ou destruction des Données à Caractère Personnel, et qu’il fera le nécessaire pour que son personnel qui aura accès aux Données à Caractère Personnel se conforme également aux obligations contenues au présent article.</li>
                                    <li>Dans la mesure du possible, à aider raisonnablement le Profesionnel de Santé à s’acquitter de son obligation de donner suite aux demandes d’exercice des droits des personnes concernées : droits d’accès, de rectification, d’effacement et d’opposition, droit à la limitation du traitement, droit à la portabilité des données, droit de ne pas faire l’objet d’une décision individuelle automatisée (y compris le profilage). Dans cette hypothèse, cette prestation d’accompagnement pourra ou fera l’objet d’une facturation sur devis.
                                            Dans l’hypothèse où les personnes concernées exercent auprès du Sous-traitant des demandes d’exercice de leurs droits, Cegedim s’engage à adresser lesdites demandes par courrier électronique.</li>
                                    <li>à modifier ou supprimer, conformément aux instructions écrites du Profesionnel de Santé, dans la mesure où cela n’est pas en contradiction avec d’autres réglementations qui trouveraient à s’appliquer, notamment celle relative au délai d’archivage des factures électroniques, les Données à Caractère Personnel de ce dernier à la suite, notamment, de l’exercice par une personne concernée de son droit d’accès et de rectification, de sorte que les données soient exactes et à jour, sachant que pour exercer ces droits, chaque Utilisateur peut modifier ses Données à Caractère Personnel dans le menu « Mon compte » du Portail.</li>
                                </ul>
                                
                                En cas de violation de donnée susceptible d’engendrer un risque pour les droits et libertés des personnes physiques dans le cadre des prestations, à notifier au Profesionnel de Santé dans les meilleurs délais après en avoir pris en connaissance, étant entendu que si les données sont rendues incompréhensibles, notamment si elles sont chiffrées, Cegedim ne sera pas tenu à cette notification. Cegedim indiquera dès que possible au Profesionnel de Santé la nature de la violation, la catégorie des traitements touchés et le nombre approximatif de personnes et d’enregistrements concernés. Cegedim s’engage en outre à coopérer avec le Profesionnel de Santé afin de prendre les mesures pour y remédier.
                                <ul><li>
                                A communiquer au Profesionnel de Santé le nom et les coordonnées de son Délégué à la Protection des Données, s’il en a désigné un conformément à l’article 37 du Règlement RGPD.
                                </li></ul>

                                Le Profesionnel de Santé, en sa qualité de Responsable de traitement s’engage :
                                <ul>
                                    <li>A respecter les dispositions légales relatives aux traitements des Données à Caractère Personnel. Notamment, le Profesionnel de Santé s’engage, au moment de la collecte des Données à Caractère Personnel, à fournir l’information aux personnes concernées et garantit que cela a bien été réalisé. </li>
                                    <li>A documenter par écrit toute instruction concernant le traitement des données par Cegedim</li>
                                    <li>A veiller, au préalable et pendant toute la durée du traitement, au respect des obligations prévues par le Règlement RGPD de la part du Sous-traitant</li>
                                    <li>A superviser le Traitement</li>
                                </ul>

                                Par ailleurs, les Données à Caractère Personnel pourront être communiquées aux prestataires Sous-traitants et partenaires de Cegedim en vue de proposer des services répondant aux besoins de l’Utilisateur.
                                <br/><br/>

                                <u>Description du Traitement</u><br/>
                                Cegedim en tant que Sous-traitant est autorisé à traiter pour le compte du Professionnel de Santé, Responsable de traitement, les Données à Caractère Personnel nécessaires à l’exécution des Prestations que le Responsable de traitement lui confie en vertu des présentes CGU.
                                <br/><br/>
                                La nature des opérations réalisées sur les Données est : <br/>
                                La collecte, l'enregistrement, l'organisation, la structuration, la conservation, l'adaptation ou la modification, l'extraction, la consultation, l'utilisation, la communication par transmission, la diffusion ou toute autre forme de mise à disposition, le rapprochement, la limitation, l'effacement ou la destruction ou toute autre opération nécessaire en vue de la réalisation de la prestation contractuelle
                                <br/><br/>
                                Les Finalités du Traitement sont l’exécution des prestations par le Sous-traitant pour le compte du Responsable de Traitement, lesdites prestations étant décrites à l’article 5 des présentes CGU.
                                <br/><br/>
                                Les Données à Caractère Personnel traitées sont : 
                                <ul>
                                    <li>Les données relatives à l’identification des personnes</li>
                                    <li>Plus globalement les données nécessaires à la réalisation de la prestation contractuelle</li>
                                </ul>
                            
                                Les catégories de personnes susceptibles d’être concernées sont : les salariés, préposés du Professionnel de Santé, et les clients patients du Professionnel de Santé.
                                <br/><br/>

                                <u>Sous-Traitance</u><br/>
                                Dans la mesure où les prestations rendues par Cegedim ont le caractère de prestations mutualisées, le Professionnel de Santé donne à Cegedim une autorisation générale de recourir à la sous-traitance. 
                                <br/><br/>
                                Les Sous-traitants pour lesquels a recours Cegedim dans le cadre des prestations sont à date des présentes CGU :
                                <ul><li>Cegedim.Cloud (Hébergement/Archivage) : hébergeur certifié en conformité avec la réglementation en vigueur, RCS 790 173 066, 137 rue d’Aguesseau 92100 Boulogne Billancourt</li></ul>
                                
                                En cas de changement ou évolutions, Cegedim actualisera cette liste au travers d’une mise à jour des présentes CGU.
                                <br/><br/>
                                Par ailleurs, Cegedim est expressément autorisé à recourir à de la sous-traitance à l’international dans la mesure où ce transfert est entouré des garanties appropriées au sens de l’article 46 du RGPD.
                                <br/><br/>

                                <u>Catégories des Données traitées</u><br/>
                                La liste des Données à Caractère Personnel susceptibles d’être traitées sont les suivantes, dans le respect des normes en vigueur applicables<br/><br/>

                                <b>De l’OCT aux organismes :</b>
                                <br/>Norme B2 (idem PS – OCT)
                                <br/>Norme 615M (B2 appauvrie, sans cryptage)
                                <br/>Norme DRE (idem PS – OCT)
                                <br/><b>Des organismes à l’OCT :</b>
                                <br/>Norme RM
                                <br/>Norme NOEMIE PS, diverses versions (900, 930, 531, 576, 580,…)
                                <br/><b>De l’OCT au PS :</b>
                                <br/>Norme NOEMIE (idem Orga – OCT)
                                <br/>Norme RPH (versions RPH et RPH2), spécifique aux pharmacies
                                <br/>Norme ART
                                <br/><br/>

                                <ul>
                                    <li>Nom (Professionnel de Santépatient)</li>
                                    <li>Prénom (Professionnel de Santé, patient)</li>
                                    <li>Adresse (Etablissement du Professionnel de Santé)</li>
                                    <li>Coordonnées téléphoniques (Etablissement du Professionnel de Santé)</li>
                                    <li>Adresse email</li>
                                    <li>RIB, IBAN Fournisseur</li>
                                    <li>Matricules (numéro de sécurité sociale patient, identifiant Professionnel de Santé)</li>
                                    <li>NIR (numéro de sécurité sociale du patient)</li>
                                    <li>Numéro de facturation Sesam-Vitale (=identifiant Professionnel de Santé)</li>
                                    <li>RPPS</li>
                                    <li>Numéro du prescripteur</li>
                                </ul>

                                Dans le cadre de votre utilisation de notre service, nous sommes également susceptibles de collecter auprès de vous des données vous concernant : nom, prénom, adresse, email, numéro de téléphone, fonction. Ces informations recueillies notamment par le biais de formulaires sont enregistrées dans un fichier informatisé par Cegedim afin de nous permettre de vous adresser des contenus adaptés à votre activité et/ou gérer la relation que nous avons avec vous (principalement au niveau commercial, technique et conformité) et sont susceptibles d’être utilisées pour les finalités suivantes :
                                <ul>
                                    <li>de mise en conformité légale et règlementaire ( anticorruption, éthique, gestion de la fraude…)</li>
                                    <li>d’information sur les bonnes pratiques</li>
                                    <li>de réalisation d’opérations commerciales, d’information, de formation ou de communication (notamment et principalement sur les solutions et services que nous proposons)</li>
                                    <li>de réalisation d’enquêtes marketing, d’études</li>
                                    <li>d’organisation de formations, de séminaires et/ou de réalisation d’opérations de mécénat</li>
                                </ul>

                                Ces données sont conservées conformément à la législation en vigueur et sont destinées notamment aux services commercial, marketing, conformité etc... Ces données sont susceptibles d’être transmises à nos sociétés affiliées, le cas échéant à l’international dans le respect de la règlementation sur la protection des données personnelles. 
                                Conformément à la loi « informatique et libertés » vous pouvez exercer vos droits d’accès aux données qui vous concerne en adressant une demande par mail à l’adresse [jacques.chaussade@cegedim.fr] 
                                <br/><br/>

                                <u>Mesures de sécurité</u>
                                Les applicatifs qui permettent de délivrer les prestations confiées sont basés sur des conceptions sécurisées incluant une gestion des droits d'accès.  Les accès en tout ou partie aux fonctionnalités sont limités aux personnes habilitées (login/mot de passe)
                                <br/><br/>L'hébergement des données est réalisé par Cegedim.cloud, hébergeur certifié en conformité avec la réglementation en vigueur, RCS 790 173 066, 137 rue d’Aguesseau 92100 Boulogne Billancourt. Lhébergement est réalisé exclusivement en France métropolitaine. Les activités d’hébergement et d’infogérance de Cegedim.cloud sont certifiées ISO 27001 mais également ISO 20000, HDS, ISO 27017 et ISO 27018 et font donc l’objet d’audits de sécurité réguliers. 
                                <br/><br/>

                                <u>Conservation</u>
                                Les Données à Caractère Personnel des Utilisateurs ne sont conservées que pendant la durée nécessaire aux finalités poursuivies telles qu'énoncées aux présentes et conformément aux lois et règlements en vigueur. Les Données à Caractère Personnel de l'Utilisateur sont détruites lorsque les durées de conservations expirent. Toutefois, les Données à Caractère Personnel pourront être archivées au-delà des durées prévues pour des besoins tenant à la recherche et des besoins de statistique ou pour toutes obligations légales (infractions pénales, la mise à disposition des données à caractère personnel à l'autorité judiciaire).

                            </div>
                        </div>

                        <div class="conditions-group">
                            <div class="conditions-title">9.	FORCE MAJEURE</div>
                            <div>
                                <p>
                                    La responsabilité de chacune des Parties ne pourra être recherchée si l’exécution des présentes Conditions Générales d’Utilisation est retardée ou empêchée en raison d’un cas de force majeure ou assimilé, du fait de l’autre Partie, d’un tiers ou de tous évènements imprévisibles et irrésistibles, tels que conflits sociaux, pandémie, épidémie, intervention des autorités civiles ou militaires, guerres ou hostilités déclarées ou non déclarées, actes terroristes, émeutes, catastrophes naturelles, incendies, dégâts des eaux, mauvais fonctionnement ou interruption du réseau de télécommunications ou du réseau électrique.  
                                    <br/><br/>Dans tous les cas, la Partie empêchée devra faire tout ce qui est en son pouvoir pour limiter la durée et les effets de la force majeure.
                                    <br/><br/>En cas de prolongation de l’empêchement total au-delà d’une période de quatre (4) mois, les Parties conviennent de se réunir en vue d’envisager de bonne foi la poursuite des présentes Conditions Générales d’Utilisation dans des conditions adaptées
                                </p>
                            </div>
                        </div>

                        <div class="conditions-group">
                            <div class="conditions-title">10.	LOYAUTE</div>
                            <div>
                                <p>
                                    Le Professionnel de Santé s'engage à un devoir de loyauté et de réserve minimum à l'égard de Cegedim en s'interdisant notamment de dénigrer les produits et services de Cegedim ou du groupe auquel elle appartient, à s'associer publiquement à des actions tierces (notamment dans des revues ou forums ou autres moyens de communication) pouvant être de nature à laisser supposer de par leurs caractéristiques ou leur ampleur notamment à une critique de Cegedim ou du groupe auquel elle appartient quant à ses produits et services. Le non respect de cette clause pourrait avoir pour conséquence, sans préjudice de dommages et intérêts, l’arrêt définitif immédiat de l’utilisation du Portail CAREWeb®.
                                </p>
                            </div>
                        </div>

                        <div class="conditions-group">
                            <div class="conditions-title">11.	SECURITE</div>
                            <div>
                                <p>
                                    Cegedim procède régulièrement à des vérifications de sécurité et intégrité et à des mises à jour évolutives ou correctives afin de prévenir ou de corriger d’éventuelles intrusions, pertes de données et fichiers ou atteintes à la confidentialité des données et fichiers. A ce titre, Cegedim pourra être amenée à mettre en œuvre toute mesure utile afin de sécuriser le Portail et les données, et notamment à supprimer tout logiciel susceptible de nuire à la sécurité, l’efficacité, l’intégrité ou la confidentialité des données et fichiers traités, dans le respect de la confidentialité et de l’intégrité des données et fichiers. Dans le cas où l’utilisateur souhaiterait rendre un logiciel tiers interopérable avec le Portail, il s’engage à en faire la demande expresse auprès de Cegedim. Aucune autre modalité d’interfaçage n’est acceptée conformément aux dispositions légales applicables (art. L. 122-6-1 du code de la propriété intellectuelle).
                                </p>
                            </div>
                        </div>
                        
                        <div class="conditions-group">
                            <div class="conditions-title">12.	ANTICORRUPTION</div>
                            <div>
                                <p>
                                    Chacune des parties s’engage à se conformer à la réglementation anti corruption en vigueur. En cas de non-conformité, Cegedim pourr suspendre immédiatement l’accès au Portail.
                                </p>
                            </div>
                        </div>

                        <div class="conditions-group">
                            <div class="conditions-title">13.	NULLITE PARTIELLE – NON RESOLUTION – INCESSIBILITE</div>
                            <div>
                                <p>
                                    Si l’une ou plusieurs stipulations des présentes conditions générales sont tenues pour non valides ou déclarées comme tel en application d’une loi, d’un règlement ou d’une décision définitive d’une juridiction compétente, les autres stipulations garderont toute leur force et toute leur portée. De convention expresse, la clause nulle ou non valide concernée sera alors remplacée par une clause qui se rapprochera le plus, dans son contenu, de la clause initialement arrêtée.
                                    <br/><br/>Le fait pour Cegedim de ne pas se prévaloir pendant un certain laps de temps de l’une quelconque des dispositions prévues aux présentes ne pourra être interprété comme une renonciation de celle-ci à s’en prévaloir à l’avenir.
                                    <br/><br/>L’Utilisateur ne pourra pas céder ou déléguer tout ou partie de ses droits ou obligations issus des présentes.
                                </p>
                            </div>
                        </div>

                        <div class="conditions-group">
                            <div class="conditions-title">14.	LOI APPLICABLE ET JURIDICTION COMPETENTE</div>
                            <div>
                                <p>
                                    La loi applicable est la loi française. Tout différend relatif à la conclusion, à la validation, l’exécution ou à l’interprétation des présentes conditions générales d’utilisation sera soumis aux tribunaux de Paris.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    </div>
</div> 
            