<div class="full-careweb-container">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" [destroyOnHide]="false" class="nav-tabs tab-container"
        (navChange)="onTabChange($event)">
        <li [ngbNavItem]="1" id="comptes" title="Suivi des rapprochements">
            <button ngbNavLink>Suivi du rapprochement</button>
            <ng-template ngbNavContent>
                <careweb-suivi-rapp></careweb-suivi-rapp>
            </ng-template>
        </li>
        <li [ngbNavItem]="2" id="logiciel" title="Performance du rapprochement par organisme">
            <button ngbNavLink>Performance du rapprochement par organisme</button>
            <ng-template ngbNavContent>
                <careweb-rapp-dashboard-organism></careweb-rapp-dashboard-organism>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>