import { AfterContentInit, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'careweb-pagination-size',
	template: `
        <ng-select
            [(ngModel)]="model"
            [searchable]="false"
            [clearable]="false"
            (change)="setPaginationSize($event)"
            style="min-width: 100px"
            name="pagination"
        >
            <ng-option *ngFor="let item of pageSizes" [value]="item">{{ item }} lignes</ng-option>
        </ng-select>
    `,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PaginationSizeComponent),
			multi: true
		}
	]
})
export class PaginationSizeComponent implements AfterContentInit, ControlValueAccessor {
	_paginationSize: number;
	@Input() pageSizes: number[];
	@Output() paginationSizeChange = new EventEmitter<number>();
	pageSizesItems: number[];

	private onSelectTouched: () => {};
	private _onSelectChange: (_: any) => {};

	constructor() {}

	ngAfterContentInit() {
		this.pageSizesItems = this.pageSizes;
	}

	setPaginationSize(n: number) {
		this.paginationSizeChange.emit(n);
	}

	get model(): number {
		return this._paginationSize;
	}

	set model(value) {
		if (value !== this._paginationSize) {
			this._paginationSize = value;
			this._onSelectChange(value);
		}
		this.onSelectTouched();
	}

	writeValue(value) {
		this._paginationSize = value;
	}

	registerOnChange(fn: any) {
		this._onSelectChange = fn;
	}

	registerOnTouched(fn: any) {
		this.onSelectTouched = fn;
	}
}
