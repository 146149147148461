import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { RSPService } from '../rsp.service';
import { RspDataRaw } from '../../../../data/rspDataRaw';
import { AuthService } from '../../../../core/services/auth.service';
import { PsService } from '../../../ps/ps.service';
import { PsDataRaw } from '../../../../data/psDataRaw';
import { FseService } from '../../fse/fse.service';
import { FseDataRaw } from '../../../../data/fseDataRaw';
import { ScrollTopService } from '../../../../core/utilities/scroll-top.service';
import { Location } from '@angular/common'
import {isNullOrUndefined} from "util";
import {RouteHistoryService} from "../../../../shared/route-history.service";
import {CustomRouteHistoryService} from "../../../../shared/custom-route-history.service";

@Component({
	selector: 'careweb-app-fiche-rsp',
	templateUrl: './fiche-rsp.component.html',
	styleUrls: [ './fiche-rsp.component.scss' ],
})
export class FicheRSPComponent implements OnInit {
	rsp: RspDataRaw;
	currentPs: PsDataRaw;
	currentFse: FseDataRaw;
	idLot: String;
	montantTotalDemande;
	montantTotalPaye;
	isRSPDetailsCollapsed = false;
	showButtonDetailFse = false;
	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private rspService: RSPService,
		private psService: PsService,
		private authSvc: AuthService,
		private fseService: FseService,
		private customRouteHistory: CustomRouteHistoryService,
		private location: Location,
		private scrollTopService: ScrollTopService,
		private routeHistory: RouteHistoryService
	) {
		// Scroll to top
		this.scrollTopService.setScrollTop();

		this.rsp = new RspDataRaw();
		this.currentPs = new PsDataRaw();
		this.currentFse = new FseDataRaw();
	}

	ngOnInit() {
		const idRsp = this.activatedRoute.snapshot.params.id;
		if(!parseInt(idRsp)){
			this.router.navigate(['/404'])
		}
		this.getRspById(idRsp);
	}

	goToRSP() {
		if(isNullOrUndefined(this.routeHistory.getPreviousUrl()) || this.customRouteHistory.getHistory().length==0){
			this.router.navigate(['/rsp']);
		}else {
			this.location.back();
		}
	}

	goToDetailLot() {
		this.router.navigate([ '/lot-details', this.idLot ]);
	}

	getIdLot() {
		if (this.rsp.idFacture) {
			this.fseService.getFseById(this.rsp.idFacture).subscribe((data) => {
				if (data && data.lot) {
					this.idLot = data.lot.idLot;
					this.showButtonDetailFse = true;
				}
			});
		}
	}

	goToDetailFSE() {
		this.router.navigate([ '/fse-details', this.rsp.idFacture ]);
	}

	getRspById(idRsp) {
		const ctx = this;
		return ctx.rspService.getRspById(idRsp).subscribe((res) => {
			this.rsp = res;
			ctx.montantTotalDemande = ctx.getMontantTotal(ctx.rsp.montantFactureRo, ctx.rsp.montantFactureRc,false);
			ctx.montantTotalPaye = ctx.getMontantTotal(ctx.rsp.montantRo, ctx.rsp.montantRc,true);
			if (res.numeroAdeli) {
				ctx.psService
					.getPsByNumAdeli({
						numAdeli: this.rsp.numeroAdeli
					})
					.subscribe((data) => {
						this.currentPs = data[0];
						this.rsp.raisonSocialEtablissement =
							res.raisonSocialEtablissement === undefined ? null : res.raisonSocialEtablissement;
					});
				this.getIdLot();
			}
		},
		err=>{
			if(err.status===400 || err.message.includes('Rsp is not found')){
				this.router.navigate(['/404'])
			}
		});
	}

	isAuthorized() {
		return this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
	}

	getMontantTotal(montantRo, montantRc,paye) {
		let cumulMontantDemande = '';
		if (montantRo != null && montantRc === null) {
			cumulMontantDemande = (parseFloat(montantRo) / 100).toFixed(2);
		} else if (montantRo === null && montantRc != null) {
			cumulMontantDemande = (parseFloat(montantRc) / 100).toFixed(2);
		} else if (montantRo === null && montantRc === null) {
			cumulMontantDemande = '0,00';
		} else {
			cumulMontantDemande = (parseFloat(montantRo) / 100 + parseFloat(montantRc) / 100).toFixed(2);
		}
		if(paye){
			if (this.rsp.signe === 0) {
				return (parseFloat(cumulMontantDemande) * -1) + ' €';
			}
		}
		return cumulMontantDemande + ' €';
	}

	formatRoRc(rsp, key) {
        let total = rsp.signe === 0 ? rsp.cumulMontantFacture * -1 : rsp.cumulMontantFacture
        let ro = rsp.montantRo ? parseFloat(rsp.montantRo) / 100 : 0
        let rc = rsp.montantRc ? parseFloat(rsp.montantRc) / 100 : 0
        switch (key) {
            case 'montantRc': { return ro + rc * -1 === total || ro * -1 + rc * -1 === total ? rc * -1 : rc; break; }
            case 'montantRo': { return rc + ro * -1 === total || ro * -1 + rc * -1 === total ? ro * -1 : ro; break; }
            case 'montantTotalPaye': { return total?total:this.montantTotalPaye; break; }
        }
    }

	formatWithSigne(montant, signe) {
		if (montant) {
			if (signe === 0) {
				if (parseFloat(montant) / 100 !== 0) {
					return (parseFloat(montant) / 100 * -1).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/\./g, ',') + ' €';
				}
				return '0,00 €';
			}
			else {
				return (parseFloat(montant) / 100).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/\./g, ',') + ' €';
			}
		}
		else {
			return '0,00 €';
		}
	}
	formatCurrency(value) {
		return value?.replace('.', ',');
	}
}
