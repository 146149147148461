import { Directive, HostListener, ElementRef, Output, EventEmitter } from '@angular/core';

@Directive({
	selector: '[carewebOnlyPositiveDouble]'
})
export class OnlyPostiveDoubleDirective {
	@Output() ngModelChange: EventEmitter<any> = new EventEmitter();
	public matched = '';

	constructor(private el: ElementRef) {}

	@HostListener('input', [ '$event' ])
	onInputChange(event) {
		const initalValue = this.el.nativeElement.value;
		if (initalValue !== '') {
			const isMatch = initalValue.match(/^\d+\.?\d*$/);
			if (isMatch) {
				this.matched = initalValue.match(/^\d+\.?\d*$/)[0];
			} else if (!isMatch) {
				this.el.nativeElement.value = this.matched;
				event.stopPropagation();
			}
		} else {
			this.matched = initalValue;
		}
		this.ngModelChange.emit(this.matched);
	}
}
