<div class="modal-header pb-1">
    <h3 class="modal-title">Détails de la FSE - N° {{facture.numFacture}}</h3>
    <button class="close" name="btn_display_filter" data-dismiss="modal" type="button" title="Fermer"
        (click)="closeModal()"><span aria-hidden="true">×</span></button>
</div>
<div class="modal-body pb-0">
    <!-- NAV TABS -->
   <!--  <ngb-tabset [justify]="currentJustify"> -->
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
        <li [ngbNavItem]="1"title="Informations">
            		<button ngbNavLink>Informations</button>
		        <ng-template ngbNavContent>
                <!-- ONGLET FSE -->
                <div id="ongletFSE" class="tab-pane fade show active">
                    <div class="form-block mt-3">
                        <fieldset class="container-fluid">
                            <div class="row">
                                <div class="col-sm-3 col-form-label">Numéro de PS</div>
                                <div class="col-sm-3 form-group">
                                    <input type="text" class="form-control" readonly value="{{facture.numPs}}" />
                                </div>
                                <div class="col-sm-3 col-form-label">Numéro INSEE</div>
                                <div class="col-sm-3 form-group">
                                    <input type="text" class="form-control" readonly value="{{facture.numAssure}}" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-3 col-form-label">Nom du titulaire</div>
                                <div class="col-sm-9 form-group">
                                    <input type="text" class="form-control" readonly value="{{ps.nom}}" />
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-3 col-form-label">Etablissement</div>
                                <div class="col-sm-9 form-group">
                                    <input type="text" class="form-control" readonly value="{{lot.raisonSociale}}" />
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-3 col-form-label">ARL SCOR</div>
                                <div class="col-sm-3 form-group">
                                    <input type="text" class="form-control" readonly value="{{lot.signeArl | yesNoPipe: 'typeArl'}}" />
                                </div>
                                <div class="col-sm-3 col-form-label">Date de naissance</div>
                                <div class="col-sm-3 form-group">
                                    <input type="text" class="form-control" readonly
                                        value="{{facture.dateNaissance | date:'shortDate'}}" />
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-3 col-form-label">Date réception lot</div>
                                <div class="col-sm-3 form-group">
                                    <input type="text" class="form-control" readonly value="{{facture.dateReceptionPartition | date:'shortDate'}}" />
                                </div>
                                <div class="col-sm-3 col-form-label">Dernière transmission</div>
                                <div class="col-sm-3 form-group">
                                    <input type="text" class="form-control" readonly value="" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-3 col-form-label">N° ADELI médecin traitant</div>
                                <div class="col-sm-3 form-group">
                                    <input type="text" class="form-control" readonly value="{{ ps.numeroAdeli }}" />
                                </div>
                                <div class="col-sm-3 col-form-label">Tiers-payant</div>
                                <div class="col-sm-3 form-group">
                                    <input type="text" class="form-control" readonly
                                        value="{{lot.tiersPayant | yesNoPipe: 'tiersPayant' }}" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-3 col-form-label">Organisme AMO</div>
                                <div class="col-sm-3 form-group">
                                    <input type="text" class="form-control" readonly value="{{amoData.libelleCourt}}" />
                                </div>
                                <div class="col-sm-3 col-form-label">Montant AMO</div>
                                <div class="col-sm-3 form-group">
                                    <input type="text" class="form-control" readonly value="{{facture.totalRemboursableAmo | customCurrency}}" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-3 col-form-label">Organisme AMC</div>
                                <div class="col-sm-3 form-group">
                                    <input type="text" class="form-control" readonly value="{{amcData.libelleCourt}}" />
                                </div>
                                <div class="col-sm-3 col-form-label">Montant AMC</div>
                                <div class="col-sm-3 form-group">
                                    <input type="text" class="form-control" readonly value="{{facture.totalRemboursableAmc | customCurrency}}" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-3 col-form-label">RSP disponible</div>
                                <div class="col-sm-3 form-group">
                                    <input type="text" class="form-control" readonly value="{{facture.nbRspRO | yesNoPipe:'nbRsp'}}" />
                                </div>
                                <div class="col-sm-3 col-form-label">Part AMC non prise en compte</div>
                                <div class="col-sm-3 form-group">
                                    <input type="text" class="form-control" readonly value="{{facture.partRcAPrendreEnCompte | yesNoPipe:'partRc'}}" />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="2"title="Assurance maladie obligatoire">
            		<button ngbNavLink>Assurance maladie obligatoire</button>
		<ng-template ngbNavContent>
                <!-- ONGLET RO -->
                <div class="form-block mt-3">
                    <fieldset class="container-fluid">
                        <div class="row">
                            <div class="col-sm-3 col-form-label">Sigle</div>
                            <div class="col-sm-3 form-group">
                                <input type="text" class="form-control" readonly value="{{amoData.libelleCourt}}" />
                            </div>
                            <div class="col-sm-3 col-form-label">Organisme actif</div>
                            <div class="col-sm-3 form-group">
                                <input type="text" class="form-control" readonly value="{{amoData.actif | yesNoPipe:'actif'}}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-3 col-form-label">Libellé</div>
                            <div class="col-sm-9 form-group">
                                <input type="text" class="form-control" readonly value="{{amoData.libelleLong}}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-3 col-form-label">Adresse 1</div>
                            <div class="col-sm-9 form-group">
                                <input type="text" class="form-control" readonly value="{{amoData.adresse1}}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-3 col-form-label">Adresse 2</div>
                            <div class="col-sm-9 form-group">
                                <input type="text" class="form-control" readonly value="{{amoData.adresse2}}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-3 col-form-label">Code Postal</div>
                            <div class="col-sm-3 form-group">
                                <input type="text" class="form-control" readonly value="{{amoData.codePostal}}" />
                            </div>
                            <div class="col-sm-3 col-form-label">Ville</div>
                            <div class="col-sm-3 form-group">
                                <input type="text" class="form-control" readonly value="{{amoData.ville}}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-3 col-form-label">Téléphone</div>
                            <div class="col-sm-3 form-group">
                                <input type="text" class="form-control" readonly value="{{amoData.telephone}}" />
                            </div>
                            <div class="col-sm-3 col-form-label">Fax</div>
                            <div class="col-sm-3 form-group">
                                <input type="text" class="form-control" readonly value="{{amoData.fax}}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-3 col-form-label">Commentaire</div>
                            <div class="col-sm-9 form-group">
                                <input type="text" class="form-control" readonly value="{{amoData.commentaires}}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-3 col-form-label">Tous centres</div>
                            <div class="col-sm-3 form-group">
                                <input type="text" class="form-control" readonly value="{{amoData.tousCentres | yesNoPipe:'actif'}}" />
                            </div>
                            <div class="col-sm-3 col-form-label">Code grand Régime</div>
                            <div class="col-sm-3 form-group">
                                <input type="text" class="form-control" readonly value="{{amoData.codeGr}}" />
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-sm-3 col-form-label">Compression</div>
                            <div class="col-sm-3 form-group">
                                <input type="text" class="form-control" readonly value="{{amoData.envoisCompresses | yesNoPipe:'actif'}}" />
                            </div>
                            <div class="col-sm-3 col-form-label">Caisse C.A.S.</div>
                            <div class="col-sm-3 form-group">
                                <input type="text" class="form-control" readonly value="{{amoData.codeCaisseCas}}" />
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-sm-3 col-form-label">Centre C.A.S.</div>
                            <div class="col-sm-3 form-group">
                                <input type="text" class="form-control" readonly value="{{amoData.codeCentreCas}}" />
                            </div>
                            <div class="col-sm-3 col-form-label">Type 0 Centre info.</div>
                            <div class="col-sm-3 form-group">
                                <input type="text" class="form-control" readonly value="{{amoData.codeCentreInfoType0}}" />
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-sm-3 col-form-label">Type 1</div>
                            <div class="col-sm-3 form-group">
                                <input type="text" class="form-control" readonly value="{{amoData.codeOrgType1}}" />
                            </div>
                            <div class="col-sm-3 col-form-label">Code organisme type 0 générique</div>
                            <div class="col-sm-3 form-group">
                                <input type="text" class="form-control" readonly value="{{amoData.codeCentreInfoType0Gen | yesNoPipe:'actif'}}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-3 col-form-label">Code organisme type 1 générique</div>
                            <div class="col-sm-3 form-group">
                                <input type="text" class="form-control" readonly value="{{amoData.codeOrgType1Gen | yesNoPipe:'actif'}}" />
                            </div>
                            <div class="col-sm-6"></div>
                        </div>
                    </fieldset>
                </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="3"title="Assurance maladie complémentaire">
            		<button ngbNavLink>Assurance maladie complémentaire</button>
		<ng-template ngbNavContent>
                <!-- ONGLET RC -->
                <div class="form-block mt-3">
                    <fieldset class="container-fluid">
                        <div class="row">
                            <div class="col-sm-3 col-form-label">Sigle</div>
                            <div class="col-sm-3 form-group">
                                <input type="text" class="form-control" readonly value="{{amcData.libelleCourt}}" />
                            </div>
                            <div class="col-sm-3 col-form-label">Organisme actif</div>
                            <div class="col-sm-3 form-group">
                                <input type="text" class="form-control" readonly value="{{amcData.actif | yesNoPipe:'actif'}}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-3 col-form-label">Libellé</div>
                            <div class="col-sm-9 form-group">
                                <input type="text" class="form-control" readonly value="{{amcData.libelleLong}}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-3 col-form-label">Adresse 1</div>
                            <div class="col-sm-9 form-group">
                                <input type="text" class="form-control" readonly value="{{amcData.adresse1}}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-3 col-form-label">Adresse 2</div>
                            <div class="col-sm-9 form-group">
                                <input type="text" class="form-control" readonly value="{{amcData.adresse2}}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-3 col-form-label">Code Postal</div>
                            <div class="col-sm-3 form-group">
                                <input type="text" class="form-control" readonly value="{{amcData.codePostal}}" />
                            </div>
                            <div class="col-sm-3 col-form-label">Ville</div>
                            <div class="col-sm-3 form-group">
                                <input type="text" class="form-control" readonly value="{{amcData.ville}}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-3 col-form-label">Téléphone</div>
                            <div class="col-sm-3 form-group">
                                <input type="text" class="form-control" readonly value="{{amcData.telephone}}" />
                            </div>
                            <div class="col-sm-3 col-form-label">Fax</div>
                            <div class="col-sm-3 form-group">
                                <input type="text" class="form-control" readonly value="{{amcData.fax}}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-3 col-form-label">Commentaire</div>
                            <div class="col-sm-9 form-group">
                                <input type="text" class="form-control" readonly value="{{amcData.commentaires}}" />
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-sm-3 col-form-label">Code officiel</div>
                            <div class="col-sm-3 form-group">
                                <input type="text" class="form-control" readonly value="{{amcData.codeOfficiel}}" />
                            </div>
                            <div class="col-sm-3 col-form-label">Code entrée</div>
                            <div class="col-sm-3 form-group">
                                <input type="text" class="form-control" readonly value="" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-3 col-form-label">Aide pérenne</div>
                            <div class="col-sm-3 form-group">
                                <input type="text" class="form-control" readonly value="{{amcData.aidePerenne | yesNoPipe: 'actif'}}" />
                            </div>
                            <div class="col-sm-3 col-form-label">AMC seul</div>
                            <div class="col-sm-3 form-group">
                                <input type="text" class="form-control" readonly value="{{amcData.rcSeul | yesNoPipe:'actif'}}" />
                            </div>
                        </div>
                    </fieldset>
                </div>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div> 
</div>
<div class="modal-footer">

</div>