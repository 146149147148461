import { Component, AfterViewInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { StorageService } from './../../../shared/storage-service';

@Component({
	selector: 'org-state-preview',
	template: `
		<i
			[hidden]="hide"
			[ngClass]="iconClass"
            container="body"
			(click)="previewOrgState()"
			[tooltip]="tooltip"
            containerClass="tooltip-grid"
			(contextmenu)="onRightClick($event)"
		></i>
	`,
	styles: [ '.fa-disabled {opacity: 0.6;cursor: not-allowed;}' ]
})
export class OrgStatePreviewComponent implements ICellRendererAngularComp, AfterViewInit {
	iconClass: string;
	tooltip: string;
	private params: any;
	hide = false;

	constructor(private router: Router, private storageService: StorageService) {}

	agInit(params: any): void {
		this.params = params;
	}

	ngAfterViewInit() {
		var cellParams = this.params;
		if (cellParams.data) {
			if (
				cellParams.colDef.field &&
				cellParams.colDef.field == 'options' &&
				cellParams.redirect == 'orgStateDetails'
			) {
				this.iconClass = cellParams.iconClass;
				this.tooltip = "Consulter détails de l'AMC/OTP";
			} else if (
				cellParams.colDef.field &&
				cellParams.colDef.field == 'options' &&
				cellParams.action == 'orgStateEdit'
			) {
				this.iconClass = cellParams.iconClass;
				this.tooltip = "Modifier l'AMC/OTP";
			} else if (
				cellParams.colDef.field &&
				cellParams.colDef.field == 'options' &&
				cellParams.action == 'paramSelEdit'
			) {
				this.iconClass = cellParams.iconClass;
				this.tooltip = 'Modifier le paramétrage SEL';
				if (this.params.data.initSource !== 'CAREWEB') {
					this.iconClass += ' fa-disabled';
				}
			} else if (
				cellParams.colDef.field &&
				cellParams.colDef.field == 'options' &&
				cellParams.action == 'paramSelDelete'
			) {
				this.iconClass = cellParams.iconClass;
				this.tooltip = 'Supprimer le paramétrage SEL';
				if (this.params.data.initSource !== 'CAREWEB') {
					this.iconClass += ' fa-disabled';
				}
			} else if (cellParams.colDef.field === 'warning') {
				if (
					!isNullOrUndefined(this.params.data) &&
					isNullOrUndefined(this.params.data.idbServiceAvailable) &&
					isNullOrUndefined(this.params.data.interAMCServiceAvailable)
				) {
					this.hide = false;
					this.iconClass = cellParams.iconClass;
					this.tooltip = 'Demande de pratique en cours';
				} else {
					this.hide = true;
				}
			} else if (cellParams.colDef.field && cellParams.colDef.field === 'subscribedPlatform') {
				cellParams.value === true ? (this.iconClass = 'fa fa-check') : (this.iconClass = 'fa fa-close');
			} else if (cellParams.colDef.field && cellParams.colDef.field === 'interAmc') {
				cellParams.value === true ? (this.iconClass = 'fa fa-check') : (this.iconClass = 'fa fa-close');
			} else if (cellParams.colDef.field && cellParams.colDef.field === 'idbServiceAvailable') {
				cellParams.value === true ? (this.iconClass = 'fa fa-check') : cellParams.value === false ? (this.iconClass = 'fa fa-close'): '';
			} else if (cellParams.colDef.field && cellParams.colDef.field === 'interAMCServiceAvailable') {
				cellParams.value === true ? (this.iconClass = 'fa fa-check') : cellParams.value === false ? (this.iconClass = 'fa fa-close'): '';
			}
		}
	}

	refresh(): boolean {
		return false;
	}

	previewOrgState(): void {
		if (this.params.redirect == 'orgStateDetails') {
			this.storageService.set('previouslyClickedOrgNumber', this.params.node.data.organizationNumber, true);
			this.router.navigate([ '/org-state-details', this.params.data.label, this.params.data.organizationNumber ]);
		}
	}

	onRightClick(event: MouseEvent): void {		
		if (this.params.redirect === 'orgStateDetails') {
			event.preventDefault();
			this.storageService.set('previouslyClickedOrgNumber', this.params.node.data.organizationNumber, true);
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/careweb-client/org-state-details',  this.params.data.label,this.params.data.organizationNumber ])
			);
			window.open(url, '_blank');
		}
	}
}
