import {HttpClient, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONF } from '../../../core/constants';
import { TypeZeroDataRaw } from '../../../data/typeZeroDataRaw';
import { FilterService } from '../../../core/services/filter-service';
import { NumeroEmetteurDataRaw } from '../../../data/numeroEmetteurDataRaw';
import {CustomSortDataRow} from "../../../data/customSortDatRaw";
import {map} from "rxjs/operators";

@Injectable({
	providedIn: 'root'
})
export class TypeZeroService extends FilterService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/type0';
	private readonly emetteurBaseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/num-emetteur';

	constructor(private httpClient: HttpClient) {
		super();
	}

	getTypeZeroById(criteria: any): Observable<any> {
		return this.httpClient.get<any>(this.baseUrl + '/' + criteria.idTypeZero);
	}

	getTypeZero(criteria: any): Observable<any> {
		return this.httpClient.get<any>(
			this.baseUrl +
				'/list?page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				this.getSortParameters(criteria.sorts)
		);
	}

	getTypeZeroFilteredList(criteria: any, typeZeroDataRaw: TypeZeroDataRaw): Observable<any> {
		return this.httpClient.post<any>(
			this.baseUrl +
				'/search?page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				this.getSortParameters(criteria.sorts),
			typeZeroDataRaw
		);
	}
	getSortParameters(sorts) {
		if (sorts.length === 1) {
			const sort = sorts[0].property;
			return '&sort=' + sort + '&direction=' + sorts[0].direction;
		} else {
			// sort par défaut
			return '&sort=typeEmetteur&direction=ASC';
		}
	}

	save(type0) {
		return this.httpClient.put(this.baseUrl, type0).toPromise();
	}

	getNumEmetteur(): Observable<NumeroEmetteurDataRaw[]> {
		return this.httpClient.get<any>(this.emetteurBaseUrl + '/list');
	}

	exportTypeZeros(criteria: any, typeZeroDataRaw: TypeZeroDataRaw, fields: string[]): Observable<any> {
		const sorts: CustomSortDataRow[] = criteria.sorts;
		return this.httpClient.post<HttpResponse<Blob>>(
			this.baseUrl + '/export?' + this.getSortParameters(sorts),
			{
				filters: typeZeroDataRaw,
				fields: fields
			},
			{
				observe: 'response' as 'body',
				responseType: 'blob' as 'json'
			}
		).pipe(
			map((response: HttpResponse<Blob>): any => {
				return this.getFileInfosFromResponse(response);
			})
		)
	}
	exportTypeZerosByEmail(criteria: any, typeZeroDataRaw: TypeZeroDataRaw, fields: string[]): Observable<any> {
		const sorts: CustomSortDataRow[] = criteria.sorts;
		return this.httpClient.post<HttpResponse<Blob>>(
			this.baseUrl + '/export-by-email?'+this.getSortParameters(sorts),
			{
				filters: typeZeroDataRaw,
				fields: fields
			},
			{
				observe: 'response' as 'body',
				responseType: 'blob' as 'json'
			}
		);
	}
	getFileInfosFromResponse(response: HttpResponse<Blob>) {
		return {
			file: new Blob([ response.body ], { type: 'application/vnd.ms-excel' }),
			fileName: 'Liste-Des-Type-Zero.xlsx'
		};
	}
}