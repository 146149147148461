<div class="modal modal-show fade show" id="modalPslogiciel" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="inmodal">
        <div class="modal-header pb-1">
          <h3 class="modal-title">{{title}}</h3>
          <button class="close" name="btn_display_filter" data-dismiss="modal" type="button" title="Fermer"
            (click)="closeModal()"><span aria-hidden="true">×</span></button>
        </div>
        <div class="modal-body pb-0 mt-4" *ngIf="!isDeleteModale">
          <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="oldRuleKeyLabel !== ''">
            Le mot clé <strong>{{oldRuleKeyLabel}}</strong> est déjà ajouté au dictionnaire.
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click) = "resetOldRuleKeyLabel()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="row mb-2">
            <form [formGroup]="dictRuleForm" class="col-12">
              <div class="row">
                <div class="col-4 col-form-label">Mot clé virement <span style="color:red">*</span> : </div>
                <div class="col-8 form-group">
                  <input type="text" class="form-control" formControlName="keyLabel" required
                    [ngClass]="{ 'is-invalid': isDictRuleFormSubmitted && dictRuleFormFields.keyLabel.errors }" />
                  <div *ngIf="isDictRuleFormSubmitted && dictRuleFormFields.keyLabel.errors" class="invalid-feedback">
                    <div *ngIf="dictRuleFormFields.keyLabel.errors?.required">
                      Ce champ est obligatoire
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-4 col-form-label">Organisme <span style="color:red">*</span> : </div>
                <div class="col-8 form-group">
                  <input type="text" class="form-control" formControlName="organisme" required
                    [ngClass]="{ 'is-invalid': isDictRuleFormSubmitted && dictRuleFormFields.organisme.errors }" />
                  <div *ngIf="isDictRuleFormSubmitted && dictRuleFormFields.organisme.errors" class="invalid-feedback">
                    <div *ngIf="dictRuleFormFields.organisme.errors?.required">
                      Ce champ est obligatoire
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-4 col-form-label">Code règle <span style="color:red">*</span> :</div>
                <div class="col-8 form-group">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="codeRegle" formControlName="codeRegle" value="1"
                      id="codeRegle1">
                    <label class="form-check-label" for="codeRegle1">
                      1 : Le montant d'1 VRT correspond au montant d'un lot de RSP regroupés par date de réception + libellé organisme (1 > N).
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="codeRegle" formControlName="codeRegle" value="2"
                      id="codeRegle2">
                    <label class="form-check-label" for="codeRegle2">
                      2 : Le montant d'1 VRT correspond au montant d'un lot de RSP regroupés par date de réception + libellé organisme + N° lot (1 > N).
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="codeRegle" formControlName="codeRegle" value="3"
                      id="codeRegle3">
                    <label class="form-check-label" for="codeRegle3">
                      3 : Le montant d'1 VRT correspond au montant d'1 seul RSP (1 > 1).
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="codeRegle" formControlName="codeRegle" value="4"
                           id="codeRegle4">
                    <label class="form-check-label" for="codeRegle4">
                      4 : Le montant de TOUS les VRT du jour correspond au montant d'un lot de RSP regroupés par date de réception + libellé organisme (N > N).
                    </label>
                  </div>
                  <div *ngIf="isDictRuleFormSubmitted && dictRuleFormFields.codeRegle.errors" class="invalid-feedback">
                    <div *ngIf="dictRuleFormFields.codeRegle.errors?.required">
                      Ce champ est obligatoire
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="modal-body pb-0" *ngIf="isDeleteModale">
          <div class="form-block">
            <fieldset class="container-fluid">
              <div class="row">
                <div class="col-sm-12 col-form-label">Etes-vous sûr de vouloir supprimer la règle ?</div>
              </div>
            </fieldset>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" (click)="closeModal()" class="btn btn-default">Annuler</button>
          <button type="button" (click)="onSubmit()" class="btn btn-primary" *ngIf="!isDeleteModale">Enregistrer</button>
          <button type="button" (click)="onSubmit()" class="btn btn-primary" *ngIf="isDeleteModale">Supprimer</button>
        </div>

      </div>
    </div>
  </div>

</div>