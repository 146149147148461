export class AbonnementDataRaw{
    id : number;
    ebicsHostId : string;
    ebicsUserId : string;
    ebicsUrl : string;
    ebicsFileFormat : string;
    ebicsOrderType : string;
    bankName : string;
    ebicsCustomerId: string;
    lastSyncDate : string;
    accountRef : string;
    lastSyncStatut: string;
    bankId;
}
