<mat-expansion-panel [disabled] = "!isDateRangeValid"  #expansionPannel>
    <mat-expansion-panel-header>
        <mat-panel-title>{{title}}</mat-panel-title>
        <mat-panel-description><span class="accordion-badge" [style.visibility]="badgeCount > 0 ? 'visible' : 'hidden'">{{
                badgeCount
            }}</span></mat-panel-description>
    </mat-expansion-panel-header>
    <div class="accordion-content">
        <ng-content></ng-content>
    </div>
</mat-expansion-panel>