import { Component, Input, ElementRef, ViewChild } from '@angular/core';
import { GridApi } from 'ag-grid-enterprise';

@Component({
	selector: 'careweb-pagination',
	templateUrl: './pagination.component.html',
	styleUrls: [ './pagination.component.scss' ]
})
export class PaginationComponent {
	@Input() gridApi: GridApi;

	@ViewChild('pagination', { static: true })
	pagination: ElementRef;

	/**
     * Clic sur le bouton de navigation prémière page.
     */
	pageFirstPageClick() {
		this.gridApi.paginationGoToFirstPage();
	}

	/**
     * Clic sur le bouton de navigation page précedente.
     */
	pagePreviousPageClick() {
		this.gridApi.paginationGoToPreviousPage();
	}

	/**
     * Clic sur le bouton de navigation page suivante.
     */
	pageNextPageClick() {
		this.gridApi.paginationGoToNextPage();
	}

	/**
     * Clic sur le bouton de navigation dernière page.
     */
	pageLastPageClick() {
		this.gridApi.paginationGoToLastPage();
	}

	/**
     * Traite l'evenement de chagement de page lors de la saisie du numéro de page.
     * @param pageNumber Le numéro de la page à afficher.
     */
	changePage(pageNumber: number) {
			this.gridApi.paginationGoToPage(pageNumber - 1);
	}

	/**
     * Fournit le nombre total de page.
     */
	get totalPages() {
		return this.gridApi && this.gridApi.paginationGetTotalPages();
	}

	/**
     * Fournit le numéro de la page courante.
     */
	get currentPage() {
		return this.gridApi && this.gridApi.paginationGetCurrentPage() + 1;
	}

	/**
     * Fournit un indicateur de navigabilité en arrière.
     */
	get cantPageBackward() {
		return !!!this.gridApi || this.gridApi.paginationGetCurrentPage() === 0;
	}

	/**
     * Fournit un indicateur de navigabilité en avant.
     */
	get cantPageForward() {
		return !!!this.gridApi || this.gridApi.paginationGetCurrentPage() + 1 >= this.gridApi.paginationGetTotalPages();
	}

	paginationKeyPressed($event) {
		$event.stopPropagation();
		if ($event.currentTarget.value > this.totalPages) {
			$event.currentTarget.value = this.totalPages;
			return false;
		}
		return $event.charCode === 8 || $event.charCode === 0 || $event.charCode === 13
			? null
			: $event.charCode >= 48 && $event.charCode <= 57;
	}
}
