import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONF } from '../../../core/constants';
import { EtablissementDataRaw } from '../../../data/EtablissementDataRaw';

@Injectable({
	providedIn: 'root'
})
export class EtablissementService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/etablissement';

	constructor(private httpClient: HttpClient) {}

	getEtablissementById(idEtablissement): Observable<EtablissementDataRaw> {
		return this.httpClient.get<EtablissementDataRaw>(this.baseUrl + '/' + idEtablissement);
	}

	saveEtablissementWithAttachPs(idPs, etablissement): Observable<any> {
		return this.httpClient.post<any>(this.baseUrl + '/create/' + idPs, etablissement);
	}
	updateEtablissementWithAttachPs(etablissement): Observable<any> {
		return this.httpClient.put<any>(this.baseUrl + '/', etablissement);
	}
	getAllEtablissement(criteria): Observable<any> {
		return this.httpClient.get<EtablissementDataRaw[]>(
			this.baseUrl +
				'/list/?page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				this.getSortParameters(criteria.sorts)
		);
	}

	getEtablissementsFilteredList(criteria, etablissement: EtablissementDataRaw): Observable<any> {
		return this.httpClient.post<EtablissementDataRaw[]>(
			this.baseUrl +
				'/search/?page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				this.getSortParameters(criteria.sorts),
			etablissement
		);
	}

	getSortParameters(sorts) {
		if (sorts && sorts.length === 1) {
			const sort = sorts[0].property;
			return '&sort=' + sort + '&direction=' + sorts[0].direction;
		} else {
			// sort par défaut
			return '&sort=raisonSociale&direction=DESC';
		}
	}
}
