import { Observable } from 'rxjs';
import { CONF } from './../../../core/constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class NouveautesService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/nouveautes/';

	constructor(private httpClient: HttpClient) {}

 	getReleaseNotes(): Observable<any> {
		return this.httpClient.get<any>(this.baseUrl, { responseType: 'text' as 'json' });
	}
}
