<div class="tab-pane">
    <div class="form-block">
        <fieldset class="container-fluid">
            <div class="row mb-2">
                <div class="col">
                    <h3>{{getLibelleSelected()}}
                    </h3>
                </div>
            </div>
            <div class="row highlight-row">
                <div class="col-md-12 relative">
                    <ag-grid-angular style="width: 100%;" class="ag-theme-material"
                        [gridOptions]="gridHelper.gridOptions">
                    </ag-grid-angular>
                    <careweb-pagination [gridApi]="gridHelper.gridApi">
                    </careweb-pagination>
                </div>
            </div>
        </fieldset>
        <div class="row grid-top-btn" style="margin-top:0px">
            <div class="col-md-12 conf-col">
                <div class="sy-toolbar">

                    <!-- TOOLBAR -->
                    <div class="col-3 mt-3">
                        <span *ngIf="messageNotSelected " class="spanError">
                            Veuillez choisir au moins un PS *
                        </span>
                    </div>
                        <button type="button" (click)="cancel()" class="btn btn-default me-3">
                            Annuler
                        </button>&nbsp;&nbsp;
                        <!-- Bouton -->
                        <button type="submit" class="btn btn-primary "(click)="savePsSupervise()">
                            Enregistrer
                        </button>
                    </div>
                </div>
            </div>
    </div>
</div>