import { Directive, HostListener, Input } from '@angular/core';
import { isNull } from 'util';

@Directive({ selector: '[onlyNum]' })
export class OnlyNumDirective {
    @Input('onlyNum') maxLenght: number = 9;

    @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent): boolean {
        const inputElement = event.target as HTMLInputElement; 
        const inputValue = (inputElement).value;
        
        if (
            (!this.isTextSelected(inputElement) && inputValue.length == this.maxLenght) ||
            isNull(event.key.match(/^[0-9]+$/))
        ) {
            return false;
        }
    }

    @HostListener('input', ['$event']) onInput(event: KeyboardEvent): void {
        const target =  (event.target as HTMLInputElement);
        target.value = target.value.replace(/\s/g,'').replace( /\./gi,'');
    }
    
    @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent): boolean {
        const inputElement = event.target as HTMLInputElement;
        const targetValue =  (inputElement).value;
        const pasteValue = event.clipboardData.getData('text/plain').replace(/\s/g,'').replace( /\./gi,'');

        if (
            (!this.isTextSelected(inputElement) && (targetValue.length == this.maxLenght || pasteValue.length + targetValue.length > this.maxLenght )) ||
            pasteValue.length > this.maxLenght ||
            isNull(pasteValue.match(/^[0-9]+$/))
        ) {
            return false;
        }
    }

    public isTextSelected(input: HTMLInputElement): boolean {
        if (typeof input.selectionStart == "number") {
            return input.selectionStart == 0 && input.selectionEnd == input.value.length;
        } 
        return false;
    }
}
