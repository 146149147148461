<careweb-help></careweb-help>
<div class="full-careweb-container">
  <div id="filter" class="filter-container" carewebFilterMinHeight>
    <div class="filter-container-inner" [ngClass]="{ 'show-content': showFilter, 'init-hidden': initHidden }">
      <careweb-accordion-header-buttons [disablesearch]="disableSearch()" (closeFilters)="toggleFilterDisplay()" (resetFilters)="resetAllFilter()"
        (launchSearch)="launchSearch()"></careweb-accordion-header-buttons>
      <div class="filter-content">
        <mat-accordion displayMode="flat" class="accordion-filter">
          <!-- @TODO !! change input controle with directives-->
          <!--Afficher Numéro de PS avec input si l'utilisateur Hotline ou Admin-->
          <careweb-accordion-group id="num_ps" title="Numéro de PS" [hidden]="!existGroupNumeroPS()">
            <input type="text" id="input_num_ps" [(ngModel)]="filters.numeroPs" (keydown.enter)="onKeyDown($event)"
            [hidden]="!isAuthorized()" onlyNum="9" placeholder="000000000"/>

            <!--Afficher Numéro de PS avec ComboBox si l'utilisateur PS-->
            <ng-select name="numeroPScomboBox" id="numeroPScomboBox" placeholder="Numéro de PS" [searchable]="false"
              [(ngModel)]="filters.numeroPScomboBox" appendTo=".filter-container" [hidden]="isAuthorized()">
              <ng-option *ngFor="let numeroPScomboBox of psDelegueListFilter" [value]="numeroPScomboBox.numeroPsDelegue">
                {{numeroPScomboBox.numeroPsDelegue}}
              </ng-option>
            </ng-select>
          </careweb-accordion-group>

          <!-- Numéro du lot -->
          <careweb-accordion-group id="num_lot" title="Numéro du lot">
            <careweb-filter-choice-exacte-plage [id]="'num_lot'"
                [(filterChoiceFrom)]="filters.numLotFrom" 
                [(filterChoiceTo)]="filters.numLotTo"
                (filterChange)="this.onChoiceChange($event, 'numLot')">
                <input EXACTE type="text" id="input_exacte_numLot" class="form-control" [(ngModel)]="filters.numLotExacte"
                  name="numLotExacte" maxlength="3" (keydown.enter)="onKeyDown($event)"
                  onkeyup="this.value=this.value.replace(/[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/,'')"
                  oninput="this.value=this.value.replace(/[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/,'');"/>
                <input FROM type="text" id="input_from_numLot" class="form-control" [(ngModel)]="filters.numLotFrom"
                  name="numLotFrom" maxlength="3" (keydown.enter)="onKeyDown($event)"
                  onkeyup="this.value=this.value.replace(/[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/,'')"
                  oninput="this.value=this.value.replace(/[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/,'');"/>
                <input TO type="text" id="input_to_numLot" class="form-control" [(ngModel)]="filters.numLotTo"
                  name="numLotTo" maxlength="3" (keydown.enter)="onKeyDown($event)"
                  onkeyup="this.value=this.value.replace(/[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/,'')"
                  oninput="this.value=this.value.replace(/[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/,'');"/>
            </careweb-filter-choice-exacte-plage>
        </careweb-accordion-group>
          <!-- Type du lots -->
          <careweb-accordion-group id="type_lots" title="Sécurisé">
            <ng-select name="securise" id="securise" placeholder="Lots sécurisés" [searchable]="false"
            [(ngModel)]="filters.securise" appendTo=".filter-container" (keydown.enter)="onKeyDown($event)">
            <ng-option [value]="''"> </ng-option>
            <ng-option [value]="true">Oui</ng-option>
            <ng-option [value]="false">Non</ng-option>
          </ng-select>
          </careweb-accordion-group>
          <!-- Tiers-payant -->
          <careweb-accordion-group id="tiers_payant" title="Tiers-payant">
            <ng-select name="tiersPayant" id="tiersPayant" placeholder="Tiers-Payant" [searchable]="false"
            [(ngModel)]="filters.tiersPayant" appendTo=".filter-container" (keydown.enter)="onKeyDown($event)">
            <ng-option [value]="''"> </ng-option>
            <ng-option [value]="true">Oui</ng-option>
            <ng-option [value]="false">Non</ng-option>
          </ng-select>
          </careweb-accordion-group>
          <!-- Signe des ARL -->
          <careweb-accordion-group id="signe_arl" title="Signe des ARL">
            <ng-select name="signeArl" id="signeArlSelector" placeholder="Signe des ARL" [searchable]="false"
              [(ngModel)]="filters.presenceArl" appendTo=".filter-container" (keydown.enter)="onKeyDown($event)">
              <ng-option [value]="''">Tous les lots</ng-option>
              <ng-option [value]="'1'">Lots avec ARL positif</ng-option>
              <ng-option [value]="'2'">Lots avec ARL négatif</ng-option>
              <ng-option [value]="'3'">Lots sans ARL</ng-option>
            </ng-select>
          </careweb-accordion-group>
          <!-- Présence de RSP -->
          <careweb-accordion-group id="presence_rsp" title="Présence de RSP">
            <ng-select name="presenceRsp" id="presenceRsp" placeholder="Présence de RSP" [searchable]="false"
            [(ngModel)]="filters.presenceRsp" appendTo=".filter-container" (keydown.enter)="onKeyDown($event)">
            <ng-option [value]="''"> </ng-option>
            <ng-option [value]="1">Oui</ng-option>
            <ng-option [value]="0">Non</ng-option>
          </ng-select>
          </careweb-accordion-group>
          <!-- Lots envoyés -->
          <careweb-accordion-group id="lots_envoyes" title="Lots envoyés" #dateAccordion>
            <careweb-date-from-to-filter labelFrom="entre le" [(modelFrom)]="filters.dateEnvoieFrom"
              [(modelTo)]="filters.dateEnvoieTo" [(modelExacte)]="filters.dateEnvoieExacte" 
							[maxDate]="null" [isExactePlageChoice]="true" id="lots_envoyes"
							(filterChange)="this.onChoiceChange($event, 'dateEnvoie')"
              (dateRangeValidityChange) = "checkDateRangeValidity($event)" #dateEnvoie (onEnter) = "onKeyDown($event)"></careweb-date-from-to-filter>
          </careweb-accordion-group>
          <!-- Sigle organisme destinataire RO -->
          <careweb-accordion-group id="sigle_dest_ro" title="Sigle organisme AMO">
            <input type="text" id="input_sigle_dest_ro" [(ngModel)]="filters.libelleOrganisme" maxlength="255" 
            (keydown.enter)="onKeyDown($event)"/>
          </careweb-accordion-group>
          <!-- Code officiel organisme destinataire RO -->
          <careweb-accordion-group id="code_dest_ro" title="Code officiel ORG AMO">
            <input type="text" id="input_code_dest_ro" onlyNum="5" [(ngModel)]="filters.organismeDestinataire"
            (keydown.enter)="onKeyDown($event)"/>
          </careweb-accordion-group>
        </mat-accordion>
      </div>
    </div>
  </div>
  <div class="grid-container" id="grid">
    <h2 class="title-border mt-3">Liste des lots
      <careweb-open-help code="help-008"></careweb-open-help>
    </h2>
    <div>
      <section>
        <div class="row grid-top-btn">
          <div class="col-md-4 filter">
            <button type="button" name="btn_toggle_filter" class="btn btn-primary small left-open" [hidden]="showFilter"
              (click)="toggleFilterDisplay()">
              <i class="fa fa-sliders"></i>
              Filtrer
            </button>
            <careweb-pluralize *ngIf="totalResultsHidden" class="filter-count" [messageKey]="'Résultat(s) trouvé(s)'"
              [count]="gridHelper.totalRowCount">
            </careweb-pluralize>
          </div>
          <div class="col-md-8 conf-col">
            <div class="careweb-toolbar">
              <div [tooltip]="gridHelper.isAvailableData() ? 'Exporter les résultats (.xlsx)' : 'Aucune ligne à exporter, veuillez affiner votre recherche'"
                   placement="top" container="body">
                <span class="btn btn-action small" [class.disabled]="!gridHelper.isAvailableData()"
                       name="btn_export_csv"  (click)="exportFileXlsxModal()">
                <i class="fa fa-file-excel-o"></i>
              </span>
              </div>

              
              <careweb-column-selector (itemSelect)="selectColumnEvent($event)"
                (itemDeselect)="deselectColumnEvent($event)"></careweb-column-selector>
              <careweb-pagination-size id="dv_lots_paging" [pageSizes]="[10, 20, 30, 50, 100]"
                [(ngModel)]="gridDataRaw.size" (paginationSizeChange)="onPaginationSizeChange($event)">
              </careweb-pagination-size>
              <span class="btn btn-action small" tooltip="Réinitialiser l'affichage" placement="top" container="body"
                name="spn_reset_grid" (click)="resetGridState()">
                <i class="fa fa-refresh"></i>
              </span>
            </div>
          </div>
        </div>
          <app-alert [export]="export" [exportState]="exportState" (close)="closeAlert()"></app-alert>
        <div class="row grid-top-filter">
          <div class="col-md-12">
            <careweb-filter-label *ngFor="let filter of filterList" (closeFilter)="removeFilter(filter.id)">
              <span *ngIf="filter">{{ filter.label}}: {{filter.value }}</span>
            </careweb-filter-label>
          </div>
        </div>
        <div class="row highlight-row">
          <div class="col-md-12 relative">
            <ag-grid-angular style="width: 100%;" class="ag-theme-balham" [gridOptions]="gridHelper.gridOptions">
            </ag-grid-angular>
            <careweb-pagination [gridApi]="gridHelper.gridApi"></careweb-pagination>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
