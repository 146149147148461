import { Injectable } from '@angular/core';
import some from 'lodash-es/some';
import { FilterService } from '../../../core/services/filter-service';
import { FilterItem } from '../../../data/filters/filter-item';

@Injectable()
export class FseFilterSvc extends FilterService {
	constructor() {
		super();
	}

	removeFilter(id: string): void {
		this.removeFilterTmp(id);
		const index = this.filterList.findIndex((filt) => filt.id === id);
		this.filterList.splice(index, 1);
	}

	addFilterTmp(filterItem: FilterItem): void {
		// Si un filtre avec un identifiant similaire on le supprime
		if (some(this.filterListTmp, { id: filterItem.id })) {
			this.removeFilterTmp(filterItem.id);
		}
		this.filterListTmp.push(filterItem);
	}

	removeFilterTmp(id: string): void {
		const index = this.filterListTmp.findIndex((filt) => filt.id === id);
		this.filterListTmp.splice(index, 1);
	}
}
