<div class="full-careweb-container">


    <div id="filter" class="filter-container" carewebFilterMinHeight>
        <div class="filter-container-inner" [ngClass]="{ 'show-content': showFilter, 'init-hidden': initHidden }">
            <careweb-accordion-header-buttons (closeFilters)="toggleFilterDisplay()" (resetFilters)="resetFilter()"
                (launchSearch)="launchSearch()"></careweb-accordion-header-buttons>
            <div class="filter-content">
                <mat-accordion displayMode="flat" class="accordion-filter">
                    <!-- Organisme -->
                    <careweb-accordion-group id="organism" title="Organisme">
                        <input type="text" id="organism" [(ngModel)]="filters.organism"
                            (keydown.enter)="onKeyDown($event)" />
                    </careweb-accordion-group>
                    <!-- Règle de clés -->
                    <careweb-accordion-group id="keyRule" title="Règle de clés">
                        <input type="text" id="keyRule" [(ngModel)]="filters.keyRule"
                            (keydown.enter)="onKeyDown($event)" />
                    </careweb-accordion-group>
                    <!-- Règle de dictionnaire -->
                    <careweb-accordion-group id="dictionaryRule" title="Règle de dictionnaire">
                        <input type="text" id="dictionaryRule" [(ngModel)]="filters.dictionaryRule"
                            (keydown.enter)="onKeyDown($event)" />
                    </careweb-accordion-group>
                </mat-accordion>
            </div>
        </div>
    </div>

    <div class="grid-container" id="grid">
        <div class="row mt-3">
            <div class="col-lg-9">
                <h2 class="title-border">Performance du rapprochement par organisme
                </h2>
            </div>
            <div class="col-lg-3">
                <careweb-date-selecteur-periode [startDate]="filters.startDate" [applyMindate]="false"
                    [endDate]="filters.endDate" [ranges]="[7, 30, 60]" (dateFilterChange)="onDateFilterChange($event)">
                </careweb-date-selecteur-periode>
            </div>
        </div>

        <section>
            <div class="row grid-top-btn">
                <div class="col-md-4 filter">
                    <button type="button" name="btn_toggle_filter" class="btn btn-primary small left-open"
                        [hidden]="showFilter" (click)="toggleFilterDisplay()">
                        <i class="fa fa-sliders"></i>
                        Filtrer
                    </button>
                    <careweb-pluralize class="filter-count" [messageKey]="'Résultat(s) trouvé(s)'"
                        [count]="totalElement"></careweb-pluralize>
                </div>
                <div class="col-md-8 conf-col">
                    <div class="careweb-toolbar">
                        <careweb-pagination-size id="dv_editeurs_paging" [pageSizes]="[10, 20, 30, 50, 100]"
                            [(ngModel)]="gridDataRaw.size" (paginationSizeChange)="onPaginationSizeChange($event)">
                        </careweb-pagination-size>
                        <span class="btn btn-action small" tooltip="Réinitialiser l'affichage" placement="top"
                            container="body" name="spn_reset_grid" (click)="resetGridState()">
                            <i class="fa fa-refresh"></i>
                        </span>
                    </div>
                </div>
            </div>
            <!-- show chips -->
            <div class="row grid-top-filter">
                <div class="col-md-12">
                    <careweb-filter-label *ngFor="let filter of filterList" (closeFilter)="removeFilter(filter.id)">
                        <span *ngIf="filter">{{ filter.label}}: {{filter.value }}</span>
                    </careweb-filter-label>
                </div>
            </div>
            <div class="row highlight-row">
                <div class="col-md-12 relative">
                    <ag-grid-angular style="width: 100%;" class="ag-theme-balham ag-theme-rapp-manual"
                        [gridOptions]="gridHelper.gridOptions">
                    </ag-grid-angular>
                    <careweb-pagination-rapp (pageChange)="pageChange($event)" [totalCount]="rowCount"
                        [currentPage]="currentPage"></careweb-pagination-rapp>
                </div>
            </div>
        </section>
    </div>
</div>