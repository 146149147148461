import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONF } from '../../core/constants';
import { RspRappSummaryDataRaw } from '../../data/rspRappSummaryDataRaw';
import { CustomSortDataRow } from '../../data/customSortDatRaw';

@Injectable({
	providedIn: 'root'
})
export class RspRappSummaryService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/rapp';

	constructor(private httpClient: HttpClient) {}

	getRappSummarysFilteredList(criteria: any, rspRappSummaryDataRaw: RspRappSummaryDataRaw): Observable<any> {
		const sorts: CustomSortDataRow[] = criteria.sorts;
		return this.httpClient.post<any>(
			this.baseUrl + '/rspsummary/searchFilterV2',
			{
				page: criteria.page,
				size: criteria.size,
				filters: rspRappSummaryDataRaw,
				sorts: sorts
			}
		);
	}
	getRappTotalCount(rspRappSummaryDataRaw: RspRappSummaryDataRaw): Observable<any> {
		return this.httpClient.post<any>(this.baseUrl + '/rspsummary/totalFse', rspRappSummaryDataRaw);
	}
	/* [CAW-1600-] Desactiver la checkAll & uncheckAll */
	getRspCountAndSumAmount(rspRappSummaryDataRaw: RspRappSummaryDataRaw): Observable<any> {
		return this.httpClient.post<any>(this.baseUrl + '/rspsummary/countAndSumAmount', rspRappSummaryDataRaw);
	}

	getAllCheckedRsp(rspRappSummaryDataRaw: RspRappSummaryDataRaw): Observable<any> {
		return this.httpClient.post<any>(this.baseUrl + '/rspsummary/allCheckedRsp', rspRappSummaryDataRaw);
	}

	isCheckedRspSoldable(rspRappSummaryDataRaw: RspRappSummaryDataRaw): Observable<any> {
		return this.httpClient.post<any>(this.baseUrl + '/rspsummary/isCheckedRspSoldable', rspRappSummaryDataRaw);
	}

	getRspAvecPaiementEtLibelle(rspRappSummaryDataRaw: RspRappSummaryDataRaw): Observable<any> {
		return this.httpClient.post<any>(this.baseUrl + '/rspsummary/rspAvecPaiementEtLibelle', rspRappSummaryDataRaw);
	}

	getRspAvecPaiementRapproches(rspRappSummaryDataRaw: RspRappSummaryDataRaw): Observable<any> {
		return this.httpClient.post<any>(this.baseUrl + '/rspsummary/rspAvecPaiementRapproches', rspRappSummaryDataRaw);
	}

	getAllRspByIdPs(rspRappSummaryDataRaw: RspRappSummaryDataRaw): Observable<any> {
		return this.httpClient.post<any>(this.baseUrl + '/rspsummary/allRsp', rspRappSummaryDataRaw);
	}

	getTop10Organismes(rspRappSummaryDataRaw: RspRappSummaryDataRaw): Observable<any> {
		return this.httpClient.post<any>(this.baseUrl + '/rspsummary/top10Organismes', rspRappSummaryDataRaw);
	}

	getTop10OrganismesByRappAuto(rspRappSummaryDataRaw: RspRappSummaryDataRaw): Observable<any> {
		return this.httpClient.post<any>(this.baseUrl + '/rspsummary/top10OrganismesByRappAuto', rspRappSummaryDataRaw);
	}

	getRspByDateAndStatus(rspRappSummaryDataRaw: RspRappSummaryDataRaw): Observable<any> {
		return this.httpClient.post<any>(this.baseUrl + '/rspsummary/rspByDateAndStatus', rspRappSummaryDataRaw);
	}
}
