import { OrganismeDataRaw } from './organismeDataRaw';

export class AmoDataRaw extends OrganismeDataRaw {
	codeOfficiel: string;
	codeGr: string;
	codeCaisseCas: string;
	codeCentreCas: string;
	envoisCompresses: boolean;
	codeCentreInfoType0: string;
	codeOrgType1: string;
	codeCentreInfoType0Gen: boolean;
	codeOrgType1Gen: boolean;
	tousCentres: boolean;
}
