import {Component, EventEmitter, Input, Output} from "@angular/core";
import {ExportState} from "../../shared/model/storage.model";

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html'
})
export class AlertComponent{
    @Input() export: boolean;
    @Input() exportState: ExportState;
    @Output() close = new EventEmitter<any>();

    closeAlert() {
        this.close.emit();
    }
}
