<div class="x-large-careweb-container">
    <section class="form-content">
        <!-- Titre H2 -->
        <div class="row form-title">
            <h2 class="col-md-6">
                Administration des paramètres
            </h2>
        </div>
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" [destroyOnHide]="false" class="nav-tabs">
            <li [ngbNavItem]="1" title="Motifs" >
                		<button ngbNavLink>Motifs</button>
		                <ng-template ngbNavContent>
                    <!-- ONGLET FSE -->
                    <div id="ongletFSE" class="tab-pane fade show active  mt-4">
                        <div class="col-md-12 text-title noPadding ">
                            <button type="" class="btn btn-primary btn-lg" data-toggle="modal"
                                data-target="#modalAddMotif" (click)="openModalAddMotif()">
                                Ajouter un motif d'intervention
                            </button>
                        </div>
                        <div class="form-block  mt-4">
                            <div class="row highlight-row">
                                <div class="col-md-12 relative">
                                    <ag-grid-angular style="width: 100%" class="ag-theme-balham"
                                        [gridOptions]="gridHelperHotMotifs.gridOptions">
                                    </ag-grid-angular>
                                    <careweb-pagination [gridApi]="gridHelperHotMotifs.gridApi"></careweb-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="2" title="Types">
                		<button ngbNavLink>Types</button>
		                <ng-template ngbNavContent>
                    <!-- ONGLET FSE -->
                    <div id="ongletType" class="tab-pane fade show active  mt-4">
                        <div class="col-md-12 text-title noPadding">
                            <button type="" class="btn btn-primary btn-lg" data-toggle="modal"
                                data-target="#modalAddMotif" (click)="openModalAddType()">
                                Ajouter un type d'intervention
                            </button>
                        </div>
                        <div class="form-block  mt-4">
                            <div class="row highlight-row">
                                <div class="col-md-12 relative">
                                    <ag-grid-angular style="width: 100%" class="ag-theme-balham"
                                        [gridOptions]="gridHelperHotType.gridOptions">
                                    </ag-grid-angular>
                                    <careweb-pagination [gridApi]="gridHelperHotType.gridApi"></careweb-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="3" title="Résolutions">
                		<button ngbNavLink>Résolutions</button>
		            <ng-template ngbNavContent>
                    <!-- ONGLET FSE -->
                    <div id="ongletFSE" class="tab-pane fade show active  mt-4">
                        <div class="col-md-12 text-title noPadding">
                            <button type="" class="btn btn-primary btn-lg" data-toggle="modal"
                                data-target="#modalAddMotif" (click)="openModalAddResolution()">
                                Ajouter une résolution d'intervention
                            </button>
                        </div>
                        <div class="form-block  mt-4 ">
                            <div class="row highlight-row">
                                <div class="col-md-12 relative">
                                    <ag-grid-angular style="width: 100%" class="ag-theme-balham"
                                        [gridOptions]="gridHelperHotResolution.gridOptions">
                                    </ag-grid-angular>
                                    <careweb-pagination [gridApi]="gridHelperHotResolution.gridApi">
                                    </careweb-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="4" title="Services">
                		<button ngbNavLink>Services</button>
		            <ng-template ngbNavContent>
                    <div id="ongletFSE" class="tab-pane fade show active mt-4">
                        <div class="col-md-12 text-title noPadding ">
                            <button type="" class="btn btn-primary btn-lg" data-toggle="modal"
                                data-target="#modalAddMotif" (click)="openModalAddHotGroupUsers()">
                                Ajouter un type service du destinataire
                            </button>
                        </div>
                        <div class="form-block mt-4">
                            <div class="row highlight-row">
                                <div class="col-md-12 relative">
                                    <ag-grid-angular style="width: 100%" class="ag-theme-balham"
                                        [gridOptions]="gridHelperGroupUsers.gridOptions">
                                    </ag-grid-angular>
                                    <careweb-pagination [gridApi]="gridHelperGroupUsers.gridApi">
                                    </careweb-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div> 
    </section>
</div>
<!--  MODAL -->
<div *ngIf="showAddTypetModal">
    <div class="modal-backdrop"></div>
    <div class="modal modal-show fade show" id="modalAddContact" tabindex="-1">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="inmodal">
                    <div class="modal-header pb-1">
                        <h3 class="modal-title">Administration</h3>
                        <div class="button-bar control">
                            <i class="fa fa-close" (click)="refresh()"></i>
                        </div>
                    </div>
                    <div class="modal-body pb-0">
                        <h3 class="title-border ">{{ btnName}} un type d'intervention</h3>
                        <div class="form-block mt-4">
                            <fieldset class="container-fluid">
                                <div class="row">
                                    <div class="col-sm-2 col-form-label" style="font-weight: 600;">Libellé *</div>
                                    <div class="col-sm-10 form-group">
                                        <input type="text" class="form-control" [(ngModel)]="type.libelle"
                                            maxlength="300" [required]="true"
                                            [ngClass]="{ 'is-invalid': messageEmptyField }" />
                                        <div *ngIf="messageEmptyField" class="invalid-feedback">
                                            Ce champ est obligatoire
                                        </div>
                                    </div>
                                </div>
                                <div class="button-bar control">
                                    <button type="button" (click)="refreshHotType()" class="btn btn-default "> Annuler
                                    </button>
                                    <button type="button" (click)="saveType()" class="btn btn-primary">
                                        <span>{{ btnName}}</span>
                                    </button>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div class="modal-footer">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="showDeleteTypeModal">
    <div class="modal-backdrop"></div>
    <div class="modal modal-show fade show" id="modalDeleteType" tabindex="-1">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="inmodal">
                    <div class="modal-header pb-1">
                        <h3 class="modal-title">Administration</h3>
                        <div class="button-bar control">
                            <i class="fa fa-close" (click)="refresh()"></i>
                        </div>
                    </div>
                    <div class="modal-body pb-0">
                        <h3 class="title-border mt-1">Voulez-vous supprimer le type d'intervention ?
                        </h3>

                        <div class="form-block">
                            <fieldset class="container-fluid">
                                <div class="row">
                                    <div class="col-sm-2 col-form-label" style="font-weight: 600;">Libellé *</div>
                                    <div class="col-sm-10 form-group">
                                        <input type="text" class="form-control" [(ngModel)]="type.libelle" readonly
                                            maxlength="300" />
                                    </div>
                                </div>
                                <div class="button-bar control">
                                    <button type="button" (click)="refresh()" class="btn btn-default"> Annuler
                                    </button>
                                    <button type="button" (click)="deleteType()" class="btn btn-primary">
                                        <span>{{ btnName}}</span>
                                    </button>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div class="modal-footer">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  MODAL Motif -->
<div *ngIf="showAddMotifModal">
    <div class="modal-backdrop"></div>
    <div class="modal modal-show fade show" id="modalAddMotif" tabindex="-1">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="inmodal">
                    <div class="modal-header pb-1">
                        <h3 class="modal-title">Administration</h3>
                        <div class="button-bar control">
                            <i class="fa fa-close" (click)="refresh()"></i>
                        </div>
                    </div>
                    <div class="modal-body pb-0">
                        <h3 class="title-border mt-0">{{ btnName}} un motif d’intervention</h3>
                        <div class="form-block mt-4">
                            <fieldset class="container-fluid">
                                <div class="row">
                                    <div class="col-sm-2 col-form-label" style="font-weight: 600;">Libellé *</div>
                                    <div class="col-sm-10 form-group">
                                        <input type="text" class="form-control" [(ngModel)]="motif.libelle"
                                            maxlength="300" [required]="true"
                                            [ngClass]="{ 'is-invalid': messageEmptyField }" />
                                        <div *ngIf="messageEmptyField" class="invalid-feedback">
                                            Ce champ est obligatoire
                                        </div>
                                    </div>
                                </div>
                                <div class="button-bar control">
                                    <button type="button" (click)="refreshIntervention()" class="btn btn-default ">
                                        Annuler
                                    </button>
                                    <button type="button" (click)="saveMotif()" class="btn btn-primary">
                                        <span>{{ btnName}}</span>
                                    </button>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div class="modal-footer">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="showDeleteMotifModal">
    <div class="modal-backdrop"></div>
    <div class="modal modal-show fade show" id="modalDeleteMotif" tabindex="-1">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="inmodal">
                    <div class="modal-header pb-1">
                        <h3 class="modal-title">Administration</h3>
                        <div class="button-bar control">
                            <i class="fa fa-close" (click)="refresh()"></i>
                        </div>
                    </div>
                    <div class="modal-body pb-0">
                        <h3 class="title-border mt-0">Voulez-vous supprimer le motif d'intervention ?
                        </h3>

                        <div class="form-block mt-4">
                            <fieldset class="container-fluid">
                                <div class="row">
                                    <div class="col-sm-2 col-form-label" style="font-weight: 600;">Libellé *</div>
                                    <div class="col-sm-10 form-group">
                                        <input type="text" class="form-control" [(ngModel)]="motif.libelle" readonly
                                            maxlength="300" />
                                    </div>
                                </div>
                                <div class="button-bar control">
                                    <button type="button" (click)="refresh()" class="btn btn-default"> Annuler
                                    </button>
                                    <button type="button" (click)="deleteModif()" class="btn btn-primary">
                                        <span>{{ btnName}}</span>
                                    </button>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div class="modal-footer">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  MODAL Resolution -->
<div *ngIf="showAddResolutiontModal">
    <div class="modal-backdrop"></div>
    <div class="modal modal-show fade show" id="modalAddResolution" tabindex="-1">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="inmodal">
                    <div class="modal-header pb-1">
                        <h3 class="modal-title">Administration</h3>
                        <div class="button-bar control">
                            <i class="fa fa-close" (click)="refresh()"></i>
                        </div>
                    </div>
                    <div class="modal-body pb-0">
                        <h3 class="title-border mt-0">{{btnName}} une resolution d’intervention</h3>

                        <div class="form-block mt-4">
                            <fieldset class="container-fluid">
                                <div class="row">
                                    <div class="col-sm-2 col-form-label" style="font-weight: 600;">Libellé *</div>
                                    <div class="col-sm-10 form-group">
                                        <input type="text" class="form-control" [(ngModel)]="resolution.libelle"
                                            maxlength="100" [required]="true"
                                            [ngClass]="{ 'is-invalid': messageEmptyField }" />
                                        <div *ngIf="messageEmptyField" class="invalid-feedback">
                                            Ce champ est obligatoire
                                        </div>
                                    </div>
                                </div>
                                <div class="button-bar control">
                                    <button type="button" (click)="refreshResolution()" class="btn btn-default ">
                                        Annuler
                                    </button>
                                    <button type="button" (click)="saveResolution()" class="btn btn-primary">
                                        <span>{{btnName}}</span>
                                    </button>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div class="modal-footer">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="showDeleteResolutionModal">
    <div class="modal-backdrop"></div>
    <div class="modal modal-show fade show" id="modalDeleteResolution" tabindex="-1">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="inmodal">
                    <div class="modal-header pb-1">
                        <h3 class="modal-title">Administration</h3>
                        <div class="button-bar control">
                            <i class="fa fa-close" (click)="refresh()"></i>
                        </div>
                    </div>
                    <div class="modal-body pb-0   mb-4">
                        <h3 class="title-border mt-0">Voulez-vous supprimer la resolution d'intervention ?
                        </h3>
                        <div class="form-block ">
                            <fieldset class="container-fluid">
                                <div class="row">
                                    <div class="col-sm-2 col-form-label" style="font-weight: 600;">Libellé *</div>
                                    <div class="col-sm-10 form-group">
                                        <input type="text" class="form-control" [(ngModel)]="resolution.libelle"
                                            readonly maxlength="100" />
                                    </div>
                                </div>
                                <div class="button-bar control">
                                    <button type="button" (click)="refresh()" class="btn btn-default"> Annuler
                                    </button>
                                    <button type="button" (click)="deleteResolution()" class="btn btn-primary">
                                        <span>{{btnName}}</span>
                                    </button>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div class="modal-footer">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  MODAL Groupe -->
<div *ngIf="showAddGroupUserstModal">
    <div class="modal-backdrop"></div>
    <div class="modal modal-show fade show" id="modalAddGroupUsers" tabindex="-1">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="inmodal">
                    <div class="modal-header pb-1">
                        <h3 class="modal-title">Administration</h3>
                        <div class="button-bar control">
                            <i class="fa fa-close" (click)="refresh()"></i>
                        </div>
                    </div>
                    <div class="modal-body pb-0">
                        <h3 class="title-border mt-0">{{btnName}} un service du destinataire</h3>
                        <div class="form-block mt-4">
                            <fieldset class="container-fluid">
                                <!--                                 <div class="row">
                                    <div class="col-sm-2 col-form-label" style="font-weight: 600;">Libellé *
                                    </div>
                                    <div class="col-sm-10 form-group">
                                        <input type="text" class="form-control" [(ngModel)]="groupe.libelleCourt"
                                            maxlength="100" (keyup)="changeCheck()" [required]="true"
                                            [ngClass]="{ 'is-invalid': messageEmptyField }"/>
                                        <div *ngIf="messageEmptyField" class="invalid-feedback">
                                                Ce champ est obligatoire
                                        </div>
                                    </div>
                                </div> -->
                                <div class="row">
                                    <div class="col-sm-2 col-form-label" style="font-weight: 600;">Libellé *
                                    </div>
                                    <div class="col-sm-10 form-group">
                                        <input type="text" class="form-control" [(ngModel)]="groupe.libelleLong"
                                            maxlength="100" (keyup)="changeCheck()" [required]="true"
                                            [ngClass]="{ 'is-invalid': messageEmptyField }" />
                                        <div *ngIf="messageEmptyField" class="invalid-feedback">
                                            Ce champ est obligatoire
                                        </div>
                                    </div>
                                </div>
                                <div class="button-bar control">
                                    <button type="button" (click)="refresh()" class="btn btn-default "> Annuler
                                    </button>
                                    <button type="button" (click)="saveHotGroupUsers()" class="btn btn-primary">
                                        <span>{{btnName}}</span>
                                    </button>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div class="modal-footer">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="showDeleteGroupUsersModal">
    <div class="modal-backdrop"></div>
    <div class="modal modal-show fade show" id="modalDeleteGroupUsers" tabindex="-1">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="inmodal">
                    <div class="modal-header pb-1">
                        <h3 class="modal-title">Administration</h3>
                        <div class="button-bar control">
                            <i class="fa fa-close" (click)="refresh()"></i>
                        </div>
                    </div>
                    <div class="modal-body pb-0   mb-4">
                        <h3 class="title-border mt-0">Voulez-vous supprimer le service du destinataire ?
                        </h3>
                        <div class="form-block ">
                            <fieldset class="container-fluid">
                                <!--                                 <div class="row">
                                    <div class="col-sm-2 col-form-label" style="font-weight: 600;">Libellé *
                                    </div>
                                    <div class="col-sm-10 form-group">
                                        <input type="text" class="form-control" [(ngModel)]="groupe.libelleCourt"
                                            readonly maxlength="100" />
                                    </div>
                                </div> -->
                                <div class="row">
                                    <div class="col-sm-2 col-form-label" style="font-weight: 600;">Libellé *
                                    </div>
                                    <div class="col-sm-10 form-group">
                                        <input type="text" class="form-control" [(ngModel)]="groupe.libelleLong"
                                            readonly maxlength="100" />
                                    </div>
                                </div>
                                <div class="button-bar control">
                                    <button type="button" (click)="refresh()" class="btn btn-default"> Annuler
                                    </button>
                                    <button type="button" class="btn btn-primary" (click)="deleteHotGroupUsers()">
                                        <span>{{btnName}}</span>
                                    </button>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div class="modal-footer">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>