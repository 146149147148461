import { Component } from '@angular/core';

@Component({
    selector: 'careweb-column-comment-tooltip',
    template: `
        <p  container="body"
            [tooltip]="tooltipValue"
            placement="bottom"
            containerClass="tooltip-grid"
            class="overflow-hidden text-truncate rappro-tooltip"
        >
            {{value}}
        </p>
    `
} )
export class ColumnCommentTooltipComponent {
    value: string;
    tooltipValue = '';
    constructor() {
    }

    agInit(params: any): void {

        if (params.colDef.field === 'commentaire') {
            this.tooltipValue = params.data.pointerKeyword ? params.data.commentaire + ' : ' + params.data.pointerKeyword
                : params.data.commentaire;
        }
        if(params.colDef.field === 'label') {
                this.tooltipValue = params.data.label;
        }
        this.value = params.value;
    }
}