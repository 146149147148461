import { AfterViewInit, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { BsModalService } from 'ngx-bootstrap/modal';
import { isNullOrUndefined } from 'util';
import { AddRegleParsingXmlComponent } from './add-regle-parsing-xml/add-regle-parsing-xml.component';

/*
	Allow to render icons on Organisme table
*/

@Component({
	selector: 'careweb-abonnement-preview',
	template: `
      <i *ngIf="params.action !== 'showNumber'" 
		 [hidden]="hide"
        [ngClass]="iconClass"
        container="body"
        (click)="reglesParsingActionClick()"
        [tooltip]="tooltip"
        containerClass="tooltip-grid"
	  ></i>
	  <span *ngIf="params.action === 'showNumber'"
		 [hidden]="hide"
		 class = "p-relative"
		 container="body"
		 containerClass="tooltip-grid"
	  >{{params.value}}</span>
    `
})
export class ReglesParsingPreviewComponent implements ICellRendererAngularComp, AfterViewInit {
	params: any;
	iconClass: string;
	tooltip = '';
	hide = false;
	showConfirmModal = false;
	modalMessage: string;

	constructor(    private modalService: BsModalService,
    ) {}

	agInit(params: any): void {
		this.params = params;
	}

	ngAfterViewInit() {
		const cellParams = this.params;
		if (cellParams.data) {
			if (!isNullOrUndefined(cellParams.iconClass)) {
				this.iconClass = cellParams.iconClass;
			}

			if (!isNullOrUndefined(cellParams.action)) {
				if (cellParams.action === 'delete') {
					this.tooltip = 'Supprimer la régle de parsing';
				} else if (cellParams.action === 'edit') {
					this.tooltip = "Modifier la régle de parsing";
				} else if (cellParams.action === 'showNumber') {
					this.tooltip = this.params.value.join(",");
				}
			}
		}
	}

	refresh(): boolean {
		return false;
	}

	reglesParsingActionClick(): void {
		if (
			!isNullOrUndefined(this.params.action) &&
			this.params.action === 'edit'
		) {
			let initialState = {
                isEditing:true,
                regleToModify:this.params.data
              };
              this.modalService.show(AddRegleParsingXmlComponent, {
                initialState,
                class: 'modal-xl'
              });
		}
	}
}
