import { HttpErrorResponse } from '@angular/common/http';

/** Fonctions utilitaires pour étudier les réponse d'erreur HTTP. */
export class HttpErrorAnalyzer {
    constructor(private error: HttpErrorResponse) {}

    private isClientSideError(): boolean {
        return this.error.error instanceof ErrorEvent;
    }

    /** Indique si une réponse HTTP est une erreur xxx. */
    private isHttpStatus(status: number): boolean {
        return !this.isClientSideError() && this.error.status === status;
    }

    /** Indique si une réponse HTTP est une erreur 404. */
    isHttpNotFound(): boolean {
        return this.isHttpStatus(404);
    }

    /** Indique si une réponse HTTP est une erreur 403. */
    isHttpForbidden(): boolean {
        return this.isHttpStatus(403);
    }

    /** Indique si une réponse HTTP est une erreur 401. */
    isHttpNotAuthenticated(): boolean {
        return this.isHttpStatus(401);
    }

    /** Indique si une réponse HTTP est une erreur 429. */
    isHttpTooManyRequest(): boolean {
        return this.isHttpStatus(429);
    }

    /** Indique si une réponse HTTP est une erreur 503. */
    isHttpServiceUnavailable(): boolean {
        return this.isHttpStatus(503);
    }
}
