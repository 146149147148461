import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Injectable()
export class SelAccessGuard implements CanActivate {
	constructor(private authSvc: AuthService) {}

	canActivate() {
		return this.hasAccess();
	}

	hasAccess() {
		return this.authSvc.userAuthorized('ADMIN_SEL') || this.authSvc.userAuthorized('ADMIN');
	}
}
