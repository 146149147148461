import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';

@Component({
  selector: 'careweb-detail-cell-renderer',
  template: `
    <div class="p-relative">
        <div class="m-3">
            <div><label>Libellé :</label></div>
            <div><textarea class="form-control-input form-content-detail-grid" readonly rows="2" value="{{ firstRecord.label }}"></textarea></div>
        </div>
        <div class="m-3">
            <div><label>Notes :</label></div>
            <div><textarea class="form-control-input form-content-detail-grid" readonly rows="4" value="{{ firstRecord.notes }}"></textarea></div>
        </div>
    </div>
  `,
})
export class RappDetailVrtCellRendererComponent implements ICellRendererAngularComp {
  firstRecord: any;

  // called on init
  agInit(params: any): void {
    this.firstRecord = params.data;
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    return false;
  }
}