import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(private authenticationService: AuthService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((err) => {
				// If error response 401 or 403 logout automatically
				if ([ 401, 403, 404 ].indexOf(err.status) !== -1) {
					this.authenticationService.logout();
					 //location.reload();
				}

				const error = err.error || err.error.message || err.statusText;
				return throwError(error);
			})
		);
	}
}
