import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { VersionModel } from "../../shared/model/storage.model";
import { NouveautesService } from './service/nouveautes.service';

@Component({
	selector: 'careweb-app-nouveautes',
	templateUrl: './nouveautes.component.html',
	styleUrls: [ './nouveautes.component.scss' ]
})
export class NouveautesComponent implements OnInit {
	releaseNotes: string = null;
	contentTitle: string;
	selectedTitle: string;
	lines: string[] = [];
	subTitle: string;
	versions: VersionModel[] = [];
	
	constructor(private nouveautesService: NouveautesService, private toastr: ToastrService) {}

	ngOnInit() {
		this.nouveautesService.getReleaseNotes().subscribe(
			(data) => {
				this.releaseNotes = data;
				this.subTitle = data.split('## **')[0];
				this.lines = this.releaseNotes.split("\n");
				this.selectedTitle = this.extractVersions(this.lines)[0].title;
				this.contentTitle = this.extractVersions(this.lines)[0].content;

			},
			(error) => {
				this.toastr.error('Une erreur est survenue lors de téléchargement du fichier ', 'Release Notes');
				console.log('Error occured while loading Realease notes ==> ' + error);
			}
		);
	}

	extractVersions(lines: string[]){
		let currentVersion: VersionModel | null = null;
		let currentContent = '';
		const targetIndex = lines.findIndex(line => line.startsWith("## **VERSION"));
		lines = lines.slice(targetIndex);
		for (const line of lines){
			if(line.startsWith('## **VERSION')){
				if(currentVersion){
					currentVersion.content = currentContent.trim();
					this.versions.push(currentVersion);
					currentContent = '';
				}
				const titleMatch = line.match(/## \*\*VERSION (.+?)\*\*/);
				if (titleMatch){
					const versionTitle = titleMatch[1];
					currentVersion = {
						title: versionTitle,
						content: ''
					};
				}
			}
			currentContent += line + '\n';
		}
		if(currentVersion){
			currentVersion.content = currentContent.trim();
			this.versions.push(currentVersion);
		}
		return this.versions;
	}
	
	onSelectTitle(){
		const selectedVersion = this.versions.find(version => version.title === this.selectedTitle);
		this.contentTitle = selectedVersion.content;
	}
}