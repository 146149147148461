export class EtablissementDataRaw {
	idEtablissement:Number;
	idUser: Number;
	raisonSociale: String;
	adresse1: String;
	adresse2: String;
	codepostal: String;
	ville: String;
	telephone: String;
	fax: String;
	email: String;
}
