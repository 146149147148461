<div>
    <div class="modal modal-show fade show" id="modalAddContact" tabindex="-1">
        <div class="modal-dialog" role="document">
            <div class="modal-content modal-width">
                <div class="inmodal">
                    <div class="modal-header pb-1">
                        <button class="close" name="btn_display_filter" data-dismiss="modal" type="button"
                                title="Fermer" (click)="closeModal()"><span aria-hidden="true">×</span></button>
                    </div>
                    <div class="modal-body pb-0">
                        <div class="alert alert-info" role="alert">
                            <span >
                                <i class="fa fa-info-circle info"></i> <b> Activation du compte utilisateur</b>

                            </span>
                            <div class="mt-2">
                                <p *ngIf="nbrInactifPs > 1">Le ou les comptes utilisateurs rattachés à ce PS sont inactifs, veuillez activer au moins un utilisateur.</p>
                                <p *ngIf="nbrInactifPs === 1">Le compte utilisateur rattaché à ce PS est inactif, veuillez l'activer.</p>
                            </div>
                        </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <div class="button-bar control">
                            <button type="button" (click)="closeModal()"  class="btn btn-primary"> Fermer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
