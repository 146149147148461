import { RouteHistoryService } from './../../../shared/route-history.service';
import { DatePipe, Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { Component, OnInit, AfterViewInit, ChangeDetectorRef, AfterContentInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isEmpty, isEqual, isInteger } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import { AuthService } from '../../../core/services/auth.service';
import { OctService } from '../../../core/services/oct.service';
import { ScrollTopService } from '../../../core/utilities/scroll-top.service';
import { EditeurDataRaw } from '../../../data/editeurDataRaw';
import { EtablissementDataRaw } from '../../../data/EtablissementDataRaw';
import { LogicielDataRaw } from '../../../data/logicielDataRaw';
import { OctPsDataRaw } from '../../../data/octpsDataRaw';
import { PsDataRaw } from '../../../data/psDataRaw';
import { PsDelegueDataRaw } from '../../../data/PsDelegueDataRaw';
import { SiteEmissionDataRaw } from '../../../data/siteEmissionDataRaw';
import { TypePsDataRaw } from '../../../data/typePsDataRaw';
import { User } from '../../../data/userDataRaw';
import { EditeurService } from '../../editeurs/services/editeurs.service';
import { LogicielsService } from '../../editeurs/services/logiciels.service';
import { PsService } from '../ps.service';
import { EtablissementService } from '../service/etablissement.service';
import { OctPsService } from '../service/octps.service';
import { PsDelegueService } from '../service/ps-delegue.service';
import { SiteEmissionService } from '../service/site-emission.service';
import { TypePsService } from '../service/typeps.service';
import { PsDelegueComponent } from './ps-attache/ps-delegue.component';
import { PsEditeurComponent } from './ps-editeur/ps-editeur.component';
import { PsEtablissementComponent } from './ps-etablissement/ps-etablissement.component';
import { PsLogicielComponent } from './ps-logiciel/ps-logiciel.component';
import { PsSiteEmissionNouveauComponent } from './ps-site-emission/ps-site-emission-nouveau/ps-site-emission-nouveau.component';
import { PsSiteEmissionComponent } from './ps-site-emission/ps-site-emission.component';
import { PsUserEditComponent } from './ps-user/ps-edit-user/ps-user-edit.component';
import { PsUserComponent } from './ps-user/ps-user.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { OrgStateService } from '../../sel/org-state.service';
import { RapprochementComptePsService } from '../../rapprochement/rapprochement.service';
import { RappComptePsDataRaw } from '../../../data/rappComptePsDataRaw';
import * as _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UsersService } from './../../users/users.service';
import { MapEmptyValueToNull } from '../../../core/utilities/map-empty-value-to-null';
import { PsModalComponent } from "../../ps-modal/ps-modal.component";
import { EbicsService } from '../service/ebics.service';
import { EbicsParams } from '../../../data/ebicsParams';
import { AddAccountEbicsComponent } from '../../abonnement-ebics/add-account-ebics/add-account-ebics.component';
import { TypeConnexionRapprochement } from '../../../data/TypeConnexionRapprochement';
import { HotlineFilterRaw } from "../../../data/filters/hotlineFilterRaw";
import { EditPsEtablissementComponent } from './ps-etablissement/edit-ps-etablissement/edit-ps-etablissement.component';

@Component({
	selector: 'careweb-app-fiche-ps',
	templateUrl: './fiche-ps.component.html',
	styleUrls: ['./fiche-ps.component.scss']
})
export class FichePsComponent implements OnInit, AfterViewInit, AfterContentInit {
	currentJustify = 'left';
	siteEmissionPs: SiteEmissionDataRaw[];
	typeModelOpen: String;
	showConfirmModal: boolean = false;
	modalMessage: string;
	labelValidButtonConfirmModal: string;
	labelCancelButtonConfirmModal: string;
	submitted = false;
	ebicsParamsExist: Boolean = false;
	isEbicsDesactive: Boolean = false;
	oxlinAccountExist: Boolean = false;
	typeConnexionBancaire: string = null;
	currentOct;
	createPsComponent = true;
	isNewPs = false;
	psForm: UntypedFormGroup;
	ebicsForm: UntypedFormGroup;
	psSelForm: UntypedFormGroup;
	currentIdPs;
	listTypePs: TypePsDataRaw[];
	bordereauxCaisseArray: any = [{ value: 1, viewValue: 'Oui' }, { value: 0, viewValue: 'Non' }];
	svArray: any = [{ value: true, viewValue: 'Oui' }, { value: false, viewValue: 'Non' }];
	actifArray: any = [{ value: true, viewValue: 'Oui' }, { value: false, viewValue: 'Non' }];
	cnxBankArray: any = [{ value: 'OXLIN', viewValue: 'OXLIN' }, { value: 'EBICS', viewValue: 'EBICS' }];
	loiArray: any = [{ value: true, viewValue: 'Oui' }, { value: false, viewValue: 'Non' }];
	carateristiqueTransmissionArray: any = [
		{ value: 'REEL', viewValue: 'En réel' },
		{ value: 'ATTENTE', viewValue: 'En attente' }
	];
	modePushArray: any = [{ value: true, viewValue: 'Oui' }, { value: false, viewValue: 'Non' }];
	aidePerenneArray: any = [
		{ value: true, viewValue: "Oui (Non supporté par l'OCT, la valeur est forcée)" },
		{ value: false, viewValue: 'Non' }
	];
	frequenceEnvoieCaisseArray: any = [
		{ value: 'QUOTIDIENNE', viewValue: 'Quotidienne' },
		{ value: 'HEBDOMADAIRE', viewValue: 'Hebdomadaire' }
	];
	listEbicsOrderType: any = [
		{ value: 'FDL', viewValue: 'FDL' },
	];
	typeUserArray = [
		{ value: '1', viewValue: 'Administrateur' },
		{ value: '3', viewValue: 'Hotline' },
		{ value: '2', viewValue: 'Utilisateur PS' },
		{ value: '4', viewValue: 'Administrateur SEL' }
	];
	subscription: Subscription;
	etablissementPs: EtablissementDataRaw;
	usersPs: Array<User>;
	mainUsers: Array<User>;
	title: string;
	showBordereauCaisse: Boolean = true;
	showFreqEnvoi: Boolean = true;
	showEbicsForm: Boolean = false;
	showOxlinForm: Boolean = true;
	showCaractTrans: Boolean = true;
	showSv: Boolean = true;
	showLoi: Boolean = true;
	showModePush: Boolean = true;
	datePipe: DatePipe;
	enabledOngletLogiciel: Boolean = true;
	enabledOngletBal: Boolean = true;
	enabledOngletSel: Boolean = true;
	enabledOngletRappBanc: Boolean = true;
	userDeleted: User;
	siteEmissionDeleted: SiteEmissionDataRaw;
	editeurPs: EditeurDataRaw;
	logicielPs: LogicielDataRaw;
	raisonSocialeEditeur: string;
	listPsAssocie: Array<PsDelegueDataRaw>;
	listPsDelegue: Array<PsDelegueDataRaw>;
	oxlinUserAccounts: any[];
	oxlinUserConnections: any[];
	oxlinUserConnAcc: any[];
	rappComptePs: RappComptePsDataRaw;
	ebicsParams: EbicsParams;
	cguAccepted: boolean = true;
	isOxlinPageLoaded: boolean = false;
	typeTransportArray = [
		{ value: 1, code: 'MAIL', viewValue: 'Mail' },
		{ value: 2, code: 'X_MODEM', viewValue: 'X Modem' },
		{ value: 3, code: 'THALCOM', viewValue: 'Thalcom' }
	];
	psSuperviseDeleted: any;
	ngUnsubscribe = new Subject<void>();
	existInSEL: boolean = false;
	activities: Array<Object>;
	agreements: Array<Object>;
	subscriptionEvents: Array<Object>;
	showError: boolean = false;
	defaultSV;
	actualOCT;
	defaultLoi;
	openedModal: BsModalRef;
	octCodsListSubject: BehaviorSubject<String> = new BehaviorSubject<String>(null);
	octCodsList: String[] = [];
	initalValuesPs: any;
	active: number = 1;
	comptesAssocies: any;
	activeTab = 1;
	typeConnexionRapprochement: any = '';
	filters = new HotlineFilterRaw();
	isLoadingTypeRappro: boolean = true; // Initially set loading to true
	lastPsConsulted: any= [];
	constructor(
		private activatedRoute: ActivatedRoute,
		private location: Location,
		private typeServicePsSVC: TypePsService,
		private psService: PsService,
		private octPsService: OctPsService,
		private formBuilder: UntypedFormBuilder,
		private octSvc: OctService,
		private editeurService: EditeurService,
		private logicielsService: LogicielsService,
		private toastr: ToastrService,
		private siteEmissionService: SiteEmissionService,
		private etablissementSVC: EtablissementService,
		private modalService: BsModalService,
		private scrollTopService: ScrollTopService,
		private psDelegueSVC: PsDelegueService,
		private authSvc: AuthService,
		private router: Router,
		private orgStateService: OrgStateService,
		private rappComptePsService: RapprochementComptePsService,
		private routeHistory: RouteHistoryService,
		private changeDetectorRef: ChangeDetectorRef,
		private usersService: UsersService,
		private mapEmptyValueToNull: MapEmptyValueToNull,
		private ebicsService: EbicsService,

	) {
		this.usersPs = [];
		this.mainUsers = [];
		this.editeurPs = new EditeurDataRaw();
		this.logicielPs = new LogicielDataRaw();
		this.listTypePs = new Array<TypePsDataRaw>();
		this.etablissementPs = new EtablissementDataRaw();
		this.siteEmissionPs = new Array<SiteEmissionDataRaw>();
	}

	ngOnInit() {


		this.scrollTopService.setScrollTop();
		this.datePipe = new DatePipe('fr-FR');
		this.activatedRoute.paramMap.subscribe(params => {
			this.currentIdPs = params.get('id');
			const isNewPsString = params.get('isNewPs');
			this.isNewPs = isNewPsString === 'true';
		});
		this.getCurrentOct();
		//this.getTypePsList();
		this.validForm();
		this.initPsSelForm();
		this.typeServicePsSVC.getTypePSList().then((data) => {
			if (data) {
				this.listTypePs = data.filter((type) => type.idTypePs !== 0);
				this.psForm
					.get('typePs')
					.setValue(this.listTypePs.find((ps) => ps.libelleLong === 'Cliniques privées et hôpitaux'));
				this.getCurrentOct();
				if (this.currentIdPs) {
					// Update
					this.createPsComponent = false;
					this.getPsByIdPs(this.currentIdPs);
					this.getCurrentOctWithPs();
				} else {
					// CREATION
					this.createPsComponent = true;
					this.enabledOngletLogiciel = false;
					this.enabledOngletBal = false;
					this.enabledOngletSel = false;
					this.enabledOngletRappBanc = false;
					this.currentOct = JSON.parse(localStorage.getItem('octCurrent'));
					this.showFields(this.currentOct);
					sessionStorage.setItem('fiche-ps-tab', '1');
					this.getActiveTab();
				}
				/* 				this.psForm.patchValue({
					typePs: this.listTypePs.find((typePs) => typePs.idTypePs == 12)
				}); */
			}
		});
		this.getActiveTab();
		if (this.currentIdPs) {
			this.getPsOctsCode(this.currentIdPs);
			this.initialiserEbicsContext(this.currentIdPs, false);
		}
		else {
			if (this.routeHistory?.getPreviousUrl()?.indexOf('suivi-rapp') !== -1 || this.routeHistory?.getPreviousUrl()?.indexOf('abonnements-ebics') !== -1) {
				this.enabledOngletRappBanc = true;
				//this.ctdTabset.select('tab5');
				//console.log("suivi-rapp previous");
				//this.active = 5;
				this.changeDetectorRef.detectChanges();
			}
		}
	}
	public initialiserEbicsContext(idPs: Number, manageRapp: boolean) {
		this.ebicsService.getEbicsByPsId(idPs).then(
			(data) => {

				this.comptesAssocies = data;
				if (manageRapp) {
					this.manageRapp(false);
				}

			}
		)
	}
	findTypeRapproCnx() {
		this.ebicsService.findTypeCnxForPs(this.currentIdPs).subscribe(data => {
			if (data) {
				let typeConnexionRapprochement: TypeConnexionRapprochement;
				typeConnexionRapprochement = data;
				if (typeConnexionRapprochement == TypeConnexionRapprochement.EBICS) {
					this.typeConnexionBancaire = "ebics";

					this.psForm.get('cnxBank')
						.setValue(this.cnxBankArray[1])

					this.showEbicsForm = true;
					this.showOxlinForm = false;
					this.manageRapp(false);
				}
				else if (typeConnexionRapprochement == TypeConnexionRapprochement.OXLIN) {
					this.typeConnexionBancaire = "oxlin";
					this.psForm.get('cnxBank')
					.setValue(this.cnxBankArray[0])
					this.showEbicsForm = false;
					this.showOxlinForm = true;
					this.manageRapp(true);
				}
				this.isLoadingTypeRappro = false; // Set loading to false 
			}
			else{
				this.psForm.get('cnxBank')
				.setValue(this.cnxBankArray[0])
				this.isLoadingTypeRappro = false; // Set loading to false 
			}
		});
	}
	getPsOctsCode(idPs: number) {
		this.psService.getOctsByIdPs(idPs).subscribe(data => {
			if (data) {
				data.forEach((codeOct) => {
					this.octCodsList.push(codeOct);
				});
				localStorage.setItem('psOctsCods', JSON.stringify(this.octCodsList));
			}
		});
	}
	ngAfterContentInit() {
		if (this.typeConnexionBancaire == "ebics") {
			this.manageRapp(false);

		}
		else if (this.typeConnexionBancaire == "oxlin") {
			this.manageRapp(true);
		}
	}
	ngAfterViewInit() {
		if (this.routeHistory?.getPreviousUrl()?.indexOf('suivi-rapp') !== -1) {
			this.enabledOngletRappBanc = true;
			//this.ctdTabset.select('tab5');
			//this.active = 5;
			this.changeDetectorRef.detectChanges();
		}
	}

	onTabChange(event: any) {
		const activeTabId = event.nextId;
		if ((activeTabId == '2' || activeTabId == '3') && this.isNewPs) {
			event.preventDefault();
			this.active = 1;
			const onglet = document.getElementById('onglet_InfoLogiciel');
			setTimeout(() => {
				onglet.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}, 200);
		} else {
			this.active = activeTabId;
		}
		sessionStorage.setItem('fiche-ps-tab', activeTabId);
	}

	getActiveTab() {
		if (sessionStorage.getItem('fiche-ps-tab') && isInteger(parseInt(sessionStorage.getItem('fiche-ps-tab')))) {
			this.active = parseInt(sessionStorage.getItem('fiche-ps-tab'));
			if(this.isNewPs && this.active!=5){
				this.active = 1;
			}
		} else {
			this.active = 1;
		}
	}

	refreshCurrentPage() {
		this.getTypePsList();
		this.validForm();
		if (!this.createPsComponent) {
			// Update
			this.getPsByIdPs(this.currentIdPs);
			this.getCurrentOctWithPs();
		} else {
			// CREATION
			this.getCurrentOct();
			this.showFields(this.currentOct);
		}
	}

	goBack() {
		this.location.back();
	}

	goHotlinePage() {
		this.filters.numeroPs = this.psForm.value.numeroAdeli;
		sessionStorage['hotlineFilters'] = JSON.stringify(this.filters);
		window.open('/careweb-client/hotline', '_blank');
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.ngUnsubscribe.unsubscribe();
	}

	trackByIndex(index, item) {
		return index;
	}

	getPsByIdPs(idPs) {
		this.psService.getPsById(idPs).then((data) => {
			if (data) {
				this.psForm.patchValue({
					numeroAdeli: data.numeroAdeli,
					rpps: data.rpps,
					typePs: this.listTypePs.find((typePs) => typePs.idTypePs == data.idTypePs),
					bordereauCaisse: this.bordereauxCaisseArray.find(
						(bordCaisse) => bordCaisse.value == data.bordereauCaisse
					)
				});
				this.initalValuesPs = this.psForm.value;
				localStorage.setItem('current_ps', JSON.stringify(data));
				//à supprimer
				this.findTypeRapproCnx();
			}
			this.getListPsConsulted();
			localStorage.setItem('numeroPs', data.numeroAdeli.valueOf());
			this.psService.eventPsSelected.emit(data.numeroAdeli.valueOf());
			localStorage.setItem('idPs', idPs);
			if (data.idEtablissement) {
				this.etablissementSVC.getEtablissementById(data.idEtablissement).subscribe((etablissement) => {
					if (etablissement) {
						this.etablissementPs = new EtablissementDataRaw();
						this.etablissementPs.idEtablissement = etablissement.idEtablissement;
						this.etablissementPs.raisonSociale = etablissement.raisonSociale
							? etablissement.raisonSociale
							: '';
						this.etablissementPs.adresse1 = etablissement.adresse1;
						this.etablissementPs.adresse2 = etablissement.adresse2;
						this.etablissementPs.codepostal = etablissement.codepostal;
						this.etablissementPs.ville = etablissement.ville;
						this.etablissementPs.telephone = etablissement.telephone;
						this.etablissementPs.fax = etablissement.fax;
						this.etablissementPs.email = etablissement.email;
					}
				});
			}
			if (data.psUsers) {
				this.usersPs = data.psUsers;
				this.usersService.getMainUsersByIdPs(this.currentIdPs, this.usersPs.map((user: User) => user.idUser)).subscribe((data: User[]) => {
					this.mainUsers = data;
				});
			}
			if (!isNullOrUndefined(data.idSsii)) {
				this.getEditeurById(data.idSsii);
			}
			if (!isNullOrUndefined(data.idLogiciel) && !this.isAdminSel()) {
				this.getLogicielById(data.idLogiciel);
			}
			this.siteEmissionPs = data.siteEmissions.filter((site) => site.idOct == this.currentOct.idOct);

			// get PS details SEL from CIS if the ps exist in SEL DB
			if (!isNullOrUndefined(data.sel) && data.sel == true) {
				this.existInSEL = true;
				this.enabledOngletSel = true;
				this.getPsSelByProvider(data.numeroAdeli);
			}

			if (data.rappComptePs) {
				this.initialiseOxlinUserContext(idPs);
			}
		});

		this.psDelegueSVC.getPsDelegueByIdPs(this.currentIdPs).subscribe((data) => {
			this.listPsDelegue = data;
		});
		this.psDelegueSVC.getPsAssocieByIdPs(this.currentIdPs).subscribe((data) => {
			this.listPsAssocie = data;
		});
	}

	getCurrentOctWithPs() {
		// Get current oct
		if (this.currentOct) {
			this.getOctPsByIdOctAndIdPs();
			this.showFields(this.currentOct);
		}
	}

	getCurrentOct() {
		// Get current oct
		this.currentOct = this.octSvc.getCurrentOctValue
			? this.octSvc.getCurrentOctValue
			: JSON.parse(localStorage.getItem('octCurrent'));
	}

	getTitle() {
		let title = '';
		let actif = '';
		if (this.psForm?.value?.actif) {
			actif = this.formatActif(this.psForm.value.actif.value);
		}
		if (this.createPsComponent) {
			title = 'Ajouter un PS';
		} else {
			title = `Compte PS - ${this.psForm.value.numeroAdeli}  - (${actif})`;
		}
		return title;
	}

	getListPsConsulted() {
		if (!this.createPsComponent) {
			this.psService.loadLastPss();
			this.psService.addPs(this.psForm.value.numeroAdeli)
			this.psService.saveLastPss();
		}
	}
	//--------------- Onglet Informations du compte -------------------

	getTypePsList() {
		this.typeServicePsSVC.getTypePSList().then((data) => {
			if (data) {
				this.listTypePs = data.filter((type) => type.idTypePs !== 0);
			}
		});
	}

	getNomTypeTransport(idTypeTransport) {
		return this.typeTransportArray.find((type) => type.value == idTypeTransport).viewValue;
	}

	showFields(oct) {
		if (oct) {
			switch (oct.code) {
				case 'THA':
					this.showBordereauCaisse = false;
					this.showFreqEnvoi = false;
					this.showCaractTrans = false;
					this.showSv = false;
					this.showLoi = false;
					this.showModePush = false;
					break;
				case 'CPH':
					this.showLoi = false;
					this.showModePush = false;
					break;
				case 'CAM':
					this.showLoi = false;
					this.showModePush = false;
					break;
				case 'ISA':
					this.showLoi = false;
					this.showModePush = false;
					break;
				case 'CCS':
					this.showLoi = false;
					this.showModePush = false;
					break;
				case 'CDS':
					this.showLoi = false;
					this.showModePush = false;
					break;
				case 'CBI':
					this.showLoi = false;
					this.showModePush = false;
					break;
				case 'SAN':
					this.showLoi = false;
					this.showModePush = false;
					break;
				default:
					break;
			}
		}
	}

	formatCivility = (value): string => {
		return value === 1 ? 'M' : 'Mme';
	};

	getTypeUser(value: string) {
		return this.typeUserArray.find((type) => type.value == value);
	}

	removeEtablissement() {
		this.etablissementPs = new EtablissementDataRaw();
	}

	existEtablissementPs() {
		return !isEmpty(this.etablissementPs);
	}

	getOctPsByIdOctAndIdPs() {
		if (this.currentOct) {
			this.octPsService.getPsByIdPsAndIdOct(this.currentOct.idOct, this.currentIdPs).subscribe((data) => {
				if (data) {
					const derniereTransmission = this.formatDate(data.derniereTransmission);
					this.psForm.patchValue({
						frequenceEnvois: data.frequenceEnvois
							? this.frequenceEnvoieCaisseArray.find((freqEnv) => freqEnv.value === data.frequenceEnvois)
							: '',
						caractTrans: this.carateristiqueTransmissionArray.find(
							(carctTrans) => carctTrans.value === data.caracteristiqueTransmission
						),
						sv: this.svArray.find((sv) => sv.value === data.sv),
						actif: this.actifArray.find((actif) => actif.value === data.actif),
						loi: this.loiArray.find((loi) => loi.value === data.loi),
						loiPush: this.modePushArray.find((modePush) => modePush.value === data.loiPush),
						aidePerenne: this.aidePerenneArray.find(
							(aidePerenne) => aidePerenne.value === data.aidePerenne
						),
						derniereTransmission: derniereTransmission,
						addrMailArt: data.addrMailArt,
						addrMailRph: data.addrMailRph
					});

					this.changeSV(this.aidePerenneArray.find((sv) => sv.value === data.sv));
				} else {
					//The current oct don't contains the current ps, so redirect to ps list.
					this.router.navigate(['/404']);
				}
			},
				err => {
					if (err.status === 400) {
						this.router.navigate(['/404'])
					}
				});
		}
	}

	validForm() {
		this.actualOCT = JSON.parse(localStorage.getItem('octCurrent'));
		this.defaultSV = this.getDefaultSV(this.actualOCT);
		this.defaultLoi = this.getDefaultLoi(this.defaultSV);
		this.ebicsForm = this.formBuilder.group({
			ebicsHostId: ['', [Validators.required, Validators.minLength(3)]],
			ebicsUserId: ['', [Validators.required, Validators.minLength(3)]],
			ebicsUrl: ['', [Validators.required, Validators.minLength(3)]],
			bankName: ['', [Validators.required, Validators.minLength(3)]],
			accountRef: ['', [Validators.required, Validators.minLength(3)]],
			ebicsPartnerId: ['', [Validators.required, Validators.minLength(3)]],
			ebicsOrderTypes: [[], [Validators.required]]
		})
		this.psForm = this.formBuilder.group({
			numeroAdeli: [null, [Validators.required, Validators.minLength(9)]],
			rpps: [null, [Validators.minLength(11)]],
			typePs: [ this.listTypePs.find((typePs) => typePs.idTypePs == 12) ],
			//typePs: [],
			bordereauCaisse: [this.bordereauxCaisseArray[1]],
			frequenceEnvois: [this.frequenceEnvoieCaisseArray[0]],
			cnxBank: [this.cnxBankArray[0]],
			caractTrans: [this.carateristiqueTransmissionArray[1]],
			sv: [this.defaultSV],
			actif: [this.actifArray.find((actif) => actif.value === false)],
			loi: new UntypedFormControl({ value: this.defaultLoi, disabled: true }),
			loiPush: new UntypedFormControl({
				value: this.modePushArray.find((loiPush) => loiPush.value == false),
				disabled: true
			}),
			aidePerenne: new UntypedFormControl({
				value: this.aidePerenneArray.find((aidePerenne) => aidePerenne.value == 0),
				disabled: true
			}),
			derniereTransmission: new UntypedFormControl({ value: '', disabled: true }),
			addrMailArt: [''],
			addrMailRph: [''],

		})
	}

	changeSV(event) {
		if (event.value === true) {
			this.psForm.controls.loi.enable();
			this.psForm.controls.loiPush.enable();
		} else {
			this.psForm.controls.loi.disable();
			this.psForm.controls.loiPush.disable();
		}
	}

	formatActif(value) {
		return value === true ? 'ACTIF' : 'INACTIF';
	}

	get formFields() {
		return this.psForm.controls;
	}

	get ebicsFields() {
		return this.ebicsForm.controls;
	}

	get formFieldsValue() {
		return this.psForm.value;
	}
	isAllUsersInactif(list1, list2): boolean {
		const concatList = list1.concat(list2);
		if (concatList.length == 0) {
			return false;
		}
		return concatList.every((utilisateur) => !utilisateur.actif);
	}

	countInactifUsers(list1, list2): number {
		const count1 = list1.reduce((acc, utilisateur) => {
			if (!utilisateur.actif) {
				acc++;
			}
			return acc;
		}, 0);
		const count2 = list2.reduce((acc, utilisateur) => {
			if (!utilisateur.actif) {
				acc++;
			}
			return acc;
		}, 0);
		return count1 + count2;
	}

	onSubmit() {
		this.submitted = true;
		if (this.psForm.valid
			&& !isEqual(this.mapEmptyValueToNull.emptyValueToNull(this.initalValuesPs), this.mapEmptyValueToNull.emptyValueToNull(this.psForm.value))) {

			if (this.createPsComponent) {
				this.psService.checkAdeli(this.formFieldsValue.numeroAdeli).subscribe((data) => {
					if (!isNullOrUndefined(data)) {
						if (data === false) {
							this.psForm.controls.numeroAdeli.setErrors({ checkAdeli: true });
							this.submitted = true;
						} else {
							this.psService
								.getPsByNumAdeli({
									numAdeli: this.formFieldsValue.numeroAdeli
								})
								.subscribe((data) => {
									if (data[0]) {
										const currentOct = JSON.parse(localStorage.getItem('octCurrent'));
										const idPs = data[0].idPs;
										this.octPsService.getPsByIdPsAndIdOct(currentOct.idOct, idPs)
											.subscribe(data => {
												if (data !== null) {
													this.toastr.error('Impossible de créer le PS : PS déjà existant');
												} else {
													this.insertValidForm();
												}
												this.submitted = false;
											});
									} else {
										this.insertValidForm();
									}
								});
						}
					}
				});
			} else {
				this.insertValidForm();
			}
			this.initalValuesPs = this.psForm.value;
			let countUsersPs;
			if (this.isAllUsersInactif(this.usersPs, this.mainUsers) && this.psForm.value.actif.value === true) {
				countUsersPs = this.countInactifUsers(this.usersPs, this.mainUsers);
				this.modalService.show(PsModalComponent, {
					initialState: {
						nbrInactifPs: countUsersPs
					}, class: 'modal-lg'
				});
			}
		}
	}

	insertValidForm() {
		var currentPs: PsDataRaw = new PsDataRaw();
		currentPs.idPs = this.currentIdPs;
		currentPs.numeroAdeli = isNullOrUndefined(this.psForm.value.numeroAdeli)
			? ''
			: (this.psForm.value.numeroAdeli as String).trim();

		currentPs.rpps = isNullOrUndefined(this.psForm.value.rpps) ? '' : (this.psForm.value.rpps as String).trim();
		currentPs.bordereauCaisse = isNullOrUndefined(this.psForm.value.bordereauCaisse)
			? 0
			: this.psForm.value.bordereauCaisse.value;
		currentPs.idTypePs = this.psForm.value.typePs.idTypePs;

		const currentOctPs: OctPsDataRaw = new OctPsDataRaw();
		currentOctPs.actif = isNullOrUndefined(this.psForm.value.actif) ? false : this.psForm.value.actif.value;
		currentOctPs.frequenceEnvois = isNullOrUndefined(this.psForm.value.frequenceEnvois)
			? 0
			: this.psForm.value.frequenceEnvois.value;
		currentOctPs.caracteristiqueTransmission = isNullOrUndefined(this.psForm.value.caractTrans)
			? null
			: this.psForm.value.caractTrans.value;
		currentOctPs.sv = isNullOrUndefined(this.psForm.value.sv) ? null : this.psForm.value.sv.value;
		if (currentOctPs.sv === true) {
			currentOctPs.loi = isNullOrUndefined(this.psForm.value.loi)
				? this.defaultLoi.value
				: this.psForm.value.loi.value;
			const loiPushcurrentOCTPS = this.modePushArray.find((loiPush) => loiPush.value === false);
			currentOctPs.loiPush = isNullOrUndefined(this.psForm.value.loiPush)
				? loiPushcurrentOCTPS.value
				: this.psForm.value.loiPush.value;
		}
		currentOctPs.aidePerenne = isNullOrUndefined(this.psForm.value.aidePerenne)
			? false
			: this.psForm.value.aidePerenne.value;
		currentOctPs.addrMailArt = isNullOrUndefined(this.psForm.value.addrMailArt)
			? ''
			: (this.psForm.value.addrMailArt as String).trim();
		currentOctPs.addrMailRph = isNullOrUndefined(this.psForm.value.addrMailRph)
			? ''
			: (this.psForm.value.addrMailRph as String).trim();

		this.psForm.patchValue({
			addrMailArt: currentOctPs.addrMailArt,
			addrMailRph: currentOctPs.addrMailRph
		});

		let currentOctPsList: Array<OctPsDataRaw> = new Array<OctPsDataRaw>();
		currentOctPsList.push(currentOctPs);
		currentPs.octPsDtos = currentOctPsList;
		currentPs.rappVisible = this.createPsComponent ? true : this.initalValuesPs.rappVisible;

		if (this.createPsComponent) {
			this.createPs(currentPs);
		} else {
			this.updatePs(currentPs);
		}
	}

	createPs(currentPs) {
		this.psService.savePs(this.currentOct.idOct, currentPs).subscribe((data) => {
			this.toastr.success('Le compte PS a été ajouté avec succès');
			this.currentIdPs = data.idPs;
			this.enabledOngletLogiciel = true;
			this.enabledOngletBal = true;
			this.enabledOngletSel = true;
			this.enabledOngletRappBanc = true;
			this.createPsComponent = false;
			this.isNewPs = true;
			this.router.navigate(['ps-details', this.currentIdPs, { isNewPs: this.isNewPs }]);
		});
	}

	updatePs(currentPs: PsDataRaw) {
		this.psService.updatePsAndOctPs(this.currentOct.idOct, this.currentIdPs, currentPs).subscribe((data) => {
			this.toastr.success('Le compte PS a été mis à jour avec succès');
		});
	}

	getLengthUserPs() {
		return (isNullOrUndefined(this.usersPs) ? 0 : this.usersPs.length) + (isNullOrUndefined(this.mainUsers) ? 0 : this.mainUsers.length);
	}

	getLengthEtablissementPs() {
		return isNullOrUndefined(this.etablissementPs) ? 0 : isEmpty(this.etablissementPs) ? 0 : 1;
	}

	getLengthPsDelegue() {
		return isNullOrUndefined(this.listPsDelegue) ? 0 : isEmpty(this.listPsDelegue) ? 0 : this.listPsDelegue.length;
	}

	getLengthPsAssocie() {
		return isNullOrUndefined(this.listPsAssocie) ? 0 : isEmpty(this.listPsAssocie) ? 0 : this.listPsAssocie.length;
	}

	//--------------- End Onglet Informations du compte ---------------

	//--------------- Onglet Informations du logiciel -------------------

	existEditeurPs() {
		return !isEmpty(this.editeurPs);
	}

	existLogicielPs() {
		return !isEmpty(this.logicielPs);
	}

	getEditeurById(idEditeur) {
		this.editeurService.getEditeurById({ idEditeur: idEditeur }).subscribe((data) => {
			this.editeurPs = data;
		});
	}

	getLogicielById(idLogiciel) {
		this.logicielsService.getLogicielById(idLogiciel).subscribe((data) => {
			this.logicielPs = data;
			if (this.logicielPs) {
				if (this.logicielPs.editeur) {
					this.raisonSocialeEditeur = this.logicielPs.editeur.raisonSociale;
				} else {
					this.raisonSocialeEditeur = data.editeurRaisonSociale;
				}
			}
		});
	}

	getLengthEditeurPs() {
		return isNullOrUndefined(this.editeurPs) ? 0 : isEmpty(this.editeurPs) ? 0 : 1;
	}

	getLengthLogicielPs() {
		return isNullOrUndefined(this.logicielPs) ? 0 : isEmpty(this.logicielPs) ? 0 : 1;
	}

	isReadonlyNumeroAdeli() {
		return !this.createPsComponent;
	}

	isReadonlyRPPS() {
		return !this.createPsComponent;
	}

	//--------------- End Onglet Informations du logiciel ---------------

	// ------------------ Modal ----------------------
	refresh() {
		this.closeModal();
	}

	closeModal() {
		this.showConfirmModal = false;
	}

	public showModal(showModal: boolean) {
		this.showConfirmModal = showModal;
	}

	public confirmModal() {
		switch (this.typeModelOpen) {
			case 'user':
				this.psService
					.detachPsOfUser(this.currentIdPs, this.userDeleted.idUser)
					.then((data) => {
						this.toastr.success("L'utilisateur a été supprimé avec succès");
						this.refreshCurrentPage();
					})
					.catch((err) => {
						this.showModal(false);
						this.toastr.error(
							"Une erreur est survenue lors de la suppression de l'utilisateur !!",
							"Suppression de l'utilisateur"
						);
					});
				break;
			case 'etablissement':
				this.psService
					.detachPsOfEtablissement(this.currentIdPs)
					.then((data) => {
						this.toastr.success("L'établissement a été supprimé avec succès");
						this.refreshCurrentPage();
						this.etablissementPs = null;
					})
					.catch((err) => {
						this.showModal(false);
						this.toastr.error(
							"Une erreur est survenue lors de la suppression de l'établissement !!",
							"Suppression de l'établissement"
						);
					});
				break;
			case 'logiciel':
				this.psService
					.detachPsOfLogiciel(this.currentIdPs)
					.then((data) => {
						//console.log(this.logicielPs)
						//if (!this.existLogicielPs()) {
						this.toastr.success('Le logiciel a été supprimé avec succès');
						//}
						this.toastr.success("L'éditeur a été supprimé avec succès");

						this.refreshCurrentPage();
						this.logicielPs = null;
						this.editeurPs = null;
					})
					.catch((err) => {
						this.showModal(false);
						this.toastr.error(
							'Une erreur est survenue lors de la suppression de logiciel !!',
							'Suppression de logiciel'
						);
					});
				break;
			case 'editeur':
				this.psService
					.detachPsOfEditeur(this.currentIdPs)
					.then((data) => {
						this.toastr.success("L'éditeur a été supprimé avec succès");
						if (this.existLogicielPs()) {
							this.toastr.success('Le logiciel a été supprimé avec succès');
						}

						this.refreshCurrentPage();
						this.editeurPs = null;
						this.logicielPs = null;
					})
					.catch((err) => {
						this.showModal(false);
						this.toastr.error(
							"Une erreur est survenue lors de la suppression de l'éditeur !!",
							"Suppression de l'éditeur"
						);
					});
				break;
			case 'siteemission':
				if (this.labelCancelButtonConfirmModal != null) {
					this.siteEmissionService
						.removeSiteEmissionWithReroute(this.siteEmissionDeleted.idSite)
						.subscribe((data) => {
							if (data === 'OK') {
								this.toastr.success("Le site d'émission a été supprimé avec succès");
								this.refreshCurrentPage();
							} else {
								this.toastr.error(
									"Une erreur est survenue lors de la suppression du site d'émission !!",
									"Suppression du site d'émission"
								);
							}
						}),
						(err) => {
							this.toastr.error(
								"Une erreur est survenue lors de la suppression du site d'émission !!",
								"Suppression du site d'émission"
							);
						};
				}
				break;
			case 'psSupervise':
				this.psDelegueSVC
					.deletePsSuperviseById(this.psSuperviseDeleted)
					.then((data) => {
						this.toastr.success('Le ps supervisé a été supprimé avec succès');
						this.refreshCurrentPage();
						this.logicielPs = null;
					})
					.catch((err) => {
						this.showModal(false);
						this.toastr.error(
							'Une erreur est survenue lors de la suppression de logiciel !!',
							'Suppression de logiciel'
						);
					});
				break;
			case 'activateRappBanc':
				this.createRappBancaireAccount();
				break;
			case 'inactivateRappBanc':
				if (!this.showEbicsForm) {
					this.deleteRappBancaireAccount();
				} else {
					this.desactiverEbicsBanking("ebics");
				}
				break;
			default:
				break;
		}
		this.showModal(false);
	}

	public openModalConfim(type: string, toDeleteObject: any) {
		this.typeModelOpen = type;
		this.labelValidButtonConfirmModal = 'Oui';
		this.labelCancelButtonConfirmModal = 'Non';
		switch (type) {
			case 'user':
				this.modalMessage = 'Voulez-vous détacher cet utilisateur ?';
				this.userDeleted = toDeleteObject;
				this.showConfirmModal = true;
				break;
			case 'etablissement':
				this.modalMessage = 'Voulez-vous détacher cet établissement ?';
				this.userDeleted = toDeleteObject;
				this.showConfirmModal = true;
				break;
			case 'editeur':
				this.modalMessage = 'Voulez-vous détacher cet éditeur ?';
				this.showConfirmModal = true;
				break;
			case 'logiciel':
				this.modalMessage = 'Voulez-vous détacher ce logiciel ?';
				this.showConfirmModal = true;
				break;
			case 'comptebank':
				this.modalMessage = 'Voulez-vous supprimer ce compte bancaire ?';
				this.showConfirmModal = true;
				break;
			case 'siteemission':
				this.siteEmissionDeleted = toDeleteObject;
				let isRerouter = this.siteEmissionPs.find(
					(site) => site.idSiteReroutage === this.siteEmissionDeleted.idSite
				);

				if (this.siteEmissionDeleted.idSiteReroutage != null && this.siteEmissionDeleted.idSiteReroutage != undefined) {
					this.modalMessage =
						'Ce site d\'émission est re-routé vers un autre site, pour le supprimer vous devez détacher les sites de re-routages.';
					this.labelValidButtonConfirmModal = 'OK';
					this.labelCancelButtonConfirmModal = null;
				} else if (isRerouter != null && isRerouter != undefined) {
					this.modalMessage =
						'Ce site d\'émission est utilisé pour re-routage, pour le supprimer vous devez le détacher des autres sites.';
					this.labelValidButtonConfirmModal = 'OK';
					this.labelCancelButtonConfirmModal = null;
				} else {
					this.modalMessage = "Voulez-vous supprimer ce site d'émission ?";
				}

				this.siteEmissionService.isSiteEmissionAttach(this.siteEmissionDeleted.idSite).subscribe((data) => {
					if (data == 'OK') {
						this.showConfirmModal = true;
					} else {
						this.showConfirmModal = false;
						this.toastr.error(data);
					}
				});
				break;
			case 'psSupervise':
				this.modalMessage = 'Voulez-vous détacher ce PS supervisé ?';
				this.showConfirmModal = true;
				this.psSuperviseDeleted = toDeleteObject.id;
				break;
			case 'activateRappBanc':
				this.modalMessage = 'Voulez-vous activer le service de Rapprochement Bancaire ?';
				this.labelValidButtonConfirmModal = 'Activer';
				this.labelCancelButtonConfirmModal = 'Annuler';
				if ((this.showEbicsForm) || this.showOxlinForm) {
					this.showConfirmModal = true;
				}
				break;
			case 'inactivateRappBanc':
				this.modalMessage = 'Voulez-vous désactiver le service de Rapprochement Bancaire ?';
				this.labelValidButtonConfirmModal = 'Désactiver';
				this.labelCancelButtonConfirmModal = 'Annuler';
				this.showConfirmModal = true;
				break;
			default:
				break;
		}
	}

	initPsSelForm() {
		this.psSelForm = this.formBuilder.group({
			civilityCode: [''],
			lastName: [''],
			firstName: [''],
			phoneNumber: [''],
			phoneNumber2: [''],
			faxNumber: [''],
			email: [''],
			streetNo: [''],
			streetType: [''],
			streetName: [''],
			addressLine2: [''],
			postCode: [''],
			town: [''],
			country: ['']
		});
	}

	getPsSelByProvider(providerNumber: any) {
		if (!isNullOrUndefined(providerNumber)) {
			this.orgStateService.getPsDetailsByProvider(providerNumber).subscribe(
				(results) => {
					this.psSelForm.patchValue({
						civilityCode: results.data.civilityCode,
						lastName: results.data.lastName,
						firstName: results.data.firstName,
						phoneNumber: results.data.phoneNumber,
						phoneNumber2: results.data.phoneNumber2,
						faxNumber: results.data.faxNumber,
						email: results.data.email,
						streetNo: results.data.streetNo,
						streetType: results.data.streetType,
						streetName: results.data.streetName,
						addressLine2: results.data.addressLine2,
						postCode: results.data.postCode,
						town: results.data.town,
						country: results.data.country
					});
					// remplir list activities
					this.activities = results.data.activities;
					this.agreements = results.data.agreements;
					this.subscriptionEvents = results.data.subscriptionEvents;
					this.showError = false;
				},
				(err) => {
					this.showError = true;
				}
			);
		}
	}

	public openModal(type, data) {
		let initialState;
		let sub;
		var ctx = this;
		switch (type) {
			case 'addUser':
				initialState = {
					idPs: this.currentIdPs,
					idEtablissement: this.etablissementPs?.idEtablissement
				};
				this.openedModal = this.modalService.show(PsUserComponent, { initialState, class: 'modal-xl' });
				sub = this.modalService.onHide.subscribe(() => {
					this.refreshCurrentPage();
					this.active = 1;
					//this.ctdTabset.select('tab1');
					sub.unsubscribe();
				});
				break;
			case 'editUser':
				initialState = {
					user: data,
					idPs: this.currentIdPs
				};
				this.openedModal = this.modalService.show(PsUserEditComponent, { initialState, class: 'modal-xl' });
				sub = this.modalService.onHide.subscribe(() => {
					this.refreshCurrentPage();
					this.active = 1;
					//this.ctdTabset.select('tab1');
					sub.unsubscribe();
				});
				break;
			case 'editEtablissement':
				initialState = {
					etablissement: data,
					idPs: this.currentIdPs
				};
				this.openedModal = this.modalService.show(EditPsEtablissementComponent, { initialState, class: 'modal-xl' });
				sub = this.modalService.onHide.subscribe(() => {
					this.refreshCurrentPage();
					this.active = 1;
					//this.ctdTabset.select('tab1');
					sub.unsubscribe();
				});
				break;
			case 'etablissement':
				initialState = {
					idPs: this.currentIdPs
				};
				this.openedModal = this.modalService.show(PsEtablissementComponent, {
					initialState,
					class: 'modal-xl'
				});
				sub = this.modalService.onHide.subscribe(() => {
					this.refreshCurrentPage();
					this.active = 1;
					//this.ctdTabset.select('tab1');
					sub.unsubscribe();
				});

				break;
			case 'addAccount':
				initialState = {
					idPs: this.currentIdPs,
				};
				this.openedModal = this.modalService.show(AddAccountEbicsComponent, {
					initialState,
					class: 'modal-xl'
				});
				sub = this.modalService.onHide.subscribe(() => {
					this.active = 5;
					this.initialiserEbicsContext(this.currentIdPs, true);

					sub.unsubscribe();
				});

				break;
			case 'editeur':
				initialState = {
					idPs: this.currentIdPs
				};
				this.openedModal = this.modalService.show(PsEditeurComponent, { initialState, class: 'modal-xl' });
				sub = this.modalService.onHide.subscribe(() => {
					this.refreshCurrentPage();
					//this.active = 2;
					//this.ctdTabset.select('tab2');
					sub.unsubscribe();
				});
				break;
			case 'logiciel':
				initialState = {
					idPs: this.currentIdPs,
					hasEditor: ctx.existEditeurPs()
				};
				this.openedModal = this.modalService.show(PsLogicielComponent, { initialState, class: 'modal-xl' });
				sub = this.modalService.onHide.subscribe(() => {
					this.refreshCurrentPage();
					//this.ctdTabset.select('tab2');
					sub.unsubscribe();
				});
				break;
			case 'createSiteEmission':
				initialState = {
					idPs: this.currentIdPs,
					numeroPs: this.psForm.value.numeroAdeli,
					idSite: data.idSite,
					codeLastTypeRetour: isEmpty(this.siteEmissionPs) ? null : this.siteEmissionPs[0].typeRetourSouhaite
				};
				this.openedModal = this.modalService.show(PsSiteEmissionNouveauComponent, {
					initialState,
					class: 'modal-xl'
				});
				sub = this.modalService.onHide.subscribe(() => {
					this.refreshCurrentPage();
					//this.ctdTabset.select('tab3');
					sub.unsubscribe();
				});
				break;
			case 'UpdateSiteEmission':
				initialState = {
					idPs: this.currentIdPs,
					numeroPs: this.psForm.value.numeroAdeli,
					idSite: data.idSite,
					idLastTypeRetour: null,
					showOngletSiteReroutage: this.siteEmissionPs.length > 1
				};
				this.openedModal = this.modalService.show(PsSiteEmissionComponent, { initialState, class: 'modal-xl' });
				sub = this.modalService.onHide.subscribe(() => {
					this.refreshCurrentPage();
					//this.ctdTabset.select('tab3');
					sub.unsubscribe();
				});
				break;
			case 'addpsSupervise':
				initialState = {
					idPs: this.currentIdPs
				};
				this.openedModal = this.modalService.show(PsDelegueComponent, { initialState, class: 'modal-xl' });
				sub = this.modalService.onHide.subscribe(() => {
					this.refreshCurrentPage();
					this.active = 1;
					//this.ctdTabset.select('tab1');
					sub.unsubscribe();
				});
				break;
			default:
				break;
		}
	}

	// ------------------ End modal ----------------------

	getDefaultSV(oct) {
		if (oct?.code === 'QTP' || oct?.code === 'ALL' || oct?.code === 'CDS' || oct?.code === 'SAN') {
			return this.svArray.find((sv) => sv.value === true);
		} else return this.svArray.find((sv) => sv.value === false);
	}

	getDefaultLoi(sv) {
		if (sv === this.svArray.find((svTab) => svTab.value === true)) {
			return this.loiArray.find((loi) => loi.value === true);
		} else return this.loiArray.find((loi) => loi.value === false);
	}

	// ------------------ Onglet Rapprochement bancaire -----------------------

	getEbicsOrderTypesList() {
		this.ebicsService.getOrderTypesList().then((data) => {
			if (data) {
				this.listEbicsOrderType = data;
			}
		});
	}

	isEbicsParamsValid() {
		if (this.ebicsForm.controls.ebicsHostId.errors || this.ebicsForm.controls.ebicsOrderTypes.errors
			|| this.ebicsForm.controls.ebicsPartnerId.errors || this.ebicsForm.controls.ebicsUrl.errors
			|| this.ebicsForm.controls.ebicsUserId.errors || this.ebicsForm.controls.accountRef.errors || this.ebicsForm.controls.bankName.errors) {
			this.markFormGroupTouched(this.ebicsForm);
			return false;
		} else {
			return true;
		}
	}

	private markFormGroupTouched(form: FormGroup) {
		Object.values(form.controls).forEach(control => {
			control.markAsTouched();

			if ((control as any).controls) {
				this.markFormGroupTouched(control as FormGroup);
			}
		});
	}

	createRappBancaireAccount() {
		this.submitted = true;

		if (this.showEbicsForm) {
			//EBICS
			// var ebicsParams = new EbicsParams();
			// ebicsParams.ebicsHostId = this.ebicsForm.value.ebicsHostId;
			// ebicsParams.ebicsOrderType = this.ebicsForm.value.ebicsOrderTypes[0].value;
			// ebicsParams.ebicsPartnerId = this.ebicsForm.value.ebicsPartnerId;
			// ebicsParams.ebicsUrl = this.ebicsForm.value.ebicsUrl;
			// ebicsParams.ebicsUserId = this.ebicsForm.value.ebicsUserId;
			// ebicsParams.accountRef = this.ebicsForm.value.accountRef;
			// ebicsParams.bankName = this.ebicsForm.value.bankName;
			// ebicsParams.idPs = this.currentIdPs;
			if (this.typeConnexionBancaire == 'oxlin') {
				this.desactiverOxlinAndActiverEbics();
			}
			else {
				this.ebicsService.activateEbicsForPs(this.currentIdPs, "EBICS").subscribe(
					(data) => {
						if (data != null)
							this.typeConnexionBancaire = "ebics";
						if (this.comptesAssocies.length == 0) {
							this.toastr.success(
								'Le rapprochement EBICS a été activé avec succès. Merci de lier votre compte à un abonnement EBICS',
								'Rapprochement bancaire'
							);
						}
						else {
							//this.comptesAssocies.map(e=>e.actif=true);
							this.toastr.success(
								'Le rapprochement EBICS a été réactivé avec succès.',
								'Rapprochement bancaire'
							);
						}

						this.manageRapp(false);
					},
					(error) => {
						this.toastr.error(error.message, 'Erreur activation du rapprochement EBICS ');
					}
				)
			}



		} else if (this.showOxlinForm) {
			//OXLIN
			if (!this.rappComptePs) {
				this.rappComptePsService.getOxlinRootUserToken().subscribe(
					(token) => {
						this.rappComptePsService.createOxlinUser(token.access_token, this.psForm.value.numeroAdeli).subscribe(
							(data) => {
								var rappComptePs = new RappComptePsDataRaw();
								rappComptePs.idOxlinUser = data.location.substring(7);
								rappComptePs.addressEmail = this.psForm.value.numeroAdeli + '@careweb.fr';
								rappComptePs.secret = 'Careweb' + this.psForm.value.numeroAdeli + '!';
								rappComptePs.actif = true;
								rappComptePs.debutDate = new Date();
								rappComptePs.idPs = this.currentIdPs;
								this.showOxlinForm = true;


								if (this.rappComptePs != null) {
									rappComptePs.idRappComptePs = this.rappComptePs.idRappComptePs;
									rappComptePs.finDate = null;
									rappComptePs.creationDate = this.rappComptePs.creationDate;
								} else {
									rappComptePs.creationDate = new Date();
								}
								if (this.typeConnexionBancaire == "ebics") {
									this.desactiverEbicsBankingAndActivateOxlin(rappComptePs);
								}
								else {
									this.rappComptePsService.saveRappComptePs(rappComptePs).subscribe(
										(rappPs) => {
											this.initialiseOxlinUserContext(this.currentIdPs, true);
											this.ebicsService.activateEbicsForPs(this.currentIdPs, "OXLIN").subscribe(
												(data) => {
													if (data != null)
														this.typeConnexionBancaire = "oxlin";
													this.toastr.success(
														'Le rapprochement OXLIN a été activé avec succès.',
														'Rapprochement bancaire'
													);
													this.manageRapp(true);
												},
												(error) => {
													this.toastr.error(error.message, 'Erreur activation du rapprochement OXLIN ');
												}
											)
											this.toastr.success(
												'Le service de rapprochement est maintenant actif',
												'Rapprochement bancaire'
											);
										},
										(error) => {
											this.toastr.error('Erreur lors de la création du RappComptePS en base');
										}
									);
								}

							},
							(error) => {
								this.toastr.error(error.message, 'Erreur création utilisateur Oxlin');
							}
						);
					},
					(error) => {
						this.toastr.error('Impossible de récupérer le token root Oxlin', error?.message);
					}
				);
			}
			else {
				this.rappComptePs.actif = true;
				this.rappComptePs.debutDate = new Date();
				this.rappComptePs.idPs = this.currentIdPs;
				this.showOxlinForm = true;
				this.rappComptePs.finDate = null;
				if (this.typeConnexionBancaire == "ebics") {
					this.desactiverEbicsBankingAndActivateOxlin(this.rappComptePs);
				}
				else {
					this.rappComptePsService.saveRappComptePs(this.rappComptePs).subscribe(
						(rappPs) => {
							this.initialiseOxlinUserContext(this.currentIdPs, true);
							this.ebicsService.activateEbicsForPs(this.currentIdPs, "OXLIN").subscribe(
								(data) => {
									if (data != null)
										this.typeConnexionBancaire = "oxlin";
									this.toastr.success(
										'Le rapprochement OXLIN a été activé avec succès.',
										'Rapprochement bancaire'
									);
									this.manageRapp(true);
								},
								(error) => {
									this.toastr.error(error.message, 'Erreur activation du rapprochement OXLIN ');
								}
							)
							this.toastr.success(
								'Le service de rapprochement est maintenant actif',
								'Rapprochement bancaire'
							);
						},
						(error) => {
							this.toastr.error('Erreur lors de la création du RappComptePS en base');
						}
					);
				}

			}


		} else {
			this.toastr.error('Choisissez le type de connexion bancaire.');
		}
	}
	desactiverOxlinAndActiverEbics() {

		this.rappComptePs.actif = false;
		this.rappComptePs.finDate = new Date();
		this.rappComptePsService.saveRappComptePs(this.rappComptePs).subscribe((rappPs) => {
			this.rappComptePs = rappPs;
			this.ebicsService.desactivateEbicsForPs(this.currentIdPs).subscribe(
				(data) => {
					if (data == true) {

						this.typeConnexionBancaire = null;
						this.toastr.success(
							'OXLIN a été désactivé avec succès',
							'Rapprochement bancaire'
						); this.ebicsService.activateEbicsForPs(this.currentIdPs, "EBICS").subscribe(
							(data) => {
								if (data == true) {
									this.typeConnexionBancaire = "ebics";
									if (this.comptesAssocies.length == 0) {
										this.toastr.success(
											'Le rapprochement EBICS a été activé avec succès. Merci de lier votre compte à une abonnement EBICS',
											'Rapprochement bancaire'
										);
									}
									else {
										//this.comptesAssocies.map(e=>e.actif=true);
										this.toastr.success(
											'Le rapprochement EBICS a été réactivé avec succès.',
											'Rapprochement bancaire'
										);
									}
								}
								this.manageRapp(false);
								//this.manageRapp(true);
							},
							(error) => {
								this.toastr.error(error.message, 'Erreur activation du rapprochement ebics ');
							}
						)

					}

				},
				(error) => {
					this.toastr.error(error.message, 'Erreur lors de la désactivation des paramèters EBICS');
				}
			)
			//this.desactiverEbicsBanking("oxlin");

		});

		//this.showOxlinForm = false;

	}
	desactiverEbicsBankingAndActivateOxlin(rappComptePs: any) {
		this.ebicsService.desactivateEbicsForPs(this.currentIdPs).subscribe(
			(data) => {
				if (data == true) {

					this.toastr.success(
						'EBICS a été désactivé avec succès',
						'Rapprochement bancaire'
					);
					this.rappComptePsService.saveRappComptePs(rappComptePs).subscribe(
						(rappPs) => {
							this.initialiseOxlinUserContext(this.currentIdPs, true);
							this.ebicsService.activateEbicsForPs(this.currentIdPs, "OXLIN").subscribe(
								(data) => {
									if (data != null)
										this.typeConnexionBancaire = "oxlin";
									this.toastr.success(
										'Le rapprochement OXLIN a été activé avec succès.',
										'Rapprochement bancaire'
									);
									this.manageRapp(true);
								},
								(error) => {
									this.toastr.error(error.message, 'Erreur activation du rapprochement OXLIN ');
								}
							)
							// this.toastr.success(
							// 	'Le service de rapprochement est maintenant actif',
							// 	'Rapprochement bancaire'
							// );
						},
						(error) => {
							this.toastr.error('Erreur lors de la création du RappComptePS en base');
						}
					);


					//this.typeConnexionBancaire=null;

					//this.manageRapp(false);
				}

			},
			(error) => {
				this.toastr.error(error.message, 'Erreur lors de la désactivation des paramèters EBICS');
			}
		)
	}
	deleteRappBancaireAccount() {


		this.rappComptePs.actif = false;
		this.rappComptePs.finDate = new Date();
		this.rappComptePsService.saveRappComptePs(this.rappComptePs).subscribe((rappPs) => {
			this.rappComptePs = rappPs;
			this.desactiverEbicsBanking("OXLIN");
			//this.toastr.success('Le compte Oxlin a bien été supprimé');
			//this.manageRapp(false);
		});

		//this.showOxlinForm = false;

	}

	desactiverEbicsBanking(type: string) {
		this.ebicsService.desactivateEbicsForPs(this.currentIdPs).subscribe(
			(data) => {
				if (data == true) {
					if (type == 'ebics') {
						//this.comptesAssocies.map(e=>e.actif=false);
						this.toastr.success(
							'EBICS a été désactivé avec succès',
							'Rapprochement bancaire'
						);
					}
					else if (type == 'OXLIN') {
						this.toastr.success(
							'OXLIN a été désactivé avec succès',
							'Rapprochement bancaire'
						);
					}
					this.typeConnexionBancaire = null;
					this.manageRapp(false);
					//this.manageRapp(false);
				}

			},
			(error) => {
				this.toastr.error(error.message, 'Erreur lors de la désactivation des paramèters EBICS');
			}
		)
	}

	manageRapp(enable: boolean) {
		let currentPsStorage = localStorage.getItem('current_ps');
		if (currentPsStorage) {
			const currentPs = JSON.parse(currentPsStorage);
			if (this.rappComptePs && currentPs.idPs == this.rappComptePs.idPs) {
				currentPs.rappComptePs = this.rappComptePs;
				currentPs.ebicsParams = [this.ebicsParams];
				currentPs.rappComptePs.actif = enable;
			}
			currentPs.psEbics = this.comptesAssocies;
			currentPs.typeRappro = this.typeConnexionBancaire?.toUpperCase();
			localStorage.setItem('current_ps', JSON.stringify(currentPs));
		}
	}

	public initialiseOxlinUserContext(idUser: Number, manageRapp = false) {
		this.oxlinUserConnAcc = [];
		this.oxlinUserAccounts = [];
		this.oxlinUserConnections = [];

		this.rappComptePsService.getRapprochementComptePsById(idUser).subscribe(
			(rappCPs) => {
				this.rappComptePs = rappCPs;
				if (rappCPs.actif) {
					this.typeConnexionBancaire = "oxlin";
					this.psForm.get('cnxBank')
						.setValue(this.cnxBankArray.find((e) => e.value === 'OXLIN'))
					this.showOxlinForm = true;
					this.showEbicsForm = false;
				}

				if (manageRapp) {
					this.manageRapp(true);
				}
				this.rappComptePsService.getUserOxlinScope(idUser).subscribe(
					(data) => {
						if (data[0] == 'connections') this.oxlinUserConnections = data[1] != '' ? data[1] : [];
						if (data[0] == 'accounts')
							this.oxlinUserAccounts = data[1] != '' ? _.sortBy(data[1], (s) => s.name) : [];

						this.aggregateConnAccounts(this.oxlinUserConnections, this.oxlinUserAccounts);
						this.rappComptePs.connectionNumber = this.oxlinUserConnections.length;
					},
					(error) => {
						if (error === '401 Unauthorized') this.cguAccepted = false;
						else this.toastr.error(error);
					}
				);
			},
			(error) => {
				this.toastr.error(error);
			}
		);
	}

	private aggregateConnAccounts(oxlinUserConn: any[], oxlinUserAcc: any[]) {
		if (oxlinUserConn.length > 0 && oxlinUserAcc.length > 0 && this.oxlinUserConnAcc.length == 0) {
			_.filter(oxlinUserAcc, (oxlinAccount) => oxlinAccount.status !== 'PENDING_CONSENT').forEach((account) => {
				this.oxlinUserConnAcc.push([account, _.find(oxlinUserConn, ['id', account.connection_id])]);
			});
		}
		this.oxlinUserConnAcc = _.sortBy(this.oxlinUserConnAcc, (s) => s[1].name);

		this.isOxlinPageLoaded = true;
	}

	// ------------------ End onglet rapprochement bancaire -------------------

	onReset() {
		this.submitted = false;
		this.psForm.reset();
		this.ebicsForm.reset();
		this.refreshCurrentPage();
	}

	formatDate(params) {
		return this.datePipe.transform(params, 'short');
	}

	isAuthorized() {
		return this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
	}

	isAdminAuthorized() {
		return this.authSvc.userAuthorized('ADMIN');
	}

	isAdminSel() {
		return this.authSvc.userAuthorized('ADMIN_SEL');
	}

	onCnxBankSelected(): void {
		this.showEbicsForm = this.psForm.value.cnxBank.value === 'EBICS';
		this.showOxlinForm = this.psForm.value.cnxBank.value === 'OXLIN';
		this.ebicsForm.reset();
		if (this.showEbicsForm) {
			this.initialiserEbicsContext(this.currentIdPs, false);
		}

	}
}
