export class OctPsDataRaw {
	frequenceEnvois: String;
	caracteristiqueTransmission: String;
	sv: Boolean;
	actif: Boolean;
	loi: Boolean;
	loiPush: Boolean;
	aidePerenne: Boolean;
	derniereTransmission: Date;
	derniereTransmissionFrom: Date;
	derniereTransmissionTo: Date;
	addrMailArt: String;
	addrMailRph: String;
	idOct: Number;
}
