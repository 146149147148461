export class FseFilterRaw {
	numeroPs: String;
	numerosPs: String[];
	numLot: String;
	tiersPayant: Boolean;
	presenceArlScor: Boolean;
	presenceRspRo: Boolean;
	presenceRspRc: Boolean;
	numeroFseFrom: string;
	numeroFseTo: string;
	numFacture: string;
	dateLotRecuFrom: Date;
	dateLotRecuTo: Date;
	dateLotRecuExacte: Date;
	codeDestinataireRoRegime: String;
	codeDestinataireRoCaisse: String;
	codeDestinataireRoCenter: String;
	codeDestinataireRC: String;
	typeOrganisme: String;
	numeroPScomboBox: any;
	statutRsp: String;
	idOct: Number;
	partExtraite: string[];
	libelleRo: string;
	libelleRc: string;
	centreInfoTypeZero: String;
	rapproBancaire: Number;
	remboursement : Boolean;
	dateRspRecuFrom: Date;
	dateRspRecuTo: Date;
	dateRspRecuExacte: Date;

	constructor(data = null) {
		Object.assign(this, data);
	}
}
