import { Component, Input, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { EbicsService } from "../../ps/service/ebics.service";
import { AgGridHelper } from "../../../components/ag-grid/ag-grid-helper";
import { GridDataRaw } from "../../../data/gridDataRaw";
import { ColDef, IServerSideDatasource, IServerSideGetRowsParams } from "ag-grid-community";
import { Router } from "@angular/router";
import { AuthService } from "../../../core/services/auth.service";
import { isNullOrUndefined } from "util";
import { StorageService } from "../../../shared/storage-service";
import { gridConvertSort } from "../../../components/ag-grid/ag-grid.utils";
import { PsService } from "../../ps/ps.service";
import { isEmpty } from "lodash";
import { OctPsService } from "../../ps/service/octps.service";
import { OctService } from "../../../core/services/oct.service";
import { OctDataRaw } from "../../../data/octDataRaw";

@Component({
    selector: 'careweb-app-details-comptes-associes',
    templateUrl: './details-comptes-associes.component.html',
    styleUrls: ['./details-comptes-associes.component.scss']
})
export class DetailsCompteAssociesComponent implements OnInit {
    @Input() idAbonnement: number;
    gridHelperComptesAssocies: AgGridHelper;
    gridDataRawComptesAssocies: GridDataRaw;
    public rowCount: number = -1;
    octSelected: OctDataRaw;
    constructor(
        private modalRef: BsModalRef,
        private ebicsService: EbicsService,
        private router: Router,
        private authSvc: AuthService,
        private storageService: StorageService,
        private psSvc: PsService,
        private octPsSvc: OctPsService,
        private octSvc: OctService,

    ) {
        const sessionPageSizeComptes = this.storageService.get('detailsComptesPaginationPageSize', true);
        this.gridDataRawComptesAssocies = new GridDataRaw();
        this.gridDataRawComptesAssocies.size = !isNullOrUndefined(sessionPageSizeComptes) ? sessionPageSizeComptes : 10;

        this.gridHelperComptesAssocies = new AgGridHelper('detailsComptes', this.gridDataRawComptesAssocies.size);

    }
    ngOnInit() {
        this.initDetailsCompteAssociesGrid();


    }
    onComptesPaginationSizeChange(paginationSize: number): void {
        this.gridHelperComptesAssocies.changePaginationSize(paginationSize);
        this.launchSearch();

        this.gridHelperComptesAssocies.saveGridStatePrefix();
    }
    closeModal() {
        this.modalRef.hide();
    }

    resetComptesGridState() {
        this.gridHelperComptesAssocies.resetGridState();
        this.gridDataRawComptesAssocies.size = this.gridHelperComptesAssocies.defaultPaginationPageSize;
    }

    initDetailsCompteAssociesGrid() {
        // Init grid options
        this.gridHelperComptesAssocies.gridOptions = {
            columnDefs: this.getColumnComptesDefs(),
            defaultColDef: {
                resizable: true,
                suppressMenu: true
            },
            suppressServerSideInfiniteScroll: false,
            rowModelType: 'serverSide',
            detailRowHeight: 550,
            alwaysShowVerticalScroll: false,
            domLayout: 'autoHeight',
            rowHeight: 50,
            headerHeight: 50,
            pagination: true,
            suppressScrollOnNewData: true,
            suppressPaginationPanel: true,
            suppressContextMenu: true,
            enableCellTextSelection: true,
            cacheBlockSize: 10,
            paginationPageSize: this.gridDataRawComptesAssocies.size,
            infiniteInitialRowCount: 1,
            localeText: this.gridHelperComptesAssocies.getLocaleText(),
            onGridReady: (params) => this.onGridReadyComptes(params),
            onGridSizeChanged: (params) => this.onComptesGridSizeChanged(),
            onSortChanged: (params) => this.onComptesSortChanged(),
        };

    }
    onComptesGridSizeChanged() {
        this.gridHelperComptesAssocies.gridApi.sizeColumnsToFit();
    }

    onComptesSortChanged(): void {
        this.gridHelperComptesAssocies.saveGridSort();
    }
    restoreComptesGrid() {
        this.gridHelperComptesAssocies.restoreGridStatePrefix();
        this.gridDataRawComptesAssocies.size = this.gridHelperComptesAssocies.paginationPageSize;
    }
    onGridReadyComptes(params) {

        this.gridHelperComptesAssocies.gridApi = params.api;
        this.gridHelperComptesAssocies.gridColumnApi = params.columnApi;
        this.restoreComptesGrid();
        this.gridHelperComptesAssocies.gridApi.sizeColumnsToFit();

        this.launchSearch();
    }
    private getColumnComptesDefs(): ColDef[] {
        return [
            {
                headerName: 'Id PS',
                field: 'idPs',
                cellClass: (params) => this.styleLink(),
                onCellClicked: (params) => this.onIdPsClicked(params.data),
                onCellContextMenu: (params) => this.onIdPsRightClicked(params.data, params.event as MouseEvent),
                minWidth: 200,
                lockVisible: true,
                cellStyle: this.gridHelperComptesAssocies.centerContent(),
                sortable: true,
                hide: !this.isAuthorized()
            },
            {
                headerName: 'Numéro de PS',
                field: 'numeroAdeli',
                minWidth: 200,
                lockVisible: true,
                cellStyle: this.gridHelperComptesAssocies.centerContent(),
                sortable: true,
                hide: !this.isAuthorized()
            },
            {
                headerName: 'IBAN',
                field: 'iban',
                minWidth: 500,
                lockVisible: true,
                cellStyle: this.gridHelperComptesAssocies.centerContent(),
                sortable: true
            },
            {
                headerName: 'Statut du compte',
                field: 'lastSyncStatut',
                minWidth: 300,
                flex: 1,
                cellRenderer: (params) => this.formatSynchoReussieEBICS(params),
                lockVisible: true,
                cellStyle: this.gridHelperComptesAssocies.centerContent(),
                sortable: true
            },
        ];

    }

    styleLink() {
        return ['link-in-not-selected-rows', 'underline'];
    }
    onIdPsClicked(compte: any) {
        this.handlePsClick(compte, false);
    }

    onIdPsRightClicked(compte: any, event: MouseEvent) {
        const url = this.getPsDetailsUrl(compte.idPs);
        window.open(url, '_blank');
        this.handlePsClick(compte, true);
    }

    handlePsClick(compte: any, isRightClick: boolean) {
        this.psSvc.getPsByNumAdeli({ numAdeli: compte.numeroAdeli }).subscribe(
            ps => {
                if (!isEmpty(ps) && ps.some(item => this.checkPsHaveOct(item.octPsDtos))) {
                    const psTrouve = ps.find(item => this.checkPsHaveOct(item.octPsDtos));
                    localStorage.setItem("numeroPs", psTrouve.numeroAdeli);
                    localStorage.setItem("idPs", psTrouve.idPs);
                    localStorage.setItem('current_ps', JSON.stringify(psTrouve));
                    this.psSvc.eventPsSelected.emit(psTrouve.numeroAdeli);
                    if (!isRightClick) {
                        this.router.navigate(['/ps-details/', psTrouve.idPs]);
                    }
                } else {
                    this.octPsSvc.getOctPsByIdPs(compte.idPs).subscribe(data => {
                        this.toNewOct(data[0], compte.idPs, ps[0]);
                    });
                }
            }
        );
        this.closeModal();
    }

    getPsDetailsUrl(idPs: string): string {
        // return `/ps-details/${idPs}`;
        return `/careweb-client/ps-details/${idPs}`;
    }
    toNewOct(newOct:any,idPs:number,ps:any): void {
        this.octSvc.getOctById(newOct.idOct).subscribe(data => {
            this.onOctChange(data.idOct, data.nom, data.code,idPs,ps);
        }
        );
    }
    onOctChange(idOct, nom, code,idPs,ps) {
        this.octSelected = new OctDataRaw().withIdOct(idOct).withNom(nom).withCode(code);
        localStorage.setItem("numeroPs", ps.numeroAdeli);
        localStorage.setItem("idPs", ps.idPs);
        localStorage.setItem('current_ps', JSON.stringify(ps));
        this.psSvc.eventPsSelected.emit(ps.numeroAdeli);

        this.router.navigate(['/ps-details/', idPs]);
        this.octSvc.setOctValue(this.octSelected);
        localStorage['octCurrent'] = JSON.stringify(this.octSelected);
    }
    checkPsHaveOct(octs: any[]): boolean {
        var currentOct = JSON.parse(localStorage.getItem('octCurrent'));
        return octs.map(e => e.idOct).includes(currentOct.idOct);
    }
    isAuthorized() {
        return this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
    }

    launchSearch() {

        const ctx = this;

        const dataSource: IServerSideDatasource = {
            getRows: function (paramsRows: IServerSideGetRowsParams) {
                const sorts = gridConvertSort(ctx.gridHelperComptesAssocies.gridSortModel(), []);
                ctx.ebicsService
                    .getAllComptes({
                        page: ctx.gridHelperComptesAssocies.gridApi.paginationGetCurrentPage(),
                        size: ctx.gridHelperComptesAssocies.gridApi.paginationGetPageSize(),
                        sorts
                    },
                        ctx.idAbonnement
                    )
                    .subscribe(
                        (data) => {
                            ctx.rowCount = data.totalElements;
                            ctx.gridHelperComptesAssocies.manageNoRowsOverlay(ctx.rowCount);
                            paramsRows.success({
                                "rowData": data.content,
                                "rowCount": ctx.rowCount
                            });
                            const pageN = Number.parseInt(sessionStorage.getItem('detailsComptesAsoociesCurrentPage'));
                            if (
                                !isNullOrUndefined(pageN) &&
                                pageN != ctx.gridHelperComptesAssocies.gridApi.paginationGetCurrentPage() &&
                                pageN > 0
                            ) {
                                ctx.gridHelperComptesAssocies.gridApi.paginationGoToPage(pageN - 1);
                            }
                            sessionStorage['detailsComptesAsoociesCurrentPage'] = null;
                        },
                        () => paramsRows.fail()
                    );

            }
        };
        this.gridHelperComptesAssocies.gridApi.setServerSideDatasource(dataSource);
    }
    formatSynchoReussieEBICS(params) {
        let isOk = params.value;
        if (isOk) {
            if (isOk === 'ERROR') {
                return "<span class='ui-grid-cell-contents red cell-bold' >KO </span>";
            } else {
                if (isOk === 'SUCCESS') {
                    return "<span class='ui-grid-cell-contents' container='body'>OK </span>";
                }
                else {
                    return '-';
                }
            }
        }
    }
}