import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONF } from '../../core/constants';

@Injectable()
export class VersionService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/version';
	constructor(private httpClient: HttpClient) {}

	getVersion(): Observable<any> {
        const httpOptions = {
			headers: new HttpHeaders({
				ContentType: 'application/json'
			})
		};
		return this.httpClient.get<any>(`${this.baseUrl}/build`, httpOptions);
	}
}
