import { MatExpansionPanel } from '@angular/material/expansion';
import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { AccordionGroupBase } from './accordion-group-base';
import { ViewChild } from '@angular/core';

@Component({ selector: 'careweb-accordion-group',
             templateUrl: './accordion-group.component.html',
             styleUrls: [ './accordion-group.component.scss' ]
            } )
export class AccordionGroupComponent extends AccordionGroupBase {

    @Input() isDateRangeValid?: boolean = true;

    @ViewChild('expansionPannel')
	expansionPannel : MatExpansionPanel ;


}
