import { Component, AfterViewInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { isNullOrUndefined } from 'util';
import { OrgStateService } from '../org-state.service';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'conventionel-preview',
	template: `
		<span class="btn btn-primary small" [tooltip]="tooltip" 
		placement="top" container="body" name="spn_relance"
		[hidden]="hide" [ngClass]="inprogress ? 'disabled':''"
		(click)="relancer()">
		<i class="fa fa-refresh btn_rel"></i>
	  	</span>
	`,
	styles: [ '.btn_rel{ color: #FFFFFF !important}' ]
})
export class ConventionelPreviewComponent implements ICellRendererAngularComp, AfterViewInit {
	iconClass: string;
	private params: any;
	hide = false;
	inprogress = false;
	tooltip: string;

	constructor(private orgStateService: OrgStateService, private toastr: ToastrService) {}

	agInit(params: any): void {
		this.params = params;
	}

	ngAfterViewInit() {
		var cellParams = this.params;
		if (cellParams.data) {
			if (
				cellParams.colDef.field &&
				cellParams.colDef.field == 'options' &&
				cellParams.action == 'btn_relancer'
			) {
				if (
					!isNullOrUndefined(this.params.data) &&
					this.params.data.idbServiceAvailable === false &&
					this.params.data.interAMCServiceAvailable === false
				) {
					this.hide = false;
					this.inprogress = false;
					this.tooltip = 'Relancer la demande de pratique';
				} else if (
					!isNullOrUndefined(this.params.data) &&
					isNullOrUndefined(this.params.data.idbServiceAvailable) &&
					isNullOrUndefined(this.params.data.interAMCServiceAvailable)
				) {
					this.hide = false;
					this.inprogress = true;
					this.tooltip = 'Demande de pratique en cours';
				} else {
					this.hide = true;
				}
			}
		}
	}

	refresh(): boolean {
		return false;
	}

	relancer(): void {
		if (!this.inprogress) {
			this.orgStateService
				.createPracticeRequest({
					organizationNumber: this.params.data.organizationNumber,
					convType: this.params.data.agreementType,
					secondaryCriteria: this.params.data.secondaryCriteria,
					providerNumber: this.params.data.providerNumber,
					requestType: 'C'
				})
				.toPromise()
				.then((res) => {
					if (res == 200) {
						this.toastr.success('La demande de pratique a été créée avec succès.');
					} else {
						this.toastr.error('Une erreur est survenue lors de la création du demande de pratique.');
					}
				})
				.catch((err) => {
					this.toastr.error('Une erreur est survenue lors de la création du demande de pratique.');
				});
		}
	}
}
