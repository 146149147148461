import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CONF } from '../../core/constants';
import { HotDashboardDataRaw } from '../../data/hotDashboardDataRaw';

@Injectable({
	providedIn: 'root'
})
export class HotlineDashboardService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/hotline/dashboard';

	constructor(private httpClient: HttpClient) {}

	getDailyHotlineReport(numeroPs: string): Observable<HotDashboardDataRaw> {
		return this.httpClient.get<HotDashboardDataRaw>(this.baseUrl + '/count?numeroPs=' + numeroPs);
	}

	// récuper la liste des interventions de la journée courante
	getDailyInterventionList(criteria: any): Observable<any> {
		return this.httpClient.get<any>(
			this.baseUrl +
				'/today/list?page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				'&numeroPs=' +
				criteria.numeroPs +
				this.getSortParameters(criteria.sorts)
		);
	}

	getSortParameters(sorts) {
		if (sorts.length === 1) {
			const sort = sorts[0].property;
			return '&sort=' + sort + '&direction=' + sorts[0].direction;
		} else {
			// sort par défaut
			return '&sort=dateDebutIntervention&direction=DESC';
		}
	}

	handleError(error: HttpErrorResponse) {
		return throwError(error);
	}
}
