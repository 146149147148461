import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { Reporting, TokenModel } from './model/storage.model';

// decalre storage keys here
export const REPORT_STORAGE_KEY = "_reporting";
export const CARWEB_TOKEN = "careweb_token";
/**
 * This class provides a set of functions to manage the session storage and the local storage of the application.
 */
@Injectable()
export class StorageService {

    /**
     * Get stored item from the session storage or from the local Storage.
     * 
     * @param string key 
     * @param boolean useSessionStorage
     */
    public get(key: string, useSessionStorage: boolean = false): any {
        if (useSessionStorage) {
            return JSON.parse(sessionStorage.getItem(key));
        } else {
            return JSON.parse(localStorage.getItem(key));
        }
    }

    /**
     * Set item to the session storage or to the local Storage.
     * 
     * @param string key 
     * @param string value 
     * @param boolean useSessionStorage
     */
    public set(key: string, value: any, useSessionStorage: boolean = false): void {
        if (useSessionStorage) {
            sessionStorage.setItem(key, JSON.stringify(value));
        } else {
            localStorage.setItem(key, JSON.stringify(value));
        }
    }

    /**
     * Remove item from the session storage or from the local Storage.
     * 
     * @param string key 
     * @param boolean useSessionStorage
     */
    public remove(key: string, useSessionStorage: boolean = false): void {
        if (useSessionStorage) {
            sessionStorage.removeItem(key);
        } else {
            localStorage.removeItem(key);
        }
    }

    getReportingStorage(): Reporting {
        if (this.getEmail()) {
            return JSON.parse(localStorage.getItem(this.getEmail() + REPORT_STORAGE_KEY));
        }
        return null;
    }

    saveReportingStorage(reporting: Reporting) {
        if (reporting && this.getEmail()) {
            localStorage.setItem(this.getEmail() + REPORT_STORAGE_KEY, JSON.stringify(reporting))
        }
    }

    getEmail() {
        const token: TokenModel = jwt_decode(localStorage.getItem(CARWEB_TOKEN));
        if (token) {
            return token.sub;
        }
        return null;
    }
}
