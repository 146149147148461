import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridHelper } from '../../../components/ag-grid/ag-grid-helper';
import { ColDef, IServerSideGetRowsParams, IServerSideDatasource } from 'ag-grid-enterprise';
import { gridConvertSort } from '../../../components/ag-grid/ag-grid.utils';
import { RetoursEditablesService } from './retours-editables.service';
import { AuthService } from '../../../core/services/auth.service';
import { RetourEditableFilterRaw } from '../../../data/filters/retour-editable-filter';
import { RetourPreviewComponent } from './retour-editables-subcomponents/retour-preview.component';
import { RetourEditableDataRaw } from '../../../data/retourEditableDataRaw';
import { OctDataRaw } from '../../../data/octDataRaw';
import { OctService } from '../../../core/services/oct.service';
import { DatePipe, formatDate } from '@angular/common';
import { NgForm } from '@angular/forms';
import { isEmpty } from 'lodash-es';
import { PsDelegueDataRaw } from '../../../data/PsDelegueDataRaw';
import { PsDelegueService } from '../../ps/service/ps-delegue.service';
import { isNullOrUndefined } from 'util';
import { PsService } from '../../ps/ps.service';
import { Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale, frLocale } from 'ngx-bootstrap/chronos';

@Component({
	selector: 'careweb-app-retours-editables',
	templateUrl: './retours-editables.component.html',
	styleUrls: ['./retours-editables.component.scss']
})
export class RetoursEditablesComponent implements OnInit {
	params: any;
	initHidden = true;
	showFilter = false;
	gridHelper: AgGridHelper;
	paginationSize: number;
	filters = new RetourEditableFilterRaw();
	retourEditableDataRaw: RetourEditableDataRaw;
	isValidDate = false;
	retourMaxDate: Date = new Date();
	currentOct = new OctDataRaw();
	retourNumber: Number = 0;
	datePipe: DatePipe;
	supervisedPs: PsDelegueDataRaw;
	psDelegueListFilter = new Array<PsDelegueDataRaw>();
	@ViewChild('retourForm')
	form: NgForm;
	currentDate = new Date();
	showGrid = false;
	isDateRangeValid = true;
	constructor(
		private retourEditableSvc: RetoursEditablesService,
		private authSvc: AuthService,
		private localeService: BsLocaleService,
		private octSvc: OctService,
		private psDelegueSVC: PsDelegueService,
		private psSvc: PsService,
		private router: Router,
	) {
		this.gridHelper = new AgGridHelper();
		this.retourEditableDataRaw = new RetourEditableDataRaw();
		// Define local date fr
		defineLocale('fr', frLocale);
		this.setLocale();
	}

	ngOnInit() {
		// Set local date

		// Inititate type de retour
		this.filters.typeRetour = 'art';
		// Get currentOct
		this.octSvc.currentOctSubject.subscribe((value) => {
			this.currentOct = value;
		});

		//Apply numero Ps on the filter if exist
		const numeroPsLocalStorage = localStorage.getItem('numeroPs');
		if (!isEmpty(numeroPsLocalStorage)) {
			this.filters.numeroAdeli = numeroPsLocalStorage;
		}
		// Init date pipe
		this.datePipe = new DatePipe('fr-FR');
		this.initiateRetoursGrid();

		if (!this.isAuthorized()) {
			this.loadComboBoxFilterNumeroPS_Supervise();
		}
	}

	initiateRetoursGrid() {
		this.gridHelper.gridOptions = {
			columnDefs: this.getColumnDefs(),
			defaultColDef: {
				suppressMenu: true
			},
			components: {
				RetourPreviewComponent: RetourPreviewComponent
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 10,
			maxBlocksInCache: 0,
			infiniteInitialRowCount: 1,
			paginationPageSize: 10,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			localeText: this.gridHelper.getLocaleText(),
			onGridReady: (params) => this.onGridReady(params),
			onRowDataUpdated: (params) => this.onRowDataUpdated(params)
		};
		this.paginationSize = this.gridHelper.paginationPageSize;
	}

	private setLocale() {
		this.localeService.use('fr');
	}

	onGridReady(params) {
		this.gridHelper.gridApi = params.api;
		this.gridHelper.gridColumnApi = params.columnApi;
		this.restoreGrid();
		this.gridHelper.gridApi.setServerSideDatasource(null);
		this.gridHelper.gridApi.sizeColumnsToFit();
		this.loadFilterStorage();
		this.filters.dateEnvoieFrom = sessionStorage.getItem('retourEditable-dateEnvoieFrom') ? new Date(sessionStorage.getItem('retourEditable-dateEnvoieFrom')) : null;
		this.setRetourMaxDate(this.filters.dateEnvoieFrom);
		this.filters.dateEnvoieTo = sessionStorage.getItem('retourEditable-dateEnvoieTo') ? new Date(sessionStorage.getItem('retourEditable-dateEnvoieTo')) : null;
		this.filters.typeRetour = sessionStorage.getItem('retourEditable-type') ? sessionStorage.getItem('retourEditable-type') : 'art';
		this.filters.numeroAdeli = sessionStorage.getItem('retourEditable-numeroAdeli') ? sessionStorage.getItem('retourEditable-numeroAdeli') : null;
		this.loadFilterStorage();
	}
	onRowDataUpdated(params): void {
		params.api.sizeColumnsToFit();
	}
	loadFilterStorage() {
		const numeroPsLocalStorage = localStorage.getItem('numeroPs');
		const numeroAdeli = sessionStorage.getItem('retourEditable-numeroAdeli');
		if (!isEmpty(numeroPsLocalStorage) && !isEmpty(numeroAdeli)) {
			if(numeroPsLocalStorage != numeroAdeli) {
				this.filters =  new RetourEditableFilterRaw();
				sessionStorage.removeItem('retourEditable-dateEnvoieFrom');
				sessionStorage.removeItem('retourEditable-dateEnvoieTo');
				sessionStorage.removeItem('retourEditable-type');
			}
			this.filters.numeroAdeli = numeroPsLocalStorage;
			sessionStorage['retourEditable-numeroAdeli'] = numeroPsLocalStorage;
		}
	}

	getRetoursList() {
		// if (!this.isAuthorized()) {
		// 	this.loadComboBoxFilterNumeroPS_Supervise();
		// }

		this.gridHelper.gridOptions.api.setColumnDefs(null);
		this.gridHelper.gridOptions.api.setColumnDefs(this.getColumnDefs());
		if (this.isFormValid()) {
			const ctx = this;
			this.retourEditableDataRaw.numeroAdeli = this.isAuthorized() ? this.filters.numeroAdeli : this.supervisedPs.numeroPsDelegue;
			this.retourEditableDataRaw.dateEnvoieFrom = formatDate(this.filters.dateEnvoieFrom, "yyyy-MM-dd'T'00:00:00.SSS", 'fr-FR')//new Date(this.filters.dateEnvoieFrom)
			this.retourEditableDataRaw.dateEnvoieTo = formatDate(this.filters.dateEnvoieTo, "yyyy-MM-dd'T'23:59:59.SSS", 'fr-FR');//this.filters.dateEnvoieTo //this.filters.dateEnvoieTo;
			this.retourEditableDataRaw.idOct = ctx.currentOct ? ctx.currentOct.idOct : null;

			sessionStorage.setItem('retourEditable-dateEnvoieFrom', this.retourEditableDataRaw.dateEnvoieFrom.toString() );
			sessionStorage.setItem('retourEditable-dateEnvoieTo', this.retourEditableDataRaw.dateEnvoieTo.toString() );
			sessionStorage.setItem('retourEditable-type', this.filters.typeRetour.toString() );
			sessionStorage.setItem('retourEditable-numeroAdeli', this.filters.numeroAdeli + '' );

			const dataSource: IServerSideDatasource = {
				getRows: function (paramsRows: IServerSideGetRowsParams) {
					const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
					if (ctx.isAuthorized()) {
						ctx.retourEditableSvc
							.getRetoursEditableList(
								{
									page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
									size: ctx.gridHelper.gridApi.paginationGetPageSize(),
									idOct: ctx.retourEditableDataRaw.idOct,
									sorts,
									typeRetour: ctx.filters.typeRetour
								},
								ctx.retourEditableDataRaw
							)
							.subscribe(
								(data) => {
									const rowCount = data.totalElements;
									ctx.retourNumber = rowCount;
									ctx.gridHelper.manageNoRowsOverlay(rowCount);
									paramsRows.success({"rowData": data.content, "rowCount": rowCount});
								},
								() => paramsRows.fail()
							);
					} else {
						ctx.retourEditableSvc
							.getRetoursEditablePsList(
								{
									page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
									size: ctx.gridHelper.gridApi.paginationGetPageSize(),
									sorts,
									typeRetour: ctx.filters.typeRetour
								},
								ctx.retourEditableDataRaw
							)
							.subscribe(
								(data) => {
									const rowCount = data.totalElements;
									ctx.retourNumber = rowCount;
									ctx.gridHelper.manageNoRowsOverlay(rowCount);
									paramsRows.success({"rowData": data.content, "rowCount": rowCount});
								},
								() => paramsRows.fail()
							);
					}

				}
			};
			this.gridHelper.gridApi.setServerSideDatasource(dataSource);
			this.gridHelper.gridApi.sizeColumnsToFit();
		}
	}

	isFormValid() {
		this.isDateRangeValid = this.checkDateRangeValidity();
		let isFormValid = false;
		//const numeroAdeliExist = this.isAuthorized() ?  !isNullOrUndefined(this.filters.numeroAdeli) : true;
		const numeroAdeliExist = this.filters.numeroAdeli?this.filters.numeroAdeli:this.supervisedPs.numeroPsDelegue;
		if (
			this.filters.dateEnvoieFrom &&
			this.filters.dateEnvoieTo &&
			numeroAdeliExist &&
			this.filters.dateEnvoieFrom.getTime() <= this.filters.dateEnvoieTo.getTime()
		) {
			let timeDifference = Math.abs(this.filters.dateEnvoieTo.getTime() - this.filters.dateEnvoieFrom.getTime());
			let dayDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
			isFormValid = dayDifference <= 30;
			this.isValidDate = true;
		} else {
			// Show toastr error
			this.isValidDate = false;
		}
		this.showGrid = isFormValid;
		this.gridHelper.gridApi.sizeColumnsToFit();
		return isFormValid;
	}

	restoreGrid() {
		this.gridHelper.restoreGridStatePrefix();
		this.paginationSize = this.gridHelper.paginationPageSize;
	}

	toggleFilterDisplay() {
		if (this.initHidden) {
			this.initHidden = false;
			setTimeout(() => {
				this.showFilter = !this.showFilter;
			}, 10);
		} else {
			this.showFilter = !this.showFilter;
		}
	}

	setRetourMaxDate($event) {
		if (!isNullOrUndefined($event)) {
			let newDate = new Date($event);
			if (!isNaN(newDate.getTime())) {
				let dayDifference = Math.floor(Math.abs(new Date().getTime() - newDate?.getTime()) / (1000 * 3600 * 24));
				newDate.setDate(newDate.getDate() + (dayDifference < 29 ? dayDifference : 29));
				this.retourMaxDate = newDate;
			}
		}
	}

	onChangeInput($event) {
		if ($event.target.value === 'Invalid date'){
			$event.target.value = 'Date invalide';
		}
	}

	checkDateRangeValidity(): boolean {
		if (this.filters.dateEnvoieFrom && this.filters.dateEnvoieTo) {
			if ((Math.abs(this.filters.dateEnvoieFrom.getTime() - this.filters.dateEnvoieTo.getTime()) / (1000 * 60 * 60 * 24) > 30)  ) {
				return false;
			}else{
				return true;
			}
		}else {
			return true;
		}
	}

	resetGridState() {
		this.gridHelper.resetGridState();
		this.paginationSize = this.gridHelper.defaultPaginationPageSize;
	}

	refresh(): boolean {
		return false;
	}

	resetAllFilter(): void {
		this.gridHelper.gridApi.setFilterModel(null);
		this.gridHelper.gridApi.onFilterChanged();
	}

	isAuthorized() {
		return this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
	}

	existGroupNumeroPS() {
		return this.isAuthorized() || this.psDelegueListFilter.length !== 0;
	}
	haveSupervisedPss(){
		return this.psDelegueListFilter.length !== 0;
	}
	loadComboBoxFilterNumeroPS_Supervise() {
		const userConnected = JSON.parse(localStorage.getItem('careweb_user'));
		var ctx = this
		if (!isEmpty(userConnected)) {
			if (!isNullOrUndefined(userConnected.idPs)) {
				this.psDelegueSVC.getPsDelegueByIdPs(userConnected.idPs).subscribe((data) => {
					if (!isNullOrUndefined(data) && !isEmpty(data) && data.length > 0) {
						this.psDelegueListFilter = data;
						let psCurrent = new PsDelegueDataRaw();
						psCurrent.idPsDelegue = this.psDelegueListFilter[0].idPsAssocie;
						psCurrent.numeroPsDelegue = this.psDelegueListFilter[0].numeroPsAssocie;
						psCurrent.nomPsDelegue = this.psDelegueListFilter[0].nomPsAssocie;
						this.psDelegueListFilter.push(psCurrent);
						// Apply numero Ps supervised on the filter if exist
						var numeroPsSupervisedLocalStorage = localStorage.getItem('supervised_ps');
						if (!isEmpty(numeroPsSupervisedLocalStorage)) {
							if (numeroPsSupervisedLocalStorage === '-1') {
								this.supervisedPs = null;
							}
							else {
								numeroPsSupervisedLocalStorage = numeroPsSupervisedLocalStorage.split(';')[0];
								//this.numeroAdeli = numeroPsSupervisedLocalStorage;
								this.supervisedPs = this.psDelegueListFilter.find(p => p.numeroPsDelegue === numeroPsSupervisedLocalStorage);
							}
						}
					} else {
						ctx.psSvc.getPsById(userConnected.idPs).then((res) => {
							ctx.filters.numeroAdeli = res.numeroAdeli
						})
					}
					this.gridHelper.gridColumnApi.setColumnVisible('numeroAdeli', this.existGroupNumeroPS());
				});
			}
		}
	}

	private getColumnDefs(): ColDef[] {
		const ctx = this;
		return [
			{
				headerName: 'Numéro de PS',
				field: 'numeroAdeli',
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				hide: !this.existGroupNumeroPS(),
				onCellClicked: (params) => this.onPsCellClicked(params),
				resizable: true
			},
			{
				headerName: 'Date de réception',
				field: ctx.filters.typeRetour === 'art' ? 'dateEnvoi' : 'dateEnvoie',
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				resizable: true,
				valueFormatter: this.formatDate
			},
			{
				headerName: 'Type de retour',
				valueFormatter: this.setTypeRetour,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				resizable: true
			},
			{
				headerName: '',
				field: 'telechargement',
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				cellRenderer: RetourPreviewComponent,
				cellRendererParams: {
					action: 'download',
					typeRetour: ctx.filters.typeRetour
				},
				maxWidth: 70,
				resizable: true
			},
			{
				headerName: '',
				field: 'consultation',
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				cellRenderer: RetourPreviewComponent,
				cellRendererParams: {
					action: 'consult',
					typeRetour: ctx.filters.typeRetour
				},
				maxWidth: 70,
				resizable: true
			}
		];
	}

	formatDate = (params): string => {
		if (params.value !== null && params.value !== 'Invalid Date') {
			return this.datePipe.transform(params.value, 'shortDate');
		}
	};

	onPsCellClicked(params) {
		var ctx = this;
		if (this.isAuthorized()) {
			ctx.psSvc
				.getPsByNumAdeli({
					numAdeli: params.value
				})
				.subscribe((data) => {
					this.router.navigate(['/ps-details', data[0].idPs]);
				});
		}
	}

	setTypeRetour = (params): string => {
		return this.filters.typeRetour === 'art' ? 'ART' : 'RPH';
	};
}
