import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONF } from '../../core/constants';

@Injectable()
export class LoginService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/user';
	isPaswordMustChanged : boolean;
	differenceEnJours : number;
	private userEmail: string = '';

	constructor(private httpClient: HttpClient) {}

	askResetPwd(login: string): Observable<void> {
		return this.httpClient.get<void>(`${this.baseUrl}/reset-password?email=${login}`);
	}
	
	setUserEmail(email: string) {
		this.userEmail = email;
	}

	getUserEmail() {
		return this.userEmail;
	}
}
