export class AbonnementEbicsFilterRaw {
    ebicsHostId : String;
    ebicsUserId : String;
    ebicsUrl : String;
    ebicsFileFormat : String;
    ebicsOrderType : String;
    ebicsCustomerId : String;
    bankName : String;
    accountRef : String;
    actif : boolean;
    idPs : Number;
    lastSyncDate : Number;
    lastSyncStatut: string;
    constructor(data = null) {
        Object.assign(this, data);
    }
}