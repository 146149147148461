import { Component, Input, OnInit} from '@angular/core';
import { Location ,DatePipe} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { AmcDataRaw } from '../../../../data/amcDataRaw';
import { AmoDataRaw } from '../../../../data/amoDataRaw';
import { LotsDataRaw } from '../../../../data/lotsDataRaw';
import { PartsDataRaw } from '../../../../data/partsDataRaw';
import { PsDataRaw } from '../../../../data/psDataRaw';
import { OrganismesService } from '../../../organismes/organismes/organismes.service';
import { PsService } from '../../../ps/ps.service';
import { RSPService } from '../../rsp/rsp.service';
import { FseService } from '../fse.service';
import { ScrollTopService } from '../../../../core/utilities/scroll-top.service';
import { Router } from '@angular/router';
import { ColDef, IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import {AgGridHelper} from "../../../../components/ag-grid/ag-grid-helper";
import {GridDataRaw} from "../../../../data/gridDataRaw";
import {StorageService} from "../../../../shared/storage-service";
import {RspPreviewComponent} from "../../rsp/rsp-preview.component";
import {RspDataRaw} from "../../../../data/rspDataRaw";
import {gridConvertSort} from "../../../../components/ag-grid/ag-grid.utils";
import {AuthService} from "../../../../core/services/auth.service";
import {OrganismeTypePipe} from "../../../../core/pipes/organisme-type.pipe";
import {RouteHistoryService} from "../../../../shared/route-history.service";
import {CustomRouteHistoryService} from "../../../../shared/custom-route-history.service";

@Component({
	selector: 'careweb-app-fiche-fse',
	templateUrl: './fiche-fse.component.html',
	styleUrls: [ './fiche-fse.component.scss' ]
})

export class FicheFSEComponent implements OnInit {
	currentFse: any;
	isFSEDetailsCollapsed = false;
	currentRO: any;
	rsp: any;
	currentRC: any;
	part: PartsDataRaw;
	arlScor: string;
	rspStatus: string;
	listRsp: any;
	currentPs: any;
	tpStatus = 'non';
	currentJustify = 'left';
	@Input() lot: LotsDataRaw;
	@Input() ps: PsDataRaw;
	amcData: AmcDataRaw | null;
	amoData: AmoDataRaw;
	gridHelper: AgGridHelper;
	gridDataRaw: GridDataRaw;
	rspDataRaw: RspDataRaw;
	datePipe: DatePipe;
	userConnectedIdPs: any;
	callLaunchSearch: boolean = false;
	active = 1;
	constructor(
		private activatedRoute: ActivatedRoute,
		private fseService: FseService,
		private rspSvc: RSPService,
		private psSvc: PsService,
		private organismesSvc: OrganismesService,
		private location: Location,
		private scrollTopService: ScrollTopService,
		private router: Router,
		private storageService: StorageService,
		private authSvc: AuthService,
		private organismeTypePipe: OrganismeTypePipe,
		private customRouteHistory: CustomRouteHistoryService,
		private routeHistory: RouteHistoryService
	) {
		// Scroll to top
		this.scrollTopService.setScrollTop();

		this.amcData = new AmcDataRaw();
		this.amoData = new AmoDataRaw();
		const sessionPageSize = this.storageService.get('paginationPageSize', true);
		this.gridDataRaw = new GridDataRaw();
		this.gridDataRaw.size = !isNullOrUndefined(sessionPageSize) ? sessionPageSize : 10;
		this.gridHelper = new AgGridHelper('grid', this.gridDataRaw.size, 10);
	}

	ngOnInit() {
		this.initGrid();
		var ctx = this;
		const idFse = this.activatedRoute.snapshot.params.id;
		if(!parseInt(idFse)){
			this.router.navigate(['/404'])
		}
		this.fseService.getFseById(idFse).subscribe((data) => {
			if (data) {
				this.currentFse = data;
			} else {
				this.location.back();
			}
			if(!isNullOrUndefined(this.currentFse)){
				ctx.psSvc.getPsByNumAdeli({
					numAdeli: ctx.currentFse.numPs
				})
				.subscribe((data) => {
					ctx.userConnectedIdPs = data[0].idPs
					this.launchSearch();
				});
			}
			// Get PS Data
			if (this.currentFse.parts) {
				this.formatRegimeRC();
				this.formatRegimeRO();
				this.getArLScor();
			//	this.getRspStatus();
				this.getPs();
				this.getTP();
			}

			if (!isNullOrUndefined(this.currentFse.dateNaissance)) {
				this.currentFse.dateNaissance = this.currentFse.dateNaissance.slice(4)+"/"+this.currentFse.dateNaissance.slice(2,4)+"/"+this.currentFse.dateNaissance.slice(0,2)
			}

			if (this.currentFse.codeOrganismeDestinataireAmo && this.currentFse.codeGr) {
				this.organismesSvc
					.getAmoByCodeOrganismeAndCodeGr({
						codeOrgType1: this.currentFse.codeOrganismeDestinataireAmo,
						codeGr: this.currentFse.codeGr
					})
					.subscribe((data) => {
						data.length > 0 ? (this.amoData = data[0]) : '';
					});
			}

			if (this.currentFse.numOrganismeRc.trim().length > 0) {
				this.organismesSvc
					.getAmcByNumOrganisme({
						numOrganismeRc: this.currentFse.numOrganismeRc
					})
					.subscribe((data) => {
						data.length > 0 ? (this.amcData = data[0]) : null;
					});
			}
		}, err => {
			if (err.status === 400 || err.message.includes('Unable to find com.cegedim.careweb.flux.fse.model.Facture')) {
				this.router.navigate(['/404'])
			}
		});
		// Initiate date pipe format
		this.datePipe = new DatePipe('fr-FR');
	}
	initGrid() {
		this.gridHelper.gridOptions = {
			columnDefs: this.getColumnDefs(),
			defaultColDef: {
				resizable: true,
				suppressMenu: true
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 10,
			maxBlocksInCache: 0,
			infiniteInitialRowCount: 1,
			paginationPageSize: this.gridDataRaw.size,
			paginateChildRows: true,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			enableCellTextSelection: true,
			localeText: this.gridHelper.getLocaleText(),
			onGridReady: (params) => this.onGridReady(params)
		};
	}
	getColumnDefs(): ColDef[] {
		return [{
			headerName: 'Date de réception RSP',
			field: 'dateReception',
			minWidth: 205,
			valueFormatter: this.formatDate,
			lockVisible: true,
			cellStyle: this.gridHelper.centerContent(),
			sortable: true
		}
		,{
			headerName: 'Statut du RSP',
			field: 'designationStatusRsp',
			minWidth: 150,
			lockVisible: true,
			sortable: true,
			cellStyle: this.gridHelper.centerContent(),
		}
		,{
			headerName: 'Montant AMO',
			field: 'montantFactureRo',
			valueFormatter: this.formatCurrency,
			minWidth: 130,
			lockVisible: true,
			cellClass: ['font-weight-bold'],
			cellStyle: this.gridHelper.centerContent(),
		},
		 {
			headerName: 'Montant payé AMO',
			field: 'montantRo',
			minWidth: 130,
			lockVisible: true,
			valueFormatter: (params) => this.gridHelper.formatWithSigne(params.value,params.data.signe),
			cellClass: ['font-weight-bold'],
			cellStyle: this.gridHelper.centerContent(),
		},
		 {
			headerName: 'Montant AMC',
			field: 'montantFactureRc',
			minWidth: 130,
			valueFormatter: this.formatCurrency,
			lockVisible: true,
			cellClass: ['font-weight-bold'],
			cellStyle: this.gridHelper.centerContent(),
		}
		,{
			headerName: 'Montant payé AMC',
			field: 'montantRc',
			minWidth: 130,
			lockVisible: true,
			valueFormatter: (params) => this.gridHelper.formatWithSigne(params.value,params.data.signe),
			cellClass: ['font-weight-bold'],
			cellStyle: this.gridHelper.centerContent(),
		}
		,{
			headerName: 'Code organisme',
			field: 'idOrganisme',
			minWidth: 150,
			lockVisible: true,
			cellStyle: this.gridHelper.centerContent()
			}
		,{
			headerName: 'Libellé organisme',
			field: 'libelleOrganisme',
			minWidth: 180,
			lockVisible: true,
			sortable: true,
			cellStyle: this.gridHelper.centerContent(),}
		,{
			headerName: 'Type organisme',
			field: 'typeOrganisme',
			valueFormatter: (params) => this.organismeTypePipe.transform(params.value),
			minWidth: 150,
			lockVisible: true,
			cellStyle: this.gridHelper.centerContent()}
		,{
			headerName: 'Téléphone organisme ',
			field: 'telephoneOrganisme',
			minWidth: 200,
			lockVisible: true,
			cellStyle: this.gridHelper.centerContent(),
			sortable: true}
		,{
			headerName: '',
			field: 'options',
			minWidth: 70,
			lockVisible: true,
			cellRenderer: RspPreviewComponent,
			cellStyle: this.gridHelper.centerContent(),
			cellRendererParams: {
				iconClass: 'fa fa-eye',
				redirect: 'rspDetailsFse'
			}
		}
		];
	}
	onGridReady(params){
		this.gridHelper.gridApi = params.api;
		this.gridHelper.gridColumnApi = params.columnApi;
		this.restoreGrid();
		this.initGrid();
		this.initSorting();
		if(this.callLaunchSearch){
			this.launchSearch();
		}
	}

	launchSearch(){
		const ctx = this;
		if (!ctx.isAuthorized()) {
			// Connect By PS
			ctx.userConnectedIdPs = JSON.parse(localStorage.getItem('careweb_user')).idPs || JSON.parse(localStorage.getItem('idPs')) || null;
		} else {
			// Connect By Admin Or Hotline
			//this.userConnectedIdPs = JSON.parse(localStorage.getItem('idPs'));
			ctx.psSvc.getPsByNumAdeli({
				numAdeli: ctx.currentFse.numPs
			})
			.subscribe((data) => {
				ctx.userConnectedIdPs = data[0].idPs
			});
		}
		if(ctx.userConnectedIdPs != null && this.gridHelper.gridApi != undefined) {
			const dataSource: IServerSideDatasource = {
				getRows: function (paramsRows: IServerSideGetRowsParams) {
					ctx.gridHelper.gridOptions.columnApi = ctx.gridHelper.gridColumnApi;
					const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
					ctx.rspDataRaw = new RspDataRaw();
					ctx.gridHelper.gridApi.paginationSetPageSize(ctx.gridDataRaw.size);
					ctx.rspSvc
						.searchRspsByIdPsWithDeleguesNumFacture(
							{
								page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
								size: ctx.gridHelper.gridApi.paginationGetPageSize(),
								idPs: ctx.userConnectedIdPs,
								numeroFacture:ctx.currentFse.numFacture,
								sorts
							},
							ctx.rspDataRaw
						)
						.subscribe(
							(data) => {
								const rowCount = data.totalElements;
								ctx.gridHelper.manageNoRowsOverlay(rowCount);
								paramsRows.success({"rowData": data.content, "rowCount": rowCount});
								const pageN = Number.parseInt(sessionStorage.getItem('rspCurrentPage'));
								if (
									!isNullOrUndefined(pageN) &&
									pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
									pageN > 0
								) {
									ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
								}
								sessionStorage['rspCurrentPage'] = null;
							},
							() => paramsRows.fail()
						);
				}
			};
			this.gridHelper.gridApi.setServerSideDatasource(dataSource);
			this.gridHelper.gridApi.sizeColumnsToFit();
		}
	}

	resetGridState() {
		this.gridHelper.resetGridState();
		this.gridDataRaw.size = this.gridHelper.defaultPaginationPageSize;
		this.initSorting();
	}

	private initSorting() {
		const sort = [
			{
				colId: 'dateReception',
				sort: 'desc',
				sortIndex: 0
			}
		];
		return this.gridHelper.applyColumnState(sort);
	}

	onPaginationSizeChange(paginationSize: number): void {
		this.gridHelper.changePaginationSize(paginationSize);
		this.launchSearch();
		this.gridHelper.saveGridStatePrefix();
	}

	isAuthorized() {
		return this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
	}

	restoreGrid() {
		this.gridHelper.restoreGridStatePrefix();
		this.gridDataRaw.size = this.gridHelper.paginationPageSize;
	}

	formatDate = (params): string => {
		return this.datePipe.transform(params.value, 'short');
	};

	formatCurrency = (params): string => {
		if (params.data) {
			if (params.value) {
				return params.value / 100 + ' €';
			} else {
				return '0.00 €';
			}
		}
	};

	onTabChange(event: any){
		this.callLaunchSearch = false;
		const activeTabId = event.nextId;
		sessionStorage.setItem('fiche-fse-tab', activeTabId);
		if(activeTabId == 'Informations'){
			this.initGrid();
			this.callLaunchSearch = true;
		}
	}

	goToFSE() {
		if (this.customRouteHistory.getHistory().length) {
			this.customRouteHistory.goBack()
		} else {
			if(this.routeHistory.getBeforeSecondLastUrl()?.includes('/rsp-details') || this.routeHistory.getPreviousUrl()?.includes('/rsp-details')  ){
				this.location.back();
			}else {
				this.router.navigate(['/fse']);
			}
		}
	}

	goToLotDetails(): void {
		this.router.navigate([ '/lot-details', this.currentFse.idLot ]);
	}

	formatRegimeRO = () => {
		if (this.currentFse) {
			const list: PartsDataRaw[] = this.currentFse.parts;
			list.forEach((element) => {
				if (element.partType == 'RO') {
					this.currentRO = element;
				}
			});
		}
	};

	formatRegimeRC = () => {
		if (this.currentFse) {
			const list: PartsDataRaw[] = this.currentFse.parts;
			list.forEach((element) => {
				if (element.partType == 'RC') {
					this.currentRC = element;
					this.currentRC['undefinedRc'] = element && element.codeOrganismeDestinataire ? 'Inconnu (' + element.codeOrganismeDestinataire.trim() + ')' : ''
				}
			});
		}
	};

	getArLScor = () => {
		if (this.currentFse) {
			let isDone = 'Non';
			if (this.currentFse.lot.signeArlScor) {
				isDone = 'Oui';
			}
			this.arlScor = isDone;
		}
	};

	getRspStatus = () => {
		this.rspSvc.getRspByNumeroFacture(this.currentFse.numFacture).subscribe(
			(data) => {
				this.rspStatus = data.length > 0 ? 'Oui' : 'Non';
			}
		);
	};

	getPs = () => {
		this.psSvc
			.getPsByNumAdeli({
				numAdeli: this.currentFse.numPs
			})
			.subscribe((data) => {
				this.currentPs = data[0];
			});
	};

	getTP = () => {
		if (Number(this.currentFse.destinataireDuReglement) > 0) {
			this.tpStatus = 'Oui';
		}
	};

	formatRoRc(params, key) {
        let total = params?.signe === 0 ? params?.cumulMontantFacture * -1 : params?.cumulMontantFacture
        let ro = params?.montantRo ? parseFloat(params.montantRo) / 100 : 0
        let rc = params?.montantRc ? parseFloat(params.montantRc) / 100 : 0
        switch (key) {
            case 'montantRc': { return ro + rc * -1 === total || ro * -1 + rc * -1 === total ? (rc * -1).toString() : rc.toString(); break; }
            case 'montantRo': { return rc + ro * -1 === total || ro * -1 + rc * -1 === total ? (ro * -1).toString() : ro.toString(); break; }
        }
    }
}
