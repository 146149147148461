import { OnlyPostiveDoubleDirective} from './only-positive-double.directive';
import { NgModule } from '@angular/core';
import { FilterMinHeightDirective } from './filter-min-height.directive';
import { EmailValidatorDirective } from './email-validator.directive';
import { CompareToDirective } from './compare-to.directive';
import { PwdValidatorDirective } from './pwd-valid.directive';
import { CompareDateDirective } from './compare-date.directive';
import { OnlyNumDirective } from './only-num.directive';
import { SubmitOnEnterDirective } from './submit-on-enter.directive';
import {OnlyDoubleDirective} from "./only-double.directive";
@NgModule({
	imports: [],
	declarations: [	
		FilterMinHeightDirective,
		EmailValidatorDirective,
		CompareToDirective,
		PwdValidatorDirective,
		CompareDateDirective,
		OnlyNumDirective,
		SubmitOnEnterDirective,
		OnlyPostiveDoubleDirective,
		OnlyDoubleDirective,
   ],
	exports: [
		FilterMinHeightDirective,
		EmailValidatorDirective,
		CompareToDirective,
		PwdValidatorDirective,
		CompareDateDirective,
		OnlyNumDirective,
		SubmitOnEnterDirective,
		OnlyPostiveDoubleDirective,
		OnlyDoubleDirective
	]
})
export class DirectivesModule {}
