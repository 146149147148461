export const CONF = Object.freeze({
	envUrl: '/careweb-back',
	appContext: '/careweb',
	apiBaseUrl: '/api',
	helpUrl: 'lienAide',
});

export const EMAIL_REGEXP = /^\S+@\S+\.\S{2,}$/;

// URL du back office
export const URL_BO = 'http://rebebsoctflow01:8080/oct-backoffice/factures.do?method=displayByIdFlux&currentPage=1&idFlux=';
export const URL_BO_FSE = 'http://rebebsoctflow01:8080/oct-backoffice/factures.do?method=displayFacture&idFacture=';
export const URL_BO_BOITE_MAIL = "http://rebebsoctflow01:8080/oct-backoffice/boiteMail.do?method=voirContenuBoiteMail&idBoiteMail=";

interface RouteInfo {
	path: string;
	title: string;
}

export const ROUTES: RouteInfo[] = [
	{
		path: '/dashboard',
		title: 'Tableau de bord'
	},
	{
		path: '/ps',
		title: 'Professionnels de la santé'
	},
	{
		path: '/lots',
		title: 'Lots'
	},
	{
		path: '/arl',
		title: 'ARL'
	},
	{
		path: '/fse',
		title: 'FSE'
	},
	{
		path: '/rsp',
		title: 'RSP'
	},
	{
		path: '/retours-editables',
		title: 'Retours éditables'
	},
	{
		path: '/comptabilite',
		title: 'Comptabilité'
	},
	{
		path: '/loi',
		title: 'LOI'
	},
	{
		path: '/organismes',
		title: 'Organismes'
	},
	{
		path: '/regroupements',
		title: 'Regroupements'
	},
	{
		path: '/type0',
		title: 'Type 0'
	},
	{
		path: '/hotline',
		title: 'Hotline'
	},
	{
		path: '/editeurs',
		title: 'Éditeurs'
	},
	{
		path: '/users',
		title: 'Comptes OCT'
	},
	{
		path: '/reporting',
		title: 'reporting'
	}
];
