import { OrganismeDataRaw } from './organismeDataRaw';
import { CodeEntreeDataRaw } from './codeEntreeDataRaw';

export class AmcDataRaw extends OrganismeDataRaw {
	codeOfficiel: string;
	libelleCodeEntree: string;
	aidePerenne: Boolean;
	montantRemunere: string;
	babusiaux: boolean;
	codeEntrees: CodeEntreeDataRaw[];
	dateDebutValidite: string;
	dateFinValidite: string;
	changeCodeNSV: boolean;
	idTypePs: Number;
	psOcts: Number[];
}
