import { Input, Directive } from '@angular/core';

/** Classe de base pour les composants accordéon de filtre. */
@Directive()
export abstract class AccordionGroupBase {
    // Titre du filtre (clé à traduire)
    @Input()
    title: string;

    // Nombre d'élement de recherche pour ce filtre
    @Input()
    badgeCount: number;
}
