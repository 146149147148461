import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { saveAs } from 'file-saver';
import { isEmpty } from 'lodash-es';
import { defineLocale, frLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { isNullOrUndefined } from 'util';
import { AuthService } from '../../../core/services/auth.service';
import { OctService } from '../../../core/services/oct.service';
import { FileExport } from '../../../data/FileExportDataRaw';
import { ComptabiliteFilterRaw } from '../../../data/filters/comptabilite-filter';
import { OctDataRaw } from '../../../data/octDataRaw';
import { PsDelegueDataRaw } from '../../../data/PsDelegueDataRaw';
import { RetourEditableDataRaw } from '../../../data/retourEditableDataRaw';
import { PsService } from '../../ps/ps.service';
import { PsDelegueService } from '../../ps/service/ps-delegue.service';
import { ComptabiliteService } from './comptabilite.service';

@Component({
	selector: 'careweb-app-comptabilite',
	templateUrl: './comptabilite.component.html',
	styleUrls: [ './comptabilite.component.scss' ]
})
export class ComptabiliteComponent implements OnInit {
	params: any;
	initHidden = true;
	showFilter = false;
	paginationSize: number;
	filters = new ComptabiliteFilterRaw();
	retourEditableDataRaw: RetourEditableDataRaw;
	isValidDate = false;
	retourMaxDate: Date;
	currentOct = new OctDataRaw();
	retourNumber: Number = 0;
	datePipe: DatePipe;
	typeComptabilite: String;
	endDateHidden: Boolean = false;
	numPsLength: Boolean;
	supervisedPs: PsDelegueDataRaw;
	numeroAdeli: String;
	psDelegueListFilter = new Array<PsDelegueDataRaw>();
	@ViewChild('retourForm')
	form: NgForm;
	currentDate: Date = new Date();
	canDownload = false;
	isInputChange = true;
	isDateRangeValid = true;

	constructor(
		private comptabiliteSvc: ComptabiliteService,
		private authSvc: AuthService,
		private localeService: BsLocaleService,
		private octSvc: OctService,
		private psSvc: PsService,
		private psDelegueSVC: PsDelegueService
	) {
		this.retourEditableDataRaw = new RetourEditableDataRaw();
		// Define local date fr
		frLocale.invalidDate = 'Date invalide';
		defineLocale('fr', frLocale);
	}

	ngOnInit() {
		// Set local date
		this.setLocale();
		// Inititate type de retour
		this.filters.reportId = 'COMP';
		// Get currentOct
		this.octSvc.currentOctSubject.subscribe((value) => {
			this.currentOct = value;
		});
		// Init date pipe
		this.datePipe = new DatePipe('fr-FR');
		this.numPsLength = true;
		// Apply numero Ps on the filter if exist
		const numeroPsLocalStorage = localStorage.getItem('numeroPs');
		if (!isEmpty(numeroPsLocalStorage)) {
			this.filters.numeroAdeli = numeroPsLocalStorage;
		}

		// Get numero PS if user is PS
		if (!this.isAuthorized()) {
			this.psSvc.getPsById(this.authSvc.currentUserValue.idPs).then((data) => {
				
				this.numeroAdeli = data.numeroAdeli;
				if (data && data.octPsDtos.length > 0 && !isNullOrUndefined(data.octPsDtos[0])) {
					this.currentOct = new OctDataRaw().withIdOct(data.octPsDtos[0].idOct);
				}
			});
		}

		if (!this.isAuthorized()) {
			this.loadComboBoxFilterNumeroPS_Supervise();
		}
		this.loadFilterStorage();

		this.filters.reportId = sessionStorage.getItem('comptabilite-report-id') ? sessionStorage.getItem('comptabilite-report-id') : 'COMP';
		this.filters.beginDate = sessionStorage.getItem('comptabilite-report-debut') ? new Date(sessionStorage.getItem('comptabilite-report-debut')) : null;
		this.setRetourMaxDate(this.filters.beginDate);
		this.filters.endDate = sessionStorage.getItem('comptabilite-report-fin') ? new Date(sessionStorage.getItem('comptabilite-report-fin')) : null;
		this.loadFilterStorage();
	}

	loadFilterStorage() {
		const numeroPsLocalStorage = localStorage.getItem('numeroPs');
		const numeroAdeli = sessionStorage.getItem('comptabilite-numeroAdeli');
		if (!isEmpty(numeroPsLocalStorage) && !isEmpty(numeroAdeli)) {
			if(numeroPsLocalStorage != numeroAdeli) {
				this.filters =  new ComptabiliteFilterRaw();
				sessionStorage.removeItem('comptabilite-report-debut');
				sessionStorage.removeItem('comptabilite-report-fin');
			}
			this.filters.numeroAdeli = numeroPsLocalStorage;
			sessionStorage['comptabilite-numeroAdeli'] = numeroPsLocalStorage;
		}
	}

	existGroupNumeroPS() {
		return this.isAuthorized() || this.psDelegueListFilter.length !== 0;
	}
	haveSupervisedPss(){
		return this.psDelegueListFilter.length !== 0;
	}

	loadComboBoxFilterNumeroPS_Supervise() {
		const userConnected = JSON.parse(localStorage.getItem('careweb_user'));
		if (!isEmpty(userConnected)) {
			if (!isNullOrUndefined(userConnected.idPs)) {
				this.psDelegueSVC.getPsDelegueByIdPs(userConnected.idPs).subscribe((data) => {
					if (!isNullOrUndefined(data) && !isEmpty(data)) {
						this.psDelegueListFilter = data;
						const psCurrent = new PsDelegueDataRaw();
						psCurrent.idPsDelegue = this.psDelegueListFilter[0].idPsAssocie;
						psCurrent.numeroPsDelegue = this.psDelegueListFilter[0].numeroPsAssocie;
						psCurrent.nomPsDelegue = this.psDelegueListFilter[0].nomPsAssocie;

						this.psDelegueListFilter.push(psCurrent);
						// Apply numero Ps supervised on the filter if exist
						var numeroPsSupervisedLocalStorage = localStorage.getItem('supervised_ps');
						if (!isEmpty(numeroPsSupervisedLocalStorage)) {
							if (numeroPsSupervisedLocalStorage === '-1') {
								this.supervisedPs = null;
							}
							else {
								numeroPsSupervisedLocalStorage = numeroPsSupervisedLocalStorage.split(';')[0];
								//this.numeroAdeli = numeroPsSupervisedLocalStorage;
								this.supervisedPs = this.psDelegueListFilter.find(p => p.numeroPsDelegue === numeroPsSupervisedLocalStorage);
							}
						}
					}
				});
			}
		}
	}

	private setLocale() {
		this.localeService.use('fr');
	}
	checkDateRangeValidity(): boolean {
		this.isInputChange=false;

		if (this.filters.beginDate && this.filters.endDate) {
			const beginDateUTC = Date.UTC(this.filters.beginDate.getFullYear(), this.filters.beginDate.getMonth(), this.filters.beginDate.getDate());
			const endDateUTC = Date.UTC(this.filters.endDate.getFullYear(), this.filters.endDate.getMonth(), this.filters.endDate.getDate());
			// différence du jours
			const diffInDays = Math.abs((endDateUTC - beginDateUTC) / (1000 * 60 * 60 * 24));
			if (diffInDays> 30  && (this.filters.reportId == 'REGLE' || this.filters.reportId == 'COMP')) {
				return false;
			}else{
				return true;
			}
		}else {
			if(this.filters.beginDate && this.filters.reportId == 'RETARD' ){
				return true;
			}
			return false;
		}
	}
	checkComptabiliteReport() {
		const numeroAdeli = this.isAuthorized() ? this.filters.numeroAdeli : this.supervisedPs.numeroPsDelegue;
		if (this.checkDateRangeValidity() && numeroAdeli && numeroAdeli.length==9 ) {
			sessionStorage.setItem('comptabilite-report-id', this.filters.reportId.toString());
			sessionStorage.setItem('comptabilite-report-debut', this.filters.beginDate?.toString());
			if (!isNullOrUndefined(this.filters.endDate)) {
				sessionStorage.setItem('comptabilite-report-fin', this.filters.endDate.toString());
			}
			sessionStorage.setItem('comptabilite-numeroAdeli', this.filters.numeroAdeli + '');
			this.canDownload = true;
		}
	}

	onSearch(){
		if (this.checkDateRangeValidity()) {
			this.isDateRangeValid= true;
		}else {
			this.isDateRangeValid= false;
		}
	}
	setRetourMaxDate($event) {
		if($event){
			if (
				this.filters.reportId == 'REGLE' || this.filters.reportId == 'COMP'
			) {
				let newDate = new Date($event);
				if(isNaN(newDate.getTime())){
					newDate.setDate(this.filters.beginDate?.getDate());
					this.retourMaxDate = this.retourMaxDate;
				}else{
					let dayDifference = Math.floor(Math.abs(new Date().getTime() - newDate?.getTime()) / (1000 * 3600 * 24));
					newDate.setDate(newDate.getDate() + (dayDifference < 30 ? dayDifference : 30));
					this.retourMaxDate = newDate;
				}
			} else {
				this.retourMaxDate = new Date();
			}
			this.hideDownloadDiv()
		}
	}
	
	resetErrors(){
		const numPs=this.form.controls.numPs.value
		const numeroPScomboBox=this.form.controls.numeroPScomboBox.value
		const typeComptabilite=this.form.controls.typeComptabilite.value
		const dateMin=this.form.controls.dateMin.value
		const dateMax=this.form.controls.dateMax.value
		this.form.resetForm()
		 this.form.controls.numPs.setValue(numPs)
		this.form.controls.numeroPScomboBox.setValue(numeroPScomboBox)
		this.form.controls.typeComptabilite.setValue(typeComptabilite)
		this.form.controls.dateMin.setValue(dateMin)
		this.form.controls.dateMax.setValue(dateMax)
	}

	changeInputs($event) {
		this.resetErrors();
		this.isInputChange=true;
		this.setRetourMaxDate(this.filters.beginDate);
		$event === 'RETARD' ? (this.endDateHidden = true) : (this.endDateHidden = false);
		this.hideDownloadDiv();		
	}

	isAuthorized() {
		return this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
	}

	formatDate = (params): string => {
		if (params.value !== null && params.value !== 'Invalid Date') {
			return this.datePipe.transform(params.value, 'shortDate');
		}
	};

	getComptabiliteContent(reportType) {
		const criteria = {
			isPsUser: !this.isAuthorized(),
			numeroAdeli: this.isAuthorized() ? this.filters.numeroAdeli : this.numeroAdeli,
			reportId: this.getReportId(this.filters.reportId),
			beginDate: this.filters.beginDate || null,
			endDate: this.filters.endDate || new Date().toISOString(),
			reportType: reportType,
			octId: this.currentOct != null ? this.currentOct.idOct : null
		};
		this.comptabiliteSvc.getComptabiliteFileContent(criteria).subscribe((response: FileExport) => {
			saveAs(response.file, response.fileName);
		});
	}

	getReportId(reportId) {
		let returnedReportId: Number;
		switch (reportId) {
			case 'COMP':
				returnedReportId = 0;
				break;
			case 'RETARD':
				returnedReportId = 1;
				break;
			case 'REJET':
				returnedReportId = 2;
				break;
			case 'REGLE':
				returnedReportId = 3;
				break;
			case 'PRESTA':
				returnedReportId = 4;
				break;
		}
		return returnedReportId;
	}

	hideDownloadDiv(){
		this.isDateRangeValid=true;
		this.canDownload = false;
	}
}
