import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONF } from '../../../core/constants';
import { PsDelegueDataRaw } from '../../../data/PsDelegueDataRaw';

@Injectable({
	providedIn: 'root'
})
export class PsDelegueService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/psdelegue';

	constructor(private httpClient: HttpClient) {}

	getPsDelegueByIdPs(idPsDelegue): Observable<Array<PsDelegueDataRaw>> {
		return this.httpClient.get<Array<PsDelegueDataRaw>>(this.baseUrl + '/list/delegue/' + idPsDelegue);
	}

	getPsAssocieByIdPs(idPs): Observable<Array<PsDelegueDataRaw>> {
		return this.httpClient.get<Array<PsDelegueDataRaw>>(this.baseUrl + '/list/associe/' + idPs);
	}

	deletePsSuperviseById(id): Promise<PsDelegueDataRaw> {
		return this.httpClient.delete<PsDelegueDataRaw>(this.baseUrl + '/' + id).toPromise();
	}

	saveSupervise(idPs, listPsSupervise): Observable<Array<PsDelegueDataRaw>> {
		return this.httpClient.post<Array<PsDelegueDataRaw>>(this.baseUrl + '/create/?idPs=' + idPs, listPsSupervise);
	}

	countPsDelegueByIdPs(idPsDelegue: number): Observable<number> {
		return this.httpClient.get<number>(this.baseUrl + '/count/delegue/' + idPsDelegue);
	}
}
