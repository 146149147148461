import { NumeroEmetteurDataRaw } from './numeroEmetteurDataRaw';

export class TypeZeroDataRaw {
	idTypeZero: number;
	typeEmetteur: string;
	numeroEmetteur: NumeroEmetteurDataRaw;
	idEmetteur: number;
	typeDestinataire: string;
	compactage: boolean;
	cryptage: boolean;
	regroupement: string;
	numDestinataire: string;
	numDestDyn: boolean;
	identifiantFichier: number;
	programmeEmetteur: string;
	programmeDestinataire: string;
	application: string;
	longueurEnregistrement: number;
}
