import { AccordionGroupComponent } from './../../../components/accordion/accordion-group.component';
import { DateFromToFilterComponent } from './../../../components/date-from-to/date-from-to-filter.component';
import {
    GridOptions,
    IServerSideDatasource,
    IServerSideGetRowsParams
} from 'ag-grid-enterprise';
import { DatePipe } from '@angular/common';
import {Component, OnInit, ViewChild, OnDestroy, HostListener} from '@angular/core';
import { Router } from '@angular/router';
import 'ag-grid-enterprise';
import { isEmpty } from 'lodash';
import { uniqBy, findIndex } from 'lodash-es';
import { AgGridHelper } from '../../../components/ag-grid/ag-grid-helper';
import { gridConvertSort } from '../../../components/ag-grid/ag-grid.utils';
import { ColumnSelectorComponent } from '../../../components/ag-grid/grid-column-selector.component';
import { ColumnSpec } from '../../../components/ag-grid/grid-column-spec';
import { AuthService } from '../../../core/services/auth.service';
import { OctService } from '../../../core/services/oct.service';
import { FilterItem } from '../../../data/filters/filter-item';
import { LotsFilterRaw } from '../../../data/filters/lots-filter';
import { LotsDataRaw } from '../../../data/lotsDataRaw';
//import { PsService } from '../../ps/ps.service';
import { LotsFilterSvc } from './lots-filter.service';
import { LotPreviewComponent } from './lots-subcomponents/lot-preview.component';
import { LotsService } from './lots.service';
import { PsDelegueService } from '../../ps/service/ps-delegue.service';
import { isNullOrUndefined } from 'util';
import { PsDelegueDataRaw } from '../../../data/PsDelegueDataRaw';
import { ConvertDateFormat } from '../../../core/utilities/convert-date-format.service';
import { PsService } from '../../ps/ps.service';
import { RouteHistoryService } from '../../../shared/route-history.service';
import { GridDataRaw } from '../../../data/gridDataRaw';
import { StorageService } from '../../../shared/storage-service';
import { ExportUtilService } from '../../../core/utilities/export-util.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ExportModalComponent, ExportMode } from '../../export-modal/export-modal.component';
import { saveAs } from 'file-saver';
import {TransformService} from "../../../core/services/transform.service";
import {ExportState, State} from "../../../shared/model/storage.model";


@Component({
    selector: 'careweb-app-lots',
    templateUrl: './lots.component.html',
    styleUrls: ['./lots.component.scss']
})
export class LotsComponent implements OnInit, OnDestroy {
    //public modules: Module[] = AllModules;
    params: any;
    initHidden = true;
    showFilter = false;
    gridHelper: AgGridHelper;
    filterList: FilterItem[];
    filters: LotsFilterRaw;
    datePipe: DatePipe;
    lotsDataRaw: LotsDataRaw;
    currentOct;
    psDelegueListFilter = new Array<PsDelegueDataRaw>();
    @ViewChild(ColumnSelectorComponent, { static: true })
    columnSelector: ColumnSelectorComponent;
    totalResultsHidden = false;
    originalColumnOrder: string[] = [];
    static MIN_WIDTH = 1920;
    exportableColumns = [
        'sv',
        'securise',
        'tiersPayant',
        'numLot',
        'nbFactures',
        'montantTotal',
        'organismeDestinataire',
        'numeroPs',
        'dateReceptionPartition',
        'dateEnvoiePartRo',
        'dateEnvoiDernierArt',
        'dateDernierArl',
        'signeArl',
        'nbreArlScanOrdo',
        'nbPartRo',
        'montantRo',
        'montantRc',
        'rejetNB',
        'rejetRO',
        'rejetRC'
    ];
    gridDataRaw: GridDataRaw;

    @ViewChild('dateEnvoie')
    dateEnvoie: DateFromToFilterComponent;
    isDateRangeValid = true;

    @ViewChild('dateAccordion')
    dateAccordion: AccordionGroupComponent;
    public rowCount: number = 0;
    private openedModal: BsModalRef;
    export: boolean = false;
    exportState: ExportState;

    constructor(
        private lotsSvc: LotsService,
        private authSvc: AuthService,
        private router: Router,
        private lotsFilterSvc: LotsFilterSvc,
        private octSvc: OctService,
        private psSVC: PsService,
        private psDelegueSVC: PsDelegueService,
        private convertFormatDate: ConvertDateFormat,
        private routeHistory: RouteHistoryService,
        private storageService: StorageService,
        private modalService: BsModalService,
        private transformService: TransformService
    ) {
        //Get grid size from session if already stored
        const sessionPageSize = this.storageService.get('lotsPaginationPageSize', true);
        this.gridDataRaw = new GridDataRaw();
        this.gridDataRaw.size = !isNullOrUndefined(sessionPageSize) ? sessionPageSize : 10;

        this.gridHelper = new AgGridHelper('lots', this.gridDataRaw.size, 10);
        this.lotsDataRaw = new LotsDataRaw();
        this.filters = new LotsFilterRaw();
    }

    ngOnInit() {
        this.parseFilters();
        // verify previous url if not details remove session currentPage
        if (
            this.routeHistory?.getPreviousUrl()?.indexOf('lot-details') === -1 &&
            this.routeHistory?.getPreviousUrl()?.indexOf('ps-details') === -1
        ) {
            sessionStorage['lotsCurrentPage'] = null;
        }

        // Apply numero Ps on the filter if exist
        const numeroPsLocalStorage = localStorage.getItem('numeroPs');
        if (!isEmpty(numeroPsLocalStorage)) {
            if(this.filters.numeroPs && this.filters.numeroPs != numeroPsLocalStorage) {
				this.filters =  new LotsFilterRaw();
			}
            this.filters.numeroPs = numeroPsLocalStorage;
            sessionStorage['lotsFilter'] = JSON.stringify(this.filters);
        }
        // Apply numero Ps supervised on the filter if exist
		var numeroPsSupervisedLocalStorage = localStorage.getItem('supervised_ps');
		if (!isEmpty(numeroPsSupervisedLocalStorage)) {
			if (numeroPsSupervisedLocalStorage === '-1') {
				this.filters.numeroPScomboBox = null;
			}
			else {
				numeroPsSupervisedLocalStorage = numeroPsSupervisedLocalStorage.split(';')[0];
				if (this.filters.numeroPScomboBox && this.filters.numeroPScomboBox != numeroPsSupervisedLocalStorage) {
					this.filters = new LotsFilterRaw();
				}
				this.filters.numeroPScomboBox = numeroPsSupervisedLocalStorage;
				sessionStorage['lotsFilter'] = JSON.stringify(this.filters);
			}
		}

        sessionStorage.setItem('fiche-lot-tab','Informations');
        // Get current oct
        this.currentOct = JSON.parse(localStorage.getItem('octCurrent'));
        if (isNullOrUndefined(this.currentOct)) {
            this.octSvc.currentOctSubject.subscribe((value) => {
                this.currentOct = value;
            });
        }

        // Init datePipe
        this.datePipe = new DatePipe('fr-FR');

        // Init grid options
        this.gridHelper.gridOptions = <GridOptions>(<GridOptions>{
            columnDefs: this.getColumnDefs(),
            defaultColDef: {
                suppressMenu: true,
                resizable: true
            },
            context: {
                componentParent: this
            },
            components: {
                formatArlComponent: this.formatArlComponent
            },
            domLayout: 'autoHeight',
            rowHeight: 50,
            headerHeight: 50,
            rowModelType: 'serverSide',
            suppressServerSideInfiniteScroll: false,
            pagination: true,
            cacheBlockSize: 10,
            maxBlocksInCache: 0,
            infiniteInitialRowCount: 1,
            paginationPageSize: this.gridDataRaw.size,
            paginateChildRows: true,
            suppressScrollOnNewData: true,
            suppressPaginationPanel: true,
            tooltipShowDelay: 0,
            suppressContextMenu: true,
            localeText: this.gridHelper.getLocaleText(),
            enableCellTextSelection: true,
            onRowClicked: (params) => this.onRowClicked(params),
            getRowClass: (params) => this.stylePreviouslyClickedRow(params),
            onGridReady: (params) => this.onGridReady(params),
            onColumnMoved: (params) => this.onColumnChanged(params),
            onColumnVisible: (params) => this.onColumnChanged(params)
        });
        this.originalColumnOrder = this.getColumnDefs().map(column => column.field);
    }

    onRowClicked(params: any) {
        sessionStorage.previouslyClickedLotId = params.node.data.idLot;
    }
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        let width = event.target.innerWidth;
        this.setColumnOrder(width);

    }
    setColumnOrder(width:any) {
        const columnDefs = this.getColumnDefs();
        const columnApi = this.gridHelper.gridColumnApi;
        if(width<=1920){
            const optionsColumnIndex = columnDefs.findIndex(column => column.field === 'options');
            const fluxIdColumnIndex = columnDefs.findIndex(column => column.field === 'idFlux');
            if (optionsColumnIndex !== -1 || fluxIdColumnIndex!== -1) {
                columnApi.moveColumn('options', 0);
                columnApi.moveColumn('idFlux', 1);
            }
        }else {
            this.gridHelper.gridApi.sizeColumnsToFit();
            const column = this.gridHelper.gridColumnApi.getColumn('nbreArlScanOrdo');
            const columnVisible=column.isVisible();
            this.gridHelper.gridApi.setColumnDefs(columnDefs);
            this.gridHelper.gridColumnApi.setColumnVisible('nbreArlScanOrdo',columnVisible);


        }
    }
    stylePreviouslyClickedRow(params: any) {
        let rowNode = params.node;
        if (!isNullOrUndefined(rowNode.data)) {
            let previouslyClickedRow = JSON.parse(sessionStorage.getItem('previouslyClickedLotId'));
            if (rowNode.data.idLot === previouslyClickedRow) {
                return 'ag-row-selected';
            }
        }
    }

    parseFilters() {
        var parsedFilter = JSON.parse(sessionStorage.getItem('lotsFilter'));
        // Get filter stored in session if exist
        if (!isEmpty(parsedFilter)) {
            this.filters = Object.assign({}, parsedFilter);
            // Convert String dates to Dates format
            this.filters.dateEnvoieFrom ? (this.filters.dateEnvoieFrom = new Date(parsedFilter.dateEnvoieFrom)) : '';
            this.filters.dateEnvoieTo ? (this.filters.dateEnvoieTo = new Date(parsedFilter.dateEnvoieTo)) : '';
            this.filters.dateEnvoieExacte ? (this.filters.dateEnvoieExacte = new Date(parsedFilter.dateEnvoieExacte)) : '';
        } else {
            this.filterList = [new FilterItem()];
        }
    }

    onGridReady(params) {
        var width = window.innerWidth;
        const ctx = this;
        ctx.initFilter();
        this.gridHelper.gridApi = params.api;
        this.gridHelper.gridColumnApi = params.columnApi;
        this.initSorting();
        this.restoreGridState();
        this.initColumnsDisplay();
        let parsedFilter = JSON.parse(sessionStorage.getItem('lotsFilter'));
        this.setColumnOrder(width);
        if (parsedFilter) {
            Object.keys(parsedFilter).forEach(
                (key) => (parsedFilter[key] === undefined || parsedFilter[key] === null ? delete parsedFilter[key] : {})
            );
        }
        if (!isEmpty(parsedFilter)) {
            this.launchSearch();
        } else {
            const dataSource: IServerSideDatasource = {
                getRows: function (paramsRows: IServerSideGetRowsParams) {
                    const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
                    let criteria;
                    // Connect By Admin Or Hotline
                    if (!ctx.isAuthorized()) {
                        const criteriaLot = new LotsDataRaw();
                        criteriaLot.tiersPayant = null;
                        criteriaLot.nbRsp = null;
                        // Connect By PS
                        const userConnected = JSON.parse(localStorage.getItem('careweb_user'));
                        criteria = {
                            page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
                            size: ctx.gridHelper.gridApi.paginationGetPageSize(),
                            idPs: userConnected.idPs,
                            sorts
                        };
                        ctx.lotsSvc.getLotsByPS(criteria, criteriaLot, false).subscribe(
                            (data) => {
                                ctx.rowCount = data.totalElements;
                                ctx.gridHelper.manageNoRowsOverlay(data.totalElements);
                                paramsRows.success({ "rowData": data.content, "rowCount": data.totalElements });

                                const pageN = Number.parseInt(sessionStorage.getItem('lotsCurrentPage'));
                                if (
                                    !isNullOrUndefined(pageN) &&
                                    pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
                                    pageN > 0
                                ) {
                                    ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
                                }
                                sessionStorage['lotsCurrentPage'] = null;
                                ctx.totalResultsHidden = true;
                            },
                            () => paramsRows.fail()
                        );
                    }
                }
            };
            this.gridHelper.gridApi.setServerSideDatasource(ctx.isAuthorized() ? null : dataSource);
        }
        // load combobox of numero PS if user is PS
        if (!this.isAuthorized()) {
            this.loadComboBoxFilterNumeroPS_Supervise();
        }
    }

    private initSorting() {
        let sort = [];
        if (this.isAuthorized()) {
            sort = [
                {
                    colId: 'dateReceptionPartition',
                    sort: 'desc',
                    sortIndex: 0
                },
                {
                    colId: 'numLot',
                    sort: 'desc',
                    sortIndex: 1
                }
            ];
        } else {
            sort = [
                {
                    colId: 'dateEnvoiePartRo',
                    sort: 'desc',
                    sortIndex: 0
                },
                {
                    colId: 'numLot',
                    sort: 'desc',
                    sortIndex: 1
                }
            ];
        }

        return this.gridHelper.applyColumnState(sort);
    }

    onCellClicked(params) {
        if (!this.gridHelper.isCellTextSelected()) {
            this.router.navigate(['/lot-details', params.data.idLot]);
        }
    }

    onPsCellClicked(params) {
        var ctx = this;
        if (this.isAuthorized() && !this.gridHelper.isCellTextSelected()) {
            ctx.psSVC
                .getPsByNumAdeli({
                    numAdeli: params.value
                })
                .subscribe((data) => {
                    this.router.navigate(['/ps-details', data[0].idPs]);
                });
        }
    }

    restoreGridState() {
        this.gridHelper.restoreGridStatePrefix();
        this.gridDataRaw.size = this.gridHelper.paginationPageSize;
    }

    toggleFilterDisplay() {
        let width =window.innerWidth;
        if (this.initHidden) {
            this.initHidden = false;
            setTimeout(() => {
                this.showFilter = !this.showFilter;
            }, 10);
        } else {
            this.showFilter = !this.showFilter;
            if(!this.showFilter && width>2500){
                setTimeout(() => {
                    this.gridHelper.gridApi.sizeColumnsToFit();
                }, 200);
            }
        }
    }

    initColumnsDisplay() {
        const allColumns = this.gridHelper.gridColumnApi.getColumns();
        const selectedColumns = this.gridHelper.gridColumnApi.getAllDisplayedColumns();
        this.columnSelector.setItems(this.getColumnSpecs(allColumns));
        this.columnSelector.setSelectedItems(this.getColumnSpecs(selectedColumns));
    }

    private getColumnSpecs(columns: any[]): ColumnSpec[] {
        const allowedColumns = [
            'sv',
            'securise',
            'tiersPayant',
            'signeArl',
            'nbreArlScanOrdo',
            'nbPartRo',
            'montantRo',
            'montantRc',
            'rejetNB',
            'rejetRO',
            'rejetRC'
        ];

        return columns
            .filter(
                (cd) => cd.colDef.headerName && cd.colDef.headerName.length > 0 && allowedColumns.indexOf(cd.colId) > -1
            )
            .map((cd) => {
                return {
                    id: cd.colDef.field,
                    name: cd.colDef.headerName,
                    label: cd.colDef.headerName
                };
            });
    }

    resetGridState() {
        this.gridHelper.resetGridState();
        this.gridDataRaw.size = this.gridHelper.defaultPaginationPageSize;
        this.initColumnsDisplay();
        this.initSorting();
        //this.resetAllFilter();
    }

    getColumnsAndFieldDataLogs(columnKeys: string[], dataFields ? : any) {
		let data = new Map();
		columnKeys.forEach(column => {
			switch (column) {
				case "sv":
					data.set("SV", this.fromTrueToOui(dataFields.sv));
					break;
                case "securise":
					data.set("Sécurisé", this.fromTrueToOui(dataFields.securise));
					break;
                case "tiersPayant":
					data.set("Tiers-Payant", this.fromTrueToOui(dataFields.tiersPayant));
					break;
                case "numLot":
					data.set("Lot", dataFields.numLot);
					break;
                case "nbFactures":
					data.set("Nb. FSE", dataFields.nbFactures);
					break;
                case "montantTotal":
					data.set("Montant total", this.formatCurrency({
                                            data: true,
                                            value: dataFields.montantTotal
                                        }));
					break;
                case "organismeDestinataire":
					data.set("Destinataire", dataFields.organismeDestinataire);
					break;
                case "numeroPs":
					data.set("N° de PS", dataFields.numeroPs);
					break;
                case "dateReceptionPartition":
					data.set("Date de réception", this.formatDate({value: dataFields.dateReceptionPartition}));
					break;
                case "dateEnvoiePartRo":
					data.set("Date d'envoi", this.formatDate({value: dataFields.dateEnvoiePartRo}));
					break;
                case "dateEnvoiDernierArt":
					data.set("Date envoi ART", this.formatDate({value: dataFields.dateEnvoiDernierArt}));
					break;
                case "dateDernierArl":
					data.set("Date réception ARL", this.formatDate({value: dataFields.dateDernierArl}));
					break;
                case "signeArl":
					data.set("ARL", dataFields.signeArl == true
                                    ? 'Oui (ARL Positif)'
                                    : dataFields.signeArl === false ?  'Non (ARL négatif)' : '' );
					break;
                case "nbreArlScanOrdo":
					data.set("Nb ARL SCOR", dataFields.nbreArlScanOrdo);
					break;
			}
		})
		return data;
	}

    fromTrueToOui(value: boolean){
        return value ? "Oui" : "Non";
    }

    exportFileXlsxModal(){
        const columns = this.gridHelper.gridColumnApi.getAllDisplayedColumns()
            .filter(column => column.getColDef().headerName != "")
            .map(column => column.getColDef().field)
            .map(item => {
                const match = item.match(/\.(\w+)$/);
                return match ? match[1] : item;
            });
        if (this.rowCount > 0 && this.rowCount <= 1000) {
            this.exportToExcel(columns);
        } else if (this.rowCount > 1000) {
            this.openedModal = this.modalService.show(ExportModalComponent,  { initialState: {
                    nbrElement: this.rowCount
                }, class: 'modal-lg'});
            this.openedModal.content.exportEvent.subscribe((e: any) => {
                if (e.action === ExportMode.EXPORT) {
                    // this.exportFileXlsx();
                    this.exportToExcel(columns);
                }
                if (e.action === ExportMode.EMAIL) {
                    // this.exportFileXlsx();
                    this.exportByEmail(columns);
                }
            });
        }
    }

    exportFileXlsx() {
        if(!this.gridHelper.isAvailableData()){
          return;
        }
            const ctx = this;
            let excel = new ExportUtilService();
            const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
            let criteria;

            if (!this.isAuthorized()) {
                // Le compte PS ne doit pas voir les 4 premières colonnes
                const nonExportableColumns = [
                    'numeroPs',
                    'dateReceptionPartition',
                    'dateEnvoiDernierArt',
                    'dateDernierArl'
                ];
                this.exportableColumns = this.exportableColumns.filter((item) => nonExportableColumns.indexOf(item) === -1);
            }
            let headers: Array < String > = [];
            let map = this.getColumnsAndFieldDataLogs(this.exportableColumns, this.lotsDataRaw);
            for (let key of map.keys()) {
                headers.push(key)
            }
            excel.setNameSheet('Liste_lots');
            excel.setCellTitle('D1');
            excel.setTitle('Liste de lots');
            excel.setFontTitleSize(30, true);
            excel.setHeadersToExport(headers);
            excel.setFontHeaderSize(14, true);

            if (ctx.isAuthorized()) {
                ctx.lotsSvc
                    .getLotsFilteredList(
                        {
                            page: 0,
                            size: this.rowCount,
                            export: true,
                            sorts
                        },
                        ctx.lotsDataRaw, 600000
                    ).subscribe((dataSource) => {
                            dataSource.content.forEach((data) => {
                            let map = this.getColumnsAndFieldDataLogs(this.exportableColumns, data);
                            let jsonObject = [];
                            map.forEach((value, key) => {
                                jsonObject[key] = value
                            });
                            excel.setRowData(jsonObject);
                            excel.SetWidthBeetwenColumns(20);
                        });
                            excel.addBorderStyle();
                            excel.ExportData('Liste_lots')
                   })
            } else {
                const userConnected = JSON.parse(localStorage.getItem('careweb_user'));
                criteria = {
                    size: this.rowCount,
                    page : 0,
                    idPs : userConnected.idPs,
                    sorts
                };
                ctx.lotsSvc.getLotsByPS(criteria, ctx.lotsDataRaw, true).subscribe((dataSource) => {
                            dataSource.content.forEach((data) => {
                            let map = this.getColumnsAndFieldDataLogs(this.exportableColumns, data);
                            let jsonObject = [];
                            map.forEach((value, key) => {
                                jsonObject[key] = value
                            });
                            excel.setRowData(jsonObject);
                            excel.SetWidthBeetwenColumns(20);
                        });
                        excel.addBorderStyle();
                        excel.ExportData('Liste_lots')
                    })
                }
    
        }

    rowGroupCallback(params) {
        return params.node.key;
    }

    resetAllFilter(): void {
        this.dateEnvoie.isDateRangeValid = true;
        this.isDateRangeValid = true;

        if (!this.isAuthorized()) {
            this.initFilter();
            this.filters = new LotsFilterRaw();
            this.launchSearch();
            this.lotsFilterSvc.reset();
        } else {
            this.filters = new LotsFilterRaw();
            this.rowCount = 0;
            this.lotsFilterSvc.reset();
            this.updateFilterList(this.filters);
            this.clearGridFromData();
        }
        sessionStorage.removeItem('lotsFilter');
    }

    //clear grid
    clearGridFromData() {
        if (this.isAuthorized()) {
            this.totalResultsHidden = false;
            this.gridHelper.gridApi.setServerSideDatasource(null);
            this.rowCount = 0;
        }
    }

    isAuthorized() {
        return this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
    }

    onKeyDown(event) {
        this.launchSearch();
    }

    launchSearch(): void {
        const ctx = this;
        ctx.totalResultsHidden = true;
        if (this.isFormValid()) {
            this.lotsDataRaw.idOct = ctx.currentOct ? ctx.currentOct.idOct : null;
            this.lotsDataRaw.numeroPs = this.isAuthorized()
                ? this.filters.numeroPs ? this.filters.numeroPs.replace(/\s/g, '').replace(/\./gi, '') : null
                : this.filters.numeroPScomboBox ? this.filters.numeroPScomboBox : null;
            this.filters.numLotExacte = this.formatNumLot(this.filters.numLotExacte);
            this.lotsDataRaw.numLot = this.filters.numLotExacte;
            this.filters.numLotFrom = this.formatNumLot(this.filters.numLotFrom); //@TODO: to put in utils service
            this.lotsDataRaw.numLotFrom = this.filters.numLotFrom;
            this.filters.numLotTo = this.formatNumLot(this.filters.numLotTo);
            this.lotsDataRaw.numLotTo = this.filters.numLotTo;
            this.lotsDataRaw.securise = isNullOrUndefined(this.filters.securise) ? null : this.filters.securise;
            this.lotsDataRaw.tiersPayant = isNullOrUndefined(this.filters.tiersPayant)
                ? null
                : this.filters.tiersPayant;
            this.lotsDataRaw.signeArlFilter = this.filters.presenceArl || null;
            this.lotsDataRaw.nbRsp = isNullOrUndefined(this.filters.presenceRsp) ? null : this.filters.presenceRsp;
            this.lotsDataRaw.dateEnvoiePartRoFrom = this.convertFormatDate.getFormatDateFr(this.filters.dateEnvoieFrom);
            this.lotsDataRaw.dateEnvoiePartRoTo = this.convertFormatDate.getFormatDateFr(this.filters.dateEnvoieTo);
            this.lotsDataRaw.dateEnvoiePartRoExacte = this.convertFormatDate.getFormatDateFr(this.filters.dateEnvoieExacte);
            this.filters.libelleOrganisme = this.filters.libelleOrganisme ? this.filters.libelleOrganisme.trim() : null;
            this.lotsDataRaw.libelleOrganisme = this.filters.libelleOrganisme;
            this.filters.organismeDestinataire = this.filters.organismeDestinataire
                ? this.filters.organismeDestinataire.trim()
                : null;
            this.lotsDataRaw.organismeDestinataire = this.filters.organismeDestinataire;

            this.updateFilterList(this.filters);
            Object.keys(this.lotsDataRaw).forEach((key) => {
                if (isNullOrUndefined(this.lotsDataRaw[key])) {
                    delete this.lotsDataRaw[key];
                }
            });

            const dataSource: IServerSideDatasource = {
                getRows: function (paramsRows: IServerSideGetRowsParams) {
                    const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
                    if (ctx.isAuthorized()) {
                        ctx.lotsSvc
                            .getLotsFilteredList(
                                {
                                    page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
                                    size: ctx.gridHelper.gridApi.paginationGetPageSize(),
                                    export: false,
                                    sorts
                                },
                                ctx.lotsDataRaw, 600000
                            )
                            .subscribe(
                                (data) => {
                                    ctx.rowCount = data.totalElements;
                                    ctx.gridHelper.manageNoRowsOverlay(ctx.rowCount);
                                    paramsRows.success({ "rowData": data.content, "rowCount": ctx.rowCount });

                                    const pageN = Number.parseInt(sessionStorage.getItem('lotsCurrentPage'));
                                    if (
                                        !isNullOrUndefined(pageN) &&
                                        pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
                                        pageN > 0
                                    ) {
                                        ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
                                    }
                                    sessionStorage['lotsCurrentPage'] = null;
                                },
                                () => paramsRows.fail()
                            );
                    } else {
                        // Connect By PS
                        ctx.lotsDataRaw.idOct = null;
                        const userConnected = JSON.parse(localStorage.getItem('careweb_user'));
                        const criteria = {
                            page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
                            size: ctx.gridHelper.gridApi.paginationGetPageSize(),
                            idPs: userConnected.idPs,
                            sorts
                        };
                        ctx.lotsSvc.getLotsByPS(criteria, ctx.lotsDataRaw, false).subscribe(
                            (data) => {
                                ctx.rowCount = data.totalElements;
                                ctx.gridHelper.manageNoRowsOverlay(ctx.rowCount);
                                paramsRows.success({ "rowData": data.content, "rowCount": ctx.rowCount });
                                const pageN = Number.parseInt(sessionStorage.getItem('lotsCurrentPage'));
                                if (
                                    !isNullOrUndefined(pageN) &&
                                    pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
                                    pageN > 0
                                ) {
                                    ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
                                }
                                sessionStorage['lotsCurrentPage'] = null;
                            },
                            () => paramsRows.fail()
                        );
                    }
                }
            };
            this.gridHelper.gridApi.setServerSideDatasource(dataSource);

            // Get grid to run filter operation again
            sessionStorage['lotsFilter'] = JSON.stringify(this.filters);
        }
    }

    formatNumLot(numLot) {
        if (!isNullOrUndefined(numLot)) {
            numLot = numLot.trim().toUpperCase();
            if (numLot.length == 3) {
                return numLot;
            } else if (numLot.length == 2) {
                return '0' + numLot;
            } else if (numLot.length == 1) {
                return '00' + numLot;
            }
        } else {
            return null;
        }
    }

    isFormValid(): boolean {
        let lotInputValue = true;
        if (this.filters.numLotTo && this.filters.numLotFrom) {
            if (this.filters.numLotTo < this.filters.numLotFrom) {
                lotInputValue = false;
            }
        }

        if (this.filters.dateEnvoieFrom && this.filters.dateEnvoieTo) {
            if (this.isDateRangeValid) {
                this.dateEnvoie.hideTooltlipDebut();
                this.dateEnvoie.hideTooltlipFin();
            } else {
                this.dateAccordion.expansionPannel.open();
                this.dateEnvoie.showTooltipDebut();
                this.dateEnvoie.showTooltipFin();
                lotInputValue = false;
            }
        }
        return lotInputValue;
    }

    removeFilter(id) {
        const ctx = this;
        delete ctx.filters[id];
        let currentFilterList = ctx.filterList;
        const index = findIndex(currentFilterList, (filt) => filt.id === id);
        currentFilterList.splice(index, 1);
        ctx.filterList = currentFilterList;
        if ((Array.isArray(ctx.filterList) && ctx.filterList.length) || !ctx.isAuthorized()) {
            this.fillFilterObjectFromTagsOnly();
            ctx.launchSearch();
        } else {
            ctx.clearGridFromData();
            this.rowCount = 0;
        }
    }

    fillFilterObjectFromTagsOnly() {
        this.filters = new LotsFilterRaw();
        this.filterList.forEach(filter => {
            this.filters[filter.id] = filter.value2;
        });
    }

    private getColumnDefs() {
        const columnDefs: any[] = [
            {
                headerName: 'N° de PS',
                field: 'numeroPs',
                headerClass: 'ag-header-merge',
                minWidth: 100,
                maxWidth: 150,
                lockVisible: true,
                cellClass: (params) => this.styleLink(params),
                cellStyle: this.gridHelper.centerContent(),
                onCellClicked: (params) => this.onPsCellClicked(params),
                hide: !this.existGroupNumeroPS(),
                sortable: true,
            },
            {
                headerName: 'SV',
                field: 'sv',
                headerClass: 'ag-header-merge',
                minWidth: 50,
                maxWidth: 100,
                lockVisible: true,
                cellRenderer: LotPreviewComponent,
                cellStyle: this.gridHelper.centerContent(),
                onCellClicked: (params) => this.onCellClicked(params),
                enableRowGroup: true,
                sortable: true,
                headerTooltip: 'Sesam Vital',
            },
            {
                headerName: 'Sécurisé',
                field: 'securise',
                headerClass: 'ag-header-merge',
                minWidth: 100,
                maxWidth: 150,
                lockVisible: true,
                cellRenderer: LotPreviewComponent,
                cellStyle: this.gridHelper.centerContent(),
                onCellClicked: (params) => this.onCellClicked(params),
                sortable: true,
            },
            {
                headerName: 'Tiers-Payant',
                field: 'tiersPayant',
                headerClass: 'ag-header-merge',
                minWidth: 100,
                maxWidth: 150,
                lockVisible: true,
                cellRenderer: LotPreviewComponent,
                cellStyle: this.gridHelper.centerContent(),
                onCellClicked: (params) => this.onCellClicked(params),
                sortable: true,
            },
            {
                headerName: 'Lot',
                field: 'numLot',
                headerClass: 'ag-header-merge',
                minWidth: 80,
                maxWidth: 100,
                lockVisible: true,
                cellStyle: this.gridHelper.centerContent(),
                onCellClicked: (params) => this.onCellClicked(params),
                sortable: true,
                sortIndex: 0,
                headerTooltip: 'Numéro du lot',
            },
            {
                headerName: 'Nb. FSE',
                field: 'nbFactures',
                headerClass: 'ag-header-merge',
                minWidth: 80,
                maxWidth: 100,
                lockVisible: true,
                onCellClicked: (params) => this.onCellClicked(params),
                cellStyle: this.gridHelper.centerContent(),
                sortable: true,
                cellClass: ['text-center'],
            },
            {
                headerName: 'Destinataire',
                field: 'organismeDestinataire',
                headerClass: 'ag-header-merge',
                minWidth: 100,
                lockVisible: true,
                cellStyle: this.gridHelper.centerContent(),
                onCellClicked: (params) => this.onCellClicked(params),
                sortable: true,
            },
            {
                headerName: 'Montant total',
                field: 'montantTotal',
                headerClass: 'ag-header-merge',
                valueFormatter: this.formatCurrency,
                minWidth: 100,
                maxWidth: 200,
                lockVisible: true,
                onCellClicked: (params) => this.onCellClicked(params),
                cellStyle: this.gridHelper.centerContent(),
                sortable: true,
                cellClass: ['text-center', 'font-weight-bold']
            },
            {
                headerName: 'Date de réception',
                field: 'dateReceptionPartition',
                headerClass: 'ag-header-merge',
                minWidth: 150,
                maxWidth: 200,
                valueFormatter: this.formatDate,
                lockVisible: true,
                cellStyle: this.gridHelper.centerContent(),
                onCellClicked: (params) => this.onCellClicked(params),
                hide: !this.isAuthorized(),
                sortable: true
            },
            {
                headerName: "Date d'envoi",
                field: 'dateEnvoiePartRo',
                headerClass: 'ag-header-merge',
                valueFormatter: this.formatDate,
                minWidth: 150,
                maxWidth: 200,
                lockVisible: true,
                cellStyle: this.gridHelper.centerContent(),
                onCellClicked: (params) => this.onCellClicked(params),
                sortable: true,
                sortIndex: 1
            },
            {
                headerName: 'Date envoi ART',
                field: 'dateEnvoiDernierArt',
                headerClass: 'ag-header-merge',
                valueFormatter: this.formatDate,
                minWidth: 150,
                maxWidth: 200,
                lockVisible: true,
                cellStyle: this.gridHelper.centerContent(),
                onCellClicked: (params) => this.onCellClicked(params),
                hide: !this.isAuthorized(),
                sortable: false
            },
            {
                headerName: 'Date réception ARL',
                field: 'dateDernierArl',
                headerClass: 'ag-header-merge',
                valueFormatter: this.formatDate,
                minWidth: 150,
                maxWidth: 200,
                lockVisible: true,
                cellStyle: this.gridHelper.centerContent(),
                onCellClicked: (params) => this.onCellClicked(params),
                hide: !this.isAuthorized(),
                sortable: true
            },
            {
                headerName: 'ARL',
                field: 'signeArl',
                headerClass: 'ag-header-merge',
                minWidth: 50,
                maxWidth: 100,
                lockVisible: true,
                cellRenderer: (params) => this.formatArlComponent(params),
                onCellClicked: (params) => this.onCellClicked(params),
                cellStyle: this.gridHelper.centerContent(),
                sortable: true,
            },
            {
                headerName: 'Nb ARL SCOR',
                field: 'nbreArlScanOrdo',
                headerClass: 'ag-header-merge',
                hide: true,
                minWidth: 100,
                maxWidth: 150,
                lockVisible: true,
                onCellClicked: (params) => this.onCellClicked(params),
                sortable: true,
                cellStyle: this.gridHelper.centerContent(),

            },
            {
                headerName: 'Paiement',
                headerClass: 'ag-header-group-parent',
                children: [
                    {
                        headerName: 'NB',
                        minWidth: 60,
                        maxWidth: 100,
                        field: 'lotRspDataSet.nbRspPaiement',
                        onCellClicked: (params) => this.onCellClicked(params),
                        cellStyle: this.gridHelper.centerContent(),
                        sortable: false,
                    },
                    {
                        headerName: 'AMO',
                        field: 'lotRspDataSet.montantPaiementRo',
                        minWidth: 65,
                        maxWidth: 100,
                        valueFormatter: this.formatCurrency,
                        onCellClicked: (params) => this.onCellClicked(params),
                        cellStyle: this.gridHelper.centerContent(),
                        sortable: false,
                    },
                    {
                        headerName: 'AMC',
                        field: 'lotRspDataSet.montantPaiementRc',
                        minWidth: 60,
                        maxWidth: 100,
                        valueFormatter: this.formatCurrency,
                        onCellClicked: (params) => this.onCellClicked(params),
                        cellStyle: this.gridHelper.centerContent(),
                        sortable: false,
                    }
                ]
            },
            {
                headerName: 'Rejet',
                headerClass: 'ag-header-group-parent',
                children: [
                    {
                        headerName: 'NB',
                        field: 'lotRspDataSet.nbRspRejet',
                        minWidth: 60,
                        maxWidth: 100,
                        onCellClicked: (params) => this.onCellClicked(params),
                        cellStyle: this.gridHelper.centerContent(),
                        sortable: false,
                    },
                    {
                        headerName: 'AMO',
                        field: 'lotRspDataSet.montantRejetRo',
                        minWidth: 65,
                        maxWidth: 100,
                        valueFormatter: this.formatCurrency,
                        onCellClicked: (params) => this.onCellClicked(params),
                        cellStyle: this.gridHelper.centerContent(),
                        sortable: false,
                    },
                    {
                        headerName: 'AMC',
                        field: 'lotRspDataSet.montantRejetRc',
                        minWidth: 60,
                        maxWidth: 100,
                        valueFormatter: this.formatCurrency,
                        onCellClicked: (params) => this.onCellClicked(params),
                        cellStyle: this.gridHelper.centerContent(),
                        sortable: false,
                    }
                ]
            },
            this.getBoLink(),
            {
                headerName: '',
                field: 'options',
                minWidth: 50,
                maxWidth: 50,
                cellRenderer: LotPreviewComponent,
                cellRendererParams: {
                    iconClass: 'fa fa-eye',
                    redirect: 'lotDetails'
                },
                cellStyle: this.gridHelper.centerContent(),
                onCellClicked: (params) => this.onCellClicked(params)
            }
        ];
        return columnDefs;
    }

    styleLink(params) {
        let previouslyClickedRow = sessionStorage.getItem('previouslyClickedLotId');
        if (!isNullOrUndefined(params.data) && params.data.idLot === Number(previouslyClickedRow)) {
            return 'link-in-selected-row';
        } else {
            return 'link-in-not-selected-rows';
        }
    }

    getBoLink() {
        if (this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN')) {
            return {
                headerName: '',
                field: 'idFlux',
                headerClass: 'ag-header-merge',
                cellRenderer: LotPreviewComponent,
                cellRendererParams: {
                    redirect: 'boLink'
                },
                minWidth: 50,
                maxWidth: 50,
                lockVisible: true,
                cellStyle: this.gridHelper.centerContent()
            };
        } else {
            // we can also use gridOptions.columnApi.setColumnVisible('columnName',false)
            return {
                hide: true
            };
        }
    }

    formatDate = (params): string => {
        if (params.value !== null && params.value !== 'Invalid Date') {
            return this.datePipe.transform(params.value, 'short');
        }
    };

    formatDateForTags = (params): string => {
        if (params.value !== null && params.value != 'Invalid Date') {
            return this.datePipe.transform(params.value, 'dd/MM/yyyy');
        }
    };

    formatCurrency = (params): string => {
        if (params.data) {
            if (params.value) {
                return params.value / 100 + ' €';
            } else {
                return '0.00 €';
            }
        }
    };

    formatArlComponent(params) {
        if (!isNullOrUndefined(params.value)) {
            if (params.value == true) {
                return "<span class='ui-grid-cell-contents bullet-label green status bullet' container='body' containerclass='tooltip-grid' placement='top' ng-reflect-klass='ui-grid-cell-contents bullet-l' ng-reflect-ng-class='green,status,bullet'> Positif </span>";
            } else {
                return "<span class='ui-grid-cell-contents bullet-label red status bullet' container='body' containerclass='tooltip-grid' placement='top' ng-reflect-klass='ui-grid-cell-contents bullet-l' ng-reflect-ng-class='red,status,bullet'> Négatif </span>";
            }
        }
    }

    selectColumnEvent(col: ColumnSpec) {
        this.gridHelper.gridColumnApi.setColumnVisible(col.id, true);
        this.gridHelper.gridApi.sizeColumnsToFit();
        this.addColumn(col.id);
    }

    deselectColumnEvent(col: ColumnSpec) {
        this.gridHelper.gridColumnApi.setColumnVisible(col.id, false);
        this.gridHelper.gridApi.sizeColumnsToFit();
        this.removeColumn(col.id);
    }

    initFilter() {
        this.resetFilter();
        this.updateFilterList(this.filters);
    }

    resetFilter() {
        this.lotsFilterSvc.reset();
    }

    updateFilterList(filters) {
        const ctx = this;
        ctx.filterList = [];
        Object.keys(filters).forEach((key) => {
            if (filters[key] !== null && filters[key] !== undefined && filters[key] !== '') {
                ctx.filterList.push(ctx.translateFilterLabelValue(key, filters[key]));
            }
        });
        // Remove duplicated ids
        ctx.filterList = uniqBy(ctx.filterList, 'id');
        // filter null
        ctx.filterList = ctx.filterList.filter((item) => {
            return item.id !== null;
        });
    }

    translateFilterLabelValue(key, value) {
        let translatedKey = key;
        let formattedValue = value;
        let formattedKey = key;
        const ctx = this;
        if (key === 'numeroPs' || key === 'numeroPScomboBox') {
            translatedKey = 'N° de PS';
        } else if (key === 'numLotExacte') {
            translatedKey = 'N° du lot';
        } else if (key === 'numLotFrom') {
            translatedKey = 'N° du lot supérieur ou égale à';
        } else if (key === 'numLotTo') {
            translatedKey = 'N° du lot inférieur ou égale à';
        } else if (key === 'securise') {
            translatedKey = 'Sécurisé';
            formattedValue = value ? 'Oui' : 'Non';
        } else if (key === 'tiersPayant') {
            translatedKey = 'Tiers-Payant';
            formattedValue = value ? 'Oui' : 'Non';
        } else if (key === 'presenceArl') {
            translatedKey = 'Signe des ARL';
            if (value === '2') {
                formattedValue = 'ARL négatif';
            } else if (value === '1') {
                formattedValue = 'ARL positif';
            } else if (value === '3') {
                formattedValue = 'sans ARL';
            } else {
                formattedValue = 'Tous les lots';
            }
        } else if (key === 'presenceRsp') {
            translatedKey = 'Présence de RSP';
            formattedValue = value == 1 ? 'Oui' : 'Non';
        } else if (key === 'dateEnvoieFrom') {
            translatedKey = 'Lots envoyés après le';
            formattedValue = ctx.formatDateForTags({ value: value });
        } else if (key === 'dateEnvoieTo') {
            translatedKey = 'Lots envoyés avant le';
            formattedValue = ctx.formatDateForTags({ value: value });
        } else if (key === 'dateEnvoieExacte') {
            translatedKey = 'Lots envoyés le';
            formattedValue = ctx.formatDateForTags({ value: value });
        } else if (key === 'libelleOrganisme') {
            translatedKey = 'Sigle organisme AMO';
        } else if (key === 'organismeDestinataire') {
            translatedKey = 'Code officiel organisme AMO';
        }
        return new FilterItem().withId(formattedKey).withLabel(translatedKey).withValue(formattedValue).withValue2(value);
    }

    ngOnDestroy() {
        sessionStorage['lotsFilter'] = JSON.stringify(this.filters);
        sessionStorage['lotsCurrentPage'] = this.gridHelper.gridApi.paginationGetCurrentPage();
        this.gridHelper.saveGridStatePrefix();
    }

    refresh(): boolean {
        return false;
    }

    existGroupNumeroPS() {
        return this.isAuthorized() || this.psDelegueListFilter.length != 0;
    }
    removeColumn(columnName: string) {
        const index = this.exportableColumns.indexOf(columnName);
        if (index > -1) {
            this.exportableColumns.splice(index, 1);
        }
    }

    addColumn(columnName: string) {
        if (this.exportableColumns.indexOf(columnName) === -1) {
            this.exportableColumns.push(columnName);
        }
    }

    // disable appliquer les filtres button
    disableSearch() {
        if (
            this.isAuthorized() &&
            Object.values(this.filters).every((o) => o === null || o === '' || o === undefined)
        ) {
            return true;
        } else {
            return false;
        }
    }

    loadComboBoxFilterNumeroPS_Supervise() {
        const userConnected = JSON.parse(localStorage.getItem('careweb_user'));
        if (!isEmpty(userConnected)) {
            if (!isNullOrUndefined(userConnected.idPs)) {
                this.psDelegueSVC.getPsDelegueByIdPs(userConnected.idPs).subscribe((data) => {
                    if (!isNullOrUndefined(data) && !isEmpty(data)) {
                        this.psDelegueListFilter = data;
                        let psCurrent = new PsDelegueDataRaw();
                        psCurrent.idPsDelegue = this.psDelegueListFilter[0].idPsAssocie;
                        psCurrent.numeroPsDelegue = this.psDelegueListFilter[0].numeroPsAssocie;
                        psCurrent.nomPsDelegue = this.psDelegueListFilter[0].nomPsAssocie;
                        this.psDelegueListFilter.push(psCurrent);
                    }
                    this.gridHelper.gridColumnApi.setColumnVisible('numeroPs', this.existGroupNumeroPS());
                });
            }
        }
    }

    onPaginationSizeChange(paginationSize: number): void {
        this.gridHelper.changePaginationSize(paginationSize);
        if (this.isAuthorized() && this.rowCount != 0){
            this.launchSearch();
        } else if(!this.isAuthorized()){
            this.launchSearch();
        }
        this.gridHelper.saveGridStatePrefix();
    }

    onColumnChanged(params: any): void {
        this.gridHelper.gridColumnApi.setColumnsVisible(["numeroPs"], this.existGroupNumeroPS());
        this.gridHelper.saveGridColumnState();
    }

    onChoiceChange($event: any, type: string): void {
        if ($event.filterChoice === "EXACTE") {
            this.filters[type + 'From'] = null;
            this.filters[type + 'To'] = null;
        } else {
            this.filters[type + 'Exacte'] = null;
        }
    }

    checkDateRangeValidity(event) {
        this.isDateRangeValid = event;
        if (this.dateEnvoie) {
            this.dateEnvoie.hideTooltlipDebut();
            this.dateEnvoie.hideTooltlipFin();
        }
    }
    
    exportToExcel(columns) {
        this.export = true;
        this.exportState = {
            message : "Vous allez exporter <b>" + this.transformService.transform(this.rowCount) + " ligne(s)</b>, veuillez patienter quelques instants.",
            state: State.INPROGRESS
        }
        const sorts = gridConvertSort(this.gridHelper.gridSortModel(), []);
        this.lotsSvc.exportLots(
                            {
                                page: 0,
                                size: this.rowCount,
                                export: false,
                                sorts
                            },
                            this.lotsDataRaw,
                            columns
                        ).subscribe((response)=> {
                            saveAs(response.file, response.fileName);
                            this.exportState = {
                                message : "Votre fichier a été téléchargé avec succès.",
                                state: State.SUCCESS
                            }
                        },() => {
                            this.exportState = {
                                 message : "Le téléchargement de votre fichier a rencontré un problème.",
                                 state: State.FAILED
                            }
                        });
    }

    exportByEmail(columns) {
        const sorts = gridConvertSort(this.gridHelper.gridSortModel(), []);
        this.lotsSvc.exportLotsByEmail(
                            {
                                page: 0,
                                size: this.rowCount,
                                export: false,
                                sorts
                            },
                            this.lotsDataRaw,
                            columns
                        ).subscribe(()=> {
                            // when email received
                        });
    }
    closeAlert() {
        this.export = false;
    }
    exportExcelClient() {
        const ctx = this;
        if (!this.isAuthorized()) {
            // Le compte PS ne doit pas voir les 3 premières colonnes
            const nonExportableColumns = [
                'numeroPs',
                'dateReceptionPartition',
                'dateEnvoiDernierArt',
                'dateDernierArl'
            ];
            this.exportableColumns = this.exportableColumns.filter((item) => nonExportableColumns.indexOf(item) === -1);
        }
        const params = {
            skipHeader: false,
            columnGroups: true,
            skipFooters: false,
            skipGroups: true,
            skipPinnedTop: false,
            skipPinnedBottom: false,
            allColumns: true,
            onlySelected: false,
            fileName: 'Liste_lots',
            sheetName: 'Liste_lots',
            columnKeys: this.exportableColumns,
            processCellCallback: function (params) {
                if (
                    params.column.colDef.field === 'montantTotal' ||
                    params.column.colDef.field === 'montantRo' ||
                    params.column.colDef.field === 'montantRc' ||
                    params.column.colDef.field === 'rejetRO' ||
                    params.column.colDef.field === 'rejetRC'
                ) {
                    params.value = ctx.formatCurrency({
                        data: true,
                        value: params.value
                    });
                } else if (
                    params.column.colDef.field === 'dateReceptionPartition' ||
                    params.column.colDef.field === 'dateEnvoiePartRo' ||
                    params.column.colDef.field === 'dateEnvoiDernierArt' ||
                    params.column.colDef.field === 'dateDernierArl'
                ) {
                    params.value = ctx.formatDate({
                        value: params.value
                    });
                } else if (
                    (params.column.colDef.field === 'sv' ||
                        params.column.colDef.field === 'securise' ||
                        params.column.colDef.field === 'tiersPayant') &&
                    (params.value === 1 || params.value === true)
                ) {
                    params.value = 'Oui';
                } else if (
                    (params.column.colDef.field === 'sv' ||
                        params.column.colDef.field === 'securise' ||
                        params.column.colDef.field === 'tiersPayant') &&
                    (params.value === 0 || params.value === false)
                ) {
                    params.value = 'Non';
                }

                if (params.column.colDef.field === 'signeArl') {
                    params.value == true
                        ? (params.value = 'Oui (ARL Positif)')
                        : params.value === false ? (params.value = 'Non (ARL négatif)') : (params.value = '');
                }
                return params.value;
            },
            processRowGroupCallback: (params) => this.rowGroupCallback(params)
        };
        this.gridHelper.gridApi.exportDataAsExcel(params);
    }
}
