import { Component, AfterViewInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'suivi-rapp-missing-transactions',
    template: `
    <i [ngClass]="iconClass"
            container="body"
			[tooltip]="tooltip"
            containerClass="tooltip-grid center"
           style="font-size: 1.3em;"
		></i>`
})

export class SuiviRappMissingTransactionsComponent implements ICellRendererAngularComp, AfterViewInit {
    iconClass: string;
    tooltip: string;
    params: any;

    constructor() { }

    refresh(params: any): boolean {
        return false;
    }

    agInit(params: any): void {
        this.params = params;
    }

    ngAfterViewInit() {
        const cellParams = this.params;
        if (cellParams.data) {
            if (cellParams.colDef.field && cellParams.colDef.field === 'missingTransactions') {
                this.iconClass = 'fa fa-file-text-o';
                this.tooltip = 'Forcer la récupération de transactions manquantes';
            }
        }
    }
}
