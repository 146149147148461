import { Component } from '@angular/core';

@Component({ 
    selector: 'careweb-column-tooltip',
    template: `
        <p  container="body"
            [tooltip]="tooltipValue"
            placement="bottom"
            containerClass="tooltip-grid"
            class="overflow-hidden text-truncate rappro-tooltip"
        >
            {{value}}
        </p>
    `
} )
export class ColumnTooltipComponent {
    value: string;
    tooltipValue = '';

    constructor() {}

    agInit(params: any): void {
        this.tooltipValue = params.data.notes ? `${params.data.label} : ${params.data.notes}` : params.data.label;
        this.value = params.value;
    }

}
