import { FilterService } from '../../../core/services/filter-service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CONF } from '../../../core/constants';
import { LogicielDataRaw } from '../../../data/logicielDataRaw';

@Injectable({
	providedIn: 'root'
})
export class LogicielsService extends FilterService {
	private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/logiciels';

	constructor(private httpClient: HttpClient) {
		super();
	}

	getLogicielById(id: number): Observable<any> {
		return this.httpClient.get<any>(this.baseUrl + '/' + id);
	}

	getLogicielByEditeurId(criteria: any): Observable<any> {
		return this.httpClient.get<any>(this.baseUrl + '/search/editeur/?idEditeur=' + criteria.idEditeur);
	}

	getLogicielsFiltred(criteria: any, logicielDataRaw: LogicielDataRaw): Observable<any> {
		return this.httpClient.post<any>(
			this.baseUrl +
				'/search?page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				'&sort=' +
				criteria.sort +
				'&direction=' +
				criteria.direction,
			logicielDataRaw
		);
	}

	deleteByLogicielId(idLogiciel: number): Promise<any> {
		return this.httpClient.delete(`${this.baseUrl}/${idLogiciel}`).toPromise();
	}

	getLogicielsByCriteria(criteria: any, logicielDataRaw: LogicielDataRaw): Observable<any> {
		return this.httpClient.post<any>(
			this.baseUrl + '/search-not-attached?page=' + criteria.page + '&size=' + criteria.size,
			logicielDataRaw
		);
	}

	rattacher(logiciel: LogicielDataRaw) {
		return this.httpClient.post(this.baseUrl, logiciel).toPromise();
	}
}
