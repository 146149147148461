// iban-clean.directive.ts
import { Directive, HostListener, ElementRef, Input, Renderer2, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

const IBAN_CLEAN_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => IbanCleanDirective),
  multi: true,
};

@Directive({
  selector: '[appIbanClean]',
  providers: [IBAN_CLEAN_VALUE_ACCESSOR]
})
export class IbanCleanDirective implements ControlValueAccessor {
  @Input() maxLength: number = 34;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('input', ['$event']) onInput(event: any): void {
    let value: string = event.target.value;
    
    // Nettoyez les espaces
    value = value.replace(/\s/g, '');

    // Limitez la longueur après le nettoyage des espaces
    if (value.length > this.maxLength) {
      value = value.substr(0, this.maxLength);
    }

    // Mettez à jour la valeur du modèle
    this.onChange(value);
    this.renderer.setProperty(this.el.nativeElement, 'value', value);
  }

  // Implémentation des méthodes du ControlValueAccessor
  private onChange: (_: any) => void = () => {};

  writeValue(value: any): void {
    // Mettez à jour la valeur du champ d'entrée quand la valeur du modèle change
    this.renderer.setProperty(this.el.nativeElement, 'value', value);
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
  }
}