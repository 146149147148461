import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { isNullOrUndefined } from 'util';

@Injectable({
	providedIn: 'root'
})
export class ConvertDateFormat {
	constructor() {}

	getFormatDateFr(date) {
		return isNullOrUndefined(date) || date == 'Invalid Date' ? null : formatDate(date, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'fr-FR');
	}

	getFormatDateFrString(date) {
		if(!isNullOrUndefined(date)){
			const year = date.getFullYear().toString().substr(-2);
			const month = (date.getMonth() + 1).toString().padStart(2, '0');
			const day = date.getDate().toString().padStart(2, '0');
			return `${day}${month}${year}`;
		}
	}
}
