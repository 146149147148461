<div class="full-careweb-container">
  <div id="filter" class="filter-container" carewebFilterMinHeight>
    <div class="filter-container-inner" [ngClass]="{ 'show-content': showFilter, 'init-hidden': initHidden }">
      <careweb-accordion-header-buttons (closeFilters)="toggleFilterDisplay()" (resetFilters)="resetAllFilter()"
        (launchSearch)="launchSearch()"></careweb-accordion-header-buttons>
      <div class="filter-content">
        <mat-accordion displayMode="flat" class="accordion-filter">
          <!-- Libellé -->
          <careweb-accordion-group id="libelle" title="Libellé">
            <input type="text" id="input_libelle" [(ngModel)]="filters.libelle" (keydown.enter)="onKeyDown($event)" />
          </careweb-accordion-group>
          <!-- Regroupement RC -->
          <careweb-accordion-group id="rc_interdit" title="Regroupement RC interdit">
            <ng-select name="regroupementRc" id="regroupementRcSelector" placeholder="Regroupement RC"
              [searchable]="false" [(ngModel)]="filters.regroupementRc" appendTo=".filter-container">
              <ng-option [value]=0>RC interdit</ng-option>
              <ng-option [value]=1>RC autorisé</ng-option>
            </ng-select>
          </careweb-accordion-group>
        </mat-accordion>
      </div>
    </div>
  </div>
  <div class="grid-container" id="grid">
    <h2 class="title-border mt-3">Regroupements</h2>
    <div>
      <section>
        <div class="row grid-top-btn">
          <div class="col-md-4 filter">
            <button type="button" name="btn_toggle_filter" class="btn btn-primary small left-open" [hidden]="showFilter"
              (click)="toggleFilterDisplay()">
              <i class="fa fa-sliders"></i>
              Filtrer
            </button>
            <careweb-pluralize
            class="filter-count"
            [messageKey]="'Résultat(s) trouvé(s)'"
            [count]="gridHelper.totalRowCount"
        ></careweb-pluralize>
          </div>
          <div class="col-md-8 conf-col">
            <div class="careweb-toolbar">
              <button type="button" name="btn_add_regroupement" class="btn btn-primary small left-open">
                <i class="fa fa-plus"></i>
                Ajouter un regroupement
              </button>
              <span class="btn btn-action small" [class.disabled]="!gridHelper.isAvailableData()" tooltip="Exporter les résultats (.xlsx)"
                placement="top" container="body" name="btn_export_csv" (click)="exportCsv()">
                <i class="fa fa-file-excel-o"></i>
              </span>
              <careweb-pagination-size id="dv_regroupements_paging" [pageSizes]="[10, 20, 30, 50, 100]" [(ngModel)]="gridDataRaw.size"
                (paginationSizeChange)="onPaginationSizeChange($event)">
              </careweb-pagination-size>
              <span class="btn btn-action small" tooltip="Réinitialiser l'affichage" placement="top" container="body"
                name="spn_reset_grid" (click)="resetGridState()">
                <i class="fa fa-refresh"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="row highlight-row">
          <div class="col-md-12 relative">
            <ag-grid-angular style="width: 100%" class="ag-theme-balham" [gridOptions]="gridHelper.gridOptions">
            </ag-grid-angular>
            <careweb-pagination [gridApi]="gridHelper.gridApi"></careweb-pagination>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>