import { ArlDataRaw } from './arlDataRaw';
import { FseDataRaw } from './fseDataRaw';

export class LotsDataRaw {
    idLot: String;
    numeroPs: String;
    sv: Boolean;
    securise: Boolean | null;
    tiersPayant: Boolean | null;
    numLotFrom: String;
    numLotTo: String;
    numLot: String;
    nbFactures: Number;
    montantTotal: String;
    organismeDestinataire: String;
    dateReceptionPartition: string;
    dateReceptionPartitionFrom: string;
    dateReceptionPartitionTo: string;
    signeArl: String;
    nbRsp: Number;
    nbArp: Number;
    dateEnvoiePartRoFrom: string;
    dateEnvoiePartRoTo: string;
    dateEnvoiePartRoExacte: string;
    sigleOrganisme: String;
    codeOrganisme: String;
    libelleOrganisme: String;
    raisonSociale: String;
    dateCreationLot: String;
    nbArl: Number;
    montantRo: String;
    montantRc: String;
    nbreArlScanOrdo: Number;
    arls: Array<ArlDataRaw>;
    factures: Array<FseDataRaw>;
    idOrganisme: String;
    idOct: Number;
	signeArlFilter: String;
}
