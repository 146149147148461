import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private authSvc: AuthService,
        private router: Router,
        private bsModalRef: BsModalRef,
        private bsModalService: BsModalService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in
        const currentUser = this.authSvc.currentUserValue;
        const token = localStorage.getItem('careweb_token');
        const oxlinToken = localStorage.getItem('oxlin_token');
        const oxlinRefreshToken = localStorage.getItem('oxlin_refresh_token');
        const selToken = localStorage.getItem('sel_token');
        const isLoggedIn = currentUser && token;

        if (
            !isLoggedIn &&
            this.router.url !== '/login' &&
            this.router.url.indexOf('/reset-password') === -1 &&
            this.router.url.indexOf('/forget-password') === -1 &&
            this.router.url.indexOf('/sent-reinit-password') === -1
        ) {
            // close modals
            this.bsModalRef.hide();
            this.bsModalService.hide(1);
            this.router.navigate([ '/login' ]);
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                    OxlinToken: `${oxlinToken}`,
                    SelToken: `${selToken}`
                }
            });
        } else if (isLoggedIn) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                    OxlinToken: `${oxlinToken}`,
                    SelToken: `${selToken}`,
                    oxlinRefreshToken: `${oxlinRefreshToken}`
                }
            });
        }
        
        return next.handle(request);
    }
}
