export class OrgStateDataRaw {
	id: string;
	organizationNumber: string;
	label: string;
	agreementType: string;
	secondaryCriteria: string;
	secondaryCriteriaLabel: string;
	agreementDomain: string;
	norm: string;
	normVersion: string;
	service: string;
	startDate: string;
	endDate: string;
	initSource: string;
	routingUrl: string;
	method: string;
	adressType: string;
	netLabel: string;
	providerComment: string;
	contactname: string;
	contactFirstName: string;
	contactMail: string;
	contactPhone: string;
	convType: string;
	interAmc: boolean;
	interAmcSel: string;
	subscribedPlatform: boolean;
}
