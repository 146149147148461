<div class="modal-header pb-1">
        <h3 class="modal-title">Détail de l'ARL</h3>
        <button class="close" name="btn_display_filter" data-dismiss="modal" type="button"
            title="Fermer" (click)="closeModal()"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body pb-0">
        <div class="form-block">
            <fieldset class="container-fluid">
                <div class="row">
                    <div class="col-sm-3 col-form-label">Nom du titulaire</div>
                    <div class="col-sm-9 form-group">
                        <input type="text" class="form-control" value="{{arl.nomPs}}" readonly/>
                    </div>
                </div> 
                <div class="row">
                    <div class="col-sm-3 col-form-label">Organisme dest.</div>
                    <div class="col-sm-9 form-group">
                        <input type="text" class="form-control" value="{{arl.libelleOrganisme}}" readonly/>
                    </div>
                </div>  
                <div class="row">
                    <div class="col-sm-3 col-form-label">ARL reçu le</div>
                    <div class="col-sm-9 form-group">
                        <input type="text" class="form-control" value="{{formatDate(arl.dateReception)}}" readonly/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3 col-form-label">Montant total du lot</div>
                    <div class="col-sm-9 form-group">
                        <input type="text" class="form-control" value="{{formatCurrency(arl.montantFacture)}}" readonly/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3 col-form-label">Statut</div>
                    <div class="col-sm-9 form-group">
                        <input type="text" class="form-control" value="{{formatSigneArl(arl.signeArl)}}" readonly/>
                    </div>
                </div>     
                <div class="row">
                    <div class="col-sm-3 col-form-label">Message</div>
                    <div class="col-sm-9 form-group">
                        <textarea type="text" class="form-control-input" rows="5" value="{{arl.libelleRejet}}" readonly></textarea>
                    </div>
                </div>      
            </fieldset>
        </div>
    </div>
<div class="modal-footer">

</div>