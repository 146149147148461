import { Component, OnInit, Input } from "@angular/core";
import * as _ from 'lodash-es';
import { DomSanitizer } from "@angular/platform-browser";
import { EbicsService } from "../../ps/service/ebics.service";
import { ToastrService } from "ngx-toastr";
import { Router } from '@angular/router';

@Component({
    selector: 'card-ebics-abonnement',
    templateUrl: './card-ebics-abonnement.component.html',
    styleUrls: ['./card-ebics-abonnement.component.scss'],
})
export class CardEbicsAbonnementComponent implements OnInit {
    ebicsUserConnected: any;
    psEbics: any;
    isPageLoaded: boolean = false;
    userConnected: any;
    host: string;
    isFichePs: boolean = true;
    @Input() comptesAssocies: any = null;
    showConfirmModal = false;
    modalMessage = 'Confirmation de Désynchronisation';
    modalBody = 'Êtes-vous sûr de vouloir désynchroniser ce compte bancaire ? Cela entraînera la suppression de toutes les données liées à ce compte dans notre système.';
    
    idEbicsToDelete;

    constructor(
        public sanitizer: DomSanitizer,
        private ebicsService: EbicsService,
        private toastr: ToastrService,
        private router: Router) {
        const body = document.body
        body.className = 'app';
    }

    ngOnInit() {
        this.isFichePs = this.router.url.includes('ebics-account') ? false : true;
        this.userConnected = JSON.parse(localStorage.getItem('careweb_user'));
        this.ebicsUserConnected = JSON.parse(localStorage.getItem('careweb_ebics_user'));
        if (this.comptesAssocies == null) {
            this.initialiserEbicsContext(this.getPsId());
        }
    }

    getPsId() {
        return (localStorage.getItem('supervised_ps') != null && localStorage.getItem('supervised_ps') !='-1') ? localStorage.getItem('supervised_ps').split(';')[1]:(localStorage.getItem('idPs') != null) ? localStorage.getItem('idPs') : this.userConnected.idPs;
        //return (localStorage.getItem('idPs') != null) ? localStorage.getItem('idPs') : this.userConnected.idPs
    }

    toggleEbicsAccount(id, idPs) {
        this.ebicsService.toggleEbicsAccount(id).subscribe((data) => {
            if (data) {
                if(data.actif) {
                    this.toastr.success("Le compte EBICS a été activé avec succès");
                } else {
                    this.toastr.success("Le compte EBICS a été désactivé avec succès");
                }
                
                const compte = this.comptesAssocies.find((c) => c.iban === data.iban);
                if (compte) {
                    compte.actif = data.actif;
                }
                let currentPsStorage = localStorage.getItem('current_ps');
                if (currentPsStorage) {
                    const currentPs = JSON.parse(currentPsStorage);
                    currentPs.psEbics = this.comptesAssocies;
                    // Mettre à jour la valeur dans le localStorage
                    localStorage.setItem('current_ps', JSON.stringify(currentPs));
                }
                if(!this.isFichePs && this.comptesAssocies.every(compte => !compte.actif)){
                    this.router.navigate(['/ps-details/'+idPs]);
                }
            }
            else {
                this.toastr.error("Erreur lors de la désactivation!");
            }
        });
    }

    deleteEbicsAccount() {
        this.ebicsService.deleteEbicsAccount(this.idEbicsToDelete).subscribe((data) => {
            this.toastr.success("Le compte EBICS a été suprrimé avec succès");
            this.comptesAssocies = this.comptesAssocies.filter(c => c.id != this.idEbicsToDelete);
            this.showModal(false);
        }, (error) => {
            this.toastr.error("Erreur lors de la suppression du compte EBICS!");
            this.showModal(false);
        });
    }

    public initialiserEbicsContext(idPs: Number) {
        this.ebicsService.getEbicsByPsId(idPs).then(
            (data) => {

                this.comptesAssocies = data;

            }
        )
    }

    openConfirmModal(id) {
        this.idEbicsToDelete = id;
        this.showModal(true);
    }

    public showModal(showModal: boolean) {
		this.showConfirmModal = showModal;
	}

}