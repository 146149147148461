export class UserFilterRaw {
	idTypeUser: Number;
	nom: String;
	prenom: String;
	email: String;
	idUser: String;
	idPs: String;
	etablissementRaisonSocial: string;
	nomPs: string;
	idCivilite: Number;
	actif: boolean;
	telephone: string;
	fax: string;
	mobile: string;
	fonction: String;

	constructor(data = null) {
		Object.assign(this, data);
	}
}
