<div class="full-careweb-container">
  <div id="filter" class="filter-container" carewebFilterMinHeight>
    <div class="filter-container-inner" [ngClass]="{ 'show-content': showFilter, 'init-hidden': initHidden }">
      <careweb-accordion-header-buttons (closeFilters)="toggleFilterDisplay()" (resetFilters)="resetAllFilter()"
        (launchSearch)="launchSearch()"></careweb-accordion-header-buttons>
      <div class="filter-content">
        <mat-accordion displayMode="flat" class="accordion-filter">
          <!-- Nom -->
          <careweb-accordion-group id="nom" title="Nom">
            <input type="text" id="input_nom" [(ngModel)]="this.filters.nom"
              onKeyPress="if(this.value.length==50) return false;" (keydown.enter)="onKeyDown($event)" name="nom" />
          </careweb-accordion-group>
          <!-- Prénom -->
          <careweb-accordion-group id="prenom" title="Prénom">
            <input type="text" id="input_prenom" onKeyPress="if(this.value.length==50) return false;"
              [(ngModel)]="this.filters.prenom" (keydown.enter)="onKeyDown($event)" name="prenom" />
          </careweb-accordion-group>
          <!-- Email -->
          <careweb-accordion-group id="email" title="Email">
            <input type="text" id="input_email" [(ngModel)]="filters.email" (keydown.enter)="onKeyDown($event)"/>
          </careweb-accordion-group>
          <!-- Fonction -->
          <careweb-accordion-group id="fonction" title="Fonction">
            <input type="text" id="input_fonction" onKeyPress="if(this.value.length==50) return false;"
              [(ngModel)]="this.filters.fonction" (keydown.enter)="onKeyDown($event)" name="fonction" />
          </careweb-accordion-group>
          <!-- Etat -->
          <careweb-accordion-group id="etat" title="Etat">
            <ng-select name="select_etat" id="etatUserSelect" placeholder="Etat" [searchable]="false" appendTo=".filter-container"
              [(ngModel)]="this.filters.actif" bindLabel="name" [items]="statesFilter" bindValue="id" name="etat" (keydown.enter)="onKeyDown($event)"
              >
            </ng-select>
          </careweb-accordion-group>
        </mat-accordion>
      </div>
    </div>
  </div>
  <div class="grid-container" id="grid">
    <h2 class="title-border mt-3">Liste des comptes OCT</h2>
    <div>
      <section>
        <div class="row grid-top-btn">
          <div class="col-md-4 filter">
            <button type="button" name="btn_toggle_filter" class="btn btn-primary small left-open" [hidden]="showFilter"
              (click)="toggleFilterDisplay()">
              <i class="fa fa-sliders"></i>
              Filtrer
            </button>
            <careweb-pluralize class="filter-count" [messageKey]="'Résultat(s) trouvé(s)'"
              [count]="gridHelper.totalRowCount"></careweb-pluralize>
          </div>
          <div class="col-md-8 conf-col">
            <div class="careweb-toolbar">
              <div [tooltip]="gridHelper.isAvailableData() ? 'Exporter les résultats (.xlsx)' : 'Aucune ligne à exporter, veuillez affiner votre recherche'"
                   placement="top" container="body">
                <span class="btn btn-action small" [class.disabled]="!gridHelper.isAvailableData()"
                       name="btn_export_csv" (click)="exportExcel()">
                <i class="fa fa-file-excel-o"></i>
                </span>
              </div>
              <!-- paginationSize -->
              <careweb-pagination-size id="dv_users_paging" [pageSizes]="[10, 20, 30, 50, 100]"
                [(ngModel)]="this.gridDataRaw.size" (paginationSizeChange)="onPaginationSizeChange($event)">
              </careweb-pagination-size>
              <span class="btn btn-action small" tooltip="Réinitialiser l'affichage" placement="top" container="body"
                name="spn_reset_grid" (click)="resetGridState()">
                <i class="fa fa-refresh"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="row grid-top-filter m-0" *ngIf="showAlert" >
          <div class="alert alert-info">
            <div>
              <i class="fa fa-info-circle"></i> Le compte <span class="font-weight-bold">{{filters.email}} </span> est associé à la liste des OCT suivante:
              <span *ngFor="let oct of listOctByUserCriteria; let last = last">
                <a [routerLink]="['.']" (click)="onOctChange(oct)"  > {{ oct.nom }}{{( listOctByUserCriteria.length>1 && !last )? ', ' : '.' }} </a>
              </span>
              <span (click)="dismissAlert()" style="cursor: pointer; float: right;"><i class="fa fa-times"></i></span>
            </div>
          </div> 
        </div>
          <app-alert [export]="export" [exportState]="exportState" (close)="closeAlert()"></app-alert>
        <!-- show chips -->
        <div class="row grid-top-filter">
          <div class="col-md-12">
            <careweb-filter-label *ngFor="let filter of filterList" (closeFilter)="removeFilter(filter.id)">
              <span *ngIf="filter">{{ filter.label}}: {{filter.value }}</span>
            </careweb-filter-label>
          </div>
        </div>
        <div class="row highlight-row">
          <div class="col-md-12 relative">
            <ag-grid-angular style="width: 100%" class="ag-theme-balham" [gridOptions]="gridHelper.gridOptions">
            </ag-grid-angular>
            <careweb-pagination [gridApi]="gridHelper.gridApi"></careweb-pagination>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
