import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from '../../core/services/auth.service';
import { User } from '../../data/userDataRaw';

@Component({
	selector: 'careweb-logout-modal',
	templateUrl: './logout-modal.component.html'
})
export class LogoutModalComponent implements OnInit {
	@Input() user: User;
	@ViewChild('logoutForm')
	form: NgForm;
	hidden = true;
	constructor(private modalRef: BsModalRef, private router: Router, private authSvc: AuthService ) {}

	ngOnInit(): void {}

	cancel() {
		this.modalRef.hide();
	}

	closeModal() {
		this.modalRef.hide();
	}

	logoutUser() {
		const ctx = this;
		if (this.router.url != '/login') {
			sessionStorage.setItem("lastRoute", ctx.router.url)
		}
		this.authSvc.logout().subscribe(() => {
			localStorage.removeItem('careweb_token');
			localStorage.removeItem('careweb_user');
			localStorage.removeItem('careweb_ebics_user');
			localStorage.removeItem('hotlineFilters');
			localStorage.removeItem('sel_token');
			localStorage.removeItem('sel_token_expires_in');
			localStorage.removeItem('octCurrent');
			localStorage.removeItem('psOctsCods');
			//localStorage.clear();
/* 			sessionStorage.clear(); // Sometimes not working
			Object.keys(sessionStorage)
				.filter(function(k) {
					return /Filter/.test(k);
				})
				.forEach(function(k) {
					sessionStorage.removeItem(k);
				}); */
			ctx.cancel();
			ctx.router.navigate([ '/login' ]);
			sessionStorage['nbr'] = 0;
		});
	}
}
