import { UserPreferencesDataRaw } from './UserPreferencesDataRaw';
import { Role } from './roleDataRaw';
import { OctDataRaw } from './octDataRaw';
import { EtablissementDataRaw } from './EtablissementDataRaw';

export class User {
	login: string;
	domain: string;
	firstname: string;
	token?: String;
	role: Role;
	idTypeUser: Number;
	nom: String;
	prenom: String;
	email: String;
	idUser: String;
	technicalId: Number;
	idPs: any;
	etablissementRaisonSocial: string;
	nomPs: string;
	idCivilite: Number;
	actif: boolean;
	blocked: boolean;
	telephone: String;
	fax: string;
	mobile: string;
	octs: OctDataRaw[];
	fonction: String;
	supprime: Boolean;
	numeroAdeli: String;
	libelleCourtGroup: String;
	commentaire: String;
	modifMdp: Boolean;
	modifAdministration: Boolean;
	idGrpUtilisateur: Number;
	idOct: Number;
	idTypePs: Number;
	etablissementUser: EtablissementDataRaw;
	idEtablissement: Number;
	typePs: string;
	userPreferences: UserPreferencesDataRaw;
	username: string;
	//passwordHash: string;
	dateExperation : Date;
	hasPasswordHash: boolean;
	differenceEnJour: number;
}
